<h1 mat-dialog-title>Dor</h1>
<div mat-dialog-content>
    <div class="modal-body text-center">

        <img *ngIf="age <= 140000" src="assets/images/regua-dor-crianca.svg" class="imagem-criana-regua-dor">

        <div class="regua-dor-left">
            <label>Sem dor</label>
        </div>
        <div class="regua-dor-outer">
            <label class="mild-pain">Dor leve discreta</label>
            <label class="moderate-pain">Moderada (significativa, mas suportável) relativamente
                ruim</label>
            <label class="severe-pain">Grave <br />muito ruim</label>
            <label class="intense-pain">Intensa, insuportável dilacerante</label>

            <div class="regua-dor">
                <div class="coluna">
                    <a (click)="selectValue(painEnum.level0, 0, '#c6c6c6')" class="celula level0">
                        0
                    </a>
                </div>
                <div class="coluna">
                    <a (click)="selectValue(painEnum.level1Seven, 1, '#7BCDED')" class="celula celula-metade level1Seven">
                        1
                    </a>
                    <a (click)="selectValue(painEnum.level1, 1, '#5AFE59')" class="celula celula-metade level1">
                        1
                    </a>
                </div>
                <div class="coluna">
                    <a (click)="selectValue(painEnum.level2Seven, 2, '#67C3EA')" class="celula celula-metade level2Seven">
                        2
                    </a>
                    <a (click)="selectValue(painEnum.level2, 2, '#56EC57')" class="celula celula-metade level2">
                        2
                    </a>
                </div>
                <div class="coluna">
                    <a (click)="selectValue(painEnum.level3Seven, 3, '#58BAEA')" class="celula celula-metade level3Seven">
                        3
                    </a>
                    <a (click)="selectValue(painEnum.level3, 3, '#52DC51')" class="celula celula-metade level3">
                        3
                    </a>
                </div>
                <div class="coluna">
                    <a (click)="selectValue(painEnum.level4Seven, 4, '#45B1E5')" class="celula celula-metade level4Seven">
                        4
                    </a>
                    <a (click)="selectValue(painEnum.level4, 4, '#49C544')" class="celula celula-metade level4">
                        4
                    </a>
                </div>
                <div class="coluna">
                    <a (click)="selectValue(painEnum.level5, 5, '#FEE177')" class="celula level5">
                        5
                    </a>
                </div>
                <div class="coluna">
                    <a (click)="selectValue(painEnum.level6, 6, '#FFDF66')" class="celula level6">
                        6
                    </a>
                </div>
                <div class="coluna">
                    <a (click)="selectValue(painEnum.level7, 7, '#FEDB53')" class="celula level7">
                        7
                    </a>
                </div>
                <div class="coluna">
                    <a (click)="selectValue(painEnum.level8, 8, '#FE962D')" class="celula level8">
                        8
                    </a>
                </div>
                <div class="coluna">
                    <a (click)="selectValue(painEnum.level9, 9, '#FF8B1A')" class="celula level9">
                        9
                    </a>
                </div>
                <div class="coluna">
                    <a (click)="selectValue(painEnum.level10, 10, '#FD7E05')" class="celula level10">
                        10
                    </a>
                </div>
            </div>
            <label class="info-mild-pain">Atividades normais</label>
            <label class="info-moderate-pain">Pouco impacto nas atividades faz a maioria das
                coisas</label>
            <label class="info-severe-pain">Causa dificuldades interrompe certas atividades</label>
            <label class="info-intense-pain">Incapacidade, interrompe atividades normais</label>
        </div>
        <div class="regua-dor-right">
            <label>A pior de todas</label>
        </div>

        <div class="significado-cores">
            <div class="significado">
                <span class="cor level4Seven"></span>
                <label>>= 7dias</label>
            </div>
            <div class="significado">
                <span class="cor level4"></span>
                <label>
                    < 7dias</label>
            </div>
        </div>
    </div>
</div>