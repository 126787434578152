import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { HealthcareAgreementRequest } from '../../requests/private-billing/healthcare-agreement.request';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { HealthcareAgreementResponse } from '../../responses/private-billing/healthcare-agreement.response';
import { ListHealthcareAgreementResponse } from '../../responses/private-billing/list-healthcare-agreement.response';

@Injectable({
  providedIn: 'root'
})
export class HealthcareAgreementService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public Post(body: HealthcareAgreementRequest): Observable<HealthcareAgreementResponse> {
    let uri = `HealthcareAgreement`;

    return this.httpClient.post<HealthcareAgreementResponse>(environment.urlApiPrivateBilling + uri, body, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public Put(body: HealthcareAgreementRequest, idHealthcareAgreement: number): Observable<ReturnStruct> {
    let uri = `HealthcareAgreement/idHealthcareAgreement/${idHealthcareAgreement}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiPrivateBilling + uri, body, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public GetAll(searchText: string, pageIndex: number, isActiveHealthcareAgreement: boolean = null): Observable<ListHealthcareAgreementResponse> {
    let uri = `HealthcareAgreement?`

    if (searchText)
      uri = uri + `searchText=${searchText}&`

    if (pageIndex)
      uri = uri + `pageIndex=${pageIndex}&`;

    if (isActiveHealthcareAgreement == true || isActiveHealthcareAgreement == false)
      uri = uri + `isActiveHealthcareAgreement=${isActiveHealthcareAgreement}`;

    return this.httpClient.get<ListHealthcareAgreementResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public GetAllByHealthUnit(searchText: string = null): Observable<ListHealthcareAgreementResponse> {
    let uri = `HealthcareAgreement/getAllByHealthUnit`;

    if (searchText)
      uri = uri + `searchText=${searchText}`

    return this.httpClient.get<ListHealthcareAgreementResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public GetById(idHealthcareAgreement: number, guideType: number): Observable<HealthcareAgreementResponse> {
    let uri = `HealthcareAgreement/idHealthcareAgreement/${idHealthcareAgreement}?`

    if (guideType)
      uri = uri + `guideType=${guideType}`

    return this.httpClient.get<HealthcareAgreementResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public getAllSelect(idHealthcareAgreement: number, guideType: number): Observable<HealthcareAgreementResponse> {
    let uri = `HealthcareAgreement/getAllSelect/idHealthcareAgreement/${idHealthcareAgreement}?`

    if (guideType)
      uri = uri + `guideType=${guideType}`

    return this.httpClient.get<HealthcareAgreementResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public getAllToSelect(searchText?: string, maxSizeSelect?: number, isActiveHealthcareAgreement: boolean = null): Observable<ListHealthcareAgreementResponse> {
    let uri = `HealthcareAgreement/getAllToSelect?`

    if (searchText)
      uri = uri + `searchText=${searchText}&`

    if (maxSizeSelect)
      uri = uri + `maxSizeSelect=${maxSizeSelect}&`;

    if (isActiveHealthcareAgreement == true || isActiveHealthcareAgreement == false)
      uri = uri + `isActiveHealthcareAgreement=${isActiveHealthcareAgreement}`;

    return this.httpClient.get<ListHealthcareAgreementResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public PutStatus(idHealthcareAgreement: number, isActive: boolean): Observable<ReturnStruct> {
    let uri = `HealthcareAgreement/idHealthcareAgreement/${idHealthcareAgreement}/isActive/${isActive}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiPrivateBilling + uri, null, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}