<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-confirm-modal-list">
            <h2>Aviso!</h2>
        </div>
        <div class="body-confirm-modal-list">
            <p>Se este atendimento for cancelado, as guias geradas serão perdidas. Você realmente deseja sair?</p>
        </div>
        <div class="footer-confirm-modal-list row">
            <div class="col-md-6">
                <a mat-flat-button type="button" color="accent" class="btn-block" (click)="clickCancel(true)">
                    Sim
                </a>
            </div>
            <div class="col-md-6">
                <a mat-flat-button type="button" color="primary" class="btn-block" (click)="clickCancel(false)">
                    Não
                </a>
            </div>
        </div>
    </div>
</div>