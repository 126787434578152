import { Socket } from 'socket.io-client';
import * as myGlobals from 'globals'; //<==== this one (**Updated**)
import { Component } from '@angular/core';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class WebsocketUtilService {

    socket: Socket;

    addSocket(socket: Socket) {
        this.socket = socket;
    }

    getSocket() {
        return this.socket;
    }

    clearSocket() {
        this.socket = null;
        return this.socket;
    }
}