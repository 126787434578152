import { Validators } from "@angular/forms";
import { FormMetaData } from "src/app/shared/structs/form-meta-data.struct";

export class SafeIDClientRequest {
    public email: string = undefined;
    public name: string = undefined;
    public comments: string = undefined;
    public redirect_uris: string[] = undefined;

    public static metadata(): Map<string, FormMetaData> {
        let metaData = new Map<string, FormMetaData>();
        metaData['email'] = new FormMetaData('SafeID email', [Validators.required, Validators.email]);
        return metaData;
    }
}