<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-modal-list">
            <h2>Teste de conexão de rede</h2>
        </div>

        <div class="body-modal-list" *ngIf="!isTestFinished && !isLoading">
            Aqui você pode testar sua conexão com a internet para descobrir se existe algum problema com sua rede local.
        </div>
        <div *ngIf="isLoading" class="body-modal-list" class="result-value result">
            <div class="loading-text">
                <span> Teste em andamento</span>
            </div>
        </div>
        <div *ngIf="isTestFinished" class="body-modal-list" class="result">
            <!-- <div class="result-body">
                Taxa de transferência: <span class="result-value">{{transeferRate}} mb/s</span>
            </div> -->
            <div class="result-body">
                Ping: <span class="result-value">{{pingAverage}} ms</span>
            </div>
            <div class="result-body">
                Perdas: <span class="result-value"> {{percentPingLost}} %</span>
            </div>
            <!-- <div class="result-body">
                Qualidade do sinal: <span class="result-value">{{signalQuality}}</span>
            </div> -->
        </div>
        <div class="footer-modal-list">
            <button mat-flat-button color="primary" type="button" class="btn-block" (click)="clickConfirm()">
                <span *ngIf="isLoading == false">Iniciar teste</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
            <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Cancelar</button>
        </div>
    </div>
</div>