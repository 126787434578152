export class SelectedLocationRequest{
    public idEpisode: number;

    public idFlow: number;

    public idQueue: number;

    public idRoomOrigin: number;

    public idFlowCompleted: number;

    //para encaminhamento de observação apenas
    public idParentService: number;

    public isLiberateBed: boolean;
    public isBedManagement: boolean;
}