export enum LookupSpSadtRequiredFieldEnum {
    NGuiaPrincipal = 1, // campo 3
    DataAutorizacao = 2,// campo 4
    SenhaAutorizacao = 3,// campo 5
    DataValidadeSenha = 4,// campo 6
    NGuiaOperadora = 5,// campo 7
    ValidadeCarteira = 6,// campo 9
    NomeProfissionalSolicitante = 7,// campo 15
    DataSolicitacao = 8,// campo 22
    IndicacaoClinica = 9,// campo 23
    ObservacaoJustificativa = 10,// campo 58
    CoberturaEspecial = 11,// campo 90
    SaudeOcupacional =12,// campo 92
}