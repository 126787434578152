import { FormGroup, FormControl, ValidatorFn, ValidationErrors, AbstractControl } from "@angular/forms";

export function VerifyPhoneNumber(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) {
            return null;
        }
        const value = control.value;
        let isValid: boolean = true;

        let error = { invalidPhone: { value: control.value } };

        let validation: RegExp = /^(\s|\S)(\d[0-9]{9})$/g;

        isValid = validation.test(value);

        return !isValid ? error : null
    };

}


export function telefoneValidation(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (!control.value) return null;

        //retira todos os caracteres menos os numeros
        let telefone = control.value.replace(/\D/g, '');

        //verifica se tem a qtde de numero correto
        if (!(telefone.length >= 10 && telefone.length <= 11)) return { 'invalidPhone': true };

        //Se tiver 11 caracteres, verificar se começa com 9 o celular
        if (telefone.length == 11 && parseInt(telefone.substring(2, 3)) != 9) return { 'invalidPhone': true };

        //verifica se não é nenhum numero digitado errado (propositalmente)
        for (var n = 0; n < 10; n++) {
            if (telefone == new Array(11).join(n.toString()) || telefone == new Array(12).join(n.toString())) return { 'invalidPhone': true };
        }
        //DDDs validos
        var codigosDDD = [11, 12, 13, 14, 15, 16, 17, 18, 19,
            21, 22, 24, 27, 28, 31, 32, 33, 34,
            35, 37, 38, 41, 42, 43, 44, 45, 46,
            47, 48, 49, 51, 53, 54, 55, 61, 62,
            64, 63, 65, 66, 67, 68, 69, 71, 73,
            74, 75, 77, 79, 81, 82, 83, 84, 85,
            86, 87, 88, 89, 91, 92, 93, 94, 95,
            96, 97, 98, 99];
        //verifica se o DDD é valido (sim, da pra verificar rsrsrs)
        if (codigosDDD.indexOf(parseInt(telefone.substring(0, 2))) == -1) return { 'invalidPhone': true };
        if (new Date().getFullYear() < 2017) return null;
        if (telefone.length == 10 && [2, 3, 4, 5, 7].indexOf(parseInt(telefone.substring(2, 3))) == -1) return { 'invalidPhone': true };
        return null;
    }
}

export function CellPhoneValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const phoneNumber = control.value;
        const regex = /^\d{2}9\d{4,5}\d{4}$/;

        if (!phoneNumber || !regex.test(phoneNumber))
            return { 'invalidPhone': true };

        return null;
    };
}