export const environment = {
  production: false,
  staging: false,
  requestTimeOut: 10000,
  requestTimeOut30: 30000,
  requestRetry: 2,
  googleClientId: "293710484941-iqtaa9bln4gs99fbpgfr4rqd6k9lk90q.apps.googleusercontent.com",
  microsoftClientId: "5c004e1b-3e1b-4f36-879d-b896d475a430",
  hashEmailSmsAuthorization: "960d5b108784b4f20f38d79dcacb1fda",
  hashBusinessIntelligenceAuthorization: "d41d8cd98f00b204e9800998ecf8427e",
  hashTotemAuthorization: "ba7e554ba77be4d83d2620b2da49b7c5",
  idManchesterV2Protocol: 1,
  bluetoothAPIExecutableName: 'medidores_ble_tolife',
  urlApiBluetooth: "http://localhost:35500",
  urlScriptMemed: "https://integrations.memed.com.br/modulos/plataforma.sinapse-prescricao/build/sinapse-prescricao.min.js",
  urlApiBase: "https://api.clusterdev.tolife.app/",
  urlApiBaseUI: "https://gpa.clusterdev.tolife.app/",
  urlBaseUIDevice: "http://34.160.93.244/",
  urlApiUser: "https://api.clusterdev.tolife.app/tolifecloud-api-user/",
  urlApiAuth: "https://api.clusterdev.tolife.app/tolifecloud-api-auth/",
  urlApiSrvEmail: "https://api.clusterdev.tolife.app/tolifecloud-api-srvemail/",
  urlApiFlow: "https://api.clusterdev.tolife.app/tolifecloud-api-flow/",
  urlApiTotem: "https://api.clusterdev.tolife.app/tolifecloud-api-totem/",
  urlApiPanel: "https://api.clusterdev.tolife.app/tolifecloud-api-panel/",
  urlWebsocket: "wss://api.clusterdev.tolife.app/",
  urlApiOrchestratorTotem: "https://api.clusterdev.tolife.app/tolifecloud-api-orchestrator-totem/",
  urlApiOrchestratorQueue: "https://api.clusterdev.tolife.app/tolifecloud-api-orchestrator-queue/",
  urlApiOrchestratorPanel: "https://api.clusterdev.tolife.app/tolifecloud-api-orchestrator-panel/",
  urlApiQueue: "https://api.clusterdev.tolife.app/tolifecloud-api-queue/",
  urlApiFrontDesk: "https://api.clusterdev.tolife.app/tolifecloud-api-frontdesk/",
  urlApiPharmacy: "https://api.clusterdev.tolife.app/tolifecloud-api-pharmacy/",
  urlApiMedicalRecord: "https://api.clusterdev.tolife.app/tolifecloud-api-medical-record/",
  urlApiSADT: "https://api.clusterdev.tolife.app/tolifecloud-api-sadt/",
  urlApiTotemPrinter: "http://localhost:49577/",
  urlApiOrchestratorPatient: "https://api.clusterdev.tolife.app/tolifecloud-api-orchestrator-patient/",
  urlApiOrchestratorSADT: "https://api.clusterdev.tolife.app/tolifecloud-api-orchestrator-sadt/",
  urlApiRiskClassification: "https://api.clusterdev.tolife.app/tolifecloud-api-risk-classification/",
  urlApiIntegration: "https://api.clusterdev.tolife.app/tolifecloud-api-integration/",
  urlApiAudit: "https://api.clusterdev.tolife.app/tolifecloud-api-audit/",
  urlApiOrchestratorAudit: "https://api.clusterdev.tolife.app/tolifecloud-api-orchestrator-audit/",
  urlApiBed: "https://api.clusterdev.tolife.app/tolifecloud-api-bed/",
  urlApiSyncMeasurer: "https://api.clusterdev.tolife.app/tolifecloud-api-sync-measurer/",
  urlApiOrchestratorForm: "https://api.clusterdev.tolife.app/tolifecloud-api-orchestrator-form/",
  urlApiForm: "https://api.clusterdev.tolife.app/tolifecloud-api-form/",
  urlApiBilling: "https://api.clusterdev.tolife.app/tolifecloud-api-billing/",
  urlApiOrchestratorBilling: "https://api.clusterdev.tolife.app/tolifecloud-api-orchestrator-billing/",
  urlApiOrchestratorSchedule: "https://api.clusterdev.tolife.app/tolifecloud-api-orchestrator-schedule/",
  urlApiBusinessIntelligence: "https://api.clusterdev.tolife.app/tolifecloud-api-business-intelligence/",
  urlApiOrchestratorBusinessIntelligence: "https://api.clusterdev.tolife.app/tolifecloud-api-orchestrator-business-intelligence/",
  urlApiReport: "https://api.clusterdev.tolife.app/tolifecloud-api-report/",
  urlApiBatch: "https://api.clusterdev.tolife.app/tolifecloud-api-batch/",
  urlApiTestNetwork: "https://api.clusterdev.tolife.app/tolifecloud-api-test-network/",
  urlApiNotification: "https://api.clusterdev.tolife.app/tolifecloud-api-notification/",
  urlApiTelephoneClassification: "https://api.clusterdev.tolife.app/tolifecloud-api-telephone-classification/",
  urlApiOrchestratorClassification: "https://api.clusterdev.tolife.app/tolifecloud-api-orchestrator-classification/",
  urlApiDataIntelligence: "https://api.clusterdev.tolife.app/tolifecloud-api-data-intelligence/",
  urlApiOrchestratorDataIntelligence: "https://api.clusterdev.tolife.app/tolifecloud-api-orchestrator-data-intelligence/",
  urlApiMedic: "https://api.clusterdev.tolife.app/tolifecloud-api-medic/",
  urlApiSelfTriage: "https://api.clusterdev.tolife.app/tolifecloud-api-self-triage/",
  urlApiTelemedicine: "https://api.clusterdev.tolife.app/tolifecloud-api-telemedicine/",
  urlApiMultiProfessional: "https://api.clusterdev.tolife.app/tolifecloud-api-multi-professional/",
  urlBasePatientUI: "https://patient.clusterdev.tolife.app/",
  urlApiGamification: "https://api.clusterdev.tolife.app/tolifecloud-api-gamification/",
  urlApiOrchestratorTelemedicine: "https://api.clusterdev.tolife.app/tolifecloud-api-orchestrator-telemedicine/",
  urlApiOrchestratorUser: "https://api.clusterdev.tolife.app/tolifecloud-api-orchestrator-user/",
  urlApiUbsHealthPromotion: "https://api.clusterdev.tolife.app/tolifecloud-api-ubs-health-promotion/",
  urlApiIntegrationSiga: "https://api.clusterdev.tolife.app/tolifecloud-api-integration-siga/",
  urlApiBillingByHealthUnit: "https://api.clusterdev.tolife.app/tolifecloud-api-billing-by-health-unit/",
  urlApiIntegrationRelationConfig: "https://api.clusterdev.tolife.app/tolifecloud-api-integration-relation-config/",
  urlApiOrchestratorBillingByHealthUnit: "https://api.clusterdev.tolife.app/tolifecloud-api-orchestrator-billing-by-health-unit/",
  urlApiSrvLog: "https://api.clusterdev.tolife.app/tolifecloud-api-srvlog/",
  urlApiCareLine: "https://api.clusterdev.tolife.app/tolifecloud-api-care-line/",
  urlApiNps: "https://api.clusterdev.tolife.app/tolifecloud-api-nps/",
  urlApiCsat: "https://api.clusterdev.tolife.app/tolifecloud-api-csat/",
  urlApicareLinePriority: "https://api.clusterdev.tolife.app/tolifecloud-api-care-line-priority/",
  urlApiSchedule: "https://api.clusterdev.tolife.app/tolifecloud-api-schedule/",
  urlApiDigitalPrompt: "https://api.clusterdev.tolife.app/tolifecloud-api-digital-prompt/",
  urlApiPatientCenter: "https://api.clusterdev.tolife.app/tolifecloud-api-patient-center/",
  urlApiAdminUser: "https://api.clusterdev.tolife.app/tolifecloud-api-admin-user/",
  urlApiMetaData: "https://api.clusterdev.tolife.app/tolifecloud-api-meta-data/",
  urlApiOrchestratorIntegration: "https://api.clusterdev.tolife.app/integration/",
  urlApiPanelLeanConfig: "https://api.clusterdev.tolife.app/tolifecloud-api-panel-lean-config/",
  urlApiHospitalDocument: "https://api.clusterdev.tolife.app/tolifecloud-api-hospital-document/",
  authorizationHash: "c9aE5c0809BCe6c7598Fd6D174dB5A5a",
  urlApiAlertPolicy: "https://api.clusterdev.tolife.app/tolifecloud-api-alert-policy/",
  urlApiDigitalSignature: "https://api.clusterdev.tolife.app/tolifecloud-api-digital-signature/",
  urlSafeId: "https://pscsafeweb.safewebpss.com.br/",
  urlApiPrivateBilling: "https://api.clusterdev.tolife.app/tolifecloud-api-private-billing/",
  urlApiVirtualTriage: "https://api.clusterdev.tolife.app/tolifecloud-api-virtual-triage/",
  urlApiChangelog: "https://api.clusterdev.tolife.app/tolifecloud-api-changelog/",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
