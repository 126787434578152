import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ConfirmModalComponent>) { }

  ngOnInit(): void {
  }

  clickPrimary() {
    this.matDialogRef.close({ clickPrimary: true });
  }

  clickAccent() {
    this.matDialogRef.close({ clickAccent: true });
  }
}