import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { GetAllergyResponse } from '../../responses/medical-record/get-allergy.response';
import { ReportResponse } from '../../responses/orchestrator-patient/report.response';


@Injectable({
  providedIn: 'root'
})
export class DownloadReportService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public downloadReport(idEpisode: number, reportName: string): Observable<ReportResponse> {
    let uri = `DownloadReport/idEpisode/${idEpisode}/reportName/${reportName}`;
    return this.httpClient.get<ReportResponse>(environment.urlApiMedicalRecord + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public downloadAihReport(idEpisode: number): Observable<ReportResponse> {
    let uri = `DownloadReport/GetAihReport/idEpisode/${idEpisode}`;
    return this.httpClient.get<ReportResponse>(environment.urlApiMedicalRecord + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public downloadCompleteReport(idEpisode: number): Observable<ReportResponse> {
    let uri = `DownloadCompleteEpisodeReport/idEpisode/${idEpisode}`;

    return this.httpClient.get<ReportResponse>(environment.urlApiMedicalRecord + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
