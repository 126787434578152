import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsClassificationService } from 'src/app/classification/utils.service';
import { PainEnum } from 'src/app/shared/enum/pain.enum';
import { DiscriminatorModel } from 'src/app/shared/services/models/risk-classification/discriminator.model';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-pain-modal',
  templateUrl: './pain-modal.component.html',
  styleUrls: ['./pain-modal.component.css']
})
export class PainModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<PainModalComponent>,
    private utilClassificationService: UtilsClassificationService,
    private utilService: UtilService) { }

  public painEnum = PainEnum;
  public discriminator: DiscriminatorModel;
  public age: number;
  public classification: any;

  ngOnInit(): void {
    this.classification = this.utilService.getSecundaryHeaderModuleConfig(this.data.cachedModuleName);
    this.age = this.data.age;
    this.discriminator = this.data.discriminator;
  }

  close() {
    this.matDialogRef.close();
  }

  selectValue(description: PainEnum, value: number, painColorCode: string = null) {
    let select = this.utilClassificationService.verifySelectPain(this.discriminator, value, description);
    this.matDialogRef.close({ value, description, select, painColorCode });
  }
}

