import { Injectable } from "@angular/core";
import { CommonService } from "../../common-service";
import { environment } from "src/environments/environment";
import { Observable, catchError } from "rxjs";
import { ReturnStruct } from "src/app/shared/structs/return.struct";
import { HttpClient } from "@angular/common/http";
import { ListDigitalSignatureResponse } from "../../responses/digital-signature/list-digital-signature.response";

@Injectable({
    providedIn: 'root'
})
export class DigitalSignatureService extends CommonService {
    constructor(private httpClient: HttpClient) { super(); }

    public getListSignature(): Observable<ListDigitalSignatureResponse> {
        let uri = `DigitalSignature`;

        return this.httpClient.get<ListDigitalSignatureResponse>(environment.urlApiDigitalSignature + uri, this.addHeaderToken())
            .pipe(catchError(this.handleError));
    }

    public deleteIntegration(idSignatureProvider: number): Observable<ReturnStruct> {
        let uri = `DigitalSignature/idSignatureProvider/${idSignatureProvider}`;

        return this.httpClient.delete<ReturnStruct>(environment.urlApiDigitalSignature + uri, this.addHeaderToken())
            .pipe(catchError(this.handleError));
    }
}