import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ListBedResponse } from '../../responses/bed/list-bed-response.response';

@Injectable({
  providedIn: 'root'
})
export class FreeBedService extends CommonService {

  constructor(private httpClient: HttpClient) { super(); }

  public listFreeBed(idSector: number = null, hygienization: boolean = false, idEpisode: number = null): Observable<ListBedResponse> {

    let uri = 'FreeBed?';

    if (idSector)
      uri += `idSector=${idSector}`;

    if (hygienization)
      uri += `&hygienization=${hygienization}`;

    if (idEpisode)
      uri += `&idEpisode=${idEpisode}`;

    return this.httpClient.get<ListBedResponse>(environment.urlApiBed + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}