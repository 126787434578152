import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Cbo } from 'src/app/shared/services/models/private-billing/cbo.model';
import { ProfessionalCouncil } from 'src/app/shared/services/models/private-billing/professional-council.model';
import { State } from 'src/app/shared/services/models/private-billing/state.model';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { GuideStatus } from 'src/app/shared/services/models/private-billing/guide-status.model';
import { AccidentIndication } from 'src/app/shared/services/models/private-billing/accident-indication.model';
import { HealthcareAgreementStruct } from 'src/app/shared/services/structs/private-billing/healthcare-agreement.struct';
import { TerminologyGroup } from 'src/app/shared/services/models/private-billing/terminology-group.model';
import { ProcedureStruct } from 'src/app/shared/services/structs/private-billing/procedure.struct';
import { AppointmentType } from 'src/app/shared/services/models/private-billing/appointment-type.model';
import { ProfessionalAgreementStruct } from 'src/app/shared/services/structs/private-billing/professional-agreement.struct';
import { HealthcareAgreementService } from 'src/app/shared/services/API/private-billing/healthcare-agreement.service';
import { TissBilling } from 'src/app/shared/services/models/private-billing/tiss-billing.model';
import { GuideTypeEnum } from 'src/app/shared/enum/private-billing/guide-type.enum';
import { AppointmentRequiredField } from 'src/app/shared/services/models/private-billing/appointment-required-field.model';
import { LookupAppointmentRequiredFieldEnum } from 'src/app/shared/enum/private-billing/lookup-appointment-required-field.enum';
import { GetProceduresRequest } from 'src/app/shared/services/requests/private-billing/get-procedures.request';
import { ProcedureService } from 'src/app/shared/services/API/private-billing/procedure.service';
import { SpecialCoverage } from 'src/app/shared/services/models/private-billing/special-coverage.model';
import { CareRegime } from 'src/app/shared/services/models/private-billing/care-regime.model';
import { OccupationalHealth } from 'src/app/shared/services/models/private-billing/occupational-health.model';
import { AppointmentGuideRequest } from 'src/app/shared/services/requests/private-billing/appointment-guide.request';
import { AppointmentGuideService } from 'src/app/shared/services/API/private-billing/appointment-guide.service';
import { AppointmentGuideStruct } from 'src/app/shared/services/structs/private-billing/appointment-guide.struct';
import { ReportModalComponent } from '../../report-modal/report-modal.component';
import { GuideStatusEnum } from 'src/app/shared/enum/private-billing/guide-status.enum';
import { environment } from 'src/environments/environment';
import { ProviderIdentityType } from 'src/app/shared/services/models/private-billing/provider-identity-type.model';
import { ProviderIdentityTypeEnum } from 'src/app/shared/enum/private-billing/provider-identity-type.enum';
import { VerifyCNPJ } from 'src/app/shared/custom-validators/cnpj.validator';
import { VerifyCPF } from 'src/app/shared/custom-validators/cpf.validator';
import { AppointmentFieldsMaxLengthEnum } from 'src/app/shared/enum/private-billing/appointment-fields-max-length.enum';
import { DateValidator } from 'src/app/shared/custom-validators/date.validator';
import { GuideRequest } from 'src/app/shared/services/requests/private-billing/guide.request';
import { OrchestratorPatientGuideService } from 'src/app/shared/services/API/orchestrator-patient/orchestrator-patient-guide.service';
import { GuideService } from 'src/app/shared/services/API/private-billing/guide.service';
import { GuideReportResponse } from 'src/app/shared/services/responses/orchestrator-patient/guide-report.response';
import { AlertModalComponent } from '../../alert-modal/alert-modal.component';
import { WebsocketPrivateBillingUtilService } from 'src/app/shared/services/websocket-util/websocket-private-billing-util.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { TerminologyGroupEnum } from 'src/app/shared/enum/private-billing/terminology-group.enum';
import { CheckHasRestrictionsRequest } from 'src/app/shared/services/requests/private-billing/check-has-restriction.request';
import { CheckHasRestrictionService } from 'src/app/shared/services/API/orchestrator-billing/episode.service';

@Component({
  selector: 'app-tiss-guide-appointment',
  templateUrl: './tiss-guide-appointment.component.html',
  styleUrls: ['./tiss-guide-appointment.component.css']
})
export class TissGuideAppointmentComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<TissGuideAppointmentComponent>,
    private alertService: AlertService,
    private maskService: MaskService,
    private formBuilder: FormBuilder,
    public procedureService: ProcedureService,
    private healthcareAgreementService: HealthcareAgreementService,
    private appointmentGuideService: AppointmentGuideService,
    private orchestratorPatientGuideService: OrchestratorPatientGuideService,
    private guideService: GuideService,
    private websocketPrivateBillingUtilService: WebsocketPrivateBillingUtilService,
    private authService: AuthService,
    private checkHasRestrictionService: CheckHasRestrictionService
  ) { matDialogRef.disableClose = true; }

  public masks: Masks = this.maskService.getMasks();
  public model: FormGroup;
  public tissBilling: TissBilling = new TissBilling();
  public healthcareAgreementStruct: HealthcareAgreementStruct = new HealthcareAgreementStruct();
  public procedureStruct: ProcedureStruct;
  public appointmentGuideStruct: AppointmentGuideStruct;
  public appointmentGuideRequest: AppointmentGuideRequest;

  public listProfessionalCouncil: ProfessionalCouncil[] = this.data.lookups.listProfessionalCouncil;
  public listState: State[] = this.data.lookups.listState;
  public listCbo: Cbo[] = this.data.lookups.listCbo;
  public listAppointmentType: AppointmentType[] = this.data.lookups.listAppointmentType;
  public listAccidentIndication: AccidentIndication[] = this.data.lookups.listAccidentIndication;
  public listGuideStatus: GuideStatus[] = this.data.lookups.listGuideStatus;
  public listProviderIdentityType: ProviderIdentityType[] = this.data.lookups.listProviderIdentityType;
  public listTussTerminologyGroup: TerminologyGroup[] = this.data.lookups.listTussTerminologyGroup;
  public listSpecialCoverage: SpecialCoverage[] = this.data.lookups.listSpecialCoverage;
  public listCareRegime: CareRegime[] = this.data.lookups.listCareRegime;
  public listOccupationalHealth: OccupationalHealth[] = this.data.lookups.listOccupationalHealth;
  public listProfessionalAgreementStruct: ProfessionalAgreementStruct[] = [];
  public listProcedureCode: ProcedureStruct[] = [];
  public listAppointmentRequiredField: AppointmentRequiredField[] = [];

  public requesterCodeMask: string = '';
  public urlListAnsRegistration: string = environment.urlApiPrivateBilling + 'HealthcareAgreement/getAllByHealthUnit';;

  public isLoading: boolean = true;
  public onAnsChangeCalled: boolean = false;
  public existsGuide: boolean = false;
  public isEditingAllowed: boolean = true;

  public idGuide: number = null;
  public idEpisode: number = null;
  public idAppointmentGuide: number = null;
  public idSector: number = null;
  public idRoom: number = null;
  public idHealthCareAgreement: number = null;
  public guideNumber: string = null;
  public idProcedure: number = null;
  public isAttendance: boolean;

  public guideStatusEnum: GuideStatusEnum;
  public lookupAppointmentRequiredFieldEnum: LookupAppointmentRequiredFieldEnum;
  public appointmentFieldsMaxLengthEnum: typeof AppointmentFieldsMaxLengthEnum = AppointmentFieldsMaxLengthEnum;
  public guideType: number = GuideTypeEnum.consulta;
  public requesterCodeMaxLength: number = AppointmentFieldsMaxLengthEnum.requesterCodeOperator;
  public guideStatus: GuideStatus = null;
  public guideStatusColor: string = null;
  public procedimentos: TerminologyGroupEnum = TerminologyGroupEnum.procedimentos;

  private throwException(error: string) {
    console.log(error);
    this.alertService.show('Erro inesperado', error, AlertType.error);
    this.isLoading = false;
  }

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      firstChildGroup: this.formBuilder.group({
        professional: ['', [Validators.required]],
        idHealthcareAgreement: ['', [Validators.required]],
      }),
      guideNumber: ['1', [Validators.required]],
      guideOperatorNumber: [''],
      idGuideStatus: [null, [Validators.required]],
      beneficiaryCardNumber: ['', [Validators.required]],
      walletBeneficiaryValidity: [null, [DateValidator()]],
      newborn: [false, [Validators.required]],
      beneficiaryName: ['', [Validators.required]],
      beneficiarySocialName: [''],
      executionContractorOperatorCode: ['', [Validators.required]],
      contractorName: ['', [Validators.required]],
      contractorCNES: ['', [Validators.required]],
      idProfessionalAgreement: [null],
      professionalName: [''],
      idProfessionalCounseling: [null, [Validators.required]],
      counselingNumber: ['', [Validators.required]],
      idProfessionalUf: [null, [Validators.required]],
      idProfessionalCbo: [null, [Validators.required]],
      idAccidentIndication: [null, [Validators.required]],
      idSpecialCoverage: [null],
      idCareRegime: [null, [Validators.required]],
      idOccupationalHealth: [null],
      completionDate: [null, [Validators.required, DateValidator()]],
      idAppointmentType: [null, [Validators.required]],
      idTerminologyGroup: [null, [Validators.required]],
      idProcedure: [null, [Validators.required]],
      idPriceProcedureHealthcare: [null],
      idPriceProcedurePlan: [null],
      procedureCode: [''],
      procedureDescription: [''],
      procedureValue: ['0,00', [Validators.required]],
      justification: [''],
      idProviderIdentityType: ['', [Validators.required]],
    });
    this.disableFields();

    if (this.data.guide != null) {
      this.existsGuide = true;
      this.appointmentGuideStruct = this.data.guide;
      this.isAttendance = this.data.isAttendance;

      if (this.data.isComingGuideReport)
        this.isEditingAllowed = this.appointmentGuideStruct.isEditingAllowed;

      this.populateData();
    }
    else {
      this.model.get('idGuideStatus').setValue(GuideStatusEnum.NovaGuia.valueOf());
      this.onGuideStatusChange(GuideStatusEnum.NovaGuia.valueOf())
      if (this.data.patientName)
        this.model.get('beneficiaryName').setValue(this.data.patientName)
      if (this.data.patientSocialName)
        this.model.get('beneficiarySocialName').setValue(this.data.patientSocialName)

      this.isLoading = false;
    }

    this.idEpisode = this.data.idEpisode;
    this.idSector = this.data.idSector
    this.idRoom = this.data.idRoom
    if (this.idEpisode)
      this.startWebsocket();
  }

  close() {
    this.matDialogRef.close();
  }

  disableFields() {
    this.model.disable();
    this.model.get('firstChildGroup').get('idHealthcareAgreement').enable();
  }

  enableFields() {
    Object.keys(this.model.controls).forEach(control => {
      if (control != 'idHealthcareAgreement' &&
        control != 'idProfessionalCounseling' && control != 'counselingNumber' &&
        control != 'idProfessionalUf' && control != 'idProfessionalCbo' &&
        control != 'idProcedure' && control != 'procedureDescription' &&
        control != 'procedureValue' && control != 'guideNumber') {
        this.model.get(control).enable();
      }
    });
  }

  onAnsChange(event?: any) {
    if (!this.model.get('firstChildGroup').get('idHealthcareAgreement').value) {
      this.disableFields();
      return;
    }

    this.idHealthCareAgreement = this.model.get('firstChildGroup').get('idHealthcareAgreement').value;
    this.model.reset();
    this.model.get('firstChildGroup').get('idHealthcareAgreement').setValue(this.idHealthCareAgreement);
    this.model.get('idGuideStatus').setValue(GuideStatusEnum.NovaGuia.valueOf());
    this.onGuideStatusChange(GuideStatusEnum.NovaGuia.valueOf())
    this.model.get('newborn').setValue(false);
    this.model.get('guideNumber').setValue(this.guideNumber);
    this.listProfessionalAgreementStruct = [];
    this.isLoading = true;
    if (this.data.patientName)
      this.model.get('beneficiaryName').setValue(this.data.patientName)
    if (this.data.patientSocialName)
      this.model.get('beneficiarySocialName').setValue(this.data.patientSocialName)
    let idHealthcareAgreementForSearch = event ? event.idHealthcareAgreement : this.model.get('firstChildGroup').get('idHealthcareAgreement').value;

    this.healthcareAgreementService.getAllSelect(idHealthcareAgreementForSearch, this.guideType).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.healthcareAgreementStruct = response.healthcareAgreementStruct;

        this.tissBilling = response.tissBilling;

        if (this.tissBilling)
          this.fillContractorData();

        this.listProfessionalAgreementStruct = response.listProfessionalAgreementStruct.filter(x => x.isActive || (this.existsGuide && x.idProfessionalAgreement == this.appointmentGuideStruct.appointmentGuide.idProfessionalAgreement));

        if (!this.listProfessionalAgreementStruct || this.listProfessionalAgreementStruct.length == 0) {
          this.openAlertModal();
        }
        this.listAppointmentRequiredField = response.listAppointmentRequiredField;

        if (response.guideNumber && !this.existsGuide) {
          this.guideNumber = response.guideNumber;
          this.model.get('guideNumber').setValue(response.guideNumber);
        }

        if (response.listAppointmentRequiredField)
          this.verifyRequiredFields();

        this.enableFields();

        this.isLoading = false;
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  verifyRequiredFields() {
    this.listAppointmentRequiredField.forEach(x => {
      if (x.idLookupAppointmentRequiredField == LookupAppointmentRequiredFieldEnum.NumeroDaGuiaOperadora) {
        this.model.get('guideOperatorNumber').setValidators([Validators.required]);
        this.model.get('guideOperatorNumber').updateValueAndValidity();
      }
      else if (x.idLookupAppointmentRequiredField == LookupAppointmentRequiredFieldEnum.ValidadeDaCarteira) {
        this.model.get('walletBeneficiaryValidity').setValidators([Validators.required, DateValidator()]);
        this.model.get('walletBeneficiaryValidity').updateValueAndValidity();
      }
      else if (x.idLookupAppointmentRequiredField == LookupAppointmentRequiredFieldEnum.Justificativa) {
        this.model.get('justification').setValidators([Validators.required]);
        this.model.get('justification').updateValueAndValidity();
      }
      else if (x.idLookupAppointmentRequiredField == LookupAppointmentRequiredFieldEnum.CoberturaEspecial) {
        this.model.get('idSpecialCoverage').setValidators([Validators.required]);
        this.model.get('idSpecialCoverage').updateValueAndValidity();
      }
      else if (x.idLookupAppointmentRequiredField == LookupAppointmentRequiredFieldEnum.SaudeOperacional) {
        this.model.get('idOccupationalHealth').setValidators([Validators.required]);
        this.model.get('idOccupationalHealth').updateValueAndValidity();
      }
    });
  }

  fillContractorData() {
    this.model.get('contractorName').setValue(this.tissBilling.providerName);
    this.model.get('contractorCNES').setValue(this.tissBilling.cnes);
    this.model.get('executionContractorOperatorCode').setValue(this.tissBilling.providerCode);
    this.model.get('idProviderIdentityType').setValue(this.tissBilling?.idProviderIdentityType);

    if (this.model.get('idProviderIdentityType'))
      this.checkProviderIdentityType(this.tissBilling?.idProviderIdentityType);
  }

  selectTable() {
    this.model.get('idProcedure').enable();
    this.model.get('idProcedure').setValue(null);
    this.model.get('procedureDescription').setValue(null);
    this.model.get('procedureValue').setValue('0,00');
    this.model.get('idPriceProcedureHealthcare').setValue(null);
    this.model.get('procedureCode').setValue(null);
    this.procedureStruct = null;
    this.getProcedures();
  }

  getProcedures() {
    let request: GetProceduresRequest = {
      key: '',
      idHealthcareAgreement: this.healthcareAgreementStruct.idHealthcareAgreement,
      idTerminologyGroup: this.model.get('idTerminologyGroup').value,
      isCreate: this.idAppointmentGuide == null ? true : false,
      listIdProcedure: Number.isSafeInteger(this.model.get('idProcedure').value) ? [this.model.get('idProcedure').value] : []
    }

    this.procedureService.GetByTerminologyGroup(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.listProcedureCode = response.listProcedureStruct;
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  onProfessionalChange(professional: ProfessionalAgreementStruct) {
    if (this.existsGuide)
      this.model.get('firstChildGroup').get('professional').setValue(professional.idProfessionalAgreement);

    this.model.get('idProfessionalAgreement').setValue(professional.idProfessionalAgreement);
    this.model.get('professionalName').setValue(professional.nameProfessional);
    this.model.get('idProfessionalCounseling').setValue(professional.idProfessionalCouncil);
    this.model.get('counselingNumber').setValue(professional.numberProfessional);
    this.model.get('idProfessionalUf').setValue(professional.idState);
    this.model.get('idProfessionalCbo').setValue(professional.idCbo);
  }

  onProcedureChange(idProcedure: number) {
    this.idProcedure = idProcedure;

    this.procedureStruct = this.listProcedureCode.find(x => x.idProcedure == idProcedure);
    this.model.get('procedureDescription').setValue(this.procedureStruct.descriptionProcedure);
    this.model.get('procedureValue').setValue(this.procedureStruct.value);
    this.model.get('procedureValue').enable();
    this.model.get('idPriceProcedureHealthcare').setValue(this.procedureStruct.idLastPriceProcedureHealthcare);
    this.model.get('procedureCode').setValue(this.procedureStruct.terminology.terminologyCodeTuss);

    if (this.data && this.data.idPatient)
      this.checkHasRestriction();
  }

  populateData() {
    this.idGuide = this.appointmentGuideStruct.guide.idGuide;
    this.idEpisode = this.appointmentGuideStruct.guide.idEpisode;
    this.idAppointmentGuide = this.appointmentGuideStruct.guide.idAppointmentGuide;
    this.idHealthCareAgreement = this.appointmentGuideStruct.guide.idHealthcareAgreement;

    this.guideNumber = this.appointmentGuideStruct.guideNumber;
    this.model.get('guideNumber').setValue(this.appointmentGuideStruct.guideNumber);

    this.model.get('idGuideStatus').setValue(this.appointmentGuideStruct.guide.idGuideStatus);
    this.onGuideStatusChange(this.appointmentGuideStruct.guide.idGuideStatus)
    this.model.get('firstChildGroup').get('idHealthcareAgreement').setValue(this.appointmentGuideStruct.guide.idHealthcareAgreement);
    this.model.get('beneficiaryName').setValue(this.appointmentGuideStruct.guide.patientName);
    this.model.get('beneficiarySocialName').setValue(this.appointmentGuideStruct.guide.patientSocialName);

    if (this.appointmentGuideStruct.appointmentGuide.walletBeneficiaryValidity != null)
      this.model.get('walletBeneficiaryValidity').setValue(this.maskService.formatDateToString(this.appointmentGuideStruct.appointmentGuide.walletBeneficiaryValidity, false));
    this.model.get('guideOperatorNumber').setValue(this.appointmentGuideStruct.appointmentGuide.guideOperatorNumber);
    this.model.get('beneficiaryCardNumber').setValue(this.appointmentGuideStruct.appointmentGuide.beneficiaryCardNumber);
    this.model.get('newborn').setValue(this.appointmentGuideStruct.appointmentGuide.newborn);
    this.model.get('executionContractorOperatorCode').setValue(this.appointmentGuideStruct.appointmentGuide.executionContractorOperatorCode);
    this.model.get('contractorName').setValue(this.appointmentGuideStruct.appointmentGuide.contractorName);
    this.model.get('contractorCNES').setValue(this.appointmentGuideStruct.appointmentGuide.contractorCnes);
    this.model.get('idProfessionalAgreement').setValue(this.appointmentGuideStruct.appointmentGuide.idProfessionalAgreement);
    this.model.get('professionalName').setValue(this.appointmentGuideStruct.appointmentGuide.professionalName);
    this.model.get('idProfessionalCounseling').setValue(this.appointmentGuideStruct.appointmentGuide.idProfessionalCounseling);
    this.model.get('counselingNumber').setValue(this.appointmentGuideStruct.appointmentGuide.counselingNumber);
    this.model.get('idProfessionalUf').setValue(this.appointmentGuideStruct.appointmentGuide.idProfessionalUf);
    this.model.get('idProfessionalCbo').setValue(this.appointmentGuideStruct.appointmentGuide.idProfessionalCbo);
    this.model.get('idAccidentIndication').setValue(this.appointmentGuideStruct.appointmentGuide.idAccidentIndication);
    this.model.get('idSpecialCoverage').setValue(this.appointmentGuideStruct.appointmentGuide.idSpecialCoverage);
    this.model.get('idCareRegime').setValue(this.appointmentGuideStruct.appointmentGuide.idCareRegime);
    this.model.get('idOccupationalHealth').setValue(this.appointmentGuideStruct.appointmentGuide.idOccupationalHealth);
    this.model.get('completionDate').setValue(this.maskService.formatDateToString(this.appointmentGuideStruct.appointmentGuide.completionDate, false));
    this.model.get('idAppointmentType').setValue(this.appointmentGuideStruct.appointmentGuide.idAppointmentType);
    this.model.get('idTerminologyGroup').setValue(this.appointmentGuideStruct.idTerminologyGroup);
    this.model.get('idPriceProcedureHealthcare').setValue(this.appointmentGuideStruct.appointmentGuide.idPriceProcedureHealthcare);
    this.model.get('idPriceProcedurePlan').setValue(this.appointmentGuideStruct.appointmentGuide.idPriceProcedurePlan);
    this.model.get('procedureCode').setValue(this.appointmentGuideStruct.appointmentGuide.procedureCode);
    this.model.get('procedureDescription').setValue(this.appointmentGuideStruct.appointmentGuide.procedureDescription);
    this.model.get('procedureValue').setValue(this.appointmentGuideStruct.appointmentGuide.procedureValue);
    this.model.get('justification').setValue(this.appointmentGuideStruct.appointmentGuide.justification);
    this.model.get('idProviderIdentityType').setValue(this.appointmentGuideStruct.appointmentGuide.idProviderIdentityType);
    this.model.get('idProcedure').setValue(this.appointmentGuideStruct.idProcedure);
    this.populateAnsSelects();
  }

  populateAnsSelects() {
    this.healthcareAgreementService.getAllSelect(this.appointmentGuideStruct.guide.idHealthcareAgreement, this.guideType).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.healthcareAgreementStruct = response.healthcareAgreementStruct;

        this.tissBilling = response.tissBilling;

        this.listProfessionalAgreementStruct = response.listProfessionalAgreementStruct.filter(x => x.isActive || (this.existsGuide && x.idProfessionalAgreement == this.appointmentGuideStruct.appointmentGuide.idProfessionalAgreement));

        if (!this.listProfessionalAgreementStruct || this.listProfessionalAgreementStruct.length == 0)
          this.openAlertModal();

        this.listAppointmentRequiredField = response.listAppointmentRequiredField;

        if (response.listAppointmentRequiredField)
          this.verifyRequiredFields();

        if (this.appointmentGuideStruct.guide.idGuideStatus == GuideStatusEnum.AguardandoAutorizacao
          || this.appointmentGuideStruct.guide.idGuideStatus == GuideStatusEnum.NovaGuia) {
          this.enableFields();
        }

        this.onProfessionalChange(this.listProfessionalAgreementStruct.find(x => x.idProfessionalAgreement == this.appointmentGuideStruct.appointmentGuide.idProfessionalAgreement));
        this.getProcedures();

        if (this.data.isComingGuideReport) {
          if (this.appointmentGuideStruct.isEditingAllowed)
            this.model.enable();
          else if (!this.appointmentGuideStruct.isEditingAllowed)
            this.model.disable();
        }

        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        this.throwException(error);
      }
    });
  }

  submit() {
    if (this.isLoading)
      return;

    if (this.model.invalid) {
      this.model.markAllAsTouched();
      this.isLoading = false;
      this.alertService.show('Erro inesperado', "Preencha corretamente os campos obrigatórios", AlertType.error);
      return;
    }

    this.isLoading = true;

    this.populateRequest();

    if (this.data && !this.data.isAttendance)
      this.post();

    else {
      this.appointmentGuideRequest.appointmentGuideStruct.hasAlteration = true;
      this.validateRequest();
    }
  }

  print() {
    if (this.isLoading || !this.appointmentGuideStruct || !this.appointmentGuideStruct.guide || !this.appointmentGuideStruct.guide.idGuide)
      return;

    this.isLoading = true;

    this.guideService.GetGuideReportName(this.appointmentGuideStruct.guide.idGuide).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        let reportDialog = this.dialog.open(ReportModalComponent, {
          data: {
            reportPdf64: response.reportPdf64,
          },
        });

        this.isLoading = false;
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  post() {
    this.isLoading = true;

    let guideRequest: GuideRequest = new GuideRequest();
    guideRequest.appointmentGuideStruct = this.appointmentGuideRequest.appointmentGuideStruct;

    this.orchestratorPatientGuideService.PostAppointmentGuide(guideRequest).subscribe({
      next: (response: GuideReportResponse) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.alertService.show('Sucesso', "Guia salva com sucesso!", AlertType.success);
        this.isLoading = false;

        const dialogRef = this.dialog.open(ReportModalComponent, {
          data: {
            reportPdf64: response.appointmentGuideString64,
          },
        });
        dialogRef.afterClosed().subscribe({
          next: result => {
            this.matDialogRef.close({ confirm: true, appointmentGuideStruct: response.appointmentGuideStruct, isAttendance: this.data.isAttendance });
          }
        });
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      }
    });
  }

  validateRequest() {
    this.isLoading = true;

    this.appointmentGuideService.VerifyAppointmentGuide(this.appointmentGuideRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }
        this.isLoading = false;
        this.alertService.show('Sucesso', "Guia validada com sucesso!", AlertType.success);
        this.matDialogRef.close({ confirm: true, appointmentGuideStruct: this.appointmentGuideRequest.appointmentGuideStruct, isAttendance: this.data.isAttendance });
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  populateRequest() {
    this.appointmentGuideRequest = new AppointmentGuideRequest();
    this.appointmentGuideRequest.appointmentGuideStruct = new AppointmentGuideStruct();
    this.appointmentGuideRequest.appointmentGuideStruct.appointmentGuide.idAppointmentGuide = this.idAppointmentGuide ? this.idAppointmentGuide : 0;
    this.appointmentGuideRequest.appointmentGuideStruct.guide.idAppointmentGuide = this.idAppointmentGuide ? this.idAppointmentGuide : 0;
    this.appointmentGuideRequest.appointmentGuideStruct.guide.idGuide = this.idGuide ? this.idGuide : 0;
    this.appointmentGuideRequest.appointmentGuideStruct.guide.idEpisode = this.idEpisode ? this.idEpisode : 0;
    this.appointmentGuideRequest.appointmentGuideStruct.guide.idSector = this.idSector ? this.idSector : null;
    this.appointmentGuideRequest.appointmentGuideStruct.guide.idRoom = this.idRoom ? this.idRoom : null;
    this.appointmentGuideRequest.appointmentGuideStruct.guide.idHealthcareAgreement = this.model.get('firstChildGroup').get('idHealthcareAgreement').value;
    this.appointmentGuideRequest.appointmentGuideStruct.guide.guideNumber = this.model.get('guideNumber').value.toString();
    this.appointmentGuideRequest.appointmentGuideStruct.guideNumber = this.model.get('guideNumber').value.toString();
    this.appointmentGuideRequest.appointmentGuideStruct.appointmentGuide.idProviderIdentityType = this.model.get('idProviderIdentityType').value;

    if (this.model.get('guideOperatorNumber').value != null)
      this.appointmentGuideRequest.appointmentGuideStruct.appointmentGuide.guideOperatorNumber = this.model.get('guideOperatorNumber').value.toString();
    else
      this.appointmentGuideRequest.appointmentGuideStruct.appointmentGuide.guideOperatorNumber = null;

    this.appointmentGuideRequest.appointmentGuideStruct.guide.idGuideStatus = this.model.get('idGuideStatus').value;
    this.appointmentGuideRequest.appointmentGuideStruct.appointmentGuide.beneficiaryCardNumber = this.model.get('beneficiaryCardNumber').value.toString();

    if (this.model.get('walletBeneficiaryValidity').value != null)
      this.appointmentGuideRequest.appointmentGuideStruct.appointmentGuide.walletBeneficiaryValidity = this.maskService.formatStringToDate(this.model.get('walletBeneficiaryValidity').value);
    else
      this.appointmentGuideRequest.appointmentGuideStruct.appointmentGuide.walletBeneficiaryValidity = null;

    this.appointmentGuideRequest.appointmentGuideStruct.appointmentGuide.newborn = this.model.get('newborn').value;
    this.appointmentGuideRequest.appointmentGuideStruct.guide.patientName = this.model.get('beneficiaryName').value;

    if (this.model.get('beneficiarySocialName').value != null)
      this.appointmentGuideRequest.appointmentGuideStruct.guide.patientSocialName = this.model.get('beneficiarySocialName').value;
    else
      this.appointmentGuideRequest.appointmentGuideStruct.guide.patientSocialName = null;

    this.appointmentGuideRequest.appointmentGuideStruct.appointmentGuide.executionContractorOperatorCode = this.model.get('executionContractorOperatorCode').value;
    this.appointmentGuideRequest.appointmentGuideStruct.appointmentGuide.contractorName = this.model.get('contractorName').value;
    this.appointmentGuideRequest.appointmentGuideStruct.appointmentGuide.contractorCnes = this.model.get('contractorCNES').value;
    this.appointmentGuideRequest.appointmentGuideStruct.appointmentGuide.professionalName = this.model.get('professionalName').value;
    this.appointmentGuideRequest.appointmentGuideStruct.appointmentGuide.idProfessionalAgreement = this.model.get('idProfessionalAgreement').value;
    this.appointmentGuideRequest.appointmentGuideStruct.appointmentGuide.idProfessionalCounseling = this.model.get('idProfessionalCounseling').value;
    this.appointmentGuideRequest.appointmentGuideStruct.appointmentGuide.counselingNumber = this.model.get('counselingNumber').value.toString();
    this.appointmentGuideRequest.appointmentGuideStruct.appointmentGuide.idProfessionalUf = this.model.get('idProfessionalUf').value;
    this.appointmentGuideRequest.appointmentGuideStruct.appointmentGuide.idProfessionalCbo = this.model.get('idProfessionalCbo').value;
    this.appointmentGuideRequest.appointmentGuideStruct.appointmentGuide.idAccidentIndication = this.model.get('idAccidentIndication').value;
    this.appointmentGuideRequest.appointmentGuideStruct.appointmentGuide.idSpecialCoverage = this.model.get('idSpecialCoverage').value;
    this.appointmentGuideRequest.appointmentGuideStruct.appointmentGuide.idCareRegime = this.model.get('idCareRegime').value;
    this.appointmentGuideRequest.appointmentGuideStruct.appointmentGuide.idOccupationalHealth = this.model.get('idOccupationalHealth').value;
    this.appointmentGuideRequest.appointmentGuideStruct.appointmentGuide.completionDate = this.maskService.formatStringToDate(this.model.get('completionDate').value);
    this.appointmentGuideRequest.appointmentGuideStruct.appointmentGuide.idAppointmentType = this.model.get('idAppointmentType').value;
    this.appointmentGuideRequest.appointmentGuideStruct.idTerminologyGroup = this.model.get('idTerminologyGroup').value;
    this.appointmentGuideRequest.appointmentGuideStruct.appointmentGuide.idPriceProcedureHealthcare = this.model.get('idPriceProcedureHealthcare').value;
    this.appointmentGuideRequest.appointmentGuideStruct.appointmentGuide.idPriceProcedurePlan = this.model.get('idPriceProcedurePlan').value;
    this.appointmentGuideRequest.appointmentGuideStruct.appointmentGuide.procedureCode = this.model.get('procedureCode').value.toString();
    this.appointmentGuideRequest.appointmentGuideStruct.appointmentGuide.procedureDescription = this.model.get('procedureDescription').value;
    this.appointmentGuideRequest.appointmentGuideStruct.appointmentGuide.procedureValue = parseFloat(this.model.get('procedureValue').value);
    this.appointmentGuideRequest.appointmentGuideStruct.appointmentGuide.justification = this.model.get('justification').value;
    this.appointmentGuideRequest.appointmentGuideStruct.guide.ansCode = this.healthcareAgreementStruct.ansRegistrationNumber;
    this.appointmentGuideRequest.appointmentGuideStruct.idProcedure = this.model.get('idProcedure').value;
  }

  checkProviderIdentityType(idProviderIdentityType: number) {
    if (idProviderIdentityType == ProviderIdentityTypeEnum.cpf) {
      this.requesterCodeMask = this.masks.cpf;
      this.requesterCodeMaxLength = null;
      this.model.get('executionContractorOperatorCode').setValidators([Validators.required, Validators.maxLength(AppointmentFieldsMaxLengthEnum.requesterCodeOperatorCpf), VerifyCPF()]);
    }
    else if (idProviderIdentityType == ProviderIdentityTypeEnum.cnpj) {
      this.requesterCodeMask = this.masks.cnpj;
      this.requesterCodeMaxLength = null;
      this.model.get('executionContractorOperatorCode').setValidators([Validators.required, Validators.maxLength(AppointmentFieldsMaxLengthEnum.requesterCodeOperatorCnpj), VerifyCNPJ()]);
    }
    else if (idProviderIdentityType == ProviderIdentityTypeEnum.providerCode) {
      this.requesterCodeMask = '';
      this.requesterCodeMaxLength = AppointmentFieldsMaxLengthEnum.requesterCodeOperator;
      this.model.get('executionContractorOperatorCode').setValidators([Validators.required, Validators.maxLength(AppointmentFieldsMaxLengthEnum.requesterCodeOperatorCnpj)]);

      if (this.model.get('executionContractorOperatorCode') && this.model.get('executionContractorOperatorCode').value != null) {
        let cleanedValue = this.model.get('executionContractorOperatorCode').value.replace(/[.\-/]/g, '');
        this.model.get('executionContractorOperatorCode').setValue(cleanedValue);
      }

      this.model.get('executionContractorOperatorCode').updateValueAndValidity();
    }
  }

  openAlertModal() {
    let reportDialog = this.dialog.open(AlertModalComponent, {
      data: {
        title: "Atenção!",
        description: "Este convênio não possui profissionais vinculados. Execute o cadastro para salvar a guia corretamente.",
      },
    });
  }

  inputNumber(formName: string) {
    let value = this.model.get(formName).value ? this.model.get(formName).value : '';
    if (value) {
      value = value.replace(/[^0-9]/g, '');
      value = value.replace(/(\..*)\./g, '$1');
      this.model.get(formName).setValue(value);
    }
  }

  //websocket
  startWebsocket() {
    if (!this.websocketPrivateBillingUtilService.isConnect())
      this.websocketPrivateBillingUtilService.connectWebsocketGuide(this, this.idEpisode, this.guideType, this.authService.getTokenMenu().login, this.authService.getTokenMenu().userName);

    this.websocketPrivateBillingUtilService.setVariables(this.idEpisode, this.guideType, this.authService.getTokenMenu().login, this.authService.getTokenMenu().userName);
    this.websocketPrivateBillingUtilService.setFunctions(this.someoneIsConectedOnEditPrivateBilling);
  }

  someoneIsConectedOnEditPrivateBilling() {
    if (this.websocketPrivateBillingUtilService.isConnect())
      this.websocketPrivateBillingUtilService.clearSocket();

    this.close();
  }

  onGuideStatusChange(idGuideStatus: number) {
    this.guideStatus = this.listGuideStatus.find(x => x.idGuideStatus == idGuideStatus);
    this.guideStatusColor = this.guideStatus.color;
  }

  ngOnDestroy() {
    if (this.websocketPrivateBillingUtilService.isConnect())
      this.websocketPrivateBillingUtilService.clearSocket();
  }

  checkHasRestriction() {
    let request = new CheckHasRestrictionsRequest();
    request.idHealthcareAgreement = this.idHealthCareAgreement;
    request.idProcedure = this.idProcedure;
    request.guideType = GuideTypeEnum.consulta;
    request.idPatient = this.data.idPatient;

    this.checkHasRestrictionService.GetListEpisodeByIdPatient(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (response.restrictionProcedureStruct && response.restrictionProcedureStruct.alertDescription)
          this.alertService.show(`<b>Atenção:</b>`, response.restrictionProcedureStruct.alertDescription, AlertType.warning);
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }
}