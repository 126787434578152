export enum AdmissionGuideFieldsMaxLengthEnum {
  ansRegistration = 6,
  guideNumber = 20,
  providerGuideNumber = 20,
  ticket = 20,
  guideNumberAssignedByTheOperator = 20,

  beneficiaryDocumentNumber = 20,
  beneficiarySocialName = 70,
  beneficiaryName = 70,
  beneficiaryCns = 15,

  requesterCodeOperatorCnpj = 14,
  requesterCodeOperatorCpf = 11,
  requesterCode = 16,
  requesterCodeOperator = 14,
  requesterName = 70,
  requesterProfessionalName = 70,
  requesterProfessionalNumberCouncil = 15,

  requesterHospitalCode = 14,
  requesterHospitalName = 70,
  requesterHospitalDailyCount = 2,

  clinicalIndication = 500,

  cidTenPrimary = 4,
  cidTenSecondary = 4,
  cidTenTertiary = 4,
  cidTenQuaternary = 4,

  authorizedHospitalDailyCount = 3,
  requesterCodeOperatorAuthorized = 14,
  requesterHospitalNameAuthorized = 70,
  codeCnes = 7,
  observationJustification = 1000
}  