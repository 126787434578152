<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-modal">
            <h1>Telefone do paciente</h1>
        </div>
        <div class="body-modal">
            <p>Insira o número de telefone do paciente para iniciar a plataforma Memed corretamente</p>
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline" class="outline-spinner">
                            <mat-label>Telefone</mat-label>
                            <input matInput type="text" formControlName="phone" [mask]="masks.phone">
                            <mat-error *ngIf="model.get('phone').invalid">Telefone inválido</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </div>

        <div class="footer-modal row">
            <div class="col-12">
                <div class="row" *ngIf="!isLoading">
                    <div class="col-6">
                        <button mat-flat-button color="accent" class="btn-block"
                            (click)="close()">Cancelar</button>
                    </div>
                    <div class="col-6">
                        <button mat-flat-button (click)="submit()" type="submit" color="primary" class="btn-block">
                            <span *ngIf="isLoading == false">Concluir</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                </div>
                <div class="loading" *ngIf="isLoading">
                    <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                </div>
            </div>
        </div>
    </div>
</div>