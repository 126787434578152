import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { io } from 'socket.io-client';

@Injectable({
  providedIn: 'root'
})
export class WebsocketRoomService {
  url: string = environment.urlWebsocket;
  websocketConnection(): any {
    return io(`${this.url}`, {
      reconnectionAttempts: 360,
      reconnectionDelay: 10000,
      reconnectionDelayMax: 15000,
      randomizationFactor: 0.1,
      reconnection: true,
      transports: ['websocket', 'polling'],
      path: '/tolifecloud-api-websocket-room/socket.io'
    });
  }
}

/**
 * How to use websocket feature
 * 
 * 
 * const socket = this.websocketService.websocketConnection('clientPanel', `panelId-${this.panelId}`);
 * 
 * socket.disconnect (boolean que identifica se a conexão ainda existe);
 * 
 * socket.on('clientPanel', function(res){
 *  //do something
 * });
 */
