import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdmissionProcedureFieldsMaxLengthEnum } from 'src/app/shared/enum/private-billing/admission-procedure-fields-max-length.enum';
import { TerminologyGroupEnum } from 'src/app/shared/enum/private-billing/terminology-group.enum';
import { GuideTypeEnum } from 'src/app/shared/enum/private-billing/guide-type.enum';
import { CheckHasRestrictionService } from 'src/app/shared/services/API/orchestrator-billing/episode.service';
import { ProcedureService } from 'src/app/shared/services/API/private-billing/procedure.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { TerminologyGroup } from 'src/app/shared/services/models/private-billing/terminology-group.model';
import { CheckHasRestrictionsRequest } from 'src/app/shared/services/requests/private-billing/check-has-restriction.request';
import { GetProceduresRequest } from 'src/app/shared/services/requests/private-billing/get-procedures.request';
import { AdmissionProcedureStruct } from 'src/app/shared/services/structs/private-billing/admission-procedure.struct';
import { ProcedureStruct } from 'src/app/shared/services/structs/private-billing/procedure.struct';

@Component({
  selector: 'app-admission-procedure',
  templateUrl: './admission-procedure.component.html',
  styleUrls: ['./admission-procedure.component.css']
})
export class AdmissionProcedureComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    public procedureService: ProcedureService,
    public alertService: AlertService,
    private checkHasRestrictionService: CheckHasRestrictionService
  ) { }

  @Input() listAdmissionProcedure: AdmissionProcedureStruct[] = [];
  @Input() tussTerminologyGroupMap: Map<number, TerminologyGroup>;
  @Input() editingIndex: number;
  @Input() isEditingAllowed: boolean = null;
  @Input() idAdmissionGuide: number = null;
  @Input() idPatient: number = null;
  @Input() listProcedure: ProcedureStruct[] = [];
  @Input() idHealthcareAgreement: number = null;
  @Output() onDataChanged = new EventEmitter<any>();

  public isLoading: boolean = false;
  public showAdditionalFields: boolean = false;
  public isEditing: boolean = false;
  public alreadyPopulate: boolean = false;
  public isEditingOrCreate: boolean = false;

  public model: FormGroup;
  public admissionMaxLength: typeof AdmissionProcedureFieldsMaxLengthEnum = AdmissionProcedureFieldsMaxLengthEnum;
  public newProcedure: AdmissionProcedureStruct = new AdmissionProcedureStruct();
  public editAdmissionProcedure: AdmissionProcedureStruct = new AdmissionProcedureStruct();

  private timeoutKeySearch: any = null;
  private idAdmissionProcedure: number = null;
  private idProcedure: number = null;
  public procedimentos: TerminologyGroupEnum = TerminologyGroupEnum.procedimentos;


  ngOnInit(): void {
    this.model = this.formBuilder.group({
      idTerminologyGroup: [null, [Validators.required]],
      procedure: [{ value: '', disabled: true }, [Validators.required, Validators.maxLength(AdmissionProcedureFieldsMaxLengthEnum.procedureCount)]],
      procedureCount: [null, [Validators.required, Validators.maxLength(AdmissionProcedureFieldsMaxLengthEnum.procedureCount)]],
      procedureCountAuthorized: [null, [Validators.required, Validators.maxLength(AdmissionProcedureFieldsMaxLengthEnum.procedureCountAuthorized)]],
    });
  }

  submit() {
    if (!this.model.valid) {
      this.alertService.show('Erro', "Preencha todos os campos obrigatórios.", AlertType.error);
      return;
    }

    if (this.isLoading)
      return;

    this.isLoading = true;

    if (this.isValidNewRule()) {
      if (this.editingIndex > -1) {
        const editedItem = this.listAdmissionProcedure[this.editingIndex];

        editedItem.procedureCodeItem = this.model.get('procedure').value.terminology.terminologyCode;
        editedItem.procedure = this.model.get('procedure').value;
        editedItem.procedureDescription = editedItem.procedure.terminology.terminologyName;
        editedItem.procedureCount = this.model.get('procedureCount').value;
        editedItem.procedureCountAuthorized = this.model.get('procedureCountAuthorized').value;
        editedItem.idTerminologyGroup = this.model.get('idTerminologyGroup').value;
        editedItem.terminologyGroupCode = this.tussTerminologyGroupMap.get(this.model.get('idTerminologyGroup').value).terminologyGroupCode;
        if (this.idAdmissionProcedure != null)
          editedItem.idAdmissionProcedure = this.idAdmissionProcedure;
        this.editingIndex = -1;
      }
      else {
        const newAdmissionProcedure = new AdmissionProcedureStruct();
        newAdmissionProcedure.procedureCount = this.model.get('procedureCount').value;
        newAdmissionProcedure.procedureCountAuthorized = this.model.get('procedureCountAuthorized').value;
        newAdmissionProcedure.idTerminologyGroup = this.model.get('idTerminologyGroup').value;
        newAdmissionProcedure.procedure = this.model.get('procedure').value;
        newAdmissionProcedure.procedureDescription = newAdmissionProcedure.procedure.terminology.terminologyName;
        newAdmissionProcedure.terminologyGroupCode = this.tussTerminologyGroupMap.get(this.model.get('idTerminologyGroup').value).terminologyGroupCode;

        this.listAdmissionProcedure.push(newAdmissionProcedure);
      }

      this.model.get('procedure').disable();
      this.listProcedure = null;
      this.isEditing = false;
      this.isEditingOrCreate = true;
      this.idAdmissionProcedure = null;
      this.reset();

      this.onCreateOrUpdate();
    }
  }

  onCreateOrUpdate() {
    if (this.idPatient != null && this.isEditingOrCreate == true)
      this.checkHasRestriction();
    else
      this.isEditingOrCreate = false;

    this.isLoading = false;
    this.showAdditionalFields = false;
    this.onDataChanged.emit({ listAdmissionProcedure: this.listAdmissionProcedure });
  }

  isValidNewRule(): boolean {
    return this.model.get('idTerminologyGroup').value &&
      this.model.get('procedure').value &&
      this.model.get('procedureCount').value !== null &&
      this.model.get('procedureCountAuthorized').value;
  }

  async selectTable(event: any) {
    let idTerminologyGroup = this.model.get('idTerminologyGroup').value;
    let idProcedure: number = this.model.get('procedure').value ? this.model.get('procedure').value.idProcedure : null;
    await this.getProcedures("", idTerminologyGroup, idProcedure);
  }

  async getProcedures(key: string, idTerminologyGroup: number, idProcedure: number = null) {
    this.isLoading = true;
    let request: GetProceduresRequest = {
      key: key,
      idHealthcareAgreement: this.idHealthcareAgreement,
      idTerminologyGroup: idTerminologyGroup,
      isCreate: this.idAdmissionGuide == null ? true : false,
      listIdProcedure: Number.isSafeInteger(idProcedure) ? [idProcedure] : []
    }

    this.procedureService.GetByTerminologyGroup(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listProcedure = response.listProcedureStruct;
        if (this.isEditing && !this.alreadyPopulate)
          this.populateForm();

        this.model.get('procedure').enable();
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  cancelEdit() {
    this.editingIndex = -1;
    this.reset();
    this.showAdditionalFields = false;
  }

  deleteItem(admissionProcedure: AdmissionProcedureStruct) {
    const index = this.listAdmissionProcedure.indexOf(admissionProcedure);
    if (index === -1)
      console.error("Objeto não encontrado na lista:", admissionProcedure);

    if (index === this.editingIndex)
      this.cancelEdit();

    this.listAdmissionProcedure.splice(index, 1);
  }

  editItem(admissionProcedure: AdmissionProcedureStruct) {
    const index = this.listAdmissionProcedure.indexOf(admissionProcedure);

    if (index === -1)
      console.error("Objeto não encontrado na lista:", admissionProcedure);

    this.editAdmissionProcedure = admissionProcedure;
    this.editingIndex = index;
    this.isEditing = true;
    this.alreadyPopulate = false;
    this.getProcedures('', admissionProcedure.idTerminologyGroup, admissionProcedure.procedure?.idProcedure);
    this.showAdditionalFields = true;
  }

  populateForm() {
    this.model.get('procedure').setValue(this.listProcedure.find(x => x.idProcedure == this.editAdmissionProcedure.procedure.idProcedure));
    this.model.get('procedureCount').setValue(this.editAdmissionProcedure.procedureCount);
    this.model.get('procedureCountAuthorized').setValue(this.editAdmissionProcedure.procedureCountAuthorized);
    this.model.get('idTerminologyGroup').setValue(this.editAdmissionProcedure.procedure.terminology.idTerminologyGroup);
    this.alreadyPopulate = true;
  }

  selectProcedure(event: any) {
    this.idProcedure = event.idProcedure;
    if (this.isEditing == true) {
      const idTerminologyGroupValue = this.model.get('idTerminologyGroup').value;
      const procedureValue = this.model.get('procedure').value;
      this.model.reset({
        idTerminologyGroup: idTerminologyGroupValue,
        procedure: procedureValue,
      });
    }
  }

  onKey(event: any) {
    let idTerminologyGroup = this.model.get('idTerminologyGroup').value;
    let idProcedure: number = this.model.get('procedure').value ? this.model.get('procedure').value.idProcedure : null;
    clearTimeout(this.timeoutKeySearch);

    if (event) {
      var $this = this;
      this.timeoutKeySearch = setTimeout(function () {
        if (event.keyCode != 13)
          $this.getProcedures(event, idTerminologyGroup, idProcedure);
      },
        1000);
    }
  }

  reset() {
    this.listProcedure = [];
    this.model.reset();
  }

  inputNumberMinOne(formName: string) {
    let value = this.model.get(formName).value ? this.model.get(formName).value : '';
    if (value) {
      value = value.replace(/[^0-9]/g, '');
      value = value.replace(/(\..*)\./g, '$1');
      if (parseInt(value) == 0) {
        this.alertService.show('Atenção', 'O valor não pode ser menor que 1!', AlertType.warning);
        value = '1';
      }
      this.model.get(formName).setValue(value);
    }
  }

  checkHasRestriction() {
    let request = new CheckHasRestrictionsRequest();
    request.idHealthcareAgreement = this.idHealthcareAgreement;
    request.idProcedure = this.idProcedure;
    request.guideType = GuideTypeEnum.internacao;
    request.idPatient = this.idPatient;

    this.checkHasRestrictionService.GetListEpisodeByIdPatient(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (response.restrictionProcedureStruct && response.restrictionProcedureStruct.alertDescription)
          this.alertService.show(`<b>Atenção:</b>`, response.restrictionProcedureStruct.alertDescription, AlertType.warning);

        this.isEditingOrCreate = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }
}