<div mat-dialog-content>
    <div class="mat-dialog-content">
        <form *ngIf="model" class="form" [formGroup]="model" id="fees-procedure-form" (ngSubmit)="submit()">
            <div class="body-modal">
                <div class="title-header">
                    <h1>Procedimentos Executados</h1>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>36 - Data</mat-label>
                            <input matInput type="text" [mask]="masks.date" formControlName="procedureDate">
                            <mat-error *ngIf="model.get('procedureDate').invalid">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>37 - Hora inicial</mat-label>
                            <input matInput type="text" [mask]="masks.fullTime.mask"
                                formControlName="procedureStartDatetime">
                            <mat-error *ngIf="model.get('procedureStartDatetime').invalid">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>38 - Hora Final</mat-label>
                            <input matInput type="text" [mask]="masks.fullTime.mask"
                                formControlName="procedureEndDatetime">
                            <mat-error *ngIf="model.get('procedureEndDatetime').invalid">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-5">
                        <mat-form-field appearance="outline">
                            <mat-label>39 - Tabela</mat-label>
                            <mat-select formControlName="idTerminologyGroup" (selectionChange)="selectTable()">
                                <mat-option *ngFor="let item of tussTerminologyGroupMap | keyvalue"
                                    [value]="item.value.idTerminologyGroup" [disabled]="item.value.idTerminologyGroup !== procedimentos">{{item.value.terminologyGroupCode}} -
                                    {{item.value.nameTerminologyGroup}}
                                </mat-option>
                            </mat-select>
                            <mat-error
                                *ngIf="model.get('idTerminologyGroup').invalid && !model.get('idTerminologyGroup').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6">
                        <mat-form-field appearance="outline">
                            <mat-label>40/41 - Cód. do procedimento/Descrição</mat-label>
                            <mat-select formControlName="procedure" (selectionChange)="selectProcedure($event.value)">
                                <input class="inputSearchSelect" type="text" (keyup)="onKey($event.target.value)"
                                    placeholder="Pesquisar" autocomplete="off" (keydown)="$event.stopPropagation()">
                                <mat-option *ngFor="let item of listProcedure"
                                    [value]="item">{{item.terminology.terminologyCode}} -
                                    {{item.terminology.terminologyName}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('procedure').invalid">Preenchimento incorreto</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-4 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>42 - Qtde.</mat-label>
                            <input matInput type="text" (keyup)="onChangeTotalValue()"
                                [maxlength]="spSadtFieldsMaxLengthEnum.procedureCount" minimun="1"
                                formControlName="procedureCount" (input)="inputNumberMinOne('procedureCount')">
                            <mat-error
                                *ngIf="model.get('procedureCount').invalid && !model.get('procedureCount').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ model.get('procedureCount').value ?
                                model.get('procedureCount').value.length : 0
                                }}/{{spSadtFieldsMaxLengthEnum.procedureCount}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-4 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>43 - Via</mat-label>
                            <mat-select formControlName="idAccess" (selectionChange)="onChangeAccess()">
                                <mat-option ngValue="null">Selecione</mat-option>
                                <mat-option *ngFor="let item of accessMap | keyvalue"
                                    [value]="item.key">{{item.value.accessLookupCode}} - {{item.value.accessDescription}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idAccess').invalid">Preenchimento incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-4 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>44 - Tec.</mat-label>
                            <mat-select formControlName="idTechnique">
                                <mat-option ngValue="null">Selecione</mat-option>
                                <mat-option *ngFor="let item of techniqueMap | keyvalue"
                                    [value]="item.key">{{item.value.techniqueLookupCode}} - {{item.value.techniqueDescription}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="model.get('idTechnique').invalid">Preenchimento incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-2 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>45 - Fator Red/Acresc.</mat-label>
                            <input matInput type="text" formControlName="adjustmentCoefficient" currencyMask
                                [options]="{ inputMode: 'Natural', prefix: '', decimal: '.', max: '9.99', min: '0.01', allowNegative: 'false' }"
                                (keyup)="onChangeTotalValue()">
                            <mat-error
                                *ngIf="model.get('adjustmentCoefficient').invalid && !model.get('adjustmentCoefficient').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>46 - Valor Unitário (R$)</mat-label>
                            <input matInput type="text" formControlName="procedureUnitValue" currencyMask
                                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', max: '999999.99', allowNegative: 'false' }"
                                (keyup)="onChangeTotalValue()">
                            <mat-error
                                *ngIf="model.get('procedureUnitValue').invalid && !model.get('procedureUnitValue').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>47 - Valor Total (R$)</mat-label>
                            <input matInput type="text" formControlName="procedureTotalValue" currencyMask
                                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', max: '999999.99', allowNegative: 'false' }">
                            <mat-error
                                *ngIf="model.get('procedureTotalValue').invalid && !model.get('procedureTotalValue').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Profissionais executantes</h1>
                </div>
                <div *ngFor="let item of professionals().controls; let i = index" class="row list-professionals"
                    formArrayName="listProfessionals">
                    <div class="row" [formGroupName]="i">
                        <div class="col-12 col-sm-6 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>51 - Profissional</mat-label>
                                <mat-select formControlName="professionalAgreement"
                                    (selectionChange)="selectProfessional($event,i)">
                                    <input class="inputSearchSelect" type="text"
                                        (keyup)="onKeyProfessional($event.target.value)" placeholder="Pesquisar"
                                        autocomplete="off" (keydown)="$event.stopPropagation()">
                                    <mat-option *ngFor="let item of listProfessionalAgreementStruct"
                                        [value]="item">{{item.nameProfessional}}
                                    </mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="professionals().controls[i].get('professionalAgreement').invalid">Preenchimento
                                    incorreto</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>49 - Grau de Part.</mat-label>
                                <mat-select formControlName="idParticipationDegree">
                                    <mat-option *ngFor="let item of participationDegreeMap | keyvalue"
                                        [value]="item.key">{{item.value.participationDegreeLookupCode}} -
                                        {{item.value.participationDegreeDescription}}
                                    </mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="professionals().controls[i].get('idParticipationDegree').invalid">Preenchimento
                                    incorreto</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>50 - Cód. na Operadora/CPF</mat-label>
                                <input matInput type="text" formControlName="professionalProviderCodeOrCpf">
                                <mat-error
                                    *ngIf="professionals().controls[i].get('professionalProviderCodeOrCpf').invalid && !professionals().controls[i].get('professionalProviderCodeOrCpf').errors?.maxlength">Preenchimento
                                    incorreto</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>55 - Cód. CBO</mat-label>
                                <mat-select formControlName="idCbo">
                                    <mat-option *ngFor="let item of cboMap | keyvalue"
                                        [value]="item.key">{{item.value.cboCode}} -
                                        {{item.value.cboName}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="professionals().controls[i].get('idCbo').invalid">Preenchimento
                                    incorreto</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-5">
                            <mat-form-field appearance="outline">
                                <mat-label>52 - Conselho Profissional</mat-label>
                                <mat-select matInput type="text" formControlName="idProfessionalCouncil">
                                    <mat-option *ngFor="let item of listProfessionalCouncil"
                                        [value]="item.idProfessionalCouncil">{{item.termCode}} - {{item.councilName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>53 - N° no Conselho</mat-label>
                                <input matInput type="text" formControlName="professionalCouncilCode">
                                <mat-error
                                    *ngIf="professionals().controls[i].get('professionalCouncilCode').invalid && !professionals().controls[i].get('professionalCouncilCode').errors?.maxlength">Preenchimento
                                    incorreto</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>54 - UF</mat-label>
                                <mat-select matInput type="text" formControlName="idCouncilState">
                                    <mat-option *ngFor="let item of listState" [value]="item.idState">{{item.stateCode}}
                                        - {{item.stateInitials}} / {{item.stateName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div *ngIf="isEditingAllowed != null" class="col-2">
                            <button color="primary" mat-mini-fab type="button" (click)="addNext()"
                                [disabled]="!this.isEditingAllowed">
                                <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                            </button>
                            <button color="primary" mat-mini-fab type="button" (click)="removeProfessional(i)"
                                *ngIf="professionals().controls.length > 1" [disabled]="!this.isEditingAllowed">
                                <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
                            </button>
                        </div>
                        <div *ngIf="isEditingAllowed == null" class="col-2">
                            <button color="primary" mat-mini-fab type="button" (click)="addNext()">
                                <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                            </button>
                            <button color="primary" mat-mini-fab type="button" (click)="removeProfessional(i)"
                                *ngIf="professionals().controls.length > 1">
                                <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<div mat-dialog-footer class="footer-modal">
    <button mat-flat-button color="accent" class="btn-block" (click)="close()" type="button">
        <span *ngIf="!isLoading">Cancelar</span>
        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
    </button>
    <button *ngIf="isEditingAllowed != null" form="fees-form" mat-flat-button type="submit" (click)="submit()"
        color="primary" class="btn-block" [disabled]="!this.isEditingAllowed">
        <span *ngIf="!isLoading">Salvar</span>
        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
    </button>
</div>