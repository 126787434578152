export class CallPanelRequest{
    public idQueue: number;
    
    public idRoomOrigin: number;
    
    public isVerifyCall: boolean;
    
    public login: string;
    
    public password: string;

    public idModule: number;
}