import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MessageStruct } from 'src/app/shared/services/structs/notification/message.struct';
import { NotificationDisplayModalComponent } from './notification-display-modal/notification-display-modal.component';
import { MessageService } from '../../../services/API/notification/message.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';


@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    private alertService: AlertService,
    private messageService: MessageService,
  ) { }

  @Input() isVisible: boolean;
  @Input() isLoading: boolean;
  @Input() listMessage: MessageStruct[];
  @Input() idSelectedMessage: number;

  @Output() closeNotification = new EventEmitter<any>();
  @Output() populateNotification = new EventEmitter<any>();
  @Output() selectMessage = new EventEmitter<any>();

  public isModalView: boolean = false;
  public modalViewIndex: number;
  public selectedMessage: MessageStruct;
  public dialogRef: MatDialogRef<NotificationDisplayModalComponent,any> = null;

  ngOnInit(): void {
  }

  singleRead(event: any, select = true){
    this.messageService.markRead(event.idMessage).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.repopulate();
      if (select) {
        this.select(event);
      }
    },
    (error) => {
      console.log(error)
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  readAll() {
    this.messageService.markAllRead().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.repopulate();
    },
    (error) => {
      console.log(error)
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  singleDelete(event: any){
    this.messageService.delete(event.idMessage).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      if (event == this.selectedMessage){
        this.dialogRef.close({
          isModalView: false});
      }
      this.repopulate();
      if (event.idMessage == this.idSelectedMessage){
        this.dialogRef.close({isModalView : false});
      }
    },
    (error) => {
      console.log(error)
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  deleteAll() {
    this.messageService.deleteAll().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.repopulate();
      this.dialogRef.close({isModalView : false});
    },
    (error) => {
      console.log(error)
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  openViewModal(message: MessageStruct, index: number) {
    if (!message.isRead){
      this.singleRead(message);
    }
    if (this.isModalView) {
      message.isRead = true;
      this.idSelectedMessage = message.idMessage;
      this.modalViewIndex = index;
      this.dialogRef.close({
        isModalView: true});
    }

    this.isModalView = true;
    this.idSelectedMessage = message.idMessage;
    this.modalViewIndex = index;
    this.dialogRef = this.dialog.open(NotificationDisplayModalComponent, {
      data: {
        listMessage: this.listMessage,
        idSelectedMessage: message.idMessage,
      },
      
    });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result && result.isModalView !== undefined){
        this.isModalView = result.isModalView; 
      } 
      else {
        this.isModalView = false;
      }
    });
  }
  

  repopulate() {
    this.populateNotification.emit();
  }

  select(event: MessageStruct) {
    this.idSelectedMessage = event.idMessage;
    this.selectMessage.emit(event);
  }

  close() {
    this.closeNotification.emit();
  }
}
