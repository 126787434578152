import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function VerifyCNPJ(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value)
            return null;

        const value = control.value;
        let isValid: boolean = true;

        let error = { invalidCNPJ: { value: control.value } };
        let cnpjString = value.replace(/[^0-9]+/g, '');

        if (cnpjString.length !== 14)
            return error;

        // Verifica se todos os dígitos são iguais
        if (/^(\d)\1+$/.test(cnpjString))
            isValid = false;

        // Calcula o primeiro dígito verificador
        let sum = 0;
        let pos = 5;
        for (let i = 0; i < 12; i++) {
            sum += parseInt(cnpjString.charAt(i)) * pos--;
            if (pos < 2) pos = 9;
        }
        let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

        if (parseInt(cnpjString.charAt(12)) !== result)
            isValid = false;

        // Calcula o segundo dígito verificador
        sum = 0;
        pos = 6;
        for (let i = 0; i < 13; i++) {
            sum += parseInt(cnpjString.charAt(i)) * pos--;
            if (pos < 2) pos = 9;
        }
        result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

        if (parseInt(cnpjString.charAt(13)) !== result)
            isValid = false;

        return isValid ? null : error;
    };
}