<h1 mat-dialog-title>Frequência Cardíaca</h1>
<div mat-dialog-content>
    <div class="modal-body text-center">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()" [appUppercaseForm]="this.setUppercaseText">
            <div *ngIf="isChild" class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <div class="input-radio-tab">
                        <input [ngClass]="{'force-invalid': isAfterSubmit == false}" value="{{awakeEnum}}"
                            formControlName="typeSomnolence" type="radio" name="typeSomnolence"
                            id="tipoSomnolence-awake">
                        <label for="tipoSomnolence-awake">Acordado</label>
                        <input [ngClass]="{'force-invalid': isAfterSubmit == false}" value="{{sleepyEnum}}"
                            formControlName="typeSomnolence" type="radio" name="typeSomnolence"
                            id="tipoSomnolence-sleepy">
                        <label for="tipoSomnolence-sleepy">Dormindo</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Freq. Cardíaca</mat-label>
                        <input [value]="data.discriminatorsValues[this.discriminatorType]" [mask]="masks.threeDigits"
                            type="text" matInput formControlName="heartRate">
                        <span matSuffix>bpm</span>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <div class="input-radio-tab">
                        <input [ngClass]="{'force-invalid': isAfterSubmit == false}" value="{{regularEnum}}"
                            formControlName="type" type="radio" name="type" id="tipo-batimento-regular">
                        <label for="tipo-batimento-regular">Regular</label>
                        <input [ngClass]="{'force-invalid': isAfterSubmit == false}" value="{{irregularEnum}}"
                            formControlName="type" type="radio" name="type" id="tipo-batimento-irregular">
                        <label for="tipo-batimento-irregular">Irregular</label>
                    </div>
                </div>
            </div>
            <div *ngIf="data.classificationData.priorityAutistic && !data.discriminatorsValues[this.discriminatorType]"
                class="row">
                <div class="col-12">
                    <mat-slide-toggle class="priorityAutistic" (change)="changePriorityAutistic($event)"
                        formControlName="priorityAutistic">
                        Não é possível aferir
                    </mat-slide-toggle>
                </div>
                <div *ngIf="autisticChecked" class="col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Motivo</mat-label>
                        <textarea type="text" matInput formControlName="discriminatorSelectionMotive"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="footer-panel-modal-list">
                <div class="row">
                    <div class="col-12">
                        <button type="submit" mat-flat-button color="primary" class="btn-block">Concluir</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>