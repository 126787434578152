<div class="container-body">
    <img class="login-image" src="assets/images/login-image.png" />
    <div class="vertical-aling-out">

        <div class="vertical-aling-inner">
            <div class="white-box">
                <div class="login-logo-image">
                    <img src="assets/images/login-logo-image.png" />
                </div>
                <h2 *ngIf="!this.isAutomaticLogin">Acessar conta</h2>
                <form class="form" [formGroup]="model" (ngSubmit)="signin()" *ngIf="!this.isAutomaticLogin">
                    <div class="row" *ngIf="state">
                        <div class="col-md-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Matrícula ou CPF</mat-label>
                                <input matInput type="text" autofocus formControlName="login" required
                                    (keydown.enter)="checkLogin()">
                                <mat-error *ngIf="model.get('login').invalid">Informe a Matricula</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <ng-container *ngIf="!state">
                        <app-verify-auth [formGroup]="model" [listHealthUnit]="listHealthUnit" [isLoading]="isLoading"
                            [canStayConnected]="canStayConnected" [login]="model.get('login').value"
                            (goBack)="changeState($event)" (forgotPasswordAuth)="forgotPassword()"
                            (setCanStayConnected)="setCanStayConnected($event)"></app-verify-auth>
                    </ng-container>

                    <div class="row">
                        <div class="col-md-12 align-button" *ngIf="!listHealthUnit">
                            <div class="split-button">
                                <button mat-flat-button color="primary" type="button" class="btn btn-primary btn-sm"
                                    (click)="checkLogin()">
                                    <span *ngIf="isLoading == false">Login</span>
                                    <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                        *ngIf="isLoading == true"></mat-spinner>
                                </button>
                            </div>
                            <div class="split-button">
                                <button mat-flat-button type="button" (click)="forgotPassword()" color="accent"
                                    class="btn btn-outline-primary btn-sm">
                                    Esqueci minha senha
                                </button>
                            </div>

                        </div>

                        <div class="col-12">
                            <div class="separator"></div>
                        </div>

                        <div class="col-12" id="googleButtonDiv"></div>
                        <div class="col-12">
                            <button mat-flat-button type="button" class="microsoft-login-btn btn" (click)="msalLogin()">
                                <img class="login-icon" src="assets/icons/microsoft.png" aria-hidden="false"
                                    aria-label="sound" />
                                <span>Entrar com a conta Office 365</span>
                            </button>
                        </div>
                    </div>
                </form>
                <div class="automatic-login" *ngIf="this.isAutomaticLogin && this.automaticLoginLoading">
                    <div>
                        <h1>Bem vindo(a), {{this.stayConnected.name}}!</h1>
                    </div>
                    <div>
                        <p>Estamos te redirecionando para a {{this.stayConnected.healthUnit}}...</p>
                    </div>
                    <div class="snippet" data-title="dot-pulse">
                        <div class="stage">
                            <div class="dot-pulse"></div>
                        </div>
                    </div>
                </div>
                <div class="automatic-login" *ngIf="this.isAutomaticLogin && !this.automaticLoginLoading">
                    <div>
                        <h2>Não foi possível fazer o Login Automático</h2>
                    </div>
                    <button mat-flat-button color="primary" type="button" class="btn btn-primary btn-sm col-12"
                        (click)="getAutomaticLoginByGuid()">
                        <span *ngIf="isLoading == false">Tentar novamente</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                    <button mat-flat-button color="accent" type="button" class="btn btn-primary btn-sm col-12"
                        (click)="returnLoginScreen()">
                        <span *ngIf="isLoading == false">Entrar com usuário e senha</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>