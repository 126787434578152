<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="row">
            <div class="col-12">
                <h3><b>{{data.userName}}</b> {{data.title}}</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h4>{{data.description}}</h4>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-4"></div>
            <div class="col-12 col-md-4">
                <button mat-flat-button color="primary" class=" btn-block" (click)="continue()" type="button">
                    <span>{{data.buttonConfirm}}</span>
                </button>
            </div>
            <div class="col-12 col-md-4"></div>
        </div>
    </div>
</div>