import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { FavoriteStandardMedicineResponse } from '../../responses/medic/favorite-standard-medicine.response';
import { MedicFavoriteStandardPrescriptionResponse } from '../../responses/medic/medic-favorite-standard-prescription.response';
import { ValidatedFavoriteStandardMedicineResponse } from '../../responses/medic/validated-favorite-standard-medicine.response';

@Injectable({
  providedIn: 'root'
})
export class FavoriteStandardMedicineService extends CommonService {

  constructor(private httpClient: HttpClient) { super(); }

  public getFavoriteStandardMedicine(idMedicFavoriteStandardPrescription: number): Observable<FavoriteStandardMedicineResponse> {
    let uri = `FavoriteStandardMedicine/idMedicFavoriteStandardPrescription/${idMedicFavoriteStandardPrescription}`

    return this.httpClient.get<FavoriteStandardMedicineResponse>(environment.urlApiMedic + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public listValidatedFavorites(idSector: number): Observable<MedicFavoriteStandardPrescriptionResponse> {
    let uri = `FavoriteStandardMedicine/idSector/${idSector}`

    return this.httpClient.get<MedicFavoriteStandardPrescriptionResponse>(environment.urlApiMedic + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public getValidatedFavoriteStandardMedicine(idMedicFavoriteStandardPrescription: number, idSector: number, listIdMedicine: number[]): Observable<ValidatedFavoriteStandardMedicineResponse> {
    let uri = `FavoriteStandardMedicine/idMedicFavoriteStandardPrescription/${idMedicFavoriteStandardPrescription}/idSector/${idSector}`

    return this.httpClient.post<ValidatedFavoriteStandardMedicineResponse>(environment.urlApiMedic + uri, listIdMedicine, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}