import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CommonService } from "src/app/shared/services/common-service";
import { Observable } from 'rxjs';
import { catchError } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
  })
  export class AutocompleteService extends CommonService{
  
    constructor(private router: Router, private httpClient: HttpClient) {
      super();
    }

    public getSelectValues(uri:string, searchText: string): Observable<any>{
      let completeUrl = uri;
      if(searchText){
        if(completeUrl.slice(-1) == "&")
          completeUrl = completeUrl+`searchText=${searchText}`;
        else
          completeUrl = completeUrl+`?searchText=${searchText}`;
      }

      return this.httpClient.get<any>(completeUrl, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }


}