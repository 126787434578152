import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { RecoverPasswordResponse } from '../../../shared/services/responses/user/recover-password.response';
import { UserloginService } from 'src/app/shared/services/API/user/user-login.service';
import { HealthUnitStruct } from 'src/app/shared/services/structs/user/health-unit.struct';
import { RecoverPasswordService } from 'src/app/shared/services/API/orchestrator-user/recover-password.service';
import { RecoverOptionEnum } from 'src/app/shared/enum/recover-option.enum';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(private alertService: AlertService,
    private formBuilder: FormBuilder,
    private recoverPasswordService: RecoverPasswordService,
    private router: Router,
    private userloginService: UserloginService,
  ) { }

  public isLoading: boolean;
  public model: FormGroup;
  public forgotPassword: RecoverPasswordResponse
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.auth;
  public listHealthUnit: HealthUnitStruct[];
  public state: boolean;
  public recoverOption: number;

  ngOnInit(): void {
    this.isLoading = false;
    this.state = true;
    this.model = this.formBuilder.group({
      login: ['', [Validators.required]],
      idHealthUnit: ['', [Validators.required]],
    });
  }

  checkLogin() {
    if (!this.model.get('login').value || this.isLoading == true) {
      this.alertService.show("Erro", 'O campo de matrícula ou CPF é obrigatório', AlertType.error);
      return;
    }

    this.isLoading = true;

    this.userloginService.listUserHealthUnit(this.model.get('login').value).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listHealthUnit = response.listHealthUnit

        if (response.listHealthUnit.length == 1) {
          this.model.get('idHealthUnit').setValue(response.listHealthUnit[0].idHealthUnit.toString());
          this.isLoading = false;
          this.submit();
          return;
        }

        this.isLoading = false;
        this.state = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  submit() {
    if (this.model.invalid || this.isLoading == true)
      return;

    if (!this.recoverOption) {
      this.alertService.show('Erro', "É necessário escolher uma forma de recuperação", AlertType.error);
      this.isLoading = false;
      return;
    }

    let login = this.model.get('login').value;
    let idHealthUnit = this.model.get('idHealthUnit').value

    this.isLoading = true;
    this.recoverPasswordService.recoverPasswordToken(login, idHealthUnit, this.recoverOption).subscribe({
      next: (response: RecoverPasswordResponse) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (this.recoverOption == RecoverOptionEnum.Sms)
          this.alertService.show('Sucesso', `O link para redefinição de senha foi enviado para o número ${response.mockPhone}`, AlertType.success);
        else
          this.alertService.show('Sucesso', `O link para redefinição de senha foi enviado para o email ${response.mockEmail}`, AlertType.success);

        this.router.navigate(['/'])
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  changeState() {
    this.state = true;
    this.listHealthUnit = null;
    this.model.get('idHealthUnit').setValue('');
  }
}