<div id="alone" [ngClass]="{ 'table': isAlone, 'd-none': !isAlone }">
    <p class="text-center text-monospace h3" style="display: table-cell" [ngClass]="{ 'text-white': isPatient}">
        Você é o único nesta sala.
        <br />
        <br />
        Aguarde a entrada do outro participante
    </p>
</div>
<!-- <div *ngIf="isPatient" class="dr-name">Você está sendo atendido por Dr(a). {{attendantUserName}}</div> -->
<div class="video-div" #videoCallDiv></div>
<div class="div-options" [ngClass]="{ 'd-none': isAlone }">
    <button type="button" mat-flat-button [ngClass]="{'btn-call-toggle':!isPatient, 'btn-call-toggle-round':isPatient}"
        title="Click para se mutar." (click)="toggleMicrophone()">
        <mat-icon aria-hidden="false" aria-label="Plus">{{isMuted ? 'mic_off' : 'mic'}}</mat-icon>
    </button>
    <button type="button" mat-flat-button [ngClass]="{'btn-call-toggle':!isPatient, 'btn-call-toggle-round':isPatient}"
        title="Click para desligar a câmera." (click)="toggleVideo()">
        <mat-icon aria-hidden="false" aria-label="Plus">{{isVideoOff ? 'videocam_off' : 'videocam'}}</mat-icon>
    </button>
    <button *ngIf="!isPatient" type="button" mat-flat-button class="btn-fullscreen-call"
        title="Click para tonar tela cheia." (click)="toggleFullscreen()">
        <mat-icon aria-hidden="false" aria-label="Plus">{{isFullScreen? 'fullscreen_exit':'fullscreen'}}</mat-icon>
    </button>
    <button *ngIf="isPatient" type="button" mat-flat-button class="btn-end-call-round" title="Sair da sala."
        (click)="openLeaveRoomConfirmModal()">
        <mat-icon aria-hidden="false" aria-label="Plus">call_end</mat-icon>
    </button>
</div>