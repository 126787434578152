import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ExamRequest } from '../../requests/SADT/exam.request';
import { GetExamResponse } from '../../responses/SADT/get-exam.response';
import { ListExamResponse } from '../../responses/SADT/list-exam.response';

@Injectable({
  providedIn: 'root'
})
export class ExamService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listExam(searchText: string, isActive: boolean, idExamType:number): Observable<ListExamResponse> {
    
    let uri = 'Exam?'
    
    if(searchText != null)
      uri = uri+ `searchText=${searchText}&`;
    
    if(isActive != null)
      uri = uri + `isActive=${isActive}&`   
    
    if(idExamType !=null)
      uri = uri + `idExamType=${idExamType}&`   
    
    return this.httpClient.get<ListExamResponse>(environment.urlApiSADT + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public listAllExam(idExamType:number): Observable<ListExamResponse> {
    
    let uri = 'Exam/GetAll?'

    if(idExamType !=null)
      uri = uri + `idExamType=${idExamType}`
    
    return this.httpClient.get<ListExamResponse>(environment.urlApiSADT + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getExam(idExam: number): Observable<GetExamResponse> {
    
    let uri = `Exam/idExam/${idExam}`;  
    
    return this.httpClient.get<GetExamResponse>(environment.urlApiSADT + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public createExam(body: ExamRequest): Observable<ReturnStruct>{

    let uri = `Exam`

    return this.httpClient.post<ReturnStruct>(environment.urlApiSADT + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public updateExam(idExam: number, body: ExamRequest): Observable<ReturnStruct>{

    let uri = `Exam/idExam/${idExam}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiSADT + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public deleteExam(idExam: number): Observable<ReturnStruct>{

    let uri = `Exam/idExam/${idExam}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiSADT + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
