import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.css']
})
export class ConfigComponent implements OnInit {

  constructor(
    private alertService:AlertService, 
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private authService: AuthService,
  ) { }
  
  public model: UntypedFormGroup;
  public isLoading:boolean;
  public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.auth;
  
  ngOnInit(): void {
    this.model = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      name: ['', [Validators.required]],
    });

    this.isLoading = false;
  }

  submit(){
    
  }

  cancel(){
    let userLoginResponse = this.authService.getTokenMenu();

    if (userLoginResponse?.idDefaultModule && userLoginResponse?.uriDefaultModule){
      this.router.navigate([userLoginResponse.uriDefaultModule]) 
    }
    else
      this.router.navigate(['/welcome'])
  }
}
