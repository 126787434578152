import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { TelemedicineConfig } from '../../models/telemedicine/telemedicine-config.model';
import { TelemedicineConfigRequest } from '../../requests/telemedicine/telemedicine-config.request';
import { GetConfigResponse } from '../../responses/telemedicine/get-config.response';

@Injectable({
  providedIn: 'root'
})
export class TelemedicineConfigService extends CommonService {

  constructor(private httpClient: HttpClient) { super(); }

  public getConfig(): Observable<GetConfigResponse> {
    let uri = `TelemedicineConfig`;

    return this.httpClient.get<GetConfigResponse>(environment.urlApiTelemedicine + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public postConfig(request: TelemedicineConfigRequest): Observable<ReturnStruct> {
    let uri = `TelemedicineConfig`;

    return this.httpClient.post<ReturnStruct>(environment.urlApiTelemedicine + uri, request, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public putColorCodePreview(request: TelemedicineConfig): Observable<ReturnStruct> {
    let uri = `TelemedicineConfig/UpdateColorCodePreview`;

    return this.httpClient.put<ReturnStruct>(environment.urlApiTelemedicine + uri, request, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public putLogoNamePreview(request: TelemedicineConfigRequest): Observable<ReturnStruct> {
    let uri = `TelemedicineConfig/UpdateLogoNamePreview`;

    return this.httpClient.put<ReturnStruct>(environment.urlApiTelemedicine + uri, request, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}