<h2 mat-dialog-title>PIN certificado digital</h2>
<div class="dialog-content" mat-dialog-content>
    <div mat-dialog-actions class="row">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="col-12">
                
                    <mat-form-field appearance="outline">
                        <mat-label>Codigo PIN</mat-label>
                        <mat-icon matSuffix (click)="show = !show">{{show ? 'visibility' : 'visibility_off'}}</mat-icon>
                        <input autofocus matInput [type]="show ? 'text' : 'password'" formControlName="certificatePin">
                        <mat-error *ngIf="model.get('certificatePin').invalid">O pin é obrigatório</mat-error>
                    </mat-form-field>
            </div>
            <div class="col-12">
                <button mat-flat-button color="primary" class="btn-block" (click)="submit()">CONTINUAR</button>
            </div>
        </form>
        <a class="link-button" (click)="close()">Seguir sem assinatura.</a>
    </div>
</div>
