<section>
    <h1 class="date-header">
        {{ viewDate | calendarSchedulerDate
              : view + 'ViewTitle'
              : locale
              : weekStartsOn
              : excludeDays
              : viewDays
              : startsWithToday
        }}
    </h1>
    <div class="group-navigation">
        <div class="btn-group">
            <div
                class="btn btn-primary btn-back"
                mwlCalendarPreviousView
                [view]="view"
                [(viewDate)]="viewDate"
                (click)="addNewEvents(true, false)"> 
                <               
            </div>
            <div
                class="btn btn-outline-secondary"
                mwlCalendarToday
                [(viewDate)]="viewDate"
                (click)="addNewEvents(false, false)">
                Hoje
            </div>
            <div
                class="btn btn-primary btn-next"
                mwlCalendarNextView
                [view]="view"
                [(viewDate)]="viewDate"
                (click)="addNewEvents(false, true)">
                >
            </div>
        </div>
        <div *ngIf="canChangeView" class="btn-group">
            <div
                class="btn btn-primary"
                (click)="setView(CalendarView.Month)"
                [class.active]="view === CalendarView.Month">
                Mês
            </div>
            <div
                class="btn btn-primary"
                (click)="setView(CalendarView.Week)"
                [class.active]="view === CalendarView.Week">
                Semana
            </div>
            <div
                class="btn btn-primary"
                (click)="setView(CalendarView.Day)"
                [class.active]="view === CalendarView.Day">
                Dia
            </div>
        </div>
    </div>
</section>
<calendar-scheduler-view *ngIf="viewSchedulerType"
    [viewDays]="viewDays"
    [viewDate]="viewDate"
    [events]="events"
    [locale]="locale"
    [responsive]="true"
    [weekStartsOn]="weekStartsOn"
    [excludeDays]="excludeDays"
    [startsWithToday]="startsWithToday"
    [hourSegments]="hourSegments"
    [dayStartHour]="dayStartHour"
    [dayEndHour]="dayEndHour"
    [dayModifier]="dayModifier"
    [hourModifier]="hourModifier"
    [segmentModifier]="segmentModifier"
    [eventModifier]="eventModifier"
    [showEventActions]="false"
    [showSegmentHour]="false"
    [zoomEventOnHover]="true"
    [refresh]="refresh"
    [responsive]="true"
    (eventClicked)="eventClicked($event.event)">
</calendar-scheduler-view>
<br>
<div *ngIf="!viewSchedulerType" [ngSwitch]="view">
    <mwl-calendar-month-view
      *ngSwitchCase="CalendarView.Month"
      [viewDays]="viewDays"
      [viewDate]="viewDate"
      [events]="events"
      [locale]="locale"
      [responsive]="true"
      [weekStartsOn]="weekStartsOn"
      [excludeDays]="excludeDays"
      [startsWithToday]="startsWithToday"
      [hourSegments]="hourSegments"
      [dayStartHour]="dayStartHour"
      [dayEndHour]="dayEndHour"
      [dayModifier]="dayModifier"
      [hourModifier]="hourModifier"
      [segmentModifier]="segmentModifier"
      [eventModifier]="eventModifier"
      [showEventActions]="false"
      [showSegmentHour]="false"
      [zoomEventOnHover]="true"
      [refresh]="refresh"
      [activeDayIsOpen]="activeDayIsOpen"
      [tooltipTemplate]="tooltipTemplate"
      (eventClicked)="eventClicked($event.event)"
      (dayClicked)="dayClicked($event.day)"
    >
    </mwl-calendar-month-view>
    <mwl-calendar-week-view
      *ngSwitchCase="CalendarView.Week"
      [viewDays]="viewDays"
      [viewDate]="viewDate"
      [events]="events"
      [locale]="locale"
      [responsive]="true"
      [weekStartsOn]="weekStartsOn"
      [excludeDays]="excludeDays"
      [startsWithToday]="startsWithToday"
      [hourSegments]="hourSegments"
      [dayStartHour]="dayStartHour"
      [dayEndHour]="dayEndHour"
      [dayModifier]="dayModifier"
      [hourModifier]="hourModifier"
      [segmentModifier]="segmentModifier"
      [eventModifier]="eventModifier"
      [showEventActions]="false"
      [showSegmentHour]="false"
      [zoomEventOnHover]="true"
      [refresh]="refresh"
      [tooltipTemplate]="tooltipTemplate"
      (eventClicked)="eventClicked($event.event)"
    >
    </mwl-calendar-week-view>
    <mwl-calendar-day-view
      *ngSwitchCase="CalendarView.Day"
      [viewDays]="viewDays"
      [viewDate]="viewDate"
      [events]="events"
      [locale]="locale"
      [responsive]="true"
      [weekStartsOn]="weekStartsOn"
      [excludeDays]="excludeDays"
      [startsWithToday]="startsWithToday"
      [hourSegments]="hourSegments"
      [dayStartHour]="dayStartHour"
      [dayEndHour]="dayEndHour"
      [dayModifier]="dayModifier"
      [hourModifier]="hourModifier"
      [segmentModifier]="segmentModifier"
      [eventModifier]="eventModifier"
      [showEventActions]="false"
      [showSegmentHour]="false"
      [zoomEventOnHover]="true"
      [refresh]="refresh"
      [tooltipTemplate]="tooltipTemplate"
      (eventClicked)="eventClicked($event.event)"
    >
    </mwl-calendar-day-view>
</div>


<ng-template #tooltipTemplate let-event="event">
    <div class="cal-tooltip ng-star-inserted cal-tooltip-top" ng-reflect-ng-class="cal-tooltip-top" 
        [ngClass]="{'tooltip-month': view == CalendarView.Month, 'tooltip-week': view == CalendarView.Week, 'tooltip-day': view == CalendarView.Day}">
        <div class="cal-tooltip-arrow"></div>
        <div class="cal-tooltip-inner">{{event.title}}, {{event.meta.description}}, {{event.start | date:'dd/MM/yyyy - HH:mm:ss'}} 
            <br>
            {{event.meta.status ? 'Status: ' + event.meta.status : ''}}
        </div>
    </div>
</ng-template>