<div mat-dialog-content>
    <div class="mat-dialog-header">
        <h3>Gerenciamento de filas</h3>
    </div>
    <form class="form" [formGroup]="model" (ngSubmit)="onSubmit()">
        <div class="mat-dialog-content">
            <div class="row">
                <div class="col-md-6 col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Máximo de envios por segundo</mat-label>
                        <input type="number" matInput formControlName="maxDispatchesPerSecond" placeholder="500">
                        <mat-icon matTooltip="Quantidade de envios por segundo. Valor padrão: 500." matSuffix
                            class="my-icon">info_outline</mat-icon>
                    </mat-form-field>
                </div>
                <div class="col-md-6 col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Máximo de envios paralelos</mat-label>
                        <input type="number" matInput formControlName="maxConcurrentDispatches" placeholder="1000">
                        <mat-icon
                            matTooltip="Quantidade de envios paralelo, ao colocar o valor igual à 1 não haverá envio paralelo. Valor padrão: 1000."
                            matSuffix class="my-icon">info_outline</mat-icon>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Quantidade de tentativas</mat-label>
                        <input type="number" matInput formControlName="maxAttempts" placeholder="100">
                        <mat-icon
                            matTooltip="Quantidade de tentativas, deve ser maior que 1 ou igual a -1 para infinitas tentativas. Valor padrão 100."
                            matSuffix class="my-icon">info_outline</mat-icon>
                    </mat-form-field>
                </div>

                <div class="col-md-6 col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Valor de espera para primeira retentativa em segundos</mat-label>
                        <input type="number" matInput formControlName="maxRetryDuration" placeholder="0">
                        <mat-icon
                            matTooltip="Quantidade máxima de tempo para tentar novamente um envio com falha, medida a partir de quando o envio foi tentado pela primeira vez. Valor padrão 0 segundos"
                            matSuffix class="my-icon">info_outline</mat-icon>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Espera miníma em segundos</mat-label>
                        <input type="number" matInput formControlName="minBackoff" placeholder="0,1">
                        <mat-icon matTooltip="Tempo mínimo de espera entre novas tentativas. Valor padrão 0,1 segundos;"
                            matSuffix class="my-icon">info_outline</mat-icon>
                    </mat-form-field>
                </div>
                <div class="col-md-6 col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Espera máxima em segundos</mat-label>
                        <input type="number" matInput formControlName="maxBackoff" placeholder="3600">
                        <mat-icon
                            matTooltip="Tempo máximo de espera entre novas tentativas. Valor padrão 3600. segundos"
                            matSuffix class="my-icon">info_outline</mat-icon>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Máximo de duplicação</mat-label>
                        <input type="number" matInput formControlName="maxDoublings" placeholder="16">
                        <mat-icon
                            matTooltip="Mutiplicador do tempo minimo de espera até que chegue ao valor máximo de espera. Valor padrão: 16."
                            matSuffix class="my-icon">info_outline</mat-icon>
                    </mat-form-field>
                </div>
            </div>
            <div class="footer-panel-modal-list">
                <div class="row">
                    <div class="col-6">
                        <a mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Cancelar</a>
                    </div>
                    <div class="col-6">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>