import { ValidatorFn, ValidationErrors, AbstractControl, FormGroup } from "@angular/forms";

export function TimeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null; // Se o controle estiver vazio, não há erro de validação
    }
    
    // Expressão regular para verificar o formato da hora (HH:MM)
    const TIME_REGEXP = /^(0[0-9]|1[0-9]|2[0-3])[0-5][0-9][0-5][0-9]$/;
    
    if (!TIME_REGEXP.test(control.value)) {
      // Se o valor não corresponder ao formato HH:MM, retorna um erro de validação
      return { 'invalidTimeFormat': true };
    }
    
    // Se o valor corresponder ao formato HH:MM, não há erro de validação
    return null;
  };
}

export function compareTime(): ValidatorFn {
  return (formGroup: FormGroup): ValidationErrors => {
    let isValid: boolean = true;
    let error = { invalidComparison: true };
    let startTime: AbstractControl = formGroup.get('procedureStartDatetime');
    let endTime: AbstractControl = formGroup.get('procedureEndDatetime');
    
    if (startTime && startTime.value && startTime.value.trim() != '' && endTime && endTime.value && endTime.value.trim() != '') {
      if (startTime.value >= endTime.value){
        isValid = false; 
        formGroup.get('procedureStartDatetime').setErrors({ invalidTime: true });
        formGroup.get('procedureEndDatetime').setErrors({ invalidTime: true });
      }        
      else {
        isValid = true; 
        if (formGroup.get('procedureStartDatetime').hasError('invalidTime')){
          formGroup.get('procedureStartDatetime').setErrors({ invalidTime: null });
          formGroup.get('procedureStartDatetime').updateValueAndValidity({emitEvent: false});
        }     
        if (formGroup.get('procedureEndDatetime').hasError('invalidTime')){
          formGroup.get('procedureEndDatetime').setErrors({ invalidTime: null });
          formGroup.get('procedureEndDatetime').updateValueAndValidity({emitEvent: false});
        }          
      }      
      
    }
    else if(((!startTime || !startTime.value) && endTime && endTime.value && endTime.value.trim() != '')
    || ((startTime && startTime.value && startTime.value.trim() != '') && (!endTime || !endTime.value))){
      isValid = false; 
      formGroup.get('procedureStartDatetime').setErrors({ invalidTime: true });
      formGroup.get('procedureEndDatetime').setErrors({ invalidTime: true });
    }
    else {
      isValid = true;
      if (formGroup.get('procedureStartDatetime').hasError('invalidTime')){
        formGroup.get('procedureStartDatetime').setErrors({ invalidTime: null });
        formGroup.get('procedureStartDatetime').updateValueAndValidity({emitEvent: false});
      }     
      if (formGroup.get('procedureEndDatetime').hasError('invalidTime')){
        formGroup.get('procedureEndDatetime').setErrors({ invalidTime: null });
        formGroup.get('procedureEndDatetime').updateValueAndValidity({emitEvent: false});
      }   
    }  

    return !isValid ?  error : null
  }
};