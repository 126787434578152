import { Injectable } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { connect, ConnectOptions, LocalTrack, Room } from "twilio-video";

export type StorageKey = 'audioInputId' | 'audioOutputId' | 'videoInputId';
export type Devices = MediaDeviceInfo[];

@Injectable({
    providedIn: 'root'
})
export class TwilioUtilService {
    constructor() { 
    }

    get(key: StorageKey): string {
        return localStorage.getItem(this.formatAppStorageKey(key));
    }

    set(key: StorageKey, value: string) {
        if (value && value !== 'null') {
            localStorage.setItem(this.formatAppStorageKey(key), value);
        }
    }

    remove(key: StorageKey) {
        localStorage.removeItem(this.formatAppStorageKey(key));
    }

    getVideoToken() {
        let token: string = sessionStorage.getItem("videoToken");
        return token;
    }

    private formatAppStorageKey(key: StorageKey) {
        return `iEvangelist.videoChat.${key}`;
    }
    
    
    private async isGrantedMediaPermissions() {
        if (navigator && navigator.userAgent && navigator.userAgent.indexOf('Chrome') < 0) {
            return true; // Follows standard workflow for non-Chrome browsers.
        }

        if (navigator && navigator.permissions) {
            try {
                const permissionName = "camera" as PermissionName
                const result = await navigator.permissions.query({ name: permissionName });
                if (result) {
                    if (result.state === 'granted') {
                        return true;
                    } else {
                        const isGranted = await new Promise<boolean>(resolve => {
                            result.onchange = (_: Event) => {
                                const granted = _.target['state'] === 'granted';
                                if (granted) {
                                    resolve(true);
                                }
                            }
                        });

                        return isGranted;
                    }
                }
            } catch (e) {
                // This is only currently supported in Chrome.
                // https://stackoverflow.com/a/53155894/2410379
                return true;
            }
        }

        return false;
    }

    // private async getDeviceOptions(): Promise<Devices> {
    //     const isGranted = await this.isGrantedMediaPermissions();
    //     if (navigator && navigator.mediaDevices && isGranted) {
    //         let devices = await this.tryGetDevices();
    //         if (devices.every(d => !d.label)) {
    //             devices = await this.tryGetDevices();
    //         }

    //         return devices.filter(d => !!d.label);
    //     }

    //     return null;
    // }

    public async tryGetDevices() {
        const mediaDevices = await navigator.mediaDevices.enumerateDevices();
        const devices = ['audioinput', 'audiooutput', 'videoinput'].reduce((options, kind) => {
            return options[kind] = mediaDevices.filter(device => device.kind === kind);
        }, [] as Devices);

        return devices;
    }

    async joinOrCreateRoom(token: string, tracks: LocalTrack[]) {
        let room: Room = null;
        try {

            room = await connect( token, {
                tracks: tracks
            } as ConnectOptions);
            
        } catch (error) {
            console.error(`Unable to connect to Room: ${error.message}`);
        }

        return room;
    }
}