import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from '../../services/alert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-favorite-name-modal',
  templateUrl: './favorite-name-modal.component.html',
  styleUrls: ['./favorite-name-modal.component.css']
})
export class FavoriteNameModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<FavoriteNameModalComponent>,
    public router: Router,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
  ) { }

  public model: FormGroup;
  public isLoading: boolean;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      favoriteName: ['', [Validators.required]],
    });

  }

  submit() {
    if (this.model.invalid) {
      return;
    }
    this.matDialogRef.close({ 'name': this.model.get('favoriteName').value });
  }

  clickCancel() {
    this.matDialogRef.close();
  }
}
