import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-health-unit-list-modal',
  templateUrl: './health-unit-list-modal.component.html',
  styleUrls: ['./health-unit-list-modal.component.css']
})
export class HealthUnitListModalComponent implements OnInit {
  constructor(private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<HealthUnitListModalComponent>,
    private authService: AuthService,
  ) { 
    matDialogRef.disableClose = true;
  }

  public isLoading: boolean;


  ngOnInit(): void {
    this.isLoading = false;  
    this.matDialogRef.disableClose = true;
    
  }

  clickCancel(){
    this.matDialogRef.close({confirmLogin: false}); 
  }
  clickConfirm(selectedItem){
    this.isLoading = true;
    this.authService.signInOAuth(this.data.accessToken, '192.168.0.2', this.data.idLoginType, selectedItem.idHealthUnit).subscribe({next: response=>{
      if (response.isError) {
        this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
        this.isLoading = false;
        this.matDialogRef.close({confirmLogin: false, response: response});        
        return;
      }
      this.authService.updateToken(response);
      this.isLoading = false;
      this.matDialogRef.close({confirmLogin: true, response: response}); 
    },
    error: error =>{
      console.log(error);
      this.alertService.show("Erro inesperado", error, AlertType.error);
      this.isLoading = false;
    }})
  }

  close(){
    this.matDialogRef.close({confirmLogin: false}); 
  }
}
