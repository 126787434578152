export enum ProfessionEnum {
    Medico = 1,
    Enfermeiro = 2,
    TecnicoDeEnfermagem = 3,
    AssistenteSocial = 4,
    Fisioterapeuta = 5,
    Recepcionista = 7,
    Administrador = 8,
    ServicoSocial = 9,
    Odontologia = 10,
    Farmacêutico = 11,
    TecnicoDeFarmacia = 12,
    AuxiliarDeSaudeBucal = 13,
    TecnicoDeGesso = 14,
    AuxiliarOperacional = 15,
}