import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Participant, RemoteParticipant, RemoteTrackPublication, RemoteTrack, RemoteAudioTrack, RemoteVideoTrack } from 'twilio-video';
import { CameraComponent } from '../camera/camera.component';
import { TwilioUtilService } from '../../twilio-util.service';
import { LeaveRoomConfirmModalComponent } from './leave-room-confirm-modal/leave-room-confirm-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-participant',
    templateUrl: './participant.component.html',
    styleUrls: ['./participant.component.css']
})
export class ParticipantComponent implements OnInit, OnDestroy {

    @ViewChild('videoCallDiv') videoCallDiv: ElementRef;
    @Output('participantsChanged') participantsChanged = new EventEmitter<boolean>();
    @Output('leaveRoom') leaveRoom = new EventEmitter<boolean>();
    @Output('toggleMic') toggleMic = new EventEmitter<boolean>();
    @Output('toggleVid') toggleVid = new EventEmitter<boolean>();
    @Output('toggleFullScreen') toggleFullScreen = new EventEmitter<boolean>();
    @Input('activeRoomName') activeRoomName: string;
    @Input() isVideoOff: boolean = false;
    @Input() isMuted: boolean = false;
    @Input() isPatient: boolean = false;

    get participantCount() {
        return !!this.participants ? this.participants.size : 0;
    }

    get isAlone() {
        return this.participantCount === 0;
    }

    private participants: Map<Participant.SID, RemoteParticipant>;
    private dominantSpeaker: RemoteParticipant;
    public isFullScreen: boolean = false;

    constructor(
        private readonly renderer: Renderer2,
        public dialog: MatDialog,
    ) { }

    public getScreenWidth: any;
    public getScreenHeight: any;
    public videoHeight: string = '200px';
    public attendantUserName: string;

    ngOnInit(): void {
        this.getScreenWidth = window.innerWidth;
        this.getScreenHeight = window.innerHeight;
        if (this.isPatient) {
            this.attendantUserName = sessionStorage.getItem("attendantUserName");
        }
    }

    ngOnDestroy() {
        if (this.participants) {
            this.participants.forEach(x => {
                x.videoTracks.forEach(track => {
                    var trackDe = track.track.detach();
                    if (trackDe && Array.isArray(trackDe)) {
                        trackDe.forEach(el => el.remove());
                    }
                })
            })
            this.participants.clear();
        }
    }

    @HostListener('window:resize', ['$event'])
    onWindowResize() {
        this.getScreenWidth = window.innerWidth;
        this.getScreenHeight = window.innerHeight;
        this.changeVideoSize();
    }

    clear() {
        if (this.participants) {
            this.participants.clear();
        }
    }

    initialize(participants: Map<Participant.SID, RemoteParticipant>) {
        this.participants = participants;
        if (this.participants) {
            this.participants.forEach(participant => this.registerParticipantEvents(participant));
        }
    }

    add(participant: RemoteParticipant) {
        if (this.participants && participant) {
            this.participants.set(participant.sid, participant);
            this.registerParticipantEvents(participant);
        }
    }

    remove(participant: RemoteParticipant) {
        if (this.participants && this.participants.has(participant.sid)) {
            this.participants.delete(participant.sid);
        }
    }

    loudest(participant: RemoteParticipant) {
        this.dominantSpeaker = participant;
    }

    onLeaveRoom() {
        this.leaveRoom.emit(true);
    }

    toggleMicrophone() {
        this.toggleMic.emit();
    }

    toggleVideo() {
        this.toggleVid.emit();
    }

    private registerParticipantEvents(participant: RemoteParticipant) {
        if (participant) {
            participant.tracks.forEach(publication => this.subscribe(publication));
            participant.on('trackPublished', publication => this.subscribe(publication));
            participant.on('trackUnpublished',
                publication => {
                    if (publication && publication.track) {
                        this.detachRemoteTrack(publication.track);
                    }
                });
        }
    }

    private subscribe(publication: RemoteTrackPublication | any) {
        if (publication && publication.on) {
            publication.on('subscribed',
                (track: RemoteTrack) => this.attachRemoteTrack(track));
            publication.on('unsubscribed',
                (track: RemoteTrack) => this.detachRemoteTrack(track));

        }
    }

    private attachRemoteTrack(track: RemoteTrack) {
        if (this.isAttachable(track)) {
            const element = track.attach();
            element.style.transform = 'scale(-1,1)';
            this.renderer.data.id = track.sid;

            if (!this.isFullScreen && !this.isPatient) {
                this.renderer.setStyle(element, 'width', this.calculateDimensions());
                this.renderer.setStyle(element, 'height', '200px');
                this.renderer.setStyle(element, 'max-height', '200px');
            }
            else {
                this.renderer.setStyle(element, 'width', this.calculateDimensions());
                this.renderer.setStyle(element, 'height', '100%');
                this.renderer.setStyle(element, 'max-height', '100%');
            }
            this.renderer.setStyle(element, 'border-radius', '15px');
            this.renderer.setStyle(element, 'object-fit', 'cover');
            this.renderer.setAttribute(element, 'id', 'myvideo');
            this.renderer.appendChild(this.videoCallDiv.nativeElement, element);
            this.participantsChanged.emit(true);
        }
    }

    private detachRemoteTrack(track: RemoteTrack) {
        if (this.isDetachable(track)) {
            track.detach().forEach(el => el.remove());
            this.participantsChanged.emit(true);
        }
    }

    private isAttachable(track: RemoteTrack): track is RemoteAudioTrack | RemoteVideoTrack {
        return !!track &&
            ((track as RemoteAudioTrack).attach !== undefined ||
                (track as RemoteVideoTrack).attach !== undefined);
    }

    private isDetachable(track: RemoteTrack): track is RemoteAudioTrack | RemoteVideoTrack {
        return !!track &&
            ((track as RemoteAudioTrack).detach !== undefined ||
                (track as RemoteVideoTrack).detach !== undefined);
    }


    toggleFullscreen() {
        this.isFullScreen = !this.isFullScreen;

        this.changeVideoSize();

        this.toggleFullScreen.emit(this.isFullScreen);
    }

    calculateDimensions(): string {
        let size: number = 250;
        let pixelSize: string;

        if (!this.isFullScreen && !this.isPatient) {
            if (this.getScreenWidth >= 1530) {
                size = 340;
            }
            else if (this.getScreenWidth < 1530 && this.getScreenWidth >= 1330) {
                size = 300;
            }
            else if (this.getScreenWidth < 1330 && this.getScreenWidth >= 1150) {
                size = 250;
            }
            else if (this.getScreenWidth < 1150) {
                size = 210;
            }
        }
        else {
            // size = 709;
            return "100%";
        }

        pixelSize = `${size}px`;

        return pixelSize;
    }

    changeVideoSize() {
        let videos = document.getElementsByTagName('video');
        let video: HTMLElement

        for (var i = 0; i < videos.length; i++) {
            if (videos[i].id == 'myvideo') {
                video = videos[i];
            }
        }

        if (video && video.style)
            video.style.width = this.calculateDimensions();

        if (this.isFullScreen || this.isPatient) {
            video.style.height = '100%';
            video.style.height = '100%';
            video.style.maxHeight = '100%';
        }
        else {
            if (video && video.style) {
                video.style.height = this.videoHeight;
                video.style.maxHeight = this.videoHeight;
            }
        }
    }

    openLeaveRoomConfirmModal() {
        const dialogRef = this.dialog.open(LeaveRoomConfirmModalComponent, {
            data: {

            },
            autoFocus: false
        });
        dialogRef.afterClosed().subscribe({
            next: result => {
                if (result && result.leave) {
                    this.onLeaveRoom();
                }
            }
        });
    }
}
