import { Injectable } from '@angular/core';
import { DigitalSignatureStruct } from './structs/digital-signature/digital-signature-struct.struct';

@Injectable({
    providedIn: 'root'
})
export class DigitalSignatureUtilService {

    constructor() { }

    setSelectedProvider(idSignatureProvider: number): void {
        sessionStorage.setItem('SelectedProvider', idSignatureProvider.toString());
    }

    getSelectedProvider(): number {
        let idSignatureProvider: number = parseInt(sessionStorage.getItem('SelectedProvider'));
        return idSignatureProvider;
    }

    removeSelectedProvider() {
        sessionStorage.removeItem('SelectedProvider');
        return;
    }

    listSignatureStruct(): Object {
        let listSignatureStruct = JSON.parse(sessionStorage.getItem('ListSignatureProvider'));
        return listSignatureStruct;
    }

    setListSignatureStruct(listSignatureStruct: Object) {
        sessionStorage.setItem('ListSignatureProvider', JSON.stringify(listSignatureStruct))
        return;
    }

    getSignatureStruct(idProvider: number) {
        let listSignatureStruct: Object = this.listSignatureStruct();

        if (listSignatureStruct != null && listSignatureStruct[idProvider] != null)
            return listSignatureStruct[idProvider];

        return null;
    }

    setSignatureStruct(idProvider: number, struct: DigitalSignatureStruct): void {
        let listSignatureStruct: Object = this.listSignatureStruct();

        if (listSignatureStruct == null) 
            listSignatureStruct = new Object();

        listSignatureStruct[idProvider] = struct;

        sessionStorage.setItem('ListSignatureProvider', JSON.stringify(listSignatureStruct));
        return;
    }

    clear() {
        sessionStorage.removeItem('ListSignatureProvider');
        sessionStorage.removeItem('SelectedProvider');
    }
}
