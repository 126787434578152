<div class="container-sub-menu" *ngIf="isVisible == true">
    <h3 class="bnt-back" (click)="close()">
        <mat-icon class="chevron back-icon" aria-hidden="false" aria-label="back">chevron_left</mat-icon>
        Voltar
    </h3>
    <div class="container-options">
        <h5>{{this.actualMainMenuName}}</h5>
        <div class="action">
            <ul *ngIf="listSubMenuStruct != null && listSubMenuStruct != undefined">
                <li *ngFor="let item of listSubMenuStruct">
                    <a (click)="redirect(item)">
                        <span>{{item.name}}</span>
                        <mat-icon class="chevron" aria-hidden="false" aria-label="Menu apps">chevron_right</mat-icon>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>