<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-confirm-modal-list">
            <h2>Encaminhar paciente</h2>
         </div>
         <div class="body-modal-list">
            O paciente será encaminhado para {{this.data.locationName}}, o que você deseja fazer com o leito dele?
        </div>
         <div class="footer-confirm-modal-list">
            <button mat-flat-button color="primary" class="btn-block" (click)="clickConfirm(true)">
                <span *ngIf="isLoading == false">Encaminhar e remover o episodio do leito</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
            <button mat-flat-button color="primary" class="btn-block" (click)="clickConfirm()">
                <span *ngIf="isLoading == false">Encaminhar e manter o leito ocupado com aquele episodio</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
            <button mat-flat-button color="accent" class="btn-block"(click)="clickCancel()">Cancelar</button>
        </div>
    </div>
</div>