import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertService, AlertType } from '../../services/alert.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-email-confirmation-end-medical-care-modal',
  templateUrl: './email-confirmation-end-medical-care-modal.component.html',
  styleUrls: ['./email-confirmation-end-medical-care-modal.component.css']
})
export class EmailConfirmationEndMedicalCareModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<EmailConfirmationEndMedicalCareModalComponent>,
    public router: Router,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
  ) { }

  public model: FormGroup;
  public isLoading: boolean;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });

    this.populateEmail();
  }

  populateEmail() {
    this.model.get('email').setValue(this.data.email);

    this.isLoading = false;
  }

  submit(confirm: boolean) {
    if (this.isLoading)
      return;

    this.isLoading = true;

    if (!confirm) {
      this.model.get('email').setValidators(null);
      this.model.get('email').updateValueAndValidity();
    }

    if (this.model.invalid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      this.isLoading = false;
      return;
    }

    this.matDialogRef.close({ confirm: confirm, email: this.model.get('email').value });
  }

  close() {
    this.matDialogRef.close();
  }

  clickCancel() {
    this.matDialogRef.close({ confirm: false });
  }
}