<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-modal-list">
            <h2 *ngIf="data.type == 's'">Saturação</h2>
            <h2 *ngIf="data.type == 'g'">Glicemia</h2>
            <h2 *ngIf="data.type == 'b'">Pressão</h2>
            <h2 *ngIf="data.type == 't'">Temperatura</h2>
            <h2 *ngIf="data.type == 'h'">Pulso</h2>
        </div>

        <div class="row">
            <div class="col-12 col-sm-12 col-md-12" *ngFor="let item of data.listHistory">
                <div class="body-modal-list">
                    <span>
                        <b>Usuário:</b> {{item.userName}}
                    </span>
                    <span>
                        <b>Módulo:</b> {{item.moduleName}}
                    </span>
                    <span>
                        <b>Data:</b> {{item.vitalsCheckDate | date: 'yyyy/MM/dd HH:mm:ss'}}
                    </span>
                    <span>
                        <b>Valor:</b> {{item.value}}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>