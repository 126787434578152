export class MedicalCareSadtStruct {
    public idMedicalCareSatd: number;
    public idMedicalCare: number;
    public idSadt: number;
    public idUsage: number;
    public idStatus: number;
    public idEpisode: number;
    public idUser: number;
    public idPlace: number;
    public idSadtSchedule: number;
    public idTypeSadt: number;

    public sadtName: string;
    public observation: string;
    public sadtResult: string;
    public observationMedic: string;
    public userName: string;
    public placeName: string;
    public sadtDescription: string;
    public usageName: string;
    public statusName: string;
    public sadtScheduleName: string;
    public typeSadtName: string;

    public datetimeInclusion: Date;

    public isLoadingSadt: boolean;
}