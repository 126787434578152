import { Injectable } from "@angular/core";
import { CommonService } from "../../common-service";
import { environment } from "src/environments/environment";
import { Observable, catchError } from "rxjs";
import { ReturnStruct } from "src/app/shared/structs/return.struct";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { SafeIDClientRequest } from "../../requests/digital-signature/safe-id/safe-id-client.request";
import { SafeIDClientResponse } from "../../responses/digital-signature/safe-id/safe-id-client.response";
import { SafeIDClientAuthRequest } from "../../requests/digital-signature/safe-id/safe-id-client-auth.request";
import { SafeIDCredentialsRequest } from "../../requests/digital-signature/safe-id/safe-id-credentials.request";
import { SafeIDCredentialsResponse } from "../../responses/digital-signature/safe-id/safe-id-credentials.response";

@Injectable({
    providedIn: 'root'
})
export class SafeIDSignatureService extends CommonService {

    constructor(private router: Router, private httpClient: HttpClient) {
        super();
    }

    public createClient(request: SafeIDClientRequest): Observable<SafeIDClientResponse> {
        let uri = `SafeID/client`;

        return this.httpClient.post<SafeIDClientResponse>(environment.urlApiDigitalSignature + uri, request, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }

    public createClientAuth(request: SafeIDClientAuthRequest): Observable<ReturnStruct> {
        let uri = `SafeID/clientAuth`;

        return this.httpClient.post<ReturnStruct>(environment.urlApiDigitalSignature + uri, request, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }

    public authenticateCredentials(request: SafeIDCredentialsRequest): Observable<SafeIDCredentialsResponse> {
        let uri = `SafeID/credentials`;

        return this.httpClient.post<SafeIDCredentialsResponse>(environment.urlApiDigitalSignature + uri, request, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }
}
