import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MedicalPrescriptionCheckRequest } from '../../services/requests/medical-record/medical-prescription-check.request';
import { MedicalPrescriptionStruct } from '../../services/structs/medical-record/medical-prescription.struct';
import { MedicalPrescriptionModalComponent } from './medical-prescription-modal/medical-prescription-modal.component';

@Component({
  selector: 'app-prescription-list',
  templateUrl: './prescription-list.component.html',
  styleUrls: ['./prescription-list.component.css']
})
export class PrescriptionListComponent implements OnInit {

  @Input() listPrescription: MedicalPrescriptionStruct[];
  @Input() listCheckedMedication: MedicalPrescriptionCheckRequest[];
  @Input() patientName: string;
  @Input() birthDate: Date;
  @Input() idEpisode: number;
  @Input() actions: boolean;
  @Input() idNursingConsultation: number;

  public listDisplay: number[];

  constructor(
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.listDisplay = [];
  }

  openPrescriptionModal(prescription: MedicalPrescriptionStruct) {
    const dialogRef = this.dialog.open(MedicalPrescriptionModalComponent, {
      data: {
        prescription: prescription,
        patientName: this.patientName,
        birthDate: this.birthDate,
        idMedicalPrescription: prescription.idMedicalPrescription,
        actions: this.actions,
        idEpisode: this.idEpisode,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let index = this.listCheckedMedication.findIndex(l => l.idMedicalPrescription == result.medicalPrescriptionCheckRequest.idMedicalPrescription);
        if(index >= 0)
          this.listCheckedMedication[index] = result.medicalPrescriptionCheckRequest
        else
          this.listCheckedMedication.push(result.medicalPrescriptionCheckRequest);
      }
    });
  }

  statusIdToText(status: number) : string {
    if(status == 1)
      return "Medicação Próxima";
    else if(status == 2)
      return "Medicação Adiantada";
    else if(status == 3)
      return "Medicação Atrasada";
    else if(status == 4)
      return "Concluída";
  }
}