import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UpdateStatusQueueService } from 'src/app/shared/services/API/orchestrator-queue/update-status-queue.service';
import { MessageStruct } from 'src/app/shared/services/structs/notification/message.struct';
import { EvadePatientModalComponent } from '../../../evade-patient-modal/evade-patient-modal.component';

@Component({
  selector: 'app-notification-display-modal',
  templateUrl: './notification-display-modal.component.html',
  styleUrls: ['./notification-display-modal.component.css']
})
export class NotificationDisplayModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<EvadePatientModalComponent>,
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder,) { }

  public isLoading: boolean;
  public listMessage: MessageStruct[];
  public selectedMessage: MessageStruct;
  public messageContent: string = "";
  public model: UntypedFormGroup;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      messageContent: [''],
    });
    this.listMessage = this.data.listMessage;
    this.selectedMessage = this.listMessage.find(x => x.idMessage == this.data.idSelectedMessage);
    this.model.get('messageContent').setValue(this.selectedMessage.messageContent);
  }

  close(){
    this.matDialogRef.close({
      isModalView: false}); 
  }

}
