import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-report-modal',
  templateUrl: './report-modal.component.html',
  styleUrls: ['./report-modal.component.css']
})
export class ReportModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<ReportModalComponent>,
  public sanitizer: DomSanitizer) { }

  public isLoading:boolean;
  public src:any;
  
  ngOnInit(): void {
    this.src = this.sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,' + this.data.reportPdf64);
    this.isLoading = false;
  }
  
  close(){
    this.matDialogRef.close(); 
  }
}

