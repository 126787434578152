import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { MedicFavoriteStandardPrescriptionResponse } from '../../responses/medic/medic-favorite-standard-prescription.response';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { MedicFavoriteStandardPrescriptionRequest } from '../../requests/medic/medic-favorite-standard-prescription-request';

@Injectable({
  providedIn: 'root'
})
export class MedicFavoriteStandardPrescriptionService extends CommonService {

  constructor(private httpClient: HttpClient) { super(); }

  public listMedicFavoritStandardPrescription(): Observable<MedicFavoriteStandardPrescriptionResponse> {
    let uri = `MedicFavoriteStandardPrescription`

    return this.httpClient.get<MedicFavoriteStandardPrescriptionResponse>(environment.urlApiMedic + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public deleteMedicFavoriteStandardPrescription(idMedicFavoriteStandardPrescription: number): Observable<ReturnStruct> {
    let uri = `MedicFavoriteStandardPrescription/idMedicFavoriteStandardPrescription/${idMedicFavoriteStandardPrescription}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiMedic + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  
  public postMedicFavoriteStandarPrescription(request: MedicFavoriteStandardPrescriptionRequest): Observable<ReturnStruct> {
    let uri = `MedicFavoriteStandardPrescription`

    return this.httpClient.post<ReturnStruct>(environment.urlApiMedic + uri, request, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public putMedicFavoriteStandarPrescription(request: MedicFavoriteStandardPrescriptionRequest): Observable<ReturnStruct> {
    let uri = `MedicFavoriteStandardPrescription`

    return this.httpClient.put<ReturnStruct>(environment.urlApiMedic + uri, request, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}