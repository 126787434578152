import { Component, Input, OnInit } from '@angular/core';
import { StatusQueueEnum } from '../../enum/status-queue.enum';

@Component({
  selector: 'app-status-bullet',
  templateUrl: './status-bullet.component.html',
  styleUrls: ['./status-bullet.component.css']
})
export class StatusBulletComponent implements OnInit {

  @Input() idStatusQueue:number;
  @Input() statusQueueName:string;
  statusQueueEnumChamando:StatusQueueEnum = StatusQueueEnum.chamando;
  statusQueueEnumEm_atendimento:StatusQueueEnum = StatusQueueEnum.em_atendimento;


  constructor() { }

  ngOnInit(): void {
    
  }
}
