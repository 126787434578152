<div *ngIf="!isPatient" [ngClass]="{'fullscreen-background-div': isFullScreen}" (click)="closeFullScreen()"></div>

<div *ngIf="isPatient" class="dr-name">Você está sendo atendido por Dr(a). {{attendantUserName}}</div>
<div class="call-content" [ngClass]="{'is-patient-call':isPatient, 'video-call-fullscreen-div':isFullScreen}">
    <app-participant [ngClass]="{'call-div':!isFullScreen}" 
        #participants 
        (leaveRoom)="onLeaveRoom($event)"
        (toggleMic)="onMute()" 
        (toggleVid)="onHideVideo()" 
        (toggleFullScreen)="toggleFullScreen($event)"
        [style.display]="!!activeRoom ? 'block' : 'none'" 
        [style.width]="isFullScreen ? '65%' : '100%'"
        [style.height]="'80%'" 
        [style.text-align]="'center'" 
        [activeRoomName]="!!activeRoom ? activeRoom.name : null"
        [isMuted]="isMuted" 
        [isVideoOff]="isVideoOff" 
        [isPatient]="isPatient">
    </app-participant>

    <div [ngClass]="{'preview-is-patient-call': isPatient,'fullscreen-preview': isFullScreen }">
        <app-settings #settings [isFullScreen]="isFullScreen"></app-settings>
    </div>
</div>
