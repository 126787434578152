import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FeesFieldsMaxLengthEnum } from 'src/app/shared/enum/private-billing/fees-fields-max-length.enum';
import { GuideStatusEnum } from 'src/app/shared/enum/private-billing/guide-status.enum';
import { GuideTypeEnum } from 'src/app/shared/enum/private-billing/guide-type.enum';
import { AdmissionGuideService } from 'src/app/shared/services/API/private-billing/admission-guide.service';
import { FeesGuideService } from 'src/app/shared/services/API/private-billing/fees-guide.service';
import { HealthcareAgreementService } from 'src/app/shared/services/API/private-billing/healthcare-agreement.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { Access } from 'src/app/shared/services/models/private-billing/access.model';
import { Cbo } from 'src/app/shared/services/models/private-billing/cbo.model';
import { FeesRequiredField } from 'src/app/shared/services/models/private-billing/fees-required-field.model';
import { GuideStatus } from 'src/app/shared/services/models/private-billing/guide-status.model';
import { HealthcareAgreement } from 'src/app/shared/services/models/private-billing/healthcare-agreement.model';
import { ParticipationDegree } from 'src/app/shared/services/models/private-billing/participation-degree.model';
import { Technique } from 'src/app/shared/services/models/private-billing/technique.model';
import { TerminologyGroup } from 'src/app/shared/services/models/private-billing/terminology-group.model';
import { TissBilling } from 'src/app/shared/services/models/private-billing/tiss-billing.model';
import { FeesGuideRequest } from 'src/app/shared/services/requests/private-billing/fees-guide.request';
import { LookupResponse } from 'src/app/shared/services/responses/private-billing/lookup.response';
import { AdmissionGuideStruct } from 'src/app/shared/services/structs/private-billing/admission-guide.struct';
import { FeesProcedureStruct } from 'src/app/shared/services/structs/private-billing/fees-procedure.struct';
import { HealthcareAgreementStruct } from 'src/app/shared/services/structs/private-billing/healthcare-agreement.struct';
import { ProfessionalAgreementStruct } from 'src/app/shared/services/structs/private-billing/professional-agreement.struct';
import { ReportModalComponent } from '../../report-modal/report-modal.component';
import { environment } from 'src/environments/environment';
import { ProviderIdentityType } from 'src/app/shared/services/models/private-billing/provider-identity-type.model';
import { ProviderIdentityTypeEnum } from 'src/app/shared/enum/private-billing/provider-identity-type.enum';
import { VerifyCNPJ } from 'src/app/shared/custom-validators/cnpj.validator';
import { GuideRequest } from 'src/app/shared/services/requests/private-billing/guide.request';
import { FeesGuideStruct } from 'src/app/shared/services/structs/private-billing/fees-guide.struct';
import { OrchestratorPatientGuideService } from 'src/app/shared/services/API/orchestrator-patient/orchestrator-patient-guide.service';
import { GuideService } from 'src/app/shared/services/API/private-billing/guide.service';
import { DateValidator } from 'src/app/shared/custom-validators/date.validator';
import { FeesProcedureFormComponent } from './fees-procedure-form/fees-procedure-form.component';
import { GuideReportResponse } from 'src/app/shared/services/responses/orchestrator-patient/guide-report.response';
import { State } from 'src/app/shared/services/models/private-billing/state.model';
import { ProfessionalCouncil } from 'src/app/shared/services/models/private-billing/professional-council.model';
import { AlertModalComponent } from '../../alert-modal/alert-modal.component';
import { LookupFeesRequiredFieldEnum } from 'src/app/shared/enum/private-billing/lookup-fees-required-field.enum';
import { AuthService } from 'src/app/shared/services/auth.service';
import { WebsocketPrivateBillingUtilService } from 'src/app/shared/services/websocket-util/websocket-private-billing-util.service';

@Component({
  selector: 'app-tiss-guide-fees-modal',
  templateUrl: './tiss-guide-fees-modal.component.html',
  styleUrls: ['./tiss-guide-fees-modal.component.css']
})
export class TissGuideFeesModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private matDialogRef: MatDialogRef<TissGuideFeesModalComponent>,
    private formBuilder: FormBuilder,
    private maskService: MaskService,
    private alertService: AlertService,
    private healthcareAgreementService: HealthcareAgreementService,
    private admisionGuideService: AdmissionGuideService,
    private feesGuideService: FeesGuideService,
    private orchestratorPatientGuideService: OrchestratorPatientGuideService,
    private guideService: GuideService,
    private websocketPrivateBillingUtilService: WebsocketPrivateBillingUtilService,
    private authService: AuthService,
  ) { matDialogRef.disableClose = true; }

  @ViewChild('feesProcedureFormComponent', { static: false }) feesProcedureFormComponent: FeesProcedureFormComponent;

  public model: FormGroup;
  public masks: Masks;
  public listFeesRequiredField: FeesRequiredField[] = [];
  public listGuideStatus: GuideStatus[] = [];
  public feesFieldsMaxLengthEnum: typeof FeesFieldsMaxLengthEnum = FeesFieldsMaxLengthEnum;
  public listProviderIdentityType: ProviderIdentityType[] = this.data.lookups.listProviderIdentityType;
  public listFeesProcedure: FeesProcedureStruct[] = [];
  public listHealthcareAgreement: HealthcareAgreement[] = [];
  public listState: State[] = [];
  public listProfessionalCouncil: ProfessionalCouncil[] = [];
  public lookups: LookupResponse;
  public accessMap: Map<number, Access>;
  public techniqueMap: Map<number, Technique>;
  public participationDegreeMap: Map<number, ParticipationDegree>;
  public tussTerminologyGroupMap: Map<number, TerminologyGroup>;
  public cboMap: Map<number, Cbo>;
  public guideType: number = GuideTypeEnum.honorarios;
  public feesGuideStruct: FeesGuideStruct = new FeesGuideStruct();
  public healthcareAgreementStruct: HealthcareAgreementStruct = new HealthcareAgreementStruct();
  public listProfessionalAgreementStruct: ProfessionalAgreementStruct[] = [];
  public tissBilling: TissBilling = new TissBilling();
  public listAdmissionGuide: AdmissionGuideStruct[] = [];
  public urlListAnsRegistration: string = environment.urlApiPrivateBilling + 'HealthcareAgreement/getAllByHealthUnit';
  public requesterCodeMask: string = '';
  public executerCodeMask: string = '';
  public requesterCodeMaxLength: number = FeesFieldsMaxLengthEnum.requesterCodeOperator;
  public executantSiteCodeMaxLength: number = FeesFieldsMaxLengthEnum.requesterCodeOperator;

  public idEpisode: number = null;
  public idHealthcareAgreement: number = null;
  public idGuide: number = null;
  public idFeesGuide: number = null;
  public idSector: number = null;
  public idRoom: number = null;

  public manualAdmissionGuide: boolean = false;
  public disableProcedureEdit: boolean = false;
  public disableProcedureAdd: boolean = false;
  public isFirstLoading: boolean = false;
  public isLoading: boolean = false;
  public existsGuide: boolean = false;
  public isAttendance: boolean = false;
  public guideStatus: GuideStatus = null;
  public guideStatusColor: string = null;
  public isEditingAllowed: boolean = true;

  private throwException(error: string) {
    console.log(error);
    this.alertService.show('Erro inesperado', error, AlertType.error);
    this.isLoading = false;
  }

  ngOnInit(): void {
    if (this.data.guide) {
      this.existsGuide = true;
      this.feesGuideStruct = this.data.guide;
      this.idGuide = this.feesGuideStruct.guide.idGuide;
      this.idHealthcareAgreement = this.feesGuideStruct.guide.idHealthcareAgreement;
      this.idEpisode = this.data.idEpisode ? this.data.idEpisode : this.data.guide.guide?.idEpisode;

      if (this.data.isComingGuideReport)
        this.isEditingAllowed = this.feesGuideStruct.isEditingAllowed;
    }

    if (this.data.idEpisode != null) {
      this.idEpisode = this.data.idEpisode;
      this.getAdmissionGuides(this.idEpisode);
    }

    this.idSector = this.data.idSector
    this.idRoom = this.data.idRoom

    this.isAttendance = this.data.isAttendance;

    this.lookups = this.data.lookups;
    this.listGuideStatus = this.lookups.listGuideStatus;
    this.listProviderIdentityType = this.listProviderIdentityType.filter(item => item.idProviderIdentityType !== ProviderIdentityTypeEnum.cpf);
    this.accessMap = this.generateAccessMap(this.lookups.listAccess);
    this.techniqueMap = this.generateTechniqueMap(this.lookups.listTechnique);
    this.tussTerminologyGroupMap = this.generateTerminologyGroupMap(this.lookups.listTussTerminologyGroup);
    this.cboMap = this.generateCboMap(this.lookups.listCbo);
    this.participationDegreeMap = this.generateParticipationDegreeMap(this.lookups.listParticipationDegree);
    this.listState = this.lookups.listState;
    this.listProfessionalCouncil = this.lookups.listProfessionalCouncil;


    this.model = this.formBuilder.group({
      //Status
      idGuideStatus: [null, [Validators.required]],

      //Registro ANS
      firstChildGroup: this.formBuilder.group({
        ansCode: [this.idHealthcareAgreement, [Validators.required, Validators.maxLength(FeesFieldsMaxLengthEnum.ansCode)]], //1      
      }),
      guideNumber: ['1', [Validators.required, Validators.maxLength(FeesFieldsMaxLengthEnum.guideNumber)]], //?
      admissionGuide: ['', [Validators.required]], //3
      ticket: ['', [Validators.maxLength(FeesFieldsMaxLengthEnum.ticket)]], //4

      //Dados do beneficiário
      providerGuideNumber: ['', [Validators.maxLength(FeesFieldsMaxLengthEnum.providerGuideNumber)]], //5      
      patientDocumentNumber: ['', [Validators.required, Validators.maxLength(FeesFieldsMaxLengthEnum.patientDocumentNumber)]], //6
      patientName: ['', [Validators.required, Validators.maxLength(FeesFieldsMaxLengthEnum.patientName)]], //7
      newbornAttendance: [false, [Validators.required]], //8
      patientSocialName: ['', [Validators.maxLength(FeesFieldsMaxLengthEnum.patientSocialName)]], //41

      //Dados do Contratado (onde foi executado o procedimento) (puxar campos da guia de internação)
      idexecutionStieProviderIdentityType: ['', [Validators.required]],
      executionSiteProviderCode: ['', [Validators.required, Validators.maxLength(FeesFieldsMaxLengthEnum.executionSiteProviderCode)]], //9
      executionSiteName: ['', [Validators.required, Validators.maxLength(FeesFieldsMaxLengthEnum.executionSiteName)]], //10
      executionSiteCnesCode: ['', [Validators.required, Validators.maxLength(FeesFieldsMaxLengthEnum.executionSiteCnesCode)]], //11

      //Dados do Contratado Executante
      idProviderIdentityType: ['', [Validators.required]],
      executantProviderCode: ['', [Validators.required, Validators.maxLength(FeesFieldsMaxLengthEnum.executantProviderCode)]], //12
      executantName: ['', [Validators.required, Validators.maxLength(FeesFieldsMaxLengthEnum.executantName)]], //13
      executantCnesCode: ['', [Validators.required, Validators.maxLength(FeesFieldsMaxLengthEnum.executantCnesCode)]], //14

      //Dados da internação
      billingStartDatetime: ['', [Validators.required, Validators.maxLength(FeesFieldsMaxLengthEnum.billingStartDatetime), DateValidator()]], //15
      billingEndDatetime: ['', [Validators.required, Validators.maxLength(FeesFieldsMaxLengthEnum.billingEndDatetime), DateValidator()]], //16
      observations: ['', [Validators.maxLength(FeesFieldsMaxLengthEnum.observations)]], //37
      feesTotalValue: [{ value: '0', disbaled: true }, [Validators.required, Validators.maxLength(FeesFieldsMaxLengthEnum.feesTotalValue)]], //38
      datetimeIssue: ['', [Validators.required, DateValidator()]],//39
    });

    this.disableFields();

    this.masks = this.maskService.getMasks();

    if (this.existsGuide)
      this.populate();
    else {
      this.isLoading = false;
      this.isFirstLoading = false;
      this.model.get('idGuideStatus').setValue(GuideStatusEnum.NovaGuia.valueOf());
      this.onGuideStatusChange(GuideStatusEnum.NovaGuia.valueOf())
      if (this.data.patientName)
        this.model.get('patientName').setValue(this.data.patientName)
      if (this.data.patientSocialName)
        this.model.get('patientSocialName').setValue(this.data.patientSocialName)
    }

    if (this.idEpisode)
      this.startWebsocket();
  }

  close() {
    this.matDialogRef.close({ confirm: false });
  }

  submit() {
    if (this.model.invalid) {
      this.model.markAllAsTouched();
      this.isLoading = false;
      this.alertService.show('Erro inesperado', "Preencha corretamente os campos obrigatórios", AlertType.error);
      return;
    }

    if (!this.listFeesProcedure || this.listFeesProcedure.length == 0) {
      this.alertService.show('Erro', "Preencha pelo menos um procedimento.", AlertType.error);
      return;
    }

    let request = new FeesGuideRequest();
    request.feesGuideStruct = new FeesGuideStruct();

    request.feesGuideStruct.feesGuide.idFeesGuide = this.idFeesGuide ? this.idFeesGuide : 0;


    //Guide
    request.feesGuideStruct.guide.idGuide = this.idGuide ? this.idGuide : 0;
    request.feesGuideStruct.guide.idEpisode = this.idEpisode ? this.idEpisode : 0;
    request.feesGuideStruct.guide.idSector = this.idSector ? this.idSector : null;
    request.feesGuideStruct.guide.idRoom = this.idRoom ? this.idRoom : null;
    request.feesGuideStruct.guide.ansCode = this.healthcareAgreementStruct.ansRegistrationNumber.toString();
    request.feesGuideStruct.guide.guideNumber = this.model.get('guideNumber').value.toString();
    request.feesGuideStruct.guideNumber = this.model.get('guideNumber').value.toString();
    request.feesGuideStruct.feesGuide.providerGuideNumber = this.model.get('providerGuideNumber').value;
    request.feesGuideStruct.guide.patientName = this.model.get('patientName').value;
    request.feesGuideStruct.feesGuide.newbornAttendance = this.model.get('newbornAttendance').value;
    request.feesGuideStruct.feesGuide.patientSocialName = this.model.get('patientSocialName').value;
    request.feesGuideStruct.guide.idHealthcareAgreement = this.model.get('firstChildGroup').get('ansCode').value;
    request.feesGuideStruct.guide.idGuideStatus = this.model.get('idGuideStatus').value;

    //FeesGuide
    if (this.manualAdmissionGuide) {
      request.feesGuideStruct.feesGuide.admissionGuideNumber = this.model.get('admissionGuide').value;
      request.feesGuideStruct.admissionGuideNumber = this.model.get('admissionGuide')?.value?.toString();
    }

    else {
      let admissionGuideControl = (this.model.get('admissionGuide').value as AdmissionGuideStruct);
      request.feesGuideStruct.feesGuide.idReferenceAdmissionGuide = admissionGuideControl.guide.idGuide;
      request.feesGuideStruct.feesGuide.admissionGuideNumber = admissionGuideControl.guide.guideNumber;
    }

    request.feesGuideStruct.feesGuide.patientDocumentNumber = this.model.get('patientDocumentNumber').value;
    request.feesGuideStruct.feesGuide.ticket = this.model.get('ticket').value;
    request.feesGuideStruct.feesGuide.executionSiteProviderCode = this.model.get('executionSiteProviderCode').value;
    request.feesGuideStruct.feesGuide.executionSiteName = this.model.get('executionSiteName').value;
    request.feesGuideStruct.feesGuide.executionSiteCnesCode = this.model.get('executionSiteCnesCode').value;
    request.feesGuideStruct.feesGuide.executantProviderCode = this.model.get('executantProviderCode').value;
    request.feesGuideStruct.feesGuide.executantName = this.model.get('executantName').value;
    request.feesGuideStruct.feesGuide.executantCnesCode = this.model.get('executantCnesCode').value;
    request.feesGuideStruct.feesGuide.billingStartDatetime = this.maskService.formatStringToDate(this.model.get('billingStartDatetime').value);
    request.feesGuideStruct.feesGuide.billingEndDatetime = this.maskService.formatStringToDate(this.model.get('billingEndDatetime').value);
    request.feesGuideStruct.feesGuide.observations = this.model.get('observations').value;
    request.feesGuideStruct.feesGuide.idProviderIdentityType = this.model.get('idProviderIdentityType').value;
    request.feesGuideStruct.feesGuide.idexecutionStieProviderIdentityType = this.model.get('idexecutionStieProviderIdentityType').value;
    request.feesGuideStruct.feesGuide.feesTotalValue = parseFloat(this.model.get('feesTotalValue').value);
    request.feesGuideStruct.feesGuide.datetimeIssue = this.maskService.formatStringToDate(this.model.get('datetimeIssue').value);
    //Procedures
    request.feesGuideStruct.listFeesProcedureStruct = [...this.listFeesProcedure];

    if (request && request.feesGuideStruct && !this.isAttendance)
      this.post(request.feesGuideStruct);

    else {
      request.feesGuideStruct.hasAlteration = true;
      this.validatePost(request);
    }
  }

  print() {
    if (this.isLoading || !this.idGuide && this.isAttendance)
      return;

    this.isLoading = true;

    this.guideService.GetGuideReportName(this.idGuide).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        let reportDialog = this.dialog.open(ReportModalComponent, {
          data: {
            reportPdf64: response.reportPdf64,
          },
        });

        this.isLoading = false;
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }

  post(feesGuideStruct: FeesGuideStruct) {
    this.isLoading = true;

    let guideRequest: GuideRequest = new GuideRequest();
    guideRequest.feesGuideStruct = feesGuideStruct;

    this.orchestratorPatientGuideService.PostFeesGuide(guideRequest).subscribe({
      next: (response: GuideReportResponse) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }

        this.alertService.show('Sucesso', "Guia salva com sucesso!", AlertType.success);
        this.isLoading = false;

        const dialogRef = this.dialog.open(ReportModalComponent, {
          data: {
            reportPdf64: response.feesGuideString64,
          },
        });
        dialogRef.afterClosed().subscribe({
          next: result => {
            this.matDialogRef.close({ confirm: true, feesGuideStruct: response.feesGuideStruct, isAttendance: this.data.isAttendance });
          }
        });
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
        return;
      }
    });
  }

  validatePost(request: FeesGuideRequest) {
    this.isLoading = true;
    this.feesGuideService.ValidatePost(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.alertService.show('Sucesso', "Guia validada com sucesso!", AlertType.success);
        this.isLoading = false;
        this.matDialogRef.close({
          confirm: true,
          feesGuideStruct: request.feesGuideStruct
        });
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  async populate() {
    this.idGuide = this.feesGuideStruct.guide.idGuide;
    this.idFeesGuide = this.feesGuideStruct.feesGuide.idFeesGuide;

    //Guide
    this.model.get('firstChildGroup').get('ansCode').setValue(this.feesGuideStruct.guide.idHealthcareAgreement);
    await this.populateAnsSelects();
    if (this.feesGuideStruct.guide.idEpisode)
      await this.getAdmissionGuides(this.feesGuideStruct.guide.idEpisode);

    this.model.get('guideNumber').setValue(this.feesGuideStruct.guideNumber);
    this.model.get('guideNumber').disable();
    this.model.get('providerGuideNumber').setValue(this.feesGuideStruct.feesGuide.providerGuideNumber);
    this.model.get('patientName').setValue(this.feesGuideStruct.guide.patientName);
    this.model.get('firstChildGroup').get('ansCode').setValue(this.feesGuideStruct.guide.idHealthcareAgreement);
    this.model.get('idGuideStatus').setValue(this.feesGuideStruct.guide.idGuideStatus);
    this.onGuideStatusChange(this.feesGuideStruct.guide.idGuideStatus)

    if (this.feesGuideStruct.feesGuide.idReferenceAdmissionGuide != null && (this.listAdmissionGuide && this.listAdmissionGuide.length > 0)) {
      this.manualAdmissionGuide = false;
      this.model.get('admissionGuide').setValue(this.listAdmissionGuide.find(x => x.guide.idGuide == this.feesGuideStruct.feesGuide.idReferenceAdmissionGuide));
    }
    else {
      this.manualAdmissionGuide = true;
      this.model.get('admissionGuide').setValue(this.feesGuideStruct.admissionGuideNumber)
    }

    this.model.get('newbornAttendance').setValue(this.feesGuideStruct.feesGuide.newbornAttendance);
    this.model.get('patientSocialName').setValue(this.feesGuideStruct.feesGuide.patientSocialName);
    this.model.get('ticket').setValue(this.feesGuideStruct.feesGuide.ticket);
    this.model.get('patientDocumentNumber').setValue(this.feesGuideStruct.feesGuide.patientDocumentNumber);
    this.model.get('executionSiteProviderCode').setValue(this.feesGuideStruct.feesGuide.executionSiteProviderCode);
    this.model.get('executionSiteName').setValue(this.feesGuideStruct.feesGuide.executionSiteName);
    this.model.get('executionSiteCnesCode').setValue(this.feesGuideStruct.feesGuide.executionSiteCnesCode);
    this.model.get('executantProviderCode').setValue(this.feesGuideStruct.feesGuide.executantProviderCode);
    this.model.get('executantName').setValue(this.feesGuideStruct.feesGuide.executantName);
    this.model.get('executantCnesCode').setValue(this.feesGuideStruct.feesGuide.executantCnesCode);
    this.model.get('billingStartDatetime').setValue(this.maskService.formatDateToString(this.feesGuideStruct.feesGuide.billingStartDatetime, false));
    this.model.get('billingEndDatetime').setValue(this.maskService.formatDateToString(this.feesGuideStruct.feesGuide.billingEndDatetime, false));
    this.model.get('observations').setValue(this.feesGuideStruct.feesGuide.observations);
    this.model.get('feesTotalValue').setValue(this.feesGuideStruct.feesGuide.feesTotalValue.toString());
    this.model.get('idProviderIdentityType').setValue(this.feesGuideStruct.feesGuide.idProviderIdentityType);
    this.model.get('idexecutionStieProviderIdentityType').setValue(this.feesGuideStruct.feesGuide.idexecutionStieProviderIdentityType);
    this.model.get('datetimeIssue').setValue(this.maskService.formatDateToString(this.feesGuideStruct.feesGuide.datetimeIssue, false));

    //Procedures

    await this.feesGuideStruct.listFeesProcedureStruct.forEach(x => {
      x.referenceTable = this.tussTerminologyGroupMap.get(x.idTerminologyGroup).terminologyGroupCode;
    });

    this.listFeesProcedure = [...this.feesGuideStruct.listFeesProcedureStruct];
    this.enableFields();
    this.statusFieldControl();

    this.checkProviderIdentityType(this.model.get('idProviderIdentityType').value, true);
    this.checkExecutantProviderIdentityType(this.model.get('idexecutionStieProviderIdentityType').value, true);


    if (this.data.isComingGuideReport) {
      if (this.isEditingAllowed)
        this.model.enable();
      else if (!this.isEditingAllowed)
        this.model.disable();
    }
  }

  statusFieldControl() {
    let idStatus: number = this.model.get('idGuideStatus').value;
    switch (idStatus) {
      case GuideStatusEnum.NovaGuia:
        break;
      case GuideStatusEnum.AguardandoAutorizacao:
        break;
      case GuideStatusEnum.Negada:
        break;
      case GuideStatusEnum.Autorizada:
        Object.keys(this.model.controls).forEach(x => {
          if (this.model.get(x).value) {
            this.model.get(x).disable();
          }
        });
        this.disableProcedureEdit = true;
        break;
      case GuideStatusEnum.Cancelada:
        break;
      case GuideStatusEnum.Finalizada:
        this.model.disable();
        this.disableProcedureEdit = true;
        this.disableProcedureAdd = true;
        break;
    }
  }

  generateAccessMap(listAccess: any[]): Map<number, Access> {
    const map = new Map();
    listAccess.forEach(x => {
      map.set(x.idAccessLookup, x);
    });

    return map;
  }

  generateCboMap(listCbo: any[]): Map<number, Cbo> {
    const map = new Map();
    listCbo.forEach(x => {
      map.set(x.idCbo, x);
    });

    return map;
  }

  generateTechniqueMap(listTechnique: Technique[]): Map<number, Technique> {
    const map = new Map();
    listTechnique.forEach(x => {
      map.set(x.idTechniqueLookup, x);
    });

    return map;
  }

  generateParticipationDegreeMap(listParticipationDegree: any[]): Map<number, ParticipationDegree> {
    const map = new Map();
    listParticipationDegree.forEach(x => {
      map.set(x.idParticipationDegreeLookup, x);
    });

    return map;
  }

  generateTerminologyGroupMap(listTussTerminologyGroup: any[]): Map<number, TerminologyGroup> {
    const map = new Map();
    listTussTerminologyGroup.forEach(x => {
      map.set(x.idTerminologyGroup, x);
    });

    return map;
  }

  getAdmissionGuides(idEpisode: number) {
    if (this.idHealthcareAgreement != null) {
      this.isLoading = true;
      this.admisionGuideService.GetAdmissionGuides(idEpisode, this.idHealthcareAgreement).subscribe({
        next: (response) => {
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }
          this.isLoading = false;
          response.listAdmissionGuide.forEach(x => {
            x.expectedEndDate = this.calculateEndDate(x.admissionGuide.datetimeSuggestedAdmission.toString(), parseInt(x.admissionGuide.requesterHospitalDailyCount));
            this.listAdmissionGuide.push(x);
          });
        },
        error: (error) => {
          console.log(error);
          this.alertService.show('Erro inesperado', error, AlertType.error);
          this.isLoading = false;
        }
      });
    }
  }

  enableFields() {
    Object.keys(this.model.controls).forEach(control => {
      if (control != 'ansCode' && control != 'feesTotalValue' && control != 'guideNumber') {
        this.model.get(control).enable();
      }
    });
  }

  disableFields() {
    this.model.disable();
    this.model.get('firstChildGroup').get('ansCode').enable();
  }

  async onAnsChange(event?: any) {
    this.listProfessionalAgreementStruct = [];

    return new Promise<void>((resolve) => {
      this.idHealthcareAgreement = event ? event.idHealthcareAgreement : this.model.get('firstChildGroup').get('ansCode').value;

      if (this.model.get('firstChildGroup').get('ansCode').value)
        this.enableFields();
      else
        this.disableFields();

      this.isLoading = true;

      this.healthcareAgreementService.getAllSelect(this.idHealthcareAgreement, this.guideType).subscribe({
        next: (response) => {
          if (response.isError) {
            this.throwException(response.errorDescription);
            resolve();
            return;
          }

          if (this.existsGuide) {
            this.resetChildComponent();
            this.model.reset();
            this.manualAdmissionGuide = false;
            this.model.get('firstChildGroup').get('ansCode').setValue(this.idHealthcareAgreement);
            this.model.get('guideNumber').setValue(this.feesGuideStruct?.guideNumber);
            this.model.get('guideNumber').disable();

            if (this.data.patientName)
              this.model.get('patientName').setValue(this.data.patientName)
            if (this.data.patientSocialName)
              this.model.get('patientSocialName').setValue(this.data.patientSocialName)
          }

          this.healthcareAgreementStruct = response.healthcareAgreementStruct;
          this.tissBilling = response.tissBilling;

          this.listProfessionalAgreementStruct = response.listProfessionalAgreementStruct.filter(x => {
            if (x.isActive)
              return true;
            else if (this.feesGuideStruct.listFeesProcedureStruct.find(y => y.listProfessional.find(p => p.idProfessionalAgreement == x.idProfessionalAgreement)))
              return true;
          });
          if (!this.listProfessionalAgreementStruct || this.listProfessionalAgreementStruct.length == 0) {
            this.openAlertModal();
          }

          if (!this.existsGuide) {
            this.model.get('guideNumber').setValue(response.guideNumber);
            this.model.get('guideNumber').disable();
            this.model.get('admissionGuide').setValue(null);

            if (response.tissBilling) {
              this.model.get('executionSiteProviderCode').setValue(response.tissBilling.providerCode);
              this.model.get('executionSiteName').setValue(response.tissBilling.providerName);
              this.model.get('executionSiteCnesCode').setValue(response.tissBilling.cnes);
              this.model.get('idexecutionStieProviderIdentityType').setValue(response.tissBilling.idProviderIdentityType);

              if (this.model.get('idProviderIdentityType'))
                this.checkProviderIdentityType(this.tissBilling?.idProviderIdentityType);
            }

            if (this.idEpisode)
              this.getAdmissionGuides(this.idEpisode);
          }

          if (response.listFeesRequiredField) {
            this.listFeesRequiredField = response.listFeesRequiredField;
            this.verifyRequiredFields();
          }

          this.isLoading = false;
          resolve();
        },
        error: (error) => {
          this.throwException(error);
          resolve();
        }
      });
    });
  }

  verifyRequiredFields() {
    this.listFeesRequiredField.forEach(x => {
      if (x.idLookupFeesRequiredField == LookupFeesRequiredFieldEnum.senhaAutorizacao) {
        this.model.get('ticket').setValidators([Validators.required]);
        this.model.get('ticket').updateValueAndValidity();
      }
      else if (x.idLookupFeesRequiredField == LookupFeesRequiredFieldEnum.nGuiaPrincipal) {
        this.model.get('providerGuideNumber').setValidators([Validators.required]);
        this.model.get('providerGuideNumber').updateValueAndValidity();
      }
      else if (x.idLookupFeesRequiredField == LookupFeesRequiredFieldEnum.observacao) {
        this.model.get('observations').setValidators([Validators.required]);
        this.model.get('observations').updateValueAndValidity();
      }

    });
  }

  async populateAnsSelects() {
    return new Promise<void>((resolve) => {
      this.idHealthcareAgreement = this.model.get('firstChildGroup').get('ansCode').value;

      if (this.model.get('firstChildGroup').get('ansCode').value)
        this.enableFields();
      else
        this.disableFields();

      this.isLoading = true;

      this.healthcareAgreementService.getAllSelect(this.idHealthcareAgreement, this.guideType).subscribe({
        next: (response) => {
          if (response.isError) {
            this.throwException(response.errorDescription);
            resolve();
            return;
          }

          this.healthcareAgreementStruct = response.healthcareAgreementStruct;
          this.tissBilling = response.tissBilling;

          this.listProfessionalAgreementStruct = response.listProfessionalAgreementStruct.filter(x => {
            if (x.isActive)
              return true;
            else if (this.feesGuideStruct.listFeesProcedureStruct.find(y => y.listProfessional.find(p => p.idProfessionalAgreement == x.idProfessionalAgreement)))
              return true;
          });

          if (!this.listProfessionalAgreementStruct || this.listProfessionalAgreementStruct.length == 0) {
            this.openAlertModal();
          }

          if (response.listFeesRequiredField) {
            this.listFeesRequiredField = response.listFeesRequiredField;
            this.verifyRequiredFields();
          }

          this.isLoading = false;
          resolve();
        },
        error: (error) => {
          this.throwException(error);
          resolve();
        }
      });
    });
  }

  onAdmissionGuideChange(event: AdmissionGuideStruct) {
    this.model.get('idexecutionStieProviderIdentityType').setValue(ProviderIdentityTypeEnum.providerCode);
    this.model.get('executantProviderCode').setValue(event.admissionGuide.requesterHospitalCode);
    this.model.get('idProviderIdentityType').setValue(event.admissionGuide.idProviderIdentityType == ProviderIdentityTypeEnum.cpf ? ProviderIdentityTypeEnum.providerCode : event.admissionGuide.idProviderIdentityType);
    this.model.get('executantName').setValue(event.admissionGuide.requesterHospitalName);
    this.model.get('executantCnesCode').setValue(event.admissionGuide.codeCnes);
    this.model.get('patientName').setValue(event.guide.patientName);
    this.model.get('patientSocialName').setValue(event.guide.patientSocialName);
    this.model.get('patientDocumentNumber').setValue(event.admissionGuide.beneficiaryDocumentNumber);
  }

  onManualAdmissionSelect(event: any) {
    this.model.get('admissionGuide').setValue(null);
  }

  calculateEndDate(start: string, days: number): Date {
    let startDate = new Date(start);

    let startTime = startDate.getTime();

    let endTime = startTime + (days * 24 * 60 * 60 * 1000);

    return new Date(endTime);
  }

  calculateTotalFeesValue() {
    let totalValue: number = 0;
    for (let index = 0; index < this.listFeesProcedure.length; index++) {
      this.listFeesProcedure[index].procedureReferenceIndex = index + 1;
      totalValue += this.listFeesProcedure[index].procedureTotalValue;
    }
    this.model.get('feesTotalValue').setValue(totalValue);
    return;
  }

  getAnsCodeValue() {
    return this.model.get('firstChildGroup').get('ansCode').value;
  }

  checkProviderIdentityType(idProviderIdentityType: number, isPopulate: boolean = false) {
    if (!isPopulate) {
      this.model.get('executantProviderCode').setValue(null);
    }
    if (idProviderIdentityType == ProviderIdentityTypeEnum.cnpj) {
      this.requesterCodeMask = this.masks.cnpj;
      this.requesterCodeMaxLength = null;
      this.model.get('executantProviderCode').setValidators([Validators.required, Validators.maxLength(FeesFieldsMaxLengthEnum.requesterCodeOperatorCnpj), VerifyCNPJ()]);
    }
    else if (idProviderIdentityType == ProviderIdentityTypeEnum.providerCode) {
      this.requesterCodeMask = '';
      this.requesterCodeMaxLength = FeesFieldsMaxLengthEnum.requesterCodeOperator;
      this.model.get('executantProviderCode').setValidators([Validators.required, Validators.maxLength(this.requesterCodeMaxLength)])
      this.model.get('executantProviderCode').updateValueAndValidity();
    }
  }

  checkExecutantProviderIdentityType(idProviderIdentityType: number, isPopulate: boolean = false) {
    if (!isPopulate) {
      this.model.get('executionSiteProviderCode').setValue(null);
    }
    if (idProviderIdentityType == ProviderIdentityTypeEnum.cnpj) {
      this.executerCodeMask = this.masks.cnpj;
      this.executantSiteCodeMaxLength = null;
      this.model.get('executionSiteProviderCode').setValidators([Validators.required, Validators.maxLength(FeesFieldsMaxLengthEnum.requesterCodeOperatorCnpj), VerifyCNPJ()]);
    }
    else if (idProviderIdentityType == ProviderIdentityTypeEnum.providerCode) {
      this.executerCodeMask = '';
      this.executantSiteCodeMaxLength = FeesFieldsMaxLengthEnum.requesterCodeOperator;
      this.model.get('executionSiteProviderCode').setValidators([Validators.required, Validators.maxLength(this.executantSiteCodeMaxLength)])
      this.model.get('executionSiteProviderCode').updateValueAndValidity();
    }
  }

  resetChildComponent() {
    if (this.feesProcedureFormComponent && typeof this.feesProcedureFormComponent.reset === 'function')
      this.feesProcedureFormComponent.reset();
  }

  openAlertModal() {
    let reportDialog = this.dialog.open(AlertModalComponent, {
      data: {
        title: "Atenção!",
        description: "Este convênio não possui profissionais vinculados. Execute o cadastro para salvar a guia corretamente.",
      },
    });
  }

  inputNumber(formName: string) {
    let value = this.model.get(formName).value ? this.model.get(formName).value : '';
    if (value) {
      value = value.replace(/[^0-9]/g, '');
      value = value.replace(/(\..*)\./g, '$1');
      this.model.get(formName).setValue(value);
    }
  }

  //websocket
  startWebsocket() {
    if (!this.websocketPrivateBillingUtilService.isConnect())
      this.websocketPrivateBillingUtilService.connectWebsocketGuide(this, this.idEpisode, this.guideType, this.authService.getTokenMenu().login, this.authService.getTokenMenu().userName);

    this.websocketPrivateBillingUtilService.setVariables(this.idEpisode, this.guideType, this.authService.getTokenMenu().login, this.authService.getTokenMenu().userName);
    this.websocketPrivateBillingUtilService.setFunctions(this.someoneIsConectedOnEditPrivateBilling);
  }

  someoneIsConectedOnEditPrivateBilling() {
    if (this.websocketPrivateBillingUtilService.isConnect())
      this.websocketPrivateBillingUtilService.clearSocket();

    this.close();
  }

  onGuideStatusChange(idGuideStatus: number) {
    this.guideStatus = this.listGuideStatus.find(x => x.idGuideStatus == idGuideStatus);
    this.guideStatusColor = this.guideStatus.color;
  }

  ngOnDestroy() {
    if (this.websocketPrivateBillingUtilService.isConnect())
      this.websocketPrivateBillingUtilService.clearSocket();
  }
}