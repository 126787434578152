import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-greater-gravity-modal',
  templateUrl: './greater-gravity-modal.component.html',
  styleUrls: ['./greater-gravity-modal.component.css']
})
export class GreaterGravityModalComponent {

  constructor(@Inject(MAT_DIALOG_DATA) private data: any, public dialog: MatDialog, public matDialogRef: MatDialogRef<GreaterGravityModalComponent>) { }

  public discriminatorName: string;
  public priorityName: string;
  public discriminatorNowName: string;
  ngOnInit(): void {
    this.discriminatorNowName = this.data.discriminatorNowName;
    this.discriminatorName = this.data.discriminatorName;
    this.priorityName = this.data.priorityName;
  }

  close() {
    this.matDialogRef.close();
  }

  clickResponse(value: boolean) {
    this.matDialogRef.close({ response: value });
  }
}

