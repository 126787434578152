import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'tolifecloud-ui';

  constructor(public updates:SwUpdate) {
    this.updateClient();
  }
  
  updateClient() {
    if (!this.updates.isEnabled) {
      console.log('Not Enabled');
      return;
    }
    this.updates.available.subscribe((event) => {
      console.log(`current`, event.current, `available `, event.available);
      this.updates.activateUpdate().then(() => location.reload());
    });

    this.updates.activated.subscribe((event) => {
      console.log(`current`, event.previous, `available `, event.current);
    });
  }
}
