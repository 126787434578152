export enum SpSadtFieldsMaxLengthEnum {
    ansRegistration = 6,
    guideNumber = 20,
    mainGuideNumber = 20,
    password = 20,
    guideNumberAssignedByTheOperator = 20,

    beneficiaryDocumentNumber = 20,
    beneficiarySocialName = 70,
    beneficiaryName = 70,

    requesterCodeOperatorCnpj = 14,
    requesterCodeOperatorCpf = 11,
    requesterCodeOperator = 14,
    requesterContractorOperatorCode = 14,
    requesterContractorName = 70,
    requesterProfessionalName = 70,
    requesterProfessionalCouncilNumber = 15,

    clinicalIndication = 500,

    requestedProcedureServiceItemDescription = 150,
    requestedQuantityProcedureServiceItem = 3,
    authorizedQuantityProcedureServiceItem = 3,

    executantContractorOperatorCode = 14,
    executantContractorName = 70,
    executantCnes = 7,

    procedureCount = 3,

    observationJustification = 500,
  }
  