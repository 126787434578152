import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CommonService } from "src/app/shared/services/common-service";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';
import { catchError } from "rxjs/operators";
import { HumanizedHeaderResponse } from "../../responses/orchestrator-patient/humanized-header.response";

@Injectable({
    providedIn: 'root'
  })
  export class HumanizedHeaderService extends CommonService{
  
    constructor(private router: Router, private httpClient: HttpClient) {
      super();
    }

    public getEpisode(idEpisode:number): Observable<HumanizedHeaderResponse>{
      let uri = `HumanizedHeader/idEpisode/${idEpisode}`
  
      return this.httpClient.get<HumanizedHeaderResponse>(environment.urlApiOrchestratorPatient + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}