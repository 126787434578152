import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TissGuideSpSadtModalComponent } from '../tiss-guide-sp-sadt-modal/tiss-guide-sp-sadt-modal.component';
import { LookupService } from 'src/app/shared/services/API/private-billing/lookup.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { GuideType } from 'src/app/shared/services/models/private-billing/guide-type.model';
import { GuideTypeEnum } from 'src/app/shared/enum/private-billing/guide-type.enum';
import { LookupResponse } from 'src/app/shared/services/responses/private-billing/lookup.response';
import { TissGuideFeesModalComponent } from '../tiss-guide-fees-modal/tiss-guide-fees-modal.component';
import { GuideStatus } from 'src/app/shared/services/models/private-billing/guide-status.model';
import { Access } from 'src/app/shared/services/models/private-billing/access.model';
import { TissGuideAdmissionModalComponent } from '../tiss-guide-admission-modal/tiss-guide-admission-modal.component';
import { TissGuideAppointmentComponent } from '../tiss-guide-appointment/tiss-guide-appointment.component';
import { GuideService } from 'src/app/shared/services/API/private-billing/guide.service';
import { Guide } from 'src/app/shared/services/models/private-billing/guide.model';
import { AdmissionGuideStruct } from 'src/app/shared/services/structs/private-billing/admission-guide.struct';
import { AppointmentGuideStruct } from 'src/app/shared/services/structs/private-billing/appointment-guide.struct';
import { SpSadtGuideStruct } from 'src/app/shared/services/structs/private-billing/sp-sadt-guide.struct';
import { FeesGuideStruct } from 'src/app/shared/services/structs/private-billing/fees-guide.struct';
import { GuideRequest } from 'src/app/shared/services/requests/private-billing/guide.request';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { GuideStructResponse } from 'src/app/shared/services/responses/private-billing/guide-struct.response';
import { ReportModalComponent } from '../../report-modal/report-modal.component';

@Component({
  selector: 'app-tiss-guide-management',
  templateUrl: './tiss-guide-management.component.html',
  styleUrls: ['./tiss-guide-management.component.css']
})
export class TissGuideManagementComponent {
  constructor(
    public dialog: MatDialog,
    private privateBillingLookupService: LookupService,
    private alertService: AlertService,
    private guideService: GuideService,
    private maskService: MaskService,
  ) { }

  @Input() isAttendance: boolean = true;
  @Input() idRoom: number;
  @Input() idSector: number;
  @Input() idEpisode: number = null;
  @Input() idPatient: number = null;
  @Input() patientSocialName: string = null;
  @Input() patientName: string = null;
  @Output() changedGuide = new EventEmitter<any>();
  @Output() operationCompleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  public isFirstLoading: boolean = true;
  public isLoading: boolean = true;

  public nameStatus: string = null;
  public tissGuide: number = 0;
  public admissionGuideEnum: number = GuideTypeEnum.internacao;
  public appointmentGuideEnum: number = GuideTypeEnum.consulta;
  public spSadtGuideEnum: number = GuideTypeEnum.spSadt;
  public feesGuideEnum: number = GuideTypeEnum.honorarios;
  public masks: Masks = this.maskService.getMasks();

  public lookups: LookupResponse;

  public guide: any = null;
  public listTissGuide: GuideType[] = [];
  public listGuideStatus: GuideStatus[] = [];
  public listGuide: Guide[] = [];
  public listAccess: Access[] = [];
  public listAdmissionGuideStruct: AdmissionGuideStruct[] = [];
  public listSpSadtGuideStruct: SpSadtGuideStruct[] = [];
  public listAppointmentGuideStruct: AppointmentGuideStruct[] = [];
  public listFeesGuideStruct: FeesGuideStruct[] = [];
  public guideRequest: GuideRequest = new GuideRequest();
  public guideStructResponse: GuideStructResponse = new GuideStructResponse();

  private throwException(error: string) {
    console.log(error);
    this.alertService.show('Erro inesperado', error, AlertType.error);
    this.isLoading = false;
  }

  ngOnInit(): void {
    this.getLookup();
  }

  getGuidRequest(): GuideRequest {
    return this.guideRequest;
  }

  submit() {
  }

  choiceGuide() {
    if (this.tissGuide == GuideTypeEnum.spSadt) {
      const dialogRef = this.dialog.open(TissGuideSpSadtModalComponent, {
        data: {
          lookups: this.lookups,
          idEpisode: this.idEpisode,
          guide: this.guide,
          isAttendance: this.isAttendance,
          patientName: this.patientName,
          patientSocialName: this.patientSocialName,
          idSector: this.idSector,
          idRoom: this.idRoom,
          idPatient: this.idPatient,
          isComingGuideReport: false
        },
        panelClass: 'sp-sadt-tiss-guide-modal',
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.confirm && result.spSadtGuideStruct) {
          this.guideRequest.spSadtGuideStruct = new SpSadtGuideStruct();
          this.guideRequest.spSadtGuideStruct = result.spSadtGuideStruct;

          const existingIndex = this.listSpSadtGuideStruct.findIndex(x => x.guide.idGuide === this.guideRequest.spSadtGuideStruct.guide.idGuide);

          if (existingIndex !== -1)
            this.listSpSadtGuideStruct[existingIndex] = this.guideRequest.spSadtGuideStruct;
          else if (this.guideRequest.spSadtGuideStruct.guide.idGuide === null || this.guideRequest.spSadtGuideStruct.guide.idGuide === undefined) {
            this.listSpSadtGuideStruct.slice();
            this.listSpSadtGuideStruct.push(this.guideRequest.spSadtGuideStruct);
          }
          else
            this.listSpSadtGuideStruct.push(this.guideRequest.spSadtGuideStruct);
        }

        this.tissGuide = null;
        this.guide = null;
      });
    }
    else if (this.tissGuide == GuideTypeEnum.honorarios) {
      const dialogRef = this.dialog.open(TissGuideFeesModalComponent, {
        data: {
          lookups: this.lookups,
          idEpisode: this.idEpisode,
          idSector: this.idSector,
          idRoom: this.idRoom,
          guide: this.guide,
          isAttendance: this.isAttendance,
          idPatient: this.idPatient,
          patientName: this.patientName,
          patientSocialName: this.patientSocialName,
          isComingGuideReport: false
        },
        panelClass: 'fees-tiss-guide-modal',
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.confirm == true && result.feesGuideStruct) {
          this.guideRequest.feesGuideStruct = new FeesGuideStruct();
          this.guideRequest.feesGuideStruct = result.feesGuideStruct;

          const existingIndex = this.listFeesGuideStruct.findIndex(x => x.guide.idGuide == this.guideRequest.feesGuideStruct.guide.idGuide);

          if (existingIndex !== -1)
            this.listFeesGuideStruct[existingIndex] = this.guideRequest.feesGuideStruct;
          else
            this.listFeesGuideStruct.push(this.guideRequest.feesGuideStruct);
        }

        this.tissGuide = null;
        this.guide = null;
      });
    }

    else if (this.tissGuide == GuideTypeEnum.internacao) {
      const dialogRef = this.dialog.open(TissGuideAdmissionModalComponent, {
        data: {
          listGuideStatus: this.listGuideStatus,
          lookups: this.lookups,
          idEpisode: this.idEpisode,
          idSector: this.idSector,
          idRoom: this.idRoom,
          guide: this.guide,
          isAttendance: this.isAttendance,
          idPatient: this.idPatient,
          patientName: this.patientName,
          patientSocialName: this.patientSocialName,
          isComingGuideReport: false
        },
        panelClass: 'admission-tiss-guide-modal',
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.confirm && result.admissionGuideStruct) {
          this.guideRequest.admissionGuideStruct = new AdmissionGuideStruct();
          this.guideRequest.admissionGuideStruct = result.admissionGuideStruct;

          const existingIndex = this.listAdmissionGuideStruct.findIndex(x => x.guide.idGuide == this.guideRequest.admissionGuideStruct.guide.idGuide);

          if (existingIndex !== -1)
            this.listAdmissionGuideStruct[existingIndex] = this.guideRequest.admissionGuideStruct;
          else
            this.listAdmissionGuideStruct.push(this.guideRequest.admissionGuideStruct);
        }

        this.tissGuide = null;
        this.guide = null;
      });
    }

    else if (this.tissGuide == GuideTypeEnum.consulta) {
      const dialogRef = this.dialog.open(TissGuideAppointmentComponent, {
        data: {
          listGuideStatus: this.listGuideStatus,
          lookups: this.lookups,
          guide: this.guide,
          idEpisode: this.idEpisode,
          idSector: this.idSector,
          idRoom: this.idRoom,
          isAttendance: this.isAttendance,
          idPatient: this.idPatient,
          patientName: this.patientName,
          patientSocialName: this.patientSocialName,
          isComingGuideReport: false
        },
        panelClass: 'appointment-tiss-guide-modal',
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.confirm && result.appointmentGuideStruct) {
          this.guideRequest.appointmentGuideStruct = new AppointmentGuideStruct();
          this.guideRequest.appointmentGuideStruct = result.appointmentGuideStruct;

          const existingIndex = this.listAppointmentGuideStruct.findIndex(x => x.guide.idGuide == this.guideRequest.appointmentGuideStruct.guide.idGuide);

          if (existingIndex !== -1)
            this.listAppointmentGuideStruct[existingIndex] = this.guideRequest.appointmentGuideStruct;
          else
            this.listAppointmentGuideStruct.push(this.guideRequest.appointmentGuideStruct);
        }

        this.tissGuide = null;
        this.guide = null;
      });
    }
  }

  getLookup() {
    this.privateBillingLookupService.listAllLookups().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show("Houve um erro", response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.lookups = response;
        this.listTissGuide = response.listGuideType;
        this.listGuideStatus = response.listGuideStatus;
        this.listAccess = response.listAccess;

        if (this.idEpisode != null)
          this.getListGuide();
        else {
          this.isFirstLoading = false;
          this.isLoading = false;
        }
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show("Houve um erro", error, AlertType.error);
      }
    });
  }

  getListGuide() {
    this.guideService.GetListByIdEpisode(this.idEpisode).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show("Houve um erro", response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listAdmissionGuideStruct = response.listAdmissionGuideStruct;
        this.listSpSadtGuideStruct = response.listSpSadtGuideStruct;
        this.listAppointmentGuideStruct = response.listAppointmentGuideStruct;
        this.listFeesGuideStruct = response.listFeesGuideStruct;

        this.guideRequest = new GuideRequest();
        this.isFirstLoading = false;
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show("Houve um erro", error, AlertType.error);
      }
    });
  }

  getGuideName(idGuideStatus: number): string {
    const guideStatus = this.listGuideStatus.find(x => x.idGuideStatus === idGuideStatus);
    return guideStatus ? guideStatus.name : '';
  }

  getGuideColor(idGuideStatus: number): string {
    const guideStatus = this.listGuideStatus.find(x => x.idGuideStatus === idGuideStatus);
    return guideStatus ? guideStatus.color : '';
  }

  selectGuide(guide: any, guideType: number) {
    this.tissGuide = guideType;
    this.guide = guide;
    this.choiceGuide();
  }

  isListPopulated(idGuideType: number): boolean {
    switch (idGuideType) {
      case Number(this.admissionGuideEnum):
        return this.listAdmissionGuideStruct.length > 0;
      case Number(this.appointmentGuideEnum):
        return this.listAppointmentGuideStruct.length > 0;
      case Number(this.spSadtGuideEnum):
        return this.listSpSadtGuideStruct.length > 0;
      case Number(this.feesGuideEnum):
        return this.listFeesGuideStruct.length > 0;
      default:
        return false;
    }
  }

  print(guide: any) {
    if (this.isLoading)
      return;

    this.isLoading = true;

    this.guideService.GetGuideReportName(guide.guide.idGuide).subscribe({
      next: (response) => {
        if (response.isError) {
          this.throwException(response.errorDescription);
          return;
        }
        let reportDialog = this.dialog.open(ReportModalComponent, {
          data: {
            reportPdf64: response.reportPdf64,
          },
        });
        this.isLoading = false;
      },
      error: (error) => {
        this.throwException(error);
      }
    });
  }
}