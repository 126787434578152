import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-vital-history-modal',
  templateUrl: './vital-history-modal.component.html',
  styleUrls: ['./vital-history-modal.component.css']
})
export class VitalHistoryModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<VitalHistoryModalComponent>
  ) { }

  ngOnInit(): void {
  }

}
