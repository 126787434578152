import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { CallPanelAlertService } from 'src/app/shared/services/API/orchestrator-panel/call-panel-alert.service';
import { CallPanelEpisodeRequest } from 'src/app/shared/services/requests/orchestrator-panel/call-panel-episode.request';
import { PanelCallEpisodeStruct } from 'src/app/shared/services/structs/orchestrator-panel/panel-call-episode.struct';
import { PatientListCallConfirmModalComponent } from '../patient-list-call-confirm-modal/patient-list-call-confirm-modal.component';

@Component({
  selector: 'app-patient-list-alert-call-confirm-modal',
  templateUrl: './patient-list-alert-call-confirm-modal.component.html',
  styleUrls: ['./patient-list-alert-call-confirm-modal.component.css']
})
export class PatientListAlertCallConfirmModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<PatientListAlertCallConfirmModalComponent>,
  private alertService: AlertService,
  private callPanelAlertService: CallPanelAlertService) { }

  public isLoading:boolean;  

  ngOnInit(): void {
    this.isLoading = false;
  }
  
  close(){
    this.matDialogRef.close(); 
  }
  
  clickConfirm(){
    this.isLoading = true;
    let request: CallPanelEpisodeRequest = new CallPanelEpisodeRequest();
    request.episodeData = new PanelCallEpisodeStruct();
    request.episodeData = this.data.episodeData;
    
    this.callPanelAlertService.alertCallPatient(request, this.data.idQueue, this.data.idRoom, true, this.data.idModule).subscribe({
      next:(response)=>{
      
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }   
        if(response.errorCode == -1){
          this.alertService.show('Aviso', response.errorDescription, AlertType.error);
        }
        if(response.isConfirm){
          this.openConfirmModal(response.confirmMessage);
        }
        else{
          this.isLoading = false;    
          this.matDialogRef.close({isCalled: true}); 
        }
      },
      error :(error)=>{
        console.log(error)
        this.isLoading = false;    
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
  
  clickCancel(){
    this.matDialogRef.close(); 
  }

  openConfirmModal(confirmMessage: string){
    const dialogRef = this.dialog.open(PatientListCallConfirmModalComponent, {
      data: {
        confirmMessage: confirmMessage,
        idQueue: this.data.idQueue,
        idRoom: this.data.idRoom,
        idModule: this.data.idModule,
        episodeData: this.data.episodeData,
      },
    });    
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.isCalled){
        this.isLoading = false;    
        this.matDialogRef.close({isCalled: true}); 
      }
    });
  }
}
