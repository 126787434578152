import { AppointmentGuide } from "../../models/private-billing/appointment-guide.model";
import { Guide } from "../../models/private-billing/guide.model";

export class AppointmentGuideStruct {
    public guide: Guide = new Guide();
    public appointmentGuide: AppointmentGuide = new AppointmentGuide();
    public idTerminologyGroup: number;
    public idProcedure: number;
    public newbornCode: string;
    public professionalCounselingCode: string;
    public professionalUfCode: string;
    public professionalCboCode: string;
    public accidentIndicationCode: string;
    public specialCoverageCode: string;
    public careRegimeCode: string;
    public occupationalHealthCode: string;
    public appointmentTypeCode: string;
    public logoName: string;
    public terminologyGroupCode: string;
    public hasAlteration: boolean = false;
    public isEditingAllowed: boolean;
    public guideNumber: string;
}