import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsClassificationService } from 'src/app/classification/utils.service';
import { DiscriminatorTypeEnum } from 'src/app/shared/enum/discriminator-type.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { SubDiscriminatorModel } from 'src/app/shared/services/models/risk-classification/subDiscriminator.model';
import { DiscriminatorStruct } from 'src/app/shared/services/structs/risk-classification/discriminator.struct';
import { SubDiscriminatorStruct } from 'src/app/shared/services/structs/risk-classification/sub-discriminator.struct';


export class FlowChartStructure {
  public column: SubDiscriminatorModel;
  public openModalValidate: boolean;
  public forceOpenModal: boolean;
}

@Component({
  selector: 'app-sepsis-crianca-modal',
  templateUrl: './sepsis-crianca-modal.component.html',
  styleUrls: ['./sepsis-crianca-modal.component.css']
})
export class SepsisCriancaModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<SepsisCriancaModalComponent>,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private utilService: UtilsClassificationService) { }

  public model: UntypedFormGroup;
  public firstColumn: FlowChartStructure[] = [];
  public list: DiscriminatorStruct[] = [];
  public subList: SubDiscriminatorStruct[] = [];
  public discriminatorName: string;
  public hideTemperature: boolean = false;
  public discriminatorTypeTemperature: number = DiscriminatorTypeEnum.Temperatura;

  ngOnInit(): void {
    this.model = this.formBuilder.group({});
    this.discriminatorName = this.data.discriminator.discriminatorName;
    this.list = this.data.list;
    this.data.discriminator.subDiscriminator.forEach((value, index) => {
      let flowStructure: FlowChartStructure = { column: value, openModalValidate: index != 0, forceOpenModal: false };
      let dis = this.list.find(c => c.idDiscriminatorType == value.idDiscriminatorType);
      let disValue = dis && dis.value != null && dis.value != undefined ? dis.value : null;
      disValue = disValue != null ? disValue : this.data.discriminatorsValues[value.idDiscriminatorType];
      if (value.idDiscriminatorType != DiscriminatorTypeEnum.Boleano) {
        let sub: SubDiscriminatorStruct = { ...value, state: dis ? dis.state : null, type: dis ? dis.type : null, value: disValue };
        this.subList.push(sub);
      } else {
        let sub: SubDiscriminatorStruct = { ...value };
        this.subList.push(sub);
      }
      this.firstColumn.push(flowStructure);
      this.model.addControl("radio-" + value.idSubDiscriminator.toString() + "-" + value.idDiscriminator.toString(), this.formBuilder.control({ value: '', disabled: !(index == 0 && value.idDiscriminatorType == DiscriminatorTypeEnum.Boleano) }));
    });
  }

  close() {
    this.matDialogRef.close();
  }

  setDiscriminatorsValues(discriminatorsValues: any): void {
    if (this.subList[discriminatorsValues.index].idDiscriminatorType == DiscriminatorTypeEnum.Temperatura) {
      let result = this.utilService.verifySelectGeneric(this.subList[discriminatorsValues.index].minValueAllowed, this.subList[discriminatorsValues.index].maxValueAllowed, this.subList[discriminatorsValues.index].value);
      if (this.subList[0].select && result) {
        let motive = discriminatorsValues.motive ? discriminatorsValues.motive : null;
        this.matDialogRef.close({ discriminatorsSelected: discriminatorsValues, value: true, select: true, list: this.list, discriminatorsValues: this.data.discriminatorsValues, motive });
      }
    }

    if (this.subList[discriminatorsValues.index].value || (discriminatorsValues.select && discriminatorsValues.motive)) {
      this.subList[discriminatorsValues.index].select = discriminatorsValues.select;
      if (DiscriminatorTypeEnum.Boleano != this.subList[discriminatorsValues.index].idDiscriminatorType && DiscriminatorTypeEnum.Numerico != this.subList[discriminatorsValues.index].idDiscriminatorType) {
        this.data.discriminatorsValues[this.subList[discriminatorsValues.index].idDiscriminatorType] = this.subList[discriminatorsValues.index].value;
        this.list.forEach((c) => {
          if (c.idDiscriminatorType == this.subList[discriminatorsValues.index].idDiscriminatorType) {
            c.value = this.subList[discriminatorsValues.index].value;
            c.type = this.subList[discriminatorsValues.index].type;
            c.state = this.subList[discriminatorsValues.index].state;
            c.hasValue = true;
          }
        })
      }
      if (this.subList[discriminatorsValues.index].select) {
        let motive = discriminatorsValues.motive ? discriminatorsValues.motive : null;
        this.matDialogRef.close({ discriminatorsSelected: this.data.discriminator, select: true, list: this.list, discriminatorsValues: this.data.discriminatorsValues, value: true, motive });
      }
      this.model.get("radio-" + this.subList[discriminatorsValues.index].idSubDiscriminator + "-" + this.subList[discriminatorsValues.index].idDiscriminator).enable();
    }
  }

  forceToOpenModal(item: SubDiscriminatorModel): void {
    if (item.idDiscriminatorType == DiscriminatorTypeEnum.Boleano)
      return;
    let discriminator = this.subList.find(c => c.idSubDiscriminator == item.idSubDiscriminator);
    if (!discriminator.hasValue) {
      let indexFirstColumn = this.firstColumn.findIndex(c => c.column.idSubDiscriminator == item.idSubDiscriminator);
      if (indexFirstColumn >= 0) {
        this.firstColumn[indexFirstColumn].forceOpenModal = !this.firstColumn[indexFirstColumn].forceOpenModal;
      }
    }
  }

  setValue(item: SubDiscriminatorModel, type: boolean): void {
    let index = this.subList.findIndex(c => c.idSubDiscriminator == item.idSubDiscriminator);
    let indexTemperature = this.subList.findIndex(c => c.idDiscriminatorType == DiscriminatorTypeEnum.Temperatura);
    if (!type) {
      if (DiscriminatorTypeEnum.FrequenciaCardiaca == item.idDiscriminatorType) {
        let result = this.utilService.verifySelectHeartRate(item, this.subList[0].value, this.subList[0].description, true);
        if (result) {
          this.hideTemperature = false;
          this.model.get("radio-" + this.subList[indexTemperature].idSubDiscriminator + "-" + this.subList[indexTemperature].idDiscriminator).setValue(null);
        }
        else {
          this.hideTemperature = true;
          let temperatureDisc = this.firstColumn.find(x => x.column.idDiscriminatorType == this.discriminatorTypeTemperature);
          this.setValue(temperatureDisc.column, false)
        }
        this.utilService.updateHideTemperatureChildSepsis(this.hideTemperature);
        if (result && this.subList[1].value) {
          if (this.utilService.verifySelectGeneric(this.subList[1].minValueAllowed, this.subList[1].maxValueAllowed, this.subList[1].value)) {
            this.matDialogRef.close({ discriminatorsSelected: this.data.discriminator, select: true, list: this.list, discriminatorsValues: this.data.discriminatorsValues, value: true });
            return;
          }
        }
      } else if (DiscriminatorTypeEnum.Temperatura == item.idDiscriminatorType) {
        let resultTemp = this.utilService.verifySelectGeneric(this.subList[1].minValueAllowed, this.subList[1].maxValueAllowed, this.subList[1].value);
        let resultFreq = this.utilService.verifySelectHeartRate(this.subList[0], this.subList[0].value, this.subList[0].description, true);
        if (resultTemp && resultFreq) {
          this.matDialogRef.close({ discriminatorsSelected: this.data.discriminator, select: true, list: this.list, discriminatorsValues: this.data.discriminatorsValues, value: true });
          return;
        }
      }
      this.validateToOpenModal(item);
      if (index + 1 >= this.subList.length && (item.idDiscriminatorType == DiscriminatorTypeEnum.Boleano || this.subList[index].value)) {
        this.matDialogRef.close({ discriminator: this.data.discriminator, value: true, select: false, list: this.list, discriminatorsValues: this.data.discriminatorsValues });
      } else if (index + 1 < this.subList.length && this.subList[index + 1].idDiscriminatorType == DiscriminatorTypeEnum.Boleano) {
        this.model.get("radio-" + this.subList[index + 1].idSubDiscriminator + "-" + this.subList[index + 1].idDiscriminator).enable();
      }
    } else {
      if (item.idDiscriminatorType == DiscriminatorTypeEnum.FrequenciaCardiaca) {
        if (this.subList[1].value) {
          let result = this.utilService.verifySelectGeneric(item.minValueAllowed, item.maxValueAllowed, this.subList[1].value);
          if (this.subList[0].select && result) {
            this.matDialogRef.close({ discriminatorsSelected: this.data.discriminator, select: true, list: this.list, discriminatorsValues: this.data.discriminatorsValues, value: true });
          } else {
            this.model.get("radio-" + this.subList[index].idSubDiscriminator + "-" + this.subList[index].idDiscriminator).setValue(null);
            this.alertService.show('Aviso', "O valor inserido não se enquadra no parâmetro definido para " + this.data.discriminator.discriminatorName, AlertType.warning);
          }
        } else {
          this.model.get("radio-" + this.subList[index].idSubDiscriminator + "-" + this.subList[index].idDiscriminator).setValue(null);
          this.alertService.show('Aviso', "É preciso inserir o valor da temperatura para ser considerado o valor do " + this.data.discriminator.discriminatorName, AlertType.warning);
        }
      } else if (item.idDiscriminatorType == DiscriminatorTypeEnum.Temperatura) {
        let result = this.utilService.verifySelectGeneric(item.minValueAllowed, item.maxValueAllowed, this.subList[index].value);
        if (this.subList[0].select && result) {
          this.matDialogRef.close({ discriminatorsSelected: this.data.discriminator, select: true, list: this.list, discriminatorsValues: this.data.discriminatorsValues, value: true });
        } else {
          this.model.get("radio-" + this.subList[index].idSubDiscriminator + "-" + this.subList[index].idDiscriminator).setValue(null);
          this.alertService.show('Aviso', "O valor inserido não se enquadra no parâmetro definido para " + this.data.discriminator.discriminatorName, AlertType.warning);
        }
      } else if (DiscriminatorTypeEnum.PerfusaoCapilar == item.idDiscriminatorType) {
        var disIndex = this.subList.findIndex(c => c.idSubDiscriminator == item.idSubDiscriminator);
        let dis = this.subList[disIndex];
        let select = this.utilService.verifySelectGeneric(dis.minValueAllowed, dis.maxValueAllowed, dis.value);
        if (select) {
          this.matDialogRef.close({ discriminatorsSelected: dis, select: true, list: this.list, discriminatorsValues: this.data.discriminatorsValues, value: dis.value });
          return;
        } else {
          this.model.get("radio-" + this.subList[index].idSubDiscriminator + "-" + this.subList[index].idDiscriminator).setValue(null);
          this.alertService.show('Aviso', "O valor inserido não se enquadra no parâmetro definido para " + this.data.discriminator.discriminatorName, AlertType.warning);
          return;
        }

      } else {
        this.matDialogRef.close({ discriminatorsSelected: this.data.discriminator, select: true, list: this.list, discriminatorsValues: this.data.discriminatorsValues, value: true });
      }
    }
  }

  validateToOpenModal(item: SubDiscriminatorModel): void {
    let indexFirstColumn = this.firstColumn.findIndex(c => c.column.idSubDiscriminator == item.idSubDiscriminator);
    if (indexFirstColumn + 1 < this.firstColumn.length) {
      this.firstColumn[indexFirstColumn + 1].openModalValidate = false;
    }
  }
}
