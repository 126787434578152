<div mat-dialog-content>
    <div class="mat-dialog-header">
        <h2>Existe alteração em relação ao estado prévio?</h2>
    </div>
    <div class="mat-dialog-content">
        <div class="footer-panel-modal-list">
            <div class="row">
                <div class="col-6">
                    <button mat-flat-button color="primary" class="btn-block" (click)="clickResponse(true)">SIM</button>
                </div>
                <div class="col-6">
                    <button mat-flat-button color="accent" class="btn-block" (click)="clickResponse(false)">NÃO</button>
                </div>
            </div>
        </div>
    </div>
</div>