import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { HealthcareAgreementRequest } from '../../requests/private-billing/healthcare-agreement.request';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { HealthcareAgreementResponse } from '../../responses/private-billing/healthcare-agreement.response';
import { ListHealthcareAgreementResponse } from '../../responses/private-billing/list-healthcare-agreement.response';
import { ListProcedureResponse } from '../../responses/private-billing/list.procedure.response';
import { ProcedureResponse } from '../../responses/private-billing/procedure.response';
import { ProcedureRequest } from '../../requests/private-billing/procedure.request';
import { GetProceduresRequest } from '../../requests/private-billing/get-procedures.request';
import { GetProcedureResponse } from '../../responses/private-billing/get-procedures.response';


@Injectable({
  providedIn: 'root'
})
export class ProcedureService extends CommonService {
  function() {
    throw new Error('Method not implemented.');
  }

  constructor(private httpClient: HttpClient) {
    super();
  }


  public GetAll(searchText: string, pageIndex: number, isActiveProcedures: boolean = null): Observable<ListProcedureResponse> {
    let uri = `Procedure?`

    if (searchText)
      uri = uri + `searchText=${searchText}&`

    if (pageIndex)
      uri = uri + `pageIndex=${pageIndex}&`;

    if (isActiveProcedures == true || isActiveProcedures == false)
      uri = uri + `isActiveProcedures=${isActiveProcedures}&`;

    return this.httpClient.get<ListProcedureResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public PutStatus(idProcedure: number, isActive: boolean): Observable<ReturnStruct> {
    let uri = `Procedure/idProcedure/${idProcedure}/isActive/${isActive}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiPrivateBilling + uri, null, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public GetAllByHealthUnit(): Observable<ListProcedureResponse> {
    let uri = `procedure/getAll`

    return this.httpClient.get<ListProcedureResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public GetById(idProcedure: number): Observable<ProcedureResponse> {
    let uri = `Procedure/idProcedure/${idProcedure}`

    return this.httpClient.get<ProcedureResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public GetByTerminologyGroup(body: GetProceduresRequest): Observable<GetProcedureResponse> {
    let uri = `Procedure/getByTerminologyGroup`;

    return this.httpClient.post<GetProcedureResponse>(environment.urlApiPrivateBilling + uri, body, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public Post(body: ProcedureRequest): Observable<ProcedureResponse> {
    let uri = `Procedure`;

    return this.httpClient.post<ProcedureResponse>(environment.urlApiPrivateBilling + uri, body, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public Put(body: ProcedureRequest, idProcedure: number): Observable<ProcedureResponse> {
    let uri = `Procedure/idProcedure/${idProcedure}`

    return this.httpClient.put<ProcedureResponse>(environment.urlApiPrivateBilling + uri, body, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}