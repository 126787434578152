import { Component, Input, OnInit } from '@angular/core';
import { MetaDataTypeEnum } from '../../enum/meta-data/meta-data-type.enum';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MetaDataStruct } from '../../services/structs/meta-data/meta-data.struct';
import { VerifyDate } from '../../custom-validators/date-age.validator';
import { MaskService, Masks } from '../../services/mask.service';
import { MetaDataKeyValueStruct } from '../../services/structs/meta-data/meta-data-key-value.struct';
import { MetaDataService } from '../../services/API/meta-data/meta-data.service';
import { AlertService, AlertType } from '../../services/alert.service';

@Component({
  selector: 'app-meta-data',
  templateUrl: './meta-data.component.html',
  styleUrls: ['./meta-data.component.css']
})
export class MetaDataComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private maskService: MaskService,
    private alertService: AlertService,
    private metaDataService: MetaDataService,
  ) { }

  // @Input() listMetaDataLookup: MetaDataStruct[] = [];
  @Input() listValueMetaData: MetaDataKeyValueStruct[] = [];
  @Input() listIdMetaDataForUsage: number[] = [];
  @Input() listIdRequiredMetaData: number[] = [];
  @Input() isReadonly: boolean = false;
  @Input() model: FormGroup;

  public listMetaData: MetaDataStruct[] = [];
  public listMetaDataLookup: MetaDataStruct[] = [];

  public isLoading: boolean = true;

  public masks: Masks = this.maskService.getMasks();

  ngOnInit(): void {
    if(!this.model)
      this.model = this.formBuilder.group({});

    this.getAllMetaData();
  }

  populateMetaData() {
    if (this.listMetaData && this.listMetaData.length > 0 
      && this.listValueMetaData && this.listValueMetaData.length > 0) {
      for (let item of this.listMetaData) {
        let metaData = this.listValueMetaData.find(c => c.key == item.metaData.idMetaData);
        if (metaData)
          this.model.get(item.metaData.idMetaData.toString()).setValue(metaData.value);
        else
          this.model.get(item.metaData.idMetaData.toString()).setValue(null);
      }
    }

    this.isLoading = false;
  }

  verifyDate(controlId: number): void {
    const control = this.model.get(controlId.toString()); // Transforme o número em uma string
    const validationError = VerifyDate()(control);

    if (validationError)
      control.setErrors({ ...control.errors, ...validationError });
    else
      control.setErrors(null);
  }

  public get metaDataTypeEnumResult(): typeof MetaDataTypeEnum {
    return MetaDataTypeEnum;
  }

  getAllMetaData() {
    this.metaDataService.getAllMetaData().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listMetaDataLookup = response.listMetaDataStruct;

        if(this.listValueMetaData && this.listValueMetaData.length > 0)
          this.listMetaData = this.listMetaDataLookup.filter((lookup) => this.listValueMetaData.some(metaData => lookup.metaData.idMetaData == metaData.key));
        else if (this.listIdMetaDataForUsage && this.listIdMetaDataForUsage.length > 0)
          this.listMetaData = this.listMetaDataLookup.filter((lookup) => this.listIdMetaDataForUsage.find(metaData => lookup.metaData.idMetaData == metaData));

        this.createControl();
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createControl() {
    if (this.listMetaData && this.listMetaData.length > 0) {
      for (let item of this.listMetaData) {
        this.model.addControl(item.metaData.idMetaData.toString(), this.formBuilder.control(''));
      }
      this.verifyRequiredFields();
      this.populateMetaData();
    }
  }

  // verifyRequiredFields() {

  // }

  verifyRequiredFields() {
    // if (this.listIdRequiredMetaData != null) {
     
    // }

    // if (this.listMetaData && this.listMetaData.length > 0) {
    //   for (let item of this.listMetaData) {
    //     if (item.metaData.isRequired) {
    //       this.model.addControl(
    //         item.metaData.idMetaData.toString(),
    //         this.formBuilder.control('', [Validators.required])
    //       );
    //     }
    //     else
    //       this.model.addControl(item.metaData.idMetaData.toString(), this.formBuilder.control(''));
    //   }
    // }
    if (this.listIdRequiredMetaData != undefined && this.listIdRequiredMetaData.length > 0) {
      for (let item of this.listIdRequiredMetaData) {
        if (this.model.controls[item]) { // Check if the control exists before setting validators
          this.model.controls[item].setValidators([Validators.required]);
          this.model.controls[item].updateValueAndValidity();
        }
      }
    }
  }
}