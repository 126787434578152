<div class="row attendance-status">
    <button [disabled]="isLoading" mat-flat-button (click)="selectOption('naoAtendidos')" [color]="naoAtendidosColor"
        class="btn-block col-12 col-md-4">
        <span>Não atendidos</span>
    </button>
    <button [disabled]="isLoading" mat-flat-button (click)="selectOption('atendidos')" [color]="atendidosColor"
        class="btn-block col-12 col-md-4" *ngIf="!isFlowManagement">
        <span>Atendidos</span>
    </button>
    <button [disabled]="isLoading" mat-flat-button (click)="selectOption('evadidos')" [color]="evadidosColor"
        class="btn-block col-12 col-md-4" *ngIf="!isFlowManagement">
        <span>Evadidos</span>
    </button>
    <button matBadge="{{returnCount}}" matBadgePosition="after" matBadgeColor="warn" [disabled]="isLoading"
        mat-flat-button (click)="selectOption('retornados')" [color]="retornadosColor"
        class="btn-block col-12 col-md-4">
        <span>Retornos</span>
    </button>
    <button matBadge="{{scheduleCount}}" matBadgePosition="after" matBadgeColor="warn" [disabled]="isLoading"
        mat-flat-button (click)="selectOption('agendados')" [color]="agendadosColor" class="btn-block col-12 col-md-4"
        *ngIf="!isFlowManagement && hasScheduleFilter">
        <span>Agendados</span>
    </button>
    <button *ngIf="isFlowManagement" [disabled]="isLoading" mat-flat-button (click)="selectOptionKPI('beyondGoal')" [color]="beyondGoalColor"
        class="btn-block col-12 col-md-4">
        <span>Fora da meta</span>
    </button>
    <button *ngIf="isFlowManagement" [disabled]="isLoading" mat-flat-button (click)="selectOptionKPI('alert')" [color]="alertColor"
        class="btn-block col-12 col-md-4">
        <span>Alerta</span>
    </button>
    <button *ngIf="isFlowManagement" [disabled]="isLoading" mat-flat-button (click)="selectOptionKPI('ok')" [color]="okColor"
        class="btn-block col-12 col-md-4">
        <span>Dentro da meta</span>
    </button>
</div>