import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-pin-certificate-modal',
  templateUrl: './pin-certificate-modal.component.html',
  styleUrls: ['./pin-certificate-modal.component.css']
})
export class PinCertificateModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<PinCertificateModalComponent>,
    public sanitizer: DomSanitizer,
    private formBuilder: UntypedFormBuilder,
  ) { this.matDialogRef.disableClose = true; }
  
  public pin: string;
  public model: UntypedFormGroup;
  public show: boolean = false;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      certificatePin: ['',[Validators.required]],
    });
  }
  
  submit(){
    if(this.model.invalid){
      return;
    }
    
    this.pin = this.model.get('certificatePin').value;
    let pin64 = btoa(this.pin)//converts to base64
    this.matDialogRef.close({pin: pin64, isICPSigned : true}); 
  }

  close(){
    this.matDialogRef.close({isICPSigned: false}); 
  }
}