export enum PainEnum{
    level0 = 1,
    level1 = 2,
    level2 = 3,
    level3 = 4,
    level4 = 5,
    level1Seven = 6,
    level2Seven = 7,
    level3Seven = 8,
    level4Seven = 9,
    level5 = 10,
    level6 = 11,
    level7 = 12,
    level8 = 13,
    level9 = 14,
    level10 = 15,
}