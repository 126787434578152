import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-security-alert-modal',
  templateUrl: './security-alert-modal.component.html',
  styleUrls: ['./security-alert-modal.component.css']
})
export class SecurityAlertModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<SecurityAlertModalComponent>,
    private alertService: AlertService
  ) { 
    matDialogRef.disableClose = true;
  }

  public isLoading:boolean = false;  

  ngOnInit(): void {
    this.isLoading = false;
  }
  
  close(){
    this.matDialogRef.close(); 
  }
  
  clickRetry(){
    this.matDialogRef.close(); 
  }
}
