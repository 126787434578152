import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfigTotemModalComponent } from 'src/app/master/pages/totem-list/config-totem-modal/config-totem-modal.component';
import { AlertService, AlertType } from '../../services/alert.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { BleScanModalComponent } from '../ble-scan-modal/ble-scan-modal.component';
import { MeasurerService } from '../../services/API/measurer/measurer.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-ble-error-modal',
  templateUrl: './ble-error-modal.component.html',
  styleUrls: ['./ble-error-modal.component.css']
})
export class BleErrorModalComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ConfigTotemModalComponent>,
    private alertService: AlertService,
    private router: Router,
    public sanitizer: DomSanitizer,
    private measurerService: MeasurerService,
  ) { 
    matDialogRef.disableClose = true;
  }

  public bluetoothExecutableName: string = environment.bluetoothAPIExecutableName;
  public bluetoothExecutableAPI: string = `https://storage.googleapis.com/tolife-production-public-storage/${this.bluetoothExecutableName}.zip`;
  public sanitizedUrlDownload
  public isLoading: boolean = false;
  public connected: boolean = false;

  ngOnInit(): void {
    //this.sanitizedUrlDownload = this.sanitizer.bypassSecurityTrustUrl(this.bluetoothExecutableAPI);
  }

  download() {
    window.open(this.bluetoothExecutableAPI, '_blank');
  }

  close(){
    this.matDialogRef.close();
  }

  retry(){
    this.isLoading = true;
    this.connected = false;
    this.measurerService.verifyConnection().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error)
          this.isLoading = false
          this.connected = false;
          return;
        }
        this.alertService.show('Sucesso!', "Bluetooth Tolife conectado com sucesso", AlertType.success)
        this.connected = true;
        this.isLoading = false
      },
      error: (error: HttpErrorResponse) => {
        console.log('Erro: ', error);
        let errorMessage = '';
        if (error.status === 0) {
          errorMessage = `Aplicativo ${this.bluetoothExecutableName} não encontrado! Siga os passos informados e tente novamente`;
        }
        else {
          errorMessage = error.message;
        }
        this.alertService.show('Erro inesperado', errorMessage, AlertType.error)
        this.connected = false;
        this.isLoading = false
      }
    }); 
  }
}
