<div *ngIf="!isLoading" class="content">
    <div class="main-call">
        <div class="main-call-content">
            <div class="main-call-label">
                SENHA
            </div>
            <div class="main-call-ticket">
                {{mainClient?.ticket}}
            </div>
            <div class="main-call-location bold">
                {{ mainClient?.nameSector +" "+ mainClient?.nameRoom}}
            </div>
            <div class="main-call-pacient">
                {{mainClient?.namePacient}}
            </div>
        </div>
    </div>
</div>