<div mat-dialog-header>
    <div class="row">
        <div class="col-12">
            <h2><b>{{data.userName}}</b> está utilizando esta sala no momento!
            </h2>
        </div>
    </div>
</div>
<div mat-dialog-content>
    <div class="row">
        <div class="col-12" *ngIf="!data.seeOccupiedRoom">Você deseja remover o usuário da sala?</div>
        <div class="col-12" *ngIf="data.seeOccupiedRoom">Você deseja remover o usuário ou visualizar a sala? Se optar
            por
            visualizar a sala não poderá realizar nenhuma ação.</div>
    </div>
    <div class="action-button row">
        <div [ngClass]="{'col-md-4': data.seeOccupiedRoom, 'col-md-6': !data.seeOccupiedRoom}" class="col-12 col-md-6">
            <button mat-flat-button color="warn" class=" btn-block" (click)="continue()" type="button">
                Remover
            </button>
        </div>
        <div *ngIf="data.seeOccupiedRoom" class="col-12 col-md-4">
            <button mat-flat-button color="primary" class=" btn-block" (click)="visualize()" type="button">
                Visualizar
            </button>
        </div>
        <div [ngClass]="{'col-md-4': data.seeOccupiedRoom, 'col-md-6': !data.seeOccupiedRoom}">
            <button mat-flat-button color="accent" class=" btn-block" (click)="clickCancel()"
                type="button">Cancelar</button>
        </div>
    </div>
</div>