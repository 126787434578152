<div mat-dialog-content>
    <div class="mat-dialog-content">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="row">
                <div class="col-md-6">
                    <div class="head-profile-modal-list">
                        <h2>Prescrição: </h2>
                    </div>
                    <div class="body-confirm-modal-list">
                        <div class="row">
                            <div class="col-md-12">
                                <label>Nome medicamento: </label>
                                <p>{{prescription.medicineName}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label>Quantidade: </label>
                                <p>{{prescription.quantity}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label>Unidade: </label>
                                <p>{{prescription.measurementUnitName}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label>Apresentação: </label>
                                <p>{{prescription.presentationName}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-10">
                                <label>Uso: </label>
                                <p>{{prescription.usage}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label>Forma de consumo:</label>
                                <p>{{prescription.administrationTypeName}}</p>
                                <p>{{prescription.administrationType}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label>Frequencia: </label>
                                <p>{{prescription.frequencyName}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label>Período de tratamento: </label>
                                <p>{{prescription.treatmentPeriodName}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label>Diluição: </label>
                                <p>{{prescription.dilution ? prescription.dilution : prescription.dilutionName}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label>Observação: </label>
                                <p>{{prescription.observation}}</p>
                            </div>
                        </div>
                        <div class="title-header" *ngIf="this.isReadonly">
                            <h1>Checagem de prescrição</h1>
                        </div>
                        <div class="row" *ngIf="this.isReadonly">
                            <div class="col-md-11">
                                <label>Checado em {{checkDatetimeFulfilled | date:'dd/MM/yyyy HH:mm'}}</label>
                                <p>{{checkProfessionName}} - {{checkUserName}}</p>
                            </div>
                        </div>                       
                    </div>
                </div>
                <div class="col-md-6" *ngIf="prescription.listMedicalPrescriptionCheck">
                    <div class="head-profile-modal-list">
                        <h2>Histórico: </h2>
                    </div>
                    <div class="row" *ngFor="let item of prescription.listMedicalPrescriptionCheck">
                        <div class="col-md-12">
                            <label *ngIf="item.datetimeCheckFulfilled">
                                Checado
                            </label>
                            <label *ngIf="!item.datetimeCheckFulfilled">
                                Pendente
                            </label>
                            <p *ngIf="item.medicalPrescriptionStatus == 2" class="early-green">{{item.datetimeCheckEstimative | date:'dd/MM/yyyy HH:mm'}}</p>
                            <p *ngIf="item.medicalPrescriptionStatus == 1" class="near-yellow">{{item.datetimeCheckEstimative | date:'dd/MM/yyyy HH:mm'}}</p>
                            <p *ngIf="item.medicalPrescriptionStatus == 3" class="late-red">{{item.datetimeCheckEstimative | date:'dd/MM/yyyy HH:mm'}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-modal-list">
                <button mat-flat-button type="button" color="primary" class="btn-block"
                    (click)="close()"><span>Fechar</span></button>
            </div>
        </form>
    </div>
</div>