export enum MenuModuleEnum{
    auth = -1,
    welcome = -2,
    master = 1,
    dashboard = 2,
    totem = 3,
    medic = 4,
    generic_call = 6,
    frontdesk = 7,
    sadt = 8,
    classification = 9,
    audit = 10,
    integration = 11,
    observation = 12,
    patient_history = 13,
    form = 14,
    billing = 15,
    remote_monitoring = 16,
    pharmacy = 17,
    business_intelligence = 18,
    multi_professional = 21,
    flow_management = 22,
    ubs_monitoring = 23,
    care_line_monitoring = 24,
    digital_prompt_service = 26,
}