<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="row">
            <div class="col-12">
                <h3><b>{{data.userName}}</b> agora está utilizando esta sala!</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h4>Você será redirecionado para a seleção de sala.</h4>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-4"></div>
            <div class="col-12 col-md-4">
                <button mat-flat-button color="primary" class=" btn-block" (click)="continue()" type="button">
                    <span>Continuar</span>
                </button>
            </div>
            <div class="col-12 col-md-4"></div>
        </div>
    </div>
</div>