import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-warn-offline-modal',
  templateUrl: './warn-offline-modal.component.html',
  styleUrls: ['./warn-offline-modal.component.css']
})
export class WarnOfflineModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<WarnOfflineModalComponent>) { }

  ngOnInit(): void {
  }

  clickCancel(value: boolean) {
    this.matDialogRef.close(value);
  }
}

