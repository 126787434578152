<!-- <div class="container-out" (click)="close()" *ngIf="isVisible == true"></div> -->

<div class="container-user" *ngIf="isVisible == true">
    <a class="name">{{initials}}</a>
    <h2>{{userLoginResponse.userName}}</h2>
    <h4>{{userLoginResponse.healthUnit.healthUnitName}}</h4>
    
    <div class="action">
        <ul>
            <li>
                <a routerLink="/config">
                    <mat-icon aria-hidden="false" aria-label="Menu apps">manage_accounts</mat-icon> 
                    <span>Configurar conta</span>
                    <mat-icon class="chevron" aria-hidden="false" aria-label="Menu apps">chevron_right</mat-icon>
                </a>
            </li>
            <li *ngIf="this.canStayConnected">
                <div (click)="goConnectedDevices()">
                    <mat-icon aria-hidden="false" aria-label="Menu apps">devices</mat-icon> 
                    <span>Gerenciar dispositivos conectados</span>
                    <mat-icon class="chevron" aria-hidden="false" aria-label="Menu apps">chevron_right</mat-icon>
                </div>
            </li>
            <li *ngIf="menuModuleEnum == menuModuleEnumMaster">
                <div (click)="openNetworkTestModal()">
                    <mat-icon aria-hidden="false" aria-label="Menu apps">network_check</mat-icon> 
                    <span>Testar rede</span>
                    <mat-icon class="chevron" aria-hidden="false" aria-label="Menu apps">chevron_right</mat-icon>
                </div>
            </li>
        </ul>
    </div>

    <div class="text-left">
        <button mat-flat-button type="button" (click)="logout()">
            Sair
        </button>
    </div>
</div>