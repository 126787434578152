import { Component, Input, OnInit } from '@angular/core';
import { EpisodeStatusEnum } from 'src/app/shared/enum/episode-status.enum';

@Component({
  selector: 'app-patient-status-tag',
  templateUrl: './patient-status-tag.component.html',
  styleUrls: ['./patient-status-tag.component.css']
})
export class PatientStatusTagComponent implements OnInit {

  @Input() patientStatus: string;
  @Input() idStatus: number;
  @Input() patientAdmStatus: string;
  @Input() idAdmStatus: number;

  public initial:EpisodeStatusEnum = EpisodeStatusEnum.Iniciado;
  public endRealease:EpisodeStatusEnum = EpisodeStatusEnum.FinalizadoAlta;
  public endReleaseAdministrative:EpisodeStatusEnum = EpisodeStatusEnum.FinalizadoAltaAdministrativa;
  public endDeath:EpisodeStatusEnum = EpisodeStatusEnum.FinalizadoObito;
  public endInternation:EpisodeStatusEnum = EpisodeStatusEnum.FinalizadoInternar;
  public endEvasion:EpisodeStatusEnum = EpisodeStatusEnum.FinalizadoEvasao;

  constructor() { }

  ngOnInit(): void {
  }

}