export class BedEpisodeRequest{
    
    public idBed: number;
    
    public idEpisode: number;

    public idLastRoom: number;

    public hygienizationConfig: boolean;

    public idBedHygienizationStatus: number;
}