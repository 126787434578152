import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appAgeMask]'
})
export class AgeMaskDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event: any) {
    const inputElement = this.el.nativeElement;
    let inputValue = inputElement.value.replace(/[^\d]/g, '');
    if (inputValue.length > 7) {
      inputValue = inputValue.slice(0, 7); // Limite máximo de 7 dígitos
    }

    let formattedValue = '';
    if (inputValue.length > 5) {
      formattedValue = `${inputValue.slice(0, 3)}a${inputValue.slice(3, 5)}m${inputValue.slice(5)}d`;
    } else if (inputValue.length > 3) {
      formattedValue = `${inputValue.slice(0, 3)}a${inputValue.slice(3)}m`;
    } else if (inputValue.length > 0) {
      formattedValue = `${inputValue}a`;
    }

    inputElement.value = formattedValue;

    // Preserve cursor position
    const cursorPosition = this.getCursorPosition(inputElement);
    inputElement.setSelectionRange(cursorPosition, cursorPosition);
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    const inputElement = this.el.nativeElement;
    const cursorPosition = this.getCursorPosition(inputElement);

    if (event.key === 'Backspace' || event.key === 'Delete') {
      let newCursorPosition = cursorPosition;

      if (event.key === 'Backspace' && cursorPosition > 0) {
        newCursorPosition = cursorPosition - 1;
      }

      // Set cursor position after delete
      setTimeout(() => {
        inputElement.setSelectionRange(newCursorPosition, newCursorPosition);
      });
    }
  }

  private getCursorPosition(inputElement: HTMLInputElement): number {
    return inputElement.selectionStart || 0;
  }
}