<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-confirm-modal-list">
            <h3>{{selectedMessage.messageTitle}}</h3>
        </div>
        <div class="body-confirm-modal-list">
            <form class="form" [formGroup]="model">
                <mat-form-field appearance="outline">
                    <mat-label>
                        Mensagem
                    </mat-label>
                    <textarea matInput formControlName="messageContent" class="message-container" type="text" readonly>
                    </textarea>
                </mat-form-field>
            </form>
        </div>
        <div class="footer-modal-list">
            <button mat-flat-button color="primary" class="btn-block" (click)="close()">
                <span>Voltar</span>
            </button>
        </div>
    </div>
</div>