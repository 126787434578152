import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-occupied-remove-modal',
  templateUrl: './occupied-remove-modal.component.html',
  styleUrls: ['./occupied-remove-modal.component.css']
})
export class OccupiedRemoveModalComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<OccupiedRemoveModalComponent>) { }

  ngOnInit(): void {
  }

  close() {
    this.matDialogRef.close();
  }

  continue() {
    this.matDialogRef.close();
  }

  clickCancel() {
    this.matDialogRef.close();
  }
}