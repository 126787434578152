<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-confirm-modal-list">
            <h2>Deseja atender o Paciente?</h2>
         </div>
         
         <div class="body-confirm-modal-list">
            {{data.confirmMessage}}
         </div>
         <div class="footer-confirm-modal-list">
            <button mat-flat-button color="primary" type="button" class="btn-block" (click)="clickConfirm()">
                <span *ngIf="isLoading == false">Sim, atender</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
            <button mat-flat-button color="accent" type="button" class="btn-block"(click)="clickCancel()">Cancelar</button>
        </div>
    </div>
</div>

