<div class="card-list" *ngIf="listPrescription != null && listPrescription.length > 0">
    <div class="row row-list">
        <ng-container *ngFor="let item of listPrescription; let index = index;">
            <div class="col-12 col-sm-6 col-md-4">
                <div class="card-item">
                    <div class="infos">
                        <span class="primary">{{item.medicineName}}</span>
                        <span class="secundary">Quantidade: {{item.quantity}}</span>
                        <span class="secundary">Uso: {{item.usage}}</span>
                        <span class="secundary">Via: {{item.administrationTypeName}}</span>
                        <span class="secundary">Apresentação: {{item.presentationName}}</span>
                        <span class="secundary">Frequência:{{item.frequencyName}} {{item.frequency}}</span>
                        <span class="secundary">Periodo trat. :{{item.treatmentPeriodName}}
                            {{item.treatmentPeriod}}</span>
                        <span class="secundary">Diluição: {{item.dilution? item.dilution : item.dilutionName}}</span>
                        <span class="secundary-status">{{item.prescriptionCheckStatus}}</span>
                        <span
                            class="secundary-status last-info">{{statusIdToText(item.thisMedicalPrescriptionCheck?.medicalPrescriptionStatus)}}</span>
                    </div>
                    <div class="actions">
                        <a>
                            <mat-icon aria-hidden="false" aria-label="Editar"
                                (click)="openPrescriptionModal(item, false)">visibility
                            </mat-icon>
                        </a>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
<div *ngIf="listPrescription == null || listPrescription.length == 0">
    <p>Este atendimento não possui prescrições internas!</p>
</div>