import { Directive, HostListener, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Directive({
  selector: '[appUppercaseForm]'
})
export class UppercaseFormDirective {
  @Input('formGroup') formGroup: FormGroup;
  @Input() appUppercaseForm: boolean = false;

  @HostListener('input', ['$event.target']) onInput(input: HTMLInputElement): void {
    if (this.appUppercaseForm) {
      input.value = input.value.toUpperCase();
    }
  }
  @HostListener('ngSubmit')
  onSubmit(): void {
    if (this.formGroup && this.appUppercaseForm) {
      Object.keys(this.formGroup.controls).forEach(key => {
        const control = this.formGroup.get(key);
        if (control && control.value && typeof control.value == "string") {
          control.setValue(control.value.toUpperCase(), { emitEvent: false });
        }
      });
    }
  }
}