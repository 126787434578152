import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';
import { ModuleStruct } from 'src/app/shared/services/structs/user/module.struct';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MenuModuleEnum } from '../menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { UserModuleService } from 'src/app/shared/services/API/user/user-module.service';
import { UpgradeModalComponent } from '../../upgrade-modal/upgrade-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { WebsocketRoomUtilService } from 'src/app/shared/services/websocket-util/websocket-room-util.service';
import { ListModuleGroupStruct } from 'src/app/shared/services/structs/user/list-module-group.struct';
import { WebsocketAttendanceUtilService } from 'src/app/shared/services/websocket-util/websocket-attendance-util.service';


@Component({
  selector: 'app-module-infos',
  templateUrl: './module-infos.component.html',
  styleUrls: ['./module-infos.component.css']
})
export class ModuleInfosComponent implements OnInit, OnChanges {

  public searchText: string;
  constructor(
    private authService: AuthService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private userModuleService: UserModuleService,
    public dialog: MatDialog,
    public websocketRoomUtilService: WebsocketRoomUtilService,
    public websocketAttendanceUtilService: WebsocketAttendanceUtilService,
  ) { }

  @Input() isVisible: boolean;
  @Input() menuModuleEnum: MenuModuleEnum;
  @Output() closeModuleInfo = new EventEmitter<any>();

  model: UntypedFormGroup;

  listModuleStruct: ModuleStruct[];
  listModuleGroupStruct: ListModuleGroupStruct[];
  filteredListModuleStruct: ModuleStruct[];
  filteredListModuleGroupStruct: ListModuleGroupStruct[];
  promptService: ModuleStruct[];
  remoteMonitoring: ModuleStruct[];
  unitMangement: ModuleStruct[];
  financial: ModuleStruct[];
  settings: ModuleStruct[];
  defaultModuleName: string;
  idDefaultModule: number;
  newDefaultModule: ModuleStruct;
  actualModuleName: string;
  actualModuleId: number;
  isMaster: boolean;
  @ViewChild('search') searchElement: ElementRef;

  ngOnInit(): void {
    this.listModuleStruct = this.authService.getTokenMenu().listModule;
    this.listModuleGroupStruct = this.authService.getTokenMenu().listModuleGroup;
    this.defaultModuleName = this.authService.getTokenMenu().defaultModuleName;
    this.idDefaultModule = this.authService.getTokenMenu().idDefaultModule;
    this.isMaster = this.authService.getTokenMenu().isMaster;
    this.filteredListModuleStruct = this.listModuleStruct;
    this.filteredListModuleGroupStruct = this.listModuleGroupStruct;

    let moduleSelected = this.listModuleStruct.find(module => module.idModule == this.menuModuleEnum);

    if (moduleSelected != undefined) {
      this.actualModuleName = moduleSelected.nameModule;
      this.actualModuleId = moduleSelected.idModule;
    }

    this.model = this.formBuilder.group({
      isDefaultModule: [''],
    });
    this.promptService = [];
    this.remoteMonitoring = [];
    this.unitMangement = [];
    this.financial = [];
    this.settings = [];
    this.promptService.push(this.listModuleStruct.find(module => module.idModuleGroup == 1));
    this.remoteMonitoring.push(this.listModuleStruct.find(module => module.idModuleGroup == 2));
    this.unitMangement.push(this.listModuleStruct.find(module => module.idModuleGroup == 3));
    this.financial.push(this.listModuleStruct.find(module => module.idModuleGroup == 4));
    this.settings.push(this.listModuleStruct.find(module => module.idModuleGroup == 5));
    this.setToggle();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.isVisible.currentValue){
      setTimeout(()=> {
        if (this.searchElement) {
          this.searchElement.nativeElement.focus();
        }
      }, 200);
    }
  }

  setToggle() {
    if (this.idDefaultModule === this.menuModuleEnum)
      this.model.get('isDefaultModule').setValue(true);
    else
      this.model.get('isDefaultModule').setValue(false);
  }

  onKeySearch(){
    setTimeout(() => {
      if(this.searchText){
        this.filteredListModuleStruct = this.listModuleStruct.filter((x => x.nameModule.toLowerCase().normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "").includes(this.searchText.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))));
        this.filteredListModuleGroupStruct = [];
        let auxId = 0;
        this.filteredListModuleStruct.forEach((elem) => {
          if (elem.idModuleGroup != auxId){
            this.filteredListModuleGroupStruct.push(this.listModuleGroupStruct.find((x) => x.idModuleGroup == elem.idModuleGroup))
            auxId = elem.idModuleGroup;
          }
        })
      } else {
        this.filteredListModuleStruct = this.listModuleStruct;
        this.filteredListModuleGroupStruct = this.listModuleGroupStruct
      }

    });
  }

  close() {
    this.closeModuleInfo.emit();
  }

  redirect(module: ModuleStruct) {

    if (module.isModulePermitted) {
      if (module.idModule != this.actualModuleId) {
        this.websocketRoomUtilService.clearSocket();
        this.websocketAttendanceUtilService.clearSocket(true);
      }
      this.router.navigate([module.uri]);
    }
    else
      this.openModal();
  }

  changeDefaultModule(event) {
    if (event && event.checked) {
      //Se não for um modulo valido cadastrado no banco de dados
      if (this.menuModuleEnum < 0)
        return;

      this.newDefaultModule = this.listModuleStruct.find(module => module.idModule == this.menuModuleEnum);

      this.userModuleService.updateDefaultModule(this.menuModuleEnum).subscribe((response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.alertService.show('Sucesso', "Módulo inicial redefinido com sucesso!", AlertType.success);
      },
        (error) => {
          console.log(error)
          this.alertService.show('Erro inesperado', error, AlertType.error);
        });

      this.authService.updateDefaultModule(this.newDefaultModule);
      this.defaultModuleName = this.newDefaultModule.nameModule;
    }

    if (event && !event.checked) {
      this.userModuleService.updateDefaultModule().subscribe((response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.alertService.show('Sucesso', "Módulo inicial removido com sucesso!", AlertType.success);
      },
        (error) => {
          console.log(error)
          this.alertService.show('Erro inesperado', error, AlertType.error);
        });

      this.authService.updateDefaultModule(this.newDefaultModule);
      this.defaultModuleName = null;
    }
  }

  openModal() {
    const dialogRef = this.dialog.open(UpgradeModalComponent, {
      data: {

      },
    });
  }
}