<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-modal-list">
            <h2>Selecione a unidade de saúde</h2>
        </div>

        <div class="footer-modal-list row">
            <div class="col-12" >
                <ngContainer *ngIf="!isLoading" >
                    <button *ngFor="let healthunit of data.listHealthUnit" type="button" mat-flat-button color="primary" class="btn-block" 
                        (click)="clickConfirm(healthunit)">
                        {{healthunit.healthUnitName}}
                    </button>
                </ngContainer>
                <span *ngIf="isLoading">
                    <mat-spinner class="loading-spinner-modal" [diameter]="30"></mat-spinner>
                </span>
            </div>
            <div class="col-12">
                <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">
                    <span>Cancelar</span>
                </button>
            </div>
        </div>
    </div>
</div>