<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="title-complete-journey">
            <h1>Jornada completa</h1>
        </div>
        <hr>
        <app-loading-list *ngIf="isLoading"></app-loading-list>
        <div class="body-complete-journey" *ngIf="!isLoading">
            <h2>Admissão</h2>
            <div class="module-content" *ngIf="this.patientHistory.episode &&
                                                (this.patientHistory.episode.waitingTime ||
                                                this.patientHistory.episode.serviceTime ||
                                                this.patientHistory.episode.userName)">
                <div class="time-content">
                    <div class="block-time">
                        <p><b>Tempo de espera:</b></p>
                        <div class="time-count">
                            <span>{{this.patientHistory.episode.waitingTime ? this.patientHistory.episode.waitingTime :
                                '00:00:00'}}</span>
                        </div>
                    </div>
                    <div class="block-time">
                        <p><b>Tempo de atendimento:</b></p>
                        <div class="time-count">
                            <span>{{this.patientHistory.episode.serviceTime ? this.patientHistory.episode.serviceTime :
                                '00:00:00'}}</span>
                        </div>
                    </div>
                </div>
                <p class="professional-responsability"><b>Profissional responsável:
                    </b>{{this.patientHistory.episode.userName ? this.patientHistory.episode.userName : ''}}</p>
            </div>
            <hr>
            <h2>Classificação de Risco</h2>
            <div class="module-content" *ngIf="this.patientHistory.triage.length > 0">
                <div *ngFor="let triage of this.patientHistory.triage">
                    <div class="time-content">
                        <div class="block-time">
                            <p><b>Tempo de espera:</b></p>
                            <div class="time-count">
                                <span>{{triage.waitingTime ? triage.waitingTime : '00:00:00'}}</span>
                            </div>
                        </div>
                        <div class="block-time">
                            <p><b>Tempo de atendimento:</b></p>
                            <div class="time-count">
                                <span>{{triage.serviceTime ? triage.serviceTime : '00:00:00'}}</span>
                            </div>
                        </div>
                    </div>
                    <p class="professional-responsability"><b>Profissional responsável:
                        </b>{{triage.userClassificationName ? triage.userClassificationName : ''}}</p>
                </div>
            </div>
            <div *ngIf="!(this.patientHistory.triage.length > 0)">
                <p>-</p>
            </div>
            <hr>
            <h2>Atendimento Multiprofissional</h2>
            <div class="module-content" *ngIf="this.patientHistory.multiProfessionalCareHistorie.length > 0">
                <div *ngFor="let multiProfessionalCareHistorie of this.patientHistory.multiProfessionalCareHistorie">
                    <div class="time-content">
                        <div class="block-time">
                            <p><b>Tempo de espera:</b></p>
                            <div class="time-count">
                                <span>{{multiProfessionalCareHistorie.waitingTime ?
                                    multiProfessionalCareHistorie.waitingTime : '00:00:00'}}</span>
                            </div>
                        </div>
                        <div class="block-time">
                            <p><b>Tempo de atendimento:</b></p>
                            <div class="time-count">
                                <span>{{multiProfessionalCareHistorie.serviceTime ?
                                    multiProfessionalCareHistorie.serviceTime : '00:00:00'}}</span>
                            </div>
                        </div>
                    </div>
                    <p class="professional-responsability"><b>Profissional responsável:
                        </b>{{multiProfessionalCareHistorie.multiProfessionalCare.userName ?
                        multiProfessionalCareHistorie.multiProfessionalCare.userName : ''}}</p>
                </div>
            </div>
            <div *ngIf="!(this.patientHistory.multiProfessionalCareHistorie.length > 0)">
                <p>-</p>
            </div>
            <hr>
            <h2>Atendimento Médico</h2>
            <div class="module-content" *ngIf="this.patientHistory.medicalCareHistorie.length > 0">
                <div *ngFor="let medicalCareHistorie of this.patientHistory.medicalCareHistorie">
                    <div class="time-content">
                        <div class="block-time">
                            <p><b>Tempo de espera:</b></p>
                            <div class="time-count">
                                <span>{{medicalCareHistorie.medicalCare.waitingTime ?
                                    medicalCareHistorie.medicalCare.waitingTime : '00:00:00'}}</span>
                            </div>
                        </div>
                        <div class="block-time">
                            <p><b>Tempo de atendimento:</b></p>
                            <div class="time-count">
                                <span>{{medicalCareHistorie.medicalCare.serviceTime ?
                                    medicalCareHistorie.medicalCare.serviceTime : '00:00:00'}}</span>
                            </div>
                        </div>
                    </div>
                    <p class="professional-responsability"><b>Profissional responsável:
                        </b>{{medicalCareHistorie.medicalCare.userName ? medicalCareHistorie.medicalCare.userName : ''}}
                    </p>
                </div>
            </div>
            <div *ngIf="!(this.patientHistory.medicalCareHistorie.length > 0)">
                <p>-</p>
            </div>
            <hr>
            <h2>Observação</h2>
            <div class="module-content" *ngIf="this.patientHistory.listServiceObservationStruct.length > 0">
                <div *ngFor="let serviceObservationStruct of this.patientHistory.listServiceObservationStruct">
                    <div class="time-content">
                        <div class="block-time">
                            <p><b>Tempo de espera:</b></p>
                            <div class="time-count">
                                <span>{{serviceObservationStruct.waitingTime ? serviceObservationStruct.waitingTime :
                                    '00:00:00'}}</span>
                            </div>
                        </div>
                        <div class="block-time">
                            <p><b>Tempo de atendimento:</b></p>
                            <div class="time-count">
                                <span>{{serviceObservationStruct.serviceTime ? serviceObservationStruct.serviceTime :
                                    '00:00:00'}}</span>
                            </div>
                        </div>
                    </div>
                    <p class="professional-responsability"><b>Profissional responsável:
                        </b>{{serviceObservationStruct.userName ? serviceObservationStruct.userName : ''}}</p>
                    <p class="footer-block"
                        *ngIf="serviceObservationStruct.medicalProcedures.length > 0 || serviceObservationStruct.medicalPrescription.length > 0">
                        <b>Procedimentos realizados: </b>
                    </p>
                    <div class="procedures-content"
                        *ngIf="serviceObservationStruct.medicalProcedures.length > 0 || serviceObservationStruct.medicalPrescription.length > 0">
                        <div *ngFor="let medicalProcedure of serviceObservationStruct.medicalProcedures">
                            <p class="footer-block" *ngIf="medicalProcedure.procedureName">
                                {{medicalProcedure.procedureName}}</p>
                        </div>
                    </div>
                    <div class="procedures-content" *ngIf="serviceObservationStruct.medicalPrescription.length > 0">
                        <div *ngFor="let medicalPrescription of serviceObservationStruct.medicalPrescription">
                            <p class="footer-block" *ngIf="medicalPrescription.medicineName">
                                {{medicalPrescription.medicineName}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!(this.patientHistory.listServiceObservationStruct.length > 0)">
                <p>-</p>
            </div>
            <hr>
            <h2>SADT</h2>
            <div class="module-content" *ngFor="let medicalCareHistorieSadt of this.patientHistory.medicalCareHistorie">
                <div *ngIf="medicalCareHistorieSadt.medicalCareSadtJourneyStruct &&
                            (medicalCareHistorieSadt.medicalCareSadtJourneyStruct.waitingTime ||
                            medicalCareHistorieSadt.medicalCareSadtJourneyStruct.serviceTime ||
                            medicalCareHistorieSadt.medicalCareSadtJourneyStruct.userName)">
                    <div class="time-content">
                        <div class="block-time">
                            <p><b>Tempo de espera:</b></p>
                            <div class="time-count">
                                <span>{{medicalCareHistorieSadt.medicalCareSadtJourneyStruct.waitingTime ?
                                    medicalCareHistorieSadt.medicalCareSadtJourneyStruct.waitingTime :
                                    '00:00:00'}}</span>
                            </div>
                        </div>
                        <div class="block-time">
                            <p><b>Tempo de atendimento:</b></p>
                            <div class="time-count">
                                <span>{{medicalCareHistorieSadt.medicalCareSadtJourneyStruct.serviceTime ?
                                    medicalCareHistorieSadt.medicalCareSadtJourneyStruct.serviceTime :
                                    '00:00:00'}}</span>
                            </div>
                        </div>
                    </div>
                    <p class="professional-responsability"><b>Profissional responsável:
                        </b>{{medicalCareHistorieSadt.medicalCareSadtJourneyStruct.userName ?
                        medicalCareHistorieSadt.medicalCareSadtJourneyStruct.userName : ''}}</p>

                    <p class="footer-block" *ngIf="medicalCareHistorieSadt.medicalCareSadt.length > 0"><b>Exames
                            realizados: </b></p>
                    <div class="procedures-content" *ngIf="medicalCareHistorieSadt.medicalCareSadt.length > 0">
                        <div *ngFor="let medicalCareSadt of medicalCareHistorieSadt.medicalCareSadt">
                            <div *ngFor="let item of medicalCareSadt.medicalCareSadt">
                                <p class="footer-block" *ngIf="item.sadtName">{{item.sadtName}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!(this.patientHistory.medicalCareHistorie.length > 0)">
                    <p>-</p>
                </div>
            </div>
            <hr>
            <h2>Tempo total da Unidade</h2>
            <div class="module-content" *ngIf="this.patientHistory.startTimeSoFar != null">
                <div class="block-time">
                    <div class="time-count">
                        <span>{{this.patientHistory.startTimeSoFar ? this.patientHistory.startTimeSoFar :
                            '00:00:00'}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="btn-container">
            <button mat-flat-button color="accent" class="button-close-complete-journey"
                (click)="close()">FECHAR</button>
        </div>
    </div>
</div>