<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="body-select-device-modal-list">
            <span class="description">Selecione dispositivo que deseja configurar:</span>
            <div class="filters">
                <div class="row align">
                    <div class="col-10">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Pesquisar</mat-label>
                            <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText"
                                (keyup)="onKeySearch()">
                        </mat-form-field>
                    </div>
                    <div class="col-2">
                        <mat-icon matTooltip="Recarregar" class="option-icon" (click)="scanDevices()">sync</mat-icon>
                    </div>
                </div>
            </div>
            <span class="span-gif" *ngIf="isConnecting || isScanning">
                <img class="connection-gif" src="assets/images/bluetooth-search.gif" >
                <span *ngIf="isScanning">Buscando...</span>
                <span *ngIf="isConnecting">Conectando...</span>
            </span>
            <div *ngIf="!isConnecting" class="body-select-device-modal-list__options">
                <div mat-flat-button color="accent" class=" body-select-device-modal-list__options--buttons"
                    (click)="selectItem(item)" *ngFor="let item of listDeviceDisplay">
                    <span class="option-name truncate">{{item.name}} - {{item.address}} </span>
                    <mat-icon class="option-icon">navigate_next</mat-icon>
                </div>
            </div>
        </div>
    </div>
</div>