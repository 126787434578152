import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-remove-allergy-modal',
  templateUrl: './remove-allergy-modal.component.html',
  styleUrls: ['./remove-allergy-modal.component.css']
})
export class RemoveAllergyModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<RemoveAllergyModalComponent>,
    private formBuilder: FormBuilder,) { }

  public model: FormGroup;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      motive: ['', [Validators.required]],
    });
  }

  close() {
    this.matDialogRef.close({ isRemoved: false });
  }

  submit() {
    if (!this.model.valid) {
      return;
    }
    this.matDialogRef.close({ isRemoved: true, motive: this.model.get('motive').value });
  }

  clickCancel() {
    this.matDialogRef.close({ isRemoved: false });
  }
}

