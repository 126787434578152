import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { AreaRankingResponse } from '../../responses/gamification/area-ranking.response';
import { GetListRankViewRespone } from '../../responses/gamification/get-list-rank-view.respone';

@Injectable({
  providedIn: 'root'
})
export class AreaRankingService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getAreaRanking(idArea: number): Observable<AreaRankingResponse> {

    let uri = `AreaRanking/idArea/${idArea}`;

    return this.httpClient.get<AreaRankingResponse>(environment.urlApiGamification + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getAreaRankingByPeriod(beginPeriod: Date, endPeriod: Date): Observable<AreaRankingResponse> {

    let uri = `AreaRanking?`;
    uri = uri + `beginPeriod=${beginPeriod.toJSON()}&`;
    uri = uri + `endPeriod=${endPeriod.toJSON()}&`;

    return this.httpClient.get<AreaRankingResponse>(environment.urlApiGamification + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getRankView(idService: number, idSector: number = null): Observable<GetListRankViewRespone> {

    let uri = `arearanking/idService/${idService}?`

    if (idSector != null)
      uri = uri + `idSector=${idSector}&`;


    return this.httpClient.get<GetListRankViewRespone>(environment.urlApiGamification + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}