<div mat-dialog-content>
    <div class="mat-dialog-header">
        <h3><b>Confirme ou altere os dados do paciente para o envio dos documentos</b></h3>
    </div>
    <div class="mat-dialog-content">
        <form class="form" [formGroup]="model" (ngSubmit)="submit($event)">
            <div class="row">
                <div class="col-12">
                    <mat-form-field appearance="outline" class="outline-spinner">
                        <mat-label>Email</mat-label>
                        <input matInput type="email" formControlName="email">
                        <mat-error *ngIf="model.get('email').invalid">E-mail inválido</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="footer-panel-modal-list">
                <div class="row" *ngIf="!isLoading">
                    <div class="col-6">
                        <button mat-flat-button color="accent" class="btn-block"
                            (click)="submit(false)">Cancelar</button>
                    </div>
                    <div class="col-6">
                        <button mat-flat-button type="submit" color="primary" class="btn-block" (click)="submit(true)">
                            <span *ngIf="isLoading == false">Concluir</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                </div>
                <div class="loading" *ngIf="isLoading">
                    <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                </div>
            </div>
        </form>
    </div>
</div>