import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PatientStruct } from '../../services/structs/medical-record/patient.struct';
import { Router } from '@angular/router';

@Component({
  selector: 'app-patient-user-details-modal',
  templateUrl: './patient-user-details-modal.component.html',
  styleUrls: ['./patient-user-details-modal.component.css']
})
export class PatientUserDetailsModalComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<PatientUserDetailsModalComponent>,
    
  ) {
  }

  patient: PatientStruct = this.data?.patient;

  ngOnInit(): void {

  }

  clickClosed() {
    this.matDialogRef.close();
  }

  clickEdit() {
    this.matDialogRef.close(this.patient);
  }
}