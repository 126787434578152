import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertService, AlertType } from '../../services/alert.service';
import { CareLineModalComponent } from '../care-line-modal/care-line-modal.component';
import { CareLinePriorityStatusEnum } from '../../enum/care-line-priority/care-line-priority-status.enum';
import { CareLinePiorityStatusService } from '../../services/API/medical-record/care-line-priority-status.service';
import { CareLinePriorityStatusRequest } from '../../services/requests/medical-record/care-line-priority-status.request';
import { CareLinePriorityStatusResponse } from '../../services/responses/medical-record/care-line-priority-status.response';
import { CareLinePriorityStruct } from '../../services/structs/medical-record/care-line-priority-validated.struct';

@Component({
  selector: 'app-validate-care-line-priority-modal',
  templateUrl: './validate-care-line-priority-modal.component.html',
  styleUrls: ['./validate-care-line-priority-modal.component.css']
})
export class ValidateCareLinePriorityModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialogRef: MatDialogRef<CareLineModalComponent>,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private careLinePiorityStatusService: CareLinePiorityStatusService,
  ) { }

  public model: FormGroup;
  public idEpisode: number;
  public careLinePriority: CareLinePriorityStruct;
  public statusConfirmed: number = CareLinePriorityStatusEnum.confirmado;
  public statusDenied: number = CareLinePriorityStatusEnum.negado;

  public careLinePriorityStatusResponse: CareLinePriorityStatusResponse;

  ngOnInit(): void {
    this.idEpisode = this.data.idEpisode;
    this.careLinePriority = this.data.careLinePriority;

    this.model = this.formBuilder.group({
      idCareLinePriority: [this.careLinePriority.idCareLinePriority, [Validators.required]],
      idCareLinePriorityStatus: [null, [Validators.required]],
      careLinePriorityStatusMotive: ["", [Validators.required]]
    });
  }

  submit() {
    if (this.model.invalid) 
      return;

    let request: CareLinePriorityStatusRequest = new CareLinePriorityStatusRequest();
    request.idCareLinePriority = this.model.get('idCareLinePriority').value;
    request.idCareLinePriorityStatus = this.model.get('idCareLinePriorityStatus').value;
    request.careLinePriorityStatusMotive = this.model.get('careLinePriorityStatusMotive').value;

    this.careLinePiorityStatusService.updateCareLinePriorityStatus(this.idEpisode, request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.careLinePriorityStatusResponse = response;

        let status: string = "";

        if (response.idCareLinePriorityStatus === this.statusConfirmed) 
          status = "confirmada";
        else if (response.idCareLinePriorityStatus === this.statusDenied) 
          status = "negada";

        this.alertService.show('Sucesso!', `Linha de cuidado ${status} com sucesso`, AlertType.success);

        this.matDialogRef.close({
          careLinePriorityStatusResponse: this.careLinePriorityStatusResponse,
        });
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  clickCancel() {
    this.matDialogRef.close({});
  }
}