import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FavoriteTypeEnum } from 'src/app/shared/enum/favorite.enum';
import { MedicFavoriteNonStandardPrescriptionService } from 'src/app/shared/services/API/medic/medic-favorite-non-standard-prescription.service';
import { MedicFavoriteStandardPrescriptionService } from 'src/app/shared/services/API/medic/medic-favorite-standard-prescription.service';
import { FavoriteExamPrescriptionService } from 'src/app/shared/services/API/medic/favorite-exam-prescription.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MedicFavoriteNonStandardPrescriptionRequest } from 'src/app/shared/services/requests/medic/medic-favorite-non-standard-prescription-request';
import { MedicFavoriteStandardPrescriptionRequest } from 'src/app/shared/services/requests/medic/medic-favorite-standard-prescription-request';
import { FavoriteGroupExamPrescriptionRequest } from 'src/app/shared/services/requests/medic/favorite-group-exam-prescription.request';
import { FavoriteExamPrescriptionStruct } from 'src/app/shared/services/structs/medic/favorite-exam-prescription.struct';
import { SadtGroupStruct } from 'src/app/shared/services/structs/medical-record/sadt-group.struct';

@Component({
  selector: 'app-favorite-medicine-modal',
  templateUrl: './favorite-prescription-modal.component.html',
  styleUrls: ['./favorite-prescription-modal.component.css']
})
export class SaveFavoritePrescriptionModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<SaveFavoritePrescriptionModalComponent>,
    private alertService: AlertService,
    private favoriteNonStandardMedicineService: MedicFavoriteNonStandardPrescriptionService,
    private favoriteMedicineService: MedicFavoriteStandardPrescriptionService,
    private favoriteExamPrescriptionService: FavoriteExamPrescriptionService,
    private formBuilder: FormBuilder
  ) { }

  public favoriteType: number;
  public model: FormGroup;
  public save: boolean = false;
  loading: boolean = false;

  ngOnInit(): void {
    if ((!this.data.listPrescription || this.data.listPrescription.length === 0) &&
        (!this.data.listPrescriptionMedicatedSolution || this.data.listPrescriptionMedicatedSolution.length === 0)) {
      this.clickCancel();
      this.alertService.show('Error', 'Não há prescrições a serem favoritadas', AlertType.error);
    }

    this.favoriteType = this.data.favoriteType;

    this.model = this.formBuilder.group({
      favoriteName: ['', [Validators.required]]
    });
  }

  clickCancel() {
    this.matDialogRef.close({ save: this.save });
  }

  clickSave() {
    if (this.model.invalid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    switch (this.favoriteType) {
      case (FavoriteTypeEnum.StandardMedicine):
        this.saveStandardPrescription();
        break;

      case (FavoriteTypeEnum.NonStandardMedicine):
        this.saveNonStandardPrescription();
        break;

      case (FavoriteTypeEnum.Exam):
        this.saveExamPrescription();
        break;
    }
  }

  saveExamPrescription() {
    this.loading = true;

    let favoriteRequest: FavoriteGroupExamPrescriptionRequest = new FavoriteGroupExamPrescriptionRequest();
    let listPrescription: SadtGroupStruct[] = this.data.listPrescription;
    
    listPrescription.forEach((exam) => {
      let newExam = new FavoriteExamPrescriptionStruct();
      newExam.favoriteExamPrescription.idExamType = exam.idTypeSadt;
      newExam.favoriteExamPrescription.idPlace = exam.idPlace;
      newExam.favoriteExamPrescription.description = exam.sadtDescription;
      newExam.favoriteExamPrescription.idDoctorFavoriteGroupExamPrescription = 0;
      newExam.favoriteExamPrescription.idFavoriteExamPrescription = 0;
      newExam.listIdExam = exam.medicalCareSadt.map(x => x.idSadt);
      favoriteRequest.listFavoriteExamPrescriptionStruct.push(newExam);
    });
    
    favoriteRequest.doctorFavoriteGroupExamPrescriptionName = this.model.get('favoriteName').value;

    this.favoriteExamPrescriptionService.postFavoriteGroupExamPrescription(favoriteRequest).subscribe({
      next: (response: { isError: any; errorDescription: string; }) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.loading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.loading = false;
        this.save = true;
        this.matDialogRef.close({ save: this.save });
      },
      error: (error: string) => {
        console.log(error)
        this.loading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });

  }

  saveNonStandardPrescription() {
    this.loading = true;

    let favoriteMedicineRequest: MedicFavoriteNonStandardPrescriptionRequest = new MedicFavoriteNonStandardPrescriptionRequest();
    favoriteMedicineRequest.listFavoriteNonStandardMedicine = this.data.listPrescription;
    favoriteMedicineRequest.favoriteMedicineName = this.model.get('favoriteName').value;

    if (favoriteMedicineRequest.listFavoriteNonStandardMedicine && favoriteMedicineRequest.listFavoriteNonStandardMedicine.length > 0){
      favoriteMedicineRequest.listFavoriteNonStandardMedicine.forEach(item =>{
        item.idFavoriteNonStandardMedicine = 0;
        item.idMedicFavoriteNonStandardPrescription = 0;
      });
    }

    this.favoriteNonStandardMedicineService.postMedicFavoriteNonStandarPrescription(favoriteMedicineRequest).subscribe({
      next: (response: { isError: any; errorDescription: string; }) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.loading = false;
          return;
        }

        this.loading = false;

        this.save = true;
        this.matDialogRef.close({ save: this.save });
      },
      error: (error: string) => {
        console.log(error)
        this.loading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  saveStandardPrescription() {
    this.loading = true;

    let favoriteMedicineRequest: MedicFavoriteStandardPrescriptionRequest = new MedicFavoriteStandardPrescriptionRequest();
    favoriteMedicineRequest.listFavoriteStandardMedicine = this.data.listPrescription;
    favoriteMedicineRequest.listMedicatedSolutionPrescription = this.data.listPrescriptionMedicatedSolution;
    favoriteMedicineRequest.favoriteMedicineName = this.model.get('favoriteName').value;

    if (favoriteMedicineRequest.listFavoriteStandardMedicine && favoriteMedicineRequest.listFavoriteStandardMedicine.length > 0){
      favoriteMedicineRequest.listFavoriteStandardMedicine.forEach(item =>{
        item.idFavoriteStandardMedicine = 0;
        item.idMedicFavoriteStandardPrescription = 0;
      });
    }

    if (favoriteMedicineRequest.listMedicatedSolutionPrescription && favoriteMedicineRequest.listMedicatedSolutionPrescription.length > 0){
      favoriteMedicineRequest.listMedicatedSolutionPrescription.forEach(item =>{
        item.listMedicine.forEach(medicine => {
          medicine.idFavoriteStandardMedicine = 0;
        })
        item.idMedicFavoriteStandardPrescription = 0;
      });
    }

    this.favoriteMedicineService.postMedicFavoriteStandarPrescription(favoriteMedicineRequest).subscribe({
      next: (response: { isError: any; errorDescription: string; }) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.loading = false;
          return;
        }

        this.loading = false;

        this.save = true;
        this.matDialogRef.close({ save: this.save });
      },
      error: (error: string) => {
        console.log(error)
        this.loading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}