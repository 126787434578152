<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="img-head-modal">
            <img src="assets/images/exclamation-circle.png" />
        </div>
        <div class="title-modal">
            <h1><b>Atenção!</b></h1>
        </div>
         
        <div class="body-modal">
            <p>O paciente é alérgico a um dos princípios ativos deste medicamento.</p>
            <p *ngIf="!this.data.blockAllergicPrescription">Deseja prosseguir com a prescrição?</p>
            <p *ngIf="this.data.blockAllergicPrescription">Prescrição bloqueada!</p>
        </div>
        <div class="footer-modal">
            <button *ngIf="!this.data.blockAllergicPrescription" mat-flat-button color="accent" class="btn-block btn-confirm" (click)="clickConfirm()" [color]="buttonColor">
                <span>Sim</span>
            </button>
            <button mat-flat-button color="accent" class="btn-block btn-cancel" (click)="clickCancel()">
                <span *ngIf="!this.data.blockAllergicPrescription">Não</span>
                <span *ngIf="this.data.blockAllergicPrescription">Sair</span>
            </button>
        </div>
        <div class="justification-content" *ngIf="this.confirm">
            <form class="form" [formGroup]="model" (ngSubmit)="onSubmit()">
                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Justificativa</mat-label>
                            <textarea matInput formControlName="justification"></textarea>
                            <mat-error *ngIf="model.get('justification').invalid">Informe a justificativa</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="footer-modal">
                    <button mat-flat-button type="submit" color="primary" class=" btn-block">
                        <span>Concluir</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

