import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { LookupResponse } from '../../responses/private-billing/lookup.response';
import { LookupTissGuideReportResponse } from '../../responses/private-billing/lookup-tiss-guide-report.response';

@Injectable({
  providedIn: 'root'
})
export class LookupService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public listAllLookups(): Observable<LookupResponse> {
    let uri = `Lookup`

    return this.httpClient.get<LookupResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public listTerminologyLookup(idTerminologyOrigin: number, searchText: string = null): Observable<LookupResponse> {
    let uri = `Lookup/getTerminologyLookup/idTerminologyOrigin/${idTerminologyOrigin}`

    if (searchText != null)
      uri = uri + `?searchText=${searchText}&`;

    return this.httpClient.get<LookupResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }  

  public getLookupTissGuideReport(): Observable<LookupTissGuideReportResponse> {
    let uri = `Lookup/getLookupTissGuideReport`

    return this.httpClient.get<LookupTissGuideReportResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public getLookupToGuideBatchList(): Observable<LookupResponse> {
    let uri = `Lookup/getLookupToGuideBatchList`

    return this.httpClient.get<LookupResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}