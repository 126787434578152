<!-- <div class="container-out" (click)="close()" *ngIf="isVisible == true"></div> -->

<div class="container-modulo" *ngIf="isVisible == true">
    <h5>Módulo atual</h5>
    <h4>{{this.actualModuleName}}</h4>
    <div class="initial-module">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <span>Definir como módulo inicial</span>
            <div class="form-field-toggle">
                <mat-slide-toggle formControlName="isDefaultModule" (change)="changeDefaultModule($event)"></mat-slide-toggle>
            </div>
        </form>
        <p class="default-module">Módulo Inicial: 
            <span *ngIf="this.defaultModuleName; else elseBlock">{{this.defaultModuleName}}</span>
            <ng-template #elseBlock>Nenhum</ng-template>
        </p>
    </div>
    
    <div class="action">
        <div class="filters">
            <mat-form-field class="filters-form" appearance="outline">
                <mat-icon matPrefix class="icon-search">search</mat-icon>
                <mat-label>Pesquisar</mat-label>
                <input matInput #search type="text" id="searchText" name="searchText" [(ngModel)]="searchText" (keyup)="onKeySearch()">
            </mat-form-field>
        </div>
        <app-loading-list *ngIf="isLoading"></app-loading-list>
        <ul *ngIf="filteredListModuleStruct != null && filteredListModuleStruct != undefined">
            <ngContainer *ngFor="let item of filteredListModuleGroupStruct">
                <p class="module-names">{{item.nameModuleGroup}}</p>
                <ngContainer *ngFor="let item2 of filteredListModuleStruct" [ngClass]="{'disabled-module': !item2.isModulePermitted}">
                    <ngContainer *ngIf="item2.idModuleGroup == item.idModuleGroup">
                        <li *ngIf="item2.isModulePermitted || this.isMaster">
                            <a (click)="redirect(item2)">
                                <span>{{item2.nameModule}}</span>
                                <mat-icon class="chevron" aria-hidden="false" aria-label="Menu apps">chevron_right</mat-icon>
                    
                            </a>
                        </li>
                    </ngContainer>
                </ngContainer>
                <br>
            </ngContainer>
        </ul>
    </div>
</div>