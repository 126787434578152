import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { TagStruct } from './tag.struct';
import { Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.css']
})
export class TagComponent implements OnInit {

  constructor() {
  }

  @Input() listTags: TagStruct[];
  @Input() allTags: TagStruct[];
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;

  public tagCtrl = new UntypedFormControl();

  public filteredTags: Observable<TagStruct[]>;
  public selectable = true;
  public removable = true;
  public addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  ngOnInit(): void {
    if(!this.listTags)
      this.listTags = [];
    
    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) => (tag && typeof tag === 'string' ? this._filter(tag) : this.allTags.slice())),
    );
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.listTags.push({ idTag: null, tagName: value });
    }

    // Clear the input value
    if (event.value) {
      event.value = '';
    }

    this.tagCtrl.setValue(null);
  }

  remove(tag: TagStruct): void {
    const index = this.listTags.indexOf(tag);

    if (index >= 0) {
      this.listTags.splice(index, 1);
      this.allTags.push(tag);
      this.tagInput.nativeElement.value = null;
      this.tagCtrl.setValue(null);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    let tag: TagStruct = { idTag: event.option.value.idTag, tagName: event.option.value.tagName };
    let tagIndex = this.allTags.findIndex(l => l.idTag == tag.idTag);

    if(tagIndex >= 0) {
      this.allTags.splice(tagIndex, 1)
      this.listTags.push(tag);
      this.tagInput.nativeElement.value = null;
      this.tagCtrl.setValue(null);
    }
  }

  private _filter(value: string): TagStruct[] {
    const filterValue = value.toLowerCase();

    return this.allTags.filter(l => l.tagName.toLowerCase().includes(filterValue));
  }
}
