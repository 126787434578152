import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-iframe-preview-modal',
  templateUrl: './iframe-preview-modal.component.html',
  styleUrls: ['./iframe-preview-modal.component.css']
})
export class IframePreviewModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<IframePreviewModalComponent>,
  private sanitizer: DomSanitizer) { }
  
  public isLoading:boolean;  
  public url: SafeResourceUrl = '';
  public title: string = '';
  
  ngOnInit(): void {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url);
    this.title = this.data.title;
  }
  
  close(){
    this.matDialogRef.close(); 
  }
}

