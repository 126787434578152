import { Validators } from "@angular/forms";
import { VerifyCPF } from "src/app/shared/custom-validators/cpf.validator";
import { FormMetaData } from "src/app/shared/structs/form-meta-data.struct";
import { MaskService } from "../../../mask.service";

export class VidaaSCellPhoneNotificationRequest {
    public clientId: string = "";
    public state: string = "";
    public codeChallenge: string = "";
    public codeChallengeMethod: string = "S256";
    public responseType: string = "code";
    public scope: string = "signature_session";
    public loginHint: string = "";
    public redirectUri: string = "push://";
    public lifetime: number = 600000;

    public static metadata(): Map<string, FormMetaData> {
        let masks = MaskService.getMasks();
        let metaData = new Map<string, FormMetaData>();
        metaData['loginHint'] = new FormMetaData('CPF', [Validators.required, VerifyCPF()], undefined, undefined, masks.cpf, false);
        return metaData;
    }
}