<div class="table-container">
    <table class="basic-table desktop">
        <thead class="basic-table__head">
            <tr>
                <th *ngFor="let column of columns" [style.width]="column.width"
                    [ngStyle]="{'text-align': column.textAlign}">
                    {{ column.header }}
                </th>
            </tr>
        </thead>
        <tbody class="basic-table__body">
            <tr class="basic-table__body__row" *ngFor="let item of list">
                <td *ngFor="let column of columns" [style.width]="column.width"
                    [ngStyle]="{'text-align': column.textAlign}">
                    <ng-container *ngIf="!column.isAction; else actionsTemplate" [matTooltip]="item[column.field]">
                        <div *ngIf="column.field === 'datetimeInclusion' || column.field === 'datetimeRelease' || column.field === 'birthDate' || column.field === 'phone1'; else defaultField"
                            [matTooltip]="item[column.field]">
                            <span *ngIf="column.field === 'datetimeInclusion' || column.field === 'datetimeRelease'">{{
                                item[column.field] | date:'dd/MM/yyyy \'às\' HH:mm' }}</span>
                            <span *ngIf="column.field === 'birthDate'">
                                {{ item[column.field] | date:'dd/MM/yyyy' }}
                                {{ item.patientAge ? item.patientAge + ' anos' : '' }}
                            </span>
                            <span *ngIf="column.field === 'phone1'">
                                {{ item[column.field] }}
                            </span>
                        </div>
                        <ng-template #defaultField>
                            <span matTooltip="{{item[column.field]}}">
                                {{ item[column.field] }}
                            </span>
                        </ng-template>
                    </ng-container>

                    <ng-template #actionsTemplate>
                        <div class="actions action-icon">
                            <a (click)="onViewClick(item)">
                                <mat-icon aria-hidden="false" aria-label="Visualizar">visibility</mat-icon>
                            </a>

                            <a *ngIf="column.isEditAction" (click)="onEditClick(item)" class="action-icon">
                                <mat-icon aria-hidden="false" aria-label="Editar" [routerLink]="[]">
                                    edit
                                </mat-icon>
                            </a>
                        </div>
                    </ng-template>
                </td>
            </tr>
        </tbody>
    </table>
</div>