import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TissGuideManagementComponent } from '../tiss-guide-management/tiss-guide-management.component';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
@Component({
  selector: 'app-tiss-guide-management-modal',
  templateUrl: './tiss-guide-management-modal.component.html',
  styleUrls: ['./tiss-guide-management-modal.component.css']
})
export class TissGuideManagementModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<TissGuideManagementModalComponent>,
    private alertService: AlertService,
  ) { }

  @ViewChild(TissGuideManagementComponent) childComponent: TissGuideManagementComponent;

  isLoading = false;

  ngOnInit(): void {
  }

  close() {
    this.matDialogRef.close();
  }

  operationIsLoading(sucess: boolean) {
    this.isLoading = !sucess;

    this.alertService.show('Sucesso', "Guias salvas com sucesso!", AlertType.success);
    this.matDialogRef.close();
  }
}