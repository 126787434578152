import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-call-patient-modal',
  templateUrl: './call-patient-modal.component.html',
  styleUrls: ['./call-patient-modal.component.css']
})
export class CallPatientModalComponent implements OnInit, AfterViewInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<CallPatientModalComponent>,
    public alertService: AlertService,
  ) {
    this.matDialogRef.disableClose = true;
  }


  public isLoading: boolean;
  public audio: HTMLAudioElement = new Audio();
  public clientList: Array<any> = new Array<any>();
  public mainClient: any;

  ngOnInit(): void {
    this.mainClient = {
      idPanel: this.data.idPanel,
      namePacient: this.data.namePacient,
      nameRoom: this.data.nameRoom,
      nameSector: this.data.nameSector,
      ticket: this.data.ticket
    };
  }

  ngAfterViewInit(): void {
    if (this.data.voiceStringBase64) {
      this.audio.src = 'data:audio/mp3;base64,' + this.data.voiceStringBase64;
      let $this = this;
      this.audio.onended = () => {
        $this.audio.onended = null;
        setTimeout(() => $this.matDialogRef.close({ endModal: true }), 1000)
      }
      this.audio.load();
      this.audio.play().catch(() => {
        setTimeout(() => this.matDialogRef.close({ endModal: true }), 5000)
      });
      this.audio.remove();
    } else {
      setTimeout(() => this.matDialogRef.close({ endModal: true }), 5000)
    }
  }
}