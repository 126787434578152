export enum AppointmentFieldsMaxLengthEnum {
  guideNumber = 20,
  guideOperatorNumber = 20,

  beneficiaryCardNumber = 20,
  walletBeneficiaryValidity = 8,
  beneficiaryName = 70,
  beneficiarySocialName = 70,

  beneficiaryNationalHealthCard = 20,

  executionContractorOperatorCode = 14,
  contractorName = 70,
  contractorCNES = 7,
  professionalName = 70,
  counselingNumber = 15,
  completionDate = 8,
  procedureCode = 10,
  procedureDescription = 500,
  justification = 500,

  requesterCodeOperatorCnpj = 14,
  requesterCodeOperatorCpf = 11,
  requesterCodeOperator = 14,
}