export class FeesGuideModel {
    public idFeesGuide: number;
    public providerGuideNumber: string;
    public idReferenceAdmissionGuide: number;
    public admissionGuideNumber: number;
    public ticket: string;
    public patientDocumentNumber: string;
    public patientSocialName: string;
    public newbornAttendance: boolean;
    public executionSiteName: string;
    public executionSiteProviderCode: string;
    public executionSiteCnesCode: string;
    public executantName: string;
    public executantProviderCode: string;
    public executantCnesCode: string;
    public billingStartDatetime: Date;
    public billingEndDatetime: Date;
    public observations: string;
    public feesTotalValue: number;
    public idProviderIdentityType: number;
    public idexecutionStieProviderIdentityType: number;
    public datetimeIssue: Date;
}