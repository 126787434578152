<div mat-dialog-content>
    <div class="mat-dialog-header">
        <h3>Informe o nome do acompanhante:</h3>
    </div>
    <div class="mat-dialog-content">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Acompanhante</mat-label>
                        <input matInput type="text" id="companionPresenceReport" name="companionPresenceReport" formControlName="companionPresenceReport" disabled="isLoading == true">
                    </mat-form-field>
                </div>
            </div>
            <div class="footer-panel-modal-list">
                <div class="row">
                    <div class="col-12">
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                        <button mat-flat-button color="primary" class="btn-block" *ngIf="isLoading == false"
                        type="submit">Salvar</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>