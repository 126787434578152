import { SpSadtExecutedProcedureProfessional } from "../../models/private-billing/sp-sadt-executed-procedure-professional.model";
import { SpSadtExecutedProcedure } from "../../models/private-billing/sp-sadt-executed-procedure.model";

export class SpSadtExecutedProcedureStruct extends SpSadtExecutedProcedure {
    public idProcedure: number;
    public procedureValue: number;
    public idTerminology: number;
    public terminologyCode: string;
    public terminologyName: string;
    public idTerminologyGroup: number;
    public terminologyGroupCode: string;
    public listProfessional: SpSadtExecutedProcedureProfessional[] = [];
}