export class TissBilling{    

    public idTissBilling: number;
    
    public idHealthcareAgreement: number;
    
    public idProviderIdentityType: number;
    
    public providerCode: string;
    
    public providerName: string;
    
    public cnes: string;
    
    public idAttendanceAspect: number;
    
    public idAttendanceType: number;
    
    public idAccidentIndication: number;
    
    public idAppointmentType: number;
    
    public honoraryBilateral: number;
    
    public honorarySameGuide: number;
    
    public honoraryDifferentGuide: number;
    
    public operationalCostBilateral: number;
    
    public operationalCostSameGuide: number;
    
    public operationalCostDifferentGuide: number;
    
    public m2FilmBilateral: number;
    
    public m2FilmSameGuide: number;
    
    public m2FilmDifferentGuide: number;
    
    public otherUrgency: number;
    
    public otherDhe: number;
    
    public otherApartment: number;
    
    public participationSurgeon: number;
    
    public participationFirstAux: number;
    
    public participationSecondAux: number;
    
    public participationThirdAux: number;
    
    public participationForthAux: number;
    
    public participationAuxiliarRoom: number;
    
    public participationScrubNurse: number;
    
    public participationAnesthetist: number;
    
    public participationAnesthetistAuxiliar: number;
    
    public participationConsultant: number;
    
    public participationPerfusionist: number;
    
    public participationBirthRoomPediatrician: number;
    
    public participationSadtAuxiliar: number;
    
    public participationClinic: number;
    
    public participationIntensivist: number;
    
    public datetimeInclusion: Date;

    public isActive: boolean;
}