import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-tiss-guide-not-saved-confirm-modal',
  templateUrl: './tiss-guide-not-saved-confirm-modal.component.html',
  styleUrls: ['./tiss-guide-not-saved-confirm-modal.component.css']
})
export class TissGuideNotSavedConfirmModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<TissGuideNotSavedConfirmModalComponent>) { }

  ngOnInit(): void {
  }

  clickCancel(value: boolean) {
    this.matDialogRef.close(value);
  }
}

