export class TasksQueuesModel {
    public idTasksQueues: number

    public idHealthUnit: number

    public maxAttempts: number;

    public maxBackoff: number;

    public maxConcurrentDispatches: number;

    public maxDispatchesPerSecond: number;

    public maxDoublings: number;

    public maxRetryDuration: number;

    public minBackoff: number;
}