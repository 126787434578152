import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { MedicFavoriteNonStandardPrescriptionResponse } from '../../responses/medic/medic-favorite-non-standard-prescription.response';
import { MedicFavoriteNonStandardPrescriptionRequest } from '../../requests/medic/medic-favorite-non-standard-prescription-request';

@Injectable({
  providedIn: 'root'
})
export class MedicFavoriteNonStandardPrescriptionService extends CommonService {

  constructor(private httpClient: HttpClient) { super(); }

  public listMedicFavoritNonStandardPrescription(): Observable<MedicFavoriteNonStandardPrescriptionResponse> {
    let uri = `MedicFavoriteNonStandardPrescription`

    return this.httpClient.get<MedicFavoriteNonStandardPrescriptionResponse>(environment.urlApiMedic + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public deleteMedicFavoriteNonStandardPrescription(idMedicFavoriteNonStandardPrescription: number): Observable<ReturnStruct> {
    let uri = `MedicFavoriteNonStandardPrescription/idMedicFavoriteNonStandardPrescription/${idMedicFavoriteNonStandardPrescription}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiMedic + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  
  public postMedicFavoriteNonStandarPrescription(request: MedicFavoriteNonStandardPrescriptionRequest): Observable<ReturnStruct> {
    let uri = `MedicFavoriteNonStandardPrescription`

    return this.httpClient.post<ReturnStruct>(environment.urlApiMedic + uri, request, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public putMedicFavoriteStandarPrescription(request: MedicFavoriteNonStandardPrescriptionRequest): Observable<ReturnStruct> {
    let uri = `MedicFavoriteNonStandardPrescription`

    return this.httpClient.put<ReturnStruct>(environment.urlApiMedic + uri, request, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}