import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ProfessionalAgreementRequest } from '../../requests/private-billing/professional-agreement.request';
import { ProfessionalAgreementResponse } from '../../responses/private-billing/professional-agreement.response';
import { ListProfessionalAgreementStructResponse } from '../../responses/private-billing/list-professional-agreement-struct.response';

@Injectable({
  providedIn: 'root'
})
export class ProfessionalAgreementService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public PostPut(request: ProfessionalAgreementRequest): Observable<ProfessionalAgreementResponse> {
    let uri = `ProfessionalAgreement`

    return this.httpClient.post<ProfessionalAgreementResponse>(environment.urlApiPrivateBilling + uri, request, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public getProfessional(idHealthcareAgreement: number, searchText: string = null, isActiveProfessionalsAgreement?: boolean): Observable<ListProfessionalAgreementStructResponse> {
    let uri = `ProfessionalAgreement/idHealthcareAgreement/${idHealthcareAgreement}?`

    if (searchText != null)
    uri = uri + `searchText=${searchText}&`;

    if (isActiveProfessionalsAgreement == true || isActiveProfessionalsAgreement == false)
      uri = uri + `isActiveProfessionalsAgreement=${isActiveProfessionalsAgreement}`;

    return this.httpClient.get<ListProfessionalAgreementStructResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}