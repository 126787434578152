import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NetworkTestEnum } from 'src/app/shared/enum/network-test.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { TestDownloadService } from 'src/app/shared/services/API/test-network/test-download.service';
import { TestPingService } from 'src/app/shared/services/API/test-network/test-ping.service';
import { NetworkDataRequest } from 'src/app/shared/services/requests/test-network/network-data.request';

@Component({
  selector: 'app-network-test-modal',
  templateUrl: './network-test-modal.component.html',
  styleUrls: ['./network-test-modal.component.css']
})
export class NetworkTestModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<NetworkTestModalComponent>,
    private testPingService: TestPingService,
    private testDownloadService: TestDownloadService,
    private alertService: AlertService
  ) { }

  public isLoading:boolean = false;  
  public timeArray: number[] = [];
  public failedAttempts: number;
  public pingAverage: number;
  public percentPingLost: number;
  public transeferRate: number;
  public isTestFinished: boolean = false;
  public signalQuality: string;

  ngOnInit(): void {
    this.isLoading = false;
  }
  
  close(){
    this.matDialogRef.close(); 
  }
  
  clickConfirm(){
    if(this.isLoading){
      return;
    }
    this.isTestFinished = false;
    this.failedAttempts = 0;
    this.pingAverage = 0;
    this.percentPingLost = 0;
    this.timeArray = [];
    this.transeferRate = 0;
    this.donwloadTest();

    this.pingTest();
  }
  
  clickCancel(){
    this.matDialogRef.close(); 
  }

  donwloadTest(){
    this.isLoading = true;
    const fileSize:number = 15.1;
    let start = new Date;
    this.testDownloadService.downloadTest().subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      let end = new Date;

      let time = (end.getTime() - start.getTime())/1000;
      this.transeferRate = Math.round(fileSize/time);
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;
      //this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  pingTest(){
    this.isLoading = true;
    let start = new Date;

    this.testPingService.pingTest().subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.failedAttempts +=1;
        if(this.timeArray.length < 29) this.pingTest();
      }
      let end = new Date;

      let time = (end.getTime() - start.getTime());

      //Preenchendo o array com o tempo em ms do intervalo de chamada
      this.timeArray.push(time);

      if(this.timeArray.length < 29) this.pingTest();
      else{
        let sum = this.timeArray.reduce((a, b) => a + b, 0);
        this.pingAverage = Math.round((sum / this.timeArray.length)) || 0;
        this.percentPingLost = this.failedAttempts>0? (this.failedAttempts/this.timeArray.length)*100 :0 ;

        let networkDataRequest: NetworkDataRequest = new NetworkDataRequest();
        networkDataRequest.ping = this.pingAverage.toString();
        networkDataRequest.percentPing = this.percentPingLost.toString();
        networkDataRequest.internetSpeed = this.transeferRate.toString();
        this.saveData(networkDataRequest);
        this.verifyQuality();
      }
      
    },
    (error)=>{
      console.log(error)
      this.percentPingLost = 100;

      this.isLoading = false;
      this.isTestFinished = true;
      this.signalQuality = "Sem Rede";   
      //this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }


  saveData(networkDataRequest: NetworkDataRequest){
    this.testDownloadService.saveTestData(networkDataRequest).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        this.isTestFinished = true;
        return;
      }
      this.isLoading = false;
      this.isTestFinished = true;
      this.verifyQuality();
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;
      this.isTestFinished = true;
      //this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  verifyQuality(){

    let pointSum = 0;

    if(this.percentPingLost == 100) {
      this.signalQuality = "Sem Rede";
      return;
    }
    if(this.percentPingLost >=20){
      this.signalQuality = "Péssimo";
      return;
    }
    if(this.percentPingLost >=5){
      this.signalQuality = "Ruim";
      return;
    }

    //velocidade e ping
    if(this.pingAverage <252){
      pointSum += NetworkTestEnum.Excelente;
    }
    else if(this.pingAverage >= 250 && this.pingAverage <300){
      pointSum += NetworkTestEnum.Bom;
    }
    else if(this.pingAverage >= 300 && this.pingAverage <350){
      pointSum += NetworkTestEnum.Mediano;
    }
    else if(this.pingAverage >= 350 && this.pingAverage <450){
      pointSum += NetworkTestEnum.Ruim;
    }
    else{
      pointSum += NetworkTestEnum.Pessimo;
    }

    if(this.transeferRate >=8){
      pointSum += NetworkTestEnum.Excelente;
    }
    else if(this.transeferRate >=5 && this.transeferRate < 8){
      pointSum += NetworkTestEnum.Bom;
    }
    else if(this.transeferRate >=4 && this.transeferRate < 5){
      pointSum += NetworkTestEnum.Mediano;
    }
    else if(this.transeferRate >=3 && this.transeferRate < 4){
      pointSum += NetworkTestEnum.Ruim;
    }
    else if(this.transeferRate <3){
      pointSum += NetworkTestEnum.Pessimo;
    }

    //Resultado final
    if(pointSum >= 9){
      this.signalQuality = "Excelente";
    }
    if(pointSum >=7 && pointSum < 9){
      this.signalQuality = "Bom";
    }
    if(pointSum >=5 && pointSum < 7){
      this.signalQuality = "Mediano";
    }
    if(pointSum >=3 && pointSum < 5){
      this.signalQuality = "Ruim";
    }
    if(pointSum < 3){
      this.signalQuality = "Péssimo";
    }
  }
}
