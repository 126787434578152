<h1 mat-dialog-title>Saturação</h1>
<div mat-dialog-content>
    <div class="modal-body text-center">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()" [appUppercaseForm]="this.setUppercaseText">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Saturação</mat-label>
                        <input [value]="data.discriminatorsValues[this.discriminatorType]" [mask]="masks.threeDigits"
                            type="text" matInput formControlName="saturation">
                        <span matSuffix>%</span>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <div class="input-radio-tab">
                        <input [ngClass]="{'force-invalid': isAfterSubmit == false}" value="{{atmosphericAir}}"
                            formControlName="type" type="radio" name="type" id="tipoSaturacao-ar-atmosferico">
                        <label for="tipoSaturacao-ar-atmosferico">Ar atmosférico</label>
                        <input [ngClass]="{'force-invalid': isAfterSubmit == false}" value="{{therapyO2}}"
                            formControlName="type" type="radio" name="type" id="tipoSaturacao-terapia-O2">
                        <label for="tipoSaturacao-terapia-O2">Em terapia de O2</label>
                    </div>
                </div>
            </div>
            <div *ngIf="data.classificationData.priorityAutistic && !data.discriminatorsValues[this.discriminatorType]"
                class="row">
                <div class="col-12">
                    <mat-slide-toggle class="priorityAutistic" (change)="changePriorityAutistic($event)"
                        formControlName="priorityAutistic">
                        Não é possível aferir
                    </mat-slide-toggle>
                </div>
                <div *ngIf="autisticChecked" class="col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Motivo</mat-label>
                        <textarea type="text" matInput formControlName="discriminatorSelectionMotive"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="footer-panel-modal-list">
                <div class="row">
                    <div class="col-12">
                        <button type="submit" mat-flat-button color="primary" class="btn-block">Concluir</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>