<mat-form-field class="chip-list" appearance="fill">
  <mat-label>Tags</mat-label>
  <mat-chip-grid #chipGrid aria-label="Tags">
    <mat-chip-row *ngFor="let tag of listTags" [selectable]="selectable" [removable]="removable"
      (removed)="remove(tag)">
      {{tag.tagName}}
      <button matChipRemove *ngIf="removable" class="btn-remove-chip">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
    <input placeholder="Nova tag..." #tagInput [formControl]="tagCtrl" [matAutocomplete]="auto"
      [matChipInputFor]="chipGrid" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)">
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
      {{tag.tagName}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>