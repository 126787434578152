import { FeesProcedureProfessionalModel } from "../../models/private-billing/fees-procedure-professional.model";

export class FeesProcedureProfessionalStruct extends FeesProcedureProfessionalModel {
    public idFeesProcedureProfessional: number;
    public idFeesProcedure: number;
    public idParticipationDegree: number;
    public professionalProviderCodeOrCpf: string;
    public professionalName: string;
    public idCouncil: number;
    public professionalCouncilCode: string;
    public idCbo: number;
    public idCouncilState: number;
    public participationDegreeLookupCode: string;
    public councilCode: string;
    public councilStateCode: string;
    public cboCode: string;
}