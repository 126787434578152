import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { ListPriorityResponse } from '../../responses/totem/list-priority.response';
import { map, retry, timeout } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PriorityService extends CommonService {

  constructor(private httpClient: HttpClient) { super(); }

  listPriority(): Observable<ListPriorityResponse> {
    let uri = `Priority/getAll`
    var sessionValue = sessionStorage.getItem('PriorityGetAll');
    if (sessionValue) {
      var session: ListPriorityResponse = JSON.parse(sessionValue);
      return of(session);
    }
    return this.httpClient.get<ListPriorityResponse>(environment.urlApiTotem + uri, this.addHeaderToken())
      .pipe(
        timeout(environment.requestTimeOut),
        retry(environment.requestRetry),
        catchError(this.handleError),
        map(t => {
          if (!t.isError)
            sessionStorage.setItem('PriorityGetAll', JSON.stringify(t));
          return t;
        })
      );
  }
}
