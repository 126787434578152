<mat-dialog-content>
    <div class="mat-dialog-header">
        <h3>Suspeita - {{suspicion.suspicionName}}</h3>
    </div>
    <form class="form" [formGroup]="model" (ngSubmit)="submit()">
        <div class="mat-dialog-content">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-radio-group class="radio-group" aria-label="" formControlName="isSuspicionEvaluated">
                        <mat-radio-button [value]="true">Confirmar</mat-radio-button>
                        <mat-radio-button [value]="false">Negar</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Motivo</mat-label>
                        <textarea matInput formControlName="suspicionEvaluationMotive">
                        </textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="footer-panel-modal-list">
                <div class="row">
                    <div class="col-6">
                        <a mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Cancelar</a>
                    </div>
                    <div class="col-6">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>