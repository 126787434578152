<div mat-dialog-content>
    <div class="mat-dialog-header">
        <h2><strong>Prescrições Favoritas</strong></h2>
    </div>
    <div class="mat-dialog-content">
        <form class="form" [formGroup]="model">
            <div class="row">
                <div class="col-12">
                    <mat-form-field
                        *ngIf="!loading && (listMedicFavoritePrescription && listMedicFavoritePrescription.length > 0)"
                        appearance="outline">
                        <mat-label>Selecione seu favorito</mat-label>
                        <mat-select formControlName="idMedicFavoriteNonStandardPrescription"
                            (selectionChange)="loadMedicine($event)">
                            <mat-select-trigger>
                                {{ favoriteMedicineName(model.get('idMedicFavoriteNonStandardPrescription').value) }}
                            </mat-select-trigger>
                            <mat-option *ngFor="let item of listMedicFavoritePrescription"
                                [value]="item.idMedicFavoriteNonStandardPrescription">
                                {{ item.favoritePrescriptionName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <app-empty-list
                        *ngIf="!loading && (!listMedicFavoritePrescription || listMedicFavoritePrescription.length == 0)"
                        [message]="'Não há favoritos cadastrados'"></app-empty-list>
                    <app-loading-list *ngIf="loading"></app-loading-list>
                </div>
            </div>
        </form>
    </div>
    <div class="footer-panel-modal-list">
        <div class="row">
            <div class="col-12">
                <div style="display: flex; justify-content: space-evenly;">
                    <div class="col-12">
                        <a mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Cancelar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>