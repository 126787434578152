<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="row">
            <div class="col-12">
                <h3><b>Você confirma a remoção deste usuário desta sala?</b></h3>
            </div>
        </div>
        <div>
            <div class="col-12">
                <h4>Caso algum atendimento esteja sendo realizado, todos os dados serão perdidos e esta ação é
                    irreversível.</h4>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6">
                <button mat-flat-button color="primary" class=" btn-block" (click)="continue()" type="button">
                    <span>Confirmar</span>
                </button>
            </div>
            <div class="col-12 col-md-6">
                <button mat-flat-button color="accent" class=" btn-block" (click)="clickCancel()"
                    type="button">Cancelar</button>
            </div>
        </div>
    </div>
</div>