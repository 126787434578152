import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { ListAutomaticLoginResponse } from '../../responses/user/list-automatic-login.response';

@Injectable({
  providedIn: 'root'
})
export class AutomaticLoginService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public deleteAutomaticLoginByGuid(guid: string): Observable<ReturnStruct>{
    let uri = `AutomaticLogin/guid/${guid}`;

    return this.httpClient.delete<ReturnStruct>(environment.urlApiUser + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getListConnectedDevices(): Observable<ListAutomaticLoginResponse>{
    let uri = `AutomaticLogin/getAll`;

    return this.httpClient.get<ListAutomaticLoginResponse>(environment.urlApiUser + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
