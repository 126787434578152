import { Guide } from "../../models/private-billing/guide.model";
import { SpSadtGuide } from "../../models/private-billing/sp-sadt-guide.model";
import { SpSadtSerialProcedure } from "../../models/private-billing/sp-sadt-serial-procedure.model";
import { SpSadtExecutedProcedureStruct } from "./sp-sadt-executed-procedure.struct";
import { SpSadtRequestedProcedureStruct } from "./sp-sadt-requested-procedure.struct";

export class SpSadtGuideStruct {
    public guide: Guide = new Guide();
    public spSadtGuide: SpSadtGuide = new SpSadtGuide();
    public listSpSadtExecutedProcedure: SpSadtExecutedProcedureStruct[] = [];
    public listSpSadtRequestedProcedure: SpSadtRequestedProcedureStruct[] = [];
    public listSpSadtSerialProcedure: SpSadtSerialProcedure[] = [];
    public hasAlteration: boolean = false;
    public isEditingAllowed: boolean;
    public guideNumber: string;
    public mainGuideNumber: string;
}