import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-attendance-occupied-remove-modal',
  templateUrl: './attendance-occupied-remove-modal.component.html',
  styleUrls: ['./attendance-occupied-remove-modal.component.css']
})
export class AttendanceOccupiedRemoveModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<AttendanceOccupiedRemoveModalComponent>) { }

  ngOnInit(): void {
  }

  close() {
    this.matDialogRef.close();
  }

  continue() {
    this.matDialogRef.close();
  }

  clickCancel() {
    this.matDialogRef.close();
  }
}

