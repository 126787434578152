<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="header-modal">
            <h1>Guia de SP/SADT</h1>
        </div>
        <app-loading-progress-bar *ngIf="isFirstLoading || isLoading"></app-loading-progress-bar>
        <div class="body-modal">
            <form class="form" [formGroup]="mainModel" id="main-form" (ngSubmit)="submit()">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-3">
                        <app-select-2 [formControlField]="'ansRegistration'" (selectedValue)="onAnsChange($event)"
                            [multiple]="false" [url]="urlListAnsRegistration" [idField]="'idHealthcareAgreement'"
                            [nameField]="'ansWithName'" [labelField]="'1 - Registro ANS'"
                            [preSelectedBlock]="this.idSpSadtGuide != null ? true : false">
                        </app-select-2>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>2 - Nº da guia no prestador</mat-label>
                            <input matInput type="text" formControlName="guideNumber"
                                [maxlength]="spSadtFieldsMaxLengthEnum.guideNumber"
                                oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                            <mat-error *ngIf="mainModel.get('guideNumber').invalid">Preenchimento incorreto</mat-error>
                            <mat-hint align="end">{{ mainModel.get('guideNumber').value ?
                                this.mainModel.get('guideNumber').value.length : 0
                                }}/{{spSadtFieldsMaxLengthEnum.guideNumber}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>3 - Nº da Guia Principal</mat-label>
                            <mat-select *ngIf="!manualMainGuide" formControlName="mainGuideNumber">
                                <mat-option>Nenhum</mat-option>
                                <mat-option *ngFor="let item of listMainGuide" [value]="item.idGuide">
                                    {{ item.guideNumber }} - <b>{{ item.datetimeInclusion | date:'shortDate'}}</b>
                                </mat-option>
                            </mat-select>
                            <input *ngIf="manualMainGuide" matInput type="text" [maxlength]="20"
                                formControlName="mainGuideNumber"
                                oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                            <mat-error *ngIf="mainModel.get('mainGuideNumber').invalid">Preenchimento
                                incorreto</mat-error>
                            <mat-hint *ngIf="manualMainGuide" align="end">{{ mainModel.get('mainGuideNumber').value ?
                                mainModel.get('mainGuideNumber').value.length : 0
                                }}/{{spSadtFieldsMaxLengthEnum.mainGuideNumber}}</mat-hint>
                        </mat-form-field>
                        <mat-checkbox [(ngModel)]="manualMainGuide" [ngModelOptions]="{standalone:true}"
                            [disabled]="!isEditingAllowed || !this.idHealthcareAgreement">
                            Inserir manualmente
                        </mat-checkbox>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Status da guia</mat-label>
                            <div class="adjust-select">
                                <div class="color-dot" [style.background-color]="guideStatusColor">
                                </div>
                                <div>
                                <mat-select class="select-spacing" formControlName="idGuideStatus" (selectionChange)="onGuideStatusChange($event.value)">
                                 <mat-option *ngFor="let item of listGuideStatus" [value]="item.idGuideStatus">
                                  <span class="color-dot" [style.background-color]="item.color"></span>
                                  {{item.name}}
                                 </mat-option>
                                </mat-select>
                                <mat-error *ngIf="mainModel.get('idGuideStatus').invalid">Selecione um status</mat-error>
                                </div>
                            </div>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>4 - Data da Autorização</mat-label>
                            <input matInput type="text" [mask]="masks.date" formControlName="authorizationDate">
                            <mat-error *ngIf="mainModel.get('authorizationDate').invalid">Data inválida</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>5 - Senha</mat-label>
                            <input matInput type="text" [maxlength]="spSadtFieldsMaxLengthEnum.password"
                                formControlName="password">
                            <mat-error
                                *ngIf="mainModel.get('password').invalid && !mainModel.get('password').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ mainModel.get('password').value ?
                                mainModel.get('password').value.length : 0
                                }}/{{spSadtFieldsMaxLengthEnum.password}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>6 - Data de Validade da Senha</mat-label>
                            <input matInput type="text" [mask]="masks.date" formControlName="passwordExpirationDate">
                            <mat-error *ngIf="mainModel.get('passwordExpirationDate').invalid">Data inválida</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6">
                        <mat-form-field appearance="outline">
                            <mat-label>7 - Nº da Guia Atribuído pela Operadora</mat-label>
                            <input matInput type="text"
                                [maxlength]="spSadtFieldsMaxLengthEnum.guideNumberAssignedByTheOperator"
                                formControlName="guideNumberAssignedByTheOperator"
                                oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                            <mat-error
                                *ngIf="mainModel.get('guideNumberAssignedByTheOperator').invalid && !mainModel.get('guideNumberAssignedByTheOperator').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ mainModel.get('guideNumberAssignedByTheOperator').value ?
                                mainModel.get('guideNumberAssignedByTheOperator').value.length : 0
                                }}/{{spSadtFieldsMaxLengthEnum.guideNumberAssignedByTheOperator}}</mat-hint>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="title-header">
                        <h3>Dados do Beneficiário</h3>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>8 - Nº da carteira</mat-label>
                            <input matInput type="text"
                                [maxlength]="spSadtFieldsMaxLengthEnum.beneficiaryDocumentNumber"
                                formControlName="beneficiaryDocumentNumber"
                                oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                            <mat-error
                                *ngIf="mainModel.get('beneficiaryDocumentNumber').invalid && !mainModel.get('beneficiaryDocumentNumber').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ mainModel.get('beneficiaryDocumentNumber').value ?
                                mainModel.get('beneficiaryDocumentNumber').value.length : 0
                                }}/{{spSadtFieldsMaxLengthEnum.beneficiaryDocumentNumber}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>9 - Validade da Carteira</mat-label>
                            <input matInput type="text" [mask]="masks.date" formControlName="documentExpirationDate">
                            <mat-error *ngIf="mainModel.get('documentExpirationDate').invalid">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6">
                        <mat-form-field appearance="outline">
                            <mat-label>10 - Nome do Beneficiário</mat-label>
                            <input matInput type="text" [maxlength]="spSadtFieldsMaxLengthEnum.beneficiaryName"
                                formControlName="beneficiaryName">
                            <mat-error
                                *ngIf="mainModel.get('beneficiaryName').invalid && !mainModel.get('beneficiaryName').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ mainModel.get('beneficiaryName').value ?
                                mainModel.get('beneficiaryName').value.length : 0
                                }}/{{spSadtFieldsMaxLengthEnum.beneficiaryName}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6">
                        <mat-form-field appearance="outline">
                            <mat-label>89 - Nome Social</mat-label>
                            <input matInput type="text" [maxlength]="spSadtFieldsMaxLengthEnum.beneficiarySocialName"
                                formControlName="beneficiarySocialName">
                            <mat-error
                                *ngIf="mainModel.get('beneficiarySocialName').invalid && !mainModel.get('beneficiarySocialName').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ mainModel.get('beneficiarySocialName').value ?
                                mainModel.get('beneficiarySocialName').value.length : 0
                                }}/{{spSadtFieldsMaxLengthEnum.beneficiarySocialName}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>12 - Atendimento a Recém-Nascido</mat-label>
                            <mat-select formControlName="newbornAttendanceIndicator">
                                <mat-option [value]="true">Sim</mat-option>
                                <mat-option [value]="false">Não</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="title-header">
                        <h3>Dados do Solicitante</h3>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Identificação no prestador</mat-label>
                            <mat-select formControlName="idRequesterProviderIdentityType"
                                (selectionChange)="checkRequesterProviderIdentityType($event.value)">
                                <mat-option *ngFor="let item of listProviderIdentityType"
                                    [value]="item.idProviderIdentityType">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="mainModel.get('idRequesterProviderIdentityType').invalid">Selecione um
                                status</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>13 - Código da Operadora</mat-label>
                            <input matInput type="text" [maxlength]="requesterCodeMaxLength"
                                formControlName="requesterContractorOperatorCode" [mask]="requesterCodeMask"
                                (input)="inputNumber('requesterContractorOperatorCode')">
                            <mat-error
                                *ngIf="mainModel.get('requesterContractorOperatorCode').invalid && !mainModel.get('requesterContractorOperatorCode').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ mainModel.get('requesterContractorOperatorCode').value ?
                                mainModel.get('requesterContractorOperatorCode').value.length : 0 }} /
                                {{ spSadtFieldsMaxLengthEnum.requesterContractorOperatorCode }}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6">
                        <mat-form-field appearance="outline">
                            <mat-label>14 - Nome do Contratado</mat-label>
                            <input matInput type="text" [maxlength]="spSadtFieldsMaxLengthEnum.requesterContractorName"
                                formControlName="requesterContractorName">
                            <mat-error
                                *ngIf="mainModel.get('requesterContractorName').invalid && !mainModel.get('requesterContractorName').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ mainModel.get('requesterContractorName').value ?
                                mainModel.get('requesterContractorName').value.length : 0
                                }}/{{spSadtFieldsMaxLengthEnum.requesterContractorName}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <app-select-2
                            *ngIf="listProfessionalAgreementStruct && listProfessionalAgreementStruct.length > 0"
                            [formControlField]="'idProfessionalAgreement'" [list]="this.listProfessionalAgreementStruct"
                            [multiple]="false" [idField]="'idProfessionalAgreement'"
                            (selectedValue)="onProfessionalChange($event)" [nameField]="'nameProfessional'"
                            [labelField]="'15 - Nome do Profissional Solicitante'">
                        </app-select-2>
                    </div>
                    <div class="col-12 col-sm-6 col-md-5">
                        <mat-form-field appearance="outline">
                            <mat-label>16 - Conselho Profissional</mat-label>
                            <mat-select matInput type="text" formControlName="idRequesterProfessionalCouncil">
                                <mat-option *ngFor="let item of listProfessionalCouncil"
                                    [value]="item.idProfessionalCouncil">{{item.termCode}} - {{item.councilName}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="mainModel.get('idRequesterProfessionalCouncil').invalid">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>17 - Nº no Conselho</mat-label>
                            <input matInput type="text"
                                [maxlength]="spSadtFieldsMaxLengthEnum.requesterProfessionalCouncilNumber"
                                formControlName="requesterProfessionalCouncilNumber"
                                oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                            <mat-error
                                *ngIf="mainModel.get('requesterProfessionalCouncilNumber').invalid && !mainModel.get('requesterProfessionalCouncilNumber').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ mainModel.get('requesterProfessionalCouncilNumber').value ?
                                mainModel.get('requesterProfessionalCouncilNumber').value.length : 0
                                }}/{{spSadtFieldsMaxLengthEnum.requesterProfessionalCouncilNumber}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>18 - UF</mat-label>
                            <mat-select matInput type="text" formControlName="idRequesterProfessionalCouncilState">
                                <mat-option *ngFor="let item of listState" [value]="item.idState">{{item.stateCode}} -
                                    {{item.stateInitials}} / {{item.stateName}}
                                </mat-option>
                            </mat-select>
                            <mat-error
                                *ngIf="mainModel.get('idRequesterProfessionalCouncilState').invalid">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>19 - Código CBO</mat-label>
                            <mat-select matInput type="text" formControlName="idRequesterProfessionalCbo">
                                <mat-option *ngFor="let item of listCbo" [value]="item.idCbo">{{item.cboName}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="mainModel.get('idRequesterProfessionalCbo').invalid">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="title-header">
                        <h3>Dados da Solicitação/Procedimentos ou Itens Assistenciais Solicitados</h3>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>21 - Caráter do Atendimento</mat-label>
                            <mat-select matInput type="text" formControlName="idAttendanceAspect">
                                <mat-option *ngFor="let item of listAttendanceAspect"
                                    [value]="item.idAttendanceAspect">{{item.termCode}} - {{item.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="mainModel.get('idAttendanceAspect').invalid">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>22 - Data da Solicitação</mat-label>
                            <input matInput type="text" [mask]="masks.date" formControlName="requestDate">
                            <mat-error *ngIf="mainModel.get('requestDate').invalid">Data inválida</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>90 - Indicador de Cobertura Especial</mat-label>
                            <mat-select matInput type="text" formControlName="idSpecialCoverage">
                                <mat-option *ngFor="let item of listSpecialCoverage"
                                    [value]="item.idSpecialCoverage">{{item.termCode}} -
                                    {{item.specialCoverageDescription}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="mainModel.get('idSpecialCoverage').invalid">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>23 - Indicação Clínica</mat-label>
                            <textarea matInput type="text" [maxlength]="spSadtFieldsMaxLengthEnum.clinicalIndication"
                                oninput="this.value = this.value.replace(/[\n\r]/g, '');"
                                formControlName="clinicalIndication"></textarea>
                            <mat-error
                                *ngIf="mainModel.get('clinicalIndication').invalid && !mainModel.get('clinicalIndication').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ mainModel.get('clinicalIndication').value ?
                                mainModel.get('clinicalIndication').value.length : 0
                                }}/{{spSadtFieldsMaxLengthEnum.clinicalIndication}}</mat-hint>
                        </mat-form-field>
                    </div>

                    <app-sp-sadt-requested-procedure-form #spSadtRequestedProcedureFormComponent
                        [listSpSadtRequestedProcedure]="listSpSadtRequestedProcedure" [listProcedure]="listProcedure"
                        [isEditingAllowed]="isEditingAllowed" [tussTerminologyGroupMap]="tussTerminologyGroupMap"
                        [healthcareAgreementStruct]="healthcareAgreementStruct" [idPatient]="this.data.idPatient"
                        (changeSpSadtRequestedProcedure)="attSpSadtRequestedProcedure($event)"
                        [idSpSadtGuide]="idSpSadtGuide">
                    </app-sp-sadt-requested-procedure-form>

                </div>
                <div class="row">
                    <div class="title-header">
                        <h3>Dados do Contratado Executante</h3>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Tipo do código contratado</mat-label>
                            <mat-select matInput type="text" formControlName="idProviderIdentityType"
                                (selectionChange)="checkProviderIdentityType($event.value)">
                                <mat-option *ngFor="let item of listProviderIdentityType"
                                    [value]="item.idProviderIdentityType">{{item.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="mainModel.get('idProviderIdentityType').invalid">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>29 - Código na Operadora</mat-label>
                            <input matInput type="text" [maxlength]="executantCodeMaxLength"
                                formControlName="executantContractorOperatorCode" [mask]="executantCodeMask"
                                (input)="inputNumber('executantContractorOperatorCode')">
                            <mat-error
                                *ngIf="mainModel.get('executantContractorOperatorCode').invalid && !mainModel.get('executantContractorOperatorCode').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ mainModel.get('executantContractorOperatorCode').value ?
                                mainModel.get('executantContractorOperatorCode').value.length : 0
                                }}/{{spSadtFieldsMaxLengthEnum.executantContractorOperatorCode}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>30 - Nome do Contratado</mat-label>
                            <input matInput type="text" [maxlength]="spSadtFieldsMaxLengthEnum.executantContractorName"
                                formControlName="executantContractorName">
                            <mat-error
                                *ngIf="mainModel.get('executantContractorName').invalid && !mainModel.get('executantContractorName').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ mainModel.get('executantContractorName').value ?
                                mainModel.get('executantContractorName').value.length : 0
                                }}/{{spSadtFieldsMaxLengthEnum.executantContractorName}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>31 - Código CNES</mat-label>
                            <input matInput type="text" [maxlength]="spSadtFieldsMaxLengthEnum.executantCnes"
                                formControlName="executantCnes"
                                oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                            <mat-error
                                *ngIf="mainModel.get('executantCnes').invalid && !mainModel.get('executantCnes').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ mainModel.get('executantCnes').value ?
                                mainModel.get('executantCnes').value.length : 0
                                }}/{{spSadtFieldsMaxLengthEnum.executantCnes}}</mat-hint>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="title-header">
                        <h3>Dados do Atendimento</h3>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>32 - Tipo de Atendimento</mat-label>
                            <mat-select matInput type="text" formControlName="idAttendanceType">
                                <mat-option *ngFor="let item of listAttendanceType"
                                    [value]="item.idAttendanceType">{{item.termCode}} - {{item.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="mainModel.get('idAttendanceType').invalid">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6">
                        <mat-form-field appearance="outline">
                            <mat-label>33 - Indicação de Acidente (acidente ou doença relacionada)</mat-label>
                            <mat-select matInput type="text" formControlName="idAccidentIndication">
                                <mat-option *ngFor="let item of listAccidentIndication"
                                    [value]="item.idAccidentIndication">{{item.termCode}} - {{item.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="mainModel.get('idAccidentIndication').invalid">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>34 - Tipo de Consulta</mat-label>
                            <mat-select matInput type="text" formControlName="idAppointmentType">
                                <mat-option *ngFor="let item of listAppointmentType"
                                    [value]="item.idAppointmentType">{{item.termCode}} - {{item.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="mainModel.get('idAppointmentType').invalid">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-5">
                        <mat-form-field appearance="outline">
                            <mat-label>35 - Motivo de Encerramento do Atendimento</mat-label>
                            <mat-select matInput type="text" formControlName="idTerminationReason">
                                <mat-option *ngFor="let item of listTerminationReason"
                                    [value]="item.idTerminationReason">{{item.termCode}} - {{item.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="mainModel.get('idTerminationReason').invalid">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>91 - Regime de atendimento</mat-label>
                            <mat-select matInput type="text" formControlName="idCareRegime">
                                <mat-option *ngFor="let item of listCareRegime"
                                    [value]="item.idCareRegime">{{item.termCode}} - {{item.careRegimeDescription}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="mainModel.get('idCareRegime').invalid">Preenchimento incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>92 - Saúde Ocupacional</mat-label>
                            <mat-select matInput type="text" formControlName="idOccupationalHealth">
                                <mat-option *ngFor="let item of listOccupationalHealth"
                                    [value]="item.idOccupationalHealth">{{item.termCode}} -
                                    {{item.occupationalHealthName}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="mainModel.get('idOccupationalHealth').invalid">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="title-header">
                        <h3>Dados da Execução/Procedimentos e Exames Realizados</h3>
                    </div>
                    <app-sp-sadt-executed-procedure-table #spSadtExecutedProcedureTable [accessMap]="accessMap"
                        [techniqueMap]="techniqueMap" [participationDegreeMap]="participationDegreeMap"
                        [healthcareAgreementStruct]="healthcareAgreementStruct" [isEditingAllowed]="isEditingAllowed"
                        [listProfessionalAgreementStruct]="listProfessionalAgreementStruct"
                        [tussTerminologyGroupMap]="tussTerminologyGroupMap"
                        [listSpSadtExecutedProcedure]="listSpSadtExecutedProcedure" [cboMap]="cboMap"
                        [tissBilling]="tissBilling" [idPatient]="this.data.idPatient"
                        [idAttendanceAspect]="this.mainModel.get('idAttendanceAspect').value"
                        [listState]="this.listState" [listProfessionalCouncil]="this.listProfessionalCouncil"
                        [idSpSadtGuide]="idSpSadtGuide"
                        (changeSpSadtExecutedProcedure)="attSpSadtExecutedProcedure($event)">
                    </app-sp-sadt-executed-procedure-table>
                </div>
                <div class="row">
                    <div class="title-header">
                        <h3>56 - Data de Realização de Procedimentos em Série</h3>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-2-5" formArrayName="listDateSerialProcedure"
                            *ngFor="let item of mainModel.get('listDateSerialProcedure')['controls']; let i = index">
                            <div [formGroupName]="i">
                                <mat-form-field appearance="outline">
                                    <input matInput type="text" placeholder="00/00/0000" [mask]="masks.date"
                                        formControlName="date">
                                    <mat-error *ngIf="item.get('date').invalid">Data inválida</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4 col-lg-2 col-xl-1">
                            <button mat-flat-button type="button" [disabled]="!this.choseAns || !this.isEditingAllowed"
                                class="add-date-serial" *ngIf="this.formArraySize == 0" color="primary"
                                (click)="addNext()">
                                <span *ngIf="isLoading == false">Adicionar item</span>
                                <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                    *ngIf="isLoading == true"></mat-spinner>
                            </button>
                            <button class="panel-button " color="primary" mat-mini-fab type="button" (click)="addNext()"
                                *ngIf="this.formArraySize > 0 && this.formArraySize < 10"
                                [disabled]="!this.choseAns || !this.isEditingAllowed">
                                <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                            </button>
                            <button class="panel-button " color="primary" mat-mini-fab type="button"
                                (click)="removeLast()" *ngIf="this.formArraySize > 0"
                                [disabled]="!this.isEditingAllowed || !this.idHealthcareAgreement">
                                <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
                            </button>
                        </div>
                    </div>
                    <mat-error *ngIf="mainModel.get('listDateSerialProcedure').errors?.datesNotInCrescentOrder">
                        As datas devem estar em ordem crescente.
                    </mat-error>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>58 - Observação/Justificativa</mat-label>
                            <textarea matInput type="text"
                                [maxlength]="spSadtFieldsMaxLengthEnum.observationJustification"
                                formControlName="observationJustification"
                                oninput="this.value = this.value.replace(/[\n\r]/g, '');"></textarea>
                            <mat-error
                                *ngIf="mainModel.get('observationJustification').invalid && !mainModel.get('observationJustification').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ mainModel.get('observationJustification').value ?
                                mainModel.get('observationJustification').value.length : 0
                                }}/{{spSadtFieldsMaxLengthEnum.observationJustification}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>59 - Total de Procedimentos (R$)</mat-label>
                            <input matInput type="text" formControlName="totalProcedureValue" (keyup)="calculateTotal()"
                                currencyMask
                                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', max: '99999999.99', allowNegative: 'false' }">
                            <mat-error *ngIf="mainModel.get('totalProcedureValue').invalid">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>60 - Total de Taxas e Aluguéis (R$)</mat-label>
                            <input matInput type="text" formControlName="totalFeesRentValue" (keyup)="calculateTotal()"
                                currencyMask
                                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', max: '99999999.99', allowNegative: 'false' }">
                            <mat-error *ngIf="mainModel.get('totalFeesRentValue').invalid">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>61 - Total de Materiais (R$)</mat-label>
                            <input matInput type="text" formControlName="totalMaterialValue" (keyup)="calculateTotal()"
                                currencyMask
                                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', max: '99999999.99', allowNegative: 'false' }">
                            <mat-error *ngIf="mainModel.get('totalMaterialValue').invalid">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>62 - Total de OPME (R$)</mat-label>
                            <input matInput type="text" formControlName="totalOpmeValue" (keyup)="calculateTotal()"
                                currencyMask
                                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', max: '99999999.99', allowNegative: 'false' }">
                            <mat-error *ngIf="mainModel.get('totalOpmeValue').invalid">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>63 - Total de Medicamentos (R$)</mat-label>
                            <input matInput type="text" formControlName="totalMedicationValue"
                                (keyup)="calculateTotal()" currencyMask
                                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', max: '99999999.99', allowNegative: 'false' }">
                            <mat-error *ngIf="mainModel.get('totalMedicationValue').invalid">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>64 - Total de Gases Medicinais (R$)</mat-label>
                            <input matInput type="text" formControlName="totalMedicinalGasValue"
                                (keyup)="calculateTotal()" currencyMask
                                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', max: '99999999.99', allowNegative: 'false' }">
                            <mat-error *ngIf="mainModel.get('totalMedicinalGasValue').invalid">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>65 - Total Geral (R$)</mat-label>
                            <input matInput type="text" formControlName="totalValue" currencyMask
                                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',',allowNegative: 'false' }">
                            <mat-error *ngIf="mainModel.get('totalValue').invalid">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div mat-dialog-footer class="footer-modal">
                    <button mat-flat-button color="accent" class="btn-block" (click)="close()" type="button">
                        <span *ngIf="!isLoading">Cancelar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                    </button>
                    <button form="main-form" mat-flat-button color="primary"
                        class="btn-block" type="submit" [disabled]="!this.isEditingAllowed || !this.idHealthcareAgreement">
                        <span *ngIf="!isLoading">Salvar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                    </button>
                    <button
                        *ngIf="this.spSadtGuideStruct && this.spSadtGuideStruct.guide && this.spSadtGuideStruct.guide.idGuide && this.data && !this.data.isAttendance"
                        mat-flat-button color="accent" class="btn-block" (click)="print();" type="button">
                        <span *ngIf="!isLoading">Imprimir</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>