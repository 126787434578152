<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-type-modal-list">
            <img src="assets/images/alert.png" />
            <h2>Alerta de segurança!</h2>
        </div>

        <div class="body-type-modal-list">
            Por favor, atualize o PIN deste {{data.equipment}} no módulo Master da Unidade e reinicie o acesso.
        </div>
        <div class="footer-type-modal-list">
            <button mat-flat-button color="primary" class="btn-block" (click)="clickRetry()">
                <span *ngIf="isLoading == false">Inserir PIN atualizado</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
        </div>
    </div>
</div>