<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-confirm-modal-list">
            <h3>Deseja evadir o paciente? Ele sairá da fila de atendimento.</h3>
        </div>
        <form class="form" [formGroup]="model"  (ngSubmit)="submit()">
            <div class="body-confirm-modal-list">     
                <div class="row">   
                    <div class="col-12">
                        <mat-radio-group aria-label="" formControlName="idEvasionNotes" class="radio-group">
                            <mat-radio-button value="{{item.idEvasionNotes}}"
                                *ngFor="let item of listEvasionNotesModel" (change)="onRadioButtonChange(item)">{{item.description}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="col-md-12" *ngIf="isOthersEvasionButtonSelected">                   
                        <mat-form-field appearance="outline">
                            <mat-label>Notas de evasão</mat-label>
                            <textarea matInput formControlName="conclusionNote"></textarea>
                            <mat-error *ngIf="model.get('conclusionNote').invalid">Informe a nota de evasão!</mat-error>
                        </mat-form-field>
                    </div>
                </div>            
            </div>
            <div class="footer-confirm-modal-list">
                <button mat-flat-button color="primary" class="btn-block" type="submit">
                    <span *ngIf="isLoading == false">Sim, evadir</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
                <button mat-flat-button color="accent" class="btn-block"(click)="clickCancel()" type="button">
                    <span *ngIf="isLoading == false">Cancelar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>                
            </div>
        </form> 
    </div>
</div>