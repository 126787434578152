<div mat-dialog-content>
    <div class="mat-dialog-header">
        <h3>Selecione um documento para visualizá-lo</h3>
    </div>
    <div class="mat-dialog-content">
        <div *ngIf="!isLoading" class="row">
            <div class="col-12 col-sm-12 col-md-12">
                <div>
                    <div *ngIf="reportDictionary.medicalCareReport.report">
                        <div class="report-select" (click)="viewReport('medicalCareReport')">
                            <span>
                                Ficha de atendimento
                            </span>
                            <mat-icon>{{reportDictionary.medicalCareReport.viewed ? 'verified' :
                                'navigate_next'}}</mat-icon>
                        </div>
                    </div>
                    <div *ngIf="reportDictionary.admissionReport.report">
                        <div class="report-select" (click)="viewReport('admissionReport')">
                            <span>
                                Relatório de admissão
                            </span>
                            <mat-icon>{{reportDictionary.admissionReport.viewed ? 'verified' :
                                'navigate_next'}}</mat-icon>
                        </div>
                    </div>
                    <div *ngIf="reportDictionary.blankMedicalCareReport.report">
                        <div class="report-select" (click)="viewReport('blankMedicalCareReport')">
                            <span>
                                Ficha de atendimento médico
                            </span>
                            <mat-icon>{{reportDictionary.blankMedicalCareReport.viewed ? 'verified' :
                                'navigate_next'}}</mat-icon>
                        </div>
                    </div>
                    <div *ngIf="reportDictionary.wristbandReport.report">
                        <div class="report-select" (click)="viewReport('wristbandReport')">
                            <span>
                                Pulseira
                            </span>
                            <mat-icon>{{reportDictionary.wristbandReport.viewed ? 'verified' :
                                'navigate_next'}}</mat-icon>
                        </div>
                    </div>
                    <div *ngIf="reportDictionary.examInternalReport.report">
                        <div class="report-select" (click)="viewReport('examInternalReport')">
                            <span>
                                Exames internos
                            </span>
                            <mat-icon>{{reportDictionary.examInternalReport.viewed ? 'verified' :
                                'navigate_next'}}</mat-icon>
                        </div>
                    </div>
                    <div *ngIf="reportDictionary.examExternalReport.report">
                        <div class="report-select" (click)="viewReport('examExternalReport')">
                            <span>
                                Exames externos
                            </span>
                            <mat-icon>{{reportDictionary.examExternalReport.viewed ? 'verified' :
                                'navigate_next'}}</mat-icon>
                        </div>
                    </div>
                    <div *ngIf="reportDictionary.internalPrescriptionReport.report">
                        <div class="report-select" (click)="viewReport('internalPrescriptionReport')">
                            <span>
                                Prescrições Padronizadas
                            </span>
                            <mat-icon>{{reportDictionary.internalPrescriptionReport.viewed ? 'verified' :
                                'navigate_next'}}</mat-icon>
                        </div>
                    </div>
                    <div *ngIf="reportDictionary.externalPrescriptionReport.report">
                        <div class="report-select" (click)="viewReport('externalPrescriptionReport')">
                            <span>
                                Prescrições não padronizadas
                            </span>
                            <mat-icon>{{reportDictionary.externalPrescriptionReport.viewed ? 'verified' :
                                'navigate_next'}}</mat-icon>
                        </div>
                    </div>
                    <div *ngIf="reportDictionary.listMemedPrescriptionPdf.listReport && reportDictionary.listMemedPrescriptionPdf.listReport.length > 0">
                        <div class="report-select" (click)="viewListReport('listMemedPrescriptionPdf')">
                            <span>
                                Prescrições não padronizadas
                            </span>
                            <mat-icon>{{reportDictionary.listMemedPrescriptionPdf.viewed ? 'verified' :
                                'navigate_next'}}</mat-icon>
                        </div>
                    </div>
                    <div *ngIf="reportDictionary.procedureReport.report">
                        <div class="report-select" (click)="viewReport('procedureReport')">
                            <span>
                                Procedimentos médicos
                            </span>
                            <mat-icon>{{reportDictionary.procedureReport.viewed ? 'verified' :
                                'navigate_next'}}</mat-icon>
                        </div>
                    </div>
                    <div *ngIf="reportDictionary.sickNoteReport.report">
                        <div class="report-select" (click)="viewReport('sickNoteReport')">
                            <span>
                                Atestado
                            </span>
                            <mat-icon>{{reportDictionary.sickNoteReport.viewed ? 'verified' :
                                'navigate_next'}}</mat-icon>
                        </div>
                    </div>
                    <div *ngIf="reportDictionary.isolationReport.report">
                        <div class="report-select" (click)="viewReport('isolationReport')">
                            <span>
                                Notificação de Isolamento
                            </span>
                            <mat-icon>{{reportDictionary.isolationReport.viewed ? 'verified' :
                                'navigate_next'}}</mat-icon>
                        </div>
                    </div>
                    <div *ngIf="reportDictionary.observationReport.report">
                        <div class="report-select" (click)="viewReport('observationReport')">
                            <span>
                                Ficha de Observação
                            </span>
                            <mat-icon>{{reportDictionary.observationReport.viewed ? 'verified' :
                                'navigate_next'}}</mat-icon>
                        </div>
                    </div>
                    <div *ngIf="reportDictionary.appointmentGuideReport.report">
                        <div class="report-select" (click)="viewReport('appointmentGuideReport')">
                            <span>
                                Guia de consulta
                            </span>
                            <mat-icon>{{reportDictionary.appointmentGuideReport.viewed ? 'verified' :
                                'navigate_next'}}</mat-icon>
                        </div>
                    </div>
                    <div *ngIf="reportDictionary.admissionGuideReport.report">
                        <div class="report-select" (click)="viewReport('admissionGuideReport')">
                            <span>
                                Guia de Internação
                            </span>
                            <mat-icon>{{reportDictionary.admissionGuideReport.viewed ? 'verified' :
                                'navigate_next'}}</mat-icon>
                        </div>
                    </div>
                    <div *ngIf="reportDictionary.feesGuideReport.report">
                        <div class="report-select" (click)="viewReport('feesGuideReport')">
                            <span>
                                Guia de honorários
                            </span>
                            <mat-icon>{{reportDictionary.feesGuideReport.viewed ? 'verified' :
                                'navigate_next'}}</mat-icon>
                        </div>
                    </div>
                    <div *ngIf="reportDictionary.spSadtGuideReport.report">
                        <div class="report-select" (click)="viewReport('spSadtGuideReport')">
                            <span>
                                Guia de SP/SADT
                            </span>
                            <mat-icon>{{reportDictionary.spSadtGuideReport.viewed ? 'verified' :
                                'navigate_next'}}</mat-icon>
                        </div>
                    </div>
                    <div *ngIf="reportDictionary.isolationAcknowledgeReport.report">
                        <div class="report-select" (click)="viewReport('isolationAcknowledgeReport')">
                            <span>
                                Declaração de ciência de isolamento
                            </span>
                            <mat-icon>{{reportDictionary.isolationAcknowledgeReport.viewed ? 'verified' :
                                'navigate_next'}}</mat-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-loading-progress-bar *ngIf="isLoading"></app-loading-progress-bar>
        <div class="footer-panel-modal-list">
            <div class="row button-container">
                <div class="col-6">
                    <button mat-flat-button color="primary" class="btn-block" *ngIf="!isLoading"
                        (click)="close()">Concluir</button>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                </div>
                <div class="col-6">
                    <button mat-flat-button color="accent" class="btn-block" *ngIf="!isLoading"
                        (click)="openAll()">Visualizar todos</button>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                </div>
            </div>
        </div>
    </div>
</div>