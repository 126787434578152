import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReportModalComponent } from 'src/app/shared/components/report-modal/report-modal.component';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { PresenceReportService } from 'src/app/shared/services/API/orchestrator-patient/presence-report.service';
import { PostPresenceReportRequest } from 'src/app/shared/services/requests/orchestrator-patient/post-presence-report.request';
import { CompanionPostPresenceInfoComponent } from './companion-post-presence-info/companion-post-presence-info.component';

@Component({
  selector: 'app-patient-list-reprint-report-modal',
  templateUrl: './patient-list-reprint-report-modal.component.html',
  styleUrls: ['./patient-list-reprint-report-modal.component.css']
})
export class PatientListReprintReportModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<PatientListReprintReportModalComponent>,
    private presenceReportService: PresenceReportService,
    private alertService: AlertService) { }

  public admissionString64: string;
  public wristbandString64: string;
  public triageWristbandString64: string;
  public presenceString64: string;
  public triageString64: string;
  public isLoading: boolean;

  ngOnInit(): void {
    this.admissionString64 = this.data.admissionString64;
    this.wristbandString64 = this.data.wristbandString64;
    this.triageWristbandString64 = this.data.triageWristbandString64;
    this.triageString64 = this.data.triageString64;

    this.matDialogRef.disableClose = true;
  }

  clickReport(string64: string) {
    let reportDialog = this.dialog.open(ReportModalComponent, {
      data: {
        reportPdf64: string64,
      },
    });
  }

  openCreateStatementOfAttendance() {
    if (this.isLoading)
      return;

    this.isLoading = true;

    const reportDialog = this.dialog.open(CompanionPostPresenceInfoComponent, {
      data: {
        postPresenceReportRequest: this.data.postPresenceReportRequest,
      }});

      reportDialog.afterClosed().subscribe({
        next: (response) => {
          if(response.isClosed)
          {
            this.isLoading = true;
            if(response.companionName != null)
            this.data.postPresenceReportRequest.companionPresenceReport = response.companionName;
        
           this.presenceReportService.postReportPDF(this.data.postPresenceReportRequest).subscribe((response) => {
            if (response.isError) {
              this.alertService.show('Erro', response.errorDescription, AlertType.error);
              this.isLoading = false;
              return;
            }
      
            let reportDialog = this.dialog.open(ReportModalComponent, {
              data: {
                reportPdf64: response.reportPdf64,
              },
            });
            reportDialog.afterClosed().subscribe({
              next: (response) => {
                this.isLoading = false;
              },
              error: (error) => {
                console.log(error);
                this.isLoading = false;
              }
            });
          },
            (error) => {
              console.log(error)
              this.alertService.show('Erro inesperado', error, AlertType.error);
              this.isLoading = false;
            });
          }

          else
          this.isLoading = false;

        },
        error: (error) => {
          console.log(error);
          this.isLoading = false;
        }
      });
  }

  clickCancel() {
    this.matDialogRef.close();
  }

  close() {
    this.matDialogRef.close();
  }
}