import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { CallPanelService } from 'src/app/shared/services/API/orchestrator-panel/call-panel.service';
import { CallPanelRequest } from 'src/app/shared/services/requests/orchestrator-panel/call-panel.request';
import { PatientListCallConfirmModalComponent } from '../patient-list-call-confirm-modal/patient-list-call-confirm-modal.component';

@Component({
  selector: 'app-patient-list-liberate-call-confirm-modal',
  templateUrl: './patient-list-liberate-call-confirm-modal.component.html',
  styleUrls: ['./patient-list-liberate-call-confirm-modal.component.css']
})
export class PatientListLiberateCallConfirmModalComponent implements OnInit {
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<PatientListLiberateCallConfirmModalComponent>,
  private alertService: AlertService,
  private callPanelService: CallPanelService,
  private formBuilder: UntypedFormBuilder,) { }
  
  public isLoading:boolean;  
  public model: UntypedFormGroup;
  
  ngOnInit(): void {
    this.isLoading = false;
    
    this.model = this.formBuilder.group({
      login: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }
  
  close(){
    this.matDialogRef.close(); 
  }
  
  submit(){
    if(this.isLoading || !this.model.valid){
      return;
    }
    this.isLoading = true;
    
    let callPanelRequest: CallPanelRequest = new CallPanelRequest();
    
    callPanelRequest.login = this.model.get('login').value;
    callPanelRequest.password = this.model.get('password').value;
    callPanelRequest.idQueue = this.data.idQueue;
    callPanelRequest.idRoomOrigin = this.data.idRoom;
    callPanelRequest.isVerifyCall = true;
    callPanelRequest.idModule = this.data.idModule;
    
    this.callPanelService.restrictedCallPatient(callPanelRequest).subscribe({
      next:(response)=>{
      
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }   
        if(response.errorCode == -1){
          this.alertService.show('Aviso', response.errorDescription, AlertType.error);
        }
        if(response.isConfirm){
          this.openConfirmModal(response.confirmMessage);
        }
        else{
          this.isLoading = false;    
          this.matDialogRef.close({isCalled: true}); 
        } 
      },
      error: (error)=>{
        console.log(error)
        this.isLoading = false;    
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
  
  clickCancel(){
    this.matDialogRef.close({isCalled: false}); 
  }
  
  openConfirmModal(confirmMessage: string){
    const dialogRef = this.dialog.open(PatientListCallConfirmModalComponent, {
      data: {
        confirmMessage: confirmMessage,
        idQueue: this.data.idQueue,
        idRoom: this.data.idRoom,
        idModule: this.data.idModule,
        episodeData: this.data.episodeData,
      },
    });    
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.isLoading = false;    
        this.matDialogRef.close({isCalled: result.isCalled}); 
      }
    });
  }
}
