import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StatusQueueEnum } from '../../enum/status-queue.enum';
import { WebsocketTelemedicineActionEnum } from '../../enum/websocket-telemedicine-action.enum';
import { AlertService, AlertType } from '../../services/alert.service';
import { UpdateStatusQueueService } from '../../services/API/orchestrator-queue/update-status-queue.service';
import { TriggerWebsocketService } from '../../services/API/orchestrator-telemedicine/trigger-websocket.service';
import { StatusQueueRequest } from '../../services/requests/queue/status-queue.request';
import { SelectLookupService } from '../../services/API/queue/select-lookup.service';
import { EvasionNotesModel } from '../../services/models/queue/evasion-notes.model';
import { EvasionNotesEnum } from '../../enum/queue/evasion-notes.enum';

@Component({
  selector: 'app-evade-patient-modal',
  templateUrl: './evade-patient-modal.component.html',
  styleUrls: ['./evade-patient-modal.component.css']
})
export class EvadePatientModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<EvadePatientModalComponent>,
    private alertService: AlertService,
    private updateStatusQueueService: UpdateStatusQueueService,
    private triggerWebsocketService: TriggerWebsocketService,
    private selectLookupService: SelectLookupService,
    private formBuilder: UntypedFormBuilder,) { }

  public isLoading: boolean;
  public model: UntypedFormGroup;
  public integrationEvade: boolean = false;
  public manualEvasion: boolean = false;
  public listEvasionNotesModel: EvasionNotesModel[] = [];
  public isOthersEvasionButtonSelected: boolean = false;

  ngOnInit(): void {
    this.isLoading = false;

    this.model = this.formBuilder.group({
      conclusionNote: ['', [Validators.required]],
      idEvasionNotes: [null, [Validators.required]]
    });

    this.integrationEvade = this.data.integrationEvade;
    this.manualEvasion = this.data.manualEvasion;
    this.getLookupListEvasionNotes();
  }

  getLookupListEvasionNotes() {
    this.isLoading = true;
    this.selectLookupService.listAllLookups().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        if (response.listEvasionNotes && response.listEvasionNotes.length == 0){
          this.alertService.show('Erro', "A lista com os motivos de evasão está vazia.", AlertType.error);
          this.isLoading = false;
          return;
        }
        
        this.listEvasionNotesModel = response.listEvasionNotes;
        this.isLoading = false;
                  
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  close() {
    if (!this.isLoading)
      this.matDialogRef.close({ isEvade: false });
  }

  submit() {
    if (this.isLoading) {
      return;
    }
    if(!this.model.valid){
      this.alertService.show('Erro', 'A razão da evasão deve ser preenchida.', AlertType.error);
      return;
    }
    
    this.isLoading = true;

    this.updateStatusPatient();
  }

  clickCancel() {
    if (!this.isLoading)
      this.matDialogRef.close({ isEvade: false });
  }

  updateStatusPatient() {

    let statusQueueRequest: StatusQueueRequest = new StatusQueueRequest();

    statusQueueRequest.conclusionNote = this.model.get('conclusionNote').value;
    statusQueueRequest.idEvasionNotes = this.model.get('idEvasionNotes').value;
    statusQueueRequest.idQueue = this.data.idQueue;
    statusQueueRequest.idEpisode = this.data.idEpisode;
    statusQueueRequest.idRoom = this.data.idRoom;
    statusQueueRequest.idStatusQueue = StatusQueueEnum.evadido;
    statusQueueRequest.manualEvasion = this.manualEvasion;

    if (this.integrationEvade) {
      statusQueueRequest.attendedPatientEvasion = true;
    }

    this.matDialogRef.disableClose = true;
    this.updateStatusQueueService.updateStatus(statusQueueRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          this.matDialogRef.disableClose = false;
          return;
        }

        else {
          this.triggerAction(WebsocketTelemedicineActionEnum.patientEvasion);
          this.isLoading = false;
          this.matDialogRef.close({ isEvade: true });
        }
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  onRadioButtonChange(evasionNotesSelected: EvasionNotesModel){
    if (evasionNotesSelected.idEvasionNotes == EvasionNotesEnum.Outro){
      this.isOthersEvasionButtonSelected = true;
      this.model.get('conclusionNote').setValue('');      
    } else{
        this.model.get('conclusionNote').setValue(evasionNotesSelected.description);
        this.isOthersEvasionButtonSelected = false;
    }
  }

  triggerAction(action: number) {
    this.triggerWebsocketService.triggerAction(this.data.idEpisode, action).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}

