import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CommonService } from "src/app/shared/services/common-service";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';
import { catchError } from "rxjs/operators";
import { SuspicionStatusRequest } from "../../requests/medical-record/suspicion-status.request";
import { SuspicionStatusResponse } from "../../responses/medical-record/suspicion-status.response";

@Injectable({
    providedIn: 'root'
})
export class SuspicionStatusService extends CommonService {

    constructor(private httpClient: HttpClient) { super(); }

    public putSuspicionEvaluatedStatus(idEpisode: number, body: SuspicionStatusRequest): Observable<SuspicionStatusResponse> {
        let uri = `SuspicionStatus/idEpisode/${idEpisode}`

        return this.httpClient.put<SuspicionStatusResponse>(environment.urlApiMedicalRecord + uri, body, this.addHeaderToken())
            .pipe(catchError(this.handleError));
    }
}