import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { CallPanelService } from 'src/app/shared/services/API/orchestrator-panel/call-panel.service';
import { CallPanelEpisodeRequest } from 'src/app/shared/services/requests/orchestrator-panel/call-panel-episode.request';

@Component({
  selector: 'app-patient-list-call-confirm-modal',
  templateUrl: './patient-list-call-confirm-modal.component.html',
  styleUrls: ['./patient-list-call-confirm-modal.component.css']
})
export class PatientListCallConfirmModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<PatientListCallConfirmModalComponent>,
  private alertService: AlertService,
  private callPanelService: CallPanelService) { }

  public isLoading:boolean;  

  ngOnInit(): void {
    this.isLoading = false;
  }
  
  close(){
    this.matDialogRef.close({isCalled: false}); 
  }
  
  clickConfirm(){

    this.isLoading = true;
    let request = new CallPanelEpisodeRequest();
    request.episodeData = this.data.episodeData;

    this.callPanelService.callPatient(request, this.data.idQueue, this.data.idRoom, false, this.data.idModule).subscribe({
      next:(response)=>{
      
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }   
        if(response.errorCode == -1){
          this.alertService.show('Aviso', response.errorDescription, AlertType.error);
        }
        this.isLoading = false;    
        this.matDialogRef.close({isCalled: true}); 
      },
      error: (error)=>{
        console.log(error)
        this.isLoading = false;    
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
  
  clickCancel(){
    this.matDialogRef.close({isCalled: false}); 
  }
}