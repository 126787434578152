import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.css']
})
export class AlertModalComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<AlertModalComponent>,
  ) { }

  public isLoading: boolean = true;
  public textButtonConfirm: string = this.data.isTwoButtonsModal ? "Confirmar" : "Ok";
  public textButtonCancel: string = this.data.isTwoButtonsModal ? "Cancelar" : "Ok";
  public title: string = this.data.title ? this.data.title : "Alerta!";
  public description: string = this.data.description ? this.data.description : "";

  ngOnInit(): void {
    if (this.data.textButtonConfirm)
      this.textButtonConfirm = this.data.textButtonConfirm;

    if (this.data.textButtonCancel)
      this.textButtonCancel = this.data.textButtonCancel;

    this.isLoading = false;

    if(this.data.isTwoButtonsModal) {
      this.textButtonConfirm = this.data.textButtonConfirm ? this.data.textButtonConfirm : 'Confirmar';
      this.textButtonCancel = this.data.textButtonCancel ? this.data.textButtonCancel : 'Cancelar';
    }
  }

  close() {
    this.matDialogRef.close();
  }

  continue() {
    let reportDialog = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: "Você confirma a remoção deste usuário desta guia?",
        message: "Caso alguma alteração esteja sendo realizada, todos os dados serão perdidos e esta ação é irreversível.",
        btnPrimary: "Não, cancelar",
        btnAccent: "Sim, remover"
      },
    });
    reportDialog.afterClosed().subscribe(result => {
      if (result && result.clickPrimary)
        this.close();
      else
        this.confirm();
    });
  }

  confirm(){
    this.matDialogRef.close({confirm: true}); 
  }

  cancel() {
    this.matDialogRef.close({confirm: false}); 
  }

}
