<div mat-dialog-content>
    <div class="mat-dialog-content">
        <form appFormscroll *ngIf="procedureModel" class="form" [formGroup]="procedureModel" (ngSubmit)="submit()">
            <div class="body-modal">
                <div class="title-header">
                    <h1>Dados do procedimento</h1>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>17 - Data de realização</mat-label>
                            <input matInput type="text" [mask]="masks.date" formControlName="procedureDate">
                            <mat-error *ngIf="procedureModel.get('procedureDate').invalid">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>18 - Hora inicial</mat-label>
                            <input matInput type="text" [mask]="masks.fullTime.mask"
                                [maxlength]="feesFieldsMaxLengthEnum.guideNumber"
                                formControlName="procedureStartDatetime">
                            <mat-error
                                *ngIf="procedureModel.get('procedureStartDatetime').invalid && !procedureModel.get('procedureStartDatetime').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>19 - Hora Final</mat-label>
                            <input matInput type="text" [mask]="masks.fullTime.mask"
                                [maxlength]="feesFieldsMaxLengthEnum.guideNumber"
                                formControlName="procedureEndDatetime">
                            <mat-error
                                *ngIf="procedureModel.get('procedureEndDatetime').invalid && !procedureModel.get('procedureEndDatetime').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-5">
                        <mat-form-field appearance="outline">
                            <mat-label>20 - Tabela</mat-label>
                            <mat-select formControlName="idTerminologyGroup" (selectionChange)="selectTable()">
                                <mat-option *ngFor="let item of tussTerminologyGroupMap | keyvalue"
                                    [value]="item.value.idTerminologyGroup" [disabled]="item.value.idTerminologyGroup !== procedimentos">{{item.value.terminologyGroupCode}} -
                                    {{item.value.nameTerminologyGroup}}
                                </mat-option>
                            </mat-select>
                            <mat-error
                                *ngIf="procedureModel.get('idTerminologyGroup').invalid && !procedureModel.get('idTerminologyGroup').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6">
                        <mat-form-field appearance="outline">
                            <mat-label>21/22 - Cód. do procedimento/Descrição</mat-label>
                            <mat-select formControlName="procedure" (selectionChange)="selectProcedure($event.value)"
                                [disabled]="!this.isEditingAllowed">
                                <input class="inputSearchSelect" type="text" (keyup)="onKey($event.target.value)"
                                    placeholder="Pesquisar" autocomplete="off" (keydown)="$event.stopPropagation()">
                                <mat-option *ngFor="let item of listProcedure"
                                    [value]="item">{{item.terminology.terminologyCode}} -
                                    {{item.terminology.terminologyName}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="procedureModel.get('procedure').invalid">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-4 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>23 - Qtde.</mat-label>
                            <input matInput type="text" (keyup)="onChangeTotalValue()"
                                [maxlength]="feesFieldsMaxLengthEnum.procedureCount" formControlName="procedureCount"
                                (input)="inputNumberMinOne('procedureCount')">
                            <mat-error
                                *ngIf="procedureModel.get('procedureCount').invalid && !procedureModel.get('procedureCount').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                            <mat-hint align="end">{{ procedureModel.get('procedureCount').value ?
                                procedureModel.get('procedureCount').value.length : 0
                                }}/{{feesFieldsMaxLengthEnum.procedureCount}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-4 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>24 - Via</mat-label>
                            <mat-select formControlName="idAccess" (selectionChange)="onChangeAccess()">
                                <mat-option ngValue="null">Selecione</mat-option>
                                <mat-option *ngFor="let item of accessMap | keyvalue"
                                    [value]="item.key">{{item.value.accessLookupCode}} -
                                    {{item.value.accessDescription}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="procedureModel.get('idAccess').invalid">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-4 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>25 - Tec.</mat-label>
                            <mat-select formControlName="idTechnique">
                                <mat-option ngValue="null">Selecione</mat-option>
                                <mat-option *ngFor="let item of techniqueMap | keyvalue"
                                    [value]="item.key">{{item.value.techniqueLookupCode}} -
                                    {{item.value.techniqueDescription}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="procedureModel.get('idTechnique').invalid">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-4 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>26 - Fator Red/Acresc.</mat-label>
                            <input matInput type="text" formControlName="adjustmentCoefficient" currencyMask
                                [options]="{ inputMode: 'Natural', prefix: '', decimal: '.', max: '9.99', allowNegative: 'false' }"
                                (keyup)="onChangeTotalValue()">
                            <mat-error
                                *ngIf="procedureModel.get('adjustmentCoefficient').invalid && !procedureModel.get('adjustmentCoefficient').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>27 - Valor Unitário (R$)</mat-label>
                            <input matInput type="text" formControlName="procedureUnitValue" currencyMask
                                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', max: '999999.99', allowNegative: 'false' }"
                                (keyup)="onChangeTotalValue()">
                            <mat-error
                                *ngIf="procedureModel.get('procedureUnitValue').invalid && !procedureModel.get('procedureUnitValue').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>28 - Valor Total</mat-label>
                            <input matInput type="text" formControlName="procedureTotalValue" currencyMask
                                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', max: '999999.99', allowNegative: 'false' }">
                            <mat-error
                                *ngIf="procedureModel.get('procedureTotalValue').invalid && !procedureModel.get('procedureTotalValue').errors?.maxlength">Preenchimento
                                incorreto</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6">
                        <mat-checkbox formControlName="otherApartment" (change)="onChangeAccess()">
                            Apartamento?
                        </mat-checkbox>
                        <mat-error
                            *ngIf="procedureModel.get('otherApartment').invalid && !procedureModel.get('otherApartment').errors?.maxlength">Preenchimento
                            incorreto</mat-error>
                        <mat-checkbox formControlName="otherUrgency" (change)="onChangeAccess()">
                            Urgência?
                        </mat-checkbox>
                        <mat-error
                            *ngIf="procedureModel.get('otherUrgency').invalid && !procedureModel.get('otherUrgency').errors?.maxlength">Preenchimento
                            incorreto</mat-error>
                        <mat-checkbox formControlName="otherDhe" (change)="onChangeAccess()">
                            DHE?
                        </mat-checkbox>
                        <mat-error
                            *ngIf="procedureModel.get('otherDhe').invalid && !procedureModel.get('otherDhe').errors?.maxlength">Preenchimento
                            incorreto</mat-error>
                    </div>
                </div>
                <div class="title-header">
                    <h1>Dados dos profissionais</h1>
                    <mat-error *ngIf="procedureModel.errors?.minProfessionals">Selecione pelo menos um
                        profissional</mat-error>
                </div>
                <div *ngFor="let item of professionals().controls; let i = index" class="row list-professionals"
                    formArrayName="listProfessionals">
                    <div class="row" [formGroupName]="i">
                        <div class="col-12 col-sm-6 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>32 - Profissional</mat-label>
                                <mat-select formControlName="professionalAgreement"
                                    (selectionChange)="selectProfessional($event,i)"
                                    [disabled]="!this.isEditingAllowed">
                                    <input class="inputSearchSelect" type="text"
                                        (keyup)="onKeyProfessional($event.target.value)" placeholder="Pesquisar"
                                        autocomplete="off" (keydown)="$event.stopPropagation()">
                                    <mat-option *ngFor="let item of listProfessionalAgreementStruct"
                                        [value]="item">{{item.nameProfessional}}
                                    </mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="professionals().controls[i].get('professionalAgreement').invalid">Preenchimento
                                    incorreto</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>30 - Grau de Participação</mat-label>
                                <mat-select formControlName="idParticipationDegree">
                                    <mat-option *ngFor="let item of participationDegreeMap | keyvalue"
                                        [value]="item.key">{{item.value.participationDegreeLookupCode}} -
                                        {{item.value.participationDegreeDescription}}
                                    </mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="professionals().controls[i].get('idParticipationDegree').invalid">Preenchimento
                                    incorreto</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>31 - Código na operadora/CPF</mat-label>
                                <input matInput type="text" formControlName="professionalProviderCodeOrCpf">
                                <mat-error
                                    *ngIf="professionals().controls[i].get('professionalProviderCodeOrCpf').invalid && !professionals().controls[i].get('professionalProviderCodeOrCpf').errors?.maxlength">Preenchimento
                                    incorreto</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>36 - Código CBO</mat-label>
                                <mat-select formControlName="idCbo">
                                    <mat-option *ngFor="let item of cboMap | keyvalue"
                                        [value]="item.key">{{item.value.cboCode}} -
                                        {{item.value.cboName}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="professionals().controls[i].get('idCbo').invalid">Preenchimento
                                    incorreto</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-5">
                            <mat-form-field appearance="outline">
                                <mat-label>33 - Conselho Profissional</mat-label>
                                <mat-select matInput type="text" formControlName="idCouncil">
                                    <mat-option *ngFor="let item of listProfessionalCouncil"
                                        [value]="item.idProfessionalCouncil">{{item.termCode}} - {{item.councilName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>34 - N° no Conselho</mat-label>
                                <input matInput type="text" formControlName="professionalCouncilCode">
                                <mat-error
                                    *ngIf="professionals().controls[i].get('professionalCouncilCode').invalid && !professionals().controls[i].get('professionalCouncilCode').errors?.maxlength">Preenchimento
                                    incorreto</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>35 - UF do Conselho</mat-label>
                                <mat-select matInput type="text" formControlName="idCouncilState">
                                    <mat-option *ngFor="let item of listState" [value]="item.idState">{{item.stateCode}}
                                        - {{item.stateInitials}} / {{item.stateName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div *ngIf="isEditingAllowed != null" class="col-2">
                            <button color="primary" mat-mini-fab type="button" (click)="addNext()"
                                [disabled]="!this.isEditingAllowed">
                                <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                            </button>
                            <button color="primary" mat-mini-fab type="button" (click)="removeProfessional(i)"
                                *ngIf="professionals().controls.length > 1" [disabled]="!this.isEditingAllowed">
                                <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
                            </button>
                        </div>
                        <div *ngIf="isEditingAllowed == null" class="col-2">
                            <button color="primary" mat-mini-fab type="button" (click)="addNext()">
                                <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                            </button>
                            <button color="primary" mat-mini-fab type="button" (click)="removeProfessional(i)"
                                *ngIf="professionals().controls.length > 1">
                                <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div mat-dialog-footer class="footer-modal">
                <button mat-flat-button color="accent" class="btn-block" (click)="close()" type="button">
                    <span *ngIf="!isLoading">Cancelar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                </button>
                <button *ngIf="isEditingAllowed != null" mat-flat-button type="submit" color="primary" class="btn-block"
                    [disabled]="!this.isEditingAllowed">
                    <span *ngIf="!isLoading">Salvar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                </button>
                <button *ngIf="isEditingAllowed == null" mat-flat-button type="submit" color="primary"
                    class="btn-block">
                    <span *ngIf="!isLoading">Salvar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                </button>
            </div>
        </form>
    </div>
</div>