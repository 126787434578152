import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AutomaticLoginService } from 'src/app/shared/services/API/user/automatic-login.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { AutomaticLoginStruct } from 'src/app/shared/services/structs/user/automatic-login.struct';

@Component({
  selector: 'app-desconect-device-modal',
  templateUrl: './desconect-device-modal.component.html',
  styleUrls: ['./desconect-device-modal.component.css']
})
export class DesconectDeviceModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    public dialog: MatDialog, 
    private alertService: AlertService, 
    public matDialogRef: MatDialogRef<DesconectDeviceModalComponent>,
    private automaticLoginService: AutomaticLoginService,
  ) { }

  public isLoading:boolean;  
  public automaticLoginStruct: AutomaticLoginStruct = new AutomaticLoginStruct();

  ngOnInit(): void {
    this.isLoading = false;
    this.automaticLoginStruct = this.data.automaticLoginStruct;
  }
  
  close(){
    this.matDialogRef.close({delete: false}); 
  }
  
  deleteAutomaticLogin() {
    this.isLoading = true;

    this.automaticLoginService.deleteAutomaticLoginByGuid(this.automaticLoginStruct.automaticLogin.guid).subscribe({
      next: response=>{
        if (response.isError) {
          this.alertService.show("Erro ao desconectar dispositivo! Tente novamente.", response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.alertService.show('Sucesso', "Dispositivo desconectado com sucesso", AlertType.success);
        this.matDialogRef.close({delete: true}); 
      },
      error:(error) => {
        this.alertService.show("Erro ao desconectar dispositivo! Tente novamente.", error, AlertType.error);
        this.isLoading = false;
      }
    });
  }
  
}
