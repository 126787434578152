import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-previous-state-modal',
  templateUrl: './previous-state-modal.component.html',
  styleUrls: ['./previous-state-modal.component.css']
})
export class PreviousStateModalComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, public matDialogRef: MatDialogRef<PreviousStateModalComponent>) { }

  close() {
    this.matDialogRef.close();
  }

  clickResponse(value: boolean) {
    this.matDialogRef.close({ response: value });
  }
}

