<div *ngIf="discriminatorTypeBoolean != discriminator.idDiscriminatorType" class="descricao descricao-com-info">
    <span matTooltip="{{discriminator.description}}" [matTooltipPosition]="'above'" matTooltipClass="tooltip-discriminator" class="texto">
        {{discriminator.discriminatorName}}
        <a><img src="assets/images/icode-radio-ask.svg" /></a>
    </span>
    <span (click)="abrirModal()" class="info">{{list[index].value}}</span>
</div>
<div *ngIf="discriminatorTypeBoolean == discriminator.idDiscriminatorType" class="descricao">
    <span matTooltip="{{discriminator.description}}" [matTooltipPosition]="'above'" class="texto">
        {{discriminator.discriminatorName}}
        <a><img src="assets/images/icode-radio-ask.svg" /></a>
    </span>
</div>