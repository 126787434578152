import { AfterViewInit, Component, ElementRef, Input ,OnDestroy,OnInit, Renderer2, ViewChild } from '@angular/core';
import { createLocalVideoTrack, LocalVideoTrack, VideoProcessor } from 'twilio-video';
import { TwilioUtilService } from '../../twilio-util.service';

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.css']
})
export class CameraComponent implements AfterViewInit, OnDestroy {
  @ViewChild('preview') previewElement: ElementRef;

    isInitializing: boolean = true;
    videoTrack: LocalVideoTrack = null;

    @Input() isFullScreen: boolean;

    constructor(
        private twilioUtilService: TwilioUtilService,
        private readonly renderer: Renderer2
    ) { }

    async ngAfterViewInit() {
        this.finalizePreview();
        if (this.previewElement && this.previewElement.nativeElement) {
            const selectedVideoInput = this.twilioUtilService.get('videoInputId');
            //await this.initializeDevice(selectedVideoInput);
        }
    }

    ngOnDestroy() {
        if(this.videoTrack)
            this.videoTrack.stop();
        this.finalizePreview();
    }

    async initializePreview(deviceId: string) {
        this.finalizePreview();
        await this.initializeDevice(deviceId);
    }

    finalizePreview() {
        try {
            if (this.videoTrack) {
                this.videoTrack.stop();
                this.videoTrack.detach().forEach(element => element.remove());
            }
            this.videoTrack = null;
        } catch (e) {
            console.error(e);
        }
    }

  private async initializeDevice(deviceId?: string) {
        try {
            this.isInitializing = true;

            this.finalizePreview();

            this.videoTrack = deviceId ? await createLocalVideoTrack({ deviceId }) : await createLocalVideoTrack();

            const videoElement = this.videoTrack.attach();
            videoElement.style.transform = 'scale(-1,1)';
            this.renderer.setStyle(videoElement, 'height', '100%');
            this.renderer.setStyle(videoElement, 'width', '95%');
            this.renderer.setStyle(videoElement, 'margin-top', '2%');
            this.renderer.setStyle(videoElement, 'border-radius', '10px');
            this.renderer.appendChild(this.previewElement.nativeElement, videoElement);
        } finally {
            this.isInitializing = false;
        }
    }
}
