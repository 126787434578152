import { CdkAccordionItem } from '@angular/cdk/accordion';
import { formatDate } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DiscriminatorTypeEnum } from '../../enum/discriminator-type.enum';
import { AlertService, AlertType } from '../../services/alert.service';
import { HumanizedHeaderService } from '../../services/API/orchestrator-patient/humanized-header.service';
import { PriorityService } from '../../services/API/totem/priority.service';
import { PriorityModel } from '../../services/models/totem/priority.model';
import { HumanizedHeaderResponse } from '../../services/responses/orchestrator-patient/humanized-header.response';
import { VitalHistoryStruct } from '../../services/structs/orchestrator-patient/vital-history.struct';
import { UtilService } from '../../services/util.service';
import { UtilsSecundaryHeaderService } from './utils.service';
import { VitalHistoryModalComponent } from './vital-history-modal/vital-history-modal.component';
import { CareLinePriorityStatusEnum } from '../../enum/care-line-priority/care-line-priority-status.enum';
import { UtilsClassificationService } from 'src/app/classification/utils.service';
import { PrioritiesStatusEnum } from '../../enum/priorities-status.enum';
import { PatientDetailsModalComponent } from './patient-details-modal/patient-details-modal.component';
import { MatTooltip } from '@angular/material/tooltip';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';

@Component({
  selector: 'app-secundary-header',
  templateUrl: './secundary-header.component.html',
  styleUrls: ['./secundary-header.component.css']
})
export class SecundaryHeaderComponent implements OnInit, OnChanges, OnDestroy {

  @Input() episode: number;
  @Input() discriminators: any[] = [];
  @Input() hasCachedModule: boolean = false;
  @Input() cachedModuleName: string = '';
  @Input() painColorCode: string[] = [];
  @Input() gravityColorCode: string = '';
  @Input() pain: string = '';
  @Input() gravity: string = '';
  @Input() isClassification: boolean = false;
  @Output() restartMeasurers: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('accordionItem', { static: true }) accordionItemChild: CdkAccordionItem;
  @ViewChild('tooltipLink') tooltipLink: ElementRef;
  @ViewChild('tooltop') tooltop: MatTooltip;

  episodeData: HumanizedHeaderResponse = new HumanizedHeaderResponse();
  listPriority: PriorityModel[];
  selectedPriorities: PriorityModel[] = [];

  public saturationHistory: VitalHistoryStruct[];
  public heartRateHistory: VitalHistoryStruct[];
  public respiratoryFrequencyHistory: VitalHistoryStruct[];
  public temperatureHistory: VitalHistoryStruct[];
  public glucoseHistory: VitalHistoryStruct[];
  public bloodPressureHistory: VitalHistoryStruct[];
  public painHistory: VitalHistoryStruct[];
  public glasgowHistory: VitalHistoryStruct[];
  public careLinePriority: string;
  public idCareLinePriorityStatus: number;
  public isSuspicionEvaluated: boolean;
  public careLinePrioritySuspicion: number = CareLinePriorityStatusEnum.suspeita;
  public autismEnum: number = PrioritiesStatusEnum.AutistaTEA;
  public overSixtyYearsEnum: number = PrioritiesStatusEnum.MaiorDe60Anos;
  public overEightyYearsEnum: number = PrioritiesStatusEnum.MaiorDe80Anos;

  isLoading = false;
  formatedRegress: string;
  formatedBirth: string;
  public discriminatorTypeEnum: any = DiscriminatorTypeEnum;
  public tootltipTimeout;
  constructor(
    private utilService: UtilsClassificationService,
    private humanizedHeaderService: HumanizedHeaderService,
    private priorityService: PriorityService,
    private alertService: AlertService,
    private utilsService: UtilService,
    private utilsSecundaryHeaderService: UtilsSecundaryHeaderService,
    private eRef: ElementRef,
    public dialog: MatDialog
  ) { }


  ngOnDestroy(): void {
    if (this.isClassification) {
      this.emitMeasurerRestart;
    }
    clearTimeout(this.tootltipTimeout);
  }

  ngOnInit(): void {
    this.saturationHistory = [];
    this.heartRateHistory = [];
    this.respiratoryFrequencyHistory = [];
    this.temperatureHistory = [];
    this.glucoseHistory = [];
    this.bloodPressureHistory = [];
    this.painHistory = [];
    this.glasgowHistory = [];
    this.episodeData.patientCareLinePriorityStruct = null;
    this.getAllPriorities();
    this.getCachedModule();
    this.careLinePriority = "";

    let data = this.utilService.getClassificationData();
    // this.matTooltip.show();
  }

  getCachedModule(changes?: SimpleChanges): void {
    let hasLoading: boolean = true;
    if (this.hasCachedModule && this.cachedModuleName) {
      const data: any = this.utilsService.getSecundaryHeaderModuleConfig(this.cachedModuleName);
      if (data) {
        if (this.isClassification)
          this.cleanClassificationValues();

        this.selectedPriorities = data.selectedPriorities;
        this.formatedBirth = data.formatedBirth;
        this.formatedRegress = data.formatedRegress;

        hasLoading = false;
      }
    }

    if (this.episode) {
      let episodeData = this.utilsSecundaryHeaderService.getSessionEpisodeData();
      if (episodeData == null || this.episode != episodeData.idEpisode)
        this.getEpisodeData(this.episode, hasLoading);
      else
        this.populateNewData(episodeData, changes);
    }
  }

  populateNewData(episodeData: HumanizedHeaderResponse, changes: SimpleChanges) {
    if (changes && changes?.discriminators && changes?.discriminators?.currentValue) {
      episodeData.bloodPressure = episodeData.bloodPressure == changes?.discriminators?.currentValue[DiscriminatorTypeEnum.PressaoArterial] ? episodeData.bloodPressure : changes.discriminators.currentValue[DiscriminatorTypeEnum.PressaoArterial]
      episodeData.pain = episodeData.pain == changes?.discriminators?.currentValue[DiscriminatorTypeEnum.Dor] ? episodeData.pain : changes?.discriminators?.currentValue[DiscriminatorTypeEnum.Dor]
      episodeData.painColorCode = episodeData.painColorCode == changes.painColorCode.currentValue ? episodeData.painColorCode : changes.painColorCode.currentValue
      episodeData.glucose = episodeData.glucose == changes?.discriminators?.currentValue[DiscriminatorTypeEnum.Glicemia] ? episodeData.glucose : changes?.discriminators?.currentValue[DiscriminatorTypeEnum.Glicemia]
      episodeData.temperature = episodeData.temperature == changes?.discriminators?.currentValue[DiscriminatorTypeEnum.Temperatura] ? episodeData.temperature : changes?.discriminators?.currentValue[DiscriminatorTypeEnum.Temperatura]
      episodeData.glasgow = episodeData.glasgow == changes?.discriminators?.currentValue[DiscriminatorTypeEnum.Glasgow] ? episodeData.glasgow : changes?.discriminators?.currentValue[DiscriminatorTypeEnum.Glasgow]
      episodeData.heartRate = episodeData.heartRate == changes?.discriminators?.currentValue[DiscriminatorTypeEnum.FrequenciaCardiaca] ? episodeData.heartRate : changes?.discriminators?.currentValue[DiscriminatorTypeEnum.FrequenciaCardiaca]
      episodeData.respiratoryFrequency = episodeData.respiratoryFrequency == changes?.discriminators?.currentValue[DiscriminatorTypeEnum.FrequenciaRespiratoria] ? episodeData.respiratoryFrequency : changes?.discriminators?.currentValue[DiscriminatorTypeEnum.FrequenciaRespiratoria]
      episodeData.saturation = episodeData.saturation == changes?.discriminators?.currentValue[DiscriminatorTypeEnum.Saturacao] ? episodeData.saturation : changes.discriminators.currentValue[DiscriminatorTypeEnum.Saturacao]
      episodeData.gravity = episodeData.gravity == changes.gravity.currentValue ? episodeData.gravity : changes.gravity.currentValue;
      episodeData.gravityColorCode = episodeData.gravityColorCode == changes.gravityColorCode.currentValue ? episodeData.gravityColorCode : changes.gravityColorCode.currentValue;
      this.utilsSecundaryHeaderService.createSessionEpisodeData(episodeData);
    }

    this.episodeData = episodeData;
    this.populateSuspicion();
    this.setFormatedInfo();
    this.setCachedModule();
  }

  rePopulate() {
    let episodeData = this.utilsSecundaryHeaderService.getSessionEpisodeData();
    this.episodeData = episodeData;
    this.populateSuspicion();
  }

  reloadHeader() {
    if (this.episode)
      this.getEpisodeData(this.episode)
    return;
  }

  cleanClassificationValues() {
    this.episodeData.gravity = null;
    this.episodeData.gravityColorCode = null;
    this.episodeData.pain = null;
    this.episodeData.painColorCode = null;
    this.episodeData.priority = null;
  }

  setCachedModule(): void {
    if (this.hasCachedModule && this.cachedModuleName) {
      const cachedValues = {
        episodeData: this.episodeData,
        selectedPriorities: this.selectedPriorities,
        formatedBirth: this.formatedBirth,
        formatedRegress: this.formatedRegress
      }
      this.utilsService.setSecundaryHeaderModuleConfig(cachedValues, this.cachedModuleName);
    }
  }

  setFormatedInfo() {
    this.formatedRegress = formatDate(this.episodeData.regress, 'dd/MM/YYYY', 'en-US');
    this.formatedBirth = formatDate(this.episodeData.birthDate, 'dd/MM/YYYY', 'en-US');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.episode) {
      if (!changes.episode.currentValue)
        return;
      const data: any = this.utilsService.getSecundaryHeaderModuleConfig(this.cachedModuleName);
      if (data && data.episodeData.idEpisode === changes.episode.currentValue) {
        this.getCachedModule(changes);
        return;
      }
      else {
        this.getEpisodeData(changes.episode.currentValue);
        return;
      }
    }
  }

  getEpisodeData(episode: number, hasLoading: boolean = true): void {
    this.isLoading = hasLoading;

    this.humanizedHeaderService.getEpisode(episode).subscribe({
      next: (response: HumanizedHeaderResponse) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.utilsSecundaryHeaderService.createSessionEpisodeData(response);
        this.episodeData = this.utilsSecundaryHeaderService.getSessionEpisodeData();
        this.episodeData = response;
        this.populateSuspicion();
        this.saturationHistory = response.listSaturation;
        this.heartRateHistory = response.listHeartRate;
        this.respiratoryFrequencyHistory = response.listRespiratoryFrequency;
        this.temperatureHistory = response.listTemperature;
        this.glucoseHistory = response.listGlucose;
        this.bloodPressureHistory = response.listBloodPressure;
        this.painHistory = response.listPain;
        this.glasgowHistory = response.listGlasgow;
        if (this.episodeData.patientCareLinePriorityStruct.careLinePriorityName != null && this.episodeData.patientCareLinePriorityStruct.careLinePriorityStatusName != null)
          this.episodeData.patientCareLinePriorityStruct.careLinePriorityName = this.episodeData.patientCareLinePriorityStruct.careLinePriorityName + " - "

        if (this.episodeData.patientCareLinePriorityStruct.careLinePriorityName == null)
          this.episodeData.patientCareLinePriorityStruct.careLinePriorityName = "-";

        if (this.episodeData.patientCareLinePriorityStruct.careLinePriorityStatusName == null)
          this.episodeData.patientCareLinePriorityStruct.careLinePriorityStatusName = "";

        //this.careLinePriority = this.episodeData.patientCareLinePriorityStruct.careLinePriorityName + " (" + this.episodeData.patientCareLinePriorityStruct.careLinePriorityStatusName + ")";
        this.idCareLinePriorityStatus = this.episodeData.idCareLinePriorityStatus;

        if (response.priority)
          this.setPriorityList(response.priority);
        else
          this.selectedPriorities = [];

        if (this.isClassification)
          this.cleanClassificationValues();

        this.setFormatedInfo();
        this.setCachedModule();

        if (response.isUnidentifiedPatient) {
          this.toggleTooltip();
        }

        this.isLoading = false;
      },
      error: (error) => {
        console.error(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  openAllergies() {
    let description = `Paciente relata alergia:\n${this.episodeData.listAllergyStruct
      .map(x => `• ${x.allergyName}`)
      .join('\n')}`;
  
    const dialogRef = this.dialog.open(AlertModalComponent, {
      data: {
        isTwoButtonsModal: false,
        title: 'Atenção!',
        description: description,
        hasImage: true
      },
    });
  }

  getAllPriorities(): void {
    this.priorityService.listPriority().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listPriority = response.listPriority;
      },
      error: (error) => {
        console.error(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  setPriorityList(prioritiesEpisode: Array<number>): void {
    let filteredPriorities = new Array<PriorityModel>();
    prioritiesEpisode.forEach((priorityEpisode: number) => {
      filteredPriorities.push(
        this.listPriority.find((priority: PriorityModel) => priority.idPriority === priorityEpisode)
      );
    });

    if (JSON.stringify(this.selectedPriorities) !== JSON.stringify(filteredPriorities))
      this.selectedPriorities = filteredPriorities;
  }

  clickVital(field: string) {
    let list: VitalHistoryStruct[] = [];

    if (field == 's') {
      if (!this.saturationHistory || this.saturationHistory.length == 0)
        return;
      list = this.saturationHistory;
    }
    else if (field == 'g') {
      if (!this.glucoseHistory || this.glucoseHistory.length == 0)
        return;
      list = this.glucoseHistory;
    }
    else if (field == 'b') {
      if (!this.bloodPressureHistory || this.bloodPressureHistory.length == 0)
        return;
      list = this.bloodPressureHistory;
    }
    else if (field == 't') {
      if (!this.temperatureHistory || this.temperatureHistory.length == 0)
        return;
      list = this.temperatureHistory;
    }
    else if (field == 'h') {
      if (!this.heartRateHistory || this.heartRateHistory.length == 0)
        return;
      list = this.heartRateHistory;
    }
    else if (field == 'f') {
      if (!this.respiratoryFrequencyHistory || this.respiratoryFrequencyHistory.length == 0)
        return;
      list = this.respiratoryFrequencyHistory;
    }
    else if (field == 'p') {
      if (!this.painHistory || this.painHistory.length == 0)
        return;
      list = this.painHistory;
    }
    else if (field == 'gla') {
      if (!this.glasgowHistory || this.glasgowHistory.length == 0)
        return;
      list = this.glasgowHistory;
    }

    const dialogRef = this.dialog.open(VitalHistoryModalComponent, {
      data: {
        listHistory: list,
        type: field
      },
    });
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target))
      this.accordionItemChild.close();
  }

  populateSuspicion() {
    this.isSuspicionEvaluated = this.episodeData.isSuspicionEvaluated;
    if (this.isSuspicionEvaluated == true)
      this.episodeData.suspicionStatusName = "Confirmada";
    if (this.isSuspicionEvaluated == false)
      this.episodeData.suspicionStatusName = "Negada";
  }

  emitMeasurerRestart() {
    this.restartMeasurers.emit();
  }

  openModalPatientDetails() {
    if (this.isLoading) return;
    this.dialog.open(PatientDetailsModalComponent, {
      data: {
        idEpisode: this.episodeData.idEpisode,
      },
    });
  }

  toggleTooltip() {
    clearTimeout(this.tootltipTimeout);
    if (this.tooltipLink) {
      this.tooltipLink.nativeElement.click()
    }

    let thisParam = this;
    this.tootltipTimeout = setTimeout(() => {
      thisParam.tooltipLink.nativeElement.click()
      clearTimeout(thisParam.tootltipTimeout);
    }, 3000)
  }
}