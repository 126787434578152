import { FormGroup, FormControl, ValidatorFn, ValidationErrors, AbstractControl } from "@angular/forms";

// To validate password and confirm password
export function VerifyPassword(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    let isValid: boolean = true;
    const regexNumber = /[0-9]/;
    const regexSpecialCharacter = /[^A-Za-z0-9]/;

    if (!value) {
      return null;
    }

    if (control.value.length < 8 || control.value.length > 100) {
      isValid = false;
    }
    else if (control.value.toUpperCase() == control.value) {
      isValid = false;
    }
    else if (control.value.toLowerCase() == control.value) {
      isValid = false;
    }
    else if (!regexNumber.test(control.value)) {
      isValid = false;
    }
    else if (!regexSpecialCharacter.test(control.value)) {
      isValid = false;
    }
    else {
      control.setErrors(null);
    }

    return !isValid ? {invalidPassword: true} : null;
  };
}

export function VerifyOldPassword(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    let isValid: boolean = true;

    if (!value) {
      return null;
    }

    if(control.parent.value['oldPassword'] == control.value){
      isValid = false;
    }
    else {
      control.setErrors(null);
    }

    return !isValid ? {invalidPassword: {error: true, message: "A nova senha não pode ser igual a temporária."}} : null;
  };
}