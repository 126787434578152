export enum LookupAdmissionRequiredFieldEnum {
    NGuiaNaOperadora = 1,
    SenhaDeAutorizacao = 2,
    ValidadeDaSenha = 3,
    ValidadeDaCarteira = 4,
    DiagnosticoPrincipal = 5,
    DiagnosticoSecundario = 6,
    TerceiroDiagnostico = 7,
    QuartoDiagnostico = 8,
    ObservacaoJustificativa = 9
}