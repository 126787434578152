import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { catchError, retry, timeout } from 'rxjs/operators';
import { CalcAgeResponse } from '../../responses/medical-record/calc-age.response';


@Injectable({
  providedIn: 'root'
})
export class CalcAgeService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient) {
    super();
  }

  public getAge(dateString): Observable<CalcAgeResponse> {
    let dob;
    if (dateString instanceof Date)
      dob = dateString;
    else {
      let date = this.toISOFormat(dateString);
      dob = new Date(date);
    }
    let uri = `CalcAge`
    let body = {
      birthDate: dob
    }

    return this.httpClient.post<CalcAgeResponse>(environment.urlApiMedicalRecord + uri, body, this.addHeaderToken())
      .pipe(
        timeout(environment.requestTimeOut),
        retry(environment.requestRetry),
        catchError(this.handleError)
      )
  }

  toISOFormat(dateTimeString: string) {
    let newDate = dateTimeString.replaceAll('/' || '-', '');
    let DD = newDate.substring(0, 2);
    let MM = newDate.substring(2, 4);
    let YYYY = newDate.substring(4, 8);

    return `${YYYY}-${MM}-${DD}`;
  }
}