import { FeesProcedureModel } from "../../models/private-billing/fees-procedure.model";
import { FeesProcedureProfessionalStruct } from "./fees-procedure-professional.struct";

export class FeesProcedureStruct extends FeesProcedureModel {
    public idProcedure: number;
    public procedureValue: number;
    public idTerminology: number;
    public terminologyCode: string;
    public terminologyName: string;
    public idTerminologyGroup: number;
    public referenceTable: string;
    public listProfessional: FeesProcedureProfessionalStruct[] = [];
}