import { Directive, ElementRef, HostListener } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { debounceTime, fromEvent, take } from 'rxjs';

@Directive({
  selector: '[appFormscroll]'
})
export class FormscrollDirective {

  constructor(private el: ElementRef,
    private formGroupDir: FormGroupDirective) { }

  @HostListener("ngSubmit") onSubmit() {
    if (this.formGroupDir.control.invalid) {
      this.scrollToFirstInvalidControl();
    }
  }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector('[class*="ng-invalid"]:not([ng-reflect-form]');

    window.scroll({
      top: this.getTopOffset(firstInvalidControl),
      left: 0,
      behavior: 'smooth'
    });

    let input = firstInvalidControl.getElementsByTagName('input').item(0);

    if (input) {
      input.focus();
    }
  }

  private getTopOffset(controlEl: HTMLElement): number {
    if (!controlEl)
      return;

    const labelOffset = 90;
    let offSet = controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
    return offSet;
  }

}
