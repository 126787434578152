import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Access } from 'src/app/shared/services/models/private-billing/access.model';
import { Technique } from 'src/app/shared/services/models/private-billing/technique.model';
import { TerminologyGroup } from 'src/app/shared/services/models/private-billing/terminology-group.model';
import { ParticipationDegree } from 'src/app/shared/services/models/private-billing/participation-degree.model';
import { HealthcareAgreementStruct } from 'src/app/shared/services/structs/private-billing/healthcare-agreement.struct';
import { ProfessionalAgreementStruct } from 'src/app/shared/services/structs/private-billing/professional-agreement.struct';
import { Cbo } from 'src/app/shared/services/models/private-billing/cbo.model';
import { TissBilling } from 'src/app/shared/services/models/private-billing/tiss-billing.model';
import { SpSadtExecutedProcedureFormModalComponent } from '../sp-sadt-executed-procedure-form-modal/sp-sadt-executed-procedure-form-modal.component';
import { SpSadtExecutedProcedureStruct } from 'src/app/shared/services/structs/private-billing/sp-sadt-executed-procedure.struct';
import { State } from 'src/app/shared/services/models/private-billing/state.model';
import { ProfessionalCouncil } from 'src/app/shared/services/models/private-billing/professional-council.model';

@Component({
  selector: 'app-sp-sadt-executed-procedure-table',
  templateUrl: './sp-sadt-executed-procedure-table.component.html',
  styleUrls: ['./sp-sadt-executed-procedure-table.component.css']
})
export class SpSadtExecutedProcedureTableComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
  ) { }

  @Input() listSpSadtExecutedProcedure: SpSadtExecutedProcedureStruct[] = [];
  @Input() idSpSadtGuide: number = null;
  @Input() idPatient: number = null;
  @Input() accessMap: Map<number, Access>;
  @Input() techniqueMap: Map<number, Technique>;
  @Input() participationDegreeMap: Map<number, ParticipationDegree>;
  @Input() tussTerminologyGroupMap: Map<number, TerminologyGroup>;
  @Input() cboMap: Map<number, Cbo>;
  @Input() healthcareAgreementStruct: HealthcareAgreementStruct;
  @Input() listProfessionalAgreementStruct: ProfessionalAgreementStruct[];
  @Input() tissBilling: TissBilling;
  @Input() isEditingAllowed: boolean = null;
  @Input() idAttendanceAspect: number;
  @Input() listState: State[] = [];
  @Input() listProfessionalCouncil: ProfessionalCouncil[] = [];
  @Output() changeSpSadtExecutedProcedure = new EventEmitter<any>();

  public isLoading: boolean = false;

  ngOnInit(): void {
  }

  ngOnDestroy() {
  }

  changeListProcedure() {
    this.changeSpSadtExecutedProcedure.emit({ listSpSadtExecutedProcedure: this.listSpSadtExecutedProcedure });
  }

  openProcedureModal(spSadtExecutedProcedure: SpSadtExecutedProcedureStruct, index: number) {
    const dialogRef = this.dialog.open(SpSadtExecutedProcedureFormModalComponent, {
      data: {
        accessMap: this.accessMap,
        idSpSadtGuide: this.idSpSadtGuide,
        techniqueMap: this.techniqueMap,
        participationDegreeMap: this.participationDegreeMap,
        tussTerminologyGroupMap: this.tussTerminologyGroupMap,
        cboMap: this.cboMap,
        healthcareAgreement: this.healthcareAgreementStruct,
        listProfessionalAgreementStruct: this.listProfessionalAgreementStruct,
        tissBiling: this.tissBilling,
        spSadtExecutedProcedure: spSadtExecutedProcedure,
        idAttendanceAspect: this.idAttendanceAspect,
        listState: this.listState,
        listProfessionalCouncil: this.listProfessionalCouncil,
        isEditingAllowed: this.isEditingAllowed,
        idPatient: this.idPatient
      },
      panelClass: 'sp-sadt-executed-procedure-form-modal',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.spSadtExecutedProcedureStruct) {

        if (index >= 0)
          this.listSpSadtExecutedProcedure.splice(index, 1);

        this.listSpSadtExecutedProcedure.push(result.spSadtExecutedProcedureStruct);
        this.changeListProcedure();
      }
    });
  }

  deleteItem(spSadtExecutedProcedure: SpSadtExecutedProcedureStruct) {
    const index = this.listSpSadtExecutedProcedure.indexOf(spSadtExecutedProcedure);
    if (index !== -1) {
      this.listSpSadtExecutedProcedure.splice(index, 1);
      this.changeListProcedure();
    }
    else
      console.error("Objeto não encontrado na lista:", spSadtExecutedProcedure);
  }

  reset() {
    this.listSpSadtExecutedProcedure = [];
  }
}