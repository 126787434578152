<form class="form" [formGroup]="model">
    <div class="row">
        <div class="col-12 col-sm-5 col-md-4 col-lg-3">
        </div>
        <div class="col-12 col-sm-2 col-md-4 col-lg-6">
        </div>
        <!-- <div class="col-12 col-sm-5 col-md-4 col-lg-3">
            <button mat-flat-button type="button" (click)="previous()" color="accent" class=" btn-block">
                <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Voltar
            </button>
        </div> -->
    </div>
    <div class="row">
        <div class="col-md-6 col-separador">
            <div *ngFor="let item of firstColumn; let i = index;" class="row-escolha-triagem">
                <span *ngIf="item.nameAndColor" [ngStyle]="{'background-color': item.nameAndColor.coloerHex}"
                    class="nivel-urgencia">{{item.nameAndColor.priorityColorName}}</span>
                <app-discriminator (discriminatorValues)="setDiscriminatorsValues($event)"
                    [openModal]="item.openModalValidate" [list]="list" [discriminator]="item.column"
                    [forceOpenModal]="item.forceOpenModal" [discriminatorsValues]="discriminators">
                </app-discriminator>
                <input (click)="setValue(item.column, true)" [value]="true" class="radio-sim" type="radio"
                    id="radio-{{item.column.idDiscriminator}}-sim"
                    formControlName="radio-{{item.column.idDiscriminator}}"
                    name="radio-{{item.column.idDiscriminator}}">
                <label (click)="forceToOpenModal(item.column)" class="label-radio-sim"
                    for="radio-{{item.column.idDiscriminator}}-sim"><img
                        src="assets/images/icone-radio-sim.svg" /></label>
                <input (click)="setValue(item.column, false)" [value]="false" class="radio-nao" type="radio"
                    id="radio-{{item.column.idDiscriminator}}-nao"
                    formControlName="radio-{{item.column.idDiscriminator}}"
                    name="radio-{{item.column.idDiscriminator}}">
                <label (click)="forceToOpenModal(item.column)" class="label-radio-nao"
                    for="radio-{{item.column.idDiscriminator}}-nao"><img
                        src="assets/images/icone-radio-nao.svg" /></label>
            </div>
        </div>
        <div class="col-md-6">
            <div *ngFor="let item of secondColumn; let i = index;" class="row-escolha-triagem">
                <span *ngIf="item.nameAndColor" [ngStyle]="{'background-color': item.nameAndColor.coloerHex}"
                    class="nivel-urgencia">{{item.nameAndColor.priorityColorName}}</span>
                <app-discriminator (discriminatorValues)="setDiscriminatorsValues($event)"
                    [openModal]="item.openModalValidate" [list]="list" [discriminator]="item.column"
                    [forceOpenModal]="item.forceOpenModal" [discriminatorsValues]="discriminators">
                </app-discriminator>

                <input (click)="setValue(item.column, true)" [value]="true" class="radio-sim" type="radio"
                    id="radio-{{item.column.idDiscriminator}}-sim"
                    formControlName="radio-{{item.column.idDiscriminator}}"
                    name="radio-{{item.column.idDiscriminator}}">
                <label (click)="forceToOpenModal(item.column)" class="label-radio-sim"
                    for="radio-{{item.column.idDiscriminator}}-sim"><img
                        src="assets/images/icone-radio-sim.svg" /></label>

                <input (click)="setValue(item.column, false)" [value]="false" class="radio-nao" type="radio"
                    id="radio-{{item.column.idDiscriminator}}-nao"
                    formControlName="radio-{{item.column.idDiscriminator}}"
                    name="radio-{{item.column.idDiscriminator}}">
                <label (click)="forceToOpenModal(item.column)" class="label-radio-nao"
                    for="radio-{{item.column.idDiscriminator}}-nao"><img
                        src="assets/images/icone-radio-nao.svg" /></label>
            </div>
        </div>
    </div>
</form>