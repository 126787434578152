import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ProfileService } from 'src/app/shared/services/API/user/profile.service';
import { UserloginService } from 'src/app/shared/services/API/user/user-login.service';
import { HealthUnitStruct } from 'src/app/shared/services/structs/user/health-unit.struct';

@Component({
  selector: 'app-verify-auth',
  templateUrl: './verify-auth.component.html',
  styleUrls: ['./verify-auth.component.css']
})
export class VerifyAuthComponent implements OnInit {

  @Input() listHealthUnit: HealthUnitStruct[];
  @Input() isLoading: boolean;
  @Input() formGroup: UntypedFormGroup;
  @Input() login: string;
  @Input() canStayConnected: boolean;

  @Output() goBack = new EventEmitter();
  @Output() setCanStayConnected = new EventEmitter();
  @Output() forgotPasswordAuth = new EventEmitter();
  @ViewChild('password') passwordElement: ElementRef;

  constructor(
    private userloginService: UserloginService,
    private router: Router,
  ) { }

  public userName: string;

  ngOnInit(): void {
    
  }

  selectLogin(){
    this.goBack.emit();
  }

  onIdHealthUnitChange(){
    if(this.listHealthUnit.length < 1 || this.formGroup.get('idHealthUnit').valid) {
      this.checkLogin();
    }
    setTimeout(()=> {
    if (this.passwordElement) {
      this.passwordElement.nativeElement.focus();
    }
   }), 200
  }

  ngOnChanges(changes: SimpleChanges): void{
    if(this.listHealthUnit.length < 1 || this.formGroup.get('idHealthUnit').valid || changes.formGroup.currentValue){
      this.onIdHealthUnitChange();
    }
    
  }

  forgotPassword(){
    this.forgotPasswordAuth.emit();
  }

  checkLogin() {

    this.isLoading = true;

    this.userloginService.getProfileByHealthUnitAndUser(this.formGroup.get('idHealthUnit').value, this.login).subscribe({
      next:(response) => {
        if (response.isError) {
          this.isLoading = false;
          return;
        }

        if(response.canStayConnected !== undefined){
          this.canStayConnected = response.canStayConnected;
          this.userName = response.userName;
          this.setCanStayConnected.emit(this.canStayConnected);
        }

        this.isLoading = false;
      },
      error: error =>{
        console.log(error);
        this.isLoading = false;
      }
    });
  }
}
