<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="body-confirm-modal-list">     
            <div class="row">   
                <div class="col-md-12"> 
                    <h3> Você não tem permissão para evadir pacientes! Solicite liberação ao usuário master da Unidade.</h3>
                </div>
            </div>            
        </div>
        <div class="footer-confirm-modal-list">
            <button mat-flat-button color="primary" class="btn-block" (click)="clickOk()">
                Entendido
            </button>        
            
        </div>
    </div>
</div>