import { Socket } from 'socket.io-client';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WebsocketPrivateBillingService } from '../websocket-private-billing.service';
import { AlertModalComponent } from '../../components/alert-modal/alert-modal.component';
import { OccupiedRemoveModalComponent } from '../../components/occupied-remove-modal/occupied-remove-modal.component';
import { ConfirmModalComponent } from '../../components/confirm-modal/confirm-modal.component';

@Injectable({ providedIn: 'root' })

export class WebsocketPrivateBillingUtilService {
    constructor(private websocketPrivateBillingService: WebsocketPrivateBillingService,
        public dialog: MatDialog,
    ) { }

    socket: Socket;
    idEpisode: number = null;
    guideType: number = null;
    login: string = null;
    userName: string = null;
    onClientPrivateBilling: Function;
    thisParam: any;
    onSomeoneIsConnect: Function;
    isOccupied: boolean = false;

    getIdRoom(): number {
        return this.idEpisode;
    }

    returnSocket() {
        return this.socket;
    }

    getIsOccupied() {
        return this.isOccupied;
    }

    isConnect(): boolean {
        return this.socket && this.socket.connected;
    }

    clearSocket(): void {
        if (this.socket && this.socket.connected)
            this.socket.disconnect();

        this.socket = null;
        this.isOccupied = false;

        this.clearVariables();
    }

    setFunctions(onSomeoneIsConnect: Function) {
        this.onSomeoneIsConnect = onSomeoneIsConnect ? onSomeoneIsConnect : this.blankFunction;
    }

    blankFunction() { }

    connectWebsocketGuide(thisParam: any, idEpisode: number, guideType: number, login: string, userName: string): void {
        this.login = login;
        this.idEpisode = idEpisode;
        this.userName = userName;
        this.guideType = guideType;

        this.socket = this.websocketPrivateBillingService.websocketConnection();
        this.socket.emit('join', idEpisode, guideType)
            .on('clientPrivateBilling', (res) => {
                this.onClientPrivateBilling(res, thisParam)
            })
            .on("someoneIsConnecting", (res) => {
                if (res.login != this.login && this.idEpisode === res.idEpisode)
                    this.socket.emit('someoneIsConnect', { idEpisode: this.idEpisode, guideType: this.guideType, login: this.login, userName: this.userName })
            })
            .on("someoneIsConnect", (res) => {
                if (res.login != login && this.idEpisode === res.idEpisode && this.guideType === res.guideType) {
                    const dialogRef = this.dialog.open(AlertModalComponent, {
                        data: {
                            isTwoButtonsModal: true,
                            title: 'Alerta!',
                            description: `Não é possível acessar este item porque ${res.userName} está editando a guia no momento.`,
                            hasImage: true,
                            textButtonConfirm: "Editar e remover usuário",
                            textButtonCancel: "Cancelar",
                        },
                    });
                    dialogRef.afterClosed().subscribe(result => {
                        this.isOccupied = false;
                        if (result && result.confirm) {
                            let reportDialog = this.dialog.open(ConfirmModalComponent, {
                                data: {
                                    title: "Aviso!",
                                    message: `Você realmente deseja editar? Qualquer alteração realizada pelo usuário(a) ${this.userName} será perdida.`,
                                    btnPrimary: "Sim, editar",
                                    btnAccent: "Não, cancelar"
                                },
                            });
                            reportDialog.afterClosed().subscribe(result => {
                                if (result && result.clickPrimary)
                                    this.socket.emit('removeUser', { idEpisode: this.idEpisode, guideType: this.guideType, login: this.login, userName: this.userName })
                                else
                                    this.onSomeoneIsConnect.apply(thisParam);
                            });
                        }
                        else
                            this.onSomeoneIsConnect.apply(thisParam);
                    });
                }
            })
            .on("removeUser", (res) => {
                if (res.login != login && this.idEpisode === res.idEpisode && this.guideType === res.guideType) {
                    this.socket.disconnect();
                    let ref = this.dialog.open(OccupiedRemoveModalComponent, {
                        data: {
                            ...res,
                            buttonConfirm: "Fechar",
                            description: "",
                            title: "agora está está editando esta guia e as alterações feitas por você não serão salvas."
                        }
                    });
                    ref.afterClosed().subscribe(() => {
                        this.onSomeoneIsConnect.apply(thisParam);
                    });
                }
            })
            .io.on("reconnect", (res) => {
                this.socket.emit('join', idEpisode);
            });
    }

    setVariables(idEpisode: number, guideType: number, login: string, userName: string) {
        this.login = login;
        this.idEpisode = idEpisode;
        this.userName = userName;
        this.guideType = guideType;

        this.socket.emit('someoneIsConnecting', { idEpisode, guideType, login, userName });
    }

    clearVariables() {
        this.idEpisode = null;
        this.login = null;
        this.userName = null;
        this.guideType = null;
    }
}