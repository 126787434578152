import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MedicalPrescriptionCheckService } from 'src/app/shared/services/API/medical-record/medical-prescription-check.service';
import { UserGetNameService } from 'src/app/shared/services/API/user/user-get-name.service';
import { MedicalPrescriptionStruct } from 'src/app/shared/services/structs/medical-record/medical-prescription.struct';
import { MedicalPrescriptionCheckStruct } from 'src/app/shared/services/structs/orchestrator-patient/medical-prescription-check.struct';
import { ListUserStruct } from 'src/app/shared/services/structs/user/list-user.struct';

@Component({
  selector: 'app-medical-prescription-modal',
  templateUrl: './medical-prescription-modal.component.html',
  styleUrls: ['./medical-prescription-modal.component.css']
})
export class MedicalPrescriptionModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<MedicalPrescriptionModalComponent>,
    private formBuilder: UntypedFormBuilder,
    public userGetNameService: UserGetNameService,
  ) { }

  public model: UntypedFormGroup;
  public isLoading: boolean;
  public showLocal: boolean;
  public showComplaints: boolean;
  public checkedMedicine: boolean;
  public checkedPatient: boolean;
  public observationSelect: string;
  public isReadonly: boolean;
  public user: ListUserStruct;

  public checkDatetimeFulfilled: Date;
  public checkProfessionName: string;
  public checkUserName: string;

  public prescription: MedicalPrescriptionStruct;
  public thisMedicalPrescriptionCheck: MedicalPrescriptionCheckStruct;

  ngOnInit(): void {
    this.isLoading = false;
    this.showLocal = false;
    this.checkedMedicine = false;
    this.checkedPatient = false;
    this.showComplaints = false;
    this.isReadonly = false;
    this.prescription = this.data.prescription;
    this.thisMedicalPrescriptionCheck = this.prescription.thisMedicalPrescriptionCheck;
    this.model = this.formBuilder.group({
      checkName: ['', [Validators.required]],
      checkMedication: ['', [Validators.required]],
      checkAdministration: ['', [Validators.required]],
      administrationLocal: [''],
      hasPatientDenied: [''],
      hasComplaints: [''],
      complaints: [''],
    });

    this.populatePrescriptionCheck();
  }

  submit() {
    
  } 

  getPrescriptionCheckData() {
    this.checkDatetimeFulfilled = this.thisMedicalPrescriptionCheck.datetimeCheckFulfilled;
    this.checkProfessionName = this.thisMedicalPrescriptionCheck.professionName;
    this.checkUserName = this.thisMedicalPrescriptionCheck.userName;
  }

  populatePrescriptionCheck() {
    this.model.get('hasComplaints').setValue(this.thisMedicalPrescriptionCheck.hasComplaints);
    this.model.get('complaints').setValue(this.thisMedicalPrescriptionCheck.complaints)
    this.model.get('hasPatientDenied').setValue(this.thisMedicalPrescriptionCheck.hasPatientDenied);
    this.model.get('checkName').setValue(this.thisMedicalPrescriptionCheck.checkName);
    this.model.get('checkMedication').setValue(this.thisMedicalPrescriptionCheck.checkMedication);
    this.model.get('checkAdministration').setValue(this.thisMedicalPrescriptionCheck.checkAdministration);
    this.model.get('administrationLocal').setValue(this.thisMedicalPrescriptionCheck.administrationLocal)

    this.showLocal = this.thisMedicalPrescriptionCheck.checkAdministration;
    this.showComplaints = this.thisMedicalPrescriptionCheck.hasComplaints;

    if (this.thisMedicalPrescriptionCheck.hasComplaints == true)
      this.observationSelect = "2";
    else if (this.thisMedicalPrescriptionCheck.hasPatientDenied == true)
      this.observationSelect = "1";
    else
      this.observationSelect = "";

      this.model.get('hasComplaints').disable();
      this.model.get('complaints').disable();
      this.model.get('hasPatientDenied').disable();
      this.model.get('checkName').disable();
      this.model.get('checkMedication').disable();
      this.model.get('checkAdministration').disable();
      this.model.get('administrationLocal').disable();
    
    this.getPrescriptionCheckData();
  }
  close() {
    this.matDialogRef.close();
  }

  statusIdToText(status: number) : string {
    if(status == 1)
      return "Medicação Próxima";
    else if(status == 2)
      return "Medicação Adiantada";
    else if(status == 3)
      return "Medicação Atrasada";
  }
}