import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RoomOccupiedConfirmModalComponent } from '../room-occupied-confirm-modal/room-occupied-confirm-modal.component';

@Component({
  selector: 'app-room-occupied-modal',
  templateUrl: './room-occupied-modal.component.html',
  styleUrls: ['./room-occupied-modal.component.css']
})
export class RoomOccupiedModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<RoomOccupiedModalComponent>) { }

  ngOnInit(): void {
  }

  close() {
    this.matDialogRef.close();
  }

  visualize() {
    this.matDialogRef.close({ visualize: true });
  }

  continue() {
    const dialogRef = this.dialog.open(RoomOccupiedConfirmModalComponent);
    dialogRef.afterClosed().subscribe(result => {
      this.matDialogRef.close(result);
    });
  }

  clickCancel() {
    this.matDialogRef.close();
  }
}

