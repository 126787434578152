import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LocationService } from '../../services/API/pharmacy/location.service';
import { AlertService, AlertType } from '../../services/alert.service';
import { LocationModel } from '../../services/models/pharmacy/location.model';

@Component({
  selector: 'app-storage-migration-modal',
  templateUrl: './storage-migration-modal.component.html',
  styleUrls: ['./storage-migration-modal.component.css']
})
export class StorageMigrationModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<AlertModalComponent>,
    private locationService: LocationService,
    private alertService: AlertService,
  ) { }

  public isLoading: boolean;
  public model: FormGroup;
  public listLocation: LocationModel[] = [];
  public idCurrentLocation: number;

  ngOnInit(): void {
    this.idCurrentLocation = this.data.idCurrentLocation;
    this.model = this.formBuilder.group({
      idLocation: [null],
    });
    this.getLocations();
  }

  getLocations() {
    this.locationService.readActive(null).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listLocation = response.listLocation.filter(x => x.idLocation != this.idCurrentLocation);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  submit() {
    this.matDialogRef.close({ idNewLocation: this.model.get('idLocation').value });

  }

  close() {
    this.matDialogRef.close({});
  }

}
