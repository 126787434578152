<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="body-modal">
            <app-tiss-guide-management [idEpisode]="this.data.idEpisode" [idSector]="this.data.idSector"
                [idRoom]="this.data.idRoom" [isAttendance]="false" [patientSocialName]="this.data.patientSocialName"
                [patientName]="this.data.patientName" [idPatient]="this.data.idPatient"
                (operationCompleted)="operationIsLoading($event)"></app-tiss-guide-management>
        </div>
    </div>
</div>
<div mat-dialog-footer class="footer-modal">
    <button mat-flat-button color="accent" class="btn-block" (click)="close()" type="button">
        <span *ngIf="isLoading == false">Fechar</span>
        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
    </button>
</div>