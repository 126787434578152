<div class="table-container">
    <table class="basic-table desktop">
        <thead class="basic-table__head">
            <tr>
                <th *ngFor="let column of columns" [style.width]="column.width">
                    {{ column.header }}
                </th>
            </tr>
        </thead>
        <tbody class="basic-table__body">
            <tr class="basic-table__body__row" *ngFor="let item of list">
                <td *ngFor="let column of columns" [style.width]="column.width">
                    <ng-container *ngIf="!column.isAction; else actionsTemplate" [matTooltip]="item[column.field]">
                        <span
                            *ngIf="column.field === 'datetimeInclusion' || column.field === 'datetimeRelease'; else defaultField"
                            [matTooltip]="item[column.field]">
                            {{ item[column.field] | date:'dd/MM/yyyy \'às\' HH:mm' }}
                        </span>
                        <ng-template #defaultField>
                            <span matTooltip="{{item[column.field]}}">
                                {{ item[column.field] }}
                            </span>
                        </ng-template>
                    </ng-container>

                    <ng-template #actionsTemplate>
                        <div class="actions">
                            <a (click)="onViewClick(item)">
                                <mat-icon aria-hidden="false" aria-label="Visualizar">
                                    visibility
                                </mat-icon>
                            </a>
                        </div>
                    </ng-template>
                </td>
            </tr>
        </tbody>
    </table>
</div>