import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { LocationRequest } from '../../requests/pharmacy/location.request';
import { GetLocationResponse } from '../../responses/pharmacy/get-location.response';
import { ListLocationResponse } from '../../responses/pharmacy/list-location.response';
import { ListMedicineAdministrationTypeResponse } from '../../responses/pharmacy/list-medicine-administration-type.response';

@Injectable({
  providedIn: 'root'
})
export class LocationService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public readAll(searchText: string = ''): Observable<ListLocationResponse> {
    let uri = 'Location/getAll?';

    if (searchText)
      uri = uri + `searchText=${searchText}&`

    return this.httpClient.get<ListLocationResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public readActive(searchText: string = ''): Observable<ListLocationResponse> {
    let uri = `Location?`;

    if (searchText)
      uri = uri + `searchText=${searchText}&`

    return this.httpClient.get<ListLocationResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getLocation(idLocation: number): Observable<GetLocationResponse> {
    let uri = `Location/idLocation/${idLocation}`;

    return this.httpClient.get<GetLocationResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }


  public createLocation(body: LocationRequest): Observable<ReturnStruct> {

    let uri = `Location`

    return this.httpClient.post<ReturnStruct>(environment.urlApiPharmacy + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public updateLocation(idLocation: number, body: LocationRequest): Observable<ReturnStruct> {

    let uri = `Location/idLocation/${idLocation}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiPharmacy + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public deleteLocation(idLocation: number): Observable<ReturnStruct> {

    let uri = `Location/idLocation/${idLocation}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiPharmacy + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}