import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { ErrorRequestPageRequest } from '../../requests/srv-log/error-request-page.request';

@Injectable({
  providedIn: 'root' 
})
export class ErrorRequestPageService extends CommonService{

  constructor(private router: Router,
    private httpClient: HttpClient) {
    super();
  }

  public post(body: ErrorRequestPageRequest[]): Observable<ReturnStruct> {

    return this.httpClient.post<ReturnStruct>(environment.urlApiSrvLog + "ErrorRequestPage", body, this.addHeaderToken())
  }
}
