import { ReturnStruct } from "src/app/shared/structs/return.struct";
import { SpSadtGuideStruct } from "../../structs/private-billing/sp-sadt-guide.struct";
import { AdmissionGuideStruct } from "../../structs/private-billing/admission-guide.struct";
import { AppointmentGuideStruct } from "../../structs/private-billing/appointment-guide.struct";
import { FeesGuideStruct } from "../../structs/private-billing/fees-guide.struct";

export class GuideStructResponse extends ReturnStruct {
    public admissionGuideStruct: AdmissionGuideStruct;
    public spSadtGuideStruct: SpSadtGuideStruct;
    public appointmentGuideStruct: AppointmentGuideStruct;
    public feesGuideStruct: FeesGuideStruct;
}