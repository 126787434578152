import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { RecoverPasswordRequest } from '../../requests/user/recover-password.request';
import { RecoverPasswordResponse } from '../../responses/user/recover-password.response';

@Injectable({
  providedIn: 'root'
})
export class RecoverPasswordService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public recoverPasswordToken(login: string, idHealthUnit, recoverOption: number): Observable<RecoverPasswordResponse> {
    let body = {
      login: login,
      idHealthUnit: idHealthUnit,
      recoverOption: recoverOption
    };

    return this.httpClient.post<RecoverPasswordResponse>(environment.urlApiOrchestratorUser + "CelerusRecoverPassword", body)
      .pipe(
        catchError(this.handleError)
      );
  }
}
