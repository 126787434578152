import { Injectable } from "@angular/core";
import { HumanizedHeaderResponse } from "../../services/responses/orchestrator-patient/humanized-header.response";

@Injectable({
    providedIn: 'root'
})
export class UtilsSecundaryHeaderService {
    constructor() { }
    getSessionEpisodeData() {
        let episodeData: HumanizedHeaderResponse = JSON.parse(sessionStorage.getItem("episodeData"))
        return episodeData;
    }
    createSessionEpisodeData(episodeData: HumanizedHeaderResponse) {
        sessionStorage.setItem("episodeData", JSON.stringify(episodeData));
    }
    removeSessionEpisodeData() {
        sessionStorage.removeItem("episodeData");
    }
}