import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, UntypedFormArray, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { Access } from 'src/app/shared/services/models/private-billing/access.model';
import { Technique } from 'src/app/shared/services/models/private-billing/technique.model';
import { TerminologyGroup } from 'src/app/shared/services/models/private-billing/terminology-group.model';
import { ProcedureService } from 'src/app/shared/services/API/private-billing/procedure.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { GetProceduresRequest } from 'src/app/shared/services/requests/private-billing/get-procedures.request';
import { ProcedureStruct } from 'src/app/shared/services/structs/private-billing/procedure.struct';
import { ParticipationDegree } from 'src/app/shared/services/models/private-billing/participation-degree.model';
import { HealthcareAgreementStruct } from 'src/app/shared/services/structs/private-billing/healthcare-agreement.struct';
import { ProfessionalAgreementStruct } from 'src/app/shared/services/structs/private-billing/professional-agreement.struct';
import { Cbo } from 'src/app/shared/services/models/private-billing/cbo.model';
import { AdjustmentCoefficientMappings } from 'src/app/shared/enum/private-billing/adjustment-coefficient-enums.enum';
import { TissBilling } from 'src/app/shared/services/models/private-billing/tiss-billing.model';
import { SpSadtExecutedProcedureStruct } from 'src/app/shared/services/structs/private-billing/sp-sadt-executed-procedure.struct';
import { TimeValidator, compareTime } from 'src/app/shared/custom-validators/time.validator';
import { DateValidator } from 'src/app/shared/custom-validators/date.validator';
import { SpSadtFieldsMaxLengthEnum } from 'src/app/shared/enum/private-billing/sp-sadt-fields-max-length.enum';
import { TerminologyGroupEnum } from 'src/app/shared/enum/private-billing/terminology-group.enum';
import { ProfessionalAgreementService } from 'src/app/shared/services/API/private-billing/professional-agreement.service';
import { AttendanceAspectEnum } from 'src/app/shared/enum/private-billing/attendance-aspect.enum';
import { State } from 'src/app/shared/services/models/private-billing/state.model';
import { ProfessionalCouncil } from 'src/app/shared/services/models/private-billing/professional-council.model';
import { SpSadtExecutedProcedureProfessionalStruct } from 'src/app/shared/services/structs/private-billing/sp-sadt-executed-procedure-professional.struct';
import { CheckHasRestrictionsRequest } from 'src/app/shared/services/requests/private-billing/check-has-restriction.request';
import { GuideTypeEnum } from 'src/app/shared/enum/private-billing/guide-type.enum';
import { CheckHasRestrictionService } from 'src/app/shared/services/API/orchestrator-billing/episode.service';

@Component({
  selector: 'app-sp-sadt-executed-procedure-form-modal',
  templateUrl: './sp-sadt-executed-procedure-form-modal.component.html',
  styleUrls: ['./sp-sadt-executed-procedure-form-modal.component.css']
})
export class SpSadtExecutedProcedureFormModalComponent implements OnInit, OnDestroy {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public alertService: AlertService,
    public matDialogRef: MatDialogRef<SpSadtExecutedProcedureFormModalComponent>,
    public procedureService: ProcedureService,
    private formBuilder: FormBuilder,
    private maskService: MaskService,
    private professionalAgreementService: ProfessionalAgreementService,
    private checkHasRestrictionService: CheckHasRestrictionService
  ) { }

  private timeoutKeySearch: any = null;
  private timeoutKeyCalculateTotal: any = null;

  public spSadtFieldsMaxLengthEnum: typeof SpSadtFieldsMaxLengthEnum = SpSadtFieldsMaxLengthEnum;
  public attendanceAspectEnum: typeof AttendanceAspectEnum = AttendanceAspectEnum;

  public model: FormGroup;
  public masks: Masks = this.maskService.getMasks();
  public healthcareAgreement: HealthcareAgreementStruct = this.data.healthcareAgreement;
  public spSadtExecutedProcedure: SpSadtExecutedProcedureStruct;
  public tissBiling: TissBilling = this.data.tissBiling;;

  public accessMap: Map<number, Access>;
  public techniqueMap: Map<number, Technique>;
  public participationDegreeMap: Map<number, ParticipationDegree>;
  public tussTerminologyGroupMap: Map<number, TerminologyGroup>;
  public cboMap: Map<number, Cbo>;

  public listProfessionalAgreementStruct: ProfessionalAgreementStruct[] = this.data.listProfessionalAgreementStruct;
  public listProcedure: ProcedureStruct[] = [];
  public listState: State[] = this.data.listState;
  public listProfessionalCouncil: ProfessionalCouncil[] = this.data.listProfessionalCouncil;

  public idTerminologyGroup: number = this.data.idTerminologyGroup;
  public idAttendanceAspect: number = 0;
  public idProcedure: number = null;

  public isFirstLoading: boolean = false;
  public isLoading: boolean = false;
  public isSelectProcedure: boolean = false;
  public isEditingAllowed: boolean = this.data.isEditingAllowed;
  public procedimentos: TerminologyGroupEnum = TerminologyGroupEnum.procedimentos;

  //Setup  
  ngOnInit(): void {
    this.accessMap = this.data.accessMap;
    this.techniqueMap = this.data.techniqueMap;
    this.participationDegreeMap = this.data.participationDegreeMap;
    this.tussTerminologyGroupMap = this.data.tussTerminologyGroupMap;
    this.cboMap = this.data.cboMap;
    this.idAttendanceAspect = this.data.idAttendanceAspect;

    const currentDate = new Date();
    this.model = this.formBuilder.group({
      idSpSadtExecutedProcedure: [null, []],
      procedure: [{ value: '', disabled: true }, [Validators.required]],
      idPriceProcedurePlan: [null, []],
      idPriceProcedureHealthcare: [null, []],
      idTerminologyGroup: [null, [Validators.required]],
      procedureDate: [this.maskService.formatDateToString(currentDate, false), [Validators.required, DateValidator()]],
      procedureStartDatetime: [null, [TimeValidator()]],
      procedureEndDatetime: [null, [TimeValidator()]],
      procedureCount: [1, [Validators.required, Validators.maxLength(SpSadtFieldsMaxLengthEnum.procedureCount)]],
      idAccess: [null, []],
      idTechnique: [null, []],
      adjustmentCoefficient: ['1.00', [Validators.required]],
      procedureUnitValue: ['0,00', [Validators.required]],
      procedureTotalValue: [{ value: '0,00', disabled: true }, [Validators.required]],
      listProfessionals: this.formBuilder.array([], [Validators.required])
    },
      {
        validators: [compareTime()]
      });

    if (this.idAttendanceAspect == this.attendanceAspectEnum.urgencia) {
      this.model.get('procedureStartDatetime').setValidators([Validators.required]);
      this.model.get('procedureStartDatetime').updateValueAndValidity();
      this.model.get('procedureEndDatetime').setValidators([Validators.required]);
      this.model.get('procedureEndDatetime').updateValueAndValidity();
    }

    if (this.data.spSadtExecutedProcedure) {
      this.spSadtExecutedProcedure = this.data.spSadtExecutedProcedure;
      this.populate()
    }
    else
      this.addNext();
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeoutKeySearch)
    clearTimeout(this.timeoutKeyCalculateTotal)
  }

  populate() {
    this.model.get('idTerminologyGroup').setValue(this.spSadtExecutedProcedure.idTerminologyGroup);
    this.getProcedures('', this.spSadtExecutedProcedure.idProcedure);
    this.model.get('procedureDate').setValue(this.maskService.formatDateToString(this.spSadtExecutedProcedure.procedureDate, false))

    this.model.get('procedureStartDatetime').setValue(this.formatHour(this.spSadtExecutedProcedure.procedureStartTime?.replaceAll(":", ""), true));
    this.model.get('procedureEndDatetime').setValue(this.formatHour(this.spSadtExecutedProcedure.procedureEndTime?.replaceAll(":", ""), true));
    this.model.get('procedureCount').setValue(this.spSadtExecutedProcedure.procedureQuantity);
    this.model.get('idAccess').setValue(this.spSadtExecutedProcedure.idAccessLookup);

    this.model.get('idTechnique').setValue(this.spSadtExecutedProcedure.idTechniqueLookup);
    this.model.get('adjustmentCoefficient').setValue(this.spSadtExecutedProcedure.adjustmentCoefficient);
    this.model.get('procedureTotalValue').setValue(this.spSadtExecutedProcedure.procedureTotalValue);
    this.model.get('procedureUnitValue').setValue(this.spSadtExecutedProcedure.procedureUnitaryValue);

    this.spSadtExecutedProcedure.listProfessional.forEach((prof, index) => {
      this.addNext();
      let professionalForm = this.professionals().controls[index];
      let profStruct = this.listProfessionalAgreementStruct.find(x => x.idProfessionalAgreement == prof.idProfessionalAgreement);
      professionalForm.get('professionalAgreement').setValue(profStruct);
      professionalForm.get('idSpSadtExecutedProcedureProfessional').setValue(prof.idSpSadtExecutedProcedureProfessional);
      this.selectProfessional({ value: profStruct }, index);
    });
  }

  //Exits

  submit() {
    let minCheck = this.checkMinProfessional();
    if (!minCheck) {
      this.model.markAllAsTouched();
      this.alertService.show('Erro', "Preencha pelo menos um profissional", AlertType.error);
      return;
    }

    if (this.model.invalid) {
      this.model.markAllAsTouched();
      this.alertService.show('Erro', "Preencha todos os campos obrigatórios", AlertType.error);
      return;
    }

    if (this.data.idPatient)
      this.checkHasRestriction();

    let spSadtExecutedProcedureStruct = new SpSadtExecutedProcedureStruct();
    //Model
    spSadtExecutedProcedureStruct.idSpSadtExecutedProcedure = this.spSadtExecutedProcedure?.idSpSadtExecutedProcedure;
    spSadtExecutedProcedureStruct.idSpSadtGuide = this.spSadtExecutedProcedure?.idSpSadtGuide;
    spSadtExecutedProcedureStruct.idPriceProcedureHealthcare = this.model.get('procedure').value.idLastPriceProcedureHealthcare;
    const procedureDateForm = this.model.get('procedureDate').value;

    const day = parseInt(procedureDateForm.substring(0, 2), 10);
    const month = parseInt(procedureDateForm.substring(2, 4), 10) - 1;
    const year = parseInt(procedureDateForm.substring(4), 10);
    const procedureDateValue = new Date(year, month, day, 0, 0, 0, 0);
    spSadtExecutedProcedureStruct.procedureDate = procedureDateValue;
    spSadtExecutedProcedureStruct.procedureStartTime = this.formatHour(this.model.get('procedureStartDatetime').value);
    spSadtExecutedProcedureStruct.procedureEndTime = this.formatHour(this.model.get('procedureEndDatetime').value);


    spSadtExecutedProcedureStruct.procedureQuantity = this.model.get('procedureCount').value;
    spSadtExecutedProcedureStruct.idAccessLookup = this.model.get('idAccess').value;
    spSadtExecutedProcedureStruct.idTechniqueLookup = this.model.get('idTechnique').value;
    spSadtExecutedProcedureStruct.adjustmentCoefficient = parseFloat(this.model.get('adjustmentCoefficient').value);
    spSadtExecutedProcedureStruct.terminologyName = this.model.get('procedure').value.terminology.terminologyName;

    spSadtExecutedProcedureStruct.idProcedure = this.model.get('procedure').value.idProcedure;
    spSadtExecutedProcedureStruct.procedureUnitaryValue = parseFloat(this.model.get('procedureUnitValue').value);
    spSadtExecutedProcedureStruct.procedureTotalValue = parseFloat(this.model.get('procedureTotalValue').value);
    spSadtExecutedProcedureStruct.idTerminology = this.model.get('procedure').value.terminology.idTerminology;
    spSadtExecutedProcedureStruct.terminologyCode = this.model.get('procedure').value.terminology.terminologyCode;
    spSadtExecutedProcedureStruct.idTerminologyGroup = this.model.get('procedure').value.terminology.idTerminologyGroup;
    spSadtExecutedProcedureStruct.terminologyGroupCode = this.tussTerminologyGroupMap.get(this.model.get('procedure').value.terminology.idTerminologyGroup).terminologyGroupCode;
    this.professionals().controls.forEach(x => {
      if (x.get('idProfessionalAgreement').value)
        spSadtExecutedProcedureStruct.listProfessional.push(this.mapSpSadtProfessionalModel(x));
    });

    if (spSadtExecutedProcedureStruct.listProfessional && spSadtExecutedProcedureStruct.listProfessional.length < 1) {
      this.alertService.show('Erro', "Preencha pelo menos um profissional", AlertType.error);
      this.isLoading = false;
      return;
    }

    this.matDialogRef.close({ spSadtExecutedProcedureStruct: spSadtExecutedProcedureStruct });
  }

  close() {
    this.matDialogRef.close();
  }

  //Dynamic triggers  
  onChangeTotalValue() {
    let procedureTotalValue = this.calculateTotalValue();
    this.model.get('procedureTotalValue').setValue(procedureTotalValue);
  }

  onChangeAccess() {
    if (!this.model.get('procedure').value)
      return;

    this.calculateAdjustmentCoefficient();
    let procedureTotalValue = this.calculateTotalValue();

    this.model.get('procedureTotalValue').setValue(procedureTotalValue);
  }

  async selectTable() {
    this.model.get('procedure').setValue(null);
    this.model.get('procedure').reset();
    this.model.get('procedureCount').setValue(1);
    this.model.get('idAccess').setValue(null);
    this.model.get('idTechnique').setValue(null);
    this.model.get('adjustmentCoefficient').setValue('1.00');
    this.model.get('procedureUnitValue').setValue('0,00');
    this.model.get('procedureTotalValue').setValue('0,00');

    this.isSelectProcedure = false;
    await this.getProcedures("");
  }

  selectProcedure(event: ProcedureStruct) {
    this.isSelectProcedure = true;
    this.model.get('procedureUnitValue').setValue(event.value);
    this.onChangeTotalValue();
  }

  onKeyProfessional(event: any) {
    clearTimeout(this.timeoutKeySearch);
    if (event) {
      var $this = this;
      this.timeoutKeySearch = setTimeout(function () {
        if (event.keyCode != 13)
          $this.getProfessional(event);
      }, 1000);
    }
  }

  selectProfessional(event: any, index: number) {
    this.professionals().controls[index].get('idProfessionalAgreement').setValue(event.value.idProfessionalAgreement);
    this.professionals().controls[index].get('idParticipationDegree').setValue(event.value.idParticipationDegree);
    this.professionals().controls[index].get('professionalProviderCodeOrCpf').setValue(event.value.codeOperator.toString());
    this.professionals().controls[index].get('professionalName').setValue(event.value.nameProfessional);
    this.professionals().controls[index].get('idProfessionalCouncil').setValue(event.value.idProfessionalCouncil);
    this.professionals().controls[index].get('professionalCouncilCode').setValue(event.value.numberProfessional.toString());
    this.professionals().controls[index].get('idCbo').setValue(event.value.idCbo);
    this.professionals().controls[index].get('idCouncilState').setValue(event.value.idState);
    this.onChangeTotalValue();
  }

  async getProcedures(key: string, idProcedureToPopulate?: number) {
    this.isLoading = true;
    let request: GetProceduresRequest = {
      key: key,
      idHealthcareAgreement: this.healthcareAgreement.idHealthcareAgreement,
      idTerminologyGroup: this.model.get('idTerminologyGroup').value,
      isCreate: this.data.idSpSadtGuide == null ? true : false,
      listIdProcedure: Number.isSafeInteger(idProcedureToPopulate) ? [idProcedureToPopulate] : []
    };

    this.procedureService.GetByTerminologyGroup(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listProcedure = response.listProcedureStruct;

        if (idProcedureToPopulate) {
          this.isSelectProcedure = true;
          this.model.get('procedure').setValue(this.listProcedure.find(x => x.idProcedure == idProcedureToPopulate))
        }

        this.model.get('procedure').enable();

        if (this.isEditingAllowed)
          this.model.enable();
        else if (!this.isEditingAllowed)
          this.model.disable();

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  calculateAdjustmentCoefficient() {
    const mappings = new AdjustmentCoefficientMappings();
    let adjustmentCoefficient: number = 1.00;

    const idAccess = this.model.get('idAccess').value;

    if (idAccess && this.tissBiling && this.isSelectProcedure) {
      switch (idAccess) {
        case mappings.AccessMapping.honorarySameGuide:
          adjustmentCoefficient *= this.tissBiling.honorarySameGuide / 100;
          break;
        case mappings.AccessMapping.honoraryBilateral:
          adjustmentCoefficient *= this.tissBiling.honoraryBilateral / 100;
          break;
        case mappings.AccessMapping.honoraryDifferentGuide:
          adjustmentCoefficient *= this.tissBiling.honoraryDifferentGuide / 100;
          break;
      }
    }

    this.model.get('adjustmentCoefficient').setValue(adjustmentCoefficient);
  }

  calculateUnitValue() {
    const adjustmentCoefficient = parseFloat(this.model.get('adjustmentCoefficient').value);
    const baseValue = parseFloat(this.model.get('procedureUnitValue').value);
    const unitValue = baseValue * adjustmentCoefficient;
    return unitValue;
  }

  calculateProfesisonalValue(professional: AbstractControl): number {
    if (!professional.value.professionalAgreement || !professional.value.professionalAgreement.idProfessionalAgreement
      || !this.tissBiling)
      return 0;

    const idParticipationDegree = professional.get('idParticipationDegree').value;

    let professionalFactor: number = 1.00;

    let unitValue = this.calculateUnitValue();
    const mappings = new AdjustmentCoefficientMappings();

    switch (idParticipationDegree) {
      case mappings.ParticipationDegreeMapping.participationSurgeon:
        professionalFactor *= this.tissBiling.participationSurgeon / 100;
        break;
      case mappings.ParticipationDegreeMapping.participationFirstAux:
        professionalFactor *= this.tissBiling.participationFirstAux / 100;
        break;
      case mappings.ParticipationDegreeMapping.participationSecondAux:
        professionalFactor *= this.tissBiling.participationSecondAux / 100;
        break;
      case mappings.ParticipationDegreeMapping.participationThirdAux:
        professionalFactor *= this.tissBiling.participationThirdAux / 100;
        break;
      case mappings.ParticipationDegreeMapping.participationForthAux:
        professionalFactor *= this.tissBiling.participationForthAux / 100;
        break;
      case mappings.ParticipationDegreeMapping.participationScrubNurse:
        professionalFactor *= this.tissBiling.participationScrubNurse / 100;
        break;
      case mappings.ParticipationDegreeMapping.participationAnesthetist:
        professionalFactor *= this.tissBiling.participationAnesthetist / 100;
        break;
      case mappings.ParticipationDegreeMapping.participationAnesthetistAuxiliar:
        professionalFactor *= this.tissBiling.participationAnesthetistAuxiliar / 100;
        break;
      case mappings.ParticipationDegreeMapping.participationConsultant:
        professionalFactor *= this.tissBiling.participationConsultant / 100;
        break;
      case mappings.ParticipationDegreeMapping.participationPerfusionist:
        professionalFactor *= this.tissBiling.participationPerfusionist / 100;
        break;
      case mappings.ParticipationDegreeMapping.participationBirthRoomPediatrician:
        professionalFactor *= this.tissBiling.participationBirthRoomPediatrician / 100;
        break;
      case mappings.ParticipationDegreeMapping.participationSadtAuxiliar:
        professionalFactor *= this.tissBiling.participationSadtAuxiliar / 100;
        break;
      case mappings.ParticipationDegreeMapping.participationClinic:
        professionalFactor *= this.tissBiling.participationClinic / 100;
        break;
      case mappings.ParticipationDegreeMapping.participationIntensivist:
        professionalFactor *= this.tissBiling.participationIntensivist / 100;
        break;
    }
    return professionalFactor * unitValue;
  }

  calculateTotalValue() {
    let professionalFullValue: number = 0;

    if (this.model.get('idTerminologyGroup').value == TerminologyGroupEnum.procedimentos && this.tissBiling) {
      this.professionals().controls.forEach((professional) => {
        professionalFullValue += this.calculateProfesisonalValue(professional);
      });
    }
    let unitValue = this.calculateUnitValue();
    let procedureCount = parseInt(this.model.get('procedureCount').value);

    return (unitValue + professionalFullValue) * procedureCount;
  }

  checkMinProfessional(): boolean {
    return this.professionals().controls.some(x => x.get('idProfessionalAgreement').value != null);
  }

  mapSpSadtProfessionalModel(professionalForm: AbstractControl): SpSadtExecutedProcedureProfessionalStruct {
    let spSadtExecutedProcedureProfessional: SpSadtExecutedProcedureProfessionalStruct = new SpSadtExecutedProcedureProfessionalStruct();
    spSadtExecutedProcedureProfessional.idSpSadtExecutedProcedureProfessional = parseInt(professionalForm.get('idSpSadtExecutedProcedureProfessional').value);
    spSadtExecutedProcedureProfessional.idSpSadtExecutedProcedure = parseInt(professionalForm.get('idSpSadtExecutedProcedure').value);
    spSadtExecutedProcedureProfessional.idParticipationDegreeLookup = professionalForm.get('idParticipationDegree').value;
    spSadtExecutedProcedureProfessional.executantContractorOperatorCodeOrCpf = professionalForm.get('professionalProviderCodeOrCpf').value;
    spSadtExecutedProcedureProfessional.executantProfessionalName = professionalForm.get('professionalName').value;
    spSadtExecutedProcedureProfessional.executantProfessionalCouncilNumber = professionalForm.get('professionalCouncilCode').value;
    spSadtExecutedProcedureProfessional.idExecutantProfessionalCbo = professionalForm.get('idCbo').value;
    spSadtExecutedProcedureProfessional.idExecutantProfessionalCouncilState = professionalForm.get('idCouncilState').value;
    spSadtExecutedProcedureProfessional.idExecutantProfessionalCouncil = professionalForm.get('idProfessionalCouncil').value;
    spSadtExecutedProcedureProfessional.idProfessionalAgreement = professionalForm.get('idProfessionalAgreement').value;

    return spSadtExecutedProcedureProfessional;
  }

  professionals(): UntypedFormArray {
    return this.model.get('listProfessionals') as UntypedFormArray;
  }

  createInput() {
    return this.formBuilder.group({
      idProfessionalCouncil: [{ value: '', disabled: true }, []],
      professionalCouncilCode: [{ value: '', disabled: true }, []],
      idCouncilState: [{ value: '', disabled: true }, []],

      idSpSadtExecutedProcedureProfessional: [{ value: '', disabled: true }, []],
      idProfessionalAgreement: [{ value: '', disabled: true }, []],
      idSpSadtExecutedProcedure: [{ value: '', disabled: true }, []],


      professionalAgreement: [null, [Validators.required]],
      idParticipationDegree: [{ value: '', disabled: true }, []],
      professionalProviderCodeOrCpf: [{ value: '', disabled: true }, []],
      professionalName: [{ value: '', disabled: true }, []],
      idCbo: [{ value: '', disabled: true }, []],
    })
  }

  addNext() {
    this.professionals().push(this.createInput());
    this.onChangeTotalValue();
  }

  removeProfessional(index: number) {
    this.professionals().removeAt(index);
    this.onChangeTotalValue();
  }

  formatHour(hour: string, noFormat: boolean = false): string {
    if (!hour) return;
    let stringHour = "";
    if (noFormat)
      stringHour = hour.slice(0, 2) + hour.slice(2, 4) + hour.slice(4, 6);
    else stringHour = hour.slice(0, 2) + ":" + hour.slice(2, 4) + ":" + hour.slice(4, 6);
    return stringHour;
  }

  onKey(event: any) {
    clearTimeout(this.timeoutKeySearch);
    if (event) {
      var $this = this;
      this.timeoutKeySearch = setTimeout(function () {
        if (event.keyCode != 13)
          $this.getProcedures(event, $this.model.get('procedure').value?.idProcedure);
      }, 1000);
    }
  }

  getProfessional(key: string) {
    this.professionalAgreementService.getProfessional(this.healthcareAgreement.idHealthcareAgreement, key).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listProfessionalAgreementStruct = response.list;

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  inputNumberMinOne(formName: string) {
    let value = this.model.get(formName).value ? this.model.get(formName).value : '';
    if (value) {
      value = value.replace(/[^0-9]/g, '');
      value = value.replace(/(\..*)\./g, '$1');
      if (parseInt(value) == 0) {
        this.alertService.show('Atenção', 'O valor não pode ser menor que 1!', AlertType.warning);
        value = '1';
      }
      this.model.get(formName).setValue(value);
    }
  }

  checkHasRestriction() {
    let request = new CheckHasRestrictionsRequest();
    request.idHealthcareAgreement = this.healthcareAgreement.idHealthcareAgreement;
    request.idProcedure = this.model.get('procedure').value.idProcedure;
    request.guideType = GuideTypeEnum.spSadt;
    request.idPatient = this.data.idPatient;

    this.checkHasRestrictionService.GetListEpisodeByIdPatient(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (response.restrictionProcedureStruct && response.restrictionProcedureStruct.alertDescription) 
          this.alertService.show(`<b>Atenção:</b>`, response.restrictionProcedureStruct.alertDescription, AlertType.warning);
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }
}