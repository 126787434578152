import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ListMedicinePresentationTypeResponse } from '../../responses/pharmacy/list-medicine-presentation-type.response';

@Injectable({
  providedIn: 'root'
})
export class MedicinePresentationTypeService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listMedicinePresentationType(): Observable<ListMedicinePresentationTypeResponse>{
    let uri = `MedicinePresentationType/`;

    return this.httpClient.get<ListMedicinePresentationTypeResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}