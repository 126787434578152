import { AdmissionGuide } from "../../models/private-billing/admission-guide.model";
import { Guide } from "../../models/private-billing/guide.model";
import { AdmissionProcedureStruct } from "./admission-procedure.struct";

export class AdmissionGuideStruct {
    public guide: Guide = new Guide();

    public admissionGuide: AdmissionGuide = new AdmissionGuide();
    public listAdmissionProcedure: AdmissionProcedureStruct[] = [];
    public expectedEndDate: Date;

    public logoName: string;
    public professionalCouncilName: string;
    public professionalStateName: string;
    public professionalCboCode: string;
    public attendanceAspectCode: string;
    public hospitalizationRegimeCode: string;
    public hospitalizationTypeCode: string;
    public accidentIndicationCode: string;
    public accommodationTypeCode: string;
    public newbornValue: string;
    public hasAlteration: boolean = false;
    public isEditingAllowed: boolean;
    public guideNumber: string;
}