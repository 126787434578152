import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { MedicineGroupRequest } from '../../requests/pharmacy/medicine-group.request';
import { GetMedicineGroupResponse } from '../../responses/pharmacy/get-medicine-group.response';
import { ListMedicineGroupResponse } from '../../responses/pharmacy/list-medicine-group.response';

@Injectable({
  providedIn: 'root'
})
export class MedicineGroupService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getMedicineGroup(idMedicineGroup: number): Observable<GetMedicineGroupResponse>{
    let uri = `MedicineGroup/idMedicineGroup/${idMedicineGroup}`;

    return this.httpClient.get<GetMedicineGroupResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public listMedicineGroup(searchText: string): Observable<ListMedicineGroupResponse> {
    
    let uri = 'MedicineGroup?'
    
    if(searchText != null)
      uri = uri+ `searchText=${searchText}&`;

    
    return this.httpClient.get<ListMedicineGroupResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public listAllMedicineGroup(): Observable<ListMedicineGroupResponse> {
    let uri = 'MedicineGroup/getAll'
    
    return this.httpClient.get<ListMedicineGroupResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public createMedicineGroup(body: MedicineGroupRequest): Observable<ReturnStruct>{

    let uri = `MedicineGroup`

    return this.httpClient.post<ReturnStruct>(environment.urlApiPharmacy + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public updateMedicineGroup(idMedicineGroup: number, body: MedicineGroupRequest): Observable<ReturnStruct>{

    let uri = `MedicineGroup/idMedicineGroup/${idMedicineGroup}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiPharmacy + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public deleteMedicineGroup(idMedicineGroup: number): Observable<ReturnStruct>{

    let uri = `MedicineGroup/idMedicineGroup/${idMedicineGroup}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiPharmacy + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
