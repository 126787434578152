<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-confirm-modal-list">
            <h2>Aviso</h2>
        </div>
        <div class="body-confirm-modal-list">
            <p>O Dispositivo do paciente está desligado ou perdeu conexão com a rede!</p>
        </div>
        <div class="footer-confirm-modal-list">
            <a mat-flat-button type="button" color="accent" class="btn-block" (click)="clickCancel(false)">
                Continuar
            </a>
        </div>
    </div>
</div>