import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { CallPanelEpisodeRequest } from '../../requests/orchestrator-panel/call-panel-episode.request';
import { CallPanelResponse } from '../../responses/orchestrator-panel/call-panel.response';

@Injectable({
  providedIn: 'root'
})
export class CallPanelAlertService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public alertCallPatient(callPanelEpisodeRequest: CallPanelEpisodeRequest,idQueue: number, idRoom: number, isVerifyCall: boolean, idModule: number): Observable<CallPanelResponse> {
    let uri = `CallPanelAlert/idQueue/${idQueue}/idRoomOrigin/${idRoom}/isVerifyCall/${isVerifyCall}/idModule/${idModule}`
      
    return this.httpClient.post<CallPanelResponse>(environment.urlApiOrchestratorPanel + uri, callPanelEpisodeRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
