import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DiscriminatorTypeEnum } from 'src/app/shared/enum/discriminator-type.enum';
import { MatDialog } from '@angular/material/dialog';
import { ClassificationData, UtilsClassificationService } from 'src/app/classification/utils.service';
import { GlasgowModalComponent } from '../../glasgow-modal/glasgow-modal.component';
import { TemperatureModalComponent } from '../../temperature-modal/temperature-modal.component';
import { SaturationModalComponent } from '../../saturation-modal/saturation-modal.component';
import { HeartRateModalComponent } from '../../heart-rate-modal/heart-rate-modal.component';
import { GlucoseModalComponent } from '../../glucose-modal/glucose-modal.component';
import { CapillaryGlucoseModalComponent } from '../../capillary-glucose-modal/capillary-glucose-modal.component';
import { RespiratoryFrequencyModalComponent } from '../../respiratory-frequency-modal/respiratory-frequency-modal.component';
import { PressureModalComponent } from '../../pressure-modal/pressure-modal.component';
import { PainModalComponent } from '../../pain-modal/pain-modal.component';
import { NumericModalComponent } from '../../numeric-modal/numeric-modal.component';
import { SubDiscriminatorStruct } from 'src/app/shared/services/structs/risk-classification/sub-discriminator.struct';
import { CapillaryPerfusionModalComponent } from '../../capillary-perfusion-modal/capillary-perfusion-modal.component';

@Component({
  selector: 'app-sub-discriminator',
  templateUrl: './sub-discriminator.component.html',
  styleUrls: ['./sub-discriminator.component.css']
})
export class SubDiscriminatorComponent implements OnInit, OnChanges {

  constructor(public dialog: MatDialog, public util: UtilsClassificationService) { }

  @Input() discriminator: SubDiscriminatorStruct;
  @Input() discriminatorsValues: any[];
  @Input() openModal: boolean;
  @Input() forceOpenModal: boolean;
  @Input() list: SubDiscriminatorStruct[];
  @Output() discriminatorValues = new EventEmitter<any>();
  @Input() isChild: boolean;

  public discriminatorTypePerfusaoCapilar: number = DiscriminatorTypeEnum.PerfusaoCapilar.valueOf();
  public discriminatorTypeBoolean: number = DiscriminatorTypeEnum.Boleano.valueOf();
  public classificationData: ClassificationData;
  public openModalImpl: boolean = false;
  public index: number;


  ngOnInit(): void {
    this.classificationData = this.util.getClassificationData();
    this.openModalImpl = this.openModal;
    this.index = this.list.findIndex(c => c.idSubDiscriminator == this.discriminator.idSubDiscriminator);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.openModal) {
      if (changes.openModal.previousValue != null && changes.openModal.currentValue !== changes.openModal.previousValue) {
        this.openModalImpl = changes.openModal.currentValue;
      }
    } else if (changes.forceOpenModal) {
      if (changes.forceOpenModal.currentValue || !changes.forceOpenModal.currentValue) {
        this.abrirModal();
      }
    }
  }

  abrirModal(): void {
    if (this.openModalImpl) {
      return;
    }
    let choosenModal;
    let data: any = {};
    data.discriminator = this.discriminator;
    data.discriminatorsValues = this.discriminatorsValues;
    data.classificationData = this.classificationData;
    data.description = this.list[this.index].type;
    data.state = this.list[this.index].state;
    switch (this.discriminator.idDiscriminatorType) {
      case DiscriminatorTypeEnum.Glasgow:
        choosenModal = GlasgowModalComponent;
        break;
      case DiscriminatorTypeEnum.Temperatura:
        data.isSepse = this.isChild;
        choosenModal = TemperatureModalComponent;
        break;
      case DiscriminatorTypeEnum.Saturacao:
        choosenModal = SaturationModalComponent;
        break;
      case DiscriminatorTypeEnum.FrequenciaCardiaca:
        choosenModal = HeartRateModalComponent;
        data.age = this.classificationData.ageYears;
        data.isSepse = this.isChild;
        break;
      case DiscriminatorTypeEnum.Glicemia:
        choosenModal = GlucoseModalComponent;
        break;
      case DiscriminatorTypeEnum.GlicemiaCapilar:
        choosenModal = CapillaryGlucoseModalComponent;
        break;
      case DiscriminatorTypeEnum.FrequenciaRespiratoria:
        choosenModal = RespiratoryFrequencyModalComponent;
        break;
      case DiscriminatorTypeEnum.PressaoArterial:
        choosenModal = PressureModalComponent;
        break;
      case DiscriminatorTypeEnum.Dor:
        choosenModal = PainModalComponent;
        break;
      case DiscriminatorTypeEnum.Numerico:
        choosenModal = NumericModalComponent;
        break;
      case DiscriminatorTypeEnum.PerfusaoCapilar:
        choosenModal = CapillaryPerfusionModalComponent;
        break;
      default:
        return;
    }
    const dialogRef = this.dialog.open(choosenModal, { data });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.value) {
        this.list[this.index].value = result.value;
        this.list[this.index].type = result.description;
        this.list[this.index].state = result.state;
        this.list[this.index].hasValue = true;
        if (this.discriminator.idDiscriminatorType == DiscriminatorTypeEnum.Glasgow) {
          this.classificationData.glasgowMotor = result.glasgowMotor;
          this.classificationData.glasgowVerbal = result.glasgowVerbal;
          this.classificationData.glasgowEye = result.glasgowEye;
          this.util.updateClassificationData(this.classificationData);
        }
        this.discriminatorValues.emit({
          select: result.select,
          index: this.index,
        });
      } else if (result.value === null && result.select && result.motive) {
        this.discriminatorValues.emit({
          select: result.select,
          motive: result.motive,
          index: this.index,
        });
      }
    });
  }
}
