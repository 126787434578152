<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-confirm-modal-list">
            <h2>{{data.title}}</h2>
        </div>
        <div class="body-confirm-modal-list">
            <div class="row">
                <div class="col-12">{{data.message}}</div>
            </div>
        </div>
        <div class="footer-confirm-modal-list">
            <button mat-flat-button color="primary" class="btn-block" (click)="clickPrimary()" type="button">
                <span>{{data.btnPrimary}}</span>
            </button>
            <button mat-flat-button color="accent" class="btn-block" (click)="clickAccent()" type="button">
                <span>{{data.btnAccent}}</span>
            </button>
        </div>
    </div>
</div>