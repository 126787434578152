import { Socket } from 'socket.io-client';
import * as myGlobals from 'globals'; //<==== this one (**Updated**)
import { Component } from '@angular/core';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { WebsocketTelemedicineService } from '../websocket-telemedicine.service';
import { AlertService, AlertType } from '../alert.service';
import { WebsocketTelemedicineActionEnum } from '../../enum/websocket-telemedicine-action.enum';

@Injectable({
    providedIn: 'root'
})
export class WebsocketTelemedicineUtilService {

    constructor(private websocketTelemedicineService: WebsocketTelemedicineService, private alertService: AlertService) { }
    socket: Socket;
    idEpisode: number;
    onClientTelemedicine: Function;
    onCountSeconds: Function;
    onCountReady: Function;
    onTimeExpired: Function;
    context: any;
    public lastActtion: number;

    isConnect(): boolean {
        return this.socket && this.socket.connected;
    }

    blankFunction() {

    }

    setFunctions(onClientTelemedicine: Function, onCountSeconds: Function, onCountReady: Function, onTimeExpired: Function) {
        this.onClientTelemedicine = onClientTelemedicine ? onClientTelemedicine : this.blankFunction;

        this.onCountSeconds = onCountSeconds ? onCountSeconds : this.blankFunction;

        this.onCountReady = onCountReady ? onCountReady : this.blankFunction;

        this.onTimeExpired = onTimeExpired ? onTimeExpired : this.blankFunction;
    }

    clearSocket(): void {
        if (this.socket && this.socket.connected) {
            this.socket.disconnect();
        }
        this.socket = null;
        this.setFunctions(null, null, null, null);
    }

    setContext(context: any) {
        this.context = context;
    }

    emitCountReady(idEpisode) {
        this.socket.emit('countReady', `idEpisode-${idEpisode}`)
    }

    emitAction(action: Number, idEpisode: Number) {
        let request = {
            action: action == WebsocketTelemedicineActionEnum.medicEvasion && this.lastActtion == WebsocketTelemedicineActionEnum.patientInternetDown ? WebsocketTelemedicineActionEnum.patientInternetDown : action,
            idEpisode: idEpisode,
        }
        if (this.socket)
            this.socket.emit('action', request);
    }

    emitVerifying(idEpisode: Number) {
        this.socket.emit('verifying', `idEpisode-${idEpisode}`);
    }

    connectwebsocketRoom(idEpisode: number, context: any): void {
        this.idEpisode = idEpisode;
        this.context = context;
        this.lastActtion = null;
        this.socket = this.websocketTelemedicineService.websocketConnection();
        this.socket.emit('join', { room: `idEpisode-${idEpisode}`, medicSide: true });

        this.socket.on('clientTelemedicine', (res) => {
            this.lastActtion = res.action;
            this.onClientTelemedicine.call(this.context, res);
        });

        this.socket.on('onlyMedicConnected', () => {
            this.alertService.show("Aviso", "O paciente parece não estar na chamada!", AlertType.warning);
            this.onTimeExpired.call(this.context);
        });

        this.socket.on('startCount', () => {
            this.lastActtion = WebsocketTelemedicineActionEnum.startCountdown;
            this.onCountReady.call(this.context);
        });

        this.socket.on('countSeconds', (seconds) => {
            this.onCountSeconds.call(this.context, seconds);
        });

        this.socket.on('timeExpired', () => {
            this.onTimeExpired.call(this.context);
            this.alertService.show("Aviso", "O paciente não aceitou iniciar o atendimento dentro do tempo!", AlertType.warning);
        });

        this.socket.on('someoneDisconnected', () => {
            if (this.lastActtion === WebsocketTelemedicineActionEnum.startCountdown) {
                this.lastActtion = WebsocketTelemedicineActionEnum.patientInternetDown;
                this.alertService.show("Aviso", "O paciente está com instabilidade, aguarde alguns segundos para iniciar o atendimento!", AlertType.warning);
                this.onTimeExpired.call(this.context);
            } else if (this.lastActtion && this.lastActtion != WebsocketTelemedicineActionEnum.patientEvasion
                && this.lastActtion != WebsocketTelemedicineActionEnum.endMedicalCare) {
                setTimeout(() => {
                    this.socket.emit('verifying', `idEpisode-${idEpisode}`);
                }, 20000);
                this.alertService.show("Aviso", "O paciente está com instabilidade, aguarde alguns segundos!", AlertType.warning);
            }
        });

        this.socket.on('personsInRoom', (res) => {
            if (Number.isFinite(res) && res === 1) {
                this.onTimeExpired.call(this.context);
            }
        });

        this.socket.on('reconnect', () => {
            this.socket.emit('join', { room: `idEpisode-${idEpisode}`, medicSide: true });
        });
    }
}