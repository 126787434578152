<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-confirm-modal-list">
            <h4>{{data.confirmMessage}}</h4>
        </div>
        <form class="form" [formGroup]="model"  (ngSubmit)="submit()">
            <div class="body-confirm-modal-list">     
                <div class="row">   
                    <div class="col-md-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Matricula</mat-label>
                            <input matInput type="text" formControlName="login" required>
                            <mat-error *ngIf="model.get('login').invalid">Informe a Matricula</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Senha</mat-label>
                            <input matInput type="password" formControlName="password" required>
                            <mat-error *ngIf="model.get('password').invalid">Informe a senha</mat-error>
                        </mat-form-field>
                    </div>
                </div>             
                
            </div>
            <div class="footer-confirm-modal-list">
                <button mat-flat-button color="primary" class="btn-block" type="submit">
                    <span *ngIf="isLoading == false">Sim, chamar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
                <button mat-flat-button color="accent" class="btn-block"(click)="clickCancel()" type="button">Cancelar</button>
            </div>
        </form> 
    </div>
</div>


