
<div *ngIf="!idAdmStatus" class="status-tag"
[ngClass]="{'endRelease': endRealease == idStatus, 'endDeath': endDeath == idStatus, 'endInternation': endInternation == idStatus, 'endEvasion': endEvasion == idStatus, 'initial': initial == idStatus}">
    {{patientStatus}}
</div>

<div *ngIf="idAdmStatus" class="status-tag"
[ngClass]="{'endRelease': endRealease == idAdmStatus, 'endDeath': endDeath == idAdmStatus, 'endInternation': endInternation == idAdmStatus, 'endEvasion': endEvasion == idAdmStatus, 'initial': initial == idAdmStatus}">
    Aguardando: {{patientAdmStatus}}
</div>

