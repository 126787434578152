import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { MenuModuleEnum } from 'src/app/shared/components/menu/menu.module.enum';
import { AutomaticLoginService } from 'src/app/shared/services/API/user/automatic-login.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UserLoginResponse } from 'src/app/shared/services/responses/user/auth.response';
import { AutomaticLoginStruct } from 'src/app/shared/services/structs/user/automatic-login.struct';
import { DesconectDeviceModalComponent } from './desconect-device-modal/desconect-device-modal.component';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-connected-devices',
  templateUrl: './connected-devices.component.html',
  styleUrls: ['./connected-devices.component.css']
})
export class ConnectedDevicesComponent implements OnInit {

  constructor(
    private alertService:AlertService, 
    private router: Router,
    private authService: AuthService,
    private automaticLoginService: AutomaticLoginService,
    public dialog: MatDialog,
    public utilService: UtilService,
  ) { }
  
  public isLoading: boolean;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.auth;
  public userLoginResponse: UserLoginResponse;
  public listAutomaticLogin: AutomaticLoginStruct[] = [];
  public previousUrl: string = null;
  
  ngOnInit(): void {
    this.isLoading = false;
    this.userLoginResponse = this.authService.getTokenMenu();
    this.getDevices();
    this.previousUrl = this.utilService.getPreviousURL();
  }

  goBack(){
    if(this.previousUrl != undefined 
      && this.previousUrl != "undefined"
      && this.previousUrl != null
      && this.previousUrl != "null"){
      this.router.navigate([this.previousUrl])
      this.utilService.cleanPreviousURL();
    }
    else if (this.userLoginResponse?.idDefaultModule && this.userLoginResponse?.uriDefaultModule){
      this.router.navigate([this.userLoginResponse.uriDefaultModule]) 
    }
    else
      this.router.navigate(['/welcome'])
  }

  getDevices() {
    this.isLoading = true;

    this.automaticLoginService.getListConnectedDevices().subscribe({
      next:(response) => {

        if (response.isError) {
          this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.listAutomaticLogin = response.listAutomaticLogin;
        this.isLoading = false;

      },
      error:(error) => {
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  desconect(automaticLoginStruct: AutomaticLoginStruct) {
    const dialogRef = this.dialog.open(DesconectDeviceModalComponent, {
      data: {
        automaticLoginStruct: automaticLoginStruct
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.delete){
        this.getDevices();
      }
    });
  }
}

