import { Component, Input, OnInit } from '@angular/core';
import { ListPatientStruct } from '../../services/structs/orchestrator-queue/list-patient.struct';
import { MatDialog } from '@angular/material/dialog';
import { CompletePatientJourneyModalComponent } from 'src/app/shared/components/patient-list/complete-patient-journey-modal/complete-patient-journey-modal.component';
import { ColumnsFlowManagementPatientListEnum } from '../../enum/columns-flow-management-patient-list.enum';
import { PrioritiesStatusEnum } from '../../enum/priorities-status.enum';
import { CareLinePriorityEnum } from '../../enum/care-line-priority/care-line-priority.enum';

@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.css']
})
export class PatientListComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
  ) { }
  
  public columns: typeof ColumnsFlowManagementPatientListEnum = ColumnsFlowManagementPatientListEnum;
  
  @Input() listColumnToDisplay: number[] = [];
  @Input() listColumnNames: { name: string, hidden: boolean }[] =[];
  @Input() listPatient: ListPatientStruct[];
  public overEighty: PrioritiesStatusEnum = PrioritiesStatusEnum.MaiorDe80Anos;
  public overSixty: PrioritiesStatusEnum = PrioritiesStatusEnum.MaiorDe60Anos;
  public autism: PrioritiesStatusEnum = PrioritiesStatusEnum.AutistaTEA;
  public dengue: CareLinePriorityEnum = CareLinePriorityEnum.Dengue;

  ngOnInit(): void {
  }

  openCompleteJourney(idEpisode: number) {
    this.dialog.open(CompletePatientJourneyModalComponent, {
      data: {
        idEpisode: idEpisode
      },
    });
  }
}
