<!--  -->
<div class="col-12">
    <form [formGroup]="model">
        <app-loading-progress-bar *ngIf="isLoading"></app-loading-progress-bar>
        <table class="basic-table desktop col-12">
            <thead class="basic-table__head">
                <tr>
                    <th class="column-table">34 - Tabela</th>
                    <th class="column-code-procedure">35 - Código do procedimento ou item</th>
                    <th class="column-description">36 - Descrição</th>
                    <th class="column-requester-count ">37 - Qtde. Solic.</th>
                    <th class="column-requester-authorizated-count">38 - Qtde. Aut.</th>
                    <th class="column-action">Ações</th>
                </tr>
            </thead>
            <tbody class="basic-table__body">
                <tr class="basic-table__body__row" *ngFor="let admissionProcedure of listAdmissionProcedure">
                    <td>{{admissionProcedure.terminologyGroupCode}}</td>
                    <td>{{admissionProcedure.procedure.terminology.terminologyCode}}</td>
                    <td>{{admissionProcedure.procedure.terminology.terminologyName}}</td>
                    <td>{{admissionProcedure.procedureCount}}</td>
                    <td>{{admissionProcedure.procedureCountAuthorized}}</td>
                    <td *ngIf="isEditingAllowed != null && isEditingAllowed">
                        <a class="action-icon">
                            <mat-icon aria-hidden="false" aria-label="Editar" (click)="editItem(admissionProcedure)">
                                edit
                            </mat-icon>
                        </a>
                        <a class="action-icon">
                            <mat-icon aria-hidden="false" aria-label="Editar" (click)="deleteItem(admissionProcedure)">
                                delete
                            </mat-icon>
                        </a>
                    </td>
                    <td *ngIf="isEditingAllowed == null">
                        <a class="action-icon">
                            <mat-icon aria-hidden="false" aria-label="Editar" (click)="editItem(admissionProcedure)">
                                edit
                            </mat-icon>
                        </a>
                        <a class="action-icon">
                            <mat-icon aria-hidden="false" aria-label="Editar" (click)="deleteItem(admissionProcedure)">
                                delete
                            </mat-icon>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
        <div [formGroup]="model" class="col-12" style="padding-top: 10px;">
            <div class="organize-mat-form-table" *ngIf="showAdditionalFields">
                <div class="col-2 ajust-field">
                    <mat-form-field appearance="outline">
                        <mat-label>34 - Tabela</mat-label>
                        <mat-select formControlName="idTerminologyGroup" (selectionChange)="selectTable($event)">
                            <mat-option *ngFor="let item of tussTerminologyGroupMap | keyvalue"
                                [value]="item.value.idTerminologyGroup" [disabled]="item.value.idTerminologyGroup !== procedimentos">{{item.value.terminologyGroupCode}} - {{item.value.nameTerminologyGroup}}
                            </mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="model.get('idTerminologyGroup').invalid && !model.get('idTerminologyGroup').errors?.maxlength">Preenchimento
                            incorreto</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-5 ajust-field">
                    <mat-form-field appearance="outline">
                        <mat-label>35/36 - Código do procedimento ou item/Descrição</mat-label>
                        <mat-select formControlName="procedure" (selectionChange)="selectProcedure($event.value)">
                            <input class="inputSearchSelect" type="text" (keyup)="onKey($event.target.value)"
                                placeholder="Pesquisar" autocomplete="off" (keydown)="$event.stopPropagation()">
                            <mat-option *ngFor="let item of listProcedure"
                                [value]="item">{{item.terminology.terminologyCode}} -
                                {{item.terminology.terminologyName}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="model.get('procedure').invalid">Preenchimento incorreto</mat-error>
                    </mat-form-field>
                </div>

                <div class="col-2 ajust-field">
                    <mat-form-field appearance="outline">
                        <mat-label>37 - Qtde. Solic.</mat-label>
                        <input matInput type="text" [maxlength]="admissionMaxLength.procedureCount"
                            formControlName="procedureCount" 
                            (input)="inputNumberMinOne('procedureCount')">
                        <mat-error
                            *ngIf="model.get('procedureCount').invalid && !model.get('procedureCount').errors?.maxlength">Preenchimento
                            incorreto</mat-error>
                        <mat-hint align="end">{{ model.get('procedureCount').value ?
                            model.get('procedureCount').value.length : 0
                            }}/{{admissionMaxLength.procedureCount}}</mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-2 ajust-field">
                    <mat-form-field appearance="outline">
                        <mat-label>38 - Qtde. Aut.</mat-label>
                        <input matInput type="text" [maxlength]="admissionMaxLength.procedureCountAuthorized"
                            formControlName="procedureCountAuthorized"
                            oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"
                            (input)="inputNumberMinOne('procedureCountAuthorized')">
                        <mat-error
                            *ngIf="model.get('procedureCountAuthorized').invalid && !model.get('procedureCountAuthorized').errors?.maxlength">Preenchimento
                            incorreto</mat-error>
                        <mat-hint align="end">{{ model.get('procedureCountAuthorized').value ?
                            model.get('procedureCountAuthorized').value.length : 0
                            }}/{{admissionMaxLength.procedureCountAuthorized}}</mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-1 button-included" *ngIf="isEditingAllowed != null && showAdditionalFields">
                    <button color="primary" class="btn-block min-width" mat-flat-button type="button"
                        [disabled]="!isValidNewRule() && !isEditingAllowed" (click)="submit()">
                        Salvar
                    </button>
                    <button mat-flat-button type="button" class="btn-cancel" (click)="cancelEdit()">
                        <span *ngIf="!isLoading">X</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                    </button>
                </div>
                <div class="col-1 button-included" *ngIf="isEditingAllowed == null && showAdditionalFields">
                    <button color="primary" class="btn-block min-width" mat-flat-button type="button"
                        [disabled]="!isValidNewRule()" (click)="submit()">
                        Salvar
                    </button>
                    <button mat-flat-button type="button" class="btn-cancel" (click)="cancelEdit()">
                        <span *ngIf="!isLoading">X</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                    </button>
                </div>
            </div>
            <div class="button-add col-12"
                *ngIf="isEditingAllowed != null && !showAdditionalFields && this.idHealthcareAgreement !== null">
                <button color="accent" color="primary" mat-flat-button type="button"
                    (click)="showAdditionalFields = true;" [disabled]="!isEditingAllowed">
                    Adicionar procedimento solicitado +
                </button>
            </div>
            <div class="button-add col-12"
                *ngIf="isEditingAllowed == null && !showAdditionalFields && this.idHealthcareAgreement !== null">
                <button color="accent" color="primary" mat-flat-button type="button"
                    (click)="showAdditionalFields = true;">
                    Adicionar procedimento solicitado +
                </button>
            </div>
        </div>
    </form>
</div>