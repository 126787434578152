export class CsatModel {
    public idCsat: number;
    
    public idModule: number;

    public idHealthUnit: number;

    public idUser: number;

    public vote: number;

    public voteDescription: string;

    public firstClosing: boolean;

    public secondClosing: boolean;

    public firstAppearence: boolean;

    public datetimeInclusion: Date;
}