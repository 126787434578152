import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TissGuideManagementComponent } from '../tiss-guide-management/tiss-guide-management.component';
import { GuideRequest } from 'src/app/shared/services/requests/private-billing/guide.request';
@Component({
  selector: 'app-tiss-guide-management-floating-window',
  templateUrl: './tiss-guide-management-floating-window.component.html',
  styleUrls: ['./tiss-guide-management-floating-window.component.css']
})
export class TissGuideManagementFloatingWindowComponent {
  constructor(
    public dialog: MatDialog,
  ) { }


  @ViewChild(TissGuideManagementComponent) childComponent: TissGuideManagementComponent;
  @Input() idEpisode: number = null;
  @Input() idPatient: number = null;
  @Input() idRoom: number;
  @Input() idSector: number;
  @Input() patientSocialName: string = null;
  @Input() patientName: string = null;

  public showGuideManagement: boolean = false;

  ngOnInit(): void {

  }

  close() {
  }

  getGuidRequest(): GuideRequest {
    return this.childComponent.getGuidRequest();
  }

  // save(idEpisode: number) {
  //   this.childComponent.post(idEpisode);
  // }

  changeShowTiss() {
    this.showGuideManagement = !this.showGuideManagement;
  }
}
