import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MedicalCareSadtFileService } from 'src/app/shared/services/API/medical-record/medical-care-sadt-file.service';
import { SadtGroupStruct } from 'src/app/shared/services/structs/medical-record/sadt-group.struct';
import { ExamStruct } from 'src/app/shared/services/structs/SADT/exam.struct';
import { ExamService } from 'src/app/shared/services/API/SADT/exam.service';
import { ExamTypeModel } from 'src/app/shared/services/models/SADT/exam-type.model';
import { MedicalCareSadtStruct } from 'src/app/shared/services/structs/medical-record/medical-care-sadt.struct';
import { SadtPlaceEnum } from '../../enum/sadt-place.enum';
import { SadtTypeEnum } from '../../enum/sadt-type.enum';
import { PlaceEnum } from '../../enum/medical-record/plcae.enum';
import { SadtScheduleStruct } from '../../services/structs/medical-record/sadt-scheddule.struct';

@Component({
  selector: 'app-sadt-modal',
  templateUrl: './sadt-modal.component.html',
  styleUrls: ['./sadt-modal.component.css']
})
export class SadtModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<SadtModalComponent>,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private medicalCareSadtFileService: MedicalCareSadtFileService,
    private examService: ExamService,
  ) { }

  public medicalSadt: SadtGroupStruct;
  public sadtModel: FormGroup;
  public selectedSadtList: any[] = [];
  public listLookupExam: ExamStruct[] = [];
  public listExamDisplay: ExamStruct[] = [];
  public listSelectedExamDisplay: ExamStruct[] = [];
  public allConcatSelectedExamNames: string = "";
  public listPlace: any[] = [];
  public idAction: number;
  public idFrequency: number
  public idTreatmentPeriod: number;
  public idSadt: number;
  public sadtOthers: number = 0;
  public fileUrl;
  public isLoading: boolean;
  public listExamType: ExamTypeModel[] = [];
  public listFilteredExamType: ExamTypeModel[] = [];
  public listExamTypeDisplay: ExamTypeModel[] = [];
  public externalPlace: number = 2;
  public examLocked: boolean;
  public isAllExams: boolean;
  public isExternal: boolean = false;
  public isInternal: boolean = false;
  public listSadtSchedule: SadtScheduleStruct[] = [];
  public isMemedPrescription: boolean = false;
  public isDisableSession: boolean = false;

  ngOnInit(): void {
    this.medicalSadt = this.data.sadt;
    this.listExamType = this.data.listExamType;
    this.listFilteredExamType = this.listExamType.filter(x => x.idExamType != SadtTypeEnum.Outros);
    this.listExamTypeDisplay = this.listFilteredExamType;
    this.isAllExams = this.data.isAllExams;
    this.listPlace = this.data.listPlace;
    this.listSadtSchedule = this.data.listSadtSchedule;

    if (this.data.isMemedPrescription != null || this.data.isDisableExternalRequest != null) {
      
      this.isMemedPrescription = this.data.isMemedPrescription;
      this.isDisableSession = this.data.isDisableSession;

      if (this.isMemedPrescription || this.isDisableSession)
        this.listPlace = this.listPlace.filter(place => place.idPlace != PlaceEnum.externo)
    }

    this.sadtModel = this.formBuilder.group({
      idTypeSadt: [{ value: null, disabled: this.data.readOnly }, Validators.required],
      firstChildGroup: this.formBuilder.group({
        idSadt: [{ value: '', disabled: this.data.readOnly }, Validators.required],
      }),
      idPlace: [{ value: null, disabled: this.data.readOnly }, Validators.required],
      sadtDescription: [{ value: '', disabled: this.data.readOnly }],
      observation: [{ value: '', disabled: this.data.readOnly }],
      resultExam: [{ value: '', disabled: this.data.readOnly }],
      sadtResult: [{ value: '', disabled: this.data.readOnly }],
      observationMedic: [{ value: '', disabled: this.data.readOnly }],
      idSadtSchedule: [{ value: null, disabled: this.data.readOnly }],
    });

    if (this.medicalSadt)
      this.populateValues();

    if (this.data.isExternal) {
      this.isExternal = true;
      this.sadtModel.get('idPlace').setValue(SadtPlaceEnum.Externo);
      this.sadtModel.get('idPlace').disable();
      this.onSelectPlaceExternal();
      this.onSelectExamType();
    }

    this.isLoading = false;
  }

  populateValues() {
    this.sadtModel.get('idPlace').setValue(this.medicalSadt.idPlace);
    this.onSelectPlaceExternal();
    this.getLookupSadt(this.medicalSadt.idTypeSadt);

    if (this.medicalSadt.medicalCareSadt && this.medicalSadt.medicalCareSadt.length >= 0) {
      let idSadt: number[] = this.medicalSadt.medicalCareSadt.map(c => c.idSadt);
      this.sadtModel.get('firstChildGroup').get('idSadt').setValue(idSadt);
    }
    else if (this.data.sadt.listMultiProfessionalCareSadt && this.data.sadt.listMultiProfessionalCareSadt.length >= 0) {
      let idSadt: number[] = this.data.sadt.listMultiProfessionalCareSadt.map(c => c.idSadt);
      this.sadtModel.get('firstChildGroup').get('idSadt').setValue(idSadt);
    }

    this.sadtModel.get('idTypeSadt').setValue(this.medicalSadt.idTypeSadt);
    this.sadtModel.get('idSadtSchedule').setValue(this.medicalSadt.idSadtSchedule);
    this.sadtModel.get('sadtDescription').setValue(this.medicalSadt.sadtDescription);
    this.sadtModel.get('observationMedic').setValue(this.medicalSadt.observationMedic);
    this.sadtModel.get('observation').setValue(this.medicalSadt.observation);
    this.sadtModel.get('sadtResult').setValue(this.medicalSadt.sadtResult);

    this.onChangeSadt(this.sadtModel.get('idTypeSadt').value);
  }

  getLookupSadt(idExamType: number) {
    this.examService.listAllExam(idExamType).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.isAllExams = false;
        this.listLookupExam = response.listExam;
        this.listExamDisplay = this.listLookupExam;
        if (this.medicalSadt && this.medicalSadt.medicalCareSadt) {
          this.listSelectedExamDisplay = this.listExamDisplay.filter(c => this.medicalSadt.medicalCareSadt.find(x => x.idSadt == c.idExam));

          if (this.data.readOnly && this.listSelectedExamDisplay) {
            for (let i = 0; i < this.listSelectedExamDisplay.length; i++) {
              this.allConcatSelectedExamNames += this.listSelectedExamDisplay[i].examName + ", ";
            }

            this.allConcatSelectedExamNames = this.allConcatSelectedExamNames.slice(0, -2);
          }
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  onSelectExamType() {
    this.listLookupExam = [];
    this.listExamDisplay = [];
    this.sadtModel.get('firstChildGroup').get('idSadt').setValue([]);
    this.onChangeSadt(this.sadtModel.get('idTypeSadt').value);

    if (this.sadtModel.get('idTypeSadt').value && this.sadtModel.get('idTypeSadt').value != this.sadtOthers)
      this.getLookupSadt(this.sadtModel.get('idTypeSadt').value);
  }

  onSelectPlaceExternal() {
    if (this.sadtModel.get('idPlace').value == this.externalPlace) {
      this.listExamTypeDisplay = this.listExamType;
      this.isInternal = false;
      this.sadtModel.get('idSadtSchedule').setValue(null);
    }
    else {
      this.sadtModel.get('idTypeSadt').setValue(null);
      this.listExamTypeDisplay = this.listFilteredExamType;
      this.isInternal = true;
    }
  }

  clickCancel() {
    this.matDialogRef.close();
  }

  onSubmit() {
    if (this.sadtModel.invalid) {
      this.alertService.show('Erro', "Preencha todos os campos obrigatórios!", AlertType.error);
      return;
    }

    let newSadt: SadtGroupStruct = new SadtGroupStruct();
    let idSadts: number[] = this.sadtModel.get('firstChildGroup').get('idSadt').value;

    let medicalCareSadt: MedicalCareSadtStruct;
    if (idSadts && idSadts.length >= 1) {
      newSadt.medicalCareSadt = idSadts.reduce((acc: MedicalCareSadtStruct[], cur: number) => {
        if (cur != 0) {
          medicalCareSadt = new MedicalCareSadtStruct;
          medicalCareSadt.idSadt = cur;
          medicalCareSadt.sadtName = this.listLookupExam.find(c => c.idExam == cur).examName;
          acc.push(medicalCareSadt);
        }
        return acc;
      },
        []);
    }

    newSadt.idTypeSadt = this.sadtModel.get('idTypeSadt').value;
    newSadt.typeSadtName = this.listExamType.find(c => c.idExamType == newSadt.idTypeSadt).examTypeName;
    newSadt.idPlace = this.sadtModel.get('idPlace').value;
    newSadt.placeName = this.listPlace.find(c => c.idPlace == newSadt.idPlace).placeName;
    newSadt.idStatus = 1;
    newSadt.statusName = "Pedido";
    newSadt.observationMedic = this.sadtModel.get('observationMedic').value;
    newSadt.sadtDescription = this.sadtModel.get('sadtDescription').value;
    newSadt.idSadtSchedule = this.sadtModel.get('idSadtSchedule').value;

    if (newSadt.idSadtSchedule != null)
      newSadt.sadtScheduleName = this.listSadtSchedule.find(x => x.idSadtSchedule === this.sadtModel.get('idSadtSchedule').value).sadtScheduleName;

    this.matDialogRef.close({ newSadt });
  }

  onChangeSadt(event: number) {
    this.idSadt = event;

    if (this.idSadt == this.sadtOthers) {
      this.sadtModel.get('sadtDescription').setValidators(Validators.required);
      this.sadtModel.get('firstChildGroup').get('idSadt').setValidators(Validators.nullValidator);
    }
    else {
      this.sadtModel.get('sadtDescription').setValidators(Validators.nullValidator);
      this.sadtModel.get('firstChildGroup').get('idSadt').setValidators(Validators.required);
    }

    this.sadtModel.get('sadtDescription').updateValueAndValidity();
    this.sadtModel.get('firstChildGroup').get('idSadt').updateValueAndValidity();
  }

  downloadFile(idSadtResultFile: number) {
    if (this.isLoading)
      return;

    this.isLoading = true;
    this.medicalCareSadtFileService.getMedicalCareSadtFile(idSadtResultFile).subscribe({
      next: (response) => {
        if (response.isError) {
          this.showAlert('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        else {
          const fileSadt = response.fileSadt;
          if (!fileSadt)
            this.showAlert('Erro', 'O arquivo não está disponível.', AlertType.error);
          else {
            const fileExtension = this.getFileExtension(fileSadt.fileNameSadt);
            if (this.isImageExtension(fileExtension))
              this.openFileInNewTab(fileSadt.sadtFile64, `image/${fileExtension}`);
            else if (fileExtension === 'pdf')
              this.openFileInNewTab(fileSadt.sadtFile64, 'application/pdf');
            else
              this.downloadFileBlob(fileSadt.sadtFile64, fileExtension, fileSadt.fileNameSadt);
          }
        }

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.showAlert('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  private showAlert(title: string, message: string, alertType: AlertType) {
    this.alertService.show(title, message, alertType);
  }

  private getFileExtension(fileName: string): string {
    const nameArray = fileName.split(".");
    return nameArray[nameArray.length - 1].toLowerCase();
  }

  private isImageExtension(extension: string): boolean {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
    return imageExtensions.includes(extension);
  }

  private openFileInNewTab(base64Data: string, contentType: string) {
    const blob = this.base64ToBlob(base64Data, contentType);
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');
  }

  private base64ToBlob(b64Data: string, contentType = '', sliceSize = 512): Blob {
    b64Data = b64Data.replace(/\s/g, ''); // IE compatibility...
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  private downloadFileBlob(base64Data: string, fileExtension: string, fileName: string) {
    const blob = this.base64ToBlob(base64Data, `application/${fileExtension}`);
    const blobUrl = URL.createObjectURL(blob);

    const anchor = document.createElement('a');
    anchor.href = blobUrl;
    anchor.download = fileName;
    anchor.style.display = 'none';

    document.body.appendChild(anchor);
    anchor.click();

    // Clean up
    document.body.removeChild(anchor);
    URL.revokeObjectURL(blobUrl);
  }

  onToggleChange(event: any) {
    if (event.checked)
      this.sadtModel.get('firstChildGroup').get('idSadt').setValue(this.listLookupExam.map(l => l.idExam));
    else
      this.sadtModel.get('firstChildGroup').get('idSadt').setValue([]);

    this.sadtModel.get('firstChildGroup').get('idSadt').updateValueAndValidity();
  }

  selectedValue(event: any) {
    if (event.length == this.listLookupExam.length)
      this.isAllExams = true;
    else
      this.isAllExams = false;
  }
}