import { Validators } from "@angular/forms";
import { VerifyCPF } from "src/app/shared/custom-validators/cpf.validator";
import { FormMetaData } from "src/app/shared/structs/form-meta-data.struct";
import { MaskService } from "../../../mask.service";

export class SafeIDClientAuthRequest {
    public login_hint: string = undefined;
    public client_id: string = undefined;
    public redirect_uri: string = undefined;
    public lifetime: number = undefined;
    public state: string = undefined;

    public static metadata(): Map<string, FormMetaData> {
        let masks = MaskService.getMasks();
        let metaData = new Map<string, FormMetaData>();
        metaData['login_hint'] = new FormMetaData('CPF', [Validators.required, VerifyCPF()], undefined, undefined, masks.cpf, false);
        return metaData;
    }
}