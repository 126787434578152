import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CommonService } from "src/app/shared/services/common-service";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';
import { catchError } from "rxjs/operators";
import { ReturnStruct } from "src/app/shared/structs/return.struct";
import { CareLinePriorityStatusRequest } from "../../requests/medical-record/care-line-priority-status.request";
import { CareLinePriorityStatusResponse } from "../../responses/medical-record/care-line-priority-status.response";

@Injectable({
    providedIn: 'root'
})
export class CareLinePiorityStatusService extends CommonService {

    constructor(
        private router: Router, private httpClient: HttpClient
    ) {
        super();
    }

    public updateCareLinePriorityStatus(idEpisode: number, body: CareLinePriorityStatusRequest): Observable<CareLinePriorityStatusResponse> {
        let uri = `CareLinePriorityStatus/idEpisode/${idEpisode}`

        return this.httpClient.put<CareLinePriorityStatusResponse>(environment.urlApiMedicalRecord + uri, body, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }
}