import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CommonService } from "src/app/shared/services/common-service";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';
import { catchError } from "rxjs/operators";
import { ActiveMedicationStockReportRequest as ActiveMedicationReportRequest } from "../../requests/Report/active-medication-stock-report.request";
import { ReportResponse } from "../../responses/orchestrator-patient/report.response";

@Injectable({
  providedIn: 'root'
})
export class PharmacyService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  getReport(body: ActiveMedicationReportRequest): Observable<ReportResponse> {
    let uri = `Pharmacy/ActiveMedicationStock`;

    return this.httpClient.post<ReportResponse>(environment.urlApiOrchestratorPatient + uri, body, this.addHeaderToken())
      .pipe(catchError(this.handleError))
  }
}