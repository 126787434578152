import { Injectable } from "@angular/core";
import { CommonService } from "../../common-service";
import { environment } from "src/environments/environment";
import { catchError } from 'rxjs/internal/operators/catchError';
import { ReturnStruct } from "src/app/shared/structs/return.struct";
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient } from "@angular/common/http";
import { GuideRequest } from "../../requests/private-billing/guide.request";
import { GuideReportResponse } from "../../responses/orchestrator-patient/guide-report.response";

@Injectable({
    providedIn: 'root'
  })
  export class OrchestratorPatientGuideService extends CommonService {
  
    constructor(private httpClient: HttpClient) {
      super();
    }
  
    public PostAdmissionGuide(request: GuideRequest): Observable<GuideReportResponse> {
      let uri = `Guide/admissionGuide`
  
      return this.httpClient.post<GuideReportResponse>(environment.urlApiOrchestratorPatient + uri, request, this.addHeaderToken())
        .pipe(catchError(this.handleError));
    }

    public PostAppointmentGuide(request: GuideRequest): Observable<GuideReportResponse> {
        let uri = `Guide/appointmentGuide`
    
        return this.httpClient.post<GuideReportResponse>(environment.urlApiOrchestratorPatient + uri, request, this.addHeaderToken())
          .pipe(catchError(this.handleError));
    }

    public PostSpSadtGuide(request: GuideRequest): Observable<GuideReportResponse> {
        let uri = `Guide/SpSadtGuide`
    
        return this.httpClient.post<GuideReportResponse>(environment.urlApiOrchestratorPatient + uri, request, this.addHeaderToken())
          .pipe(catchError(this.handleError));
    }

    public PostFeesGuide(request: GuideRequest): Observable<GuideReportResponse> {
        let uri = `Guide/FeesGuide`
    
        return this.httpClient.post<GuideReportResponse>(environment.urlApiOrchestratorPatient + uri, request, this.addHeaderToken())
          .pipe(catchError(this.handleError));
    }
  }