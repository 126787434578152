import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertService, AlertType } from '../../services/alert.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MaskService, Masks } from '../../services/mask.service';
import { CellPhoneValidator } from '../../custom-validators/phone.validator';

@Component({
  selector: 'app-get-phone-modal',
  templateUrl: './get-phone-modal.component.html',
  styleUrls: ['./get-phone-modal.component.css']
})
export class GetPhoneModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<GetPhoneModalComponent>,
    public sanitizer: DomSanitizer,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private maskService: MaskService,
  ) { }

  public body: string = "";
  public confirm: boolean = false;
  public model: FormGroup;
  public isLoading: boolean = false;
  public masks: Masks;

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      phone: ['', [Validators.required, CellPhoneValidator()]],
    });

  }

  submit() {
    if (this.isLoading)
      return;

    this.isLoading = true;
    if (this.model.invalid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      this.isLoading = false;
      return;
    }

    this.confirm = true;
    this.matDialogRef.close({ phone: this.maskService.formatPhoneToSave(this.model.get('phone').value) });
  }

  close() {
    this.matDialogRef.close();
  }
}