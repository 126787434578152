import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { AttendPatientRequest } from '../../requests/orchestrator-queue/attend-patient.request';
import { AttendPatientResponse } from '../../responses/orchestrator-queue/attend-patient.response';

@Injectable({
  providedIn: 'root'
})
export class AttendPatientService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public attendPatient(attendPatientRequest : AttendPatientRequest, idRoom: number, isCheckAttendance: boolean, anotherRoom: boolean = false): Observable<AttendPatientResponse> {
    let uri = `AttendPatient/idRoom/${idRoom}/isCheckAttendance/${isCheckAttendance}?`

    if(anotherRoom)
      uri = uri+`anotherRoom=${anotherRoom}`;    
    

    return this.httpClient.post<AttendPatientResponse>(environment.urlApiOrchestratorQueue + uri, attendPatientRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public restrictedAttendPatient(attendPatientRequest: AttendPatientRequest): Observable<AttendPatientResponse> {
    let uri = `AttendPatient`
      
    return this.httpClient.post<AttendPatientResponse>(environment.urlApiOrchestratorQueue + uri, attendPatientRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
