import { RoomMeasurerModel } from "../../models/risk-classification/room-measurer.model";

export class RoomMeasurerStruct extends RoomMeasurerModel {
    public idTypeMeasurer: number;
    public idDeviceType: number;
    public idOximeter: number;    
    public idThermometer: number;    
    public idGlucometer: number;    
    public idPressureMeasurer: number;    
    public uri: string = "";
    public macAddresss: string = "";
    public isBluetooth: boolean = false;
}