import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EpisodeService } from 'src/app/shared/services/API/medical-record/episode.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
@Component({
  selector: 'app-companion-post-presence-info',
  templateUrl: './companion-post-presence-info.component.html',
  styleUrls: ['./companion-post-presence-info.component.css']
})
export class CompanionPostPresenceInfoComponent implements OnInit{

constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
public dialog: MatDialog, 
public matDialogRef: MatDialogRef<CompanionPostPresenceInfoComponent>,
private alertService: AlertService,
private episodeService: EpisodeService,
private formBuilder: UntypedFormBuilder,
) { }

public isLoading: boolean;
public idEpisode: number;
public model: FormGroup;
public companionName: string;

public isClosed: boolean = false;
ngOnInit(): void {
  this.isLoading = false;
  this.idEpisode = this.data.postPresenceReportRequest.idEpisode;

  this.model = this.formBuilder.group({
    companionPresenceReport: ['']
  });

  this.populateCompanionData(this.idEpisode);
  
}

populateCompanionData(idEpisode: number) {
  this.isLoading = true;
  this.episodeService.getPatientByEpisode(idEpisode).subscribe({
    next: (response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      if(response.episodeStruct.companionName != null)
      {
        this.model.get('companionPresenceReport').setValue(response.episodeStruct.companionName);
      }

      this.isLoading = false;

    },
    error: (error) => {
      console.log(error);
    }
  });
}

submit()
{
  if(this.isLoading)
  return;
  
  this.isClosed = true;
  this.companionName = this.model.get('companionPresenceReport').value;
  this.matDialogRef.close({companionName:this.companionName, isClosed: this.isClosed});
}

ngOnDestroy() {
  this.matDialogRef.close({companionName:this.companionName, isClosed: this.isClosed});
}
}
