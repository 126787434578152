export class SpSadtGuide {
    public idSpSadtGuide: number;
    public idRequesterProviderIdentityType: number;
    public isDeleted: boolean;
    public ansRegistration: string;
    public mainGuideNumber: number;
    public idMainGuide: number;
    public authorizationDate: Date;
    public password: string;
    public passwordExpirationDate: Date;
    public guideNumberAssignedByTheOperator: string;
    public beneficiaryDocumentNumber: string;
    public documentExpirationDate: Date;
    public beneficiarySocialName: string;
    public beneficiaryName: string;
    public newbornAttendanceIndicator: boolean;
    public requesterContractorOperatorCode: string;
    public requesterContractorName: string;
    public requesterProfessionalName: string;
    public idRequesterProfessionalCouncil: number;
    public requesterProfessionalCouncilNumber: string;
    public idRequesterProfessionalCouncilState: number;
    public idRequesterProfessionalCbo: number;
    public idAttendanceAspect: number;
    public requestDate: Date;
    public clinicalIndication: string;
    public idSpecialCoverage: number;
    public idProviderIdentityType: number;
    public executantContractorOperatorCode: string;
    public executantContractorName: string;
    public executantCnes: string;
    public idAttendanceType: number;
    public idAccidentIndication: number;
    public idAppointmentType: number;
    public idTerminationReason: number;
    public idCareRegime: number;
    public idOccupationalHealth: number;
    public observationJustification: string;
    public totalProceduresValue: number;
    public totalFeesRentalsValue: number;
    public totalMaterialsValue: number;
    public totalOpmeValue: number;
    public totalMedicationsValue: number;
    public totalMedicinalGasesValue: number;
    public totalGeneralValue: number;
    public idProfessionalAgreement: number;
}