import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { TasksQueuesRequest } from '../../services/requests/orchestrator-integration/tasks-queues.request';
import { TasksQueuesModel } from '../../services/models/integration/tasks-queues.model';
import { TasksQueuesService } from '../../services/API/orchestrator-integration/tasks-queues.service';

@Component({
  selector: 'app-tasks-queues-modal',
  templateUrl: './tasks-queues-modal.component.html',
  styleUrls: ['./tasks-queues-modal.component.css']
})
export class TasksQueuesModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: TasksQueuesModel,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<TasksQueuesModalComponent>,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private tasksQueuesService: TasksQueuesService
  ) { }

  public model: FormGroup;
  public isLoading: boolean = false;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      maxAttempts: this.data.maxAttempts,
      maxBackoff: this.data.maxBackoff,
      maxConcurrentDispatches: this.data.maxConcurrentDispatches,
      maxDispatchesPerSecond: this.data.maxDispatchesPerSecond,
      maxDoublings: this.data.maxDoublings,
      maxRetryDuration: this.data.maxRetryDuration,
      minBackoff: this.data.minBackoff,
    });
  }

  clickCancel() {
    this.matDialogRef.close();
  }
  close() {
    this.matDialogRef.close();
  }

  onSubmit() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    let idTasksQueues = this.data.idTasksQueues;
    let request: TasksQueuesRequest = {
      idTasksQueues: idTasksQueues,
      maxAttempts: this.model.get('maxAttempts').value,
      maxBackoff: this.model.get('maxBackoff').value,
      maxConcurrentDispatches: this.model.get('maxConcurrentDispatches').value,
      maxDispatchesPerSecond: this.model.get('maxDispatchesPerSecond').value,
      maxDoublings: this.model.get('maxDoublings').value,
      maxRetryDuration: this.model.get('maxRetryDuration').value,
      minBackoff: this.model.get('minBackoff').value,
    };
    this.tasksQueuesService.saveTasksQueues(request).subscribe({
      next: (response) => {
        this.isLoading = false;
        if (response.isError) {
          this.alertService.show("Houve um erro", response.errorDescription, AlertType.error);
          return;
        }
        this.alertService.show("Sucesso", "Fila de envio atualizado com sucesso!", AlertType.success);
        this.matDialogRef.close(request);
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show("Houve um erro", error, AlertType.error);
      }
    });
  }
}