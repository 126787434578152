import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ReturnToQueueResponse } from '../../responses/queue/return-to-queue.response';
import { ReturnToQueueRequest } from '../../requests/queue/return-to-queue.request';

@Injectable({
    providedIn: 'root'
})
export class ReturnToQueueService extends CommonService {

    constructor(private httpClient: HttpClient) {
        super();
    }

    public checkReturnability(idEpisode: number, maxReturns: number): Observable<ReturnToQueueResponse> {
        let uri = `ReturnToQueue/idEpisode/${idEpisode}/maxReturns/${maxReturns}`

        return this.httpClient.get<ReturnToQueueResponse>(environment.urlApiQueue + uri, this.addHeaderToken())
            .pipe(catchError(this.handleError));
    }

    public create(request: ReturnToQueueRequest): Observable<ReturnStruct> {
        let uri = `ReturnToQueue`;

        return this.httpClient.post<ReturnStruct>(environment.urlApiQueue + uri, request, this.addHeaderToken())
            .pipe(catchError(this.handleError));
    }

    public removeReturnToQueue(idEpisode: number): Observable<ReturnStruct> {
        let uri = `ReturnToQueue/idEpisode/${idEpisode}`;

        return this.httpClient.delete<ReturnStruct>(environment.urlApiQueue + uri, this.addHeaderToken())
            .pipe(catchError(this.handleError));
    }
}