import { Component, Inject, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FilterSelectModel } from '../filter-select.model';

@Component({
  selector: 'app-filter-select-modal',
  templateUrl: './filter-select-modal.component.html',
  styleUrls: ['./filter-select-modal.component.css']
})
export class FilterSelectModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, public matDialogRef: MatDialogRef<FilterSelectModalComponent>) { }

  public filterSelectModel: FilterSelectModel;

  ngOnInit(): void {
    this.filterSelectModel = this.data.filterSelectModel;
  }

  close() {
    this.matDialogRef.close({ searchAction: false });
  }

  clickSearch() {
    this.matDialogRef.close({ searchAction: true });
  }

  clickCancel() {
    this.matDialogRef.close({ searchAction: false });
  }

  onCheckChange(event: MatCheckboxChange) {
    this.filterSelectModel.itemSelectModel.forEach(element => {
      if (element.key == event.source.value)
        element.checked = event.checked;
    });
  }
}
