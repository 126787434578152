<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-confirm-modal-list">
            <h3>{{this.data && this.data.deniesAllergies ? 'Realmente deseja retirar todas as alergias deste
                paciente?!':'Deseja remover a alergia?' }} </h3>
        </div>
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="body-confirm-modal-list">
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Motivo</mat-label>
                            <textarea matInput formControlName="motive"></textarea>
                            <mat-error *ngIf="model.get('motive').invalid">Informe o motivo da remoção!</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="footer-confirm-modal-list">
                <button mat-flat-button color="primary" class="btn-block" type="submit">
                    <span>Sim, remover</span>
                </button>
                <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()"
                    type="button">Cancelar</button>
            </div>
        </form>
    </div>
</div>