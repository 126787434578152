<div class="card-container row">
    <app-loading-list *ngIf="isLoading"></app-loading-list>
    <div class="row">
        <div class="col-md-4 col-lg-3 col-sm-6 col-12"
            *ngFor="let group of moduleStruct.listFunctionalityGroup; let i = index">
            <a class="card-item-group" (click)="expand(group.idFunctionalityGroup, i)">
                <mat-icon class="card-icon" aria-hidden="false" aria-label="Menu grupo">{{group.iconClass}}</mat-icon>
                <span class="module-names">{{group.functionalityGroupName}}</span>
                <mat-icon aria-hidden="false" aria-label="Menu grupo">expand_more</mat-icon>
            </a>
            <div id={{group.idFunctionalityGroup}} class="list-functionality">

                <a class="functionality-sub-menu" (click)="redirect(item)"
                    *ngFor="let item of groupedFunctionalityStructs[groupIndices[group.idFunctionalityGroup]]">
                    <mat-icon aria-hidden="false" aria-label="Menu grupo">{{item.iconClass}}</mat-icon>
                    <span class="module-names">{{item.functionalityName}}</span>
                    <mat-icon aria-hidden="false" aria-label="Menu grupo">chevron_right</mat-icon>
                </a>

            </div>
        </div>
        <div class="col-md-4 col-lg-3 col-sm-6 col-12" *ngFor="let item of standaloneFunctionalityStructs">
            <a class="card-item" (click)="redirect(item)">
                <mat-icon class="card-icon" aria-hidden="false" aria-label="Menu grupo">{{item.iconClass}}</mat-icon>
                <span class="module-names">{{item.functionalityName}}</span>
                <mat-icon  [ngStyle]="{'visibility':'hidden'}" aria-hidden="false"
                    aria-label="Menu grupo">chevron_right</mat-icon>
            </a>
        </div>
    </div>
</div>