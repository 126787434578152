import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../services/alert.service';
import { CareLineModel } from '../../services/models/care-line/care-line.model';
import { EpisodeCareLineStruct } from '../../services/structs/care-line/episode-care-line.struct';
import { VerifyPhoneNumber } from '../../custom-validators/phone.validator';
import { Masks } from '../../services/mask.service';

@Component({
  selector: 'app-care-line-modal',
  templateUrl: './care-line-modal.component.html',
  styleUrls: ['./care-line-modal.component.css']
})


export class CareLineModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialogRef: MatDialogRef<CareLineModalComponent>,
    private activatedRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private dialog: MatDialog,
  ) { }

  public masks: Masks;
  public model: FormGroup;
  public idEpisode: number;
  public listCareLine: CareLineModel[];
  public listPastEpisodeCareLine: EpisodeCareLineStruct[];
  public listCurrentEpisodeCareLine: EpisodeCareLineStruct[];
  public listBlockedCareLine: number[];
  public patientPhoneNumber: string = "";
  public countryCode: string = "";

  ngOnInit(): void {
    this.masks = this.data.masks;
    this.idEpisode = this.data.idEpisode;
    this.listCareLine = this.data.listCareLine;
    this.listPastEpisodeCareLine = this.data.listPastEpisodeCareLine;
    this.listCurrentEpisodeCareLine = this.data.listCurrentEpisodeCareLine;
    this.listBlockedCareLine = this.listCurrentEpisodeCareLine.map(x => x.idCareLine);
    this.patientPhoneNumber = this.data.patientPhoneNumber;

    this.model = this.formBuilder.group({
      listIdCareLine: [null],
      phoneNumber: [this.data.patientPhoneNumber, [Validators.required, VerifyPhoneNumber()]],
      countryCode: [this.data.countryCode != null ? this.data.countryCode : '+55', [Validators.required]]
    });
  }

  submit() {
    if (this.model.invalid) {
      return;
    }

    let newEpisodeCareLines: EpisodeCareLineStruct[] = [];

    this.model.get('listIdCareLine').value.forEach(idCareLine => {
      let careLine = this.listCareLine.find(x => x.idCareLine == idCareLine);
      let episodeCareLine = new EpisodeCareLineStruct(careLine, this.idEpisode);
      newEpisodeCareLines.push(episodeCareLine);
    });

    this.patientPhoneNumber = this.model.get('phoneNumber').value;
    this.countryCode = this.model.get('countryCode').value;

    this.matDialogRef.close({
      newEpisodeCareLines: newEpisodeCareLines,
      patientPhoneNumber: this.patientPhoneNumber,
      countryCode: this.countryCode,
    });
  }

  clickCancel() {
    this.matDialogRef.close({});
  }
}
