import { CdkAccordionItem } from '@angular/cdk/accordion';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PatientCareLinePriorityStruct } from '../../services/structs/care-line-priority/patient-care-line-priority-struct';
import { DiscriminatorTypeEnum } from '../../enum/discriminator-type.enum';
import { UtilsClassificationService } from 'src/app/classification/utils.service';

@Component({
  selector: 'app-secundary-header-v2',
  templateUrl: './secundary-header-v2.component.html',
  styleUrls: ['./secundary-header-v2.component.css']
})
export class SecundaryHeaderV2Component implements OnInit {


  constructor(
    private eRef: ElementRef,
    private utilService: UtilsClassificationService,
  ) { }

  @Input() name: string;
  @Input() discriminators: [];
  @Input() listAllergyStruct: [];
  @Input() socialName: string;
  @Input() birthDate: Date;
  @Input() formatedBirth: string;
  @Input() age: string;
  @Input() patientCareLinePriorityStruct: PatientCareLinePriorityStruct;
  @Input() isClassification: boolean = false;
  @Output() restartMeasurers: EventEmitter<any> = new EventEmitter<any>();

  public discriminatorTypeEnum: any = DiscriminatorTypeEnum;


  @ViewChild('accordionItem', { static: true }) accordionItemChild: CdkAccordionItem;

  ngOnInit(): void {
    let data = this.utilService.getClassificationData();
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.accordionItemChild.close();
    }
  }

  reloadHeader(discriminatorValues) {
    this.discriminators = discriminatorValues;
  }

  emitMeasurerRestart() {
    this.restartMeasurers.emit();
  }
}
