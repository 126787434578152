import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { UserInfosResponse } from '../../responses/user/user-infos.response';

@Injectable({
    providedIn: 'root'
})
export class GetUserService extends CommonService {

    constructor(private router: Router, private httpClient: HttpClient) {
        super();
    }

    public getUser(): Observable<UserInfosResponse> {

        return this.httpClient.get<UserInfosResponse>(environment.urlApiUser + "GetUser", this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }
}