import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { SearchFlowchartRequest } from '../../requests/risk-classification/search-flowchart.request';
import { FlowchartResponse } from '../../responses/risk-classification/flowchart.response';
import { GetFlowchartResponse } from '../../responses/risk-classification/get-flowchart.response';
import { retry, timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FlowchartService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  getFlowchart(searchFlowchartRequest: SearchFlowchartRequest): Observable<FlowchartResponse> {
    let uri = `Flowchart`

    return this.httpClient.post<FlowchartResponse>(environment.urlApiRiskClassification + uri, searchFlowchartRequest, this.addHeaderToken())
      .pipe(
        timeout(environment.requestTimeOut),
        retry(environment.requestRetry),
        catchError(this.handleError)
      )
  }

  getTheFlowchart(idFlowchart: number): Observable<GetFlowchartResponse> {
    let uri = `Flowchart/idFlowchart/${idFlowchart}`

    return this.httpClient.get<GetFlowchartResponse>(environment.urlApiRiskClassification + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
