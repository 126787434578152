<div mat-dialog-content>
    <div class="mat-dialog-content">

        <div class="head-confirm-modal-list">
            <h1><b>Deixe que o médico finalize a consulta!</b></h1>
        </div>

        <div class="body-confirm-modal-list">
            Sua consulta será finalizada automaticamente pelo médico assim que ele concluir o seu atendimento.
            <br>
            <br>
            <b>Se você optar por sair agora, o seu atendimento será perdido.</b>
        </div>
        <div class="footer-confirm-modal-list">
            <button mat-flat-button color="primary" class="block" (click)="clickCancel()">
                <span>AGUARDAR</span>
            </button>
            <button mat-flat-button color="accent" class="block" (click)="clickConfirm()">
                <span>FINALIZAR CONSULTA</span>
            </button>
        </div>

    </div>
</div>