import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DigitalSignatureFlowEnum } from 'src/app/shared/enum/digital-signature-flow.enum';
import { SignatureProviderEnum } from 'src/app/shared/enum/signature-providers/signature-providers.enum';
import { SafeIDSignatureService } from 'src/app/shared/services/API/digital-signature/safe-id.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DigitalSignatureUtilService } from 'src/app/shared/services/digital-signature-util.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { WebsocketDigitalSignatureService } from 'src/app/shared/services/websocket-digital-signature.service';
import { FormMetaData } from 'src/app/shared/structs/form-meta-data.struct';
import { environment } from 'src/environments/environment';
import { SafeIDClientRequest } from 'src/app/shared/services/requests/digital-signature/safe-id/safe-id-client.request';
import { SafeIDClientAuthRequest } from 'src/app/shared/services/requests/digital-signature/safe-id/safe-id-client-auth.request';
import { SafeIDCredentialsRequest } from 'src/app/shared/services/requests/digital-signature/safe-id/safe-id-credentials.request';
import { KeyValue } from '@angular/common';
import { DigitalSignatureService } from 'src/app/shared/services/API/digital-signature/digital-signature.service';
import { SafeIDCredentialsResponse } from 'src/app/shared/services/responses/digital-signature/safe-id/safe-id-credentials.response';
import { GetUserService } from 'src/app/shared/services/API/user/get-user.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertModalComponent } from '../../../alert-modal/alert-modal.component';
import { SafeIDVidaaSSignatureStruct } from 'src/app/shared/services/structs/digital-signature/safe-id/safe-id-vidaas-signature-struct.struct';

@Component({
  selector: 'app-safeID-authenticator',
  templateUrl: './safeID-authenticator.component.html',
  styleUrls: ['./safeID-authenticator.component.css']
})
export class SafeIDAuthenticatorComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: FormBuilder,
    private safeIDSignatureService: SafeIDSignatureService,
    public dialog: MatDialog,
    private digiatlSignatureService: DigitalSignatureService,
    private getUserService: GetUserService,
    private utilService: UtilService,
    private signatureUtilService: DigitalSignatureUtilService,
    private alertService: AlertService,
    private websocketDigitalSignatureService: WebsocketDigitalSignatureService,
    private authService: AuthService) { }

  public state: number;
  public model: FormGroup;
  public stateEnum = DigitalSignatureFlowEnum;
  public formMetaData = new Map<string, FormMetaData>();
  public title: string = 'Cadastro';
  public description: string = 'Teste';
  public ready: boolean = false;

  @Input() isLoading: boolean;
  @Input() isMenu: boolean;
  @Output() goBack = new EventEmitter<number>();
  @Output() setStatusIcon = new EventEmitter<any>();
  @Output() credentialsRequest = new EventEmitter<SafeIDCredentialsResponse>();

  private socket: any;

  ngOnInit(): void {
    let signatureStruct = this.signatureUtilService.getSignatureStruct(SignatureProviderEnum.safeID);

    if (!signatureStruct ||
      !signatureStruct.client_id ||
      !signatureStruct.client_secret) {
      this.state = DigitalSignatureFlowEnum.uninitiated;
    }

    else if (!signatureStruct.identifierCA)
      this.state = DigitalSignatureFlowEnum.registered;
    else if (!signatureStruct.access_token)
      this.state = DigitalSignatureFlowEnum.authenticated;
    else
      this.state = DigitalSignatureFlowEnum.signable;

    this.startWebsocket();
    this.changeState(this.state);
  }

  ngOnDestroy(): void {
    if (this.socket)
      this.socket.close();
  }

  //Websocket
  startWebsocket() {
    let login = this.authService.getTokenMenu().login
    this.socket = this.websocketDigitalSignatureService.websocketConnection();
    this.socket.emit('join', `login-${login}`)
      .on("clientAppAuth", (res) => this.authenticateApp(res))
      .io.on("reconnect", (res) => {
        this.socket.emit('join', `login-${login}`);
      });
  }

  //Functionalities
  back() {
    this.goBack.emit(SignatureProviderEnum.safeID);
  }

  changeState(state: number) {
    this.ready = false;
    this.state = state;
    this.model = this.formBuilder.group({});

    switch (state) {
      case DigitalSignatureFlowEnum.uninitiated:
        this.title = 'Cadastro de aplicação';
        this.description = '';
        this.formMetaData = SafeIDClientRequest.metadata();
        if (this.formMetaData) {
          this.buildForm();
        }
        this.setStatusIcon.emit({icon: 'block', description: 'Clique para habilitar seu certificado!'});
        break;

      case DigitalSignatureFlowEnum.registered:
        this.title = 'Autenticar aplicação';
        this.description = '';
        this.formMetaData = SafeIDClientAuthRequest.metadata();
        if (this.formMetaData) {
          this.buildForm();
        }
        this.getUser();
        this.setStatusIcon.emit({icon: 'phonelink_setup', description: 'E-mail vinculado! Continue o processo de autenticação.'});
        break;

      case DigitalSignatureFlowEnum.awatingConfirmation:
        this.title = 'Aceitar aplicação confiável';
        this.description = 'Clique na notificação que o aplicativo SafeID enviou para o seu celular e confirme a aplicação ToLife como confiável. <br><b>Importante:</b> você precisa clicar na notificação. Caso contrário, não conseguirá visualizar a tela para liberar a ToLife como aplicativo confiável.';
        this.formMetaData = null;
        if (this.formMetaData) {
          this.buildForm();
        }
        this.setStatusIcon.emit({icon: 'security_update_warning', description: 'Liberação pendente! Verifique o app do certificado.'});
        break;

      case DigitalSignatureFlowEnum.authenticated:
        if (this.isMenu) {
          this.title = 'Aplicação autenticada com sucesso!';
          this.formMetaData = null;
          if (this.formMetaData) {
            this.buildForm();
          }
        }

        else {
          this.title = 'Autenticar credenciais';
          this.formMetaData = SafeIDCredentialsRequest.metadata();
          if (this.formMetaData) {
            this.buildForm();
          }
          this.getUser();
        }

        this.description = '';
        this.setStatusIcon.emit({icon: 'mobile_friendly', description: 'Apto para assinar com a senha do certificado.'});
        break;

      case DigitalSignatureFlowEnum.signable:
        this.title = 'Tudo pronto!';
        this.description = '';
        this.formMetaData = null;
        this.setStatusIcon.emit({icon: 'verified', description: 'Apto para assinar!'});
        break;

      default:
        break;
    }

    this.ready = true;
  }

  getUser() {
    this.isLoading = true;
    this.getUserService.getUser().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        
        if (this.model.contains('login_hint')) {
          this.model.get('login_hint').setValue(response.userInfos.cpf);
          this.model.get('login_hint').disable();
        }

        if (this.model.contains('username')) {
          this.model.get('username').setValue(response.userInfos.cpf);
          this.model.get('username').disable();
        }

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  authenticateApp(response: SafeIDVidaaSSignatureStruct) {
    this.signatureUtilService.setSignatureStruct(SignatureProviderEnum.safeID, response);
    this.changeState(DigitalSignatureFlowEnum.authenticated);
    this.alertService.show('Sucesso!', 'Aplicação autenticada com sucesso', AlertType.success);
  }

  toggleVisibility(key: string) {
    this.formMetaData[key].hide = !this.formMetaData[key].hide;
  }

  submit() {
    if (this.model.invalid)
      return;

    switch (this.state) {
      case DigitalSignatureFlowEnum.uninitiated:
        this.generateClientApp();
        break;
      case DigitalSignatureFlowEnum.registered:
        this.clientAppAuth();
        break;
      case DigitalSignatureFlowEnum.authenticated:
        this.AuthenticateCredentials();
        break;
    }
  }

  //Requests
  generateClientApp() {
    this.isLoading = true;

    let request = new SafeIDClientRequest();
    request = this.readForm(request);

    this.safeIDSignatureService.createClient(request).subscribe({
      next: (response) => {
        let signatureStruct = this.signatureUtilService.getSignatureStruct(SignatureProviderEnum.safeID);

        if (signatureStruct == null) 
          signatureStruct = new SafeIDVidaaSSignatureStruct();

        signatureStruct.client_id = response.client_id;
        signatureStruct.client_secret = response.client_secret;

        this.signatureUtilService.setSignatureStruct(SignatureProviderEnum.safeID, signatureStruct);

        this.changeState(DigitalSignatureFlowEnum.registered);
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
      }
    });
  }

  clientAppAuth() {
    this.isLoading = true;

    let request = new SafeIDClientAuthRequest();
    request = this.readForm(request);

    let token = this.utilService.getToken();
    request.state = `${token.login};${environment.authorizationHash}`;

    this.safeIDSignatureService.createClientAuth(request).subscribe({
      next: (response) => {
        this.changeState(DigitalSignatureFlowEnum.awatingConfirmation);
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  AuthenticateCredentials() {
    this.isLoading = true;

    let request = new SafeIDCredentialsRequest();
    request = this.readForm(request);

    this.signatureUtilService.setSelectedProvider(SignatureProviderEnum.safeID);
    let signatureStruct: SafeIDVidaaSSignatureStruct = this.signatureUtilService.getSignatureStruct(SignatureProviderEnum.safeID);
    request.client_id = signatureStruct.client_id;
    request.client_secret = signatureStruct.client_secret;
    request.password = signatureStruct.identifierCA + request.password;
    this.safeIDSignatureService.authenticateCredentials(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.credentialsRequest.emit(response);
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  confirmDeleteSignature() {
    const dialogRef = this.dialog.open(AlertModalComponent, {
      data: {
        title: "Deseja remover seus dados cadastrados?",
        description: "Essa ação é irreversível.",
        isTwoButtonsModal: true
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result && result.confirm)
          this.deleteSignature();
      }
    });
  }

  deleteSignature() {
    this.isLoading = true;
    this.digiatlSignatureService.deleteIntegration(SignatureProviderEnum.safeID).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.alertService.show('Sucesso', "Assinatura deletada com sucesso", AlertType.success);
        this.signatureUtilService.clear();
        this.changeState(DigitalSignatureFlowEnum.uninitiated);
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  // Order by descending property key
  keyDescOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return a.key > b.key ? -1 : (b.key > a.key ? 1 : 0);
  }

  readForm(request: any): any {
    let rawModel = this.model.getRawValue();
    for (let key of Object.keys(this.model.controls)) {
      if (Object.hasOwn(request, key)) {
        if (Array.isArray(request[key]))
          request[key].push(rawModel[key])
        else
          request[key] = rawModel[key];
      }
    }
    return request;
  }

  buildForm() {
    for (let key of Object.keys(this.formMetaData)) {
      this.model.addControl(key, new FormControl(null, this.formMetaData[key].validators));
      if (this.formMetaData[key].disable)
        this.model.get(key).disable();
    }
  }
}