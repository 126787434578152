import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { ListUserResponse } from '../../responses/user/list-user.response';
import { retry, timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserGetNameService extends CommonService {

  constructor(private httpClient: HttpClient) { super(); }

  public listUser(listId: Number[]): Observable<ListUserResponse> {
    let uri = 'UserGetName?'

    return this.httpClient.post<ListUserResponse>(environment.urlApiUser + uri, listId, this.addHeaderToken())
      .pipe(timeout(environment.requestTimeOut), retry(environment.requestRetry), catchError(this.handleError));
  }

  public listUserSelect(isActive: boolean = null, searchText: string = null, jsonListIdProfession: string = null): Observable<ListUserResponse> {
    let uri = 'UserGetName?'

    if (isActive != null)
      uri = uri + `isActive=${isActive}&`;

    if (searchText != null)
      uri = uri + `searchText=${searchText}&`;

    if (jsonListIdProfession != null)
      uri = uri + `jsonListIdProfession=${jsonListIdProfession}&`;

    return this.httpClient.get<ListUserResponse>(environment.urlApiUser + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}