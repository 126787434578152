<button mat-flat-button type="submit" color="primary" class="floating-button" (click)="changeShowTiss()">
    <mat-icon>description</mat-icon>
    Nova guia
</button>

<div class="floating-window"
    [ngClass]="{'floating-window-visible': this.showGuideManagement, 'floating-window-hide': !this.showGuideManagement}">
    <app-tiss-guide-management [idEpisode]="idEpisode" [isAttendance]="true" [idRoom]="this.idRoom"
        [idSector]="this.idSector" [idPatient]="this.idPatient" [patientSocialName]="this.patientSocialName"
        [patientName]="this.patientName">
    </app-tiss-guide-management>
</div>