<div mat-dialog-content>
    <div class="mat-dialog-header">
        <h3><b>Escolha um nome para o seu favorito</b></h3>
    </div>
    <div class="mat-dialog-content">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="row">
                <div class="col-12">
                    <mat-form-field appearance="outline" class="outline-spinner">
                        <mat-label>Nome</mat-label>
                        <input matInput type="favoriteName" formControlName="favoriteName">
                        <mat-error *ngIf="model.controls['favoriteName'].errors?.length > 0">Nome inválido</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="footer-panel-modal-list">
                <div class="row" *ngIf="!isLoading">
                    <div class="col-6">
                        <button mat-flat-button type="button" color="accent" class="btn-block"
                            (click)="clickCancel()">Cancelar</button>
                    </div>
                    <div class="col-6">
                        <button mat-flat-button type="submit" color="primary" class="btn-block">
                            <span *ngIf="!isLoading">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                        </button>
                    </div>
                </div>
                <div class="loading" *ngIf="isLoading">
                    <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                </div>
            </div>
        </form>
    </div>
</div>