import { SpSadtExecutedProcedureProfessional } from "../../models/private-billing/sp-sadt-executed-procedure-professional.model";

export class SpSadtExecutedProcedureProfessionalStruct extends SpSadtExecutedProcedureProfessional {
    public idSpSadtExecutedProcedureProfessional: number;
    public idSpSadtExecutedProcedure: number;
    public participationDegreeLookupCode: string;
    public executantProfessionalCouncilCode: string;
    public executantProfessionalCouncilStateCode: string;
    public executantProfessionalCboCode: string;
    public performedProcedureReferenceSequential: string;
    public idParticipationDegreeLookup: number;
    public executantContractorOperatorCodeOrCpf: string;
    public executantProfessionalName: string;
    public idExecutantProfessionalCouncil: number;
    public executantProfessionalCouncilNumber: string;
    public idExecutantProfessionalCouncilState: number;
    public idExecutantProfessionalCbo: number;
}