import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { WebSocketNotificationUpdateRequest } from '../../requests/orchestrator-user/websocket-notification-update.request';
import { MedicConfigSectorResponse } from '../../responses/medic/medic-config-sector.response';
import { GetMessageResponse } from '../../responses/notification/get-message.response';
import { IdHealthUnitResponse } from '../../responses/orchestrator-user/id-health-unit.response';
import { UpdateNotificationResponse } from '../../responses/orchestrator-user/update-notification.response';

@Injectable({
  providedIn: 'root'
})
export class TriggerWebSocketNotificationService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getIdHealthUnit(): Observable<IdHealthUnitResponse> {
    
    let uri = `TriggerWebSocketNotification/getHealthUnit`;
    
    return this.httpClient.get<IdHealthUnitResponse>(environment.urlApiOrchestratorUser + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public updateCheck(listIdUser: number[]): Observable<UpdateNotificationResponse> {
    
    let uri = `TriggerWebSocketNotification`;

    let body: WebSocketNotificationUpdateRequest = new WebSocketNotificationUpdateRequest;

    body.listIdUser = listIdUser;
    
    return this.httpClient.post<UpdateNotificationResponse>(environment.urlApiOrchestratorUser + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
