import { Validators } from "@angular/forms";
import { VerifyCPF } from "src/app/shared/custom-validators/cpf.validator";
import { FormMetaData } from "src/app/shared/structs/form-meta-data.struct";
import { MaskService } from "../../../mask.service";

export class VidaaSCredentialsRequest {
    public client_id: string = "";
    public client_secret: string = "";
    public grant_type: string = "";
    public code: string = "";
    public code_verifier: string = "";
    public loginHint: string = "";

    public static metadata(): Map<string, FormMetaData> {
        let masks = MaskService.getMasks();
        let metaData = new Map<string, FormMetaData>();
        metaData['loginHint'] = new FormMetaData('CPF', [Validators.required, VerifyCPF()], undefined, undefined, masks.cpf, false);

        return metaData;
    }
}