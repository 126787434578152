import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MaskService {

  constructor() { }

  getMasks(): Masks {
    let masks: Masks = new Masks();
    masks.phone = "(00) 00000-0000||(00) 0000-0000";
    masks.fullPhone = "+00 (00) 00000-0000";
    masks.sacPhone = "0000 000 0000";
    masks.cpf = "000.000.000-00";
    masks.cnpj = "00.000.000/0000-00";
    masks.cns = "000 0000 0000 0000";
    masks.creditCardNumber = "0000 0000 0000 0000,";
    masks.dateTime = "00/00/0000 00:00:00";
    masks.date = "00/00/0000";
    masks.monthYear = "00/0000";
    masks.age = { mask: "000L00L00L", pattern: "^([0-9]{3}a(0[0-9]|1[01])m((0|1|2)[0-9])d)$" };
    masks.temperature = "00,0";
    masks.pressure = "000/000||000/00||00/00";
    masks.threeDigits = "000||00";
    masks.twoDigits = "00";
    masks.oneDigit = "0";
    masks.height = "0,00";
    masks.weight = "000,0||00,0||0,0";
    masks.cnes = "0000000";
    masks.ansRegistration = "000000";
    masks.cpfCns = "000.000.000-00||000 0000 0000 0000";
    masks.cpfCnpj = "000.000.000-00||00.000.000/0000-00";
    masks.time = "00:00 || 00:00:00";
    masks.OneTothreeDigits = "000||00||0";
    masks.OneAndTwoDigits = "00||0";
    masks.timeUnit = { mask: "00:00", pattern: "^([0-1]?[0-9]|2[0-3])[0-5][0-9]$" };
    masks.fullTime = { mask: "00:00:00", pattern: "([0-1]?[0-9]|2[0-3])([0-5][0-9])([0-5][0-9])$" };
    masks.decimalOneDigit = "0.00";
    return masks;
  }

  static getMasks(): Masks {
    let masks: Masks = new Masks();
    masks.phone = "(00) 00000-0000||(00) 0000-0000";
    masks.fullPhone = "+00 (00) 00000-0000";
    masks.sacPhone = "0000 000 0000";
    masks.cpf = "000.000.000-00";
    masks.cnpj = "00.000.000/0000-00";
    masks.cns = "000 0000 0000 0000";
    masks.creditCardNumber = "0000 0000 0000 0000,";
    masks.dateTime = "00/00/0000 00:00:00";
    masks.date = "00/00/0000";
    masks.monthYear = "00/0000";
    masks.age = { mask: "000L00L00L", pattern: "^([0-9]{3}a(0[0-9]|1[01])m((0|1|2)[0-9])d)$" };
    masks.temperature = "00,0";
    masks.pressure = "000/000||000/00||00/00";
    masks.threeDigits = "000||00";
    masks.twoDigits = "00";
    masks.oneDigit = "0";
    masks.height = "0,00";
    masks.weight = "000,0||00,0||0,0";
    masks.cnes = "0000000";
    masks.ansRegistration = "000000";
    masks.cpfCns = "000.000.000-00||000 0000 0000 0000";
    masks.cpfCnpj = "000.000.000-00||00.000.000/0000-00";
    masks.time = "00:00";
    masks.OneTothreeDigits = "000||00||0";
    masks.OneAndTwoDigits = "00||0";
    masks.timeUnit = { mask: "00:00", pattern: "^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$" };
    masks.fullTime = { mask: "00:00:00", pattern: "([0-1]?[0-9]|2[0-3])([0-5][0-9])([0-5][0-9])$" };
    masks.decimalOneDigit = "0.00";
    return masks;
  }



  formatPhoneToField(phone: string): string {
    if (phone) {
      return phone.replace(" ", "").replace("(", "").replace(")", "").replace("-", "");
    }
    return null;
  }

  formatPhoneToSave(phone: string): string {
    let phoneArr = phone ? phone.split("") : null
    if (phoneArr) {
      return `(${phoneArr[0]}${phoneArr[1]}) ${phoneArr[2]}${phoneArr[3]}${phoneArr[4]}${phoneArr[5]}${phoneArr[6]}-${phoneArr[7]}${phoneArr[8]}${phoneArr[9]}${phoneArr[10]}`;
    }
    return null;
  }

  formatBloodPressure(bloodPressure: string): number[] {
    if (bloodPressure) {
      let pa: string[] = bloodPressure.split("/")[0].split("");
      let bloodPressureSystole = pa.length == 4 ? parseInt(pa[0] + pa[1]) : parseInt(pa[0] + pa[1] + pa[2]);
      let bloodPressureDiastole = pa.length == 4 ? parseInt(pa[2] + pa[3]) : pa.length == 5 ? parseInt(pa[3] + pa[4]) : parseInt(pa[3] + pa[4] + pa[5]);

      if (bloodPressureSystole < bloodPressureDiastole) {
        return [-1, -1]
      }
      return [bloodPressureSystole, bloodPressureDiastole];
    }
    return [null, null]
  }

  formatTemperature(temperatura: string): number {
    return temperatura ? parseFloat(temperatura.split("")[0] + temperatura.split("")[1] + "." + temperatura.split("")[2]) : null;
  }

  formatStringToDate(dateUnformatted: string): Date {
    if (dateUnformatted) {                 //  0    1    2    3    4    5    6    7
      let dateString = dateUnformatted.toString().replaceAll("/", "");
      let darr = dateString.split("");//["2", "9", "0", "1", "2", "0", "1", "6"]
      let dobj = new Date(parseInt(darr[4] + darr[5] + darr[6] + darr[7]), parseInt(darr[2] + darr[3]) - 1, parseInt(darr[0] + darr[1]));
      return dobj;
    }
    return null;
  }

  formatDateToString(dateToFormat: Date, needSeparator: boolean = true, americanStyle: boolean = false): string {
    if (dateToFormat) {
      var data = new Date(dateToFormat),
        dia = data.getDate().toString(),
        diaF = (dia.length == 1) ? '0' + dia : dia,
        mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
        mesF = (mes.length == 1) ? '0' + mes : mes,
        anoF = data.getFullYear();
      if (americanStyle) {
        return anoF + "-" + mesF + "-" + diaF;
      } else if (needSeparator) {
        return diaF + "/" + mesF + "/" + anoF;
      } else {
        return diaF + mesF + anoF;
      }
    }
    return null;
  }

  formatStringToTime(stringToFormat: string): string {
    if (stringToFormat) {
      var hora = stringToFormat[0] + stringToFormat[1];
      var minuto = stringToFormat[2] + stringToFormat[3];
      var segundo = stringToFormat[4] + stringToFormat[5];
      return hora + ":" + minuto + ":" + segundo;
    }
    return null;
  }

  formatTimeToString(timeToFormat: string): string {
    if (timeToFormat) {
      var formatedString = timeToFormat.toString().replaceAll(":", "");
      return formatedString;
    }
    return null;
  }
}

export class Masks {
  monthYear;
  phone;
  fullPhone;
  sacPhone;
  cpf;
  cns;
  cnpj;
  creditCardNumber;
  date;
  temperature;
  pressure;
  threeDigits;
  twoDigits;
  age;
  height;
  weight;
  time;
  dateTime;
  cpfCns;
  cnes;
  ansRegistration;
  OneTothreeDigits;
  OneAndTwoDigits;
  oneDigit;
  timeUnit;
  fullTime;
  cpfCnpj;
  decimalOneDigit;
}