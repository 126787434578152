<div mat-dialog-content>
    <div class="mat-dialog-header">
        <h2><strong>Defina um nome para esta prescrição favorita</strong></h2>
    </div>
    <div class="mat-dialog-content">
        <form class="form" [formGroup]="model">
            <div class="row">
                <div class="col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome</mat-label>
                        <input matInput type="text" formControlName="favoriteName" required>
                        <mat-error *ngIf="this.model.get('favoriteName').invalid">O nome é
                            obrigatório</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
    <div class="footer-panel-modal-list">
        <div class="row">
            <div class="col-12">
                <div style="display: flex; justify-content: space-evenly;">
                    <div class="col-5">
                        <a mat-flat-button color="primary" class="btn-block" (click)="clickSave()" [disabled]="loading">
                            <span *ngIf="!loading">Salvar</span>
                            <mat-spinner *ngIf="loading" diameter="20"></mat-spinner>
                        </a>
                    </div>
                    <div class="col-5">
                        <a mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Cancelar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>