<div class="floating-button">
    <mat-icon (click)="closeButton()" class="floating-button-close">close</mat-icon>
    <button id="feedback-button" (click)="openFloatingVoting()">
        <mat-icon class="person-icon-first">person_search</mat-icon>
        <span class="floating-button-label">Dê sua opinião</span>
    </button>
    <div id="feedback-dialog" class="floating-button-voting">
        <ng-container *ngIf="!hasVoted && !showThankYouMessage">
            <mat-icon (click)="closeFloating()" class="floating-button-close">close</mat-icon>
            <p class="floating-button-voting-message">
                Como você avalia a sua satisfação com as funcionalidades e módulos oferecidos neste sistema pela ToLife?
            </p>
            <div class="feedback-points">
                <div class="feedback-point" (click)="captureVote(onePoint)">
                    <span class="feedback-point-text">Muito Insatisfeito</span>
                    <div class="feedback-point-circle feedback-point-circle-red">
                        <mat-icon class="person-icon">sentiment_very_dissatisfied</mat-icon>
                    </div>
                    <span class="feedback-point-number">{{onePoint}}</span>
                </div>
                <div class="feedback-point" (click)="captureVote(twoPoints)">
                    <span class="feedback-point-text">Pouco Insatisfeito</span>
                    <div class="feedback-point-circle feedback-point-circle-red">
                        <mat-icon class="person-icon">sentiment_dissatisfied</mat-icon>
                    </div>
                    <span class="feedback-point-number">{{twoPoints}}</span>
                </div>
                <div class="feedback-point" (click)="captureVote(threePoints)">
                    <span class="feedback-point-text">Neutro</span>
                    <div class="feedback-point-circle feedback-point-circle-yellow">
                        <mat-icon class="person-icon">sentiment_neutral</mat-icon>
                    </div>
                    <span class="feedback-point-number">{{threePoints}}</span>
                </div>
                <div class="feedback-point" (click)="captureVote(fourPoints)">
                    <span class="feedback-point-text">Pouco Satisfeito</span>
                    <div class="feedback-point-circle feedback-point-circle-green">
                        <mat-icon class="person-icon">sentiment_satisfied</mat-icon>
                    </div>
                    <span class="feedback-point-number">{{fourPoints}}</span>
                </div>
                <div class="feedback-point" (click)="captureVote(fivePoints)">
                    <span class="feedback-point-text">Muito Satisfeito</span>
                    <div class="feedback-point-circle feedback-point-circle-green">
                        <mat-icon class="person-icon">sentiment_very_satisfied</mat-icon>
                    </div>
                    <span class="feedback-point-number">{{fivePoints}}</span>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="valueSelect && valueSelect >= 1 && valueSelect <= 5 && !showThankYouMessage && hasVoted">
            <mat-icon (click)="closeFloating()" class="floating-button-close">close</mat-icon>
            <div class="feedback-message">
                <p class="feedback-message-title">Conte-nos um pouco mais sobre a sua experiência:</p>
                <p class="feedback-message-sub-title">Não se preocupe! Sua opinião será dada de forma totalmente
                    anônima.</p>
                <textarea placeholder="Escreva aqui as suas sugestões, elogios ou críticas..." class="feedback-textarea"
                    rows="4" cols="50" [(ngModel)]="feedbackMessage"></textarea>

                <div class="button-feedback">
                    <button class="button-feedback-jump" mat-flat-button type="button" color="accent"
                        (click)="skipFeedback()">
                        Pular
                    </button>
                    <button class="button-feedback-send" mat-flat-button type="submit" color="primary"
                        (click)="submitFeedback()">
                        <span *ngIf="isLoading == false">Enviar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="showThankYouMessage">
            <mat-icon (click)="closeFloating()" class="floating-button-close">close</mat-icon>
            <div class="thank-you-message">
                <p class="thank-you-title">Obrigado!</p>
                <img class="thank-you-img" src="assets/images/Doctor-pana 2.png">
                <p class="thank-you-sub-title">Sua avaliação é muito importante para a evolução dos nossos produtos.</p>
            </div>
        </ng-container>
    </div>
</div>