import { Injectable } from "@angular/core";
import { CommonService } from "../../common-service";
import { environment } from "src/environments/environment";
import { Observable, catchError } from "rxjs";
import { ReturnStruct } from "src/app/shared/structs/return.struct";
import { HttpClient } from "@angular/common/http";
import { VidaaSClientResponse } from "../../responses/digital-signature/safe-id/vidaas-client.response";
import { VidaaSClientRequest } from "../../requests/digital-signature/vidaas/vidaas-client.request";
import { VidaaSCellPhoneNotificationRequest } from "../../requests/digital-signature/vidaas/vidaas-cell-phone-notification.request";
import { VidaaSCredentialsRequest } from "../../requests/digital-signature/vidaas/vidaas-credentials.request";
import { VidaaSCredentialsResponse } from "../../responses/digital-signature/safe-id/vidaas-credentials.response";
import { VidaaSCellPhoneNotificationResponse } from "../../responses/digital-signature/safe-id/vidaas-cell-phone-notification.response";
import { VidaaSClientAuthResponse } from "../../responses/digital-signature/safe-id/vidaas-client-auth.response";

@Injectable({
    providedIn: 'root'
})
export class VidaaSSignatureService extends CommonService {

    constructor(private httpClient: HttpClient) { super(); }

    public createClient(request: VidaaSClientRequest): Observable<VidaaSClientResponse> {
        let uri = `VidaaS/client`;

        return this.httpClient.post<VidaaSClientResponse>(environment.urlApiDigitalSignature + uri, request, this.addHeaderToken())
            .pipe(catchError(this.handleError));
    }

    public cellPhoneNotification(request: VidaaSCellPhoneNotificationRequest): Observable<VidaaSCellPhoneNotificationResponse> {
        let uri = `VidaaS/CellPhoneNotification`;

        return this.httpClient.post<VidaaSCellPhoneNotificationResponse>(environment.urlApiDigitalSignature + uri, request, this.addHeaderToken())
            .pipe(catchError(this.handleError));
    }

    public authenticateCredentials(request: VidaaSCredentialsRequest): Observable<VidaaSCredentialsResponse> {
        let uri = `VidaaS/credentials`;

        return this.httpClient.post<VidaaSCredentialsResponse>(environment.urlApiDigitalSignature + uri, request, this.addHeaderToken())
            .pipe(catchError(this.handleError));
    }

    public VerifyAuthentication(code: string): Observable<VidaaSClientAuthResponse> {
        let uri = `VidaaS/VerifyAuthentication/${code}`;

        return this.httpClient.get<VidaaSClientAuthResponse>(environment.urlApiDigitalSignature + uri, this.addHeaderToken())
            .pipe(catchError(this.handleError));
    }
}