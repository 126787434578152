import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AlertService, AlertType } from '../../services/alert.service';
import { MessageService } from '../../services/API/notification/message.service';
import { TriggerWebSocketNotificationService } from '../../services/API/orchestrator-user/trigger-websocket-notification.service';
import { AuthService } from '../../services/auth.service';
import { UserLoginResponse } from '../../services/responses/user/auth.response';
import { ScriptService } from '../../services/script.service';
import { MessageStruct } from '../../services/structs/notification/message.struct';
import { FunctionalityStruct } from '../../services/structs/user/funcionality.struct';
import { FunctionalityGroupStruct } from '../../services/structs/user/funcionalityGroup.struct';
import { ModuleStruct } from '../../services/structs/user/module.struct';
import { UtilService } from '../../services/util.service';
import { WebsocketNotificationService } from '../../services/websocket-notification.service';
import { MenuFunctionalityEnum } from './menu.functionality.enum';
import { MenuModuleEnum } from './menu.module.enum';
import { WebsocketUtilService } from '../../services/websocket-util/websocket-util.service';
import { WebsocketRoomUtilService } from '../../services/websocket-util/websocket-room-util.service';
import { ErrorRequestPageService } from '../../services/API/srv-log/error-request-page.service';
import { ErrorRequestPageRequest } from '../../services/requests/srv-log/error-request-page.request';
import { SecundaryHeaderComponent } from '../secundary-header/secundary-header.component';
import { PatientCareLinePriorityStruct } from '../../services/structs/care-line-priority/patient-care-line-priority-struct';
import { SecundaryHeaderV2Component } from '../secundary-header-v2/secundary-header-v2.component';
import { MatDialog } from '@angular/material/dialog';
import { WebsocketAttendanceUtilService } from '../../services/websocket-util/websocket-attendance-util.service';
import { DigitalSignatureStruct } from '../../services/structs/digital-signature/digital-signature-struct.struct';
import { DigitalSignatureUtilService } from '../../services/digital-signature-util.service';
import { DigitalSignatureService } from '../../services/API/digital-signature/digital-signature.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit, OnChanges, OnDestroy {

  constructor(private authService: AuthService,
    private alertService: AlertService,
    private router: Router,
    private utilService: UtilService,
    private signatureUtilService: DigitalSignatureUtilService,
    private digitalSignatureService: DigitalSignatureService,
    private scriptService: ScriptService,
    private messageService: MessageService,
    private websocketnotificationService: WebsocketNotificationService,
    private triggerWebsocketNotificationService: TriggerWebSocketNotificationService,
    private websocketUtil: WebsocketUtilService,
    public websocketRoomUtilService: WebsocketRoomUtilService,
    public websocketAttendanceUtilService: WebsocketAttendanceUtilService,
    public errorRequestPageService: ErrorRequestPageService,
    public dialog: MatDialog,
  ) {
    this.router.events.subscribe({
      next: (events) => {
        if (events instanceof NavigationEnd)
          this.previousUrl = this.router.url;
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  isAuthenticated: boolean;
  isVisibleUserInfo: boolean;
  isVisibleModule: boolean;
  isVisibleModuleMenu: boolean;
  isVisibleSubMenu: boolean;
  isVisibleRank: boolean;
  isVisibleSignatureManager: boolean;
  isLoadingSignatureManager: boolean;
  isVisibleNotification: boolean;
  isLoadingNotification: boolean;
  listFunctionalityStruct: FunctionalityStruct[];
  listFunctionalityStructPlus: FunctionalityStruct[];
  selectedSubMenu: Array<any>;
  uriModule: string;
  initials: string;
  isMenuMobileOpen: boolean;
  isMasterModule: boolean;
  selectedModuleName: string;
  onlineOffline: boolean = navigator.onLine;
  socketDisconneted: boolean = false;
  //private socket: Socket;
  openedMainMenu: string;
  systemMainMenu: Array<MenuStructView> = new Array<MenuStructView>();
  uriModuleSelectd: string;
  userLoginResponse: UserLoginResponse;
  isWelcomeModule: boolean;
  userRankPosition: string;
  userRankPoint: number;
  isVisibleButtonRank: boolean;
  public listMessage: MessageStruct[];
  public idSelectedMessage: number;
  public unreadMessages: number;
  isExpanded: boolean = false;
  public previousUrl: string = null;
  public managerIcon: string = "draw";
  public loadingSignatures: boolean = true;
  public notificationAlreadyOpened: boolean = true;


  @Input() public isRenderMenu: boolean;
  @Input() public menuModuleEnum: MenuModuleEnum;
  @Input() public menuFunctionalityEnum: MenuFunctionalityEnum;

  //Secundary Header Params
  @Input() public episode: number;
  @Input() public hasSecundaryHeader: true;
  @Input() discriminators: any[];
  @Input() suspicionName: string;
  @Input() hasCachedModule: boolean = false;
  @Input() cachedModuleName: string = '';
  @Input() painColorCode: string[] = [];
  @Input() gravityColorCode: string = '';
  @Input() pain: string = '';
  @Input() gravity: string = '';
  @Input() isClassification: boolean = false;
  @Input() idSector: number;
  @Input() idService: number;
  @Input() sectorName: string;
  @Input() serviceName: string;
  @Input() name: string;
  @Input() socialName: string;
  @Input() birthDate: Date;
  @Input() formatedBirth: string;
  @Input() age: string;
  @Input() patientCareLinePriorityStruct: PatientCareLinePriorityStruct;

  @Output() restartMeasurers: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('sidenavModuleMenu') sidenavModuleMenu: ElementRef;
  @ViewChild(SecundaryHeaderComponent) secundaryHeader: SecundaryHeaderComponent;
  @ViewChild(SecundaryHeaderV2Component) secundaryHeaderV2: SecundaryHeaderV2Component;

  ngOnInit(): void {
    if (this.menuModuleEnum == undefined || this.menuModuleEnum == null) {
      this.alertService.show('Erro no menu', 'Informe o id do modulo do usuário logado para o menu', AlertType.error);
      return;
    }

    this.notificationAlreadyOpened = this.utilService.getNotificationOpened();

    this.populateMenu();

    this.getSelectedMenu();

    this.scriptService.load('movidesk').catch(error => console.log(error));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.idSector) {
      if (changes.idSector.previousValue != null && changes.idSector.currentValue !== changes.idSector.previousValue) {
        this.idSector = changes.idSector.currentValue;
      }
    }
    if (changes.idService) {
      if (changes.idService.previousValue != null && changes.idService.currentValue !== changes.idService.previousValue) {
        this.idService = changes.idService.currentValue;
      }
    }
  }

  private setHeaderInfo(userInfo: UserLoginResponse): void {
    const module: ModuleStruct = userInfo.listModule.find(c => c.idModule == this.menuModuleEnum.valueOf());
    this.isMasterModule = module.idModule === 1;
    this.selectedModuleName = module.nameModule;
    this.uriModuleSelectd = module.uri;
  }

  private populateMenu(): void {
    this.isAuthenticated = this.authService.isAuthenticated();
    this.listFunctionalityStruct = null;
    this.uriModule = "/";
    this.isMenuMobileOpen = false;
    this.listFunctionalityStructPlus = null;

    //Se o usuario estiver desligado, não pode renderizar o menu
    if (this.isAuthenticated == false)
      this.isRenderMenu = false;

    //inicializa variaveis default
    this.isVisibleUserInfo = false;
    this.isVisibleModule = false;
    this.isVisibleModuleMenu = false;
    this.isVisibleRank = false;

    //Se o usuario estiver logado
    if (this.isAuthenticated) {
      this.getDigitalSignatures();
      this.userLoginResponse = this.authService.getTokenMenu();
      this.initials = this.utilService.creatInitials(this.userLoginResponse.userName);

      //Obtém as funcionalidades do menu
      if (this.isRenderMenu != false) {
        this.startWebsocket();
        this.isWelcomeModule = this.menuModuleEnum.valueOf() === MenuModuleEnum.welcome;
        if (this.userLoginResponse.uriDefaultModule != null)
          this.uriModule = this.userLoginResponse.uriDefaultModule;
        else
          this.uriModule = "/welcome";

        let moduleSelected = this.userLoginResponse.listModule.find(c => c.idModule == this.menuModuleEnum.valueOf());

        if (moduleSelected == undefined)
          return;

        let listFunctionalityStructAll = moduleSelected.listFunctionality;
        let listFunctionalityGroupStructAll = moduleSelected.listFunctionalityGroup;

        this.setSubMenu(listFunctionalityStructAll, listFunctionalityGroupStructAll);

        this.setHeaderInfo(this.userLoginResponse);
      }
    }
  }

  setSubMenu(listFunctionality: Array<FunctionalityStruct>, listFunctionalityGroup: Array<FunctionalityGroupStruct>) {
    let mainMenu: Array<MenuStructView> = new Array<MenuStructView>();
    listFunctionality.forEach((functionality: FunctionalityStruct) => {
      if (functionality.idFunctionalityGroup) {
        if (mainMenu.filter((menu: MenuStructView) => menu.idFunctionalityGroup === functionality.idFunctionalityGroup).length === 0) {
          let group: FunctionalityGroupStruct = listFunctionalityGroup.find((group: FunctionalityGroupStruct) => group.idFunctionalityGroup === functionality.idFunctionalityGroup);
          mainMenu.push({
            name: group.functionalityGroupName,
            iconClass: group.iconClass,
            idFunctionalityGroup: group.idFunctionalityGroup,
            active: false,
            id: group.idFunctionalityGroup + '_',
            subMenu: [
              {
                name: functionality.functionalityName,
                idFunctionalityGroup: functionality.idFunctionalityGroup,
                id: group.idFunctionalityGroup + '_' + functionality.idFunctionality,
                uri: functionality.uri
              }
            ]
          });
        } else {
          mainMenu.forEach((menu: MenuStructView) => {
            if (menu.idFunctionalityGroup === functionality.idFunctionalityGroup) {
              menu.subMenu.push({
                name: functionality.functionalityName,
                idFunctionalityGroup: functionality.idFunctionalityGroup,
                id: functionality.idFunctionalityGroup + '_' + functionality.idFunctionality,
                uri: functionality.uri
              });
            }
          });
        }
      } else {
        mainMenu.push({
          name: functionality.functionalityName,
          iconClass: functionality.iconClass,
          uri: functionality.uri,
          id: '_' + functionality.idFunctionality,
          active: false
        });
      }
    });

    this.systemMainMenu = mainMenu;
  }

  populateNotification() {
    this.isLoadingNotification = true;
    this.messageService.listByUser().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoadingNotification = false;
          return;
        }

        if (response.listMessage) {
          this.listMessage = response.listMessage;
          this.unreadMessages = response.listMessage.filter(x => !x.isRead).length;
          if(this.unreadMessages > 0 && !this.notificationAlreadyOpened)
            this.openNotification();

          sessionStorage.setItem("listMessage", JSON.stringify(response.listMessage));
        }

        this.isLoadingNotification = false;
      },
      error: (error) => {
        this.isLoadingNotification = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  // checkSigningResponsibility() {
  //   let token: UserLoginResponse = this.utilService.getToken();
  //   let signatureMap: Object = this.signatureUtilService.listSignatureStruct();

  //   let requireSignature = token.listRole.map(x => x.idRole).includes(UserRoleEnum.requerer_assinatura) && !token.isMaster;
  //   let signatureReady = signatureMap != null && signatureMap[SignatureProviderEnum.safeID] != null && signatureMap[SignatureProviderEnum.safeID].identifierCA != null;

  //   if (requireSignature && !signatureReady) {
  //     const dialogRef = this.dialog.open(AlertModalComponent, {
  //       data: {
  //         isTwoButtonsModal: false,
  //         title: 'Atenção',
  //         description: 'Seu perfil foi marcado com obrigatoriedade de assinatura digital de documentos. Escolha um provedor e siga o processo para ativar sua assinatura digital no sistema.'
  //       },
  //       disableClose: true,
  //     });
  //     dialogRef.afterClosed().subscribe({
  //       next: result => {
  //         this.openSignatureManager();
  //       }
  //     });
  //   }
  // }

  closeUserInfo(sidenavUserInfo: any) {
    sidenavUserInfo.toggle();
    this.isVisibleUserInfo = false;
  }

  openUserInfo(sidenavUserInfo: any) {
    sidenavUserInfo.toggle();
    this.isVisibleUserInfo = true;
  }

  openModuleInfo(sidenavModuleInfos: any) {
    sidenavModuleInfos.toggle();
    this.isVisibleModule = true;
  }

  closeModuleInfo(sidenavModuleInfos: any) {
    sidenavModuleInfos.toggle();
    this.isVisibleModule = false;
  }

  openModuleMenu(sidenavModuleMenu: any) {
    if (this.selectedModuleName != undefined) {
      sidenavModuleMenu.toggle();
    }
    // this.isVisibleModuleMenu = true;
  }

  closeModuleMenu() {
    this.isVisibleModuleMenu = false;
  }

  openRankInfo() {
    this.isVisibleRank = true;
  }

  closeRankInfo() {
    this.isVisibleRank = false;
  }

  closeSubMenu() {
    this.isVisibleSubMenu = false;
  }

  openNotification() {
    this.utilService.setNotificationOpened(true);
    this.isVisibleNotification = true;
  }

  closeNotification() {
    this.isVisibleNotification = false;
  }

  openSignatureManager() {
    this.isVisibleSignatureManager = true;
  }

  closeSignatureManager() {
    this.isVisibleSignatureManager = false;
  }

  setManagerIcon(event: string) {
    this.managerIcon = event;
  }

  redirect(uri: string) {
    this.router.navigate([uri]);
  }

  selectItem(item: MenuStructView) {
    let selectedMenu: MenuStructView = sessionStorage.getItem('selectedMenu') ? JSON.parse(sessionStorage.getItem('selectedMenu')) : null;
    this.setActiveItem(item);
    item.active = true;
    sessionStorage.setItem('selectedMenu', JSON.stringify(item));
    if (!item.subMenu) {
      if (selectedMenu && selectedMenu.id != item.id) {
        this.websocketRoomUtilService.clearSocket();
        this.websocketAttendanceUtilService.clearSocket(true);
      }
      this.redirect(item.uri);
      return;
    }

    if (item.active)

      this.selectedSubMenu = item.subMenu;
    this.isVisibleSubMenu = !this.isVisibleSubMenu;
    this.openedMainMenu = item.name;
  }

  getSelectedMenu() {
    let selectedMenu: MenuStructView = sessionStorage.getItem('selectedMenu') ? JSON.parse(sessionStorage.getItem('selectedMenu')) : {};
    this.setActiveItem(selectedMenu);
  }

  populateSecundaryHeader() {
    this.secundaryHeader.rePopulate();
  }

  reloadSecondaryHeader() {
    if (this.secundaryHeader)
      this.secundaryHeader.reloadHeader();
  }

  reloadSecondaryHeaderV2(discriminatorValues) {
    if (this.secundaryHeaderV2)
      this.secundaryHeaderV2.reloadHeader(discriminatorValues);
  }

  setActiveItem(item: MenuStructView) {
    this.systemMainMenu.forEach((menu: MenuStructView) => {
      menu.active = false;

      if (!item) return;

      menu.active = item.id === menu.id;
    });
  }
  getRankInfosValue(obj) {
    this.userRankPosition = obj.position;
    this.userRankPoint = obj.userPoint;
    this.isVisibleButtonRank = obj.isButtonVisible;
  }
  selectMessage(event: any) {
    this.idSelectedMessage = event.idMessage;
  }

  getActiveItem(item: MenuStructView) {
    if (item.active)
      return item.active;
  }

  closeMenuMobile() {
    this.isMenuMobileOpen = false;
  }

  openMenuMobile() {
    this.isMenuMobileOpen = true;
  }

  toggleMenuMobile() {
    this.isMenuMobileOpen = !this.isMenuMobileOpen;
  }

  ngOnDestroy() {
    clearInterval(this.idInterval);
    clearInterval(this.idIntervalSessionErroRequest);
  }

  //Websocket
  startWebsocket() {
    if (this.websocketUtil.getSocket() == null || !this.websocketUtil.getSocket().connected) {
      this.populateNotification();
      this.triggerWebsocketNotificationService.getIdHealthUnit().subscribe((response) => {
        let idHealthUnit = response.idHealthUnit;
        let socket = this.websocketnotificationService.websocketConnection();
        socket.emit('join', `idHealthUnit-${idHealthUnit}`).on("updateNotification", (res) => sessionStorage.setItem("updateNotification", JSON.stringify(res)));
        this.websocketUtil.addSocket(socket);
      });
    } else {
      let res = JSON.parse(sessionStorage.getItem("listMessage"));
      if (res) {
        this.listMessage = res;
        this.unreadMessages = this.listMessage.filter(x => !x.isRead).length;
        if(this.unreadMessages > 0 && !this.notificationAlreadyOpened) 
          this.openNotification();
      }
    }
  }

  public checkNewMessages(listIdUser) {
    this.triggerWebsocketNotificationService.updateCheck(listIdUser).subscribe((response) => {
      if (response.update) {
        this.notificationAlreadyOpened = false;
        this.populateNotification();
      }
    });
  }

  private idInterval = setInterval(() => {
    let res = sessionStorage.getItem("updateNotification")
    if (res) {
      this.checkNewMessages(JSON.parse(res));
      sessionStorage.removeItem("updateNotification")
    }

    if (this.onlineOffline !== navigator.onLine) {
      this.onlineOffline = navigator.onLine;
    }

  }, 1000);

  private idIntervalSessionErroRequest = setInterval(() => {
    const tokenSessionError = "sessionErroRequest";
    if (sessionStorage.getItem(tokenSessionError) == null)
      return;

    let listErrorRequestPageRequest: ErrorRequestPageRequest[] = JSON.parse(sessionStorage.getItem(tokenSessionError));
    this.errorRequestPageService.post(listErrorRequestPageRequest).subscribe();
    sessionStorage.removeItem(tokenSessionError);

  }, 60000);

  emitMeasurerRestart() {
    this.restartMeasurers.emit();
  }

  setPreviousURL() {
    this.utilService.setPreviousURL(this.previousUrl);
  }

  async getDigitalSignatures() {
    let listSignatureStruct = this.signatureUtilService.listSignatureStruct();

    if (listSignatureStruct) {
      this.loadingSignatures = false;
      return;
    }

    this.digitalSignatureService.getListSignature().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        let listSignatureStruct = new Object;
        response.listSignature.forEach(signatureString => {
          let signatureStruct: DigitalSignatureStruct = JSON.parse(signatureString);
          if (signatureStruct) {
            signatureStruct.idUser = 0;
            listSignatureStruct[signatureStruct.idSignatureProvider] = signatureStruct;
          }
        });
        this.signatureUtilService.setListSignatureStruct(listSignatureStruct);
        this.loadingSignatures = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.loadingSignatures = false;
      }
    });
  }
}

class MenuStructView {
  id: string;
  name: string;
  iconClass?: string;
  idFunctionalityGroup?: number;
  uri?: string;
  subMenu?: Array<MenuStructView>;
  active?: boolean;
}