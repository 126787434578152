<form class="form" [formGroup]="model">
    <div *ngIf="this.listMetaData && this.listMetaData.length > 0 && !this.isLoading">
        <div class="title-header">
            <h1>Dados Adicionais</h1>
        </div>
        <div class="row">
            <div *ngFor="let item of listMetaData" class="col-12 col-sm-6 col-md-4">
                <mat-form-field *ngIf="item.metaData.idMetaDataType == metaDataTypeEnumResult.Texto"
                    appearance="outline">
                    <mat-label>{{item.metaData.metaDataName}}</mat-label>
                    <input matInput [readonly]="this.isReadonly" formControlName="{{item.metaData.idMetaData}}"
                        type="text">
                </mat-form-field>
                <mat-form-field *ngIf="item.metaData.idMetaDataType == metaDataTypeEnumResult.Numero"
                    appearance="outline">
                    <mat-label>{{item.metaData.metaDataName}}</mat-label>
                    <input matInput [readonly]="this.isReadonly" formControlName="{{item.metaData.idMetaData}}"
                        type="number">
                </mat-form-field>
                <mat-form-field *ngIf="item.metaData.idMetaDataType == metaDataTypeEnumResult.Data"
                    appearance="outline">
                    <mat-label>{{item.metaData.metaDataName}}</mat-label>
                    <input matInput inputmode="text" type="text" [mask]="masks.date" placeholder="dd/mm/aaaa"
                        [readonly]="this.isReadonly" [formControlName]="item.metaData.idMetaData"
                        (change)="verifyDate(item.metaData.idMetaData)">
                    <mat-error *ngIf="model.get(item.metaData.idMetaData.toString()).hasError('invalidDate')">Data
                        inválida</mat-error>
                </mat-form-field>
                <mat-form-field *ngIf="item.metaData.idMetaDataType == metaDataTypeEnumResult.Select"
                    appearance="outline">
                    <mat-label>{{item.metaData.metaDataName}}</mat-label>
                    <input matInput formControlName="{{item.metaData.idMetaData}}" [readonly]="this.isReadonly">
                </mat-form-field>
            </div>
        </div>
    </div>
</form>