import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { MetaDataStructResponse } from '../../responses/meta-data/meta-data-struct.response';
import { GetMetaDataStructResponse } from '../../responses/meta-data/get-meta-data-struct.response';
import { PostPutMetaDataRequest } from '../../requests/meta-data/post-put-meta-data.request';
import { MetaDataSectorResponse } from '../../responses/orchestrator-patient/meta-data-sector.response';

@Injectable({
  providedIn: 'root'
})

export class MetaDataService extends CommonService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  public getBySearchMetaData(searchText: string): Observable<MetaDataStructResponse> {
    let uri = `MetaData?`

    if (searchText != null)
      uri = uri + `searchText=${searchText}&`;

    return this.httpClient.get<MetaDataStructResponse>(environment.urlApiMetaData + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public getAllMetaData(): Observable<MetaDataStructResponse> {
    let uri = `MetaData/GetAllMetaData`

    return this.httpClient.get<MetaDataStructResponse>(environment.urlApiMetaData + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public getByIdMetaData(idMetaData: number): Observable<GetMetaDataStructResponse> {
    let uri = `MetaData/idMetaData/${idMetaData}`;

    return this.httpClient.get<GetMetaDataStructResponse>(environment.urlApiMetaData + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public createMetaData(body: PostPutMetaDataRequest): Observable<ReturnStruct> {
    let uri = `MetaData`

    return this.httpClient.post<ReturnStruct>(environment.urlApiMetaData + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public updateMetaData(idMetaData: number, body: PostPutMetaDataRequest): Observable<ReturnStruct> {
    let uri = `MetaData/idMetaData/${idMetaData}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiMetaData + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public deleteMetaData(idMetaData: number, ip: string): Observable<MetaDataSectorResponse> {
    let uri = `MetaData/idMetaData/${idMetaData}/ip/${ip}`

    return this.httpClient.delete<MetaDataSectorResponse>(environment.urlApiOrchestratorPatient + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }
}