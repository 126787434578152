import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserLoginResponse } from 'src/app/shared/services/responses/user/auth.response';
import { AuthService } from 'src/app/shared/services/auth.service';
import { StayConnected, UtilService } from 'src/app/shared/services/util.service';
import { NetworkTestModalComponent } from '../network-test-modal/network-test-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { MenuModuleEnum } from '../menu.module.enum';
import { AutomaticLoginService } from 'src/app/shared/services/API/user/automatic-login.service';
import { NavigationEnd, Router } from '@angular/router';
import { DigitalSignatureUtilService } from 'src/app/shared/services/digital-signature-util.service';

@Component({
  selector: 'app-user-infos',
  templateUrl: './user-infos.component.html',
  styleUrls: ['./user-infos.component.css']
})
export class UserInfosComponent implements OnInit {

  constructor(private authService: AuthService,
    private utilService: UtilService,
    private digitalSignatureUtil: DigitalSignatureUtilService,
    private automaticLoginService: AutomaticLoginService,
    public dialog: MatDialog,
    public router: Router,
  ) { }

  @Input() isVisible: boolean;
  @Input() menuModuleEnum: MenuModuleEnum;
  @Output() closeUserInfo = new EventEmitter<any>();
  @Output() savePreviousURL = new EventEmitter<any>();

  userLoginResponse: UserLoginResponse
  initials: string;
  menuModuleEnumMaster = MenuModuleEnum.master;
  public stayConnected: StayConnected;
  public canStayConnected: boolean;
  public previousUrl: any = null;

  ngOnInit(): void {
    this.userLoginResponse = this.authService.getTokenMenu();
    this.canStayConnected = (this.userLoginResponse.canStayConnected != null) ? this.userLoginResponse.canStayConnected : false;
    this.initials = this.utilService.creatInitials(this.userLoginResponse.userName);

  }

  close() {
    this.closeUserInfo.emit();
  }

  logout() {
    this.stayConnected = this.utilService.getGuidStayConnected();
    this.digitalSignatureUtil.clear();

    if (this.stayConnected) {
      this.deleteAutomaticLogin();
      this.utilService.cleanGuidStayConnected();
    }

    this.authService.logout();
  }

  openNetworkTestModal() {
    const dialogRef = this.dialog.open(NetworkTestModalComponent, {
      data: {

      },
    });
    this.closeUserInfo.emit();
  }

  deleteAutomaticLogin() {

    this.automaticLoginService.deleteAutomaticLoginByGuid(this.stayConnected.guid).subscribe({
      next: response => {
        if (response.isError) {
          return;
        }

      },
      error: error => {
        console.log(error);
      }
    });
  }

  goConnectedDevices() {
    this.savePreviousURL.emit();
    this.router.navigate(['/connected-devices']);
  }
}
