import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { GetQueueResponse } from '../../responses/queue/get-queue.response';

@Injectable({
  providedIn: 'root'
})
export class QueueService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public updtadeStatus(idQueue: number, updateStatusQueueRequest: any): Observable<ReturnStruct> {
    let uri = `Queue/idQueue/${idQueue}`
      
    return this.httpClient.put<ReturnStruct>(environment.urlApiQueue + uri, updateStatusQueueRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getCurrentQueueByEpisode(idEpisode: number): Observable<GetQueueResponse> {
    
    let uri = `Queue/idEpisode/${idEpisode}`
      
    return this.httpClient.get<GetQueueResponse>(environment.urlApiQueue + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
