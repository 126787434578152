<div class="row kpi-status">
    <button [disabled]="isLoading" mat-flat-button (click)="selectOption('beyondGoal')" [color]="beyondGoalColor"
        class="btn-block col-12 col-md-4">
        <span>Fora da meta</span>
    </button>
    <button [disabled]="isLoading" mat-flat-button (click)="selectOption('alert')" [color]="alertColor"
        class="btn-block col-12 col-md-4">
        <span>Alerta</span>
    </button>
    <button [disabled]="isLoading" mat-flat-button (click)="selectOption('ok')" [color]="okColor"
        class="btn-block col-12 col-md-4">
        <span>Dentro da meta</span>
    </button>
</div>