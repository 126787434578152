import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HealthGuidelineTriageData, UtilsClassificationService } from 'src/app/classification/utils.service';
import { ValidateFutureDate } from 'src/app/shared/custom-validators/date.validator';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { HealthGuidelineCategorization } from 'src/app/shared/services/models/risk-classification/health-guideline-categorization.model';
import { HealthGuideline } from 'src/app/shared/services/models/risk-classification/health-guideline.model';

@Component({
  selector: 'app-health-guideline-triage-modal',
  templateUrl: './health-guideline-triage-modal.component.html',
  styleUrls: ['./health-guideline-triage-modal.component.css']
})
export class HealthGuidelineTriageModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<HealthGuidelineTriageModalComponent>,
    private formBuilder: FormBuilder,
    private maskService: MaskService,
    private utilClassificationService: UtilsClassificationService,
  ) { }

  public listCategorization: HealthGuidelineCategorization[]=[]
  public selectedCategoriation: HealthGuidelineCategorization;
  public healthGuideline: HealthGuideline;
  public model: FormGroup;
  public masks: Masks = this.maskService.getMasks();
  public healthGuidelineTriageData: HealthGuidelineTriageData = new HealthGuidelineTriageData();

  ngOnInit(): void {
    this.listCategorization = this.data.healthGuidelineStruct.listHealthGuidelineCategorization
    this.healthGuideline = this.data.healthGuidelineStruct.healthGuideline;

    this.model = this.formBuilder.group({
      symptomStartTime: ['', [Validators.required]],
      symptomStartDate: ['', [Validators.required, ValidateFutureDate()]],
    });
  }

  selectCategorization(event) {
    this.selectedCategoriation = event;
  }

  submit(){
    if(this.model.invalid)
      return;

    let timeString = this.model.get('symptomStartTime').value
    let dateString = this.model.get('symptomStartDate').value

    this.healthGuidelineTriageData.healthGuideline = this.healthGuideline;
    this.healthGuidelineTriageData.healthGuidelineCategorization = this.selectedCategoriation
    this.healthGuidelineTriageData.datetimeStartSymptom = this.formatDate(dateString, timeString);
    this.utilClassificationService.createHealthGuidelineTriageData(this.healthGuidelineTriageData);

    this.matDialogRef.close();
  }

  formatDate(dateString: string, timeString:string) : Date{
    let darr = dateString.split("");//["2", "9", "0", "1", "2", "0", "1", "6"]
    let selectedDate = new Date(parseInt(darr[4] + darr[5] + darr[6] + darr[7]), parseInt(darr[2] + darr[3]) - 1, parseInt(darr[0] + darr[1]));

    let tarr = timeString.split("");//["1", "2", "0", "0"]

    let hour = parseInt(tarr[0] + tarr[1])

    let minutes = parseInt(tarr[2] + tarr[3])

    selectedDate.setHours(hour, minutes);

    return selectedDate;
  }
}

