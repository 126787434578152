<div [formGroup]="formGroup">
    <div class="row label-row">
        <div class="col-md-12 login-label" (click)="selectLogin()">
            <mat-icon class="vertical-align" aria-hidden="false" aria-label="Plus">account_circle</mat-icon>{{login}}
        </div>
    </div>
    <div class="row" *ngIf="listHealthUnit && listHealthUnit.length>1">
        <div class="col-md-12">
            <mat-form-field appearance="outline">
                <mat-label>Unidade de Saude</mat-label>
                <mat-select formControlName="idHealthUnit" (selectionChange)="onIdHealthUnitChange()">
                    <mat-option *ngFor="let item of listHealthUnit"
                        value="{{item.idHealthUnit}}">{{item.healthUnitName}}</mat-option>
                </mat-select>
                <mat-error *ngIf="formGroup.get('idHealthUnit').invalid">Informe a Unidade de Saúde</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row" *ngIf="listHealthUnit.length<1 || formGroup.get('idHealthUnit').valid">
        <div class="col-md-12">
            <mat-form-field appearance="outline">
                <mat-label>Senha</mat-label>
                <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                <input matInput #password type="password" [type]="hide ? 'text' : 'password'" formControlName="password"
                    required>
                <mat-error *ngIf="formGroup.get('password').invalid">Informe a senha</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="adjust-checkbox">
        <mat-checkbox *ngIf="canStayConnected" formControlName="stayConnected" class="my-checkbox-field">
            Lembrar login
        </mat-checkbox>
    </div>
    <div class="row">
        <div class="col-md-12 align-button" *ngIf="listHealthUnit && listHealthUnit.length > 0">
            <div class="split-button">
                <button mat-flat-button type="submit" color="primary" class="btn btn-primary btn-sm">
                    <span *ngIf="isLoading == false">Login</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
            <div class="split-button">
                <button mat-flat-button type="button" (click)="forgotPassword()" color="accent"
                    class="btn btn-outline-primary btn-sm">
                    Esqueci minha senha
                </button>
            </div>
        </div>
    </div>
</div>