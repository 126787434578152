<div mat-dialog-content>
    <div class="mat-dialog-header">
        <h2>O valor inserido para <b>{{discriminatorNowName}}</b> se enquadra na prioridade {{priorityName}}!</h2>
    </div>
    <div class="mat-dialog-content">
        <h4>Deseja selecionar o discriminador <b>{{discriminatorName}}</b>?</h4>
        <div class="footer-panel-modal-list">
            <div class="row">
                <div class="col-6">
                    <button mat-flat-button color="primary" class="btn-block" (click)="clickResponse(true)">SIM</button>
                </div>
                <div class="col-6">
                    <button mat-flat-button color="accent" class="btn-block" (click)="clickResponse(false)">NÃO</button>
                </div>
            </div>
        </div>
    </div>
</div>