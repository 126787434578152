import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { PharmacyMovementRequest } from '../../requests/pharmacy/pharmacy-movement.request';
import { ListStorageResponse } from '../../responses/pharmacy/list-storage.response';
import { PharmacyMovementResponse } from '../../responses/pharmacy/pharmacy-movement.response';
import { ActiveMedicationStockResponse } from '../../responses/pharmacy/active-medication-stock.response';

@Injectable({
  providedIn: 'root'
})
export class PharmacyMovementService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public getStorage(idMedicine: number, idItem: number): Observable<PharmacyMovementResponse> {
    let uri = `PharmacyMovement?`;

    if (idMedicine) {
      uri = uri + `idMedicine=${idMedicine}`
    } else if (idItem) {
      uri = uri + `idItem=${idItem}`
    }

    return this.httpClient.get<PharmacyMovementResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public listAllProducts(pageIndex: number, code: string, description: string): Observable<ListStorageResponse> {
    let uri = 'PharmacyMovement/getAll?'

    if (pageIndex) {
      uri = uri + "pageIndex=" + pageIndex + "&";
    }
    if (code) {
      uri = uri + "code=" + code + "&";
    }
    if (description) {
      uri = uri + "description=" + description + "&";
    }

    return this.httpClient.get<ListStorageResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getAllMedicinePaginated(searchText: string, pageIndex: number, listIdMedicineSubGroup: number[],
    listIdMedicineGroup: number[]): Observable<ActiveMedicationStockResponse> {
    let uri = 'PharmacyMovement/getAllMedicine?'

    if (searchText)
      uri = uri + `searchText=${searchText}&`

    if (pageIndex)
      uri += `pageIndex=${pageIndex}&`;

    if (listIdMedicineSubGroup.length > 0) {
      let listIdMedicineSubGroupRequest = JSON.stringify(listIdMedicineSubGroup);
      uri += `listIdMedicineSubGroupRequest=${listIdMedicineSubGroupRequest}&`;
    }

    if (listIdMedicineGroup.length > 0) {
      let listIdMedicineGroupRequest = JSON.stringify(listIdMedicineGroup);
      uri += `listIdMedicineGroupRequest=${listIdMedicineGroupRequest}&`;
    }

    uri = uri.endsWith('&') ? uri.slice(0, -1) : uri;

    return this.httpClient.get<ActiveMedicationStockResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public getAllMedicineNoPaginated(searchText: string, listIdMedicineSubGroup: number[],
    listIdMedicineGroup: number[]): Observable<ActiveMedicationStockResponse> {
    let uri = 'PharmacyMovement/getAllMedicineNoPagination?'

    if (searchText)
      uri = uri + `searchText=${searchText}&`

    if (listIdMedicineSubGroup.length > 0) {
      let listIdMedicineSubGroupRequest = JSON.stringify(listIdMedicineSubGroup);
      uri += `listIdMedicineSubGroupRequest=${listIdMedicineSubGroupRequest}&`;
    }

    if (listIdMedicineGroup.length > 0) {
      let listIdMedicineGroupRequest = JSON.stringify(listIdMedicineGroup);
      uri += `listIdMedicineGroupRequest=${listIdMedicineGroupRequest}&`;
    }

    uri = uri.endsWith('&') ? uri.slice(0, -1) : uri;

    return this.httpClient.get<ActiveMedicationStockResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public updateAmount(body: PharmacyMovementRequest): Observable<ReturnStruct> {

    let uri = `PharmacyMovement`

    return this.httpClient.put<ReturnStruct>(environment.urlApiPharmacy + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}