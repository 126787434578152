export enum ColumnsFlowManagementPatientListEnum {
    Episodio = 1,
    Senha = 2,
    Entrada = 3,
    Prioridade = 4,
    LinhaDeCuidado = 5,
    Gravidade = 6,
    NomePaciente = 7,
    DataNascimento = 8,
    NomeMae = 9,
    LocalizacaoAtual = 10,
    TempoEspera = 11,
    Situacao = 12,
    ProfissionalResponsavel = 13,
    Procedimentos = 14
}