import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { AutocompleteService } from './autocomplete.service';
import { Observable, map, startWith } from 'rxjs';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class AutocompleteComponent implements OnInit {

  constructor(
    private autocompleteService: AutocompleteService, 
    private alertService: AlertService, 
    private parentForm: FormGroupDirective
  ) { }

  @Input() list: any[] = []; //Input responsável por gerar o select de maneira offline
  @Input() url: string; //Input responsável por gerar o select de maneira online, ou seja, qualquer pesquisa vai ao backend enviando um searchtext
  @Input() nameField: string; //Input responsável por determinar o campo que é o label do select que também será usado para filtrar (ex: [{id:1, name:"teste"},...] deve ser passado como [nameField]="'name'")
  @Input() labelField: string; //Nome que aparecerar para o usuário final
  @Input() formControlField: string = null;//Se houver formBuilder passar um string do valor child
  @Input() isDisabled: boolean = false;//controle para deixar o campo desabilitado

  @Output() selectedValue = new EventEmitter<any>();//Colocar uma função para receber a lista de objetos selecionados

  public filteredList: Observable<any[]>;
  public initialList: any[] = [];
  public offlineList: boolean;
  public firstChildGroup: UntypedFormGroup;
  private timeoutKeySearch: any = null;

  ngOnInit(): void {
    this.firstChildGroup = this.parentForm.form;
    if (this.list && this.list.length > 0) {
      this.initialList = this.list;
      this.offlineList = true;
      this.search('');
    } else {
      this.populateSelect("");
    }
  }

  selectValue(item: any) {
    this.selectedValue.emit(item);
  }

  populateSelect(searchText: string) {
    if (this.url) {
      this.autocompleteService.getSelectValues(this.url, searchText).subscribe((response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.initialList = response.list;
        this.mapFilteredList();
      });
    }
  }

  onKey(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search(event);
      }
    }, 1000);
  }

  search(value: string) {
    if (this.offlineList) {
      this.mapFilteredList();
    } else {
      this.populateSelect(value);
    }
  }

  mapFilteredList() {
    this.filteredList = this.firstChildGroup.get('firstChildGroup').get(this.formControlField).valueChanges.pipe(
      startWith(''),
      map(value => this.filterValues(value || '')),
    );
  }

  filterValues(value: string) {
    return this.initialList.filter(option => option[this.nameField].toLowerCase().includes(value.toLowerCase()));
  }
}
