import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { SelectFavoriteMedicineModalComponent } from '../select-favorite-medicine-modal/select-favorite-medicine-modal.component';
import { MedicFavoriteNonStandardPrescriptionStruct } from 'src/app/shared/services/structs/medic/medic-favorite-non-standard-prescription.struct';
import { MedicFavoriteNonStandardPrescriptionService } from 'src/app/shared/services/API/medic/medic-favorite-non-standard-prescription.service';
import { FavoriteNonStandardMedicineService } from 'src/app/shared/services/API/medic/favorite-non-standard-medicine.service';

@Component({
  selector: 'app-select-favorite-non-standard-medicine-modal',
  templateUrl: './select-favorite-non-standard-medicine-modal.component.html',
  styleUrls: ['./select-favorite-non-standard-medicine-modal.component.css']
})
export class SelectFavoriteNonStandardMedicineModalComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<SelectFavoriteMedicineModalComponent>,
    private alertService: AlertService,
    private favoriteNonStandardPrescriptionService: MedicFavoriteNonStandardPrescriptionService,
    private favoriteNonStandardMedicineService: FavoriteNonStandardMedicineService,
    private formBuilder: FormBuilder
  ) { }

  public model: FormGroup;
  public listMedicFavoritePrescription: MedicFavoriteNonStandardPrescriptionStruct[] = [];
  public loading: boolean = false;
  public selected: boolean = false;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      idMedicFavoriteNonStandardPrescription: ['', [Validators.required]]
    });
    this.getAllFavoriteMedicine();
  }


  getAllFavoriteMedicine() {
    this.loading = true;
    this.favoriteNonStandardPrescriptionService.listMedicFavoritNonStandardPrescription().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.loading = false;
          return;
        }
        this.listMedicFavoritePrescription = response.listMedicFavoriteNonStandardPrescriptionStruct;
        this.loading = false;
        if (!this.listMedicFavoritePrescription || this.listMedicFavoritePrescription.length === 0)
          this.alertService.show('Aviso', 'Não há prescrições favoritadas', AlertType.warning);
      },
      error: (error: string) => {
        console.log(error);
        this.loading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  loadMedicine(event: any) {
    if (!event.value || event.value == 0) {
      return;
    }
    else {
      this.loading = true;
      this.favoriteNonStandardMedicineService.getFavoriteNonStandardMedicine(event.value).subscribe({
        next: (response) => {
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            this.loading = false;
            return;
          }
          this.selected = true;
          this.loading = false;
          this.matDialogRef.close({
            selected: this.selected,
            listMedicine: response.listFavoriteNonStandardMedicine,
          });
        },
        error: (error) => {
          console.log(error)
          this.alertService.show('Erro inesperado', error, AlertType.error);
          this.loading = false;
          return;
        }
      });
    }
  }

  clickCancel() {
    this.matDialogRef.close({});
  }

  favoriteMedicineName(idMedicFavoriteNonStandardPrescription: number): string | undefined {
    const item = this.listMedicFavoritePrescription.find(item => item.idMedicFavoriteNonStandardPrescription === idMedicFavoriteNonStandardPrescription);
    return item ? item.favoritePrescriptionName : undefined;
  }

}
