<div mat-dialog-content>
    <div class="mat-dialog-header">
        <h3>Linha de Cuidado</h3>
    </div>
    <form class="form" [formGroup]="model" (ngSubmit)="submit()">
        <div class="mat-dialog-content">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Linhas de Cuidado</mat-label>
                        <mat-select formControlName="listIdCareLine" [multiple]="true">
                            <mat-option *ngFor="let item of listCareLine" [value]="item.idCareLine" [disabled]="listBlockedCareLine.includes(item.idCareLine)">
                                {{item.careLineName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Código do país</mat-label>
                        <input matInput formControlName="countryCode" inputmode="numeric">
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-12 col-md-8">
                    <mat-form-field appearance="outline">
                        <mat-label>Número de Telefone</mat-label>
                        <input matInput formControlName="phoneNumber" [mask]="masks.phone" inputmode="numeric">
                    </mat-form-field>
                </div>
            </div>
            <div class="footer-panel-modal-list">
                <div class="row">
                    <div class="col-6">
                        <a mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Cancelar</a>
                    </div>
                    <div class="col-6">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span>Salvar</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>