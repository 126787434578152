import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { ForgotPasswordRequest } from '../../requests/user/forgot-password.request';
import { RecoverPasswordResponse } from '../../responses/user/recover-password.response';

@Injectable({
  providedIn: 'root' 
})
export class ForgotPasswordService extends CommonService{

  constructor(private router: Router,
    private httpClient: HttpClient) {
    super();
  }

  public emailRecoverPassword(body: ForgotPasswordRequest): Observable<ReturnStruct> {

    return this.httpClient.post<ReturnStruct>(environment.urlApiSrvEmail + "ForgotPassword", body, this.addHeaderHashEmailSms())
      .pipe(
        catchError(this.handleError)
    )
  }
}
