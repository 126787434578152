import { Component, Inject } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FeesFieldsMaxLengthEnum } from 'src/app/shared/enum/private-billing/fees-fields-max-length.enum';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { Access } from 'src/app/shared/services/models/private-billing/access.model';
import { Technique } from 'src/app/shared/services/models/private-billing/technique.model';
import { TerminologyGroup } from 'src/app/shared/services/models/private-billing/terminology-group.model';
import { ProcedureService } from 'src/app/shared/services/API/private-billing/procedure.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { GetProceduresRequest } from 'src/app/shared/services/requests/private-billing/get-procedures.request';
import { ProcedureStruct } from 'src/app/shared/services/structs/private-billing/procedure.struct';
import { ParticipationDegree } from 'src/app/shared/services/models/private-billing/participation-degree.model';
import { HealthcareAgreementStruct } from 'src/app/shared/services/structs/private-billing/healthcare-agreement.struct';
import { ProfessionalAgreementStruct } from 'src/app/shared/services/structs/private-billing/professional-agreement.struct';
import { Cbo } from 'src/app/shared/services/models/private-billing/cbo.model';
import { FeesProcedureStruct } from 'src/app/shared/services/structs/private-billing/fees-procedure.struct';
import { AdjustmentCoefficientMappings } from 'src/app/shared/enum/private-billing/adjustment-coefficient-enums.enum';
import { TissBilling } from 'src/app/shared/services/models/private-billing/tiss-billing.model';
import { ProfessionalAgreementService } from 'src/app/shared/services/API/private-billing/professional-agreement.service';
import { DateValidator } from 'src/app/shared/custom-validators/date.validator';
import { State } from 'src/app/shared/services/models/private-billing/state.model';
import { ProfessionalCouncil } from 'src/app/shared/services/models/private-billing/professional-council.model';
import { TerminologyGroupEnum } from 'src/app/shared/enum/private-billing/terminology-group.enum';
import { TimeValidator, compareTime } from 'src/app/shared/custom-validators/time.validator';
import { FeesRequiredField } from 'src/app/shared/services/models/private-billing/fees-required-field.model';
import { LookupFeesRequiredFieldEnum } from 'src/app/shared/enum/private-billing/lookup-fees-required-field.enum';
import { FeesProcedureProfessionalStruct } from 'src/app/shared/services/structs/private-billing/fees-procedure-professional.struct';
import { CheckHasRestrictionService } from 'src/app/shared/services/API/orchestrator-billing/episode.service';
import { CheckHasRestrictionsRequest } from 'src/app/shared/services/requests/private-billing/check-has-restriction.request';
import { GuideTypeEnum } from 'src/app/shared/enum/private-billing/guide-type.enum';

@Component({
  selector: 'app-fees-procedure-modal',
  templateUrl: './fees-procedure-modal.component.html',
  styleUrls: ['./fees-procedure-modal.component.css']
})
export class FeesProcedureModalComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService,
    private matDialogRef: MatDialogRef<FeesProcedureModalComponent>,
    public procedureService: ProcedureService,
    private formBuilder: FormBuilder,
    private maskService: MaskService,
    private professionalAgreementService: ProfessionalAgreementService,
    private checkHasRestrictionService: CheckHasRestrictionService
  ) { }

  private timeoutKeySearch: any = null;

  public procedureModel: FormGroup;
  public masks: Masks;
  public listProcedure: ProcedureStruct[] = [];
  public listState: State[] = [];
  public listProfessionalCouncil: ProfessionalCouncil[] = [];
  public listFeesRequiredField: FeesRequiredField[] = [];
  public accessMap: Map<number, Access>;
  public techniqueMap: Map<number, Technique>;
  public participationDegreeMap: Map<number, ParticipationDegree>;
  public tussTerminologyGroupMap: Map<number, TerminologyGroup>;
  public cboMap: Map<number, Cbo>;
  public feesFieldsMaxLengthEnum: typeof FeesFieldsMaxLengthEnum = FeesFieldsMaxLengthEnum;

  public idTerminologyGroup: number;
  public idProcedure: number = null;
  public healthcareAgreement: HealthcareAgreementStruct;
  public tissBiling: TissBilling;
  public listProfessionalAgreementStruct: ProfessionalAgreementStruct[];
  public feesProcedureStruct: FeesProcedureStruct;

  public isFirstLoading: boolean = false;
  public isLoading: boolean = false;
  public isSelectProcedure: boolean = false;
  public isEditingAllowed: boolean = this.data.isEditingAllowed;
  public procedimentos: TerminologyGroupEnum = TerminologyGroupEnum.procedimentos;

  ngOnInit(): void {
    this.accessMap = this.data.accessMap;
    this.techniqueMap = this.data.techniqueMap;
    this.participationDegreeMap = this.data.participationDegreeMap;
    this.tussTerminologyGroupMap = this.data.tussTerminologyGroupMap;
    this.cboMap = this.data.cboMap;
    this.idTerminologyGroup = this.data.idTerminologyGroup;
    this.healthcareAgreement = this.data.healthcareAgreement;
    this.listProfessionalAgreementStruct = this.data.listProfessionalAgreementStruct;
    this.tissBiling = this.data.tissBiling;
    this.listState = this.data.listState;
    this.listProfessionalCouncil = this.data.listProfessionalCouncil;
    this.listFeesRequiredField = this.data.listFeesRequiredField;

    const currentDate = new Date();

    this.procedureModel = this.formBuilder.group({
      idFeesProcedure: [null, []],
      procedure: [{ value: '', disabled: true }, [Validators.required]],
      idPriceProcedurePlan: [null, []],
      idPriceProcedureHealthcare: [null, []],
      idTerminologyGroup: [null, [Validators.required]],
      procedureDate: [this.maskService.formatDateToString(currentDate, false), [Validators.required, DateValidator()]],
      procedureStartDatetime: [null, [TimeValidator()]],
      procedureEndDatetime: [null, [TimeValidator()]],
      procedureCount: ['1', [Validators.required]],
      idAccess: [null, []],
      idTechnique: [null, []],
      adjustmentCoefficient: ['1.00', [Validators.required]],
      procedureUnitValue: ['0,00', [Validators.required]],
      procedureTotalValue: [{ value: '0,00', disabled: true }, [Validators.required]],
      procedureReferenceIndex: [null, []],
      otherUrgency: [false, []],
      otherDhe: [false, []],
      otherApartment: [false, []],
      listProfessionals: this.formBuilder.array([], [Validators.required])
    },
      {
        validators: [compareTime()]
      });
    if (this.listFeesRequiredField && this.listFeesRequiredField.find(x => x.idLookupFeesRequiredField == LookupFeesRequiredFieldEnum.tecnicaRealizada)) {
      this.procedureModel.get('idTechnique').setValidators([Validators.required]);
      this.procedureModel.get('idTechnique').updateValueAndValidity();
    };

    this.masks = this.maskService.getMasks();

    if (this.data.feesProcedureStruct) {
      this.feesProcedureStruct = this.data.feesProcedureStruct;

      this.populate();
    }
    else
      this.addNext();
  }

  async populate() {
    this.procedureModel.get('idTerminologyGroup').setValue(this.feesProcedureStruct.idTerminologyGroup);
    this.getProcedures('', this.feesProcedureStruct.idProcedure);
    this.idProcedure = this.feesProcedureStruct.idProcedure;
    this.procedureModel.get('procedureDate').setValue(this.maskService.formatDateToString(this.feesProcedureStruct.procedureDate, false));
    this.procedureModel.get('procedureCount').setValue(this.feesProcedureStruct.procedureCount.toString());
    this.procedureModel.get('idAccess').setValue(this.feesProcedureStruct.idAccess);
    this.procedureModel.get('idTechnique').setValue(this.feesProcedureStruct.idTechnique);
    this.procedureModel.get('adjustmentCoefficient').setValue(this.feesProcedureStruct.adjustmentCoefficient.toString());
    this.procedureModel.get('procedureTotalValue').setValue(this.feesProcedureStruct.procedureTotalValue.toString());
    this.procedureModel.get('procedureReferenceIndex').setValue(this.feesProcedureStruct.procedureReferenceIndex);
    this.procedureModel.get('procedureStartDatetime').setValue(this.formatHour(this.feesProcedureStruct.procedureStartDatetime?.replaceAll(":", ""), true));
    this.procedureModel.get('procedureEndDatetime').setValue(this.formatHour(this.feesProcedureStruct.procedureEndDatetime?.replaceAll(":", ""), true));
    this.procedureModel.get('procedureUnitValue').setValue(this.feesProcedureStruct.procedureUnitValue.toString());
    this.procedureModel.get('otherApartment').setValue(this.feesProcedureStruct.otherApartment);
    this.procedureModel.get('otherUrgency').setValue(this.feesProcedureStruct.otherUrgency);
    this.procedureModel.get('otherDhe').setValue(this.feesProcedureStruct.otherDhe);

    this.feesProcedureStruct.listProfessional.forEach((prof, index) => {
      this.addNext();
      let professionalForm = this.professionals().controls[index];
      let profStruct = this.listProfessionalAgreementStruct.find(x => x.idProfessionalAgreement == prof.idProfessionalAgreement);
      professionalForm.get('professionalAgreement').setValue(profStruct);
      professionalForm.get('idFeesProcedureProfessional').setValue(prof.idFeesProcedureProfessional);
      this.selectProfessional({ value: profStruct }, index);
    });
  }

  submit() {
    if (this.procedureModel.invalid)
      return;

    const minCheck = this.checkMinProfessional();
    this.procedureModel.setErrors({ minProfessionals: !minCheck });

    if (!minCheck)
      return;

    if (this.data.idPatient)
      this.checkHasRestriction();

    const procedureStruct = this.createProcedureStructFromModel();

    this.isLoading = false;

    this.matDialogRef.close({ procedureStruct: procedureStruct });
  }

  createProcedureStructFromModel(): FeesProcedureStruct {
    const procedureStruct = new FeesProcedureStruct();
    const procedureDateForm = this.procedureModel.get('procedureDate').value as string;

    const day = parseInt(procedureDateForm.substring(0, 2), 10);
    const month = parseInt(procedureDateForm.substring(2, 4), 10) - 1; // Mês precisa ser decrementado em 1, pois em JavaScript os meses começam de 0
    const year = parseInt(procedureDateForm.substring(4), 10);

    procedureStruct.idFeesProcedure = this.feesProcedureStruct?.idFeesProcedure;
    procedureStruct.idFeesGuide = this.feesProcedureStruct?.idFeesGuide;
    procedureStruct.idPriceProcedureHealthcare = this.procedureModel.get('procedure').value.idLastPriceProcedureHealthcare;
    procedureStruct.procedureDate = new Date(year, month, day, 0, 0, 0, 0);
    procedureStruct.procedureCount = parseInt(this.procedureModel.get('procedureCount').value);
    procedureStruct.idAccess = this.procedureModel.get('idAccess').value;
    procedureStruct.idTechnique = this.procedureModel.get('idTechnique').value;
    procedureStruct.adjustmentCoefficient = parseFloat(this.procedureModel.get('adjustmentCoefficient').value);
    procedureStruct.procedureTotalValue = parseFloat(this.procedureModel.get('procedureTotalValue').value);
    procedureStruct.procedureReferenceIndex = this.procedureModel.get('procedureReferenceIndex').value;
    procedureStruct.procedureStartDatetime = this.formatHour(this.procedureModel.get('procedureStartDatetime').value);
    procedureStruct.procedureEndDatetime = this.formatHour(this.procedureModel.get('procedureEndDatetime').value);
    procedureStruct.otherApartment = this.procedureModel.get('otherApartment').value;
    procedureStruct.otherUrgency = this.procedureModel.get('otherUrgency').value;
    procedureStruct.otherDhe = this.procedureModel.get('otherDhe').value;

    const selectedProcedure = this.procedureModel.get('procedure').value;
    procedureStruct.idProcedure = selectedProcedure.idProcedure;
    procedureStruct.procedureValue = parseFloat(this.procedureModel.get('procedureUnitValue').value);
    procedureStruct.procedureUnitValue = parseFloat(this.procedureModel.get('procedureUnitValue').value);
    procedureStruct.idTerminology = selectedProcedure.terminology.idTerminology;
    procedureStruct.terminologyCode = selectedProcedure.terminology.terminologyCode;
    procedureStruct.terminologyName = selectedProcedure.terminology.terminologyName;
    procedureStruct.idTerminologyGroup = selectedProcedure.terminology.idTerminologyGroup;
    procedureStruct.referenceTable = this.tussTerminologyGroupMap.get(selectedProcedure.terminology.idTerminologyGroup).terminologyGroupCode;

    this.professionals().controls.forEach(x => {
      if (x.get('idProfessionalAgreement').value)
        procedureStruct.listProfessional.push(this.mapFeesProfessionalModel(x));
    });

    if (procedureStruct.listProfessional && procedureStruct.listProfessional.length < 1) {
      this.alertService.show('Erro', "Preencha pelo menos um profissional", AlertType.error);
      this.isLoading = false;
      return;
    }

    return procedureStruct;
  }

  formatHour(hour: string, noFormat: boolean = false): string {
    if (!hour) return;
    let stringHour = "";
    if (noFormat)
      stringHour = hour.slice(0, 2) + hour.slice(2, 4) + hour.slice(4, 6);
    else stringHour = hour.slice(0, 2) + ":" + hour.slice(2, 4) + ":" + hour.slice(4, 6);
    return stringHour;
  }

  close() {
    this.matDialogRef.close();
  }

  //Dynamic triggers  
  onChangeTotalValue() {
    let procedureTotalValue = this.calculateTotalValue();
    this.procedureModel.get('procedureTotalValue').setValue(procedureTotalValue);
  }

  onChangeAccess() {
    if (!this.procedureModel.get('procedure').value)
      return;

    this.calculateAdjustmentCoefficient();
    let calcTotalValue = this.calculateTotalValue();

    this.procedureModel.get('procedureTotalValue').setValue(calcTotalValue);
  }

  async selectTable() {
    this.procedureModel.get('procedure').setValue(null);
    this.procedureModel.get('procedure').reset();
    this.procedureModel.get('procedureCount').setValue(1);
    this.procedureModel.get('idAccess').setValue(null);
    this.procedureModel.get('idTechnique').setValue(null);
    this.procedureModel.get('adjustmentCoefficient').setValue('1.00');
    this.procedureModel.get('procedureUnitValue').setValue('0,00');
    this.procedureModel.get('procedureTotalValue').setValue('0,00');

    this.isSelectProcedure = false;
    await this.getProcedures("");
  }

  selectProcedure(event: ProcedureStruct) {
    this.isSelectProcedure = true;
    this.idProcedure = event.idProcedure;
    this.procedureModel.get('procedureUnitValue').setValue(event.value);
    this.onChangeTotalValue();
  }

  onKey(event: any) {
    clearTimeout(this.timeoutKeySearch);
    if (event) {
      var $this = this;
      this.timeoutKeySearch = setTimeout(function () {
        if (event.keyCode != 13)
          $this.getProcedures(event, $this.procedureModel.get('procedure').value?.idProcedure);
      }, 1000);
    }
  }

  selectProfessional(event: any, index: number) {
    let professionalForm = this.professionals().controls[index];
    professionalForm.get('idProfessionalAgreement').setValue(event.value.idProfessionalAgreement);
    professionalForm.get('idParticipationDegree').setValue(event.value.idParticipationDegree);
    professionalForm.get('professionalProviderCodeOrCpf').setValue(event.value.codeOperator.toString());
    professionalForm.get('professionalName').setValue(event.value.nameProfessional);
    professionalForm.get('idCouncil').setValue(event.value.idProfessionalCouncil);
    professionalForm.get('idCouncilState').setValue(event.value.idState);
    professionalForm.get('professionalCouncilCode').setValue(event.value.numberProfessional.toString());
    professionalForm.get('idCbo').setValue(event.value.idCbo);
    this.onChangeTotalValue();
  }

  //Requests
  async getProcedures(key: string, idProcedureToPopulate?: number) {
    this.isLoading = true;
    let request: GetProceduresRequest = {
      key: key,
      idHealthcareAgreement: this.healthcareAgreement.idHealthcareAgreement,
      idTerminologyGroup: this.procedureModel.get('idTerminologyGroup').value,
      isCreate: this.data.idFeesGuide == null ? true : false,
      listIdProcedure: idProcedureToPopulate ? [idProcedureToPopulate] : []
    };

    this.procedureService.GetByTerminologyGroup(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listProcedure = response.listProcedureStruct;

        if (idProcedureToPopulate) {
          this.procedureModel.get('procedure').setValue(this.listProcedure.find(x => x.idProcedure == idProcedureToPopulate));
          this.isSelectProcedure = true;
        }

        this.procedureModel.get('procedure').enable();

        if (this.isEditingAllowed)
          this.procedureModel.enable();
        else if (!this.isEditingAllowed)
          this.procedureModel.disable();

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  calculateAdjustmentCoefficient() {
    const mappings = new AdjustmentCoefficientMappings();
    let adjustmentCoefficient: number = 1.00;
    const idAccess = this.procedureModel.get('idAccess').value;

    if (idAccess && this.tissBiling && this.isSelectProcedure) {
      switch (idAccess) {
        case mappings.AccessMapping.honorarySameGuide:
          adjustmentCoefficient *= this.tissBiling.honorarySameGuide / 100;
          break;
        case mappings.AccessMapping.honoraryBilateral:
          adjustmentCoefficient *= this.tissBiling.honoraryBilateral / 100;
          break;
        case mappings.AccessMapping.honoraryDifferentGuide:
          adjustmentCoefficient *= this.tissBiling.honoraryDifferentGuide / 100;
          break;
      }
    }

    // const idFilmSurface = this.procedureModel.get('idFilmSurface')?.value;
    // if (this.tissBiling && idFilmSurface && this.isSelectProcedure) {
    //   switch (idFilmSurface) {
    //     case mappings.FilmSurfaceMapping.m2FilmSameGuide:
    //       adjustmentCoefficient *= this.tissBiling.m2FilmSameGuide / 100;
    //       break;
    //     case mappings.FilmSurfaceMapping.m2FilmBilateral:
    //       adjustmentCoefficient *= this.tissBiling.m2FilmBilateral / 100;
    //       break;
    //     case mappings.FilmSurfaceMapping.m2FilmDifferentGuide:
    //       adjustmentCoefficient *= this.tissBiling.m2FilmDifferentGuide / 100;
    //       break;
    //   }
    // }

    if (this.tissBiling && this.procedureModel.get('otherUrgency').value && this.isSelectProcedure)
      adjustmentCoefficient *= this.tissBiling.otherUrgency / 100;

    if (this.tissBiling && this.procedureModel.get('otherDhe').value && this.isSelectProcedure)
      adjustmentCoefficient *= this.tissBiling.otherDhe / 100;

    if (this.tissBiling && this.procedureModel.get('otherApartment').value && this.isSelectProcedure)
      adjustmentCoefficient *= this.tissBiling.otherApartment / 100;

    this.procedureModel.get('adjustmentCoefficient').setValue(adjustmentCoefficient);
  }

  calculateUnitValue() {
    const adjustmentCoefficient = parseFloat(this.procedureModel.get('adjustmentCoefficient').value);
    const baseValue = parseFloat(this.procedureModel.get('procedureUnitValue').value);
    const unitValue = baseValue * adjustmentCoefficient;
    return unitValue;
  }

  calculateProfesisonalValue(professional: AbstractControl): number {
    if (!professional.value.professionalAgreement || !professional.value.professionalAgreement.idProfessionalAgreement
      || !this.tissBiling)
      return 0;

    const idParticipationDegree = professional.get('idParticipationDegree').value;

    let professionalFactor: number = 1.00;

    let unitValue = this.calculateUnitValue();

    const mappings = new AdjustmentCoefficientMappings();

    switch (idParticipationDegree) {
      case mappings.ParticipationDegreeMapping.participationSurgeon:
        professionalFactor *= this.tissBiling.participationSurgeon / 100;
        break;
      case mappings.ParticipationDegreeMapping.participationFirstAux:
        professionalFactor *= this.tissBiling.participationFirstAux / 100;
        break;
      case mappings.ParticipationDegreeMapping.participationSecondAux:
        professionalFactor *= this.tissBiling.participationSecondAux / 100;
        break;
      case mappings.ParticipationDegreeMapping.participationThirdAux:
        professionalFactor *= this.tissBiling.participationThirdAux / 100;
        break;
      case mappings.ParticipationDegreeMapping.participationForthAux:
        professionalFactor *= this.tissBiling.participationForthAux / 100;
        break;
      case mappings.ParticipationDegreeMapping.participationScrubNurse:
        professionalFactor *= this.tissBiling.participationScrubNurse / 100;
        break;
      case mappings.ParticipationDegreeMapping.participationAnesthetist:
        professionalFactor *= this.tissBiling.participationAnesthetist / 100;
        break;
      case mappings.ParticipationDegreeMapping.participationAnesthetistAuxiliar:
        professionalFactor *= this.tissBiling.participationAnesthetistAuxiliar / 100;
        break;
      case mappings.ParticipationDegreeMapping.participationConsultant:
        professionalFactor *= this.tissBiling.participationConsultant / 100;
        break;
      case mappings.ParticipationDegreeMapping.participationPerfusionist:
        professionalFactor *= this.tissBiling.participationPerfusionist / 100;
        break;
      case mappings.ParticipationDegreeMapping.participationBirthRoomPediatrician:
        professionalFactor *= this.tissBiling.participationBirthRoomPediatrician / 100;
        break;
      case mappings.ParticipationDegreeMapping.participationSadtAuxiliar:
        professionalFactor *= this.tissBiling.participationSadtAuxiliar / 100;
        break;
      case mappings.ParticipationDegreeMapping.participationClinic:
        professionalFactor *= this.tissBiling.participationClinic / 100;
        break;
      case mappings.ParticipationDegreeMapping.participationIntensivist:
        professionalFactor *= this.tissBiling.participationIntensivist / 100;
        break;
    }

    return professionalFactor * unitValue;
  }

  calculateTotalValue() {
    let professionalFullValue: number = 0;

    if (this.procedureModel.get('idTerminologyGroup').value == TerminologyGroupEnum.procedimentos && this.professionals().valid) {
      this.professionals().controls.forEach((professional) => {
        professionalFullValue += this.calculateProfesisonalValue(professional);
      });
    }
    let unitValue = this.calculateUnitValue();
    let procedureCount = parseInt(this.procedureModel.get('procedureCount').value);

    return (unitValue + professionalFullValue) * procedureCount;
  }

  checkMinProfessional(): boolean {
    return this.professionals().controls.some(x => x.get('idProfessionalAgreement').value != null);
  }

  mapFeesProfessionalModel(professionalForm: AbstractControl): FeesProcedureProfessionalStruct {
    let feesProcedureProfessionalModel: FeesProcedureProfessionalStruct = new FeesProcedureProfessionalStruct();
    feesProcedureProfessionalModel.idFeesProcedureProfessional = professionalForm.get('idFeesProcedureProfessional').value ? professionalForm.get('idFeesProcedureProfessional').value : null;
    feesProcedureProfessionalModel.idFeesProcedure = professionalForm.get('idFeesProcedure').value ? professionalForm.get('idFeesProcedure').value : null;
    feesProcedureProfessionalModel.idProfessionalAgreement = professionalForm.get('idProfessionalAgreement').value;
    feesProcedureProfessionalModel.idParticipationDegree = professionalForm.get('idParticipationDegree').value;
    feesProcedureProfessionalModel.professionalProviderCodeOrCpf = professionalForm.get('professionalProviderCodeOrCpf').value.toString();
    feesProcedureProfessionalModel.professionalName = professionalForm.get('professionalName').value;
    feesProcedureProfessionalModel.professionalCouncilCode = professionalForm.get('professionalCouncilCode').value.toString();
    feesProcedureProfessionalModel.idCbo = professionalForm.get('idCbo').value;
    feesProcedureProfessionalModel.idCouncilState = professionalForm.get('idCouncilState').value;
    feesProcedureProfessionalModel.idCouncil = professionalForm.get('idCouncil').value;
    return feesProcedureProfessionalModel;
  }

  professionals(): FormArray {
    return this.procedureModel.get('listProfessionals') as FormArray;
  }

  createInput() {
    return this.formBuilder.group({
      idFeesProcedureProfessional: [{ value: '', disabled: true }, []],
      idProfessionalAgreement: [{ value: '', disabled: true }, [Validators.required]],
      idFeesProcedure: [{ value: '', disabled: true }, []],
      professionalAgreement: [null, [Validators.required]],
      idParticipationDegree: [{ value: '', disabled: true }, []],
      professionalProviderCodeOrCpf: [{ value: '', disabled: true }, [Validators.required]],
      professionalName: ['', []],
      idCouncil: [{ value: '', disabled: true }, [Validators.required]],
      professionalCouncilCode: [{ value: '', disabled: true }, [Validators.required]],
      idCbo: [{ value: '', disabled: true }, [Validators.required]],
      idCouncilState: [{ value: '', disabled: true }, [Validators.required]],
    })
  }

  addNext() {
    this.professionals().push(this.createInput());
    this.onChangeAccess();
  }

  removeProfessional(index: number) {
    this.professionals().removeAt(index);
    this.onChangeAccess();
  }

  onKeyProfessional(event: any) {
    clearTimeout(this.timeoutKeySearch);
    if (event) {
      var $this = this;
      this.timeoutKeySearch = setTimeout(function () {
        if (event.keyCode != 13)
          $this.getProfessional(event);
      }, 1000);
    }
  }

  getProfessional(key: string) {
    this.professionalAgreementService.getProfessional(this.healthcareAgreement.idHealthcareAgreement, key).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listProfessionalAgreementStruct = response.list;

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  inputNumberMinOne(formName: string) {
    let value = this.procedureModel.get(formName).value ? this.procedureModel.get(formName).value : '';
    if (value) {
      value = value.replace(/[^0-9]/g, '');
      value = value.replace(/(\..*)\./g, '$1');
      if (parseInt(value) == 0) {
        this.alertService.show('Atenção', 'O valor não pode ser menor que 1!', AlertType.warning);
        value = '1';
      }
      this.procedureModel.get(formName).setValue(value);
    }
  }

  checkHasRestriction() {
    let request = new CheckHasRestrictionsRequest();
    request.idHealthcareAgreement = this.healthcareAgreement.idHealthcareAgreement
    request.idProcedure = this.idProcedure;
    request.guideType = GuideTypeEnum.honorarios;
    request.idPatient = this.data.idPatient;

    this.checkHasRestrictionService.GetListEpisodeByIdPatient(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (response.restrictionProcedureStruct && response.restrictionProcedureStruct.alertDescription)
          this.alertService.show(`<b>Atenção:</b>`, response.restrictionProcedureStruct.alertDescription, AlertType.warning);
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }
}