import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { AppointmentGuideRequest } from '../../requests/private-billing/appointment-guide.request';
import { AppointmentGuideResponse } from '../../responses/private-billing/appointment-guide.response';
import { GuideResponse } from '../../responses/private-billing/guide.response';

@Injectable({
  providedIn: 'root'
})
export class AppointmentGuideService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public VerifyAppointmentGuide(request: AppointmentGuideRequest): Observable<AppointmentGuideResponse> {
    let uri = 'AppointmentGuide/sendAppointmentGuide';
    return this.httpClient.post<AppointmentGuideResponse>(environment.urlApiPrivateBilling + uri, request, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public UpdateAppointmentGuide(request: AppointmentGuideRequest): Observable<AppointmentGuideResponse> {
    let uri = 'AppointmentGuide/updateAppointmentGuide';
    return this.httpClient.put<AppointmentGuideResponse>(environment.urlApiPrivateBilling + uri, request, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public GetAppointmentGuideByIdEpisode(idEpisode: number): Observable<GuideResponse> {
    let uri = `AppointmentGuide/idEpisode/${idEpisode}`
    return this.httpClient.get<GuideResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}