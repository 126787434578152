import { ValidatorFn, ValidationErrors, FormArray } from "@angular/forms";


export const datesInCrescentOrderValidator: ValidatorFn = (formArray: FormArray): ValidationErrors | null => {
  let invalid = false;

  // Itera sobre todos os controles no FormArray, exceto o último, pois está comparando cada elemento com o próximo
  for (let i = 0; i < formArray.length - 1; i++) {
    let currentValue = formArray.at(i).value.date.toString();
    const currentDay = parseInt(currentValue.substring(0, 2), 10);
    const currentMonth = parseInt(currentValue.substring(2, 4), 10) - 1; // Mês é base zero
    const currentYear = parseInt(currentValue.substring(4), 10);
    const currentDate = new Date(currentYear, currentMonth, currentDay);

    let nextValue = formArray.at(i + 1).value.date;
    const nextDay = parseInt(nextValue.substring(0, 2), 10);
    const nextMonth = parseInt(nextValue.substring(2, 4), 10) - 1; // Mês é base zero
    const nextYear = parseInt(nextValue.substring(4), 10);
    const nextDate = new Date(nextYear, nextMonth, nextDay);
    
    // Se a data atual ou a próxima data não for válida, pula a iteração
    if (isNaN(currentDate.getTime()) || isNaN(nextDate.getTime())) {
      continue;
    }

    // Verifica se a data atual é maior ou igual à próxima data
    if (currentDate >= nextDate) {
      invalid = true;
      break; // Sai do loop se encontrar datas fora de ordem
    }
  }

  // Retorna o objeto de erro se as datas não estiverem em ordem crescente
  return invalid ? { datesNotInCrescentOrder: true } : null;
};