import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, retry, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ListExamAndExamTypeResponse } from '../../responses/SADT/list-exam-and-exam-type.response';

@Injectable({
  providedIn: 'root'
})
export class ExamAndExamTypeService extends CommonService {

  constructor(private httpClient: HttpClient) { super(); }

  public getAllExamAndExamType(): Observable<ListExamAndExamTypeResponse> {

    let uri = 'ExamAndExamType'

    return this.httpClient.get<ListExamAndExamTypeResponse>(environment.urlApiSADT + uri, this.addHeaderToken())
      .pipe(timeout(environment.requestTimeOut), retry(environment.requestRetry), catchError(this.handleError));
  }
}
