<div class="table-container">
    <table class="basic-table desktop">
        <thead class="basic-table__head">
            <tr>
                <th>Seq. Ref</th>
                <th>Data</th>
                <th>Hora Inicial</th>
                <th>Hora Final</th>
                <th>Tabela</th>
                <th>Cód. proced</th>
                <th>Nome</th>
                <th>Qtde.</th>
                <th>Via</th>
                <th>Téc.</th>
                <th>Red/Acresc</th>
                <th>Valor Unitário</th>
                <th>Valor Total</th>
                <th>Ações</th>
            </tr>
        </thead>
        <tbody class="basic-table__body">
            <tr class="basic-table__body__row" *ngFor="let item of listFeesProcedure; let index = index;">
                <td class="truncate"><span>{{item.procedureReferenceIndex}}</span></td>
                <td class="truncate"><span>{{item.procedureDate | date: 'dd/MM/YYYY'}}</span></td>
                <td class="truncate"><span>{{item.procedureStartDatetime}}</span></td>
                <td class="truncate"><span>{{item.procedureEndDatetime}}</span></td>
                <td class="truncate"><span>{{item.referenceTable}}</span></td>
                <td class="truncate"><span>{{item.terminologyCode}}</span></td>
                <td class="truncate"><span>{{item.terminologyName}}</span></td>
                <td class="truncate"><span>{{item.procedureCount}}</span></td>
                <td class="truncate"><span>{{accessMap.get(item.idAccess)?.accessDescription}}</span></td>
                <td class="truncate"><span>{{techniqueMap.get(item.idTechnique)?.techniqueDescription}}</span></td>
                <td class="truncate"><span>{{item.adjustmentCoefficient}}</span></td>
                <td class="truncate"><span>{{item.procedureUnitValue}}</span></td>
                <td class="truncate"><span>{{item.procedureTotalValue}}</span></td>
                <td class="truncate">
                    <div *ngIf="listProfessionalAgreementStruct && isEditingAllowed != null" class="actions">
                        <a *ngIf="!disableProcedureEdit"
                            (click)="editProcedure($event,index)"><mat-icon>edit</mat-icon></a>
                        <a *ngIf="!(disableProcedureAdd || disableProcedureEdit || this.isEditingAllowed)"
                            (click)="deleteProcedure($event,index)"><mat-icon>delete</mat-icon></a>
                    </div>
                    <div *ngIf="listProfessionalAgreementStruct && isEditingAllowed == null" class="actions">
                        <a *ngIf="!disableProcedureEdit"
                            (click)="editProcedure($event,index)"><mat-icon>edit</mat-icon></a>
                        <a *ngIf="!(disableProcedureAdd || disableProcedureEdit)"
                            (click)="deleteProcedure($event,index)"><mat-icon>delete</mat-icon></a>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div>
    <div class="button-add col-12" *ngIf="isEditingAllowed != null">
        <button mat-flat-button type="button"
            [disabled]="!healthcareAgreementStruct.idHealthcareAgreement || disableProcedureAdd || !this.isEditingAllowed"
            color="primary" (click)="newProcedure()">
            <span *ngIf="!isLoading">Adicionar procedimento executado +</span>
            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
        </button>
    </div>
    <div class="button-add col-12" *ngIf="isEditingAllowed == null">
        <button mat-flat-button type="button"
            [disabled]="!healthcareAgreementStruct.idHealthcareAgreement || disableProcedureAdd" color="primary"
            (click)="newProcedure()">
            <span *ngIf="!isLoading">Adicionar procedimento executado +</span>
            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
        </button>
    </div>
</div>