import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ReportModalComponent } from '../report-modal/report-modal.component';

@Component({
  selector: 'app-report-view-select-modal',
  templateUrl: './report-view-select-modal.component.html',
  styleUrls: ['./report-view-select-modal.component.css']
})
export class ReportViewSelectModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ReportViewSelectModalComponent>,
  ) { }

  public isLoading: boolean = true;
  public reportDictionary: Object;

  ngOnInit(): void {
    this.reportDictionary = {
      medicalCareReport: { report: this.data.medicalCareReport, viewed: false },
      examInternalReport: { report: this.data.examInternalReport, viewed: false },
      examExternalReport: { report: this.data.examExternalReport, viewed: false },
      internalPrescriptionReport: { report: this.data.internalPrescriptionReport, viewed: false },
      externalPrescriptionReport: { report: this.data.externalPrescriptionReport, viewed: false },
      procedureReport: { report: this.data.procedureReport, viewed: false },
      sickNoteReport: { report: this.data.sickNoteReport, viewed: false },
      isolationReport: { report: this.data.isolationReport, viewed: false },
      isolationAcknowledgeReport: { report: this.data.isolationAcknowledgeReport, viewed: false },
      wristbandReport: { report: this.data.wristbandReport, viewed: false },
      admissionReport: { report: this.data.admissionReport, viewed: false },
      blankMedicalCareReport: { report: this.data.blankMedicalCareReport, viewed: false },
      appointmentGuideReport: { report: this.data.appointmentGuideReport, viewed: false },      
      admissionGuideReport: { report: this.data.admissionGuideReport, viewed: false },
      feesGuideReport: { report: this.data.feesGuideReport, viewed: false },
      spSadtGuideReport: { report: this.data.spSadtGuideReport, viewed: false },
      observationReport: { report: this.data.observationReport, viewed: false },    
      allReportsConcat: { report: this.data.allReportsConcat, viewed: false },      
      listMemedPrescriptionPdf: { report: null, listReport: this.data.listMemedPrescriptionPdf, viewed: false },
    };
    this.isLoading = false;
  }

  close() {
    if (this.isLoading) {
      return;
    }
    this.matDialogRef.close();
  }

  async viewReport(key: string): Promise<void> {
    return new Promise((resolve) => {
      const pdf64 = this.reportDictionary[key].report;
      const reportDialog = this.dialog.open(ReportModalComponent, {
        data: {
          reportPdf64: pdf64,
        },
      });

      reportDialog.afterClosed().subscribe({
        next: () => {
          this.reportDictionary[key].viewed = true;
          resolve();
        },
        error: (error) => {
          console.log(error);
          resolve();
        }
      });
    })
  }

  viewListReport(key: string) {
    this.reportDictionary[key].listReport.forEach(item => {
      const pdf64 = item;
      this.dialog.open(ReportModalComponent, {
        data: {
          reportPdf64: pdf64,
        },
      });
    });

    this.reportDictionary[key].viewed = true;
  }

  async openAll() {
    if (!this.data.sign && this.data.allReportsConcat){
      this.viewReport("allReportsConcat");
    }

    else {
      for (const [key, value] of Object.entries(this.reportDictionary)) {
        if (value.report) {
          await this.viewReport(key);
        }
        else if (value.listReport)
          await this.viewListReport(key);
      }
    }    
  }
}
