import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AlertService, AlertType } from '../../services/alert.service';
import { BLEDevice } from '../../services/responses/bluetooth-api/ble-device';
import { HttpErrorResponse } from "@angular/common/http";
import { BleErrorModalComponent } from '../ble-error-modal/ble-error-modal.component';
import { environment } from 'src/environments/environment';
import { MeasurerService } from '../../services/API/measurer/measurer.service';

@Component({
  selector: 'app-ble-scan-modal',
  templateUrl: './ble-scan-modal.component.html',
  styleUrls: ['./ble-scan-modal.component.css']
})
export class BleScanModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialogRef: MatDialogRef<BleScanModalComponent>,
    private activatedRoute: ActivatedRoute,
    private measurerService: MeasurerService,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private dialog: MatDialog,
  ) { }

  public model: FormGroup;
  public searchText: string = "";
  public isScanning: boolean = false;
  public isConnecting: boolean = false;
  public listDevice: BLEDevice[] = [];
  public listDeviceDisplay: BLEDevice[] = [];

  public bluetoothExecutableName: string = environment.bluetoothAPIExecutableName;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      deviceAddress: [null, Validators.required],
      isConnected: [false, Validators.requiredTrue]
    });
    this.scanDevices();
  }

  scanDevices() {
    this.isScanning = true;
    this.measurerService.scan().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isScanning = false;
          return;
        }
        this.listDevice = response.devices;
        this.listDeviceDisplay = this.listDevice;
        this.isScanning = false;
      },
      error: (error: HttpErrorResponse) => {
        console.log('Erro: ', error);
        let errorMessage = '';
        if (error.status === 0) {
          this.dialog.open(BleErrorModalComponent);
          errorMessage = `Aplicativo ${this.bluetoothExecutableName} não encontrado, trate de baixar e rodar!`;
        }
        else {
          errorMessage = error.message;
        }
        this.alertService.show('Erro inesperado', errorMessage, AlertType.error);
        this.isScanning = false;
        this.matDialogRef.close();
      }
    });
  }

  onKeySearch() {
    setTimeout(() => {
      if (this.searchText) {
        this.listDeviceDisplay = this.listDevice.filter(x => x.name != undefined && x.name.trim().toLowerCase().includes(this.searchText))
      }
      else {
        this.listDeviceDisplay = this.listDevice;
      }
    }, 500);
  }

  selectItem(item: BLEDevice) {
    this.isConnecting = true;
    this.measurerService.connect(item.address).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isConnecting = false;
          this.scanDevices();
          return;
        }
        else {
          this.matDialogRef.close({ device: item })
        }
        this.isConnecting = false;
      },
      error: (error: HttpErrorResponse) => {
        console.log('Erro: ', error);
        let errorMessage = '';
        if (error.status === 0) {
          this.dialog.open(BleErrorModalComponent);
          errorMessage = `Aplicativo ${this.bluetoothExecutableName} não encontrado, trate de baixar e rodar!`;
        }
        else {
          errorMessage = error.message;
        }
        this.alertService.show('Erro inesperado', errorMessage, AlertType.error);
        this.isScanning = false;
        this.matDialogRef.close();
      }
    });

  }

  clickCancel() {
    if (this.isScanning || this.isConnecting) {
      return;
    }
    this.matDialogRef.close();
  }

  submit() {

  }
}
