<div class="inner-nav">

    <ul *ngIf="systemMainMenu != null">
        <li *ngFor="let item of systemMainMenu">
            <a (click)="selectItem(item, sidenavSubMenu)" [ngClass]="{'active': item.active}">
                <mat-icon aria-hidden="false" aria-label="Menu apps">{{item.iconClass}}</mat-icon>
                <span>{{item.name}}</span>
            </a>
        </li>
    </ul>
</div>

<mat-drawer-container class="container-sidenav" [hasBackdrop]="true">
    <mat-drawer #sidenavSubMenu class="sub-menu-sidenav" mode="over">
        <app-sub-menu *ngIf="isVisibleSubMenu" [isVisible]="isVisibleSubMenu"
            (closeSubMenu)="closeSubMenu(sidenavSubMenu)" [actualMainMenuName]="openedMainMenu" [listSubMenuStruct]="selectedSubMenu">
        </app-sub-menu>
    </mat-drawer>
</mat-drawer-container>


<!-- <div class="container-out" (click)="close()" *ngIf="isVisible == true"></div>

<div class="container-modulo-menu" *ngIf="isVisible == true">
    <nav>
        <div class="inner-nav">
            <ul *ngIf="systemMainMenu != null">
                <li *ngFor="let item of systemMainMenu | slice:0:3">
                    <a (click)="selectItem(item)" [ngClass]="{'active': item.active}">
                        <mat-icon aria-hidden="false" aria-label="Menu apps">{{item.iconClass}}</mat-icon>
                        <span>{{item.name}}</span>
                    </a>
                </li>

                <li *ngIf="systemMainMenu.length > 3" class="menu-more-mobile">
                    <a (click)="toggleMenuMobile()">
                        <mat-icon aria-hidden="false" aria-label="Menu apps">more_horiz</mat-icon>
                        <span>Mais</span>
                    </a>
                </li>
            </ul>



            <div class="plus-menu-mobile active" [ngClass]="{'active': isMenuMobileOpen}">
                <span (click)="closeMenuMobile()">Fechar</span>
                <ul *ngIf="systemMainMenu.length > 3">
                    <li *ngFor="let item of systemMainMenu | slice:3">
                        <a (click)="selectItem(item)" [ngClass]="{'active': item.active}">
                            <mat-icon aria-hidden="false" aria-label="Menu apps">{{item.iconClass}}</mat-icon>
                            <span>{{item.name}}</span>
                        </a>
                    </li>
                </ul>
            </div>



        </div>
    </nav>
</div> -->