import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KpiStatusEnum } from '../../enum/flow/kpi-status.enum';

@Component({
  selector: 'app-kpi-status',
  templateUrl: './kpi-status.component.html',
  styleUrls: ['./kpi-status.component.css']
})
export class KpiStatusComponent implements OnInit {

  @Input() ok: boolean = false;
  @Input() alert: boolean = false;
  @Input() beyondGoal: boolean = false;
  @Input() isLoading: boolean;
  @Output() selectedStatus: EventEmitter<any> = new EventEmitter<any>();

  selectedItems: Array<number> = new Array<number>();
  public okColor: string = "accent";
  public alertColor: string = "accent";
  public beyondGoalColor: string = "accent";

  constructor() { }

  ngOnInit(): void {
  }

  selectOption(option: string): void {
    this.selectedItems = [];
    switch (option) {
      case 'ok': {
        this.ok = !this.ok;
        break;
      }
      case 'alert': {
        this.alert = !this.alert;
        break;
      }
      case 'beyondGoal': {
        this.beyondGoal = !this.beyondGoal;
        break;
      }
    }

    this.changeSelection();

    if (this.ok) this.selectedItems.push(KpiStatusEnum.ok);
    if (this.alert) this.selectedItems.push(KpiStatusEnum.alert);
    if (this.beyondGoal) this.selectedItems.push(KpiStatusEnum.beyondGoal);

    this.selectedStatus.emit(this.selectedItems);
  }

  changeSelection() {
    this.okColor = this.ok ? "primary" : "accent";
    this.alertColor = this.alert ? "primary" : "accent";
    this.beyondGoalColor = this.beyondGoal ? "primary" : "accent";
  }
}

