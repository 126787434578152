export class AppointmentGuide {
    public idAppointmentGuide: number;
    public isDeleted: boolean;
    public guideOperatorNumber: string;
    public beneficiaryCardNumber: string;
    public walletBeneficiaryValidity: Date;
    public newborn: boolean;
    public executionContractorOperatorCode: string;
    public contractorName: string;
    public contractorCnes: string;
    public idProfessionalAgreement: number;
    public professionalName: string;
    public idProfessionalCounseling: number;
    public counselingNumber: number;
    public idProfessionalUf: number;
    public idProfessionalCbo: number;
    public idAccidentIndication: number;
    public idSpecialCoverage: number;
    public idCareRegime: number;
    public idOccupationalHealth: number;
    public completionDate: Date;
    public idAppointmentType: number;
    public procedureCode: string;
    public procedureDescription: string;
    public justification: string;
    public idPriceProcedurePlan: number;
    public idPriceProcedureHealthcare: number;
    public procedureValue: number;
    public idProviderIdentityType: number;
}