import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { MedicalCareReportService } from 'src/app/shared/services/API/orchestrator-patient/medical-care-report.service';
import { MedicalCareReportRequest } from 'src/app/shared/services/requests/orchestrator-patient/medical-care-report.request';
import { ReadMedicalPrescriptionStruct } from 'src/app/shared/services/structs/orchestrator-patient/read-medical-prescription.struct';
import { AdministrationTypeEnum, DilutionEnum, FrequencyEnum, PresentationEnum, UsageEnum } from 'src/app/shared/enum/prescription.enum';
import { ReadExternalPrescriptionStruct } from 'src/app/shared/services/structs/orchestrator-patient/read-external-prescription.struct';
import { DownloadReportService } from 'src/app/shared/services/API/medical-record/download-report.service';
import { MedicalProcedureModel } from 'src/app/shared/services/models/medical-record/medical-procedure.model';
import { MedicalCareSadtStruct } from '../../services/structs/medical-record/medical-care-sadt.struct';

@Component({
  selector: 'app-report-select-modal',
  templateUrl: './report-select-modal.component.html',
  styleUrls: ['./report-select-modal.component.css']
})
export class ReportSelectModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ReportSelectModalComponent>,
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    private maskService: MaskService,
    private medicalCareReportService: MedicalCareReportService,
    private downloadReportService: DownloadReportService
  ) { }

  public usageInternalEnum: UsageEnum = UsageEnum.interno;
  public usageExternalEnum: UsageEnum = UsageEnum.externo;
  public reportModel: UntypedFormGroup;
  public isLoading: boolean;
  public masks: Masks = this.maskService.getMasks();
  public sickNote: boolean = false;
  public checkedInternal: boolean = false;
  public checkedExternal: boolean = false;
  public isEpisodeReport: boolean = false;
  public listPrescription: ReadMedicalPrescriptionStruct[] = [];
  public listExternalPrescription: ReadExternalPrescriptionStruct[] = [];
  public listMedicalProcedure: MedicalProcedureModel[] = [];
  public listSADT: MedicalCareSadtStruct[] = [];
  public request: MedicalCareReportRequest = new MedicalCareReportRequest();
  public administrationTypeOthersEnum: AdministrationTypeEnum = AdministrationTypeEnum.outros;
  public presentationOthersEnum: PresentationEnum = PresentationEnum.outros;
  public dilutionOthersEnum: DilutionEnum = DilutionEnum.outros;
  public satdOthers: number = 0;
  public hasInternalExam: boolean = false;
  public hasExternalExam: boolean = false;
  public hasInternalPrescription: boolean = false;
  public hasExternalPrescription: boolean = false;
  public hasMedicalProcedure: boolean = false;
  public sickDaysOffAmount: number;
  public datetimeEndIsolation: Date;
  public isCompletedReport: boolean = true;
  public isOnline: boolean = false;
  public patientEmail: string;
  public cpf: string;

  ngOnInit(): void {
    this.listSADT = this.data.listSADT;
    this.listPrescription = this.data.listPrescription;
    this.sickDaysOffAmount = this.data.sickDaysOffAmount;
    this.listExternalPrescription = this.data.listExternalPrescription;
    this.listMedicalProcedure = this.data.listMedicalProcedure;
    if (this.data.isOnline){
      this.isOnline = this.data.isOnline
    }
    else{
      this.isOnline = false;
    }
    this.patientEmail = this.data.patientEmail,
    this.cpf = this.data.cpf;
    this.isLoading = false;
    this.datetimeEndIsolation = this.data.medicalCare.datetimeEndIsolation
    this.reportModel = this.formBuilder.group({
      downloadCompleteReport: [false],
      examExternal: [false],
      examInternal: [false],
      prescriptionExternal: [false],
      externalPrintCount: [1],
      prescriptionInternal: [false],
      sickNote: [false],
      internalPrintCount: [1],
      isMedicalProcedureReport: [false],
      isEpisodeReport: [this.isEpisodeReport],
      isolation: [false],
      isolationAcknowledge: [false],
    });
    this.verifyExamAndPrescription();
  }

  onChangeExternal(event: any) {
    this.checkedExternal = event;
    if (event) {
      this.reportModel.get('externalPrintCount').setValidators([Validators.required]);
      this.reportModel.get('externalPrintCount').updateValueAndValidity();
    } else {
      this.reportModel.get('externalPrintCount').setValidators([]);
      this.reportModel.get('externalPrintCount').updateValueAndValidity();
      this.reportModel.get('externalPrintCount').setValue(1);
    }
  }

  onChangeInternal(event: any) {
    this.checkedInternal = event;
    if (event) {
      this.reportModel.get('internalPrintCount').setValidators([Validators.required]);
      this.reportModel.get('internalPrintCount').updateValueAndValidity();
    } else {
      this.reportModel.get('internalPrintCount').setValidators([]);
      this.reportModel.get('internalPrintCount').updateValueAndValidity();
      this.reportModel.get('internalPrintCount').setValue(1);
    }
  }


  onChangeDownloadComplete(event: any) {
    if (event) {
      this.onChangeInternal(false);
      this.onChangeExternal(false);
      this.isCompletedReport = false;
      this.reportModel.get('examExternal').setValue(true);
      this.reportModel.get('internalPrintCount').setValue(1);
      this.reportModel.get('examInternal').setValue(true);
      this.reportModel.get('prescriptionExternal').setValue(true);
      this.reportModel.get('prescriptionInternal').setValue(true);
      this.reportModel.get('sickNote').setValue(true);
      this.reportModel.get('isolation').setValue(true);
      this.reportModel.get('isolationAcknowledge').setValue(true);
      this.reportModel.get('externalPrintCount').setValue(1);
      this.reportModel.get('isEpisodeReport').setValue(true);
      this.reportModel.get('isMedicalProcedureReport').setValue(true);
    } else {
      this.isCompletedReport = true;
      this.reportModel.get('examExternal').setValue(false);
      this.reportModel.get('examInternal').setValue(false);
      this.reportModel.get('prescriptionExternal').setValue(false);
      this.reportModel.get('prescriptionInternal').setValue(false);
      this.reportModel.get('sickNote').setValue(false);
      this.reportModel.get('isolation').setValue(false);
      this.reportModel.get('isolationAcknowledge').setValue(false);
      this.reportModel.get('isEpisodeReport').setValue(false);
      this.reportModel.get('isMedicalProcedureReport').setValue(false);
    }
  }

  clickOK() {
    if (this.isLoading) {
      return;
    }
    this.selectReports(this.reportModel.get('downloadCompleteReport').value);
  }

  printCompleteReport() {
    this.isLoading = true;
    this.downloadReportService.downloadCompleteReport(this.data.idEpisode).subscribe({next: (response) => {
      if (response.isError && response.errorCode == 1) {
        this.alertService.show('Aviso', response.errorDescription, AlertType.warning);
        this.isLoading = false;
        return;
      }
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.matDialogRef.close();
        return;
      }
      if (this.hasAnyPrint()) {
        this.matDialogRef.close({ response });
      }
    },
      error:(error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });

    if (!this.hasAnyPrint()) {
      this.matDialogRef.close();
    }
  }


  selectReports(isCompleted: boolean) {
    if (this.reportModel.invalid) {
      this.alertService.show('Erro', "Preencha todos os campos obrigatórios!", AlertType.error);
      return;
    }

    if (this.reportModel.get('internalPrintCount').value <= 0) {
      this.alertService.show('Erro', "A prescrição interna deve ter no mínimo uma via.", AlertType.error);
      return;
    }
    if (this.reportModel.get('externalPrintCount').value <= 0) {
      this.alertService.show('Erro', "A prescrição externa deve ter no mínimo uma via.", AlertType.error);
      return;
    }

    let prescriptionExternal = this.reportModel.get('prescriptionExternal').value;
    let prescriptionInternal = this.reportModel.get('prescriptionInternal').value;
    this.request.internalPrescriptionPrintCount = this.reportModel.get('internalPrintCount').value;
    this.request.externalPrescriptionPrintCount = this.reportModel.get('externalPrintCount').value;
    this.request.isSickNoteReport = this.reportModel.get('sickNote').value;
    this.request.isCompleteMedicalCareReport = this.reportModel.get('isEpisodeReport').value;
    this.request.isExamExternalReport = this.reportModel.get('examExternal').value;
    this.request.isExamInternalReport = this.reportModel.get('examInternal').value;
    this.request.isPrescriptionReport = (prescriptionExternal || prescriptionInternal) ? true : false;
    this.request.isMedicalProcedureReport = this.reportModel.get('isMedicalProcedureReport').value;
    this.request.isIsolationReport = this.reportModel.get('isolation').value;
    this.request.isIsolationAcknowledgeReport = this.reportModel.get('isolationAcknowledge').value;
    this.request.isBedManagement = this.data.isBedManagement;
    this.request.idBed = this.data.idBed;
    this.request.idSector = this.data.idSector;
    this.request.isOnline = this.isOnline;
    this.request.email = this.data.patientEmail;

  
    this.isLoading = true;

    this.medicalCareReportService.getReportPDF(this.data.idMedicalCare, this.request).subscribe((response) => {
      if (response.isError) {  
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.matDialogRef.close();
        return;
      }

      if (isCompleted) {
        this.printCompleteReport();
      } else 
      if (this.hasAnyPrint()) {
        this.matDialogRef.close({ response });
      } else {
        this.matDialogRef.close();
      }
    });
  }

  hasAnyPrint(): boolean {
    return this.request.isSickNoteReport ||
      this.request.isCompleteMedicalCareReport ||
      this.request.isExamInternalReport ||
      this.request.isExamExternalReport ||
      this.request.isPrescriptionReport ||
      this.request.isIsolationReport ||
      this.request.isIsolationAcknowledgeReport ||
      this.request.isMedicalProcedureReport;
  }

  verifyExamAndPrescription() {
    this.hasInternalPrescription = this.listPrescription.some(x => x.idUsage == UsageEnum.interno);
    this.hasExternalPrescription = this.listExternalPrescription.length > 0;
    this.hasExternalExam = this.listSADT.some(x => x.idPlace == UsageEnum.externo);
    this.hasInternalExam = this.listSADT.some(x => x.idPlace == UsageEnum.interno);
    this.hasMedicalProcedure = this.listMedicalProcedure.length > 0;
  }
}

