<div class="table-container">
    <form class="form" [formGroup]="model">
        <app-loading-progress-bar *ngIf="isLoading"></app-loading-progress-bar>
        <table class="basic-table desktop col-12">
            <thead class="basic-table__head">
                <tr>
                    <th class="column-table">Tabela</th>
                    <th class="column-code-procedure">Código do Procedimento ou Item Assistencial</th>
                    <th class="column-description">Descrição</th>
                    <th class="column-requester-count ">Qtde. Solic.</th>
                    <th class="column-requester-authorizated-count">Qtde. Aut.</th>
                    <th class="column-action">Ações</th>
                </tr>
            </thead>
            <tbody class="basic-table__body">
                <tr class="basic-table__body__row"
                    *ngFor="let item of listSpSadtRequestedProcedure; let index = index;">
                    <td class="truncate"><span>{{item.terminologyGroupCode}}</span></td>
                    <td class="text-description" matTooltip="{{item.procedure.terminology.terminologyCode}}">
                        <span>{{item.procedure.terminology.terminologyCode}}</span>
                    </td>
                    <td class="truncate"><span>{{item.procedure.terminology.terminologyNameTuss}}</span></td>
                    <td class="truncate"><span>{{item.requestedQuantityProcedureServiceItem}}</span></td>
                    <td class="truncate"><span>{{item.authorizedQuantityProcedureServiceItem}}</span></td>
                    <td *ngIf="isEditingAllowed != null">
                        <a class="action-icon"
                            *ngIf="(isEditingAllowed == null) || (isEditingAllowed != null && isEditingAllowed)">
                            <mat-icon aria-hidden="false" aria-label="Editar" (click)="editItem(item)">
                                edit
                            </mat-icon>
                        </a>
                        <a class="action-icon" *ngIf="(isEditingAllowed == null) || (isEditingAllowed != null && isEditingAllowed)">
                            <mat-icon aria-hidden="false" aria-label="Editar" (click)="deleteItem(item)">
                                delete
                            </mat-icon>
                        </a>
                    </td>
                    <td *ngIf="isEditingAllowed == null">
                        <a class="action-icon">
                            <mat-icon aria-hidden="false" aria-label="Editar" (click)="editItem(item)">
                                edit
                            </mat-icon>
                        </a>
                        <a class="action-icon">
                            <mat-icon aria-hidden="false" aria-label="Editar" (click)="deleteItem(item)">
                                delete
                            </mat-icon>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
        <div [formGroup]="model" class="col-12 form-containter">
            <div class="organize-mat-form-table" *ngIf="showAdditionalFields">
                <div class="col-12 col-sm-6 col-md-2 ajust-field">
                    <mat-form-field appearance="outline">
                        <mat-label>24 - Tabela</mat-label>
                        <mat-select formControlName="idTerminologyGroup" (selectionChange)="selectTable($event)">
                            <mat-option *ngFor="let item of tussTerminologyGroupMap | keyvalue"
                                [value]="item.value.idTerminologyGroup" [disabled]="item.value.idTerminologyGroup !== procedimentos">{{item.value.terminologyGroupCode}} - {{item.value.nameTerminologyGroup}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="model.get('idTerminologyGroup').invalid">Preenchimento incorreto</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 ajust-field">
                    <mat-form-field appearance="outline">
                        <mat-label>25/26 - Cod do Procedimento ou Item Assistencial/Descrição</mat-label>
                        <mat-select matInput type="text" formControlName="procedure"
                            (selectionChange)="selectProcedure($event.value)">
                            <input class="inputSearchSelect" type="text" (keyup)="onKey($event.target.value)"
                                placeholder="Pesquisar" autocomplete="off" (keydown)="$event.stopPropagation()">
                            <mat-option *ngFor="let item of listProcedure"
                                [value]="item">{{item.terminology.terminologyCode}} -
                                {{item.terminology.terminologyName}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="model.get('procedure').invalid">Preenchimento incorreto</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-2 ajust-field">
                    <mat-form-field appearance="outline">
                        <mat-label>27 - Qtde. Solic.</mat-label>
                        <input matInput type="text"
                            [maxlength]="spSadtFieldsMaxLengthEnum.requestedQuantityProcedureServiceItem"
                            formControlName="requestedQuantityProcedureServiceItem" (input)="inputNumberMinOne('requestedQuantityProcedureServiceItem')"
                            oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                        <mat-error
                            *ngIf="model.get('requestedQuantityProcedureServiceItem').invalid && !model.get('requestedQuantityProcedureServiceItem').errors?.maxlength">Preenchimento
                            incorreto</mat-error>
                        <mat-hint align="end">{{ model.get('requestedQuantityProcedureServiceItem').value ?
                            model.get('requestedQuantityProcedureServiceItem').value.length : 0
                            }}/{{spSadtFieldsMaxLengthEnum.requestedQuantityProcedureServiceItem}}</mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-2 ajust-field">
                    <mat-form-field appearance="outline">
                        <mat-label>28 - Qtde. Aut.</mat-label>
                        <input matInput type="text"
                            [maxlength]="spSadtFieldsMaxLengthEnum.authorizedQuantityProcedureServiceItem"
                            formControlName="authorizedQuantityProcedureServiceItem"
                            oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');">
                        <mat-error
                            *ngIf="model.get('authorizedQuantityProcedureServiceItem').invalid && !model.get('authorizedQuantityProcedureServiceItem').errors?.maxlength">Preenchimento
                            incorreto</mat-error>
                        <mat-hint align="end">{{ model.get('authorizedQuantityProcedureServiceItem').value ?
                            model.get('authorizedQuantityProcedureServiceItem').value.length : 0
                            }}/{{spSadtFieldsMaxLengthEnum.authorizedQuantityProcedureServiceItem}}</mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-1 button-included" *ngIf="showAdditionalFields">
                    <button mat-flat-button type="button" color="primary" class="btn-block"
                        [disabled]="!isValidNewRule()" (click)="submit()">
                        <span *ngIf="!isLoading">Salvar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                    </button>
                    <button mat-flat-button type="button" class="btn-cancel" (click)="cancelEdit()">
                        <span *ngIf="!isLoading">X</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                    </button>
                </div>
            </div>
            <div class="button-add col-12" *ngIf="!showAdditionalFields && isEditingAllowed != null">
                <button color="accent" color="primary" mat-flat-button type="button"
                    (click)="showAdditionalFields = true;"
                    [disabled]="!healthcareAgreementStruct.idHealthcareAgreement || !this.isEditingAllowed">
                    Adicionar procedimento solicitado +
                </button>
            </div>
            <div class="button-add col-12" *ngIf="!showAdditionalFields && isEditingAllowed == null">
                <button color="accent" color="primary" mat-flat-button type="button"
                    (click)="showAdditionalFields = true;"
                    [disabled]="!healthcareAgreementStruct.idHealthcareAgreement">
                    Adicionar procedimento solicitado +
                </button>
            </div>
        </div>
    </form>
</div>