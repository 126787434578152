import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alert-greater-gravity-modal',
  templateUrl: './alert-greater-gravity-modal.component.html',
  styleUrls: ['./alert-greater-gravity-modal.component.css']
})
export class AlertGreaterGravityModalComponent {

  constructor(@Inject(MAT_DIALOG_DATA) private data: any, public dialog: MatDialog, public matDialogRef: MatDialogRef<AlertGreaterGravityModalComponent>) { }

  public vitalSignName: string;
  public newValue: string;
  public oldValue: string;


  ngOnInit(): void {
    this.vitalSignName = this.data.vitalSignName;
    this.newValue = this.data.newValue;
    this.oldValue = this.data.oldValue;
  }

  close() {
    this.matDialogRef.close();
  }
}

