<div class="container-out" (click)="close()" *ngIf="isVisible == true"></div>


<div class="arrow-up"></div>
<div class="container-rank slide-bottom" *ngIf="isVisible == true">
    <div class="sector-rank">
        <h2>Ranking {{showSectorRankingTitle ? 'de ' + sectorName : ''}}</h2>
        <a class="my-tooltip" mat-mini-fab
            matTooltip="O cálculo da pontuação é obtido por uma média ponderada, que considera o volume total de atendimentos realizados durante um turno de seis ou 12 horas !"
            matTooltipPosition="below" aria-label="Conta de gamificacao">
            <mat-icon class="my-tooltip-icon">question_mark</mat-icon>
        </a>
    </div>

    <h3>Resultados de hoje:</h3>
    <div class="medal-rank">
        <div class="second-and-third flex-item-1">
            <div class="position" style="background-color:#b5bec0ec">
                2°
            </div>
            <mat-icon class="silver-icon" aria-hidden="false" aria-label="rank de classificação">military_tech
            </mat-icon>
            <div class="points">{{listRankViewStruct.length > 1 ? listRankViewStruct[1].areaRanking.point : 0}} {{listRankViewStruct.length > 1 && listRankViewStruct[1].areaRanking.point > 1 ? 'pontos' : 'ponto'}}</div>
        </div>
        <div class="first flex-item-1">
            <div class="position" style="background-color:rgb(255, 189, 4)">
                1°
            </div>
            <mat-icon class="gold-icon" aria-hidden="false" aria-label="rank de classificação">military_tech</mat-icon>
            <div class="points">{{listRankViewStruct.length >= 1 ? listRankViewStruct[0].areaRanking.point : 0}} {{listRankViewStruct.length >= 1 && listRankViewStruct[0].areaRanking.point > 1 ? 'pontos' : 'ponto'}}</div>
        </div>
        <div class="second-and-third flex-item-1">
            <div class="position" style="background-color:#c26712d8">
                3°
            </div>
            <mat-icon class="bronze-icon" aria-hidden="false" aria-label="rank de classificação">military_tech
            </mat-icon>
            <div class="points">{{listRankViewStruct.length > 2 ? listRankViewStruct[2].areaRanking.point : 0}} {{listRankViewStruct.length > 2 && listRankViewStruct[2].areaRanking.point > 1 ? 'pontos' : 'ponto'}}</div>
        </div>
    </div>
    <div class="position-and-ranking">
        <mat-icon class="flag-icon" aria-hidden="false" aria-label="bandeira de posicao">outlined_flag</mat-icon>
        <h3>Sua posição</h3>        
    </div>
    
    <div class="card-list" *ngIf="listRankViewStruct != null">
        <div class="card-item rank-card-user">
            <div  class="number-name-and-icon">
                <div class="with-icon" *ngIf= "userUpDownRanking != 0">
                    {{userRanking}}°
                    <mat-icon *ngIf="userUpDownRanking == -1" class="up-icon">keyboard_double_arrow_up</mat-icon>
                    <mat-icon *ngIf="userUpDownRanking == 1" class="down-icon">keyboard_double_arrow_down</mat-icon>
                </div>
                <div class="without-icon" *ngIf= "userUpDownRanking == 0">
                    {{userRanking}}°                            
                </div>
                <p class="name">{{userName}}</p>
            </div>
            <span>{{userPoint}} {{userPoint > 1 ? 'pontos':'ponto'}}</span>                        
        </div>
        <div class="row rank-all-positions" *ngIf = "showRankingList">
            <div *ngFor="let item of listRankViewStruct" class="col-12">
                <div class="card-item rank-card">
                    <div class="number-name-and-icon">
                        <div class="with-icon" *ngIf= "item.upDownOrEqualPosition != 0">
                            {{item.areaRanking.ranking}}°
                            <mat-icon *ngIf="item.upDownOrEqualPosition == -1" class="up-icon">keyboard_double_arrow_up</mat-icon>
                            <mat-icon *ngIf="item.upDownOrEqualPosition == 1" class="down-icon">keyboard_double_arrow_down</mat-icon>
                        </div>
                        <div class="without-icon" *ngIf= "item.upDownOrEqualPosition == 0">
                            {{item.areaRanking.ranking}}°                            
                        </div>
                        <p class="name" [ngClass]="{'blurred' : !showNames}">{{showNames? item.areaRanking.userName : "**********"}}</p>
                    </div>
                    <span>{{item.areaRanking.point}} {{item.areaRanking.point > 1 ? 'pontos':'ponto'}}</span>                        
                   
                </div>
            </div>
        </div>
    </div>
</div>