import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StatusQueueEnum } from '../../enum/status-queue.enum';
import { UtilService } from '../../services/util.service';
import { MenuModuleEnum } from '../menu/menu.module.enum';
import { UserHasModuleStruct } from '../../structs/user-has-module.struct';
import { KpiStatusEnum } from '../../enum/flow/kpi-status.enum';

@Component({
  selector: 'app-attendance-status',
  templateUrl: './attendance-status.component.html',
  styleUrls: ['./attendance-status.component.css']
})
export class AttendanceStatusComponent implements OnInit {

  @Input() evadidos: boolean = false;
  @Input() atendidos: boolean = false;
  @Input() naoAtendidos: boolean = false;
  @Input() retornados: boolean = false;
  @Input() agendados: boolean = false;
  @Input() ok: boolean = false;
  @Input() alert: boolean = false;
  @Input() beyondGoal: boolean = false;
  @Input() isLoading: boolean;
  @Input() isFlowManagement: boolean = false;
  @Input() returnCount: number;
  @Input() scheduleCount: number;
  @Input() isMedic: boolean = false;
  @Output() selectedStatus: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedKPIStatus: EventEmitter<any> = new EventEmitter<any>();

  selectedItems: Array<number> = new Array<number>();
  selectedKPIItems: Array<number> = new Array<number>();
  public evadidosColor: string = "accent";
  public atendidosColor: string = "accent";
  public naoAtendidosColor: string = "accent";
  public retornadosColor: string = "accent";
  public agendadosColor: string = "accent";
  public okColor: string = "accent";
  public alertColor: string = "accent";
  public beyondGoalColor: string = "accent";
  public hasScheduleFilter: boolean = false;

  constructor(private utilService: UtilService,) { }

  ngOnInit(): void {
    this.verifyUserHasScheduleAndDigitalPrompt();
  }

  verifyUserHasScheduleAndDigitalPrompt(){
    let listUserHasModule: UserHasModuleStruct[] = this.utilService.validateUserHasModuleList([MenuModuleEnum.digital_prompt_service, MenuModuleEnum.digital_prompt_service]);

    let notHaveScheduleOrDigitalPrompt: boolean = listUserHasModule.map(item => item.userHasModule).includes(false);

    if (notHaveScheduleOrDigitalPrompt)
      this.hasScheduleFilter = false;
    else
      this.hasScheduleFilter = true;
  }

  selectOption(option: string): void {
    this.selectedItems = [];
    switch (option) {
      case 'evadidos': {
        this.evadidos = !this.evadidos;
        break;
      }
      case 'atendidos': {
        this.atendidos = !this.atendidos;
        break;
      }
      case 'naoAtendidos': {
        this.naoAtendidos = !this.naoAtendidos;
        break;
      }
      case 'retornados': {
        this.retornados = !this.retornados;
        break;
      }
      case 'agendados': {
        this.agendados = !this.agendados;
        break;
      }
    }

    this.changeSelection();

    if (this.evadidos) this.selectedItems.push(StatusQueueEnum.evadido);
    if (this.atendidos) this.selectedItems.push(StatusQueueEnum.atendido);
    if (this.naoAtendidos) this.selectedItems.push(StatusQueueEnum.nao_atendido);
    if (this.naoAtendidos) this.selectedItems.push(StatusQueueEnum.chamando);
    if (this.naoAtendidos) this.selectedItems.push(StatusQueueEnum.em_atendimento);
    if (this.retornados) this.selectedItems.push(StatusQueueEnum.retorno);

    this.selectedStatus.emit({selectedItems: this.selectedItems, agendados: this.agendados});
  }

  selectOptionKPI(option: string): void {
    this.selectedKPIItems = [];
    switch (option) {
      case 'ok': {
        this.ok = !this.ok;
        break;
      }
      case 'alert': {
        this.alert = !this.alert;
        break;
      }
      case 'beyondGoal': {
        this.beyondGoal = !this.beyondGoal;
        break;
      }
    }

    this.changeSelection();

    if (this.ok) this.selectedKPIItems.push(KpiStatusEnum.ok);
    if (this.alert) this.selectedKPIItems.push(KpiStatusEnum.alert);
    if (this.beyondGoal) this.selectedKPIItems.push(KpiStatusEnum.beyondGoal);

    this.selectedKPIStatus.emit(this.selectedKPIItems);

  }

  changeSelection() {
    this.naoAtendidosColor = this.naoAtendidos ? "primary" : "accent";
    this.atendidosColor = this.atendidos ? "primary" : "accent";
    this.evadidosColor = this.evadidos ? "primary" : "accent";
    this.retornadosColor = this.retornados ? "primary" : "accent";
    this.agendadosColor = this.agendados ? "primary" : "accent";
    this.okColor = this.ok ? "primary" : "accent";
    this.alertColor = this.alert ? "primary" : "accent";
    this.beyondGoalColor = this.beyondGoal ? "primary" : "accent";
  }
}