<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-totem-modal-list">
            <h2>Deseja atualizar o PIN deste {{data.equipment}}?</h2>
        </div>

        <div class="body-totem-modal-list">
            Você precisará inserir este novo PIN no equipamento.
        </div>
        <div class="footer-totem-modal-list">
            <button mat-flat-button color="primary" class="btn-block" (click)="clickConfirm()">
                <span *ngIf="isLoading == false">Confirmar</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
            <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Cancelar</button>
        </div>
    </div>
</div>