import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { StatusQueueRequest } from '../../requests/queue/status-queue.request';

@Injectable({
  providedIn: 'root'
})
export class UpdateStatusQueueService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public updateStatus(statusQueueRequest: StatusQueueRequest): Observable<ReturnStruct> {
    let uri = `UpdateStatusQueue`;
      
    return this.httpClient.post<ReturnStruct>(environment.urlApiOrchestratorQueue + uri, statusQueueRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public updateQueueToOldStatus(idQueue: number, idEpisode: number): Observable<ReturnStruct> {
    let uri = `UpdateStatusQueue/returnQueueToLastStatus/idQueue/${idQueue}/idEpisode/${idEpisode}`;
      
    return this.httpClient.post<ReturnStruct>(environment.urlApiOrchestratorQueue + uri, null, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public updateStatusBedLink(idService: number, statusQueueRequest: StatusQueueRequest): Observable<ReturnStruct> {
    let uri = `UpdateStatusQueue/bed/idService/${idService}`;
      
    return this.httpClient.post<ReturnStruct>(environment.urlApiOrchestratorQueue + uri, statusQueueRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
