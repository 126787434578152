import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-remove-active-principle-modal',
  templateUrl: './remove-active-principle-modal.component.html',
  styleUrls: ['./remove-active-principle-modal.component.css']
})
export class RemoveActivePrincipleModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<RemoveActivePrincipleModalComponent>,
    private formBuilder: FormBuilder,) { }


  ngOnInit(): void {

  }

  close() {
    this.matDialogRef.close({ isRemoved: false });
  }

  submit() {
    this.matDialogRef.close({ isRemoved: true });
  }

  clickCancel() {
    this.matDialogRef.close({ isRemoved: false });
  }
}
