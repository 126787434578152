import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService, AlertType } from '../../services/alert.service';
import { BedStruct } from '../../services/structs/bed/bed.struct';
import { BedEpisodeRequest } from '../../services/requests/bed/bed-episode.request';
import { StatusQueueEnum } from '../../enum/status-queue.enum';
import { StatusQueueRequest } from '../../services/requests/queue/status-queue.request';
import { HygienizationStatusEnum } from '../../enum/hygienization-status.enum';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BedEpisodeService } from '../../services/API/bed/bed-episode.service';
import { FreeBedService } from '../../services/API/bed/free-bed.service';
import { UpdateStatusQueueService } from '../../services/API/orchestrator-queue/update-status-queue.service';

@Component({
  selector: 'app-patient-link-bed-modal',
  templateUrl: './patient-link-bed-modal.component.html',
  styleUrls: ['./patient-link-bed-modal.component.css']
})
export class PatientLinkBedModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<PatientLinkBedModalComponent>,
    private alertService: AlertService,
    private bedEpisodeService: BedEpisodeService,
    private freeBedService: FreeBedService,
    private formBuilder: FormBuilder,
    private updateStatusQueueService: UpdateStatusQueueService,
    private router: Router,
  ) { }

  public isLoading: boolean;
  public model: FormGroup;
  public listFreeBed: BedStruct[] = [];
  public idSector: number;
  public hygienization: boolean = false;
  public hygienizedStatus = HygienizationStatusEnum.higienizado;
  public hygienizingStatus = HygienizationStatusEnum.em_higienizacao;
  public unhygienizedStatus = HygienizationStatusEnum.nao_higienizado;
  public inUseStatus = HygienizationStatusEnum.em_uso;
  public selectedBed: BedStruct;
  public unhygienizedAllocationConfirm: boolean = false;
  public oldStatusHygienization: number = null;
  public showTissGuideButton: boolean = false;
  public isMedicAllocateBed: boolean = this.data.isMedicAllocateBed ? this.data.isMedicAllocateBed : false;

  ngOnInit(): void {
    this.isLoading = false;
    this.idSector = this.data.idSector;
    this.hygienization = this.data.hygienization;
    this.showTissGuideButton = this.data.showTissGuideButton;
    this.populateFreeBedSelect();

    this.model = this.formBuilder.group({
      idBed: [null],
      unhygienizedAllocation: [false],
    });
  }

  close() {
    this.matDialogRef.close({ isAttend: false });
  }

  submit() {
    if (this.isLoading || !this.model.valid || this.isMedicAllocateBed) {
      return;
    }
    this.isLoading = true;

    if (!this.data.observationConfigSector) {
      this.alertService.show('Erro', "Esse setor não possui configuração do módulo vinculada a ele.", AlertType.error);
      this.isLoading = false;
      return;
    }
    if (this.data.observationConfigSector.observationConfig.isMandatory && !this.listFreeBed) {
      this.alertService.show('Erro', "Você não possui leitos livres para realizar o atendimento. Cadastre um leito novo ou libere um leito antes.", AlertType.error);
      this.isLoading = false;
      return;
    }
    if (this.data.observationConfigSector.observationConfig.isMandatory && !this.model.get('idBed').value) {
      this.alertService.show('Erro', "Você deve alocar o paciente a um leito antes de atende-lo.", AlertType.error);
      this.isLoading = false;
      return;
    }

    if (!this.model.get('idBed').value) {
      this.isLoading = false;
      this.router.navigate(['/observation/bed-management/details', { idEpisode: this.data.idEpisode, idSector: this.data.idSector, idService: this.data.idService, idRoom: this.data.idRoom, idBed: null, isBedManagement: false, idQueue: this.data.idQueue, showTissGuideButton: this.showTissGuideButton }]);
      this.matDialogRef.close({ isAttend: true });
    }
    else {
      this.linkBedEpisode();
    }
  }

  clickCancel() {
    this.matDialogRef.close({ isAttend: false });
  }

  isMedicAllocated() {
    this.matDialogRef.close({ idBed: this.model.get('idBed').value });
  }

  isMedicNoAllocated() {
    this.matDialogRef.close({ idBed: null });
  }

  populateFreeBedSelect() {
    this.freeBedService.listFreeBed(this.idSector, this.hygienization, this.data.idEpisode).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        if (response) {
          if (response.listBedStruct && response.listBedStruct.length > 0)
            this.listFreeBed = this.sortBedsByHygienizationStatus(response.listBedStruct);

          if (response.idBed)
            this.model.get('idBed').setValue(response.idBed);
        }
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  updateStatusPatient() {
    let updateStatusRequest: StatusQueueRequest = new StatusQueueRequest();

    updateStatusRequest.idStatusQueue = StatusQueueEnum.atendido;
    updateStatusRequest.idQueue = this.data.idQueue;
    updateStatusRequest.idEpisode = this.data.idEpisode;

    this.updateStatusQueueService.updateStatusBedLink(this.data.idService, updateStatusRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.alertService.show('Sucesso', "Paciente foi associado ao leito com sucesso", AlertType.success);
        this.matDialogRef.close({ isAttend: true });
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  linkBedEpisode() {
    let bedEpisodeRequest: BedEpisodeRequest = new BedEpisodeRequest();

    bedEpisodeRequest.idBed = this.model.get('idBed').value;
    bedEpisodeRequest.idEpisode = this.data.idEpisode;
    bedEpisodeRequest.idLastRoom = this.data.idRoom;
    bedEpisodeRequest.hygienizationConfig = this.hygienization;

    let bed: BedStruct = this.listFreeBed.find(x => x.idBed == bedEpisodeRequest.idBed);

    if (this.hygienization && (bed.idHygienizationStatus == this.hygienizingStatus || bed.idHygienizationStatus == this.unhygienizedStatus)) {

      bedEpisodeRequest.idBedHygienizationStatus = bed.idHygienizationStatus;
      this.model.get('unhygienizedAllocation').setValidators(Validators.requiredTrue);
      this.model.get('unhygienizedAllocation').updateValueAndValidity();
    }

    this.bedEpisodeService.linkBedEpisode(bedEpisodeRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.updateStatusPatient();
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  checkHygienization(event: any) {
    if (event.value) {
      let idBed = event.value;
      this.selectedBed = this.listFreeBed.find(x => x.idBed === idBed);

      if (this.hygienization && (this.selectedBed.idHygienizationStatus == this.hygienizingStatus || this.selectedBed.idHygienizationStatus == this.unhygienizedStatus)) {
        this.unhygienizedAllocationConfirm = true;
        this.model.get('unhygienizedAllocation').setValidators(Validators.requiredTrue);
        this.model.get('unhygienizedAllocation').updateValueAndValidity();
      }
      else {
        this.unhygienizedAllocationConfirm = false;
        this.model.get('unhygienizedAllocation').setValidators([]);
        this.model.get('unhygienizedAllocation').setValue(false);
        this.model.get('unhygienizedAllocation').updateValueAndValidity();
      }
    }
  }

  toggleConfirmation(event: any) {
    if (event?.checked ?? false) {
      this.oldStatusHygienization = this.selectedBed.idHygienizationStatus
      this.selectedBed.idHygienizationStatus = this.hygienizedStatus;
    }
    else
      this.selectedBed.idHygienizationStatus = this.oldStatusHygienization;
  }

  private sortBedsByHygienizationStatus(beds: any[]): any[] {
    return beds.sort((a, b) => {
      if (a.idHygienizationStatus === b.idHygienizationStatus)
        return 0;

      if (a.idHygienizationStatus === this.hygienizedStatus)
        return -1;
      else if (b.idHygienizationStatus === this.hygienizedStatus)
        return 1;

      if (a.idHygienizationStatus === this.hygienizingStatus)
        return -1;
      else if (b.idHygienizationStatus === this.hygienizingStatus)
        return 1;

      if (a.idHygienizationStatus === this.unhygienizedStatus)
        return -1
      else if (b.idHygienizationStatus === this.unhygienizedStatus)
        return 1;

      return 0;
    });
  }
}