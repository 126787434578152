import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VerifyPassword ,VerifyOldPassword} from 'src/app/shared/custom-validators/password.validator';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { FirstAccessService } from 'src/app/shared/services/API/user/first-access.service';
import { FirstAccessRequest } from 'src/app/shared/services/requests/user/first-access.request';

@Component({
  selector: 'app-first-access-modal',
  templateUrl: './first-access-modal.component.html',
  styleUrls: ['./first-access-modal.component.css']
})
export class FirstAccessModalComponent implements OnInit {

  constructor(private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<FirstAccessModalComponent>,
    private formBuilder: UntypedFormBuilder,
    private firstAccessService: FirstAccessService) { }

  public isLoading: boolean;
  public model: UntypedFormGroup;
  public isExpiredPassword: boolean = false;
  
  public firstAccessRequest: FirstAccessRequest;

  public hideOld: boolean = true;
  public hideNew: boolean = true;
  public hideNewRepeat: boolean = true;

  ngOnInit(): void {
    this.isExpiredPassword = this.data.isExpiredPassword;
    this.isLoading = false;
    this.firstAccessRequest = new FirstAccessRequest();

    this.matDialogRef.disableClose = true;

    this.model = this.formBuilder.group({
      oldPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required, VerifyPassword()]],
      repetedPassword: ['', [Validators.required]],
    });
  }

  submit() {
    if (this.model.invalid || this.isLoading)
      return;

    this.isLoading = true;

    if (this.model.get('newPassword').value != this.model.get('repetedPassword').value) {
      this.alertService.show('Erro', "Os dois campos de senha devem ser iguais", AlertType.error);
      this.isLoading = false;
      return;
    }

    if (this.model.get('newPassword').value == this.model.get('oldPassword').value) {
      this.alertService.show('Erro', "A nova senha não pode ser igual a antiga.", AlertType.error);
      this.model.get("newPassword").setValidators([VerifyOldPassword()]);
      this.model.get("newPassword").updateValueAndValidity();

      this.model.get("repetedPassword").setValidators([VerifyOldPassword()]);
      this.model.get("repetedPassword").updateValueAndValidity();
      this.isLoading = false;
      return;
    }

    this.firstAccessRequest.userLogin = this.data.userLogin;
    this.firstAccessRequest.oldPassword = this.model.get('oldPassword').value;
    this.firstAccessRequest.newPassword = this.model.get('newPassword').value;
    this.firstAccessRequest.idHealthUnit = this.data.idHealthUnit;

    this.firstAccessService.firstAccessPassword(this.firstAccessRequest).subscribe((response) => {
      if (response.isError) {
        this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.alertService.show('Sucesso', "Senha atualizada com sucesso!", AlertType.success);
      this.isLoading = false;
      this.matDialogRef.close();
    },
    (error) => {
      console.log(error)
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  clickCancel() {
    this.matDialogRef.close();
  }
}
