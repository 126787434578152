import { ValidatorFn } from "@angular/forms";

export class FormMetaData {
    constructor(label: string, validators?: ValidatorFn[], hideable?: boolean, hide?: boolean, mask?: any, disable?: boolean) {
        this.label = label;
        this.validators = validators;
        this.hideable = (hideable != null ? hideable : false);
        this.hide = (hide != null ? hide : false);
        this.mask = (mask != null ? mask : null);
        this.disable = (disable != null ? disable : false);
    }

    public label: string;
    public validators: ValidatorFn[];
    public disable: boolean = false;
    public hide: boolean = false;
    public hideable: boolean = false;
    public mask: any;
}