import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { AdmissionGuideRequest } from '../../requests/private-billing/admission-guide.request';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { ListAdmissionGuideResponse } from '../../responses/private-billing/list-admission-guide.response';
import { GuideResponse } from '../../responses/private-billing/guide.response';

@Injectable({
  providedIn: 'root'
})
export class AdmissionGuideService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public ValidationRequest(request: AdmissionGuideRequest): Observable<ReturnStruct> {
    let uri = `AdmissionGuide`

    return this.httpClient.post<ReturnStruct>(environment.urlApiPrivateBilling + uri, request, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public GetAdmissionGuides(idEpisode: number, idHealthCareAgreement: number): Observable<ListAdmissionGuideResponse> {
    let uri = `AdmissionGuide/idHealthCareAgreement/${idHealthCareAgreement}/idEpisode/${idEpisode}`
    return this.httpClient.get<ListAdmissionGuideResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public GetAdmissionGuideByIdEpisode(idEpisode: number): Observable<GuideResponse> {
    let uri = `AdmissionGuide/idEpisode/${idEpisode}`
    return this.httpClient.get<GuideResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}