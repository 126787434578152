import { Validators } from "@angular/forms";
import { VerifyCPF } from "src/app/shared/custom-validators/cpf.validator";
import { FormMetaData } from "src/app/shared/structs/form-meta-data.struct";
import { MaskService } from "../../../mask.service";

export class SafeIDCredentialsRequest {
    public grant_type: string = "password";
    public client_id: string = "";
    public client_secret: string = "";
    public username: string = "";
    public password: string = "";
    public lifetime: number = 600000;
    public scope: string = "signature_session";
    public slot_alias: string = "";

    public static metadata(): Map<string, FormMetaData> {
        let masks = MaskService.getMasks()
        let metaData = new Map<string, FormMetaData>();
        metaData['username'] = new FormMetaData('CPF', [Validators.required, VerifyCPF()], undefined, undefined, masks.cpf, false);
        metaData['password'] = new FormMetaData('Senha', [Validators.required], true, true);
        return metaData;
    }
}