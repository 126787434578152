<app-menu [isRenderMenu]="false" [menuModuleEnum]="menuModuleEnum"></app-menu>


<div class="container-body">
    <div class="vertical-aling-out">
        <div class="vertical-aling-inner">
            <div class="white-box">
                <h2>Redefinir senha</h2>
                <form class="form" [formGroup]="model"  (ngSubmit)="submit()">
                    <p>Insira a matricula ou CPF associado à sua conta para receber um link de redefinição de senha.</p>
                    <div class="row label-row" *ngIf="!state">   
                        <div class="col-md-12 login-label" (click)="changeState()">
                            <mat-icon class="vertical-align" aria-hidden="false" aria-label="Plus">account_circle</mat-icon>{{model.get('login').value}}
                        </div>
                    </div>
                    <div class="row" *ngIf="state">   
                        <div class="col-md-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Matricula ou CPF</mat-label>
                                <input matInput type="text" formControlName="login" required>
                                <mat-error *ngIf="model.get('login').invalid">Informe a sua matricula ou cpf</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row" *ngIf="listHealthUnit && listHealthUnit.length>1">   
                        <div class="col-md-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Unidade de Saude</mat-label>
                                <mat-select formControlName="idHealthUnit">                       
                                  <mat-option *ngFor="let item of listHealthUnit" value="{{item.idHealthUnit}}">{{item.healthUnitName}}</mat-option>
                                </mat-select>
                                <mat-error *ngIf="model.get('idHealthUnit').invalid">Informe a unidade de saúde</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row" *ngIf="state">   
                        <div class="col-md-12">
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="recoverOption" [ngModelOptions]="{standalone: true}">
                                <mat-radio-button value="1">Email</mat-radio-button>
                                <mat-radio-button value="2">SMS</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12" *ngIf="state">
                            <button mat-flat-button type="button" color="primary" class=" btn-block" (click)="checkLogin()">
                                <span *ngIf="isLoading == false">Avançar</span>
                                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                            </button>
                        </div>
                        <div class="col-md-12">
                            <button mat-flat-button type="submit" color="primary" class=" btn-block" *ngIf="!state && model.get('idHealthUnit').value">
                                <span *ngIf="isLoading == false">Enviar link de redefinição</span>
                                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                            </button>
        
                            <button mat-flat-button type="button" routerLink="/" color="accent" class=" btn-block">
                                Voltar
                            </button>
                        </div>
                    </div>
                </form> 
            </div>
        </div>
    </div>
</div>