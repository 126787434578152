import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { ListHealthUnitResponse } from '../../responses/user/list-health-unit.response';
import { ProfileResponse } from '../../responses/user/profile.response';
import { GetProfileAutomaticLoginResponse } from '../../responses/user/get-profile-automatic-login.response';

@Injectable({
  providedIn: 'root'
})
export class UserloginService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listUserHealthUnit(login, email?): Observable<ListHealthUnitResponse> {
    
    let uri = `Userlogin?`;
    if(login) uri += `login=${login}&`;
    if(email) uri += `email=${email}&`;
    
    return this.httpClient.get<ListHealthUnitResponse>(environment.urlApiUser + uri)
    .pipe(
      catchError(this.handleError)
    )
  }

  public getProfileByHealthUnitAndUser(idHealthUnit: number, login: string): Observable<GetProfileAutomaticLoginResponse> {
    
    let uri = `UserLogin/profile?idHealthUnit=${idHealthUnit}&login=${login}`;
    
    return this.httpClient.get<GetProfileAutomaticLoginResponse>(environment.urlApiUser + uri)
    .pipe(
      catchError(this.handleError)
    )
  }
}
