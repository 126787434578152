<div mat-dialog-content>
    <div class="mat-dialog-header">
        <h3>Selecione quais documentos você deseja ver e/ou imprimir!</h3>
    </div>
    <div class="mat-dialog-content">
        <form class="form" [formGroup]="reportModel">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <ul>
                        <li>
                            <mat-checkbox (ngModelChange)="onChangeDownloadComplete($event)"
                                formControlName="downloadCompleteReport">
                                Download ficha completa
                            </mat-checkbox>
                        </li>
                        <li *ngIf="hasExternalExam && isCompletedReport">
                            <mat-checkbox formControlName="examExternal">
                                Exames externo
                            </mat-checkbox>
                        </li>
                        <li *ngIf="hasInternalExam && isCompletedReport">
                            <mat-checkbox formControlName="examInternal">
                                Exames interno
                            </mat-checkbox>
                        </li>
                        <li *ngIf="hasExternalPrescription && isCompletedReport">
                            <mat-checkbox (ngModelChange)="onChangeExternal($event)"
                                formControlName="prescriptionExternal">
                                Prescrições Não padronizadas
                            </mat-checkbox>
                        </li>
                        <li *ngIf="hasExternalPrescription && isCompletedReport">
                            <div *ngIf="checkedExternal" class="row">
                                <div class="col-6 col-sm-6 col-md-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Quantidade de Vias</mat-label>
                                        <input formControlName="externalPrintCount" matInput type="number">
                                    </mat-form-field>
                                </div>
                            </div>
                        </li>
                        <li *ngIf="hasInternalPrescription && isCompletedReport">
                            <mat-checkbox (ngModelChange)="onChangeInternal($event)"
                                formControlName="prescriptionInternal">
                                Prescrições Padronizadas
                            </mat-checkbox>
                        </li>
                        <li *ngIf="hasInternalPrescription && isCompletedReport">
                            <div *ngIf="checkedInternal" class="row">
                                <div class="col-6 col-sm-6 col-md-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Quantidade de Vias</mat-label>
                                        <input formControlName="internalPrintCount" matInput type="number">
                                    </mat-form-field>
                                </div>
                            </div>
                        </li>
                        <li *ngIf="hasMedicalProcedure && isCompletedReport">
                            <mat-checkbox formControlName="isMedicalProcedureReport">
                                Procedimentos Médicos
                            </mat-checkbox>
                        </li>
                        <li *ngIf="isCompletedReport">
                            <mat-checkbox formControlName="isEpisodeReport">
                                Ficha do episódio
                            </mat-checkbox>
                        </li>
                        <li *ngIf="this.sickDaysOffAmount && this.sickDaysOffAmount > 0 && isCompletedReport">
                            <mat-checkbox formControlName="sickNote">
                                Atestado
                            </mat-checkbox>
                        </li>
                        <li *ngIf="this.datetimeEndIsolation && isCompletedReport">
                            <mat-checkbox formControlName="isolation">
                                Notificação de Isolamento
                            </mat-checkbox>
                        </li>
                        <li *ngIf="this.datetimeEndIsolation && isCompletedReport">
                            <mat-checkbox formControlName="isolationAcknowledge">
                                Declaração de ciência de isolamento
                            </mat-checkbox>
                        </li>
                    </ul>
                </div>
            </div>
        </form>
        <div class="footer-panel-modal-list">
            <div class="row">
                <div class="col-6">
                    <button mat-flat-button color="primary" class="btn-block" *ngIf="isLoading == false"
                        (click)="clickOK()">Ok</button>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </div>
            </div>
        </div>
    </div>
</div>