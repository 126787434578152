<h1 mat-dialog-title>{{discriminatorName}}</h1>
<div mat-dialog-content>
    <div class="modal-body text-center">
        <form class="form" [formGroup]="model">
            <div class="row">
                <div class="col-12">
                    <div *ngFor="let item of firstColumn; let i = index;" class="row-escolha-triagem">
                        <div class="sub-discriminator">
                            <app-sub-discriminator (discriminatorValues)="setDiscriminatorsValues($event)"
                                [openModal]="item.openModalValidate" [list]="subList" [discriminator]="item.column"
                                [discriminatorsValues]="data.discriminatorsValues"
                                [forceOpenModal]="item.forceOpenModal">
                            </app-sub-discriminator>
                        </div>
                        <input (click)="setValue(item.column, true)" [value]="true" class="radio-sim" type="radio"
                            id="radio-{{item.column.idSubDiscriminator}}-{{item.column.idDiscriminator}}-sim"
                            formControlName="radio-{{item.column.idSubDiscriminator}}-{{item.column.idDiscriminator}}"
                            name="radio-{{item.column.idSubDiscriminator}}-{{item.column.idDiscriminator}}">
                        <label (click)="forceToOpenModal(item.column)" class="label-radio-sim"
                            for="radio-{{item.column.idSubDiscriminator}}-{{item.column.idDiscriminator}}-sim"><img
                                src="assets/images/icone-radio-sim.svg" /></label>
                        <input (click)="setValue(item.column, false)" [value]="false" class="radio-nao" type="radio"
                            id="radio-{{item.column.idSubDiscriminator}}-{{item.column.idDiscriminator}}-nao"
                            formControlName="radio-{{item.column.idSubDiscriminator}}-{{item.column.idDiscriminator}}"
                            name="radio-{{item.column.idSubDiscriminator}}-{{item.column.idDiscriminator}}">
                        <label (click)="forceToOpenModal(item.column)" class="label-radio-nao"
                            for="radio-{{item.column.idSubDiscriminator}}-{{item.column.idDiscriminator}}-nao"><img
                                src="assets/images/icone-radio-nao.svg" /></label>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>