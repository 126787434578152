<div mat-dialog-content>
    <div ing class="mat-dialog-container-medic-hold-modal">
        <div class="text-and-count">
            <h1>Aguardando o paciente entrar na consulta!</h1>
            <h2>Ainda restam:</h2>
            <div class="countdown-el">
                <p>{{timeStringRender}}</p>
            </div>

            <h2>Para o início do atendimento</h2>
        </div>
        <div class="orientation-image">
            <img src="assets/images/medic-hold-modal-image.png">
            <p>
                <mat-icon>warning</mat-icon>
                Mantenha-se no centro da tela para que o seu paciente te veja corretamente!
            </p>
        </div>
        <div class="cancel-button">
            <button mat-flat-button color="accent" type="button" class="btn-block" (click)="validateWhatToDo(true)">
                <span *ngIf="isLoading == false">Cancelar</span>
                <mat-spinner [diameter]="30" *ngIf="isLoading == true"></mat-spinner>
            </button>
        </div>
    </div>
</div>