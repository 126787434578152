import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { CallPanelEpisodeRequest } from '../../requests/orchestrator-panel/call-panel-episode.request';
import { CallPanelRequest } from '../../requests/orchestrator-panel/call-panel.request';
import { CallPanelResponse } from '../../responses/orchestrator-panel/call-panel.response';

@Injectable({
  providedIn: 'root'
})
export class CallPanelService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public callPatient(callPanelEpisodeRequest: CallPanelEpisodeRequest, idQueue: number, idRoom: number, isVerifyCall: boolean, idModule: number, isRemote: boolean = false): Observable<CallPanelResponse> {
    let uri = `CallPanel/idQueue/${idQueue}/idRoomOrigin/${idRoom}/isVerifyCall/${isVerifyCall}/idModule/${idModule}?isRemote=${isRemote}`

    return this.httpClient.post<CallPanelResponse>(environment.urlApiOrchestratorPanel + uri, callPanelEpisodeRequest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public restrictedCallPatient(callPanelRequest: CallPanelRequest): Observable<CallPanelResponse> {
    let uri = `CallPanel`

    return this.httpClient.post<CallPanelResponse>(environment.urlApiOrchestratorPanel + uri, callPanelRequest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
