import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthRoutingModule } from './auth-routing.module';
import { AppComponent } from '../app.component';
import { AuthComponent } from './pages/auth/auth.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { HttpClientModule } from '@angular/common/http';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ConfigComponent } from './pages/config/config.component';
import { ForgotPasswordService } from '../shared/services/API/srv-email/forgot-password.service';
import { RecoverPasswordComponent } from './pages/recover-password/recover-password.component';
import { RecoverPasswordService } from '../shared/services/API/user/recover-password.service';
import { SharedModule } from '../shared/shared.module';
import { FirstAccessModalComponent } from './pages/auth/first-access-modal/first-access-modal.component';
import { VerifyAuthComponent } from './pages/auth/verify-auth/verify-auth.component';
import { HealthUnitConfirmModalComponent } from './pages/auth/health-unit-confirm-modal/health-unit-confirm-modal.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { environment } from 'src/environments/environment';
import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { MsalModule, MsalService, MSAL_INSTANCE } from '@azure/msal-angular';
import { HealthUnitListModalComponent } from './pages/health-unit-list-modal/health-unit-list-modal.component';
import { ConnectedDevicesComponent } from './pages/connected-devices/connected-devices.component';
import { DesconectDeviceModalComponent } from './pages/connected-devices/desconect-device-modal/desconect-device-modal.component';


export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.microsoftClientId,
      redirectUri: environment.urlApiBaseUI,
    }
  });
}

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    ConfigComponent,
    ForgotPasswordComponent,
    RecoverPasswordComponent,
    FirstAccessModalComponent,
    VerifyAuthComponent,
    HealthUnitConfirmModalComponent,
    HealthUnitListModalComponent,
    ConnectedDevicesComponent,
    DesconectDeviceModalComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatExpansionModule,
    MatDividerModule,
    MatIconModule,
    MatTooltipModule,
    MatCheckboxModule,
    HttpClientModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatRadioModule,
    SharedModule,
    MsalModule,
  ],
  providers:[
    ForgotPasswordService,
    RecoverPasswordService,
    MsalService,
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
  ],
  bootstrap: [AppComponent]
})
export class AuthModule { }
