import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { SelectedLocationRequest } from 'src/app/shared/services/requests/orchestrator-queue/selected-location.request';
import { FowardService } from '../../services/API/orchestrator-queue/foward.service';
import { FlowStruct } from '../../services/structs/flow/flow.struct';

@Component({
  selector: 'app-flow-sequence-modal',
  templateUrl: './flow-sequence-modal.component.html',
  styleUrls: ['./flow-sequence-modal.component.css']
})
export class FlowSequenceModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<FlowSequenceModalComponent>,
    private alertService: AlertService,
    public fowardService: FowardService,
    public router: Router) { }

  public isLoading: boolean;
  public model: UntypedFormGroup;
  public selectedLocationRequest: SelectedLocationRequest;
  public flowSequence: FlowStruct[];
  public isLasStep: boolean;
  public readOnly: boolean = false;

  ngOnInit(): void {
    this.isLoading = false;
    this.flowSequence = this.data.flowSequence;
    this.isLasStep = (this.flowSequence.length == 1);
    this.readOnly = this.data.readOnly;
  }

  clickConfirm() {
    this.matDialogRef.close({ fowardPatient: true });
  }

  clickCancel() {
    this.matDialogRef.close({ fowardPatient: false });
  }
  submit(isLiberateBed: boolean = null) {
    this.selectedLocationRequest = new SelectedLocationRequest();
    if (isLiberateBed) {
      this.selectedLocationRequest.isLiberateBed = true;
    }

    this.selectedLocationRequest.idEpisode = this.data.idEpisode;
    this.selectedLocationRequest.idQueue = this.data.idQueue;
    this.selectedLocationRequest.idRoomOrigin = this.data.idOriginRoom;
    this.selectedLocationRequest.idFlow = this.flowSequence[1].idFlow;
    this.selectedLocationRequest.idFlowCompleted = this.data.flowSequence[0].idFlow;

    if(this.data.currentQueueFinished){
      this.episodeFowardPatient(this.selectedLocationRequest);
    } else {
      this.fowardPatient(this.selectedLocationRequest);
    } 
    this.clickConfirm();
  }
  fowardPatient(body: SelectedLocationRequest) {
    if (this.isLoading)
      return;
    this.isLoading = true;
    this.fowardService.fowardPatient(body).subscribe({next:(response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.isLoading = false;

      if (response.errorCode == 4) {
        this.alertService.show('Aviso', response.errorDescription, AlertType.warning);
      }
      else {
        this.alertService.show('Sucesso', "Paciente encaminhado com sucesso", AlertType.success);
      }

      this.matDialogRef.close({ fowardPatient: true });
    },
    error:(error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.matDialogRef.close({ fowardPatient: false });
      }});
  }

  episodeFowardPatient(body: SelectedLocationRequest) {
    if (this.isLoading)
      return;
    this.isLoading = true;
    this.fowardService.episodeFowardPatient(body).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;

        if (response.errorCode == 4) {
          this.alertService.show('Aviso', response.errorDescription, AlertType.warning);
        }
        else {
          this.alertService.show('Sucesso', "Paciente encaminhado com sucesso", AlertType.success);
        }

        this.matDialogRef.close({ fowardPatient: true });
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.matDialogRef.close({ fowardPatient: false });
      }
    });
  }
}