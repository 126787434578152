import { CareLineModel } from "../../models/care-line/care-line.model";

export class EpisodeCareLineStruct {
    constructor(careLine?: CareLineModel, idEpisode?: number) {
        if (careLine && idEpisode) {
            this.idEpisode = idEpisode;
            this.idCareLine = careLine.idCareLine;
            this.careLineName = careLine.careLineName;
        }
        else if (careLine) {
            this.idCareLine = careLine.idCareLine;
            this.careLineName = careLine.careLineName;
        }
    }

    public idEpisode: number;
    public idCareLine: number;
    public careLineName: string;
    public idUser: number;
    public userName: string;
    public datetimeInclusion: Date;
}