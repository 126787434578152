import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { BedEpisodeRequest } from '../../requests/bed/bed-episode.request';

@Injectable({
  providedIn: 'root'
})
export class BedEpisodeService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public linkBedEpisode(body: BedEpisodeRequest): Observable<ReturnStruct> {

    let uri = `BedEpisode`

    return this.httpClient.post<ReturnStruct>(environment.urlApiBed + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public changeBedEpisode(body: BedEpisodeRequest): Observable<ReturnStruct> {

    let uri = `BedEpisode`

    return this.httpClient.put<ReturnStruct>(environment.urlApiBed + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
