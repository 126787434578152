<div mat-dialog-content>
    <div class="mat-dialog-content">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="title-modal">
                <!-- <h1>{{this.data.title}}</h1> -->
            </div>
            <div class="body-modal">
                <span>
                    Esse local possui estoques cadastrados, gostaria de migrá-los para um outro local?
                </span>
                <div class="white-body">
                    <mat-form-field appearance="outline">
                        <mat-label>Localização</mat-label>
                        <mat-select formControlName="idLocation">
                            <mat-option ngValue="null">Nenhum</mat-option>
                            <mat-option *ngFor="let item of listLocation" [value]="item.idLocation">
                                {{item.locationName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- {{this.data.description}} -->
            </div>
            <div class="footer-modal-list">
                <button mat-flat-button color="primary" type="submit" class="btn-block">
                    <span *ngIf="!isLoading">Migrar</span>
                    <mat-spinner *ngIf="isLoading" [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                </button>
                <button mat-flat-button color="accent" class="btn-block" (click)="close()">Manter os itens no mesmo
                    local</button>
            </div>
        </form>
    </div>
</div>