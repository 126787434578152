export class SpSadtExecutedProcedure {
    public idSpSadtExecutedProcedure : number;
    public idSpSadtGuide : number;
    public idPriceProcedureHealthcare : number;
    public procedureDate : Date;
    public procedureStartTime : string; //no back é um timeSpan
    public procedureEndTime : string; //no back é um timeSpan
    public idTerminologyLookup : number;
    public performedProcedureSequential : number;
    public procedureCode : string;
    public procedureDescription : string;
    public procedureQuantity : number;
    public idAccessLookup : number;
    public idTechniqueLookup : number;
    public adjustmentCoefficient : number;
    public procedureUnitaryValue : number;
    public procedureTotalValue : number;
    public datetimeInclusion : Date;
}