<div mat-dialog-content>
    <div class="mat-dialog-header">
        <h3>A {{vitalSignName}} já foi confirmado anteriormente com o valor {{oldValue}}.</h3>
        <h3>Para inserir o novo valor {{newValue}} você deve voltar no discriminador onde foi medido a
            {{vitalSignName}}!</h3>
    </div>
    <div class="mat-dialog-content">
        <div class="footer-panel-modal-list">
            <div class="row">
                <div class="col-12 col-md-4">
                </div>
                <div class="col-12 col-md-4">
                    <button mat-flat-button color="primary" class="btn-block" (click)="close()">Fechar</button>
                </div>
                <div class="col-12 col-md-4">
                </div>
            </div>
        </div>
    </div>
</div>