export enum DiscriminatorTypeEnum {
    Glasgow = 1,
    Temperatura = 2,
    Saturacao = 3,
    FrequenciaCardiaca = 4,
    Glicemia = 5,
    GlicemiaCapilar = 6,
    FrequenciaRespiratoria = 7,
    PressaoArterial = 8,
    Dor = 9,
    Boleano = 10,
    Numerico = 11,
    SepsePossivel = 12,
    DorCoceira = 13,
    SepsePossivelCrianca = 14,
    LastDiscriminator = 15,
    PerfusaoCapilar = 16
}