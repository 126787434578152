<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-modal-device">
            <h2>Desconectar dispositivo</h2>
        </div>
         
         <div class="body-modal-device">
            <p>Você tem certeza que deseja se desconectar do dispositivo abaixo:</p>
            <div class="info-device">
                <div class="icon-device">
                    <mat-icon *ngIf="!this.automaticLoginStruct .automaticLogin.isMobile" aria-hidden="false" aria-label="devices">laptop</mat-icon>
                    <mat-icon *ngIf="this.automaticLoginStruct .automaticLogin.isMobile" aria-hidden="false" aria-label="devices">smartphone</mat-icon>
                </div>
                <h2 class="primary">{{this.automaticLoginStruct .automaticLogin.browser}} ({{this.automaticLoginStruct .automaticLogin.device}})</h2>
            </div>
            <span class="secundary-status last-info">Última sessão ativa: {{this.automaticLoginStruct .lastSession}}</span>
         </div>
         
        <div class="footer-modal-device">
            <button mat-flat-button color="accent" class="btn-block"(click)="close()">
                <span *ngIf="isLoading == false">Voltar</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
            <button mat-flat-button color="warn" class="btn-block" (click)="deleteAutomaticLogin()">
                <span *ngIf="isLoading == false">Desconectar</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
        </div>
    </div>
</div>