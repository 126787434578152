import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ClassificationData, UtilsClassificationService } from 'src/app/classification/utils.service';
import { DiscriminatorTypeEnum } from 'src/app/shared/enum/discriminator-type.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { DiscriminatorModel } from 'src/app/shared/services/models/risk-classification/discriminator.model';
import { PriorityColorModel } from 'src/app/shared/services/models/risk-classification/priority-color.model';
import { DiscriminatorResponse } from 'src/app/shared/services/responses/risk-classification/discriminator.response';
import { DiscriminatorStruct } from 'src/app/shared/services/structs/risk-classification/discriminator.struct';
import { GreaterGravityModalComponent } from './greater-gravity-modal/greater-gravity-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { DeviceTypeEnum } from 'src/app/shared/enum/device-type.enum';
import { UtilsMeasurerService } from './utils-measurer.service';
import { UtilsMeasurerRemoteService } from './utils-measurer-remote.service';
import { Router } from '@angular/router';

export class FlowChartStructure {
  public column: DiscriminatorStruct;
  public openModalValidate: boolean;
  public forceOpenModal: boolean;
  public nameAndColor: PriorityColorModel;
}

@Component({
  selector: 'app-protocol-v2',
  templateUrl: './protocol-v2.component.html',
  styleUrls: ['./protocol-v2.component.css']
})
export class ProtocolV2Component implements OnInit, OnDestroy {

  constructor(private alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    private utilService: UtilsClassificationService,
    public dialog: MatDialog,
    public measurerService: UtilsMeasurerService,
    public measurerRemote: UtilsMeasurerRemoteService,
    public router: Router,
    public utilClassification: UtilsClassificationService,
  ) { }

  @Input() discriminatorResponse: DiscriminatorResponse;
  @Input() discriminators: any[] = [];
  @Input() painColorCode: string[] = [];
  @Output() selectedValue = new EventEmitter<any>();
  @Output() goNextToFlowchartSelect = new EventEmitter<any>();

  public list: DiscriminatorStruct[];
  public firstColumn: FlowChartStructure[];
  public secondColumn: FlowChartStructure[];
  public priorityColor: PriorityColorModel[];
  public currentIndexPages: number;
  public currentPage: number = 0;
  public model: UntypedFormGroup;
  public discriminatorsPersist: any[][];
  public defaultValue: DiscriminatorStruct;
  private data: ClassificationData;
  public isSelfTriage: boolean;
  public lastCanBeDenied: boolean = false;

  ngOnInit(): void {
    this.discriminatorsPersist = [];
    if (!this.discriminators && !Array.isArray(this.discriminators)) {
      this.discriminators = [];
    }

    this.data = this.utilService.getClassificationData();

    this.lastCanBeDenied = this.data.lastCanBeDenied;
    this.model = this.formBuilder.group({});
    this.list = this.discriminatorResponse.discriminator;

    if (this.list.length > 1 && 
      this.list[this.list.length - 1].idDiscriminatorType == DiscriminatorTypeEnum.LastDiscriminator)
      this.lastCanBeDenied = false;
    
    if (this.list.length > 1 && !this.lastCanBeDenied) 
      this.defaultValue = this.list.pop();
    
    this.list.forEach((value, index) => {
      if (value.idDiscriminatorType != DiscriminatorTypeEnum.Numerico
        && value.idDiscriminatorType != DiscriminatorTypeEnum.SepsePossivel
        && value.idDiscriminatorType != DiscriminatorTypeEnum.Boleano) {
        if (!Array.isArray(this.discriminatorsPersist[value.idDiscriminatorType])) {
          this.discriminatorsPersist[value.idDiscriminatorType] = []
          this.discriminatorsPersist[value.idDiscriminatorType].push(index);
        } else {
          this.discriminatorsPersist[value.idDiscriminatorType].push(index);
        }
      }

      if (Array.isArray(this.data.discriminatorAllValues) && this.data.discriminatorAllValues[index] && this.data.discriminatorAllValues[index].hasValue) {
        value.value = this.data.discriminatorAllValues[index].value;
        value.type = this.data.discriminatorAllValues[index].type;
        value.state = this.data.discriminatorAllValues[index].state;
      } else if (this.discriminators && Array.isArray(this.discriminators)) {
        if (value.idDiscriminatorType == DiscriminatorTypeEnum.PressaoArterial && this.discriminators[DiscriminatorTypeEnum.PressaoArterial]) {
          value.value = this.discriminators[DiscriminatorTypeEnum.PressaoArterial];
        } else if (value.idDiscriminatorType == DiscriminatorTypeEnum.FrequenciaCardiaca && this.discriminators[DiscriminatorTypeEnum.FrequenciaCardiaca]) {
          value.value = this.discriminators[DiscriminatorTypeEnum.FrequenciaCardiaca];
        } else if ((value.idDiscriminatorType == DiscriminatorTypeEnum.Glicemia || value.idDiscriminatorType == DiscriminatorTypeEnum.GlicemiaCapilar) && this.discriminators[DiscriminatorTypeEnum.Glicemia]) {
          value.value = this.discriminators[DiscriminatorTypeEnum.Glicemia];
        } else if (value.idDiscriminatorType == DiscriminatorTypeEnum.Saturacao && this.discriminators[DiscriminatorTypeEnum.Saturacao]) {
          value.value = this.discriminators[DiscriminatorTypeEnum.Saturacao];
        } else if (value.idDiscriminatorType == DiscriminatorTypeEnum.Temperatura && this.discriminators[DiscriminatorTypeEnum.Temperatura]) {
          value.value = this.discriminators[DiscriminatorTypeEnum.Temperatura];
        }
      }
      this.model.addControl("radio-" + value.idDiscriminator.toString(), this.formBuilder.control({ value: '', disabled: !(index == 0 && (value.idDiscriminatorType == DiscriminatorTypeEnum.Boleano)) }));
    });
    this.priorityColor = this.discriminatorResponse.priorityColor;
    this.currentIndexPages = Math.ceil(this.list.length / 16);
    this.calculateColumnsAndNext();
    this.data.discriminatorsPersist = this.discriminatorsPersist;
    this.utilService.updateClassificationData(this.data);
    if (this.data.idDeviceType == DeviceTypeEnum.Trius || this.data.idDeviceType == DeviceTypeEnum.Berco || this.data.idDeviceType == DeviceTypeEnum.Bluetooth) {
      this.measurerService.setIndex(-1);
      this.measurerService.createReference(this.discriminators, this.discriminatorsPersist, this.list,
        this.data.deviceThermometer, this.data.deviceGlucometer, this.data.deviceOximeter, this.data.devicePressure);
      this.measurerService.restartMeasurer();
    } else if (this.data.idDeviceType == DeviceTypeEnum.Remoto) {
      this.measurerRemote.isOnline(this.data.device.idDevice);
      this.measurerRemote.createReference(this.discriminators, this.discriminatorsPersist, this.list);
      this.measurerRemote.startWebsocket(this.data.device.deviceHash);
    }

  }

  calculateColumnsAndNext(openModalValidate: boolean = true): void {
    let showList = this.list.slice(0 + (16 * this.currentPage), 16 + (16 * this.currentPage));
    this.firstColumn = [];
    this.secondColumn = [];
    showList.slice(0, 8).forEach((value, index) => {
      this.firstColumn[index] = new FlowChartStructure();
      this.firstColumn[index].column = value;
      this.firstColumn[index].openModalValidate = index != 0 && openModalValidate;
      this.firstColumn[index].forceOpenModal = false;
      if (openModalValidate) {
        this.model.get("radio-" + this.firstColumn[index].column.idDiscriminator).setValue(null);
      }
      if (this.currentPage > 0 && index == 0) {
        let dis = this.list.find(c => c.idDiscriminator == this.firstColumn[index].column.idDiscriminator);
        if (dis.idDiscriminatorType == DiscriminatorTypeEnum.Boleano || !dis.isRequired || (dis.hasValue && dis.isRequired)) {
          this.model.get("radio-" + this.firstColumn[index].column.idDiscriminator).enable()
        }
      }
      if (!this.data.isPunctuationSystem) {
        if (index == 0 || (index - 1 >= 0 && this.firstColumn[index - 1].column.idPriorityColor != this.firstColumn[index].column.idPriorityColor)) {
          this.firstColumn[index].nameAndColor = this.priorityColor.find(c => c.idPriorityColor == value.idPriorityColor);
        }
      }
    });
    showList.slice(8, 16).forEach((value, index) => {
      this.secondColumn[index] = new FlowChartStructure();
      this.secondColumn[index].column = value;
      this.secondColumn[index].openModalValidate = true && openModalValidate;
      this.secondColumn[index].forceOpenModal = false;
      if (openModalValidate) {
        this.model.get("radio-" + this.secondColumn[index].column.idDiscriminator).setValue(null);
      }
      if (!this.data.isPunctuationSystem) {
        if ((index == 0 && value.idPriorityColor != this.firstColumn[7].column.idPriorityColor) || (index - 1 >= 0 && this.secondColumn[index - 1].column.idPriorityColor != this.secondColumn[index].column.idPriorityColor)) {
          this.secondColumn[index].nameAndColor = this.priorityColor.find(c => c.idPriorityColor == value.idPriorityColor);
        }
      }
    });

    if (!openModalValidate) {
      this.model.get("radio-" + this.secondColumn[7].column.idDiscriminator).setValue(null);
    }
  }

  previous() {
    if (this.currentPage > 0) {
      this.currentPage -= 1;
      this.calculateColumnsAndNext(false);
    }
    else
      this.previousFirstPage();
  }

  previousFirstPage() {
    var data = this.utilClassification.getClassificationData();
    data.isPatientWhite = false;
    data.discriminatorAllValues = [];
    data.discriminatorsPersist = [];
    data.idFlowchart = null;
    this.utilClassification.updateClassificationData(data);
    this.goNextToFlowchartSelect.emit();
  }

  next(): void {
    this.currentPage += 1;
    this.calculateColumnsAndNext();
  }
  //após fechar o modal, atualiza os valores dos discriminadores
  setDiscriminatorsValues(discriminatorsValues: any): void {

    if (this.defaultValue == null && this.list.length == 1) {
      this.selectedValue.emit({ item: this.list[0], discriminatorsValues: this.list, painColorCode: discriminatorsValues.painColorCode });
    } else if (discriminatorsValues.select) {
      let item = this.list[discriminatorsValues.index];
      this.discriminators[item.idDiscriminatorType] = item.value;
      this.propagateValue(discriminatorsValues);
      let motive = discriminatorsValues.motive ? discriminatorsValues.motive : null;
      this.selectedValue.emit({ item, discriminatorsValues: this.list, painColorCode: discriminatorsValues.painColorCode, motive });
    }
    if (this.list[discriminatorsValues.index].hasValue) {
      this.discriminators[this.list[discriminatorsValues.index].idDiscriminatorType] = this.list[discriminatorsValues.index].value;
      if (discriminatorsValues.painColorCode) {
        this.painColorCode[0] = discriminatorsValues.painColorCode;
      }
      if (this.propagateValue(discriminatorsValues)) {
        this.model.get("radio-" + this.list[discriminatorsValues.index].idDiscriminator).enable();
      }
    }
  }

  propagateValue(discriminatorsValues: any): boolean {

    let openModalGreaterGravity = false;
    let discriminatorGravity: DiscriminatorStruct;

    if (this.list[discriminatorsValues.index].idDiscriminatorType != DiscriminatorTypeEnum.Numerico
      && this.list[discriminatorsValues.index].idDiscriminatorType != DiscriminatorTypeEnum.SepsePossivel) {
      //O discriminatorsPersist contém um array com o index dos discriminadores
      this.discriminatorsPersist[this.list[discriminatorsValues.index].idDiscriminatorType].forEach((c: number) => {
        this.list[c].value = this.list[discriminatorsValues.index].value;
        this.list[c].hasValue = this.list[discriminatorsValues.index].hasValue;
        this.list[c].type = this.list[discriminatorsValues.index].type;
        this.list[c].state = this.list[discriminatorsValues.index].state;
        if (c < discriminatorsValues.index) {
          if (!openModalGreaterGravity) {
            discriminatorGravity = this.list[c];
            openModalGreaterGravity = this.utilService.verifySelectDiscriminator(this.list[c], this.list[c].value, this.list[c].type, this.list[c].state)
          }
        }
      });

      if (this.list[discriminatorsValues.index].idDiscriminatorType == DiscriminatorTypeEnum.Dor) {
        if (this.discriminatorsPersist[DiscriminatorTypeEnum.DorCoceira.valueOf()]) {
          this.discriminators[DiscriminatorTypeEnum.DorCoceira] = this.list[discriminatorsValues.index].value;
          this.discriminatorsPersist[DiscriminatorTypeEnum.DorCoceira.valueOf()].forEach((c: number) => {
            this.list[c].value = this.list[discriminatorsValues.index].value;
            this.list[c].hasValue = this.list[discriminatorsValues.index].hasValue;
            this.list[c].type = this.list[discriminatorsValues.index].type;
            this.list[c].state = this.list[discriminatorsValues.index].state;
            if (c < discriminatorsValues.index) {
              if (!openModalGreaterGravity) {
                discriminatorGravity = this.list[c];
                openModalGreaterGravity = this.utilService.verifySelectDiscriminator(this.list[c], this.list[c].value, this.list[c].type, this.list[c].state)
              }
            }
          });
        }
      } else if (this.list[discriminatorsValues.index].idDiscriminatorType == DiscriminatorTypeEnum.DorCoceira) {
        if (this.discriminatorsPersist[DiscriminatorTypeEnum.Dor.valueOf()]) {
          this.discriminators[DiscriminatorTypeEnum.Dor] = this.list[discriminatorsValues.index].value;
          this.discriminatorsPersist[DiscriminatorTypeEnum.Dor.valueOf()].forEach((c: number) => {
            this.list[c].value = this.list[discriminatorsValues.index].value;
            this.list[c].hasValue = this.list[discriminatorsValues.index].hasValue;
            this.list[c].type = this.list[discriminatorsValues.index].type;
            this.list[c].state = this.list[discriminatorsValues.index].state;
            if (c < discriminatorsValues.index) {
              if (!openModalGreaterGravity) {
                discriminatorGravity = this.list[c];
                openModalGreaterGravity = this.utilService.verifySelectDiscriminator(this.list[c], this.list[c].value, this.list[c].type, this.list[c].state)
              }
            }
          });
        }
      }
      else if (this.list[discriminatorsValues.index].idDiscriminatorType == DiscriminatorTypeEnum.Glicemia) {
        if (this.discriminatorsPersist[DiscriminatorTypeEnum.GlicemiaCapilar]) {
          this.discriminators[DiscriminatorTypeEnum.GlicemiaCapilar] = this.list[discriminatorsValues.index].value;
          this.discriminatorsPersist[DiscriminatorTypeEnum.GlicemiaCapilar].forEach((c: number) => {
            this.list[c].value = this.list[discriminatorsValues.index].value;
            if (this.list[c].maxValueAllowed < this.list[c].value) {
              this.list[c].hasValue = false;
            } else {
              this.list[c].hasValue = true;
            }
            if (c < discriminatorsValues.index) {
              if (!openModalGreaterGravity) {
                discriminatorGravity = this.list[c];
                openModalGreaterGravity = this.utilService.verifySelectDiscriminator(this.list[c], this.list[c].value, this.list[c].type, this.list[c].state)
              }
            }
          });
        }
      } else if (this.list[discriminatorsValues.index].idDiscriminatorType == DiscriminatorTypeEnum.GlicemiaCapilar) {
        this.discriminatorsPersist[DiscriminatorTypeEnum.Glicemia].forEach((c: number) => {
          this.discriminators[DiscriminatorTypeEnum.Glicemia] = this.list[discriminatorsValues.index].value;
          this.list[c].value = this.list[discriminatorsValues.index].value;
          if (c < discriminatorsValues.index) {
            if (!openModalGreaterGravity) {
              discriminatorGravity = this.list[c];
              openModalGreaterGravity = this.utilService.verifySelectDiscriminator(this.list[c], this.list[c].value, this.list[c].type, this.list[c].state)
            }
          }
        });
      }
    }
    if (openModalGreaterGravity) {
      this.openModalGreaterGravity(discriminatorsValues, discriminatorGravity);
      return false;
    }
    return true;
  }

  openModalGreaterGravity(discriminatorsValues: any, discriminatorGravity: DiscriminatorStruct) {
    let data: any = {};
    data.discriminatorNowName = this.list[discriminatorsValues.index].discriminatorName;
    data.discriminatorName = discriminatorGravity.discriminatorName;
    data.priorityName = this.priorityColor.find((c) => c.idPriorityColor == discriminatorGravity.idPriorityColor).priorityColorName;
    const dialogRef = this.dialog.open(GreaterGravityModalComponent, { data });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.response) {
        this.selectedValue.emit({ item: discriminatorGravity, discriminatorsValues: this.list });
      } else {
        this.alertService.show('Aviso', "É necessário reavaliar o valor do discriminador " + this.list[discriminatorsValues.index].discriminatorName, AlertType.warning);
        this.forceToOpenModal(this.list[discriminatorsValues.index], true);
      }
    });
  }

  isGlucose(value: number, value2: number): boolean {
    return value == DiscriminatorTypeEnum.Glicemia && value2 == DiscriminatorTypeEnum.GlicemiaCapilar || value2 == DiscriminatorTypeEnum.Glicemia && value == DiscriminatorTypeEnum.GlicemiaCapilar;
  }

  //se tentar negar ou selecionar o discriminador sem ter preenchido obrigatóriamente abre o modal
  forceToOpenModal(item: DiscriminatorModel, withValue: boolean = false): void {
    if (item.idDiscriminatorType == DiscriminatorTypeEnum.Boleano || !item.isRequired) {
      if (this.model.get("radio-" + item.idDiscriminator).disabled) {
        this.alertService.show('Aviso', "É necessário negar todos os discriminadores anteriores!", AlertType.warning);
      }
      return;
    }

    let discriminator = this.list.find(c => c.idDiscriminator == item.idDiscriminator);
    if (!discriminator.hasValue || withValue) {
      let indexFirstColumn = this.firstColumn.findIndex(c => c.column.idDiscriminator == item.idDiscriminator);
      if (indexFirstColumn >= 0) {
        this.firstColumn[indexFirstColumn].forceOpenModal = !this.firstColumn[indexFirstColumn].forceOpenModal;
      } else {
        let indexSecondColumn = this.secondColumn.findIndex(c => c.column.idDiscriminator == item.idDiscriminator);
        if (indexSecondColumn >= 0) {
          this.secondColumn[indexSecondColumn].forceOpenModal = !this.secondColumn[indexSecondColumn].forceOpenModal;
        }
      }
    }
  }

  negateDiscriminator(item: DiscriminatorModel, index: number) {
    //Valida se foi preenchido anteriormente e se deve ser selecionado
    if (this.utilService.verifySelectDiscriminator(item, this.list[index].value, this.list[index].type, this.list[index].state)) {
      this.selectedValue.emit({ item, discriminatorsValues: this.list });
      return;
    }
    //Valida se é o "penultimo" discriminador, se for seleciona o último automaticamente
    if (this.list[this.list.length - 1].idDiscriminator == item.idDiscriminator && !this.lastCanBeDenied) {
      this.selectedValue.emit({ item: this.defaultValue, discriminatorsValues: this.list });
      return;
    }
    //Valida se é o "último" discriminador sendo passível de negação para retornar à escolha de fluxograma
    if (this.list[this.list.length - 1].idDiscriminator == item.idDiscriminator && this.lastCanBeDenied) {
      this.selectedValue.emit({ item, discriminatorsValues: this.list, cofirm: false });
      return;
    }
    //Se for o último da segunda coluna muda de página
    if (this.secondColumn.length > 0 && item.idDiscriminator == this.secondColumn[this.secondColumn.length - 1].column.idDiscriminator) {
      this.model.get("radio-" + item.idDiscriminator).setValue(false);
      this.next();
      return;
    }
    this.validateToOpenModal(item);
    if (this.list[index + 1].idDiscriminatorType == DiscriminatorTypeEnum.Boleano || !this.list[index + 1].isRequired) {
      this.model.get("radio-" + this.list[index + 1].idDiscriminator).enable();
    } else if (this.list[index + 1].hasValue) {
      let result
      for (let c in this.list) {
        if (parseInt(c) >= index) {
          result = false;
          break;
        } else if (this.list[parseInt(c)].idDiscriminatorType == this.list[index + 1].idDiscriminatorType) {
          if (this.utilService.verifySelectDiscriminator(this.list[parseInt(c)], this.list[parseInt(c)].value, this.list[parseInt(c)].type, this.list[parseInt(c)].state)) {
            result = true;
            break;
          }
        }
      };
      if (!result) {
        this.model.get("radio-" + this.list[index + 1].idDiscriminator).enable();
      }
    }
  }

  setValue(item: DiscriminatorModel, type: boolean): void {
    let index = this.list.findIndex(c => c.idDiscriminator == item.idDiscriminator);
    if (this.data.idDeviceType == DeviceTypeEnum.Trius || this.data.idDeviceType == DeviceTypeEnum.Berco || this.data.idDeviceType == DeviceTypeEnum.Bluetooth) {
      this.measurerService.setIndex(index + 1);
    }
    if (this.data.isPunctuationSystem) {
      this.selectedValue.emit({ item, discriminatorsValues: this.list });
      return;
    }
    if (!type) {
      this.negateDiscriminator(item, index);
    } else {
      if (DiscriminatorTypeEnum.Boleano != this.list[index].idDiscriminatorType && DiscriminatorTypeEnum.DorCoceira != this.list[index].idDiscriminatorType) {
        if (!this.utilService.verifySelectDiscriminator(item, this.list[index].value, this.list[index].type, this.list[index].state)) {
          if (this.model.get("radio-" + this.list[index].idDiscriminator).value == null) {
            this.model.get("radio-" + this.list[index].idDiscriminator).setValue(null);
          } else if (this.model.get("radio-" + this.list[index].idDiscriminator).value == false) {
            this.model.get("radio-" + this.list[index].idDiscriminator).setValue(false);
          }
          this.alertService.show('Aviso', "O valor inserido não se enquadra no parâmetro definido para " + this.list[index].discriminatorName, AlertType.warning);
          return;
        }
      }
      this.selectedValue.emit({ item, discriminatorsValues: this.list });
    }
  }

  //Seta a permissão de abrir o modal do próximo discriminador
  validateToOpenModal(item: DiscriminatorModel): void {
    let indexFirstColumn = this.firstColumn.findIndex(c => c.column.idDiscriminator == item.idDiscriminator);
    if (indexFirstColumn >= 0 && indexFirstColumn + 1 < this.firstColumn.length) {
      this.firstColumn[indexFirstColumn + 1].openModalValidate = false;
    } else {
      let indexSecondColumn = this.secondColumn.findIndex(c => c.column.idDiscriminator == item.idDiscriminator);
      if (indexFirstColumn == 7 && this.secondColumn.length > 0) {
        this.secondColumn[0].openModalValidate = false;
      }
      else if (indexSecondColumn >= 0 && indexSecondColumn + 1 <= this.secondColumn.length) {
        this.secondColumn[indexSecondColumn + 1].openModalValidate = false;
      }
    }
  }

  ngOnDestroy() {
    if (this.data.idDeviceType == DeviceTypeEnum.Trius || this.data.idDeviceType == DeviceTypeEnum.Berco || this.data.idDeviceType == DeviceTypeEnum.Bluetooth) {
      this.measurerService.setIndex(-1);
    } else if (this.data.idDeviceType == DeviceTypeEnum.Remoto) {
      this.measurerRemote.clearAllInterval();
    }
  }
}
