import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StatusQueueEnum } from 'src/app/shared/enum/status-queue.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { QueueService } from 'src/app/shared/services/API/queue/queue.service';
import { UpdateStatusQueueRequest } from 'src/app/shared/services/requests/queue/update-status-queue.request';

@Component({
  selector: 'app-patient-list-attendance-confirm-modal',
  templateUrl: './patient-list-attendance-confirm-modal.component.html',
  styleUrls: ['./patient-list-attendance-confirm-modal.component.css']
})
export class PatientListAttendanceConfirmModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<PatientListAttendanceConfirmModalComponent>,
  private queueService: QueueService,
  private alertService: AlertService,
  ) { this.matDialogRef.disableClose = true; }

  public isLoading:boolean;  

  ngOnInit(): void {
    this.isLoading = false;
  }
  
  close(){
    if(this.isLoading) return;
    this.matDialogRef.close({isAttend: false}); 
  }
  
  clickConfirm(){  
    this.isLoading = true;
    if(this.data.isAlertPermission){
      this.updateStatusPatient();
    }
    else {
      this.matDialogRef.close({isAttend: true});
    }
  }
  
  clickCancel(){
    if(this.isLoading) return;
    this.matDialogRef.close({isAttend: false}); 
  }

  updateStatusPatient(){

    let updateStatusRequest: UpdateStatusQueueRequest = new UpdateStatusQueueRequest();

    updateStatusRequest.idStatusQueue = StatusQueueEnum.em_atendimento;

    this.queueService.updtadeStatus(this.data.idQueue, updateStatusRequest).subscribe({
      next: (response)=>{
      
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
  
        this.isLoading = false;
        this.matDialogRef.close({isAttend: true}); 
      },
      error: (error)=>{
        console.log(error)
        this.isLoading = false;    
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.matDialogRef.close({isAttend: false}); 
      }
    });
  }
}
