export class AdmissionGuide {
    public idAdmissionGuide: number;
    public providerGuideNumber: string;
    public datetimeAuthorization: Date;
    public ticket: string;
    public datetimeExpirationTicket: Date;
    public beneficiaryDocumentNumber: string;
    public datetimeExpirationDocumentNumber: Date;
    public newbornAttendance: boolean;
    public idProviderIdentityType: number;
    public requesterCodeOperator: string;
    public requesterName: string;
    public idProfessionalAgreement: number;
    public requesterProfessionalName: string;
    public idProfessionalCouncil: number;
    public requesterProfessionalNumberCouncil: string;
    public idState: number;
    public idCbo: number;
    public idAttendanceAspect: number;
    public requesterHospitalCode: string;
    public requesterHospitalName: string;
    public datetimeSuggestedAdmission: Date;
    public idHospitalizationRegime: number;
    public idHospitalizationType: number;
    public requesterHospitalDailyCount: string;
    public opmeUsageForecast: string;
    public predictionChemotherapyUse: string;
    public clinicalIndication: string;
    public cidTenPrimary: string;
    public cidTenSecondary: string;
    public cidTenTertiary: string;
    public cidTenQuaternary: string;
    public idAccidentIndication: number;
    public datetimeHospitalAuthorization: Date;
    public authorizedHospitalDailyCount: number;
    public idAccommodationType: number;
    public requesterCodeOperatorAuthorized: string;
    public requesterHospitalNameAuthorized: string;
    public codeCnes: string;
    public observationJustification: string;
    public datetimeRequest: Date;
    public isUpdate: boolean;
}