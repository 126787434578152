import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsClassificationService } from 'src/app/classification/utils.service';
import { DiscriminatorTypeEnum } from 'src/app/shared/enum/discriminator-type.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { DiscriminatorModel } from 'src/app/shared/services/models/risk-classification/discriminator.model';
import { AcidosisModalComponent } from './acidosis-modal/acidosis-modal.component';
import { DeviceTypeSectorResponse } from 'src/app/shared/services/responses/risk-classification/device-type-sector.response';
import { UtilsMeasurerService } from '../../utils-measurer.service';

@Component({
  selector: 'app-capillary-glucose-modal',
  templateUrl: './capillary-glucose-modal.component.html',
  styleUrls: ['./capillary-glucose-modal.component.css']
})
export class CapillaryGlucoseModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<CapillaryGlucoseModalComponent>,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private utilService: UtilsClassificationService,
    private maskService: MaskService,
    public measurerService: UtilsMeasurerService) { }

  public model: UntypedFormGroup;
  public discriminator: DiscriminatorModel;
  public masks: Masks;
  public initValue: any = null;
  public discriminatorType: DiscriminatorTypeEnum = DiscriminatorTypeEnum.GlicemiaCapilar;
  public autisticChecked: boolean = false;
  public setUppercaseText: boolean = false;

  ngOnInit(): void {
    let deviceTypeSectorResponse: DeviceTypeSectorResponse = this.utilService.getDevicetypeBySector()
    if (deviceTypeSectorResponse && deviceTypeSectorResponse.deviceTypeSector) {
      if (deviceTypeSectorResponse.deviceTypeSector.setUppercaseText)
        this.setUppercaseText = deviceTypeSectorResponse.deviceTypeSector.setUppercaseText;
    }

    this.measurerService.setModal(this.discriminatorType);
    this.masks = this.maskService.getMasks();
    this.discriminator = this.data.discriminator;
    this.initValue = this.data.discriminatorsValues[this.discriminatorType];
    this.model = this.formBuilder.group({
      glucose: [this.data.discriminatorsValues[this.discriminatorType], Validators.required],
      discriminatorSelectionMotive: [],
      priorityAutistic: [false]
    });
  }

  changePriorityAutistic(event: any) {
    this.model.get('glucose').setValue(null);
    this.autisticChecked = event.checked
    if (event.checked) {
      this.model.get('discriminatorSelectionMotive').setValidators([Validators.required]);
      this.model.get('glucose').disable();
    } else {
      this.model.get('discriminatorSelectionMotive').setValidators(Validators.nullValidator);
      this.model.get('glucose').enable();
    }
    this.model.get('discriminatorSelectionMotive').updateValueAndValidity();
  }

  close() {
    this.measurerService.setModal(null);
    this.matDialogRef.close();
  }

  submit() {
    if (this.data.discriminatorsValues[this.discriminatorType] && (this.initValue == this.model.get("glucose").value || (this.initValue != this.data.discriminatorsValues[this.discriminatorType] && !this.model.get("glucose").value))) {
      this.model.get("glucose").setValue(this.data.discriminatorsValues[this.discriminatorType]);
    }
    if (this.model.invalid) {
      if (this.model.get('priorityAutistic').value) {
        this.alertService.show('Erro', "Preencha o motivo!", AlertType.error);
      } else {
        this.alertService.show('Erro', "Preencha os campos!", AlertType.error);
      }
      return;
    }
    if (this.model.get('priorityAutistic').value) {
      this.matDialogRef.close({ value: null, select: true, motive: this.model.get('discriminatorSelectionMotive').value });
    } else {
      let description;
      let select = false;
      let value = this.model.get("glucose").value.replace("_", "");
      this.measurerService.setModal(null);
      if (value > this.discriminator.maxValueAllowed) {
        const dialogRef = this.dialog.open(AcidosisModalComponent);
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            select = result.response;
            description = result.response.toString();
            this.matDialogRef.close({ value, select, description });
          }
        });
        return;
      }
      select = this.utilService.verifySelectCappilaryGlucose(this.discriminator, value, description);
      this.matDialogRef.close({ value, select, description });
    }
  }
}

