import { Suspicion } from "../../responses/medical-record/lookup.response";

export class MedicalCareReportRequest {
    public internalPrescriptionPrintCount: number;
    public externalPrescriptionPrintCount: number;
    public isSickNoteReport: boolean;
    public isCompleteMedicalCareReport: boolean;
    public isExamInternalReport: boolean;
    public isExamExternalReport: boolean;
    public isPrescriptionReport: boolean;
    public isPrescriptionMemedReport: boolean;
    public isIsolationReport: boolean;
    public isIsolationAcknowledgeReport: boolean;
    public isMedicalProcedureReport: boolean;
    public isBedManagement: boolean;
    public idBed: number;
    public idSector: number;
    public isOnline: boolean = false;
    public email: string;
    public isCompleted: boolean;
    public hasAnyPrint: boolean;
    public isICPBrasilSigned: boolean;
    public certificatePin: string;
    public isIcpBrailTokenSigned: boolean;
    public selectedCertificateData: string;
    public listSuspicion: Suspicion[] = [];
}