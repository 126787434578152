export class HealthcareAgreementStruct {
    public idHealthcareAgreement: number;
    public idGuide: number;
    public idHealthUnit: number;

    public ansRegistrationNumber: string;
    public logoName: string;
    public logoString64: string;
    public phone: string;
    public nameHealthcareAgreement: string;
    public ansWithName: string;

    public idTissXml: number;
    public nextBatchNumber: string;
    public nextGuideNumber: string;
    public idTerminologyOrigin: number;
    public privateHealthcare: boolean;

    public datetimeInclusion: Date;

    public isActive: boolean;
}