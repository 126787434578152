import { MedicalCareSadtStruct } from "./medical-care-sadt.struct";
import { SadtResultFileStruct } from "./sadt-result-file.struct";

export class SadtGroupStruct {
    public medicalCareSadt: MedicalCareSadtStruct[] = [];
    public sadtResultFile: SadtResultFileStruct[] = [];

    public idMedicalCare: number;
    public idPlace: number;
    public idStatus: number;
    public idEpisode: number;
    public idUser: number;
    public idSadtGroup: number;
    public idTypeSadt: number;
    public idSadtSchedule: number;

    public observation: string;
    public observationMedic: string;
    public placeName: string;
    public statusName: string;
    public userName: string;
    public typeSadtName: string;
    public sadtResult: string;
    public sadtDescription: string;
    public sadtScheduleName: string;

    public datetimeStartAttendance: Date;
    public datetimeInclusion: Date;

    public isLoadingSadt: boolean;
    public fileLinkedLater: boolean;
    public isSaved: boolean;
    public isDisabled: boolean;
}