import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { GetLookupDataResponse } from '../../responses/data-intelligence/get-lookup-data.response';
import { GetCsatResponse } from '../../responses/csat/get-csat.response';
import { CsatRequest } from '../../requests/csat/csat.request';

@Injectable({
  providedIn: 'root'
})
export class CsatService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getCsat(): Observable<GetCsatResponse> {
    let uri = `Csat`;

    return this.httpClient.get<GetCsatResponse>(environment.urlApiCsat + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public PostPutCsatRequest(request: CsatRequest): Observable<CsatRequest> {
    let uri = `Csat`;

    return this.httpClient.put<CsatRequest>(environment.urlApiCsat + uri, request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}