<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-confirm-modal-list">
            <div class="flow-sequence-title">Próximo destino do paciente:</div>
        </div>
        <div class="flow-sequence-body">
            <div class="location">
                {{flowSequence[1].destinyRoomName ? 'Sala: ' :
                (flowSequence[1].destinySectorName ? 'Setor: ' : 'Serviço: ')}}
            </div>
            <div class="location-name">
                <b>{{flowSequence[1].destinyRoomName ? flowSequence[1].destinyRoomName :
                (flowSequence[1].destinySectorName ? flowSequence[1].destinySectorName :
                flowSequence[1].destinyServiceName)}}</b>
            </div>
        </div>
        <div class="footer-confirm-modal-list" *ngIf="!readOnly && !data.isBedManagement">
            <button mat-flat-button color="primary" class="btn-block" (click)="submit()">
                <span *ngIf="isLoading == false">Encaminhar</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
            <button type="button" mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Cancelar</button>
        </div>
        <div class="footer-confirm-modal-list" *ngIf="readOnly && !data.isBedManagement">
            <button mat-flat-button color="primary" class="btn-block" (click)="clickConfirm()">
                <span *ngIf="isLoading == false">Confirmar</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
            <button type="button" mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Cancelar</button>
        </div>
        <div *ngIf= "data.isBedManagement" class="footer-confirm-modal-list">
            <button mat-flat-button color="primary" class="btn-block" (click)="submit(true)">
                <span *ngIf="isLoading == false">Encaminhar e remover o episodio do leito</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
            <button mat-flat-button color="primary" class="btn-block" (click)="submit()">
                <span *ngIf="isLoading == false">Encaminhar e manter o leito ocupado com aquele episodio</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
            <button mat-flat-button color="accent" class="btn-block"(click)="clickCancel()">Cancelar</button>
        </div>
    </div>
</div>