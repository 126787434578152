import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { DeviceOnlineResponse } from '../../responses/sync-measurer/device-online.response';
import { PatientDeviceResponse } from '../../responses/sync-measurer/patient-device.response';

@Injectable({
  providedIn: 'root'
})
export class PatientDeviceService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public isOnline(idSyncMeasurer:number): Observable<DeviceOnlineResponse> {
    
    let uri = `PatientDevice/idSyncMeasurer/${idSyncMeasurer}`
    
    return this.httpClient.get<DeviceOnlineResponse>(environment.urlApiSyncMeasurer + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getDeviceByPatient(idPatient:number): Observable<PatientDeviceResponse> {
    
    let uri = `PatientDevice/idPatient/${idPatient}`
    
    return this.httpClient.get<PatientDeviceResponse>(environment.urlApiSyncMeasurer + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
