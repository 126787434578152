import { Socket } from 'socket.io-client';
import * as myGlobals from 'globals'; //<==== this one (**Updated**)
import { Component } from '@angular/core';
import { Injectable } from '@angular/core';
import { WebsocketRoomService } from '../websocket-room.service';
import { MatDialog } from '@angular/material/dialog';
import { RoomOccupiedModalComponent } from '../../components/room-occupied-modal/room-occupied-modal.component';
import { RoomOccupiedRemoveModalComponent } from '../../components/room-occupied-remove-modal/room-occupied-remove-modal.component';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class WebsocketRoomUtilService {

    constructor(private websocketRoomService: WebsocketRoomService, public dialog: MatDialog, private router: Router) { }
    socket: Socket;
    idRoom: number;
    login: string;
    userName: string;
    seeOccupiedRoom: boolean;
    isOccupied: boolean = false;
    getIdRoom(): number {
        return this.idRoom;
    }

    getIsOccupied() {
        return this.isOccupied;
    }

    isConnect(): boolean {
        return this.socket && this.socket.connected;
    }
    clearSocket(): void {
        if (this.socket && this.socket.connected) {
            this.socket.disconnect();
        }
        this.socket = null;
        this.idRoom = null;
        this.login = null;
        this.userName = null;
        this.seeOccupiedRoom = null;
        this.isOccupied = false;
    }

    connectwebsocketRoom(idRoom: number, login: string, userName: string, route: string, seeOccupiedRoom: boolean): void {
        this.idRoom = idRoom;
        this.login = login;
        this.userName = userName;
        this.seeOccupiedRoom = seeOccupiedRoom;
        this.socket = this.websocketRoomService.websocketConnection();
        this.socket.emit('join', { idRoom, login, userName })
            .on("someoneIsConnecting", (res) => {
                if (res.login != login) {
                    this.socket.emit('someoneIsConnect', { idRoom, login, userName })
                }
            })
            .on("someoneIsConnect", (res) => {
                if (res.login != login) {
                    const dialogRef = this.dialog.open(RoomOccupiedModalComponent, {
                        data: {
                            ...res,
                            seeOccupiedRoom
                        },
                    });
                    dialogRef.afterClosed().subscribe(result => {
                        this.isOccupied = false;
                        if (result && result.visualize) {
                            this.isOccupied = true;
                        } else if (result && result.removeUser) {
                            this.socket.emit('removeUser', { idRoom, login, userName })
                        } else {
                            this.socket.disconnect();
                            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                                this.router.navigate([route]));
                        }
                    });
                }
            })
            .on("removeUser", (res) => {
                if (res.login != login) {
                    this.socket.disconnect();
                    let ref = this.dialog.open(RoomOccupiedRemoveModalComponent, { data: { ...res } });
                    ref.afterClosed().subscribe(() => {
                        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                            this.router.navigate([route]));
                    })
                }
            }).io.on("reconnect", (res) => {
                this.socket.emit('join', { idRoom, login, userName });
            });
    }
}