import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { TotemRequest } from '../../requests/totem/totem.request';
import { GetTotemResponse } from '../../responses/totem/get-totem.response';
import { ListTotemResponse } from '../../responses/totem/list-totem.response';

@Injectable({
  providedIn: 'root'
})
export class TotemService extends CommonService {

  constructor(private httpClient: HttpClient) { super(); }

  public listTotem(searchText: string, isActive: boolean): Observable<ListTotemResponse> {
    let uri = 'Totem?'

    if (searchText != null)
      uri = uri + `searchText=${searchText}&`;

    if (isActive != null)
      uri = uri + `isActive=${isActive}&`

    return this.httpClient.get<ListTotemResponse>(environment.urlApiTotem + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public deleteTotem(idTotem: number, ip: string): Observable<ReturnStruct> {
    let uri = `Totem/idTotem/${idTotem}/ip/${ip}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiTotem + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public getTotem(idTotem: number): Observable<GetTotemResponse> {
    let uri = `Totem/idTotem/${idTotem}`;

    return this.httpClient.get<GetTotemResponse>(environment.urlApiTotem + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public createTotem(body: TotemRequest): Observable<ReturnStruct> {
    let uri = `Totem`

    return this.httpClient.post<ReturnStruct>(environment.urlApiTotem + uri, body, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public updateTotem(idTotem: number, body: TotemRequest): Observable<ReturnStruct> {
    let uri = `Totem/idTotem/${idTotem}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiTotem + uri, body, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public updateTotemPin(idTotem: number, ip: string): Observable<ReturnStruct> {
    let uri = `Totem/updatePin/idTotem/${idTotem}/ip/${ip}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiTotem + uri, null, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}