import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-room-occupied-confirm-modal',
  templateUrl: './room-occupied-confirm-modal.component.html',
  styleUrls: ['./room-occupied-confirm-modal.component.css']
})
export class RoomOccupiedConfirmModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<RoomOccupiedConfirmModalComponent>) { }

  ngOnInit(): void {
  }

  close() {
    this.matDialogRef.close();
  }

  continue() {
    this.matDialogRef.close({ removeUser: true });
  }

  clickCancel() {
    this.matDialogRef.close();
  }
}

