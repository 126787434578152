import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { SelectLookupResponse } from '../../responses/queue/select-lookup.response';

@Injectable({
  providedIn: 'root'
})
export class SelectLookupService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listAllLookups(): Observable<SelectLookupResponse> {
    
    let uri = `SelectLookup`
      
    return this.httpClient.get<SelectLookupResponse>(environment.urlApiQueue + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
