import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { AttendPatientService } from 'src/app/shared/services/API/orchestrator-queue/attend-patient.service';
import { AttendPatientRequest } from 'src/app/shared/services/requests/orchestrator-queue/attend-patient.request';

@Component({
  selector: 'app-patient-list-liberate-attendance-modal',
  templateUrl: './patient-list-liberate-attendance-modal.component.html',
  styleUrls: ['./patient-list-liberate-attendance-modal.component.css']
})
export class PatientListLiberateAttendanceModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<PatientListLiberateAttendanceModalComponent>,
  private alertService: AlertService,
  private attendPatientService: AttendPatientService,
  private formBuilder: UntypedFormBuilder,
  ) { }

  public isLoading:boolean;  
  public model: UntypedFormGroup;

  ngOnInit(): void {
    this.isLoading = false;

    this.model = this.formBuilder.group({
      login: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }
  
  close(){
    this.matDialogRef.close({isAttend: false}); 
  }
  
  submit(){
    if(this.isLoading || !this.model.valid){
      return;
    }
    this.isLoading = true;
    
    let attendPatientRequest: AttendPatientRequest = new AttendPatientRequest();
    attendPatientRequest = this.data.attendPatientRequest;
    attendPatientRequest.login = this.model.get('login').value;
    attendPatientRequest.password = this.model.get('password').value;

    this.attendPatientService.restrictedAttendPatient(attendPatientRequest).subscribe({
      next:(response)=>{
      
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }   
  
        this.isLoading = false;
        this.matDialogRef.close({isAttend: true}); 
      },
      error: (error)=>{
        console.log(error)
        this.isLoading = false;    
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
  
  clickCancel(){
    this.matDialogRef.close({isAttend: false}); 
  }
}