<app-menu [menuModuleEnum]="menuModuleEnum" [isRenderMenu]="false"></app-menu>

<div class="container-body">
    <div class="white-body">
        <div class="title-header">
            <mat-icon aria-hidden="false" aria-label="devices">devices</mat-icon>
            <h1>Gerenciar dispositivos conectados</h1>
        </div>


        <p class="info-body" *ngIf="listAutomaticLogin != undefined && listAutomaticLogin.length > 0 && !this.isLoading">Clique em um dispositivo para desconectá-lo:</p>
        <div class="card-list" *ngIf="listAutomaticLogin != null && !this.isLoading">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listAutomaticLogin">
                    <div class="card-item">
                        <div class="icon-device">
                            <mat-icon *ngIf="!item.automaticLogin.isMobile" aria-hidden="false" aria-label="devices">laptop</mat-icon>
                            <mat-icon *ngIf="item.automaticLogin.isMobile" aria-hidden="false" aria-label="devices">smartphone</mat-icon>
                        </div>
                        <div class="infos">
                            <span class="primary">{{item.automaticLogin.browser}} ({{item.automaticLogin.device}})</span>
                            <span class="secundary-status last-info">Última sessão ativa: {{item.lastSession}}</span>
                            <div class="button-desconect col-6">
                                <button mat-flat-button color="accent" class="btn-block" (click)="desconect(item)">
                                    Desconectar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <app-empty-list *ngIf="listAutomaticLogin != undefined && listAutomaticLogin.length == 0 && !this.isLoading"></app-empty-list>

        <app-loading-list *ngIf="isLoading"></app-loading-list>

        <div class="button-conclude row">
            <div class="col-12 col-md-4">
                <button mat-flat-button color="accent" class="btn-block" (click)="goBack()">
                    <span *ngIf="isLoading == false">Sair</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
        </div>
    </div>
</div>