import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CommonService, HandlerErrorRequest } from "src/app/shared/services/common-service";
import { environment } from "src/environments/environment";
import { Observable, throwError } from 'rxjs';
import { catchError, timeout } from "rxjs/operators";
import { ReturnStruct } from "src/app/shared/structs/return.struct";
import { MeasurerResultResponse } from "../../responses/measurer/measurer-result.response";
import { ConnectionResponse } from "../../responses/bluetooth-api/connection.response";
import { ScanResponse } from "../../responses/bluetooth-api/scan.response";

@Injectable({
    providedIn: 'root'
})
export class MeasurerService extends CommonService {

    constructor(private router: Router, private httpClient: HttpClient) {
        super();
    }

    public verifyConnection(): Observable<ScanResponse> {
        let uri = '/';

        return this.httpClient.get<ScanResponse>(environment.urlApiBluetooth + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleBluetoothError)
            )
    }

    public scan(): Observable<ScanResponse> {
        let uri = '/scan';

        return this.httpClient.get<ScanResponse>(environment.urlApiBluetooth + uri, this.addHeaderToken())
            .pipe(
                timeout(environment.requestTimeOut),
                catchError(this.handleBluetoothError)
            )
    }

    public connect(address: string): Observable<ConnectionResponse> {
        let uri = '/connect';

        uri += `/${address}`

        return this.httpClient.get<ConnectionResponse>(environment.urlApiBluetooth + uri, this.addHeaderToken())
            .pipe(
                timeout(environment.requestTimeOut30),
                catchError(this.handleBluetoothError)
            )
    }

    public clear(): Observable<ReturnStruct> {
        let uri = '/clear';

        return this.httpClient.delete<ReturnStruct>(environment.urlApiBluetooth + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleBluetoothError)
            )
    }

    public getBloodPressure(deviceUrl: string): Observable<MeasurerResultResponse> {
        return this.httpClient.get<MeasurerResultResponse>(deviceUrl, this.addHeaderToken())
            .pipe(
                catchError(this.handleBluetoothError)
            )
    }
    public postBloodPressure(deviceUrl: string, deviceMacAddress: string): Observable<ReturnStruct> {

        if (deviceMacAddress) deviceUrl = `${deviceUrl}/${deviceMacAddress}`

        return this.httpClient.post<ReturnStruct>(deviceUrl, null, this.addHeaderToken())
            .pipe(
                catchError(this.handleBluetoothError)
            )
    }
    public deleteBloodPressure(deviceUrl: string): Observable<ReturnStruct> {
        return this.httpClient.delete<ReturnStruct>(deviceUrl, this.addHeaderToken())
            .pipe(
                catchError(this.handleBluetoothError)
            )
    }

    public getGlucometer(deviceUrl: string): Observable<MeasurerResultResponse> {
        return this.httpClient.get<MeasurerResultResponse>(deviceUrl, this.addHeaderToken())
            .pipe(
                catchError(this.handleBluetoothError)
            )
    }
    public postGlucometer(deviceUrl: string, deviceMacAddress: string): Observable<ReturnStruct> {
        if (deviceMacAddress) deviceUrl = `${deviceUrl}/${deviceMacAddress}`
        return this.httpClient.post<ReturnStruct>(deviceUrl, null, this.addHeaderToken())
            .pipe(
                catchError(this.handleBluetoothError)
            )
    }
    public deleteGlucometer(deviceUrl: string): Observable<ReturnStruct> {
        return this.httpClient.delete<ReturnStruct>(deviceUrl, this.addHeaderToken())
            .pipe(
                catchError(this.handleBluetoothError)
            )
    }

    public getOximeter(deviceUrl: string): Observable<MeasurerResultResponse> {
        return this.httpClient.get<MeasurerResultResponse>(deviceUrl, this.addHeaderToken())
            .pipe(
                catchError(this.handleBluetoothError)
            )
    }
    public postOximeter(deviceUrl: string, deviceMacAddress: string): Observable<ReturnStruct> {
        if (deviceMacAddress) deviceUrl = `${deviceUrl}/${deviceMacAddress}`
        return this.httpClient.post<ReturnStruct>(deviceUrl, null, this.addHeaderToken())
            .pipe(
                catchError(this.handleBluetoothError)
            )
    }
    public deleteOximeter(deviceUrl: string): Observable<ReturnStruct> {
        return this.httpClient.delete<ReturnStruct>(deviceUrl, this.addHeaderToken())
            .pipe(
                catchError(this.handleBluetoothError)
            )
    }

    public getThermometer(deviceUrl: string): Observable<MeasurerResultResponse> {
        return this.httpClient.get<MeasurerResultResponse>(deviceUrl, this.addHeaderToken())
            .pipe(
                catchError(this.handleBluetoothError)
            )
    }
    public postThermometer(deviceUrl: string, deviceMacAddress: string): Observable<ReturnStruct> {
        if (deviceMacAddress) deviceUrl = `${deviceUrl}/${deviceMacAddress}`
        return this.httpClient.post<ReturnStruct>(deviceUrl, null, this.addHeaderToken())
            .pipe(
                catchError(this.handleBluetoothError)
            )
    }
    public deleteThermometer(deviceUrl: string): Observable<ReturnStruct> {
        return this.httpClient.delete<ReturnStruct>(deviceUrl, this.addHeaderToken())
            .pipe(
                catchError(this.handleBluetoothError)
            )
    }

    public clearMeasurements(deviceUrl: string): Observable<ReturnStruct> {
        return this.httpClient.delete<ReturnStruct>(deviceUrl, this.addHeaderToken())
            .pipe(
                catchError(this.handleBluetoothError)
            )
    }

    handleBluetoothError(error: HttpErrorResponse) {

        let errorMessage = '';
        let handlerErrorRequest: HandlerErrorRequest = new HandlerErrorRequest();

        if (error.error instanceof ErrorEvent) {
            // Erro ocorreu no lado do client
            errorMessage = error.error.message;
            return throwError(error);
        }

        if (error.status == 504) {
            errorMessage = `GATEWAY TIMEOUT 504- Acione sua TI local para verificar sua rede/roteador/proxy local.`;
            handlerErrorRequest.setSessionErroRequest(error);
            return throwError(error);
        }

        if (error.status == 502) {
            errorMessage = `GATEWAY TIMEOUT 502- Tente novamente!`;
            return throwError(error);
        }

        if (error.status == 0) {
            errorMessage = `Você esta sem conexão com a internet no momento.`;
            handlerErrorRequest.setSessionErroRequest(error);
            return throwError(error);
        }

        // Erro ocorreu no lado do servidor
        errorMessage = `Código do erro: ${error.status}, ` + `mensagem: ${error.message}`;

        if (error.status == 401) {
            sessionStorage.removeItem("token");
            window.location.href = '/';
        }

        return throwError(error);
    };
}