import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { GuideResponse } from '../../responses/private-billing/guide.response';
import { GuideRequest } from '../../requests/private-billing/guide.request';
import { GuideStructResponse } from '../../responses/private-billing/guide-struct.response';
import { DownloadGuideReportResponse } from '../../responses/private-billing/download-guide-report.response';
import { ListTissGuideReportResponse } from '../../responses/private-billing/list-tiss-guide-report.response';

@Injectable({
  providedIn: 'root'
})
export class GuideService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public GetListByIdEpisode(idEpisode: number): Observable<GuideResponse> {
    let uri = `Guide/idEpisode/${idEpisode}`


    return this.httpClient.get<GuideResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public GetAllByIdHealthUnit(startOfAttendance: Date, endOfAttendance: Date,
    idEpisode: number, idHealthcareAgreement: number,
    idGuideType: number, idGuideStatus: number,
    idProfessionalExecutant: number, pageIndex: number): Observable<ListTissGuideReportResponse> {
    let uri = `Guide/getAllByIdHealthUnit?`

    if (startOfAttendance != null)
      uri = uri + `startOfAttendance=${startOfAttendance.toISOString()}&`;

    if (endOfAttendance != null)
      uri = uri + `endOfAttendance=${endOfAttendance.toISOString()}&`;

    if (idEpisode != null)
      uri = uri + `idEpisode=${idEpisode}&`;

    if (idHealthcareAgreement != null)
      uri = uri + `idHealthcareAgreement=${idHealthcareAgreement}&`;

    if (idGuideType != null)
      uri = uri + `idGuideType=${idGuideType}&`;

    if (idGuideStatus != null)
      uri = uri + `idGuideStatus=${idGuideStatus}&`;

    if (idProfessionalExecutant != null)
      uri = uri + `idProfessionalExecutant=${idProfessionalExecutant}&`;

    if (pageIndex != null)
      uri = uri + `pageIndex=${pageIndex}&`;

    return this.httpClient.get<ListTissGuideReportResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public Post(request: GuideRequest): Observable<GuideStructResponse> {
    let uri = `Guide`

    return this.httpClient.post<GuideStructResponse>(environment.urlApiPrivateBilling + uri, request, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public GetGuideReportName(idGuide: number): Observable<DownloadGuideReportResponse> {
    let uri = `Guide/getGuideReportName/idGuide/${idGuide}`

    return this.httpClient.get<DownloadGuideReportResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}