import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SignatureProviderEnum } from '../../enum/signature-providers/signature-providers.enum';
import { DigitalSignatureUtilService } from '../../services/digital-signature-util.service';

@Component({
  selector: 'app-signature-credentials-modal',
  templateUrl: './signature-credentials-modal.component.html',
  styleUrls: ['./signature-credentials-modal.component.css']
})
export class SignatureCredentialsModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private digitalSignatureUtilService: DigitalSignatureUtilService,
    public matDialogRef: MatDialogRef<SignatureCredentialsModalComponent>,
  ) { }

  public signatureProvidersEnum = SignatureProviderEnum;
  public obligatorySigning = false;
  public voluntarySigning = false;
  public isLoading = false;

  ngOnInit(): void {
  }

  cancelSigning() {
    this.voluntarySigning = false;
    this.matDialogRef.close({ sign: false });
  }

  submit(event: string) {
    this.matDialogRef.close({
      sign: true,
      signatureAuthentication: event,
      idSignatureProvider: this.digitalSignatureUtilService.getSelectedProvider()
    });
  }

  close() {
    if (this.matDialogRef.disableClose)
      return;

    this.matDialogRef.close();
  }
}