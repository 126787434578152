export class FeesProcedureModel {
    public idFeesProcedure: number;
    public datetimeInclusion: Date;
    public idFeesGuide: number;
    public procedureDate: Date;
    public procedureEndDatetime: string;
    public procedureStartDatetime: string;
    public procedureCount: number;
    public idAccess: number;
    public idTechnique: number;
    public adjustmentCoefficient: number;
    public procedureTotalValue: number;
    public procedureReferenceIndex: number;
    public idPriceProcedurePlan: number;
    public idPriceProcedureHealthcare: number;
    public procedureUnitValue: number;
    public otherApartment: boolean;
    public otherUrgency: boolean;
    public otherDhe: boolean;
}