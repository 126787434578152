import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { CalendarDateFormatter, CalendarView, DateAdapter } from 'angular-calendar';
import { CustomDateFormatter } from './custom-date-formatter.provider';
import { 
  CalendarSchedulerEvent,
  CalendarSchedulerViewComponent,
  DAYS_IN_WEEK,
  SchedulerDateFormatter,
  SchedulerViewDay,
  SchedulerViewHour,
  SchedulerViewHourSegment,
} from 'angular-calendar-scheduler';
import { isSameDay, isSameMonth } from 'date-fns';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-calendar-schedule',
  templateUrl: './calendar-schedule.component.html',
  styleUrls: ['./calendar-schedule.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [{
    provide: CalendarDateFormatter,
    useClass: CustomDateFormatter,
  }]
})
export class CalendarScheduleComponent implements OnInit {

    constructor(
        @Inject(LOCALE_ID) locale: string,
        private dateAdapter: DateAdapter,
        private datePipe: DatePipe
    ) {
        this.dayModifier = ((day: SchedulerViewDay): void => {
            if (!this.isDateValid(day.date)) {
                day.cssClass = 'cal-disabled';
            }
        }).bind(this);

        this.hourModifier = ((hour: SchedulerViewHour): void => {
            if (!this.isDateValid(hour.date)) {
                hour.cssClass = 'cal-disabled';
            }
        }).bind(this);

        this.segmentModifier = ((segment: SchedulerViewHourSegment): void => {
            if (!this.isDateValid(segment.date)) {
                segment.isDisabled = true;
            }
        }).bind(this);

        this.eventModifier = ((event: CalendarSchedulerEvent): void => {
            if(this.isMedicalScheduleComponent)
                event.isDisabled = !this.isDateValid(event.end);            
            else
                event.isDisabled = !this.isDateValid(event.start);
        }).bind(this);
        this.locale = locale;
    }

    public CalendarView = CalendarView;
    public view: CalendarView = CalendarView.Week;
    public viewDays: number = DAYS_IN_WEEK;
    public refresh: Subject<any> = new Subject();
    public locale: string = 'pt';
    public hourSegments: number = 2;
    public weekStartsOn: number = 0;
    public excludeDays: number[] = []; // [0];
    public weekendDays: number[] = [0,6];
    public dayStartHour: number = 0;
    public dayEndHour: number = 24;
    public dayModifier: Function;
    public hourModifier: Function;
    public segmentModifier: Function;
    public eventModifier: Function;
    public prevBtnDisabled: boolean = false;
    public nextBtnDisabled: boolean = false;
    public activeDayIsOpen: boolean = true;

    @Input() events: CalendarSchedulerEvent[];
    @Input() minDate: Date;
    @Input() maxDate: Date;
    @Input() startsWithToday: boolean;
    @Input() isMedicalScheduleComponent: boolean = false;
    @Input() canChangeView: boolean = false;
    @Input() viewSchedulerType: boolean = true;
    @Input() viewDate: Date;
    @Output() newEvent = new EventEmitter<any>();
    @Output() clicked = new EventEmitter<any>();

    @ViewChild(CalendarSchedulerViewComponent) calendarScheduler: CalendarSchedulerViewComponent;

    ngOnInit(): void {
        this.refresh.next;
    }

    private isDateValid(date: Date): boolean {
        return /*isToday(date) ||*/ date >= this.minDate && date <= this.maxDate;
    }

    addNewEvents(buttonBack: boolean, buttonNext: boolean) {
        this.newEvent.emit({
            viewDate: this.viewDate,
            buttonBack: buttonBack,
            buttonNext: buttonNext
        });
    }

    eventClicked(event: CalendarSchedulerEvent): void {
        this.clicked.emit(event);
    }

    setView(view: CalendarView) {
        this.view = view;
    }

    dayClicked({ date, events }: { date: Date; events: CalendarSchedulerEvent[] }): void {
        if (isSameMonth(date, this.viewDate)) {
            if (
            (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
            events.length === 0
            ) {
            this.activeDayIsOpen = false;
            } else {
            this.activeDayIsOpen = true;
            }
            this.viewDate = date;
        }
    }
      
    closeOpenMonthViewDay() {
        this.activeDayIsOpen = false;
    }

}
