import { Injectable, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { UtilsClassificationService } from "src/app/classification/utils.service";
import { DiscriminatorTypeEnum } from "src/app/shared/enum/discriminator-type.enum";
import { AlertService, AlertType } from "src/app/shared/services/alert.service";
import { MeasurerService } from "src/app/shared/services/API/measurer/measurer.service";
import { DiscriminatorStruct } from "src/app/shared/services/structs/risk-classification/discriminator.struct";
import { AlertGreaterGravityModalComponent } from "./alert-greater-gravity-modal/alert-greater-gravity-modal.component";
import { RoomMeasurerStruct } from "src/app/shared/services/structs/risk-classification/room-measurer.struct";
import { HttpErrorResponse } from "@angular/common/http";
import { BleErrorModalComponent } from "src/app/shared/components/ble-error-modal/ble-error-modal.component";
import { environment } from "src/environments/environment";
import { MeasurerResultResponse } from "src/app/shared/services/responses/measurer/measurer-result.response";


@Injectable({
    providedIn: 'root'
})
export class UtilsMeasurerService {
    constructor(private measurer: MeasurerService,
        private alertService: AlertService,
        private utilService: UtilsClassificationService,
        private dialog: MatDialog) { }

    public bluetoothExecutableName: string = environment.bluetoothAPIExecutableName;
    public bluetoothErrorControl: boolean = false;
    private intervalPressureId: any;
    private intervalGlucoseId: any;
    private intervalOximeterId: any;
    private intervalThermometerId: any;

    private callingBloodPressure: boolean = false;
    private callingOximeter: boolean = false;
    private callingGlucometer: boolean = false;
    private callingThermometer: boolean = false;

    private startedBloodPressure: boolean = true;
    private startedOximeter: boolean = true;
    private startedGlucometer: boolean = true;
    private startedThermometer: boolean = true;

    private modalBloodPressure: boolean = false;
    private modalSat: boolean = false;
    private modalFr: boolean = false;
    private modalGlucometer: boolean = false;
    private modalThermometer: boolean = false;

    private list: DiscriminatorStruct[];
    private discriminators: any[] = [];
    private discriminatorsPersist: any[][];

    private deviceThermometer: RoomMeasurerStruct;
    private deviceGlucometer: RoomMeasurerStruct;
    private deviceOximeter: RoomMeasurerStruct;
    private devicePressure: RoomMeasurerStruct;
    private indexLastDiscriminatorSeted: number;

    private forcedStop: boolean = false;

    private modalIsOpened: DiscriminatorTypeEnum = null;

    setIndex(index: number) {
        this.indexLastDiscriminatorSeted = index;
    }

    setModal(enumModal: DiscriminatorTypeEnum) {
        this.modalIsOpened = enumModal;
    }

    forceStop() {
        this.forcedStop = true;
        setTimeout(() => {
            this.stopMesurer();
        }, 2000);
    }

    createReference(discriminators, discriminatorsPersist, list, deviceThermometer: RoomMeasurerStruct, deviceGlucometer: RoomMeasurerStruct, deviceOximeter: RoomMeasurerStruct, devicePressure: RoomMeasurerStruct) {
        this.list = list;
        this.discriminators = discriminators;
        this.discriminatorsPersist = discriminatorsPersist;

        this.deviceThermometer = deviceThermometer ? deviceThermometer : new RoomMeasurerStruct();
        this.deviceGlucometer = deviceGlucometer ? deviceGlucometer : new RoomMeasurerStruct();
        this.deviceOximeter = deviceOximeter ? deviceOximeter : new RoomMeasurerStruct();
        this.devicePressure = devicePressure ? devicePressure : new RoomMeasurerStruct();
        this.forcedStop = false;
    }

    restartMeasurer() {
        this.restartMeasurerBloodPressurer();

        if (this.intervalGlucoseId == null) {
            this.restartMeasurerGlucometer();
        }

        this.restartMeasurerOximeter();

        if (this.intervalThermometerId == null) {
            this.restartMeasurerThermometer();
        }
    }

    restartMeasurerBloodPressurer() {
        clearInterval(this.intervalPressureId);
        this.intervalPressureId = null;
        this.measurer.deleteBloodPressure(this.devicePressure?.uri).subscribe({
            next: () => {
                if (this.forcedStop) return;
                this.measurer.postBloodPressure(this.devicePressure?.uri, this.devicePressure?.macAddresss).subscribe({
                    next: (response) => {
                        if (response.isError) {
                            setTimeout(() => { this.restartMeasurerBloodPressurer(); }, 2000);
                            return;
                        }
                        this.callingBloodPressure = false;
                        if (this.intervalPressureId == null)
                            this.intervalPressureId = setInterval(() => { this.getMeasurePressure() }, 2000);
                    }, error: (error: HttpErrorResponse) => {
                        clearInterval(this.intervalPressureId);
                        this.callingBloodPressure = false;
                        this.intervalPressureId = null;
                        let errorMessage = '';
                        if ((error.status === 0 || error.status === 504) && this.devicePressure.isBluetooth) {

                            if (!this.bluetoothErrorControl) this.openBluetoothErrorModal();

                            errorMessage = `Aplicativo ${this.bluetoothExecutableName} não encontrado`;
                        }
                        else {
                            errorMessage = error.message;
                        }
                    }
                });
            }, error: (error: HttpErrorResponse) => {
                clearInterval(this.intervalPressureId);
                this.intervalPressureId = null;
                let errorMessage = '';
                this.callingBloodPressure = false;
                if ((error.status === 0 || error.status === 504) && this.devicePressure.isBluetooth) {

                    if (!this.bluetoothErrorControl) this.openBluetoothErrorModal();

                    errorMessage = `Aplicativo ${this.bluetoothExecutableName} não encontrado`;
                }
                else {
                    errorMessage = error.message;
                }

            }
        });
    }
    restartMeasurerGlucometer() {
        clearInterval(this.intervalGlucoseId);
        this.intervalGlucoseId = null;
        this.measurer.deleteGlucometer(this.deviceGlucometer?.uri).subscribe({
            next: () => {
                if (this.forcedStop) return;
                this.measurer.postGlucometer(this.deviceGlucometer?.uri, this.deviceGlucometer?.macAddresss).subscribe({
                    next: (response) => {
                        if (response.isError) {
                            setTimeout(() => { this.restartMeasurerGlucometer(); }, 2000);
                            return;
                        }
                        this.callingGlucometer = false;
                        if (this.intervalGlucoseId == null)
                            this.intervalGlucoseId = setInterval(() => { this.getMeasureGlucometer() }, 3000);
                    }, error: (error: HttpErrorResponse) => {
                        clearInterval(this.intervalGlucoseId);
                        this.callingGlucometer = false;
                        this.intervalGlucoseId = null;
                        let errorMessage = '';
                        if ((error.status === 0 || error.status === 504) && this.deviceGlucometer.isBluetooth) {

                            if (!this.bluetoothErrorControl) this.openBluetoothErrorModal();

                            errorMessage = `Aplicativo ${this.bluetoothExecutableName} não encontrado`;
                        }
                        else {
                            errorMessage = error.message;
                        }
                    }
                });
            }, error: (error: HttpErrorResponse) => {
                clearInterval(this.intervalGlucoseId);
                this.callingGlucometer = false;
                this.intervalGlucoseId = null;
                let errorMessage = '';
                if ((error.status === 0 || error.status === 504) && this.deviceGlucometer.isBluetooth) {

                    if (!this.bluetoothErrorControl) this.openBluetoothErrorModal();

                    errorMessage = `Aplicativo ${this.bluetoothExecutableName} não encontrado`;
                }
                else {
                    errorMessage = error.message;
                }

            }
        });
    }
    restartMeasurerOximeter() {
        clearInterval(this.intervalOximeterId);
        this.intervalOximeterId = null;
        this.measurer.deleteOximeter(this.deviceOximeter?.uri).subscribe({
            next: () => {
                if (this.forcedStop) return;
                this.measurer.postOximeter(this.deviceOximeter?.uri, this.deviceOximeter?.macAddresss).subscribe({
                    next: (response) => {
                        if (response.isError) {
                            setTimeout(() => { this.restartMeasurerOximeter(); }, 2000);
                            return;
                        }
                        this.callingOximeter = false;
                        if (this.intervalOximeterId == null)
                            this.intervalOximeterId = setInterval(() => { this.getMeasureOximeter() }, 1000);
                    }, error: (error: HttpErrorResponse) => {
                        clearInterval(this.intervalOximeterId);
                        this.callingOximeter = false;
                        this.intervalOximeterId = null;
                        let errorMessage = '';
                        if ((error.status === 0 || error.status === 504) && this.deviceOximeter.isBluetooth) {

                            if (!this.bluetoothErrorControl) this.openBluetoothErrorModal();

                            errorMessage = `Aplicativo ${this.bluetoothExecutableName} não encontrado`;
                        }
                        else {
                            errorMessage = error.message;
                        }
                    }
                });
            }, error: (error: HttpErrorResponse) => {
                clearInterval(this.intervalOximeterId);
                this.callingOximeter = false;
                this.intervalOximeterId = null;
                let errorMessage = '';
                if ((error.status === 0 || error.status === 504) && this.deviceOximeter.isBluetooth) {

                    if (!this.bluetoothErrorControl) this.openBluetoothErrorModal();

                    errorMessage = `Aplicativo ${this.bluetoothExecutableName} não encontrado`;
                }
                else {
                    errorMessage = error.message;
                }

            }
        });
    }
    restartMeasurerThermometer() {
        clearInterval(this.intervalThermometerId);
        this.intervalThermometerId = null;
        this.measurer.deleteThermometer(this.deviceThermometer?.uri).subscribe({
            next: () => {
                if (this.forcedStop) return;
                this.measurer.postThermometer(this.deviceThermometer?.uri, this.deviceThermometer?.macAddresss).subscribe({
                    next: (response) => {
                        if (response.isError) {
                            setTimeout(() => { this.restartMeasurerThermometer(); }, 2000);
                            return;
                        }
                        this.callingThermometer = false;
                        if (this.intervalThermometerId == null)
                            this.intervalThermometerId = setInterval(() => { this.getMeasureThermometer() }, 1000);
                    }, error: (error: HttpErrorResponse) => {
                        clearInterval(this.intervalThermometerId);
                        this.callingThermometer = false;
                        this.intervalThermometerId = null;
                        let errorMessage = '';
                        if ((error.status === 0 || error.status === 504) && this.deviceThermometer.isBluetooth) {

                            if (!this.bluetoothErrorControl) this.openBluetoothErrorModal();

                            errorMessage = `Aplicativo ${this.bluetoothExecutableName} não encontrado`;
                        }
                        else {
                            errorMessage = error.message;
                        }
                    }
                });
            }, error: (error: HttpErrorResponse) => {
                clearInterval(this.intervalThermometerId);
                this.callingThermometer = false;
                this.intervalThermometerId = null;
                let errorMessage = '';
                if ((error.status === 0 || error.status === 504) && this.deviceThermometer.isBluetooth) {

                    if (this.dialog.openDialogs.filter(x => x.componentInstance == BleErrorModalComponent).length === 0) {
                        if (!this.bluetoothErrorControl) this.openBluetoothErrorModal();
                    }
                    errorMessage = `Aplicativo ${this.bluetoothExecutableName} não encontrado`;
                }
                else {
                    errorMessage = error.message;
                }
            }
        });
    }

    getMeasurePressure(): void {
        let openModalGreaterGravity = false;
        if (this.forcedStop) {
            clearInterval(this.intervalPressureId);
            this.intervalPressureId = null;
            return;
        }
        if (!this.callingBloodPressure) {
            this.callingBloodPressure = true;
            this.measurer.getBloodPressure(this.devicePressure?.uri).subscribe({
                next: (response) => {
                    if (response.isError || !response.running) {
                        this.restartMeasurerBloodPressurer();
                        return;
                    } else {
                        if (this.startedBloodPressure) {
                            this.alertService.show("Sucesso", "Aparelho de pressão iniciado com sucesso", AlertType.success);
                            this.startedBloodPressure = false;
                        }
                        if (response.measurer) {
                            this.setValuePressure(response, openModalGreaterGravity);
                            if (this.devicePressure.isBluetooth) {
                                this.callingBloodPressure = false;
                            }
                        } else {
                            this.callingBloodPressure = false;
                        }
                    }
                    this.callingBloodPressure = false;
                }, error: (error: HttpErrorResponse) => {
                    clearInterval(this.intervalPressureId);
                    this.intervalPressureId = null;
                    this.callingBloodPressure = false;
                    let errorMessage = '';
                    if ((error.status === 0 || error.status === 504) && this.devicePressure.isBluetooth) {

                        if (!this.bluetoothErrorControl) this.openBluetoothErrorModal();

                        errorMessage = `Aplicativo ${this.bluetoothExecutableName} não encontrado`;
                    }
                    else {
                        errorMessage = error.message;
                    }
                }
            });
        }
    }

    getMeasureOximeter(): void {
        let openModalGreaterGravitySat = false;
        let openModalGreaterGravityFr = false;
        if (this.forcedStop) {
            clearInterval(this.intervalOximeterId);
            this.intervalOximeterId = null;
            return;
        }
        if (!this.callingOximeter) {
            this.callingOximeter = true;
            this.measurer.getOximeter(this.deviceOximeter?.uri).subscribe({
                next: (response) => {
                    if (response.isError || !response.running) {
                        this.restartMeasurerOximeter();
                        return;
                    }
                    else {
                        if (this.startedOximeter) {
                            this.alertService.show("Sucesso", "Oxímetro iniciado com sucesso", AlertType.success);
                            this.startedOximeter = false;
                        }
                        this.callingOximeter = false;
                        if (response.measurer) {
                            this.setValueOximeter(response, openModalGreaterGravitySat, openModalGreaterGravityFr);
                        }
                    }
                }, error: (error: HttpErrorResponse) => {
                    clearInterval(this.intervalOximeterId);
                    this.intervalOximeterId = null;
                    this.callingOximeter = false;
                    let errorMessage = '';
                    if ((error.status === 0 || error.status === 504) && this.deviceOximeter.isBluetooth) {

                        if (!this.bluetoothErrorControl) this.openBluetoothErrorModal();

                        errorMessage = `Aplicativo ${this.bluetoothExecutableName} não encontrado`;
                    }
                    else {
                        errorMessage = error.message;
                    }
                }
            });
        }
    }

    getMeasureGlucometer(): void {
        let openModalGreaterGravity = false;
        if (this.forcedStop) {
            clearInterval(this.intervalGlucoseId);
            this.intervalGlucoseId = null;
            return;
        }
        if (!this.callingGlucometer) {
            this.callingGlucometer = true;
            this.measurer.getGlucometer(this.deviceGlucometer?.uri).subscribe({
                next: (response) => {
                    if (response.isError || !response.running) {
                        this.restartMeasurerGlucometer();
                        return;
                    }
                    else {
                        if (this.startedGlucometer) {
                            this.alertService.show("Sucesso", "Glicosímetro iniciado com sucesso", AlertType.success);
                            this.startedGlucometer = false;
                        }
                        this.callingGlucometer = false;
                        if (response.measurer) {
                            this.setValueGlucometer(response, openModalGreaterGravity);
                        }
                    }
                }, error: (error: HttpErrorResponse) => {
                    clearInterval(this.intervalGlucoseId);
                    this.intervalGlucoseId = null;
                    this.callingGlucometer = false;
                    let errorMessage = '';
                    if ((error.status === 0 || error.status === 504) && this.deviceGlucometer.isBluetooth) {

                        if (!this.bluetoothErrorControl) this.openBluetoothErrorModal();

                        errorMessage = `Aplicativo ${this.bluetoothExecutableName} não encontrado`;
                    }
                    else {
                        errorMessage = error.message;
                    }
                }
            });
        }
    }

    getMeasureThermometer(): void {
        let openModalGreaterGravity = false;
        if (this.forcedStop) {
            clearInterval(this.intervalThermometerId);
            this.intervalThermometerId = null;
            return;
        }
        if (!this.callingThermometer) {
            this.callingThermometer = true;
            this.measurer.getThermometer(this.deviceThermometer?.uri).subscribe({
                next: (response) => {
                    if (response.isError || !response.running) {
                        this.restartMeasurerThermometer();
                        return;
                    }
                    else {
                        if (this.startedThermometer) {
                            this.alertService.show("Sucesso", "termômetro iniciado com sucesso", AlertType.success);
                            this.startedThermometer = false;
                        }
                        this.callingThermometer = false; //Sinal verde semafaro
                        if (response.measurer) { //Se houver valor seta em tela
                            this.setValueThermometer(response, openModalGreaterGravity);
                        }
                    }
                }, error: (error: HttpErrorResponse) => {
                    clearInterval(this.intervalThermometerId);
                    this.intervalThermometerId = null;
                    this.callingThermometer = false; //Sinal verde semafaro
                    let errorMessage = '';
                    if ((error.status === 0 || error.status === 504) && this.deviceThermometer.isBluetooth) {

                        if (!this.bluetoothErrorControl) this.openBluetoothErrorModal();

                        errorMessage = `Aplicativo ${this.bluetoothExecutableName} não encontrado`;
                    }
                    else {
                        errorMessage = error.message;
                    }
                }
            });
        }
    }


    setValuePressure(response: MeasurerResultResponse, openModalGreaterGravity: boolean) {
        let sepseIndex = this.list ? this.list.findIndex(c => c.idDiscriminatorType == DiscriminatorTypeEnum.SepsePossivel) : -1;;

        let firstIndex: number = null;

        if (sepseIndex > -1 && Array.isArray(this.discriminatorsPersist[DiscriminatorTypeEnum.PressaoArterial])) {
            firstIndex = Math.min(sepseIndex, this.discriminatorsPersist[DiscriminatorTypeEnum.PressaoArterial][0]);
        } else if (Array.isArray(this.discriminatorsPersist[DiscriminatorTypeEnum.PressaoArterial])) {
            firstIndex = this.discriminatorsPersist[DiscriminatorTypeEnum.PressaoArterial][0];
        } else if (sepseIndex > -1) {
            firstIndex = sepseIndex;
        }

        if (!(this.discriminators[DiscriminatorTypeEnum.PressaoArterial])
            || this.modalIsOpened == DiscriminatorTypeEnum.PressaoArterial ||
            (firstIndex == null || firstIndex >= this.indexLastDiscriminatorSeted)) {
            this.discriminators[DiscriminatorTypeEnum.PressaoArterial] = response.measurer;
            if (Array.isArray(this.discriminatorsPersist[DiscriminatorTypeEnum.PressaoArterial]) && this.list) {
                this.discriminatorsPersist[DiscriminatorTypeEnum.PressaoArterial].forEach((c: number) => {
                    this.list[c].value = response.measurer;
                    if (c < this.indexLastDiscriminatorSeted) {
                        if (!openModalGreaterGravity) {
                            openModalGreaterGravity = this.utilService.verifySelectDiscriminator(this.list[c], response.measurer, this.list[c].type, this.list[c].state)
                        }
                    }
                });
            }

            if (openModalGreaterGravity && !this.modalBloodPressure && this.modalIsOpened != DiscriminatorTypeEnum.PressaoArterial) {
                this.modalBloodPressure = true;
                this.openModalGreaterGravity("Pressão Arterial", response.measurer, DiscriminatorTypeEnum.PressaoArterial)
            }
        }
        if (!this.devicePressure.isBluetooth) {
            this.restartMeasurerBloodPressurer();
        }
    }

    setValueOximeter(response: MeasurerResultResponse, openModalGreaterGravitySat: boolean, openModalGreaterGravityFr: boolean) {
        if (!(this.discriminators[DiscriminatorTypeEnum.Saturacao]) || this.modalIsOpened == DiscriminatorTypeEnum.Saturacao || (!Array.isArray(this.discriminatorsPersist[DiscriminatorTypeEnum.Saturacao]) || (Array.isArray(this.discriminatorsPersist[DiscriminatorTypeEnum.Saturacao]) && this.discriminatorsPersist[DiscriminatorTypeEnum.Saturacao][0] >= this.indexLastDiscriminatorSeted))) {
            this.discriminators[DiscriminatorTypeEnum.Saturacao] = response.measurer.split("/")[0];
            if (Array.isArray(this.discriminatorsPersist[DiscriminatorTypeEnum.Saturacao]) && this.list) {
                this.discriminatorsPersist[DiscriminatorTypeEnum.Saturacao].forEach((c: number) => {
                    this.list[c].value = response.measurer.split("/")[0];
                    if (c < this.indexLastDiscriminatorSeted) {
                        if (!openModalGreaterGravitySat) {
                            openModalGreaterGravitySat = this.utilService.verifySelectDiscriminator(this.list[c], response.measurer.split("/")[0], this.list[c].type, this.list[c].state)
                        }
                    }
                });
            }

            if (openModalGreaterGravitySat && !this.modalSat && this.modalIsOpened != DiscriminatorTypeEnum.Saturacao) {
                this.modalSat = true;
                this.openModalGreaterGravity("Saturação", response.measurer.split("/")[0], DiscriminatorTypeEnum.Saturacao);
            }
        }

        let sepseIndex = this.list ? this.list.findIndex(c => c.idDiscriminatorType == DiscriminatorTypeEnum.SepsePossivelCrianca) : -1;

        let firstIndex: number = null;

        if (sepseIndex > -1 && Array.isArray(this.discriminatorsPersist[DiscriminatorTypeEnum.FrequenciaCardiaca])) {
            firstIndex = Math.min(sepseIndex, this.discriminatorsPersist[DiscriminatorTypeEnum.FrequenciaCardiaca][0]);
        } else if (Array.isArray(this.discriminatorsPersist[DiscriminatorTypeEnum.FrequenciaCardiaca])) {
            firstIndex = this.discriminatorsPersist[DiscriminatorTypeEnum.FrequenciaCardiaca][0];
        } else if (sepseIndex > -1) {
            firstIndex = sepseIndex;
        }

        if (!(this.discriminators[DiscriminatorTypeEnum.FrequenciaCardiaca]) || this.modalIsOpened == DiscriminatorTypeEnum.FrequenciaCardiaca || (firstIndex == null || firstIndex >= this.indexLastDiscriminatorSeted)) {
            this.discriminators[DiscriminatorTypeEnum.FrequenciaCardiaca] = response.measurer.split("/")[1];
            if (Array.isArray(this.discriminatorsPersist[DiscriminatorTypeEnum.FrequenciaCardiaca]) && this.list) {
                this.discriminatorsPersist[DiscriminatorTypeEnum.FrequenciaCardiaca].forEach((c: number) => {
                    this.list[c].value = response.measurer.split("/")[1];

                    if (c < this.indexLastDiscriminatorSeted) {
                        if (!openModalGreaterGravityFr) {
                            openModalGreaterGravityFr = this.utilService.verifySelectDiscriminator(this.list[c], response.measurer.split("/")[1], this.list[c].type, this.list[c].state)
                        }
                    }
                });
            }
            if (openModalGreaterGravityFr && !this.modalFr && this.modalIsOpened != DiscriminatorTypeEnum.FrequenciaCardiaca) {
                this.modalFr = true;
                this.openModalGreaterGravity("Frequência Cardiaca", response.measurer.split("/")[1], DiscriminatorTypeEnum.FrequenciaCardiaca);
            }
        }
    }

    setValueGlucometer(response: MeasurerResultResponse, openModalGreaterGravity: boolean) {
        let firstIndex: number = null;

        if (Array.isArray(this.discriminatorsPersist[DiscriminatorTypeEnum.GlicemiaCapilar]) && Array.isArray(this.discriminatorsPersist[DiscriminatorTypeEnum.Glicemia])) {
            firstIndex = Math.min(this.discriminatorsPersist[DiscriminatorTypeEnum.GlicemiaCapilar][0], this.discriminatorsPersist[DiscriminatorTypeEnum.Glicemia][0]);
        } else if (Array.isArray(this.discriminatorsPersist[DiscriminatorTypeEnum.Glicemia])) {
            firstIndex = this.discriminatorsPersist[DiscriminatorTypeEnum.Glicemia][0];
        } else if (Array.isArray(this.discriminatorsPersist[DiscriminatorTypeEnum.GlicemiaCapilar])) {
            firstIndex = this.discriminatorsPersist[DiscriminatorTypeEnum.GlicemiaCapilar][0];
        }

        if (!(this.discriminators[DiscriminatorTypeEnum.Glicemia]) || this.modalIsOpened == DiscriminatorTypeEnum.GlicemiaCapilar || this.modalIsOpened == DiscriminatorTypeEnum.Glicemia || (firstIndex == null || firstIndex >= this.indexLastDiscriminatorSeted)) {
            this.discriminators[DiscriminatorTypeEnum.Glicemia] = response.measurer;
            if (Array.isArray(this.discriminatorsPersist[DiscriminatorTypeEnum.Glicemia]) && this.list) {
                this.discriminatorsPersist[DiscriminatorTypeEnum.Glicemia].forEach((c: number) => {
                    this.list[c].value = response.measurer;
                    if (c < this.indexLastDiscriminatorSeted) {
                        if (!openModalGreaterGravity) {
                            openModalGreaterGravity = this.utilService.verifySelectDiscriminator(this.list[c], this.list[c].value, this.list[c].type, this.list[c].state)
                        }
                    }
                });
            }

            if (Array.isArray(this.discriminatorsPersist[DiscriminatorTypeEnum.GlicemiaCapilar]) && this.list) {
                this.discriminatorsPersist[DiscriminatorTypeEnum.GlicemiaCapilar].forEach((c: number) => {
                    this.list[c].value = "";
                    this.list[c].value = response.measurer;
                    if (this.list[c].maxValueAllowed < this.list[c].value) {
                        this.list[c].hasValue = false;
                    } else {
                    }
                    if (c < this.indexLastDiscriminatorSeted) {
                        if (!openModalGreaterGravity) {
                            openModalGreaterGravity = this.utilService.verifySelectDiscriminator(this.list[c], response.measurer, this.list[c].type, this.list[c].state)
                        }
                    }
                });
            }

            if (openModalGreaterGravity && !this.modalGlucometer && !(this.modalIsOpened == DiscriminatorTypeEnum.Glicemia || this.modalIsOpened == DiscriminatorTypeEnum.GlicemiaCapilar)) {
                this.modalGlucometer = true;
                this.openModalGreaterGravity("Glicemia", response.measurer, DiscriminatorTypeEnum.Glicemia)
            }
        }
    }

    setValueThermometer(response: MeasurerResultResponse, openModalGreaterGravity: boolean) {
        let sepseIndex = this.list ? this.list.findIndex(c => c.idDiscriminatorType == DiscriminatorTypeEnum.SepsePossivelCrianca) : -1;

        let firstIndex: number = null;

        if (sepseIndex > -1 && Array.isArray(this.discriminatorsPersist[DiscriminatorTypeEnum.Temperatura])) {
            firstIndex = Math.min(sepseIndex, this.discriminatorsPersist[DiscriminatorTypeEnum.Temperatura][0]);
        } else if (Array.isArray(this.discriminatorsPersist[DiscriminatorTypeEnum.Temperatura])) {
            firstIndex = this.discriminatorsPersist[DiscriminatorTypeEnum.Temperatura][0];
        } else if (sepseIndex > -1) {
            firstIndex = sepseIndex;
        }

        if (!(this.discriminators[DiscriminatorTypeEnum.Temperatura]) || this.modalIsOpened == DiscriminatorTypeEnum.Temperatura || (firstIndex == null || firstIndex >= this.indexLastDiscriminatorSeted)) {
            this.discriminators[DiscriminatorTypeEnum.Temperatura] = response.measurer;
            if (Array.isArray(this.discriminatorsPersist[DiscriminatorTypeEnum.Temperatura]) && this.list) {
                this.discriminatorsPersist[DiscriminatorTypeEnum.Temperatura].forEach((c: number) => {
                    this.list[c].value = response.measurer;
                    if (c < this.indexLastDiscriminatorSeted) {
                        if (!openModalGreaterGravity) {
                            openModalGreaterGravity = this.utilService.verifySelectDiscriminator(this.list[c], response.measurer, this.list[c].type, this.list[c].state)
                        }
                    }
                });
                if (openModalGreaterGravity && !this.modalThermometer && this.modalIsOpened != DiscriminatorTypeEnum.Temperatura) {
                    this.modalThermometer = true;
                    this.openModalGreaterGravity("Temperatura", response.measurer, DiscriminatorTypeEnum.Temperatura);
                }
            }
        }
    }

    openModalGreaterGravity(vitalSignName, newValue, oldValue) {
        let data: any = {};
        data.vitalSignName = vitalSignName;
        data.newValue = newValue;
        data.oldValue = this.discriminators[oldValue];
        const dialogRef = this.dialog.open(AlertGreaterGravityModalComponent, { data });
        dialogRef.afterClosed().subscribe((result) => {
            if (oldValue == DiscriminatorTypeEnum.Temperatura) {
                this.modalThermometer = false;
            } else if (oldValue == DiscriminatorTypeEnum.Glicemia) {
                this.modalGlucometer = false;
            } else if (oldValue == DiscriminatorTypeEnum.PressaoArterial) {
                this.modalBloodPressure = false;
            } else if (oldValue == DiscriminatorTypeEnum.FrequenciaCardiaca) {
                this.modalFr = false;
            } else if (oldValue == DiscriminatorTypeEnum.Saturacao) {
                this.modalSat = false;
            }
        });
    }

    stopMesurer() {
        this.measurer.deleteBloodPressure(this.devicePressure?.uri).subscribe();
        this.measurer.deleteGlucometer(this.deviceGlucometer?.uri).subscribe();
        this.measurer.deleteOximeter(this.deviceOximeter?.uri).subscribe();
        this.measurer.deleteThermometer(this.deviceThermometer?.uri).subscribe();
    }

    openBluetoothErrorModal() {
        if (!this.bluetoothErrorControl) {
            this.bluetoothErrorControl = true;
            this.dialog.open(BleErrorModalComponent).afterClosed().subscribe(() => this.bluetoothErrorControl = false);
        }
    }

    clearAllInterval() {
        if (this.devicePressure && this.deviceGlucometer && this.deviceOximeter && this.deviceThermometer) {
            this.stopMesurer();
        }
        this.forcedStop = true;
        clearInterval(this.intervalPressureId);
        this.intervalPressureId = null;
        clearInterval(this.intervalGlucoseId);
        this.intervalGlucoseId = null;
        clearInterval(this.intervalOximeterId);
        this.intervalOximeterId = null;
        clearInterval(this.intervalThermometerId);
        this.intervalThermometerId = null;
    }
}