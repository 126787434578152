import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StatusQueueEnum } from '../../enum/status-queue.enum';
import { AlertService, AlertType } from '../../services/alert.service';
import { UpdateStatusQueueService } from '../../services/API/orchestrator-queue/update-status-queue.service';
import { StatusQueueRequest } from '../../services/requests/queue/status-queue.request';

@Component({
  selector: 'app-room-occupied-remove-modal',
  templateUrl: './room-occupied-remove-modal.component.html',
  styleUrls: ['./room-occupied-remove-modal.component.css']
})
export class RoomOccupiedRemoveModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<RoomOccupiedRemoveModalComponent>) { }

  ngOnInit(): void {
  }

  close() {
    this.matDialogRef.close();
  }

  continue() {
    this.matDialogRef.close();
  }

  clickCancel() {
    this.matDialogRef.close();
  }
}

