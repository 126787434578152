<div mat-dialog-content>
    <div class="mat-dialog-content">
        <h2 class="title">Configurar aplicativo de medições bluetooth</h2>
        <div class="body description">
            <p>Para configurar um medidor Bluetooth siga os passos abaixo:
            </p>
            <ol>
                <li>Faça o download clicando <a class="download-link" (click)="download()">aqui</a>, ou no botão de <b>Download</b> abaixo.</li>
                <li>Uma vez baixado o arquivo <b>{{bluetoothExecutableName}}.zip</b> descompacte o arquivo.</li>
                <li>Depois de descompactar o arquivo, clique no arquivo <b>app.exe</b> e execute-o como administrador, clicando com o botão direito do mouse e selecionando a opção "Executar como administrador".</li>
                <li>Após a execução, você pode minimizar o projeto clicando no botão minimizar e clique em verificar conexão para testar.</li>
                <li>Depois reinicie os medidores clicando no botão <b>reiniciar medidores</b> no topo direito da tela do celerus ou recomece o processo de classificação de risco.</li>
                <li>Caso essa mensagem apareça novamente, feche a janela do programa e rode o arquivo <b>app.exe</b> como administrador outra vez.</li>
            </ol>
        </div>
        <div class="footer-modal">
            <div class="row">
                <div class="col-12 col-sm-6"> 
                    <button *ngIf="!connected" mat-flat-button color="primary" class="btn-block" (click)="retry()">
                        <span  *ngIf="isLoading == false">Verificar Conexão</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                    <button *ngIf="connected" mat-flat-button color="accent" class="btn-block" (click)="close()">
                        <span  *ngIf="isLoading == false">Fechar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
                <div class="col-12 col-sm-6"> <button mat-flat-button color="primary" class="btn-block" (click)="download()">Download</button></div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 align-end">
                <a class="cancel-link" (click)="close()">Seguir sem verificar</a>
            </div>
        </div>
    </div>
</div>