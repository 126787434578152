import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { MedicConfigSectorResponse } from '../../responses/medic/medic-config-sector.response';
import { GetMessageResponse } from '../../responses/notification/get-message.response';

@Injectable({
  providedIn: 'root'
})
export class MessageService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listByUser(): Observable<GetMessageResponse> {
    
    let uri = `Message`;
    
    return this.httpClient.get<GetMessageResponse>(environment.urlApiNotification + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public markAllRead(): Observable<ReturnStruct> {
    
    let uri = `Message`;
    
    return this.httpClient.put<ReturnStruct>(environment.urlApiNotification + uri, null, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public markRead(idMessage: number): Observable<ReturnStruct> {
    
    let uri = `Message/idMessage/${idMessage}`;
    
    return this.httpClient.put<ReturnStruct>(environment.urlApiNotification + uri, null, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public deleteAll(): Observable<ReturnStruct> {
    
    let uri = `Message`;
    
    return this.httpClient.delete<ReturnStruct>(environment.urlApiNotification + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public delete(idMessage: number): Observable<ReturnStruct> {
    
    let uri = `Message/idMessage/${idMessage}`;
    
    return this.httpClient.delete<ReturnStruct>(environment.urlApiNotification + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
