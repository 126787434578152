import { Component, Inject, OnInit } from '@angular/core';
import { AlertService, AlertType } from '../../services/alert.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SuspicionStatusService } from '../../services/API/medical-record/suspicion-status.service';
import { SuspicionStatusRequest } from '../../services/requests/medical-record/suspicion-status.request';
import { Suspicion } from '../../services/responses/medical-record/lookup.response';
import { SuspicionStatusResponse } from '../../services/responses/medical-record/suspicion-status.response';

@Component({
  selector: 'app-validate-suspicion-modal',
  templateUrl: './validate-suspicion-modal.component.html',
  styleUrls: ['./validate-suspicion-modal.component.css']
})
export class ValidateSuspicionModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public matDialogRef: MatDialogRef<ValidateSuspicionModalComponent>,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private suspicionStatusService: SuspicionStatusService,
  ) { }

  public model: FormGroup;
  public idEpisode: number;
  public suspicion: Suspicion = new Suspicion;
  public suspicionStatusResponse: SuspicionStatusResponse;
  public statusConfirmed: boolean = true;
  public statusDenied: boolean = false;
  public isLoading: boolean = false;

  ngOnInit(): void {
    this.idEpisode = this.data.idEpisode;
    this.suspicion = this.data.suspicion;

    this.model = this.formBuilder.group({
      isSuspicionEvaluated: [false, [Validators.required]],
      suspicionEvaluationMotive: ["", [Validators.required]]
    });
  }

  submit() {
    if (this.isLoading)
      return;

    this.isLoading = true;

    if (this.model.invalid)
      return;

    let request: SuspicionStatusRequest = new SuspicionStatusRequest();
    request.isSuspicionEvaluated = this.model.get('isSuspicionEvaluated').value;
    request.suspicionEvaluationMotive = this.model.get('suspicionEvaluationMotive').value;

    this.suspicionStatusService.putSuspicionEvaluatedStatus(this.idEpisode, request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.suspicionStatusResponse = response;

        let status: string = "";

        if (response.isSuspicionEvaluated === this.statusConfirmed)
          status = "confirmada";
        else if (response.isSuspicionEvaluated === this.statusDenied)
          status = "negada";

        this.isLoading = false;
        this.alertService.show('Sucesso!', `Suspeita ${status} com sucesso`, AlertType.success);

        this.matDialogRef.close({ suspicionStatusResponse: this.suspicionStatusResponse });
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  clickCancel() {
    this.matDialogRef.close({});
  }
}