import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-health-unit-confirm-modal',
  templateUrl: './health-unit-confirm-modal.component.html',
  styleUrls: ['./health-unit-confirm-modal.component.css']
})
export class HealthUnitConfirmModalComponent implements OnInit {

  constructor(private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<HealthUnitConfirmModalComponent>,
    private formBuilder: UntypedFormBuilder,
  ) { }

  public isLoading: boolean;


  ngOnInit(): void {
    this.isLoading = false;  
    this.matDialogRef.disableClose = true;
  }

  clickCancel(){
    this.matDialogRef.close({confirmLogin: false}); 
  }
  clickConfirm(){
    this.matDialogRef.close({confirmLogin: true}); 
  }
  close(){
    this.matDialogRef.close({confirmLogin: false}); 
  }
}
