<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-first-access-modal">
            <h2 *ngIf="!isExpiredPassword">Primeiro Acesso</h2>
            <h2 *ngIf="isExpiredPassword">Senha Expirada! Troque para continuar.</h2>
        </div>
        <div class="body-first-access-modal">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="row">   
                    <div class="col-md-12">
                        <mat-form-field appearance="outline">
                            <mat-label *ngIf="!isExpiredPassword">Senha Temporária</mat-label>
                            <mat-label *ngIf="isExpiredPassword">Senha Antiga</mat-label>
                            <mat-icon matSuffix (click)="hideOld = !hideOld">{{hideOld ? 'visibility_off' : 'visibility'}}</mat-icon>
                            <input  matInput #oldPassword [type]="hideOld ? 'password' : 'text'" formControlName="oldPassword" required>
                            <mat-error *ngIf="model.get('oldPassword').invalid && !isExpiredPassword">Informe a senha temporária</mat-error>
                            <mat-error *ngIf="model.get('oldPassword').invalid && isExpiredPassword">Informe a senha antiga</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">   
                    <div class="col-md-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Nova senha</mat-label>
                            <mat-icon matSuffix (click)="hideNew = !hideNew">{{hideNew ? 'visibility_off' : 'visibility'}}</mat-icon>
                            <input #newPassword matInput [type]="hideNew ? 'password' : 'text'" formControlName="newPassword" required>
                            <mat-error *ngIf="model.get('newPassword').invalid && !model.get('repetedPassword').getError('invalidPassword')">Informe a nova senha</mat-error>
                            <mat-error *ngIf="model.get('newPassword').getError('invalidPassword')">{{ model.get('newPassword').getError('invalidPassword').message}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">   
                    <div class="col-md-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Repetir senha</mat-label>
                            <mat-icon matSuffix (click)="hideNewRepeat = !hideNewRepeat">{{hideNewRepeat ? 'visibility_off' : 'visibility'}}</mat-icon>
                            <input matInput #newPasswordRepeat [type]="hideNewRepeat ? 'password' : 'text'" formControlName="repetedPassword" required>
                            <mat-error *ngIf="model.get('repetedPassword').invalid && !model.get('repetedPassword').getError('invalidPassword')">Informe a nova senha</mat-error>
                            <mat-error *ngIf="model.get('repetedPassword').getError('invalidPassword')">{{ model.get('repetedPassword').getError('invalidPassword').message}}</mat-error>
                        </mat-form-field>
                        <p [ngStyle]="{ 'color': model.get('newPassword').invalid ? '#fc0303' : '#000000' }">Use entre oito ou mais caracteres, com uma combinação de letras maiúsculas, letras minúsculas, números e caracteres especiais.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <button mat-flat-button type="submit" color="primary" class=" btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true">
                            </mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-6">
                        <a mat-flat-button type="button" color="accent" class=" btn-block" (click)="clickCancel()">
                            Cancelar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>