import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-glasgow-modal',
  templateUrl: './glasgow-modal.component.html',
  styleUrls: ['./glasgow-modal.component.css']
})
export class GlasgowModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<GlasgowModalComponent>) { }
  public glasgowScale;
  public result: number = 0;
  public currentPage: number = 0;
  private glasgowMotor: number;
  private glasgowVerbal: number;
  private glasgowEye: number;

  public glasgowScaleList: any = [];


  private glasgowScaleAdult = [{
    "title": "Avaliação Ocular", "body": [
      { "value": 4, "label": "Olhos abertos espontâneamente" },
      { "value": 3, "label": "Apenas abre os olhos à fala (ordem verbal)" },
      { "value": 2, "label": "Apenas abre os olhos à dor(estímulo doloroso)" },
      { "value": 1, "label": "Não abre os olhos" }]
  },
  {
    "title": "Avaliação Verbal", "body": [
      { "value": 5, "label": "Discurso coerente e orientado" },
      { "value": 4, "label": "Discurso confuso" },
      { "value": 3, "label": "Discurso inapropriado" },
      { "value": 2, "label": "Discurso incompreensível | Sons incompreensíveis" },
      { "value": 1, "label": "Não fala" }]
  },
  {
    "title": "Avaliação Motora", "body": [
      { "value": 6, "label": "Cumpre ordens" },
      { "value": 5, "label": "Não cumpre ordens, mas localiza a dor" },
      { "value": 4, "label": "Flexão anormal do(s) membro(s) à dor (movimento de fuga)" },
      { "value": 3, "label": "Flexão do(s) membro(s) (descorticação)" },
      { "value": 2, "label": "Extensão do(s) membro(s) (descerebração)" },
      { "value": 1, "label": "Não mexe os membros" }]
  },
  {
    "title": "Existe alteração em relação ao estado prévio?", "body": [
      { "value": "SIM", "label": null },
      { "value": "NÃO", "label": null }
    ]
  }];

  private glasgowScaleChild = [{
    "title": "Abertura dos olhos", "body": [
      { "value": 4, "label": "Espontânea" },
      { "value": 3, "label": "Ao comando verbal" },
      { "value": 2, "label": "Somente a estímulos dolorosos" },
      { "value": 1, "label": "Sem resposta" }]
  },
  {
    "title": "Melhor resposta verbal", "body": [
      { "value": 5, "label": "Orientada, adequada" },
      { "value": 4, "label": "Confusa" },
      { "value": 3, "label": "Palavras inadequadas" },
      { "value": 2, "label": "Sons ininteligíveis" },
      { "value": 1, "label": "Sem resposta" }]
  },
  {
    "title": "Melhor resposta motora", "body": [
      { "value": 6, "label": "Obedece a ordens" },
      { "value": 5, "label": "Localiza estímulo doloroso" },
      { "value": 4, "label": "Reflexo de retirada em resposta à dor" },
      { "value": 3, "label": "Flexão em resposta à dor" },
      { "value": 2, "label": "Extensão em resposta à dor" },
      { "value": 1, "label": "Sem resposta" }]
  },
  {
    "title": "Existe alteração em relação ao estado prévio?", "body": [
      { "value": "SIM", "label": null },
      { "value": "NÃO", "label": null }
    ]
  }];

  private glasgowScaleBaby = [{
    "title": "Abertura dos olhos", "body": [
      { "value": 4, "label": "Espontânea" },
      { "value": 3, "label": "Ao comando verbal" },
      { "value": 2, "label": "Somente a estímulos dolorosos" },
      { "value": 1, "label": "Sem resposta" }]
  },
  {
    "title": "Melhor resposta verbal", "body": [
      { "value": 5, "label": "Resmunga e balbucia" },
      { "value": 4, "label": "Choro irritado" },
      { "value": 3, "label": "Chora em resposta à dor" },
      { "value": 2, "label": "Geme em resposta à dor" },
      { "value": 1, "label": "Sem resposta" }]
  },
  {
    "title": "Melhor resposta motora", "body": [
      { "value": 6, "label": "Mexe-se espontanêa e deliberadamente" },
      { "value": 5, "label": "Reflexo de retirada em resposta ao toque" },
      { "value": 4, "label": "Reflexo de retirada em resposta à dor" },
      { "value": 3, "label": "Postura de Flexão anormal em resposta à dor" },
      { "value": 2, "label": "Postura de Extensão anormal em resposta à dor" },
      { "value": 1, "label": "Sem resposta" }]
  },
  {
    "title": "Existe alteração em relação ao estado prévio?", "body": [
      { "value": "SIM", "label": null },
      { "value": "NÃO", "label": null }
    ]
  }];

  ngOnInit(): void {
    if (this.data.age <= 20000) {
      this.glasgowScaleList = [...this.glasgowScaleBaby];
    } else if (this.data.age >= 20000 && this.data.age <= 140000) {
      this.glasgowScaleList = [...this.glasgowScaleChild];
    } else {
      this.glasgowScaleList = [...this.glasgowScaleAdult];
    }
    this.glasgowScale = this.glasgowScaleList[this.currentPage];
  }

  close() {
    this.matDialogRef.close();
  }

  clickSelect(value: any) {
    if (Number.isInteger(value)) {
      if (this.currentPage == 0) this.glasgowEye = value;
      if (this.currentPage == 1) this.glasgowVerbal = value;
      if (this.currentPage == 2) this.glasgowMotor = value;

      this.result += value;
      this.currentPage += 1;
      if (this.result == 15 || (this.data.noShowLastPage && this.currentPage == 3)) {
        this.matDialogRef.close({
          value: this.result,
          select: false,
          glasgowEye: this.glasgowEye,
          glasgowVerbal: this.glasgowVerbal,
          glasgowMotor: this.glasgowMotor
        });
      } else {
        this.glasgowScale = this.glasgowScaleList[this.currentPage];
      }
    } else {
      this.matDialogRef.close({
        value: this.result,
        select: value == "SIM",
        glasgowEye: this.glasgowEye,
        glasgowVerbal: this.glasgowVerbal,
        glasgowMotor: this.glasgowMotor
      });
    }
  }
}

