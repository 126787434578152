import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StorageKey, TwilioUtilService } from '../../../twilio-util.service';


@Component({
  selector: 'app-device-select',
  templateUrl: './device-select.component.html',
  styleUrls: ['./device-select.component.css']
})
export class DeviceSelectComponent implements OnInit {
  private localDevices: MediaDeviceInfo[] = [];
  id: string;
  selectedId: string;

  get devices(): MediaDeviceInfo[] {
    return this.localDevices;
  }

  @Input() label: string;
  @Input() kind: MediaDeviceKind;
  @Input() key: StorageKey;
  @Input() set devices(devices: MediaDeviceInfo[]) {
      this.selectedId = this.getOrAdd(this.key, this.localDevices = devices);
  }

  @Output() settingsChanged = new EventEmitter<MediaDeviceInfo>();

  constructor(
    private twilioUtilService: TwilioUtilService,
  ) {

    this.id = `device-select-${IdGenerator.getNext()}`;
  }

  ngOnInit(): void {
    
  }

  onSettingsChanged(deviceId: string) {
    this.setAndEmitSelections(this.key, this.selectedId = deviceId);
  }

  private getOrAdd(key: StorageKey, devices: MediaDeviceInfo[]) {
    
    const existingId = this.twilioUtilService.get(key);

    if (devices && devices.length > 0) {
      const defaultDevice = devices.find(d => d.deviceId === existingId) || devices[0];
      this.twilioUtilService.set(key, defaultDevice.deviceId);
      return defaultDevice.deviceId;
    }

    return null;
  }

  private setAndEmitSelections(key: StorageKey, deviceId: string) {
    this.twilioUtilService.set(key, deviceId);
    this.settingsChanged.emit(this.devices.find(d => d.deviceId === deviceId));
  }

}

class IdGenerator {
  protected static id: number = 0;
  static getNext() {
      return ++ IdGenerator.id;
  }
}