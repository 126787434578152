import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DiscriminatorTypeEnum } from "src/app/shared/enum/discriminator-type.enum";
import { AlertService, AlertType } from "src/app/shared/services/alert.service";
import { PatientDeviceService } from "src/app/shared/services/API/sync-measurer/patient-device.service";
import { DiscriminatorStruct } from "src/app/shared/services/structs/risk-classification/discriminator.struct";
import { WebsocketMeasurerService } from "src/app/shared/services/websocket-measurer.service";
import { WarnOfflineModalComponent } from "./warn-offline-modal/warn-offline-modal.component";


@Injectable({
    providedIn: 'root'
})
export class UtilsMeasurerRemoteService {
    constructor(private websocketService: WebsocketMeasurerService,
        private patientDeviceService: PatientDeviceService,
        private alertService: AlertService,
        public dialog: MatDialog,) { }

    private list: DiscriminatorStruct[];
    private discriminators: any[] = [];
    private discriminatorsPersist: any[][];
    public intervalOnlineId: any;
    public modalIsOpen: boolean = false;
    //websocket
    private socketDisconneted: boolean = false;
    private onlineOffline: boolean = navigator.onLine;
    private socket: any;

    isOnline(idSyncMeasurer: number) {
        this.intervalOnlineId = setInterval(() => { this.getDeviceIsOnline(idSyncMeasurer) }, 5000);
    }

    clearAllInterval() {
        clearInterval(this.intervalOnlineId);
        if (this.socket) {
            this.socket.disconnect();
        }
        clearInterval(this.idInterval);
    }

    getDeviceIsOnline(idSyncMeasurer: number) {
        if (!this.modalIsOpen) {
            this.patientDeviceService.isOnline(idSyncMeasurer).subscribe((response) => {
                if (response.isError) {
                    this.alertService.show('Erro', response.errorDescription, AlertType.error);
                    return;
                }
                if (!response.isDeviceOnline && !this.modalIsOpen) {
                    this.modalIsOpen = true;
                    let dialogRef = this.dialog.open(WarnOfflineModalComponent);
                    dialogRef.afterClosed().subscribe((response) => {
                        this.modalIsOpen = false;
                        if (response) {
                        } else {
                            this.getDeviceIsOnline(idSyncMeasurer);
                        }
                    });
                }
            });
        }
    }

    createReference(discriminators, discriminatorsPersist, list) {
        this.list = list;
        this.discriminators = discriminators;
        this.discriminatorsPersist = discriminatorsPersist;
    }

    updateMeasurePressure(measurer: string): void {
        this.discriminators[DiscriminatorTypeEnum.PressaoArterial] = measurer;
        if (Array.isArray(this.discriminatorsPersist[DiscriminatorTypeEnum.PressaoArterial])) {
            this.discriminatorsPersist[DiscriminatorTypeEnum.PressaoArterial].forEach((c: number) => {
                this.list[c].value = measurer;
                this.list[c].hasValue = true;
            });
        }
    }

    updateMeasureOximeter(measurer: string): void {
        this.discriminators[DiscriminatorTypeEnum.Saturacao] = measurer.split("/")[0];
        this.discriminators[DiscriminatorTypeEnum.FrequenciaCardiaca] = measurer.split("/")[1];

        if (Array.isArray(this.discriminatorsPersist[DiscriminatorTypeEnum.Saturacao])) {
            this.discriminatorsPersist[DiscriminatorTypeEnum.Saturacao].forEach((c: number) => {
                this.list[c].value = measurer.split("/")[0];;
            });
        }
        if (Array.isArray(this.discriminatorsPersist[DiscriminatorTypeEnum.FrequenciaCardiaca])) {
            this.discriminatorsPersist[DiscriminatorTypeEnum.FrequenciaCardiaca].forEach((c: number) => {
                this.list[c].value = measurer.split("/")[1];
            });
        }
    }

    updateMeasureGlucometer(measurer: string): void {
        if (Array.isArray(this.discriminatorsPersist[DiscriminatorTypeEnum.Glicemia])) {
            this.discriminatorsPersist[DiscriminatorTypeEnum.Glicemia].forEach((c: number) => {
                this.list[c].value = 0;
            });
            this.discriminatorsPersist[DiscriminatorTypeEnum.Glicemia].forEach((c: number) => {
                this.list[c].value = measurer;
                this.list[c].hasValue = true;
            });
        }
        if (Array.isArray(this.discriminatorsPersist[DiscriminatorTypeEnum.GlicemiaCapilar])) {
            this.discriminatorsPersist[DiscriminatorTypeEnum.GlicemiaCapilar].forEach((c: number) => {
                this.list[c].value = "";
                this.list[c].value = measurer;
                if (this.list[c].maxValueAllowed < this.list[c].value) {
                    this.list[c].hasValue = false;
                } else {
                    this.list[c].hasValue = true;
                }
            });
        }
        this.discriminators[DiscriminatorTypeEnum.Glicemia] = measurer;
    }

    updateMeasureThermometer(measurer: string): void {
        if (measurer) {
            this.discriminators[DiscriminatorTypeEnum.Temperatura] = measurer;
            if (Array.isArray(this.discriminatorsPersist[DiscriminatorTypeEnum.Temperatura])) {
                this.discriminatorsPersist[DiscriminatorTypeEnum.Temperatura].forEach((c: number) => {
                    this.list[c].value = measurer;
                    this.list[c].hasValue = true;
                });
            }
        }
    }

    startWebsocket(hash: string) {
        if (this.socket) {
            this.socket.disconnect();
        }
        this.socket = this.websocketService.websocketConnection();
        this.socket.emit('join', `syncMeasurerhash-${hash}`)
            .on('measurer', (res) => { this.updateMeasurerValues(res) })
            .io.on("reconnect", (res) => {
                this.socket.emit('join', `syncMeasurerhash-${hash}`);
            });
    }

    private idInterval = setInterval(() => {
        if (this.socket) {
            if (this.socket.connected) {
                this.socketDisconneted = false;
            } else {
                this.socketDisconneted = true;
            }
        } else {
            this.socketDisconneted = false;
        }
        if (this.onlineOffline !== navigator.onLine) {
            this.onlineOffline = navigator.onLine;
        }
    }, 1000);

    updateMeasurerValues(values: any) {
        this.socketDisconneted = false;
        if (values.bloodPressureDiastole && values.bloodPressureSystole) {
            this.updateMeasurePressure(values.bloodPressureSystole + "/" + values.bloodPressureDiastole);
        }
        if (values.glucose) {
            this.updateMeasureGlucometer(values.glucose);
        }
        if (values.temperature) {
            this.updateMeasureThermometer(values.temperature);
        }
        if (values.saturation && values.heartRate) {
            this.updateMeasureOximeter(values.saturation + "/" + values.heartRate);
        }
    }
}