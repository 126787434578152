import { Component, Input, OnInit } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { Router } from '@angular/router';
import { FunctionalityStruct } from '../../services/structs/user/funcionality.struct';
import { ListModuleGroupStruct } from '../../services/structs/user/list-module-group.struct';
import { ModuleStruct } from '../../services/structs/user/module.struct';

@Component({
  selector: 'app-module-functionality',
  templateUrl: './module-functionality.component.html',
  styleUrls: ['./module-functionality.component.css']
})
export class ModuleFunctionalityComponent implements OnInit {
  constructor(
    private util: UtilService,
    private router: Router,
  ) { }

  @Input() menuModuleEnum: number;

  public isLoading: boolean = false;
  public listModuleGroupStruct: ListModuleGroupStruct[];
  public listModuleStruct: ModuleStruct[];
  public moduleStruct: ModuleStruct;

  public groupedFunctionalityStructs: Array<FunctionalityStruct[]> = [];
  public groupIndices: Object = new Object();
  public standaloneFunctionalityStructs: FunctionalityStruct[] = [];

  ngOnInit(): void {
    this.listModuleStruct = this.util.getModules();
    this.moduleStruct = this.listModuleStruct.find(x => x.idModule == this.menuModuleEnum);

    this.moduleStruct.listFunctionalityGroup.forEach((group, index) => {
      this.groupIndices[group.idFunctionalityGroup] = index;
      this.groupedFunctionalityStructs.push([]);
    });

    this.moduleStruct.listFunctionality.forEach((func, index) => {
      if (func.idFunctionalityGroup != null) {
        let groupIndex = this.groupIndices[func.idFunctionalityGroup];
        this.groupedFunctionalityStructs[groupIndex].push(func);
      }
      else {
        this.standaloneFunctionalityStructs.push(func);
      }
    });
  }

  expand(id, index: number) {
    const functionalityGroupsLive = document.getElementsByClassName('show');

    if (functionalityGroupsLive.length > 0) {
      for (let i = 0; i < functionalityGroupsLive.length; i++) {
        if (functionalityGroupsLive[i].id != id) {
          functionalityGroupsLive[i].classList.remove('show');
        }
      }
    }

    const functionalityGroup = document.getElementById(id);
    functionalityGroup.classList.toggle('show');

    const functionalityGroups = document.getElementsByClassName('card-item-group');

    if (functionalityGroups.length > 0) {
      for (let i = 0; i < functionalityGroups.length; i++) {
        if (i != index) {
          functionalityGroups[i].classList.remove('selected');
        }
      }
    }

    functionalityGroups[index].classList.toggle('selected');
  }

  redirect(functionality: FunctionalityStruct) {
    this.router.navigate([functionality.uri]);
  }

}
