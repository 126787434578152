import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { SpSadtGuideResponse } from '../../responses/private-billing/sp-sadt-guide.response';
import { MainGuidesResponse } from '../../responses/private-billing/main-guides.response';
import { SpSadtGuideRequest } from '../../requests/private-billing/spsadt-guide-request';
import { GuideResponse } from '../../responses/private-billing/guide.response';

@Injectable({
  providedIn: 'root'
})

export class SpSadtService extends CommonService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  public getById(idGuide: number): Observable<SpSadtGuideResponse> {
    let uri = `SpSadt/idGuide/${idGuide}`

    return this.httpClient.get<SpSadtGuideResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public getMainGuides(idEpisode: number, idHealthcareAgreement: number): Observable<MainGuidesResponse> {
    let uri = `SpSadt/getMainGuides/idEpisode/${idEpisode}/idHealthcareAgreement/${idHealthcareAgreement}`

    return this.httpClient.get<MainGuidesResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public delete(idSpSadtGuide: number): Observable<ReturnStruct> {
    let uri = `idSpSadtGuide/${idSpSadtGuide}`

    return this.httpClient.get<ReturnStruct>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public validatePost(body: SpSadtGuideRequest): Observable<ReturnStruct> {
    let uri = `spSadt`

    return this.httpClient.post<ReturnStruct>(environment.urlApiPrivateBilling + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      );
  }

  public GetSpSadtGuideByIdEpisode(idEpisode: number): Observable<GuideResponse> {
    let uri = `SpSadt/idEpisode/${idEpisode}`
    return this.httpClient.get<GuideResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}