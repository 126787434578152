import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit {
  constructor() { }

  @Input() columns: string[] = [];
  @Input() list: any[] = [];
  @Output() actionClicked = new EventEmitter<any>();
  @Output() actionEditClicked = new EventEmitter<any>();

  ngOnInit(): void {
  }

  onViewClick(item: any) {
    this.actionClicked.emit(item); 
  }

  onEditClick(item: any) {
    this.actionEditClicked.emit(item); 
  }
}