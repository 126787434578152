import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { CompleteJourneyResponse } from '../../responses/orchestrator-queue/complete-journey.response';

@Injectable({
  providedIn: 'root'
})
export class CompleteJourneyService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getCompleteJourney(idEpisode: number): Observable<CompleteJourneyResponse> {
    let uri = `CompleteJourney/idEpisode/${idEpisode}`
    return this.httpClient.get<CompleteJourneyResponse>(environment.urlApiOrchestratorQueue + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
