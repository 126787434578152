export enum AdministrationTypeEnum{
    outros = 5,
    retal = 6,
    ocular = 9,
    sublingual = 10,
    oral = 1,
    subcutânea = 3,
    nasal = 4,
    topico = 7,
    inalatorio = 8,
    endovenoso = 2,
    intramuscular = 11,
    nenhuma = -1,
    naoDefinida = 12, 
}

export enum FrequencyEnum{
    outros = 10,
    medicCriteria = 11,
    ifNecessary = 12,
    now = 13,
}

export enum TreatmentPeriodEnum{
    outros = 9,
    UmDia = 11,
}

export enum PresentationEnum{
    outros = 7
}

export enum UsageEnum{
    interno = 1,
	externo = 2
}

export enum DilutionEnum{
    outros = 6,
    semDiluicao = -1,
}
