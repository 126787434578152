import { ListPatientStruct } from "../../structs/orchestrator-queue/list-patient.struct";

export class AttendPatientRequest {
    public login: string;

    public password: string;

    public idModule: number;

    public patientQueue: ListPatientStruct;

    public idRoomSector: number;

    public idRoomService: number;
 
    public isUpdateFrontdesk: boolean;

    public remoteAttendance: boolean;

    public idRoom: number;
}