import { DigitalSignatureStruct } from "../digital-signature-struct.struct";

export class SafeIDVidaaSSignatureStruct extends DigitalSignatureStruct {
    public client_id: string;
    public client_secret: string;
    public identifierCA: string;
    public serialNumber: string;
    public access_token: string;
    public authorizationToken: string;
    public code: string;
    public codeChallenge: string;
}