import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompleteJourneyService } from 'src/app/shared/services/API/orchestrator-queue/complete-journey.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { PatientHistoryResponse } from 'src/app/shared/services/responses/orchestrator-patient/patient-history.response';

@Component({
  selector: 'app-complete-patient-journey-modal',
  templateUrl: './complete-patient-journey-modal.component.html',
  styleUrls: ['./complete-patient-journey-modal.component.css']
})
export class CompletePatientJourneyModalComponent implements OnInit {
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<CompletePatientJourneyModalComponent>,
    private alertService: AlertService,
    public completeJourneyService: CompleteJourneyService,
  ) { }

  public isLoading: boolean;
  public patientHistory: PatientHistoryResponse;

  ngOnInit(): void {
    this.isLoading = false;
    this.patientHistory = new PatientHistoryResponse();
    this.searchCompleteJourney();
  }

  searchCompleteJourney() {
    this.isLoading = true;
    this.completeJourneyService.getCompleteJourney(this.data.idEpisode).subscribe({
      next: (response) => {
        
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        
        this.patientHistory = response.patientHistoryResponse;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  close(){
    this.matDialogRef.close(); 
  }

}
