<div class="body-tiss-guide">
    <div class="title-header">
        <h1>Gerenciamento de Guias TISS</h1>
        <div class="col-12 col-sm-4">
            <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
            <mat-form-field appearance="outline" *ngIf="!this.isFirstLoading">
                <mat-label>Nova guia TISS</mat-label>
                <mat-select [(ngModel)]="tissGuide" (selectionChange)="choiceGuide()">
                    <mat-option *ngFor="let item of listTissGuide" [value]="item.idGuideType"
                        [disabled]="isListPopulated(item.idGuideType)">
                        {{item.guideName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row" *ngIf="!isLoading">
        <h2>Guias Geradas</h2>
        <div class="guide-container">
            <div *ngFor="let admissionGuide of listAdmissionGuideStruct" class="border-box">
                <a (click)="selectGuide(admissionGuide, this.admissionGuideEnum)">
                    <div class="spacing-data">
                        <span><strong>Guia nº {{ admissionGuide.guide.guideNumber }} - </strong>
                            {{ admissionGuide?.admissionGuide?.requesterName ?? "Sem contratado executante definido" }}
                        </span>
                        <span>Internação</span>
                    </div>
                    <div [style.color]="getGuideColor(admissionGuide.guide.idGuideStatus)">
                        <span>{{ getGuideName(admissionGuide.guide.idGuideStatus) }}</span>
                        <span *ngIf="!isAttendance || (admissionGuide.guide.idGuide && !admissionGuide.hasAlteration && isAttendance)"
                            class="secundary tag-saved" matTooltip="Esta guia foi salva corretamente">Guia Salva</span>
                        <span *ngIf="isAttendance && (!admissionGuide.guide.idGuide || (admissionGuide.guide.idGuide && admissionGuide.hasAlteration))"
                            class="secundary tag-not-saved" matTooltip="Esta guia ainda não está salva porque o atendimento não foi finalizado">Guia Pendente</span>
                    </div>
                </a>
                <div class="actions">
                    <a *ngIf="!isAttendance || (admissionGuide.guide.idGuide && !admissionGuide.hasAlteration && isAttendance)"><mat-icon aria-hidden="false" aria-label="Editar" (click)="print(admissionGuide)">print</mat-icon></a>
                </div>
            </div>
            <div *ngFor="let appointmentGuide of listAppointmentGuideStruct" class="border-box">
                <a (click)="selectGuide(appointmentGuide, this.appointmentGuideEnum)">
                    <div class="spacing-data">
                        <span>
                            <strong>Guia nº {{ appointmentGuide.guide ? appointmentGuide.guide.guideNumber :
                                appointmentGuide.guideNumber }} - </strong>
                            {{ appointmentGuide?.appointmentGuide?.contractorName ?? "Sem contratado executante definido" }}
                        </span>
                        <span>Consulta</span>
                    </div>
                    <div
                        [style.color]="getGuideColor(appointmentGuide.guide ? appointmentGuide.guide.idGuideStatus : appointmentGuide.idGuideStatus)">
                        <span>{{ getGuideName(appointmentGuide.guide ? appointmentGuide.guide.idGuideStatus :
                            appointmentGuide.idGuideStatus) }}</span>
                        <span *ngIf="!isAttendance || (appointmentGuide.guide.idGuide && !appointmentGuide.hasAlteration && isAttendance)"
                            class="secundary tag-saved" matTooltip="Esta guia foi salva corretamente">Guia Salva</span>
                        <span *ngIf="isAttendance && (!appointmentGuide.guide.idGuide || (appointmentGuide.guide.idGuide && appointmentGuide.hasAlteration))"
                            class="secundary tag-not-saved" matTooltip="Esta guia ainda não está salva porque o atendimento não foi finalizado">Guia Pendente</span>
                    </div>
                </a>
                <div class="actions">
                    <a *ngIf="!isAttendance || (appointmentGuide.guide.idGuide && !appointmentGuide.hasAlteration && isAttendance)"><mat-icon aria-hidden="false" aria-label="Editar" (click)="print(appointmentGuide)">print</mat-icon></a>
                </div>
            </div>
            <div *ngFor="let spSadtGuide of listSpSadtGuideStruct" class="border-box">
                <a (click)="selectGuide(spSadtGuide, this.spSadtGuideEnum)">
                    <div class="spacing-data">
                        <span>
                            <strong>Guia nº {{ spSadtGuide.guide.guideNumber }} - </strong>
                            {{ spSadtGuide?.spSadtGuide?.executantContractorName ?? "Sem contratado executante definido" }}
                        </span>
                        <span>SP/SADT</span>
                    </div>
                    <div [style.color]="getGuideColor(spSadtGuide.guide.idGuideStatus)">
                        <span>{{ getGuideName(spSadtGuide.guide.idGuideStatus) }}</span>
                        <span *ngIf="!isAttendance || (spSadtGuide.guide.idGuide && !spSadtGuide.hasAlteration && isAttendance)"
                            class="secundary tag-saved" matTooltip="Esta guia foi salva corretamente">Guia Salva</span>
                        <span *ngIf="isAttendance && (!spSadtGuide.guide.idGuide || (spSadtGuide.guide.idGuide && spSadtGuide.hasAlteration))"
                            class="secundary tag-not-saved" matTooltip="Esta guia ainda não está salva porque o atendimento não foi finalizado">Guia Pendente</span>
                    </div>
                </a>
                <div class="actions">
                    <a *ngIf="!isAttendance || (spSadtGuide.guide.idGuide && !spSadtGuide.hasAlteration && isAttendance)"><mat-icon aria-hidden="false" aria-label="Editar" (click)="print(spSadtGuide)">print</mat-icon></a>
                </div>
            </div>
            <div *ngFor="let feesGuide of listFeesGuideStruct" class="border-box">
                <a (click)="selectGuide(feesGuide, this.feesGuideEnum)">
                    <div class="spacing-data">
                        <span>
                            <strong>Guia nº {{ feesGuide.guide.guideNumber }} - </strong>
                            {{ feesGuide?.feesGuide?.executantName ?? "Sem contratado executante definido" }}
                        </span>
                        <span>Honorários</span>
                    </div>
                    <div [style.color]="getGuideColor(feesGuide.guide.idGuideStatus)">
                        <span>{{ getGuideName(feesGuide.guide.idGuideStatus) }}</span>
                        <span *ngIf="!isAttendance || (feesGuide.guide.idGuide && !feesGuide.hasAlteration && isAttendance)"
                            class="secundary tag-saved" matTooltip="Esta guia foi salva corretamente">Guia Salva</span>
                        <span *ngIf="isAttendance && (!feesGuide.guide.idGuide || (feesGuide.guide.idGuide && feesGuide.hasAlteration))"
                            class="secundary tag-not-saved" matTooltip="Esta guia ainda não está salva porque o atendimento não foi finalizado">Guia Pendente</span>
                    </div>
                </a>
                <div class="actions">
                    <a *ngIf="!isAttendance || (feesGuide.guide.idGuide && !feesGuide.hasAlteration && isAttendance)"><mat-icon aria-hidden="false" aria-label="Editar" (click)="print(feesGuide)">print</mat-icon></a>
                </div>
            </div>
            <app-empty-list *ngIf="!isLoading && listAdmissionGuideStruct != undefined  && listAdmissionGuideStruct.length == 0 && 
                listFeesGuideStruct != undefined && listFeesGuideStruct.length == 0 && 
                listSpSadtGuideStruct != undefined && listSpSadtGuideStruct.length == 0 && 
                listAppointmentGuideStruct != undefined && listAppointmentGuideStruct.length == 0">
            </app-empty-list>
        </div>
    </div>
    <app-loading-list *ngIf="isLoading"></app-loading-list>
</div>