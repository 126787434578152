<div class="container-out" (click)="close()" *ngIf="isVisible"></div>

<div class="container-notification-info slide-bottom" *ngIf="isVisible">
    <div class="title-notification">
        <h2>Notificações</h2>
        <span class="read-all" *ngIf="listMessage != null && listMessage.length > 0" (click)="readAll()"> Marcar todas como lidas
            <mat-icon class="icon-transform" aria-hidden="false" aria-label="Menu apps" (click)="readAll()">
                mark_chat_unread
            </mat-icon>
        </span>
    </div>
    <div *ngIf="listMessage == null || listMessage.length == 0" class="text-center">
        <img class="no-notification-image" src="/assets/images/notification-image.webp">
        <p>Você ainda não possui novas notificações.</p>
    </div>
    <app-loading-progress-bar *ngIf="isLoading"></app-loading-progress-bar>
    <div class="action">
        <ul *ngIf="listMessage != null && listMessage.length > 0">
            <div class="list-container">
                <ngContainer *ngFor="let item of listMessage; let i = index;">
                    <li>
                        <a-unread
                            *ngIf="(!isModalView && !item.isRead) || (isModalView && item.idMessage != idSelectedMessage && !item.isRead)">
                            <span><b>{{item.messageTitle}}</b></span>
                            <div class="container-message" contenteditable="false" [innerHTML]="item.messageContent"
                                (click)="openViewModal(item, i)">
                            </div>
                            <mat-icon class="chevron" matTooltip="Deletar" aria-hidden="false" aria-label="Menu apps"
                                (click)="singleDelete(item)">delete_sweep</mat-icon>

                            <date>{{item.datetimeInclusion | date:'dd/MM/yy, H:mm'}}</date>
                        </a-unread>
                        <a-selected *ngIf="isModalView && item.idMessage == idSelectedMessage">
                            <span><b>{{item.messageTitle}}</b></span>
                            <div class="container-message" contenteditable="false" [innerHTML]="item.messageContent"
                                (click)="openViewModal(item, i)">
                            </div>
                            <mat-icon class="chevron" matTooltip="Deletar" aria-hidden="false" aria-label="Menu apps"
                                (click)="singleDelete(item)">delete_sweep</mat-icon>
                            <date>{{item.datetimeInclusion | date:'dd/MM/yy, H:mm'}}</date>
                        </a-selected>
                        <a-read
                            *ngIf="(!isModalView && item.isRead) || (isModalView && item.idMessage != idSelectedMessage && item.isRead)">
                            <span><b>{{item.messageTitle}}</b></span>
                            <div class="container-message" contenteditable="false" [innerHTML]="item.messageContent"
                                (click)="openViewModal(item, i)">
                            </div>
                            <mat-icon matTooltip="Deletar" class="chevron" aria-hidden="false" aria-label="Menu apps"
                                (click)="singleDelete(item)">delete_sweep</mat-icon>
                            <date>{{item.datetimeInclusion | date:'dd/MM/yy, H:mm'}}</date>
                        </a-read>
                        <div class="title-header"></div>
                    </li>
                </ngContainer>
            </div>
        </ul>
    </div>
    <div>
        <span class="delete-all" *ngIf="listMessage != null && listMessage.length > 0" (click)="deleteAll()">Limpar
            notificações</span>
    </div>
</div>