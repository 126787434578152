export class AdjustmentCoefficientMappings {
    public AccessMapping = {
        'honoraryBilateral': 1,
        'honorarySameGuide': 2,
        'honoraryDifferentGuide': 3,
    }

    public ParticipationDegreeMapping = {
        'participationSurgeon': 0,
        'participationFirstAux': 1,
        'participationSecondAux': 2,
        'participationThirdAux': 3,
        'participationForthAux': 4,
        'participationScrubNurse': 5,
        'participationAnesthetist': 6,
        'participationAnesthetistAuxiliar': 7,
        'participationConsultant': 8,
        'participationPerfusionist': 9,
        'participationBirthRoomPediatrician': 10,
        'participationSadtAuxiliar': 11,
        'participationClinic': 12,
        'participationIntensivist': 13,
    }

    public FilmSurfaceMapping = {
        'm2FilmBilateral': null,
        'm2FilmSameGuide': null,
        'm2FilmDifferentGuide': null,
    }

    public Others = {
        'otherUrgency': null,
        'otherDhe': null,
        'otherApartment': null,
    }

}