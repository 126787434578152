import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TotemService } from 'src/app/shared/services/API/totem/totem.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-change-pin-confirm-modal',
  templateUrl: './change-pin-confirm-modal.component.html',
  styleUrls: ['./change-pin-confirm-modal.component.css']
})
export class ChangePinConfirmModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ChangePinConfirmModalComponent>,
    private totemService: TotemService,
    private alertService: AlertService,
  ) { }

  public isLoading: boolean;

  ngOnInit(): void {
    this.isLoading = false;
  }

  close() {
    this.matDialogRef.close({ changePin: false });
  }

  clickConfirm() {
    this.matDialogRef.close({ changePin: true });
  }

  clickCancel() {
    this.matDialogRef.close({ changePin: false });
  }
}