export enum FeesFieldsMaxLengthEnum {
    ansCode = 6, //1
    guideNumber = 20, //2
    admissionGuideNumber = 20, //3
    ticket = 20, //4
    providerGuideNumber = 20, //5
    patientDocumentNumber = 20, //6
    patientName = 70, //7
    newbornAttendance = 1, //8
    patientSocialName = 70, //41
    executionSiteProviderCode = 14, //9
    executionSiteName = 70, //10
    executionSiteCnesCode = 7, //11
    executantProviderCode = 14, //12
    executantName = 70, //13
    executantCnesCode = 7, //14
    billingStartDatetime = 8, //15
    billingEndDatetime = 8, //16
    observations = 500, //37
    feesTotalValue = 8, //38-1
    feesTotalValueDeciamals = 2, //38-2    

    //Procedimentos
    procedureReferenceTable = 2, //20
    procedureCount = 3, //23
    procedureCode = 10, //
    procedureDescription = 150, //                
    // procedureUnitValue, //    

    requesterCodeOperatorCnpj = 14,
    requesterCodeOperatorCpf = 11,
    requesterCodeOperator = 14,
}