import { CounselingStruct } from "../risk-classification/counseling.struct";

export class TriageStruct {
    public idLastFlowchart: number;
    public lastFlowchart: string;
    public idTriage: number;
    public idPatient: number;
    public idEpisode: number;
    public idGravity: number;
    public temperature: number;
    public respiratoryFrequency: number;
    public isolation: boolean;
    public heartRate: number;
    public heartRateRegular: boolean;
    public glucose: number;
    public bloodPressureDiastole: number;
    public bloodPressureSystole: number;
    public saturation: number;
    public gravityName: string;
    public idPain: number;
    public painColorCode: string;
    public idUserClassification: number;
    public gravityColor: string;
    public gravityColorCode: string;
    public complaint: string;
    public flowchart: string;
    public idFlowchart: number;
    public discriminator: string;
    public idDiscriminator: number;
    public score: number;
    public glasgow: number;
    public justification: string;
    public atmosphericAir: boolean;
    public dpoc: boolean;
    public capillaryFillingTime: boolean;
    public fallRisk: boolean;
    public idSuspicion: number;
    public diseaseHistory: string;
    public height: number;
    public weight: number;
    public dateTimeInclusion: Date;
    public startClassification: Date;
    public endClassification: Date;
    public suspicionName: string;
    public forwardName: string;
    public serviceTime: string;
    public userCouncilNumber: string;
    public userClassificationName: string;
    public idRoomAttendance: number;
    public roomAttendanceName: string;
    public triageReportName: string;
    public observation: string;
    public advice: string;
    public listCounseling: CounselingStruct[];
    public metadata: string;
    public idProtocol: number;
    public protocolName: string;
    public patientName: string;
    public patientSocialName: string;
    public patientCpf: string;
    public patientCns: string;
    public patientBirthDate: Date;
    public patientGender: string;
    public idPatientGender: number;
    public isHeartRateSleeping: boolean
    public capillaryFillingTimeValue: number;
    public isSuspicionEvaluated: boolean;
    public datetimeSuspicionEvaluation: Date;
    public suspicionEvaluationMotive: string;
    public suspicionUserName: string;
    public idUserSuspicionEvaluation: number;
    public idGrievanceNotification: number;
    public discriminatorSelectionMotive: string;

    //HealthGuideline data
    public idHealthGuideline: number;
    public healthGuidelineName: string;
    public idHealthGuidelineCategorization: number;
    public categorizationName: string;
    public datetimeStartSymptoms: Date;

    //dados complementares da triagem
    public temperatureComplementaryData: number;
    public heartRateComplementaryData: number;
    public bloodPressureDiastoleComplementaryData: number;
    public bloodPressureSystoleComplementaryData: number;
    public respiratoryFrequencyComplementaryData: number;
    public glucoseComplementaryData: number;
    public saturationComplementaryData: number;
}