import { AfterViewInit, Component, OnInit, Output, ViewChild, EventEmitter, OnDestroy, Input } from '@angular/core';
import { createLocalAudioTrack, LocalAudioTrack, LocalTrack, LocalVideoTrack, RemoteParticipant, Room } from 'twilio-video';
import { CameraComponent } from './pages/camera/camera.component';
import { ParticipantComponent } from './pages/participant/participant.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { TwilioUtilService } from './twilio-util.service';

@Component({
    selector: 'app-twilio-video',
    templateUrl: './twilio-video.component.html',
    styleUrls: ['./twilio-video.component.css']
})

export class TwilioVideoComponent implements OnInit, OnDestroy {
    @ViewChild('settings') settings: SettingsComponent;
    @ViewChild('participants') participants: ParticipantComponent;
    @Output() leaveRoom = new EventEmitter<void>();
    @Input() isPatient: boolean = false;
    constructor(
        private twilioUtilService: TwilioUtilService,
    ) { }

    public activeRoom: Room;
    public isMuted: boolean = false;
    public isVideoOff: boolean = false;
    public isFullScreen: boolean = false;
    
    public attendantUserName: string;

    ngOnInit() {
        this.isFullScreen = this.isPatient;
        if (this.isPatient) {
            this.attendantUserName = sessionStorage.getItem("attendantUserName");
        }
    }

    ngOnDestroy(): void {
        if (this.activeRoom) {
            this.activeRoom.localParticipant.audioTracks.forEach(track => {
                track.track.stop();
            });

            this.activeRoom.localParticipant.videoTracks.forEach(track => {
                track.track.stop();
            });
        }
        this.stopTracks();
    }

    async onLeaveRoom(_: boolean) {
        if (this.activeRoom) {
            this.activeRoom.disconnect();
            this.activeRoom = null;
            this.leaveRoom.emit();
        }
        const videoDevice = this.settings.hidePreviewCamera();

        this.isMuted = false;
        this.isVideoOff = false;
        this.stopTracks();
        this.participants.clear();
    }

    onMute() {
        if (this.activeRoom) {
            this.activeRoom.localParticipant.audioTracks.forEach(track => {
                if (track.track.isEnabled) {
                    track.track.disable();
                }
                else {
                    track.track.enable();
                }
            });

            this.isMuted = !this.isMuted
        }
    }

    onHideVideo() {
        if (this.activeRoom) {
            this.activeRoom.localParticipant.videoTracks.forEach(track => {
                if (track.track.isEnabled) {
                    track.track.disable();
                }
                else {
                    track.track.enable();
                }
            });

            this.isVideoOff = !this.isVideoOff
        }
    }

    async onRoomChanged(token: string) {
        if (token) {
            if (this.activeRoom) {
                this.activeRoom.disconnect();
            }
            await this.settings.hidePreviewCamera();
            const tracks = await Promise.all([
                await createLocalAudioTrack(),
                await this.settings.showPreviewCamera(),
            ]);

            this.activeRoom = await this.twilioUtilService.joinOrCreateRoom(token, tracks);

            this.participants.initialize(this.activeRoom.participants);
            this.registerRoomEvents();

            let room = this.activeRoom;

            return room;
        }
    }

    private registerRoomEvents() {
        this.activeRoom
            .on('disconnected',
                (room: Room) => room.localParticipant.tracks.forEach(async publication => {
                    this.detachLocalTrack(publication.track);
                    this.stopTracks();
                    this.leaveRoom.emit();
                }))
            .on('participantConnected',
                (participant: RemoteParticipant) => this.participants.add(participant))
            .on('participantDisconnected',
                (participant: RemoteParticipant) => this.participants.remove(participant))
            .on('dominantSpeakerChanged',
                (dominantSpeaker: RemoteParticipant) => this.participants.loudest(dominantSpeaker));
    }

    private detachLocalTrack(track: LocalTrack) {
        if (this.isDetachable(track)) {
            track.stop();
            track.detach().forEach(el => el.remove());
        }
    }

    private isDetachable(track: LocalTrack): track is LocalAudioTrack | LocalVideoTrack {
        return !!track
            && ((track as LocalAudioTrack).detach !== undefined
                || (track as LocalVideoTrack).detach !== undefined);
    }

    stopTracks() {
        navigator.mediaDevices.getUserMedia({
            video: true,
            audio: true
        }).then(stream => {
            stream.getTracks().forEach((track) => {
                track.stop();
            })
        });
    }

    toggleFullScreen(event: boolean) {
        this.isFullScreen = event;
    }

    closeFullScreen() {
        this.participants.toggleFullscreen();
    }
}
