import { Component, OnInit, Optional } from '@angular/core';
import { AlertService, AlertType } from '../../services/alert.service';
import { ReportModalComponent } from '../report-modal/report-modal.component';
import { ActiveMedicationStockReportRequest } from '../../services/requests/Report/active-medication-stock-report.request';
import { MedicineGroupTypeStruct } from '../../services/structs/pharmacy/medicine-group-type.struct';
import { MedicineSubgroupStruct } from '../../services/structs/pharmacy/medicine-subgroup.struct';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PharmacyService } from '../../services/API/orchestrator-patient/pharmacy.service';
import { MedicineGroupService } from '../../services/API/pharmacy/medicine-group.service';
import { MedicinePresentationTypeService } from '../../services/API/pharmacy/medicine-presentation-type.service';
import { MedicineSubgroupService } from '../../services/API/pharmacy/medicine-subgroup.service';
import { PharmacyMovementService } from '../../services/API/pharmacy/pharmacy-movement.service';
import { MedicinePresentationTypeModel } from '../../services/models/pharmacy/medicine-presentation-type.model';
import { ActiveMedicationStockStruct } from '../../services/structs/pharmacy/active-medication-stock.struct';
import { MenuModuleEnum } from '../menu/menu.module.enum';
import { SelectLookupService } from '../../services/API/medical-record/select-lookup.service';

@Component({
  selector: 'app-active-medication-stock',
  templateUrl: './active-medication-stock.component.html',
  styleUrls: ['./active-medication-stock.component.css']
})
export class ActiveMedicationStockComponent implements OnInit {

  menuModuleEnum: MenuModuleEnum = MenuModuleEnum.pharmacy;

  isModal: boolean = false;

  constructor(private alertService: AlertService,
    private medicineGroupService: MedicineGroupService,
    private medicineSubgroupService: MedicineSubgroupService,
    private medicinePresentationTypeService: MedicinePresentationTypeService,
    private selectLookupService: SelectLookupService,
    private pharmacyMovementService: PharmacyMovementService,
    private pharmacyService: PharmacyService,
    private dialog: MatDialog,
    @Optional() public dialogRef: MatDialogRef<ActiveMedicationStockComponent>
  ) {
    this.isModal = !!dialogRef;
  }

  pageSize: number = 50;
  pageIndex: number = 0;
  fullSize: number = null;
  asyncCallsCounter: number = 0;

  isLoading: boolean = true;
  isFirstLoading: boolean = true;
  isPrintTableLoading: boolean = false;

  searchText: string = null;

  listIdMedicineGroup: number[] = [];
  listIdMedicineSubGroup: number[] = [];
  listGroupStruct: MedicineGroupTypeStruct[];
  listSubgroupStruct: MedicineSubgroupStruct[];
  listActiveMedicationStockStruct: ActiveMedicationStockStruct[] = [];
  // listPresentationType: MedicinePresentationTypeModel[];
  listMeasurementList: { id: number, label: string }[] = [];
  columns: { header: string, field: string, isAction?: boolean, width?: string }[] = [
    { header: 'Medicamento', field: 'medicineDescription', width: '40%' },
    { header: 'Unidade', field: 'measurementUnitName', width: '20%' },
    { header: 'Apresentação', field: 'medicinePresentationTypeName', width: '20%' },
    { header: 'Quantidade em estoque', field: 'totalStock', width: '20%' },
  ];

  ngOnInit(): void {
    this.isModal = this.dialogRef ? true : false;

    let asyncCallsCounter: number = 0;

    const handleAsyncCallCompleted = () => {
      asyncCallsCounter++;
      if (asyncCallsCounter === 3) {
        this.isFirstLoading = false;
        this.search();
      }
    };

    this.populateGroup(handleAsyncCallCompleted);
    this.populateSubgroup(handleAsyncCallCompleted);
    this.populateMeasurementUnit(handleAsyncCallCompleted);
    // this.populatePresentationType(handleAsyncCallCompleted);
  }

  private timeoutKeySearch: any = null;
  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.pageIndex = 0;
        $this.search();
      }
    }, 1000);
  }

  changePage(pageIndex: number) {
    this.pageIndex = pageIndex;
    this.search();
  }

  search() {
    this.pharmacyMovementService.getAllMedicinePaginated(this.searchText, this.pageIndex, this.listIdMedicineSubGroup, this.listIdMedicineGroup).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (response) {
          if (response.listActiveMedicationStockStruct && response.listActiveMedicationStockStruct.length > 0) {
            this.listActiveMedicationStockStruct = response.listActiveMedicationStockStruct;

            this.listActiveMedicationStockStruct.forEach(x => {
              if (x.idMeasurementUnit)
                x.measurementUnitName = this.listMeasurementList.find(measure => measure.id == x.idMeasurementUnit)?.label;   
            });
          }
          else
            this.listActiveMedicationStockStruct = [];
          
          this.fullSize = response.fullSize;
        }

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateGroup(callback: () => void) {
    this.medicineGroupService.listAllMedicineGroup().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          return;
        }

        this.listGroupStruct = response.listMedicineGroupTypeStruct;

        callback();
      },
      error: (error) => {
        console.log(error);
        this.isFirstLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateSubgroup(callback: () => void) {
    this.medicineSubgroupService.listAllMedicineSubgroup().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          return;
        }

        this.listSubgroupStruct = response.listMedicineSubgroupStruct;

        callback();
      },
      error: (error) => {
        console.log(error);
        this.isFirstLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateMeasurementUnit(callback: () => void) {
    this.selectLookupService.listAllLookups().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          return;
        }

        if (response.list && response.list.length > 0)
          this.listMeasurementList = response.list;

        callback();
      },
      error: (error) => {
        console.log(error);
        this.isFirstLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getListNoPaginated() {
    this.isPrintTableLoading = true;

    this.pharmacyMovementService.getAllMedicineNoPaginated(this.searchText, this.listIdMedicineSubGroup, this.listIdMedicineGroup).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isPrintTableLoading = false;
          return;
        }

        if (response) {
          if (response.listActiveMedicationStockStruct && response.listActiveMedicationStockStruct.length > 0) {
            let listActiveMedicationStockStruct = response.listActiveMedicationStockStruct;

            listActiveMedicationStockStruct.forEach(x => {
              if (x.idMeasurementUnit)
                x.measurementUnitName = this.listMeasurementList.find(measure => measure.id == x.idMeasurementUnit)?.label;
            });

            let reportRequest = new ActiveMedicationStockReportRequest();
            reportRequest.listActiveMedicationStockStruct = listActiveMedicationStockStruct;
            this.printTable(reportRequest);
          }
        }
        else
          this.isPrintTableLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isPrintTableLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  printTable(reportRequest: ActiveMedicationStockReportRequest) {
    this.pharmacyService.getReport(reportRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isPrintTableLoading = false;
          return;
        }

        const reportDialog = this.dialog.open(ReportModalComponent, {
          data: {
            reportPdf64: response.reportPdf64,
          },
        });

        this.isPrintTableLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isPrintTableLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  closeDialog() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
}