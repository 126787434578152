import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { FowardRequest } from '../../requests/orchestrator-queue/foward.request';
import { SelectedLocationRequest } from '../../requests/orchestrator-queue/selected-location.request';
import { ForwardResponse } from '../../responses/orchestrator-queue/forward.response';

@Injectable({
  providedIn: 'root'
})
export class ObservationForwardService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  fowardPatient(body: SelectedLocationRequest): Observable<ReturnStruct>{
    let uri = `ObservationForward`
      
    return this.httpClient.put<ReturnStruct>(environment.urlApiOrchestratorQueue + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  listDestination(body: FowardRequest): Observable<ForwardResponse>{
    let uri = `ObservationForward`
      
    return this.httpClient.post<ForwardResponse>(environment.urlApiOrchestratorQueue + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
