<h2 mat-dialog-title>Funcionalidade Indisponível!</h2>
<div class="dialog-content" mat-dialog-content>
    Esta funcionalidade não esta disponível em sua conta.<br />
    Fale com o consultor e veja o que é necessário para ativá-la cliando <a href="https://tolife.com.br/contato/"
        target="_blank">aqui</a>.
</div>
<div mat-dialog-actions class="row">
    <div class="col-12 col-md-6">
        <a mat-flat-button type="button" href="https://tolife.com.br/contato/" color="primary" class=" btn-block"
            target="_blank">
            Saiba mais
        </a>
    </div>
    <div class="col-12 col-md-6">
        <button mat-flat-button color="accent" class="btn-block" (click)="close()">Voltar</button>
    </div>
</div>