<div class="container-generic" *ngIf="!isModal">
    <app-menu [menuModuleEnum]='menuModuleEnum'></app-menu>
    <div class="container-body">
        <ng-container *ngTemplateOutlet="medicamentosTemplate"></ng-container>
    </div>
</div>

<div mat-dialog-content *ngIf="isModal">
    <div class="mat-dialog-header">
        <div class="mat-dialog-content">
            <ng-container *ngTemplateOutlet="medicamentosTemplate"></ng-container>
        </div>
    </div>
</div>

<ng-template #medicamentosTemplate>
    <div style="display: flex; flex-direction: row; justify-content: space-between;">
        <h1 *ngIf="!isModal">Tabela de medicamentos ativos</h1>
        <h2 *ngIf="isModal"><strong>Tabela de medicamentos ativos</strong></h2>
        <div *ngIf="!isModal" class="col-md-2">
            <button mat-flat-button type="button" (click)="getListNoPaginated();" color="primary" class="btn-block">
                <span *ngIf="isPrintTableLoading == false">Imprimir tabela</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isPrintTableLoading == true"></mat-spinner>
            </button>
        </div>
    </div>
    <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
    <div class="white-body">
        <div class="filters">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-icon matPrefix class="my-icon">search</mat-icon>
                        <mat-label>Pesquisar</mat-label>
                        <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText"
                            (keyup)="onKeySearch($event)">
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Grupo de Medicamento</mat-label>
                        <mat-select (selectionChange)="search()" [multiple]="true" [(ngModel)]="listIdMedicineGroup">
                            <mat-option *ngFor="let item of listGroupStruct"
                                [value]="item.medicineGroup.idMedicineGroup">
                                {{item.medicineGroup.medicineGroupName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Subgrupo de Medicamento</mat-label>
                        <mat-select (selectionChange)="search()" [multiple]="true" [(ngModel)]="listIdMedicineSubGroup">
                            <mat-option *ngFor="let item of listSubgroupStruct"
                                [value]="item.medicineSubgroup.idMedicineSubgroup">
                                {{item.medicineSubgroup.medicineSubgroupName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="table-padding">
            <app-table [columns]="columns" [list]="listActiveMedicationStockStruct"></app-table>
        </div>
        <app-empty-list
            *ngIf="listActiveMedicationStockStruct && listActiveMedicationStockStruct.length == 0 && !isLoading"></app-empty-list>
        <app-loading-list *ngIf="isLoading"></app-loading-list>
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12">
                <mat-paginator [length]="fullSize" #paginator [pageIndex]="pageIndex" [pageSize]="pageSize"
                    aria-label="Select page" (page)="changePage($event.pageIndex)" [hidePageSize]="true">
                </mat-paginator>
            </div>
        </div>
        <div class="row">
            <div class="col-2">
                <a *ngIf="!isModal" routerLink="/pharmacy/movement" mat-flat-button color="accent" class="btn-block">
                    <span>Voltar</span>
                </a>
                <a *ngIf="isModal" (click)="closeDialog();" mat-flat-button color="accent" class="btn-block">
                    <span>Voltar</span>
                </a>
            </div>
        </div>
    </div>
</ng-template>