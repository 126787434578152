import { Component, ComponentFactoryResolver, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UserLoginResponse } from 'src/app/shared/services/responses/user/auth.response';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { NetworkTestModalComponent } from '../network-test-modal/network-test-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { RankViewStruct } from 'src/app/shared/services/structs/gamification/rank-view.struct';
import { AreaRankingService } from 'src/app/shared/services/API/gamification/area-ranking.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { WebsocketGamificationService } from 'src/app/shared/services/websocket-gamification.service';
import { Socket } from 'socket.io-client';

@Component({
  selector: 'app-rank-infos',
  templateUrl: './rank-infos.component.html',
  styleUrls: ['./rank-infos.component.css']
})
export class RankInfosComponent implements OnInit, OnChanges, OnDestroy {

  constructor(private authService: AuthService,
    private utilService: UtilService,
    private alertService: AlertService,
    private areaRankingService: AreaRankingService,
    private websocketGamificationService: WebsocketGamificationService,
  ) { }

  @Input() isVisible: boolean;
  @Input() idSector: number;
  @Input() idService: number;
  @Input() sectorName: string;
  @Input() serviceName: string;
  @Output() closeRankInfo = new EventEmitter<any>();
  @Output() getRankInfosValue = new EventEmitter<any>();

  public isLoading: boolean;
  public listRankViewStruct: RankViewStruct[];
  public showNames: boolean;
  public showRankingList: boolean;
  public userLoginResponse: UserLoginResponse;
  public userPoint: number;
  public userRanking: number;
  public userName: string;
  public userUpDownRanking: number;
  public idUserClassification: number;
  public isButtonVisible: boolean = false;
  public showSectorRankingTitle: boolean = false;

  private socket: Socket;

  ngOnInit(): void {
    this.isLoading = true;
    this.userLoginResponse = this.authService.getTokenMenu();

  }
  ngOnChanges(changes: SimpleChanges): void {
    let idServiceImpl;
    let idSectorImpl;
    if (changes.idSector) {
      if (!changes.idSector.previousValue && changes.idSector.currentValue || changes.idSector.currentValue !== changes.idSector.previousValue) {
        idSectorImpl = changes.idSector.currentValue;
      }
    }
    if (changes.idService) {
      if (!changes.idService.previousValue && changes.idService.currentValue || changes.idService.currentValue !== changes.idService.previousValue) {
        idServiceImpl = changes.idService.currentValue;
        if (this.socket) {
          this.socket.disconnect();
        }
        this.startWebsocket(changes.idService.currentValue);
      }
    }

    if (idServiceImpl || (this.idService && idSectorImpl)) {
      idServiceImpl = idServiceImpl ? idServiceImpl : this.idService;
      idSectorImpl = idSectorImpl ? idSectorImpl : this.idSector;

      this.getRanking(idServiceImpl, idSectorImpl);

    }
  }

  getRanking(idService: number, idSector: number) {
    if(!idSector || isNaN(parseInt(idSector.toString())))
      idSector = null;

    this.areaRankingService.getRankView(idService, idSector).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }


      this.idUserClassification = response.idUserClassification;
      this.listRankViewStruct = response.listRankViewStruct;
      this.showSectorRankingTitle = (this.idSector == response.idSector);
      this.showNames = response.showNames;
      this.showRankingList = response.showRankingList;



      if (this.listRankViewStruct && this.listRankViewStruct.length > 0) {
        this.isButtonVisible = true;
      }
      else {
        this.isButtonVisible = false;
        this.close();
      }

      this.getUserInformations();

      let menuButtonRankInfo = {
        position: this.userRanking,
        userPoint: this.userPoint,
        isButtonVisible: this.isButtonVisible
      }
      this.getRankInfosValueImpl(menuButtonRankInfo);
      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  startWebsocket(idService) {
    this.socket = this.websocketGamificationService.websocketConnection();
    this.socket.emit('join', `idService-${idService}`)
      .on("updateRank", () => {
        this.getRanking(this.idService, this.idSector);
      })
      .io.on("reconnect", (res) => {
        this.socket.emit('join', `idService-${idService}`);
      });
  }

  getUserInformations() {
    this.listRankViewStruct.forEach(x => {
      if (x.areaRanking.idUserClassification == this.idUserClassification) {
        this.userName = x.areaRanking.userName;
        this.userPoint = x.areaRanking.point;
        this.userRanking = x.areaRanking.ranking;
        this.userUpDownRanking = x.upDownOrEqualPosition;

      }
    })
  }
  getRankInfosValueImpl(value) {
    this.getRankInfosValue.emit(value);
  }
  close() {
    this.closeRankInfo.emit();
  }
  ngOnDestroy() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

}
