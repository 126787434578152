<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-confirm-modal-list">
            <h2>Relatórios</h2>
        </div>
        <div class="footer-confirm-modal-list">
            <div class="row" *ngIf="admissionString64">
                <button mat-flat-button color="primary" class="btn-block" (click)="clickReport(admissionString64)">
                    <span *ngIf="!isLoading">Relatório de Admissão</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                </button>        
            </div>
            <div class="row">
                <button mat-flat-button color="primary" class="btn-block" (click)="openCreateStatementOfAttendance()">
                    <span *ngIf="!isLoading">Declaração de Comparecimento</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                </button>        
            </div>
            <div class="row" *ngIf="wristbandString64">
                <button mat-flat-button color="primary" class="btn-block" (click)="clickReport(wristbandString64)">
                    <span *ngIf="!isLoading">Pulseira</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                </button>             
            </div>
            <div class="row" *ngIf="triageWristbandString64">
                <button mat-flat-button color="primary" class="btn-block" (click)="clickReport(triageWristbandString64)">
                    <span *ngIf="!isLoading">Pulseira da Classificação</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                </button>             
            </div>
            <div class="row" *ngIf="triageString64">
                <button mat-flat-button color="primary" class="btn-block" (click)="clickReport(triageString64)">
                    <span *ngIf="!isLoading">Ficha de Classificação</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                </button>             
            </div>
            <div class="row">
                <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Fechar</button>
            </div>
        </div>
    </div>
</div>