import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { WebsocketRoomUtilService } from 'src/app/shared/services/websocket-util/websocket-room-util.service';
import { FunctionalityStruct } from 'src/app/shared/services/structs/user/funcionality.struct';
import { FunctionalityGroupStruct } from 'src/app/shared/services/structs/user/funcionalityGroup.struct';
import { WebsocketAttendanceUtilService } from 'src/app/shared/services/websocket-util/websocket-attendance-util.service';

@Component({
  selector: 'app-module-menu',
  templateUrl: './module-menu.component.html',
  styleUrls: ['./module-menu.component.css']
})
export class ModuleMenuComponent implements OnInit {

  public searchText: string;
  constructor(
    private router: Router,
    public dialog: MatDialog,
    public websocketRoomUtilService: WebsocketRoomUtilService,
    public websocketAttendanceUtilService: WebsocketAttendanceUtilService,
  ) { }

  @Input() isVisible: boolean;
  @Input() systemMainMenu: Array<MenuStructView> = new Array<MenuStructView>();
  @Output() closeModuleMenu = new EventEmitter<any>();
  
  selectedSubMenu: Array<any>;
  isVisibleSubMenu: boolean;
  openedMainMenu: string;

  ngOnInit(): void {
  }

  close() {
    this.closeModuleMenu.emit();
  }

  setSubMenu(listFunctionality: Array<FunctionalityStruct>, listFunctionalityGroup: Array<FunctionalityGroupStruct>) {
    let mainMenu: Array<MenuStructView> = new Array<MenuStructView>();
    listFunctionality.forEach((functionality: FunctionalityStruct) => {
      if (functionality.idFunctionalityGroup) {
        if (mainMenu.filter((menu: MenuStructView) => menu.idFunctionalityGroup === functionality.idFunctionalityGroup).length === 0) {
          let group: FunctionalityGroupStruct = listFunctionalityGroup.find((group: FunctionalityGroupStruct) => group.idFunctionalityGroup === functionality.idFunctionalityGroup);
          mainMenu.push({
            name: group.functionalityGroupName,
            iconClass: group.iconClass,
            idFunctionalityGroup: group.idFunctionalityGroup,
            active: false,
            id: group.idFunctionalityGroup + '_',
            subMenu: [
              {
                name: functionality.functionalityName,
                idFunctionalityGroup: functionality.idFunctionalityGroup,
                id: group.idFunctionalityGroup + '_' + functionality.idFunctionality,
                uri: functionality.uri
              }
            ]
          });
        } else {
          mainMenu.forEach((menu: MenuStructView) => {
            if (menu.idFunctionalityGroup === functionality.idFunctionalityGroup) {
              menu.subMenu.push({
                name: functionality.functionalityName,
                idFunctionalityGroup: functionality.idFunctionalityGroup,
                id: functionality.idFunctionalityGroup + '_' + functionality.idFunctionality,
                uri: functionality.uri
              });
            }
          });
        }
      } else {
        mainMenu.push({
          name: functionality.functionalityName,
          iconClass: functionality.iconClass,
          uri: functionality.uri,
          id: '_' + functionality.idFunctionality,
          active: false
        });
      }
    });

    this.systemMainMenu = mainMenu;
  }

  selectItem(item: MenuStructView, sidenavSubMenu: any) {
    let selectedMenu: MenuStructView = sessionStorage.getItem('selectedMenu') ? JSON.parse(sessionStorage.getItem('selectedMenu')) : null;
    this.setActiveItem(item);
    item.active = true;
    sessionStorage.setItem('selectedMenu', JSON.stringify(item));
    if (!item.subMenu) {
      if (selectedMenu && selectedMenu.id != item.id) {
        this.websocketRoomUtilService.clearSocket();
        this.websocketAttendanceUtilService.clearSocket(true);
      }
      this.redirect(item.uri);
      return;
    }

    if (item.active)

      this.selectedSubMenu = item.subMenu;
    this.isVisibleSubMenu = true;
    this.openedMainMenu = item.name;
    sidenavSubMenu.toggle();
  }

  getSelectedMenu() {
    let selectedMenu: MenuStructView = sessionStorage.getItem('selectedMenu') ? JSON.parse(sessionStorage.getItem('selectedMenu')) : {};
    this.setActiveItem(selectedMenu);
  }

  setActiveItem(item: MenuStructView) {
    this.systemMainMenu.forEach((menu: MenuStructView) => {
      menu.active = false;

      if (!item) return;

      menu.active = item.id === menu.id;
    });
  }

  getActiveItem(item: MenuStructView) {
    if (item.active)
      return item.active;
  }

  redirect(uri) {
    this.router.navigate([uri]);
  }

  closeSubMenu(sidenavSubMenu: any) {
    sidenavSubMenu.toggle();
    this.isVisibleSubMenu = false;
  }

}

class MenuStructView {
  id: string;
  name: string;
  iconClass?: string;
  idFunctionalityGroup?: number;
  uri?: string;
  subMenu?: Array<MenuStructView>;
  active?: boolean;
}