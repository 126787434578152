import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { FeesGuideRequest } from "../../requests/private-billing/fees-guide.request";
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { FeesGuideResponse } from "../../responses/private-billing/fees-guide.response";
import { GuideResponse } from "../../responses/private-billing/guide.response";

@Injectable({
    providedIn: 'root'
})

export class FeesGuideService extends CommonService {
    constructor(private httpClient: HttpClient) {
        super();
    }

    public Post(request: FeesGuideRequest): Observable<ReturnStruct> {
        let uri = `FeesGuide`

        return this.httpClient.post<ReturnStruct>(environment.urlApiPrivateBilling + uri, request, this.addHeaderToken())
            .pipe(catchError(this.handleError));
    }

    public ValidatePost(request: FeesGuideRequest): Observable<ReturnStruct> {
        let uri = `FeesGuide/validateRequest`

        return this.httpClient.post<ReturnStruct>(environment.urlApiPrivateBilling + uri, request, this.addHeaderToken())
            .pipe(catchError(this.handleError));
    }

    public GetFeesGuideByIdEpisode(idEpisode: number): Observable<GuideResponse> {
        let uri = `FeesGuide/idEpisode/${idEpisode}`
        return this.httpClient.get<GuideResponse>(environment.urlApiPrivateBilling + uri, this.addHeaderToken())
            .pipe(catchError(this.handleError));
    }
}