<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-modal-list">
            <h2>Bem-vindo a {{this.data.healthUnit}}</h2>
        </div>

        <div class="footer-modal-list row">
            <div class="col-12">
                <button mat-flat-button color="primary" class="btn-block" (click)="clickConfirm()">Confirmar</button>
            </div>
            <div class="col-12">
                <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">
                    <span *ngIf="this.data.haveMultipleHealthUnit">Acessar outra instituição</span>
                    <span *ngIf="!this.data.haveMultipleHealthUnit">Cancelar</span>
                </button>
            </div>
        </div>
    </div>
</div>