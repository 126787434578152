import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-confirm-allergic-patient',
  templateUrl: './confirm-allergic-patient-modal.component.html',
  styleUrls: ['./confirm-allergic-patient-modal.component.css']
})
export class ConfirmAllergicPatientModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<ConfirmAllergicPatientModalComponent>,
    public router: Router,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
  ) { }

  public buttonColor: string = "accent";
  public isLoading: boolean;
  public confirm: boolean = false;
  public model: FormGroup;

  ngOnInit(): void {

    this.model = this.formBuilder.group({
      justification: ['', [Validators.required]],
    });

  }

  onSubmit() {
    if (this.model.invalid) {
      this.alertService.show('Erro', "Preencha os campos obrigatórios", AlertType.error);
      this.isLoading = false;
      return;
    }

    this.matDialogRef.close({
      cancel: false,
      justification: this.model.get('justification').value,
    }); 
  }
  
  clickConfirm(){
    this.confirm = true;
    this.buttonColor = this.confirm ? "primary" : "accent";
  }

  close(){
    this.matDialogRef.close(); 
  }

  clickCancel(){
    this.matDialogRef.close({
      cancel: true,
    }); 
  }
}

