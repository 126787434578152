<div class="table-container">
    <table class="basic-table desktop col-12">
        <thead class="basic-table__head">
            <tr>
                <th class="column-date">Data</th>
                <th class="column-start-time">Hora Inicial</th>
                <th class="column-end-time">Hora Final</th>
                <th class="column-table">Tabela</th>
                <th class="column-code-procedure">Cód. proced</th>
                <th class="column-description">Descrição</th>
                <th class="column-count">Qtde.</th>
                <th class="column-via">Via</th>
                <th class="column-tec">Téc.</th>
                <th class="column-reduction-increase">Red/Acresc</th>
                <th class="column-unit-value">Valor Unitário</th>
                <th class="column-total-value">Valor Total</th>
                <th class="column-action">Ações</th>
            </tr>
        </thead>
        <tbody class="basic-table__body">
            <tr class="basic-table__body__row" *ngFor="let item of listSpSadtExecutedProcedure; let index = index;">
                <td class="truncate"><span>{{item.procedureDate | date:'dd/MM/yyyy'}}</span></td>
                <td class="truncate text-start-end-time"><span>{{item.procedureStartTime}}</span></td>
                <td class="truncate text-start-end-time"><span>{{item.procedureEndTime}}</span></td>
                <td class="truncate"><span>{{item.terminologyGroupCode}}</span></td>
                <td class="truncate"><span>{{item.terminologyCode}}</span></td>
                <td class="text-description" matTooltip="{{item.terminologyName}}">
                    <span>{{item.terminologyName}}</span>
                </td>
                <td class="text-count"><span>{{item.procedureQuantity}}</span></td>
                <td class="truncate"><span>{{accessMap.get(item.idAccessLookup)?.accessDescription}}</span></td>
                <td class="truncate"><span>{{techniqueMap.get(item.idTechniqueLookup)?.techniqueDescription}}</span>
                </td>
                <td class="truncate"><span>{{item.adjustmentCoefficient}}</span></td>
                <td class="truncate"><span>{{item.procedureUnitaryValue}}</span></td>
                <td class="truncate"><span>{{item.procedureTotalValue}}</span></td>
                <td class="ajust-buttons actions">
                    <div *ngIf="listProfessionalAgreementStruct && isEditingAllowed != null" class="ajust-buttons">
                        <a (click)="openProcedureModal(item,index)">
                            <mat-icon aria-hidden="false" aria-label="Editar">edit</mat-icon></a>
                        <a *ngIf="this.isEditingAllowed" (click)="deleteItem(item)">
                            <mat-icon aria-hidden="false" aria-label="Deletar">delete</mat-icon></a>
                    </div>
                    <div *ngIf="listProfessionalAgreementStruct && isEditingAllowed == null" class="ajust-buttons">
                        <a (click)="openProcedureModal(item,index)">
                            <mat-icon aria-hidden="false" aria-label="Editar">edit</mat-icon></a>
                        <a (click)="deleteItem(item)">
                            <mat-icon aria-hidden="false" aria-label="Deletar">delete</mat-icon></a>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div>
    <div *ngIf="isEditingAllowed != null" class="button-add col-12">
        <button mat-flat-button type="button"
            [disabled]="!healthcareAgreementStruct.idHealthcareAgreement || !this.isEditingAllowed" color="primary"
            (click)="openProcedureModal(null, -1)">
            <span *ngIf="isLoading == false">Adicionar procedimento executado +</span>
            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
        </button>
    </div>
    <div *ngIf="isEditingAllowed == null" class="button-add col-12">
        <button mat-flat-button type="button" [disabled]="!healthcareAgreementStruct.idHealthcareAgreement"
            color="primary" (click)="openProcedureModal(null, -1)">
            <span *ngIf="isLoading == false">Adicionar procedimento executado +</span>
            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
        </button>
    </div>
</div>