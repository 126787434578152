import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, retry, timeout } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { FowardRequest } from '../../requests/orchestrator-queue/foward.request';
import { GenerateQueueRequest } from '../../requests/orchestrator-queue/generate-queue.request';
import { SelectedLocationRequest } from '../../requests/orchestrator-queue/selected-location.request';
import { ForwardResponse } from '../../responses/orchestrator-queue/forward.response';
import { PostPutCreateQueueResponse } from '../../responses/orchestrator-queue/post-put-create-queue.response';

@Injectable({
  providedIn: 'root'
})
export class FowardService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  fowardPatient(body: SelectedLocationRequest): Observable<ReturnStruct> {
    let uri = `Forward`

    return this.httpClient.put<ReturnStruct>(environment.urlApiOrchestratorQueue + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  episodeFowardPatient(body: SelectedLocationRequest): Observable<ReturnStruct> {
    let uri = `EpisodeForward`

    return this.httpClient.put<ReturnStruct>(environment.urlApiOrchestratorQueue + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  genarateQueue(body: GenerateQueueRequest): Observable<PostPutCreateQueueResponse> {
    let uri = `GenerateQueue`

    return this.httpClient.post<PostPutCreateQueueResponse>(environment.urlApiOrchestratorQueue + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  listDestination(body: FowardRequest): Observable<ForwardResponse> {
    let uri = `Forward`

    return this.httpClient.post<ForwardResponse>(environment.urlApiOrchestratorQueue + uri, body, this.addHeaderToken())
      .pipe(
        timeout(environment.requestTimeOut),
        retry(environment.requestRetry),
        catchError(this.handleError)
      )
  }

  listEpisodeDestination(body: FowardRequest): Observable<ForwardResponse> {
    let uri = `EpisodeForward`

    return this.httpClient.post<ForwardResponse>(environment.urlApiOrchestratorQueue + uri, body, this.addHeaderToken())
      .pipe(
        timeout(environment.requestTimeOut),
        retry(environment.requestRetry),
        catchError(this.handleError)
      )
  }
}
