export enum PrioritiesStatusEnum {
    MaiorDe80Anos = 1,
    MaiorDe60Anos = 2,
    DeficienteFisico = 3,
    Desconforto = 4,
    GestanteGravida = 5,
    MenorDe1Ano = 6,
    AutistaTEA = 7,
    CriancaDeColo = 8,
    Obesidade = 9,
    DoadorDeSangue = 10
}