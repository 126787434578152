import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { TasksQueuesRequest } from '../../requests/orchestrator-integration/tasks-queues.request';

@Injectable({
  providedIn: 'root'
})
export class TasksQueuesService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public saveTasksQueues(body: TasksQueuesRequest): Observable<ReturnStruct> {

    let uri = `TasksQueues`;

    return this.httpClient.post<ReturnStruct>(environment.urlApiOrchestratorIntegration + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}