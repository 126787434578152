import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-empty-list',
  templateUrl: './empty-list.component.html',
  styleUrls: ['./empty-list.component.css']
})
export class EmptyListComponent implements OnInit {

  constructor() { }

  @Input() message: string = "Não encontramos dados para a pesquisa realizada.";

  ngOnInit(): void {
  }

}
