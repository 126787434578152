<div mat-dialog-content>
    <div class="row">
        <div class="text-center">
            <h1>{{selfTriage.patientFlowchart.flowchart}}</h1>
        </div>
    </div>
    <div class="row" *ngFor="let item of selfTriage.listQuestionAnswer; let i=index">
        <div class="col-1">
            <span class="question col-12"><b>{{i + 1}}-</b></span>
        </div> 
        <div class="col-11 row">
            <span class="question col-12 adjustment"><b>Pergunta: </b>{{item.statement}}</span>
            <span class="answer col-12 adjustment"><b>Resposta: </b>{{item.answer}}</span>
        </div> 
    </div>
    <div class="row counseling">
        <span class="text-center"><b>Aconselhamento:</b></span>
        <span class="text-center">{{selfTriage.patientFlowchart.counseling}}</span>
    </div>
    <div class="row close-button">
        <button mat-flat-button color="primary" type="button" class="btn-block justify-button" (click)="close()">
            Fechar
        </button>
    </div>
</div>