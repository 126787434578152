import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { GetUserResponse } from 'src/app/shared/services/responses/user/get-user.response';
import { ListUserResponse } from '../../responses/user/list-user.response';
import { ProfessionResponse } from '../../responses/user/profession.response';
import { UserRegisterRequest } from '../../requests/user/user-register-request';

@Injectable({
  providedIn: 'root'
})
export class UserModuleService extends CommonService{
  
  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public updateDefaultModule(idModule?: number): Observable<ReturnStruct>{
    let uri = `UserModule?idModule=`;
    if(idModule != undefined && idModule != null) 
      uri = uri + idModule
    return this.httpClient.put<ReturnStruct>(environment.urlApiUser + uri, null, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
    
    