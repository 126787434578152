import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-acidosis-modal',
  templateUrl: './acidosis-modal.component.html',
  styleUrls: ['./acidosis-modal.component.css']
})
export class AcidosisModalComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, public matDialogRef: MatDialogRef<AcidosisModalComponent>) { }

  close() {
    this.matDialogRef.close();
  }

  clickResponse(value: boolean) {
    this.matDialogRef.close({ response: value });
  }
}

