import { Component, Input, OnInit } from '@angular/core';
import { CsatService } from '../../services/API/csat/csat.service';
import { CsatRequest } from '../../services/requests/csat/csat.request';
import { MenuModuleEnum } from '../menu/menu.module.enum';
import { CsatModel } from '../../services/models/csat/csat.model';
import { AlertService, AlertType } from '../../services/alert.service';

@Component({
  selector: 'app-floating-button',
  templateUrl: './floating-button.component.html',
  styleUrls: ['./floating-button.component.css']
})
export class FloatingButtonComponent implements OnInit {

  @Input() public menuModuleEnum: MenuModuleEnum;

  constructor(private csatService: CsatService,
    private alertService: AlertService,
  ) { }

  public onePoint: number = 1;
  public twoPoints: number = 2;
  public threePoints: number = 3;
  public fourPoints: number = 4;
  public fivePoints: number = 5;

  public valueSelect: number = null;

  public showFeedbackForm: boolean = false;
  public showFloatingButton: boolean = false;
  public showThankYouMessage: boolean = false;

  public hasVoted: boolean = false;
  public isLoading: boolean = false;

  public feedbackMessage: string = '';
  public feedbackButton = null;
  public feedbackDialog = null;
  public buttonClose = null;

  ngOnInit(): void {
    this.feedbackButton = document.getElementById("feedback-button")!;
    this.feedbackDialog = document.getElementById("feedback-dialog")!;
    this.buttonClose = document.querySelector(".floating-button-close")!;
  }

  openFloatingVoting() {
    if (this.feedbackDialog && this.showFloatingButton) {
      this.showFloatingButton = false;
      this.feedbackDialog.classList.remove('visible');
    }
    else {
      this.showFloatingButton = true;
      this.feedbackDialog.classList.add('visible');
    }
  }

  captureVote(value: number) {
    this.valueSelect = value;
    this.hasVoted = true;
    this.showFeedbackForm = true;
  }

  skipFeedback() {
    this.showFeedbackForm = false;
    this.showThankYouMessage = true;

    this.putCsat();
  }

  submitFeedback() {
    this.isLoading = true;
    this.showThankYouMessage = true;

    this.putCsat();
  }

  closeButton() {
    this.showFloatingButton = false;
    this.feedbackButton.classList.add('hidden');
    this.feedbackDialog.classList.remove("visible");
    this.buttonClose.classList.add('hidden');

    this.putCsat();
  }

  closeFloating() {
    this.hasVoted = false;
    this.showThankYouMessage = false;
    this.valueSelect = null;
    this.feedbackDialog.classList.remove("visible");
    this.showFloatingButton = false;
  }

  putCsat() {
    let csatRequest: CsatRequest = new CsatRequest;
    csatRequest.csat = new CsatModel;
    csatRequest.csat.vote = this.valueSelect ? this.valueSelect : null;
    csatRequest.csat.voteDescription = this.feedbackMessage ? this.feedbackMessage : null;
    csatRequest.showFloatingButton = this.showFloatingButton;

    if (csatRequest.csat.vote != null)
      csatRequest.csat.idModule = this.menuModuleEnum;

    this.csatService.PostPutCsatRequest(csatRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (csatRequest.csat.vote != null) {
          this.showFloatingButton = false;
          this.feedbackButton.classList.add('hidden');
          this.buttonClose.classList.add('hidden');
        }

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}