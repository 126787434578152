export enum DiscriminadorHeartRateSleepy {
    awake = 1,
    sleepy = 2,
}

export enum DiscriminadorHeartRegular {
    regular = 1,
    irregular = 2,
}

export enum DiscriminadorSaturationAtmosphericAir {
    atmosphericAir = 1,
    therapyO2 = 2,
}