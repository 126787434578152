<app-menu [menuModuleEnum]="menuModuleEnum" [isRenderMenu]="false"></app-menu>

<div class="container-body">
    <div class="white-body">
        <div class="title-header">
            <mat-icon aria-hidden="false" aria-label="Menu apps">manage_accounts</mat-icon>
            <h1>Configurar conta</h1>
        </div>


        <form class="form" [formGroup]="model" (ngSubmit)="submit()">

            <div class="row">
                <div class="col-12 col-md-4">
                    <mat-form-field class="auth-input" appearance="outline">
                        <mat-label>Nome</mat-label>
                        <input matInput type="text" formControlName="name" required>
                        <mat-error *ngIf="model.get('name').invalid">Informe o nome</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-4">
                    <mat-form-field class="auth-input password-input" appearance="outline">
                        <mat-label>E-mail</mat-label>
                        <input matInput type="email" formControlName="email" required>
                        <mat-error *ngIf="model.get('email').invalid">Informe a e-mail</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-2">
                    <button mat-flat-button type="submit" color="primary" class="btn-block">
                        <span *ngIf="isLoading == false">Salvar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
                <div class="col-12 col-md-2">
                    <a mat-flat-button color="accent" class="btn-block" (click)="cancel()">
                        Cancelar
                    </a>
                </div>
            </div>
        </form>
    </div>
</div>