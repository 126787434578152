import { Component, Input, OnInit } from '@angular/core';
import { GravityEnum } from '../../enum/gravity.enum';

@Component({
  selector: 'app-gravity-tag',
  templateUrl: './gravity-tag.component.html',
  styleUrls: ['./gravity-tag.component.css']
})
export class GravityTagComponent implements OnInit {

  @Input() gravityName: string;
  @Input() idGravity: number;

  public emergency:GravityEnum = GravityEnum.emergencia;
  public veryUrgent:GravityEnum = GravityEnum.muitoUrgente;
  public urgent:GravityEnum = GravityEnum.urgente;
  public littleUrgent:GravityEnum = GravityEnum.poucoUrgente;
  public notUrgent:GravityEnum = GravityEnum.naoUrgente;
  public deceased:GravityEnum = GravityEnum.obito;
  public whitePatient:GravityEnum = GravityEnum.branco;

  constructor() { }

  ngOnInit(): void {
  }

}
