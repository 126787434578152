import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsClassificationService } from 'src/app/classification/utils.service';
import { DiscriminatorTypeEnum } from 'src/app/shared/enum/discriminator-type.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { DiscriminatorModel } from 'src/app/shared/services/models/risk-classification/discriminator.model';
import { DeviceTypeSectorResponse } from 'src/app/shared/services/responses/risk-classification/device-type-sector.response';

@Component({
  selector: 'app-capillary-perfusion-modal',
  templateUrl: './capillary-perfusion-modal.component.html',
  styleUrls: ['./capillary-perfusion-modal.component.css']
})
export class CapillaryPerfusionModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<CapillaryPerfusionModalComponent>,
    private formBuilder: UntypedFormBuilder,
    private maskService: MaskService,
    private alertService: AlertService,
    private utilService: UtilsClassificationService) { }

  public model: UntypedFormGroup;
  public discriminatorsValues: any[];
  public discriminator: DiscriminatorModel;
  public masks: Masks;
  public autisticChecked: boolean = false;
  public setUppercaseText: boolean = false;
  public discriminatorType: DiscriminatorTypeEnum = DiscriminatorTypeEnum.PerfusaoCapilar;

  ngOnInit(): void {
    let deviceTypeSectorResponse: DeviceTypeSectorResponse = this.utilService.getDevicetypeBySector()
    if (deviceTypeSectorResponse && deviceTypeSectorResponse.deviceTypeSector) {
      if (deviceTypeSectorResponse.deviceTypeSector.setUppercaseText)
        this.setUppercaseText = deviceTypeSectorResponse.deviceTypeSector.setUppercaseText;
    }
    
    this.masks = this.maskService.getMasks();
    this.discriminatorsValues = this.data.discriminatorsValues;
    this.discriminator = this.data.discriminator;
    this.model = this.formBuilder.group({
      value: [this.discriminatorsValues[this.discriminatorType], Validators.required],
      discriminatorSelectionMotive: [],
      priorityAutistic: [false]
    });
  }

  changePriorityAutistic(event: any) {
    this.model.get('value').setValue(null);
    this.autisticChecked = event.checked
    if (event.checked) {
      this.model.get('discriminatorSelectionMotive').setValidators([Validators.required]);
      this.model.get('value').disable();
    } else {
      this.model.get('discriminatorSelectionMotive').setValidators(Validators.nullValidator);
      this.model.get('value').enable();
    }
    this.model.get('discriminatorSelectionMotive').updateValueAndValidity();
  }

  close() {
    this.matDialogRef.close();
  }

  submit() {
    if (this.model.invalid) {
      if (this.model.get('priorityAutistic').value) {
        this.alertService.show('Erro', "Preencha o motivo!", AlertType.error);
      } else {
        this.alertService.show('Erro', "Preencha o campo!", AlertType.error);
      }
      return;
    }
    if (this.model.get('priorityAutistic').value) {
      this.matDialogRef.close({ value: null, select: true, motive: this.model.get('discriminatorSelectionMotive').value });
    } else {
      let value = this.model.get("value").value
      let select = this.utilService.verifySelectGeneric(this.discriminator.minValueAllowed, this.discriminator.maxValueAllowed, value);
      let data = this.utilService.getClassificationData();
      data.perfusaoCapilar = select;
      data.perfusaoCapilarValue = value;
      this.discriminatorsValues[this.discriminatorType] = value;
      this.utilService.updateClassificationData(data);
      this.matDialogRef.close({ value, select });
    }
  }
}

