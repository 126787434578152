export class StatusQueueRequest{
    public idStatusQueue: number;

    public idQueue: number;

    public idEpisode: number;

    public conclusionNote: string;

    public idEvasionNotes: number;

    public idRoom:number;

    public attendedPatientEvasion: boolean = false;

    public manualEvasion: boolean = false;
}