export class Guide {
    public idGuide: number;
    public ansCode: string;
    public datetimeInclusion: Date;
    public idEpisode: number;
    public idService: number;
    public idSector: number;
    public idRoom: number;
    public idHealthcareAgreement: number;
    public idGuideStatus: number;
    public idAppointmentGuide: number;
    public idFeesGuide: number;
    public idAdmissionGuide: number;
    public idSpSadtGuide: number;
    public patientName: string;
    public patientSocialName: string;
    public guideNumber: number;
    public idUser: number;
    public idHealthUnit: number;
}