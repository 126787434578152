import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { timestamp } from 'rxjs';
import { StatusQueueEnum } from 'src/app/shared/enum/status-queue.enum';
import { WebsocketTelemedicineActionEnum } from 'src/app/shared/enum/websocket-telemedicine-action.enum';
import { UpdateStatusQueueService } from 'src/app/shared/services/API/orchestrator-queue/update-status-queue.service';
import { ReturnToQueueService } from 'src/app/shared/services/API/queue/return-to-queue.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { StatusQueueRequest } from 'src/app/shared/services/requests/queue/status-queue.request';
import { WebsocketTelemedicineUtilService } from 'src/app/shared/services/websocket-util/websocket-telemedicine-util.service';
import { TelemedicineConfigService } from '../../services/API/telemedicine/telemedicine-config.service';
import { TelemedicineConfig } from '../../services/models/telemedicine/telemedicine-config.model';
import { PatientRedirectTypeEnum } from '../../enum/medic/patient-redirect-type.enum';
import { ReturnToQueueRequest } from '../../services/requests/queue/return-to-queue.request';


@Component({
  selector: 'app-hold-modal',
  templateUrl: './hold-modal.component.html',
  styleUrls: ['./hold-modal.component.css']
})
export class HoldModalComponent implements OnInit, OnDestroy {
  constructor(@Inject(MAT_DIALOG_DATA)
  public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<HoldModalComponent>,
    public updateStatusQueueService: UpdateStatusQueueService,
    public returnToQueueService: ReturnToQueueService,
    public alertService: AlertService,
    public websocketTelemedicineUtilService: WebsocketTelemedicineUtilService,
    public router: Router,
    private telemedicineConfigService: TelemedicineConfigService
  ) {
    this.matDialogRef.disableClose = true;
  }
  public redirectToQueue = PatientRedirectTypeEnum.retorno_a_fila;
  public evade = PatientRedirectTypeEnum.evasao;
  public isLoading: boolean = false;
  public confirmAttend = false;
  public cancelAttend = false;
  public telemedicineConfig: TelemedicineConfig;
  public timeString: string = null;
  public timeStringRender: string = null;
  public idEpisode: number = this.data.idEpisode;
  private intervalId: any;
  //websocket
  socketDisconneted: boolean = false;
  onlineOffline: boolean = navigator.onLine;
  public readyInterval;

  ngOnInit(): void {
    this.getTelemedicineConfig();
    this.intervalId = setInterval(() => {
      this.timeStringRender = this.timeString;
    }, 100);
  }

  ngOnDestroy(): void {
    if (!this.confirmAttend && !this.cancelAttend) {
      this.websocketTelemedicineUtilService.emitAction(WebsocketTelemedicineActionEnum.patientEvasion, this.data.idEpisode);
    }
    clearInterval(this.intervalId);
  }

  clearReadyInterval() {
    clearInterval(this.readyInterval);
  }

  //Websocket
  startWebsocket() {
    if (!this.websocketTelemedicineUtilService.isConnect())
      this.websocketTelemedicineUtilService.connectwebsocketRoom(this.data.idEpisode, this);

    this.websocketTelemedicineUtilService.setContext(this);
    this.websocketTelemedicineUtilService.setFunctions(this.verifyTrigger, this.updateSeconds, this.clearReadyInterval, this.validateWhatToDo);

    this.readyInterval = setInterval(() => {
      this.websocketTelemedicineUtilService.emitCountReady(this.data.idEpisode);
    }, 2000);
  }

  verifyTrigger(client: any) {
    if (this.isLoading) return;
    this.isLoading = true;
    if (client.action == WebsocketTelemedicineActionEnum.patientEvasion) {
      this.cancelAttendance("Paciente saiu do atendimento durante a contagem.",
        StatusQueueEnum.evadido,
        WebsocketTelemedicineActionEnum.patientEvasion
      )
      return;
    }
    else if (client.action == WebsocketTelemedicineActionEnum.confirmAttend) {
      this.confirmAttend = true
      // this.websocketTelemedicineUtilService.clearSocket();
      this.matDialogRef.close({ confirmAttend: this.confirmAttend });
    }
  }

  updateSeconds(timeString: string) {
    this.timeString = timeString
  }


  getTelemedicineConfig() {
    this.telemedicineConfigService.getConfig().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error, false);
          return;
        }
        this.startWebsocket();
        this.telemedicineConfig = response.telemedicineConfig;
      },
      error: (error) => {
        this.alertDynamic('Erro', error, AlertType.error, false);
      }
    });
  }

  cancelAttendance(conclusionNote: string, idStatusQueue: number, triggerAction: number) {
    if (!this.data.idEpisode) {
      this.alertService.show("Erro", "Episódio não encontrado", AlertType.error);
      return;
    }

    clearInterval(this.readyInterval);

    if (this.data.idEpisode) {
      this.cancelAttend = true;
      let statusQueueRequest: StatusQueueRequest = new StatusQueueRequest();
      statusQueueRequest.idQueue = this.data.idQueue;
      statusQueueRequest.idEpisode = this.data.idEpisode;
      statusQueueRequest.idRoom = this.data.idRoom;
      statusQueueRequest.conclusionNote = conclusionNote;
      statusQueueRequest.idStatusQueue = idStatusQueue;

      this.updateStatusQueueService.updateStatus(statusQueueRequest).subscribe({
        next: (response) => {
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }
          this.websocketTelemedicineUtilService.emitAction(triggerAction, this.data.idEpisode);
          setTimeout(() => { this.websocketTelemedicineUtilService.clearSocket(); }, 1000);
          if (triggerAction == WebsocketTelemedicineActionEnum.medicEvasion) {
            this.alertService.show("Sucesso", "Fila do episódio atualizada com sucesso", AlertType.success);
          }
          else {
            this.alertService.show("Aviso", conclusionNote, AlertType.warning);
          }
          this.matDialogRef.close({ isCancel: true });
        },
        error: (error) => {
          this.isLoading = false;
          this.alertService.show('Erro inesperado', error, AlertType.error);
        }
      });
    }
    else
      this.alertService.show("Erro", "Episódio não encontrado", AlertType.error);
  }

  validateWhatToDo(medicCanceled: boolean = false) {
    if (this.isLoading) return;

    this.isLoading = true
    if (this.websocketTelemedicineUtilService.lastActtion == WebsocketTelemedicineActionEnum.patientInternetDown) {
      this.cancelAttendance("Paciente retornado para fila.",
        StatusQueueEnum.nao_atendido,
        WebsocketTelemedicineActionEnum.patientInternetDown
      )
    }
    else if (!medicCanceled && this.telemedicineConfig.idTelemedicinePatientRedirectType == this.redirectToQueue) {
      this.CheckForReturn();
    } else if (!medicCanceled) {
      this.cancelAttendance("Paciente evadido após não entrar no atendimento a tempo",
        StatusQueueEnum.evadido,
        WebsocketTelemedicineActionEnum.patientEvasion
      )
    } else {
      this.cancelAttendance("O atendimento foi cancelado antes de ser iniciado",
        StatusQueueEnum.nao_atendido,
        WebsocketTelemedicineActionEnum.medicEvasion
      )
    }
  }

  CheckForReturn() {
    this.returnToQueueService.checkReturnability(this.data.idEpisode, this.telemedicineConfig.maxReturns).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (response.canReturn) {
          this.createReturnability();
        }
        else {
          this.cancelAttendance("O paciente foi evadido, pois alcançou o número máximo de retornos para fila!",
            StatusQueueEnum.evadido,
            WebsocketTelemedicineActionEnum.patientEvasion
          )
        }
      }, error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    })
  }


  createReturnability() {
    let returnToQueueRequest = new ReturnToQueueRequest();
    returnToQueueRequest.idEpisode = this.data.idEpisode;
    returnToQueueRequest.idQueue = this.data.idQueue;
    this.returnToQueueService.create(returnToQueueRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.cancelAttendance("O paciente foi retornado para fila, pois não entrou no atendimento no tempo",
          StatusQueueEnum.nao_atendido,
          WebsocketTelemedicineActionEnum.medicEvasion
        )
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    })
  }

  alertDynamic(alertTypeDescription: string, alertDescription: string, alertType: AlertType, endLoading: boolean = false) {
    if (alertType && alertType.valueOf() == AlertType.error)

      this.alertService.show(alertTypeDescription, alertDescription, alertType ? alertType.valueOf() : AlertType.error);
    if (endLoading)
      this.isLoading = false;
  }
}