import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StatusQueueEnum } from '../../enum/status-queue.enum';
import { AlertService, AlertType } from '../../services/alert.service';
import { UpdateStatusQueueService } from '../../services/API/orchestrator-queue/update-status-queue.service';
import { StatusQueueRequest } from '../../services/requests/queue/status-queue.request';

@Component({
  selector: 'app-without-permission-evade-modal',
  templateUrl: './without-permission-evade-modal.component.html',
  styleUrls: ['./without-permission-evade-modal.component.css']
})
export class withoutPermissionEvadeModal implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<withoutPermissionEvadeModal>,
  private formBuilder: UntypedFormBuilder,) { }
  
  public isLoading:boolean;  
  public model: UntypedFormGroup;
  
  ngOnInit(): void {
    
  }
  
  close(){
    this.matDialogRef.close({isEvade: false}); 
  }
  
  clickOk(){
    this.matDialogRef.close({isEvade: false}); 
  }
}
