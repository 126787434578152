import { FeesGuideModel } from "../../models/private-billing/fees-guide.model";
import { Guide } from "../../models/private-billing/guide.model";
import { FeesProcedureStruct } from "./fees-procedure.struct";

export class FeesGuideStruct {
    public guide: Guide = new Guide();
    public feesGuide: FeesGuideModel = new FeesGuideModel();
    public listFeesProcedureStruct: FeesProcedureStruct[] = [];
    public hasAlteration: boolean = false;
    public isEditingAllowed: boolean;
    public guideNumber: string;
    public admissionGuideNumber: string;
}